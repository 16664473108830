import { createSelector } from 'reselect';

import { State } from 'store/types';

const rootSelector = (state: State) => state.ui.topic.tag.details;

export default {
	startStopIsLoading: createSelector(rootSelector, state => state.startStopLoading),
	getSelectedTag: createSelector(rootSelector, state => state.selectedTag)
};
