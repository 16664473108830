import React from 'react';

import "./Header.scss";

type OwnProps = {
	alert?: JSX.Element,
	children: JSX.Element
};

type ComponentProps = OwnProps;

const Header = (props: ComponentProps) => (
	<div id="header" className="md-grid">
		<div className="content">
			<div className="logo md-cell--middle">
				<img src="/logo_discover.png" alt="" />
				<img src="/icon_discover.png" className="icon" alt="" />
			</div>
			{props.alert}
			<div className="md-cell--right md-cell--middle">
				{props.children}
			</div>
		</div>
	</div>
);

export default React.memo(Header);
