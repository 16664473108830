/* eslint-disable max-len */
import React from 'react';
import './Terms.scss';

export default () => (
	<div id="headerPanelMain">
		<div id="terms">
			<div className="term">
				<h1>Discover & Insights user privacy notice</h1>
				<p> <span className="bold">Last Updated:</span> July 4, 2022 </p>
				<p>This user privacy notice (“<span className="bold">Notice</span>”) provides additional information regarding the choices you have with respect to the information collected and the policies and procedures of Launchmetrics on how we collect and use personal information throughout our services Discover and Insights (each called “<span className="bold">Service</span>” and together “<span className="bold">Services</span>”).</p>

				<p>Discover<sup>SM</sup> (the “<span className="bold">Service</span>”) allows Users to track the mentions of their brands (and any keyword of their choice) on traditional and social media, create and distribute news digests and generate reports of their coverage.</p>

				<p>Insights<sup>SM</sup> (the “<span className="bold">Service</span>”) allows Users to track and analyze the Customer's public reputation in print, online press and social media, as well as to produce reports and conduct competitive benchmarking.</p>

				<p>When we refer to authors (“<span className="bold">Autors</span>”) we mean all individual authors of online content published on publicly available websites (Twitter, Instagram, etc.).
				We receive information about you from various sources, including: (i) if you register for the Services, through your user account on the Launchmetrics Cloud (your “<span className="bold">Account</span>”), and (ii) your use of the Services generally; and (iii) from third party websites and services. When you use the Services, you are consenting to the collection, transfer, manipulation, storage, disclosure and other uses of your information as described in this Notice.</p>

				<p>Capitalized terms used herein and not otherwise defined herein shall have the meanings assigned to them in the <a href="https://www.launchmetrics.com/product-portal-privacy-policy" target="_blank" rel="noopener noreferrer">Launchmetrics Cloud Privacy Policy</a>, which shall be considered as an integral part of this Notice.</p>

				<p>Table of Contents:</p>

				<span className="bold"> <a href="#privacyPolicyCover"> What Does This Privacy Policy Cover? </a></span>
				<br />
				<span className="bold"> <a href="#informationCollect"> What Information Do We Collect and Receive?</a></span>
				<br />
				<span className="bold"> <a href="#thirdPartyTermsOfService"> Third Party Terms of Service </a></span>
				<br />
				<span className="bold"> <a href="#howToContactUs"> How to Contact Us?</a></span>
				<br />
				<span className="bold"> <a href="#whatHappensIfThisNoticeChanges"> What Happens When There are Changes To This Notice</a></span>
				<br />
				<span className="bold"> <a href="#howToContactUs"> More Information </a></span>

				<p>Please read this statement carefully to understand our policies and practices regarding your Information and how we will treat it while using Discover and Insights. In case of doubts or questions, please feel free to contact us at <a href="mailto:dpo@launchmetrics.com">dpo@launchmetrics.com</a>.</p>
			</div>

			<div id="privacyPolicyCover" className="term">
				<h2>What Does This Privacy Policy Cover?</h2>
				<p>This Notice covers the treatment of personal data (“<span className="bold">Personal Information</span>”) gathered when you are using or accessing the Services. This Notice also covers our treatment of any Personal Information that our business partners share with us or that we share with our business partners. This Notice does not apply to the practices of third parties that we do not own or control, including but not limited to any third party websites, services and applications (“<span className="bold">Third Party Services</span>”) that you elect to access through the Service or to individuals that we do not manage or employ. While we attempt to facilitate access only to those Third Party Services that share our respect for your privacy, we cannot take responsibility for the content or privacy policies of those Third Party Services. We encourage you to carefully review the privacy policies of any Third Party Services you access.</p>
			</div>

			<div id="informationCollect" className="term">
				<h2>What Information Do We Collect and Receive?</h2>
				<p>The information we gather enables us to personalize, improve and continue to operate the Services. In connection with certain aspects of each Service, we may request, and/or collect some of your Personal Information. We collect and receive Personal Information and other information and data in different ways:</p>

				<p><li className="key-point"><span className="bold">Information you provide to us:</span> Users routinely submit Personal Information to Launchmetrics when using the Services within the Launchmetrics Cloud (“<span className="bold">User Data</span>” <span className="bold">or</span> “<span className="bold">Account Information</span>”).</li></p>

				<p>You acknowledge that this information may be personal to you, and by creating an Account on the Launchmetrics Cloud and providing Personal Information to us, you allow others, including us, to identify you. Your Account Information is completely isolated from the ones of other Users Accounts. Only different Users of the same Customer Account will have access to each other's information. You are able to change any of the details you provided in your Account settings. We may use your contact information to send you information about our Services. You <span className="bold">may unsubscribe from these messages by sending an email to your Account Manager</span>. Please refer to our <a href="https://connect.launchmetrics.com/Home/en/GeneralTerms" target="_blank" rel="noopener noreferrer">Terms of Service</a> for further details on registering and using the Services offered on the Launchmetrics Cloud.</p>

				<p className="pre-dash">When we create an Account for you, you will provide us information that could be Personal Information, such as your:</p>
				<ul className="dash">
					<li><span className="bold">Business contact Information</span> (First name, Last name, Email address, Telephone number, Job Title, Company name,  Location, Language, Timezone and Country).</li>
					<li><span className="bold">Account Information</span> (Username and/or Email address, Password).</li>
					<li><span className="bold">Support Data</span> (Account Information for the service used and related to the support inquiry).</li>
				</ul>

				<p className="pre-dash"><li className="key-point"><span className="bold">Information we collect automatically</span>: We automatically receive and record information from your web browser when you interact with the Launchmetrics Cloud, including your IP address, Device, and Usage Information (as detailed <a href="https://www.launchmetrics.com/product-portal-privacy-policy" target="_blank" rel="noopener noreferrer">here</a>):</li></p>
				<ul className="dash">
					<li><span className="bold">Usage Data</span>: We collect statistical information about how both Unregistered and Registered Users, collectively, use the Services (“<span className="bold">Aggregate Information</span>”). Some of this information is derived from Personal Information. This statistical information is not Personal Information and cannot be tied back to you, your Account or your web browser, unless you give us the explicit agreement to do so.</li>
				</ul>

				<p><li className="key-point"><span className="bold">Third Party Data</span>: We collect content that has been made publicly available on the Internet (including Personal Information) for monitoring purposes (“<span className="bold">Third Party Data</span>”). Such content is accessed and used by us. Such content is also maintained, accessed and used by Users on the Discover and Insights platforms in connection with our provision of the Services.</li></p>

				<p>When we are crawling the Internet and Social Media to provide you with Data on Discover and Insights, we are a Data Controller (under GDPR or a “Business” under the CCPA) as we decide what data we collect, how and where we collect it and why this data is used in connection with its services. A User is a Data Controller as well, as it can decide and is responsible for what happens to this Data.</p>

				<p>The legal basis on which we process Personal Information in these circumstances is pursuant to our “legitimate interests”. Our legitimate interests are in providing our Services to our Discover and Insights Users, including the monitoring features of publicly available data or data made directly available on social media by the relevant data subjects (“<span className="bold">Authors</span>”). In turn, this allows our Users to obtain the best coverage, consolidate print, online and social monitoring and reporting into a single tool and create and share newsletters, coverage reports and books easily, and benchmark their performance on those channels in time and against competitors. This legal basis requires a balancing of the legitimate interests of the Data Controller with the interests or fundamental rights and freedoms of the data subject which require protection of personal data. In this situation the data subjects (the Authors) made the crawled data publicly available and should retain control of the privacy settings of their publications on their website or social media. As such the interests, fundamental rights and freedoms of these data subjects are not prejudiced or overridden.</p>

				<p>In the course of using our Services you may also provide us with personal information about third parties, in particular information regarding potential recipients of the newsletters and news digests that you are distributing through our Services. In this case, Launchmetrics is a Processor (a “Service Provider” under the CCPA) of this data and you, as a User, are the Controller (a “Business” under the CCPA) as we store this data but you decide and are responsible for what happens to it.</p>

				<p><li className="key-point"><span className="bold">Cookies Information</span>: We use cookies and similar technologies in our Services that help us collect other information. The Services may also include cookies and similar tracking technologies of third parties, which may collect information about you via the Services and across other websites and online services. For more details about how we use these technologies, please see our <a href="https://www.launchmetrics.com/bpc-cookies" target="_blank" rel="noopener noreferrer">Cookies Policy</a>.</li></p>
			</div>

			<div id="thirdPartyTermsOfService" className="term">
				<h2>Third party Terms of service</h2>
				<ul>
					<li><span className="bold">YouTube and Google Terms of service</span></li>
					<p>Discover uses YouTube's API to run queries based on your search terms, in order to identify relevant videos on the platform.</p>
					<p>Therefore, by using Discover, you also agree to YouTube terms of service and Google Privacy Policy that can be accessed here:</p>
					<a href="http://www.google.com/policies/privacy" target="_blank" rel="noopener noreferrer">Terms of Service Privacy Policy – Privacy & Terms – Google</a>
					<br />
					<p>Please visit the web page <a href="https://security.google.com/settings/security/permissions" target="_blank" rel="noopener noreferrer">https://security.google.com/settings/security/permissions</a> to know more about Google's security settings about revoking your consent to the access to your data.</p>
					<p>In addition, for any privacy questions, please feel free to contact <strong>Launchmetrics</strong> at <a href="mailto:dpo@launchmetrics.com">dpo@launchmetrics.com</a> or to visit Launchmetrics Cloud Privacy Policy available <a href="https://www.launchmetrics.com/product-portal-privacy-policy" target="_blank" rel="noopener noreferrer">here</a>.</p>

					<li><span className="bold">Meta Terms of service</span></li>
					<p>Discover uses Meta’s API to run queries based on your search terms, in order to identify relevant content on the platform.</p>
					<p>Therefore, by using Discover, you also agree to Meta terms of service and Meta Privacy Policy that can be accessed here:</p>
					<a href="https://www.facebook.com/legal/terms/update" target="_blank" rel="noopener noreferrer">https://www.facebook.com/legal/terms/update</a>
					<br />
					<a href="https://www.facebook.com/policies" target="_blank" rel="noopener noreferrer">https://www.facebook.com/policies</a>

					<li><span className="bold">Instagram Terms of service</span></li>
					<p>Discover uses Instagram’s API to run queries based on your search terms, in order to identify relevant videos and pictures on the platform.</p>
					<p>Therefore, by using Discover, you also agree to Instagram terms of service and Instagram Privacy Policy that can be accessed here:</p>
					<a href="https://help.instagram.com/581066165581870?helpref=page_content" target="_blank" rel="noopener noreferrer">https://help.instagram.com/581066165581870?helpref=page_content</a>
					<br />
					<a href="https://help.instagram.com/519522125107875?helpref=page_content" target="_blank" rel="noopener noreferrer">https://help.instagram.com/519522125107875?helpref=page_content</a>

					<li><span className="bold">Twitter Terms of service</span></li>
					<p>Discover uses Twitter’s API to run queries based on your search terms, in order to identify relevant content on the platform.</p>
					<p>Therefore, by using Discover, you also agree to Twitter terms of service and Twitter Privacy Policy that can be accessed here:</p>
					<a href="https://twitter.com/en/tos" target="_blank" rel="noopener noreferrer">https://twitter.com/en/tos</a>
					<br />
					<a href="https://twitter.com/en/privacy#update" target="_blank" rel="noopener noreferrer">https://twitter.com/en/privacy#update</a>

					<li><span className="bold">Douyin Terms of service</span></li>
					<p>By using Discover, you also agree to Douyin terms of service and Douyin Privacy Policy that can be accessed here:</p>
					<a href="https://www.douyin.com/agreements/?id=6773901168964798477" target="_blank" rel="noopener noreferrer">https://www.douyin.com/agreements/?id=6773901168964798477</a>
					<br />
					<a href="https://www.douyin.com/agreements/?id=6773906068725565448" target="_blank" rel="noopener noreferrer">https://www.douyin.com/agreements/?id=6773906068725565448</a>

					<li><span className="bold">WeChat Terms of service</span></li>
					<p>By using Discover, you also agree to WeChat terms of service and WeChat Privacy Policy that can be accessed here:</p>
					<a href="https://www.wechat.com/en/service_terms.html" target="_blank" rel="noopener noreferrer">https://www.wechat.com/en/service_terms.html</a>
					<br />
					<a href="https://www.wechat.com/en/privacy_policy.html" target="_blank" rel="noopener noreferrer">https://www.wechat.com/en/privacy_policy.html</a>

					<li><span className="bold">Weibo Terms of service</span></li>
					<p>By using Discover, you also agree to Weibo terms of service and Weibo Privacy Policy that can be accessed here:</p>
					<a href="https://weibo.com/signup/v5/privacy" target="_blank" rel="noopener noreferrer">https://weibo.com/signup/v5/privacy</a>
					<br />
					<a href="https://weibo.com/signup/v5/protocol" target="_blank" rel="noopener noreferrer">https://weibo.com/signup/v5/protocol</a>

					<li><span className="bold">Bili bili Terms of service</span></li>
					<p>By using Discover, you also agree to Bili bili terms of service and Bili bili Privacy Policy that can be accessed here:</p>
					<a href="https://www.bilibili.com/blackboard/privacy-pc.html" target="_blank" rel="noopener noreferrer">https://www.bilibili.com/blackboard/privacy-pc.html</a>
					<br />
					<a href="https://www.bilibili.com/protocal/licence.html" target="_blank" rel="noopener noreferrer">https://www.bilibili.com/protocal/licence.html</a>
				</ul>
			</div>

			<div id="howToContactUs" className="term">
				<h2>How to contact us?</h2>
				<p>If you have any questions or comments regarding this User Policy or our privacy practices using the Launchmetrics Cloud or any of the Services, please send us a detailed message to <a href="mailto:support@launchmetrics.com">support@launchmetrics.com</a>. We will make every effort to resolve your concerns. You may also contact us by postal mail or email at:</p>

				<p className="pre-dash">If you are a resident of or have your principal place of business in the US:</p>
				<ul className="no-dash">
					<li>Launchmetrics</li>
					<li>Attention: Legal - Privacy</li>
					<li>support@launchmetrics.com</li>
					<li>115 E 23rd St., Fl 6</li>
					<li>New York, NY 10010 USA</li>
				</ul>

				<p className="pre-dash">If you are a resident of the EEA, Switzerland, the United Kingdom:</p>
				<ul className="no-dash">
					<li>Our Data Protection Officer can be contacted at: <a href="mailto:dpo@launchmetrics.com">dpo@launchmetrics.com</a>.</li>
				</ul>

				<p className="pre-dash">If you are a resident in the People Republic of China, Hong Kong or Singapore:</p>
				<ul className="no-dash">
					<li>Our Data Protection Officer can be contacted at: <a href="mailto:privacy@parklu.com">privacy@parklu.com</a>.</li>
				</ul>
			</div>

			<div id="whatHappensIfThisNoticeChanges" className="term">
				<h2>What Happens When There are Changes To This Notice</h2>
				<p>Although most changes are likely to be minor, Launchmetrics will occasionally update this User Policy to reflect user and customer feedback about the Services changes and clarifications will take effect immediately upon their posting. Launchmetrics encourages you to periodically review this Notice and associated User Policy to be informed of how we are protecting your information and check for any changes. We have the sole discretion to modify this Notice and User Policy or other Notices. If we make changes that materially alter your privacy rights, we will provide additional notice, such as via email or through the Services or the homepage of the Launchmetrics Cloud.</p>
			</div>

			<div id="moreInformation" className="term">
				<h2>More Information</h2>
				<p>For more information regarding how we use, maintain and protect Personal Information that we receive when you login to Discover or Insights, read the following: <a href="https://www.launchmetrics.com/product-portal-privacy-policy" target="_blank" rel="noopener noreferrer">Launchmetrics Cloud Privacy Policy</a>.</p>
			</div>
		</div>
	</div>
);
