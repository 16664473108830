import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import selectors from 'store/ui/newsletter/emailList/edit/selectors';
import { DispatchProps, StateProps } from './types';
import NewsletterEmailListLayout from './EmailListLayout';

const mapStateToProps = (state: State): StateProps => ({
	selectedListId: selectors.getEmailListId(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterEmailListLayout);
