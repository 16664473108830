import { transform } from "lib/imports/lodash";

import { Facet } from "class/Facet";
import { FeedFilters } from "./Feed";

export type FilterFacet = {
	key: string,
	name?: string,
	detail?: string,
	type?: string
};

export type FiltersFacetGroup = {
	[facet_group_name: string]: FilterFacet[]
};


export default {
	transformFromApi: (filters: FeedFilters): FiltersFacetGroup => {
		return transform(filters, (res: FiltersFacetGroup, value, key) => {
			const valueFilters = value!.map(facetValue => ({ key: facetValue }));
			if (key === Facet.channelGroupKey) res[key] = Facet.groupFacets(valueFilters, Facet.channelGroups);
			else if (key === Facet.printMediaLevelKey) res[key] = Facet.groupFacets(valueFilters, Facet.printMediaLevelGroup);
			else res[key] = valueFilters;
		}, {});
	}
};
