import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { DispatchProps, StateProps } from './types';
import LinkedBrand from './LinkedBrand';

const mapStateToProps = (): StateProps => ({
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkedBrand);
