import { combineReducers } from 'redux';

import * as list from './list';
import * as feed from './feed';
import * as dashboard from './dashboard';

export const reducers = combineReducers({
	list: list.reducers,
	feed: feed.reducers,
	dashboard: dashboard.reducers
});

export interface State {
	list: list.State;
	feed: feed.State;
	dashboard: dashboard.State;
}

export const INITIAL_STATE = {
	list: list.INITIAL_STATE,
	feed: feed.INITIAL_STATE,
	dashboard: dashboard.INITIAL_STATE
};
