import React from 'react';
import { withT } from 'lib/i18n';
import { Button, CircularProgress } from 'lib/imports/react-md';
import { isEmpty } from 'lib/imports/lodash';

import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import Dialog from 'components/common/dialog';
import Dropzone, { AddFileError } from 'components/common/Dropzone';
import { useAssignReducer } from 'lib/hooks';
import { useNewsletterEditParser } from 'lib/components/hooks/newsletter';

import { NewsletterDraftSection } from 'store/ui/newsletter/draft/edit/types';
import NewsletterDraftEditContentLayer from './Layer';

import { ComponentProps } from './types';

import './NewsletterDraftEditContent.scss';

const FILE_MAX_SIZE = 3000000 as const;

type CropperProps = {
	image?: string
	crop?: Area
	position?: Point
	aspect?: number
	zoom?: number
};
type DropzoneProps = {
	files?: File[]
};
type DialogImageActionOpenMode = 'cropImage' | 'customImage';
type DialogImageActionResult = { action: DialogImageActionOpenMode | 'removeCustomImage' | 'closed', file?: File, crop?: Area };
let resolveDialogImageAction: (result: DialogImageActionResult) => void;

type DialogTextActionResult = { action: 'editText' | 'closed', text?: string };
let resolveDialogTextAction: (result: DialogTextActionResult) => void;

type DialogStatus = DialogImageActionOpenMode | 'editText' | 'closed';

const NewsletterDraftEditContent = (props: ComponentProps) => {
	const { status, previewHtml, updatedSectionsHtml, t } = props;
	const { onAddNotification } = props;

	const [cropperProps, setCropperProps] = useAssignReducer<CropperProps>({});
	const [dropzoneProps, setDropzoneProps] = useAssignReducer<DropzoneProps>({});
	const [editedText, setEditedText] = React.useState<string>('');
	const [dialogStatus, setDialogStatus] = React.useState<DialogStatus>('closed');

	const onDialogImageAction = React.useCallback((mode: DialogImageActionOpenMode, image: { url: string, aspect: number }) => {
		setCropperProps({ position: { x: 0, y: 0 }, zoom: 1, image: image.url, aspect: image.aspect });
		if (mode === 'customImage') setDropzoneProps({ files: [] });
		setDialogStatus(mode);
		return new Promise<DialogImageActionResult>(resolve => { resolveDialogImageAction = resolve; });
	}, [setCropperProps, setDropzoneProps]);

	const onDialogTextAction = React.useCallback((text: string) => {
		setEditedText(text);
		setDialogStatus('editText');
		return new Promise<DialogTextActionResult>(resolve => { resolveDialogTextAction = resolve; });
	}, [setEditedText]);

	const editTextActions = React.useMemo(() => ({
		change: (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
			setEditedText(ev.target.value);
		}
	}), [setEditedText]);

	const dropzoneActions = React.useMemo(() => ({
		added: (files: File[]) => {
			const imageBlob = URL.createObjectURL(files[0]);
			setCropperProps({ image: imageBlob, position: { x: 0, y: 0 }, zoom: 1 });
			setDropzoneProps({ files });
		},
		removed: () => {
			setDropzoneProps({ files: [] });
			setCropperProps({ image: undefined, position: undefined, crop: undefined });
		},
		error: ([fileError]: AddFileError[]) => {
			if (fileError.error === "incorrect_file_type") onAddNotification({ level: "warning", t: 'newsletter.digest.edit.custom_image.rejected_type' });
			else if (fileError.error === "file_too_large") onAddNotification({ text: t('newsletter.digest.edit.custom_image.rejected_size', { sizeInMb: FILE_MAX_SIZE / 1000000 }), level: 'warning' });
			else onAddNotification({ level: "warning", t: 'newsletter.digest.edit.custom_image.upload_error' });
		}
	}), [setDropzoneProps, setCropperProps, onAddNotification, t]);

	const cropperActions = React.useMemo(() => ({
		positionChange: (position: Point) => setCropperProps({ position }),
		zoomChange: (zoom: number) => setCropperProps({ zoom }),
		cropChange: (_: any, cropPx: Area) => setCropperProps({ crop: cropPx })
	}), [setCropperProps]);

	const resetDialogProps = React.useCallback(() => {
		setCropperProps({ image: undefined, crop: undefined, position: { x: 0, y: 0 }, zoom: undefined });
		setDropzoneProps({ files: [] });
	}, [setCropperProps, setDropzoneProps]);

	const onRemoveCustomImage = React.useCallback(() => {
		resolveDialogImageAction({ action: 'removeCustomImage' });
		setDialogStatus('closed');
		resetDialogProps();
	}, [setDialogStatus, resetDialogProps]);

	const onAcceptDialog = React.useCallback(() => {
		if (dialogStatus === 'editText') {
			resolveDialogTextAction({ text: editedText, action: 'editText' });
		} else {
			const crop = cropperProps.crop;
			if (!crop) resolveDialogImageAction({ action: 'closed' });
			else {
				const dialogImageActionResult: DialogImageActionResult = { action: dialogStatus, crop };
				if (dropzoneProps.files) dialogImageActionResult.file = dropzoneProps.files![0];
				resolveDialogImageAction(dialogImageActionResult);
			}
		}
		setDialogStatus('closed');
		resetDialogProps();
	}, [setDialogStatus, editedText, dropzoneProps, cropperProps, dialogStatus, resetDialogProps]);

	const onCancelDialog = React.useCallback(() => {
		if (dialogStatus === 'editText') {
			resolveDialogTextAction({ action: 'closed' });
		} else {
			resolveDialogImageAction({ action: 'closed' });
		}
		setDialogStatus('closed');
		resetDialogProps();
	}, [dialogStatus, setDialogStatus, resetDialogProps]);

	const createEditLayer = React.useCallback((attrs: Record<string, string>): JSX.Element => {
		const { id, type, text, 'image-count': imageCount, 'image-size': imageSize, 'image-original': imageOriginal } = attrs;
		return (
			<NewsletterDraftEditContentLayer
				documentId={id.replace('doc_', '')}
				type={type}
				section={(type.match('online') ? 'online' : type) as NewsletterDraftSection}
				text={text}
				imageCount={imageCount ? parseInt(imageCount) : 0}
				imageSize={imageSize || ''}
				imageOriginalUrl={imageOriginal || ''}
				onDialogImageAction={onDialogImageAction}
				onDialogTextAction={onDialogTextAction}
			/>
		);
	}, [onDialogImageAction, onDialogTextAction]);

	const [previewContent, setUpdatedSectionsHtml] = useNewsletterEditParser(previewHtml, createEditLayer);

	React.useEffect(() => {
		setUpdatedSectionsHtml(updatedSectionsHtml);
	}, [setUpdatedSectionsHtml, updatedSectionsHtml]);

	const dialogContent = React.useMemo(() => {
		if (dialogStatus === 'closed') return null;
		if (dialogStatus === 'editText') {
			return (
				<div id="editTextContainer">
					<textarea value={editedText} onChange={editTextActions.change}></textarea>
				</div>
			);
		}

		const cropperComponent = cropperProps.image && (
			<div className="cropperContainer">
				<Cropper
					image={cropperProps.image}
					aspect={cropperProps.aspect!}
					crop={cropperProps.position!}
					zoom={cropperProps.zoom!}
					onCropChange={cropperActions.positionChange}
					onZoomChange={cropperActions.zoomChange}
					onCropComplete={cropperActions.cropChange}
				/>
			</div>
		);
		if (dialogStatus === 'cropImage') return cropperComponent;

		return (
			<>
				<Dropzone
					files={dropzoneProps.files!}
					multiple={false}
					accept="image/*"
					maxSize={FILE_MAX_SIZE}
					onFilesAdded={dropzoneActions.added}
					onRemoveFile={dropzoneActions.removed}
					onAddFilesError={dropzoneActions.error}
				/>
				{(isEmpty(dropzoneProps.files) && cropperProps.image) && (
					<Button flat onClick={onRemoveCustomImage} iconBefore={true} iconChildren="delete">
						{t('newsletter.digest.edit.custom_image.remove')}
					</Button>
				)}
				{cropperComponent}
			</>
		);
	}, [dialogStatus, editedText, cropperProps, dropzoneProps, cropperActions, dropzoneActions, editTextActions, onRemoveCustomImage, t]);

	const dialogTitle = React.useMemo(() => {
		if (dialogStatus === 'editText') return t('newsletter.digest.edit.icon.edit_text');
		if (dialogStatus === 'cropImage') return t('newsletter.digest.edit.modal.title.crop');
		if (dialogStatus === 'customImage') return t('newsletter.digest.edit.custom_image.title');
		return '';
	}, [dialogStatus, t]);

	return (
		<div id="newsletterDraftEditContent">
			<div id="newsletterDraftPreview" className={status}>

				{previewContent}

				{status === 'updating' && (
					<div id="updatingLayer">
						<CircularProgress id="updatingDraft" className="loader" />
					</div>
				)}

			</div>
			{dialogContent && (
				<Dialog
					id="newsletterEditDraftDialog"
					title={dialogTitle}
					content={dialogContent}
					onAcceptText={t('form.accept')}
					onCancelText={t('form.cancel')}
					onAccept={onAcceptDialog}
					onCancel={onCancelDialog}
				/>
			)}
		</div>
	);
};

export default withT(NewsletterDraftEditContent);
