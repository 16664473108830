import React, { useRef, RefObject, useState } from "react";
import parse, { domToReact, HTMLReactParserOptions, DomElement } from 'html-react-parser';
import { transform, forEach, isEmpty, get } from "lib/imports/lodash";
import { NewsletterDraftSection, NewsletterDraftUpdatedSectionsHtml } from "store/ui/newsletter/draft/edit/types";

type Refs = {
	table: RefObject<HTMLTableElement>,
	section: RefObject<HTMLTableDataCellElement>
}

type RefChannels = {
	[key in NewsletterDraftSection]: Refs
}

const showMoreHandler = (ref: Refs) => {
	if (ref.table.current!.className.match(/show-more-block-hidden/)) {
		ref.table.current!.className = "show-more-block show-more-block-fade";
		setTimeout(() => {
			ref.table.current!.className = "show-more-block";
			ref.section.current!.className = "channel showing-all";
		}, 500);
	} else {
		ref.table.current!.className = "show-more-block show-more-block-fade";
		setTimeout(() => {
			ref.table.current!.className = "show-more-block show-more-block-hidden";
			ref.section.current!.className = "channel";
		}, 500);
	}
};

const useNewsletterChannelRefs = (): RefChannels => {
	return {
		all: { table: useRef() as RefObject<HTMLTableElement>, section: useRef() as RefObject<HTMLTableDataCellElement> },
		online: { table: useRef() as RefObject<HTMLTableElement>, section: useRef() as RefObject<HTMLTableDataCellElement> },
		print: { table: useRef() as RefObject<HTMLTableElement>, section: useRef() as RefObject<HTMLTableDataCellElement> },
		blogs: { table: useRef() as RefObject<HTMLTableElement>, section: useRef() as RefObject<HTMLTableDataCellElement> },
		twitter: { table: useRef() as RefObject<HTMLTableElement>, section: useRef() as RefObject<HTMLTableDataCellElement> },
		instagram: { table: useRef() as RefObject<HTMLTableElement>, section: useRef() as RefObject<HTMLTableDataCellElement> },
		facebook: { table: useRef() as RefObject<HTMLTableElement>, section: useRef() as RefObject<HTMLTableDataCellElement> },
		youtube: { table: useRef() as RefObject<HTMLTableElement>, section: useRef() as RefObject<HTMLTableDataCellElement> },
		tiktok: { table: useRef() as RefObject<HTMLTableElement>, section: useRef() as RefObject<HTMLTableDataCellElement> },
		wechat_public: { table: useRef() as RefObject<HTMLTableElement>, section: useRef() as RefObject<HTMLTableDataCellElement> },
		weibo: { table: useRef() as RefObject<HTMLTableElement>, section: useRef() as RefObject<HTMLTableDataCellElement> },
		bilibili: { table: useRef() as RefObject<HTMLTableElement>, section: useRef() as RefObject<HTMLTableDataCellElement> },
		red: { table: useRef() as RefObject<HTMLTableElement>, section: useRef() as RefObject<HTMLTableDataCellElement> },
		douyin: { table: useRef() as RefObject<HTMLTableElement>, section: useRef() as RefObject<HTMLTableDataCellElement> }
	};
};

type Content = JSX.Element | JSX.Element[] | null;
type Parse = (html: string) => void;
type OpenSection = (section: NewsletterDraftSection) => void;

export function useNewsletterParser(): [Content, Parse, OpenSection] {
	const [content, setContent] = useState<Content>(null);
	const refs = useNewsletterChannelRefs();

	const options: HTMLReactParserOptions = React.useMemo(() => ({
		trim: true,
		replace: node => {
			const { type, name, attribs, children } = node;
			if (!attribs || type !== 'tag') return;
			const classNames = attribs.class;
			if (!classNames) return;

			if (classNames.match(/show-more-block/)) {
				const section = attribs.type as NewsletterDraftSection;
				return (
					<table className="show-more-block show-more-block-hidden" ref={refs[section].table} >
						{domToReact(children!, options)}
					</table>
				);
			}

			if ((name === 'td' || name === 'div') && classNames.match(/channel/)) {
				const parsedChildren = domToReact(children!, options);
				const section = attribs.id as NewsletterDraftSection;

				if (['print', 'blogs', 'online'].includes(section)) return (
					<td id={section} ref={refs[section].section} className={attribs.class} >
						{parsedChildren}
					</td>
				);
				else return (
					<div id={section} ref={refs[section].section} className={attribs.class} >
						{parsedChildren}
					</div>
				);
			}

			if (name === 'a' && attribs.section && classNames === 'button') {
				const section = attribs.section as NewsletterDraftSection;
				return (
					<span className='show-more-button' onClick={() => { showMoreHandler(refs[section]); }}>
						{domToReact(children!, options)}
					</span>
				);
			}

			return;
		}
	}), [refs]);

	const parseContent: Parse = html => {
		setContent(parse(html, options));
	};

	const openSection: OpenSection = section => {
		showMoreHandler(refs[section]);
		refs[section].section.current!.scrollIntoView();
	};

	return [content, parseContent, openSection];
}

type CreateEditLayer = (attribs: Record<string, string>) => JSX.Element;
type SetUpdatedSectionsHtml = (sections: NewsletterDraftUpdatedSectionsHtml) => void;
type NewsletterDraftUpdatedSections = Partial<{ [index in NewsletterDraftSection]: JSX.Element }>;

export function useNewsletterEditParser(previewHtml: string, createEditLayer: CreateEditLayer): [Content, SetUpdatedSectionsHtml] {
	const [content, setContent] = useState<JSX.Element | null>(null);
	const [updatedSections, setUpdatedSections] = useState<NewsletterDraftUpdatedSections>({});
	const refs = useNewsletterChannelRefs();

	const parseOptions: HTMLReactParserOptions = React.useMemo(() => ({
		trim: true,
		replace: node => {
			const { type, name, attribs, children } = node;
			if (!attribs || type !== 'tag') return;
			const classNames = attribs.class;
			if (!classNames) return;

			if (classNames.match(/show-more-block/)) {
				const section = attribs.type as NewsletterDraftSection;
				return (
					<table className="show-more-block show-more-block-hidden" ref={refs[section].table} >
						{domToReact(children!, parseOptions)}
					</table>
				);
			}

			if ((name === 'td' || name === 'div') && classNames.match(/channel/)) {
				const section = attribs.id as NewsletterDraftSection;
				const parsedChildren = updatedSections[section] || domToReact(children!, parseOptions);

				if (['print', 'blogs', 'online'].includes(section)) return (
					<td id={section} ref={refs[section].section} className={attribs.class}>
						{parsedChildren}
					</td>
				);
				else return (
					<div id={section} ref={refs[section].section} className={attribs.class}>
						{parsedChildren}
					</div>
				);
			}

			if (name === 'a' && attribs.section && classNames === 'button') {
				const section = attribs.section as NewsletterDraftSection;
				return (
					<span className='show-more-button' onClick={() => { showMoreHandler(refs[section]); }}>
						{domToReact(children!, parseOptions)}
					</span>
				);
			}

			if (name === 'div' && classNames.match('doc-anchor')) {
				const className = classNames.replace('doc-anchor', 'doc-layer-created');
				attribs.text = __getDocumentNodeContentText(node);
				return (
					<div className={className}>
						{domToReact(children!, parseOptions)}
						{createEditLayer(attribs)}
					</div>
				);
			}

			return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [updatedSections]);

	React.useEffect(() => {
		setContent(<>{parse(previewHtml, parseOptions)}</>);
	}, [previewHtml, setContent, parseOptions]);

	const setUpdatedSectionsHtml = React.useCallback((updatedSectionsHtml: NewsletterDraftUpdatedSectionsHtml) => {
		setUpdatedSections(transform(updatedSectionsHtml, (result: NewsletterDraftUpdatedSections, sectionHtml, sectionName) => {
			result[sectionName as NewsletterDraftSection] = (<>{parse(sectionHtml!, parseOptions)}</>);
		}, {}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return [content, setUpdatedSectionsHtml];
}

function __getDocumentNodeContentText(node: DomElement): string {
	const type = get(node, 'attribs.type', '');
	if (!type.match('online') && type !== 'blogs') return '';

	const docContent = __findDeep(node, checkNode => {
		const className = get(checkNode, 'attribs.class', '');
		return !!(checkNode.name === 'div' && className.match('doc-content'));
	});
	if (!docContent) return '';
	return get(docContent, 'children.0.children.0.data', '');
}

function __findDeep(node: DomElement, matchFn: (node: DomElement) => boolean): DomElement | undefined {
	if (matchFn(node)) return node;
	if (!node.children || isEmpty(node.children)) return;

	let match: DomElement | undefined;
	forEach(node.children, child => {
		match = __findDeep(child, matchFn);
		if (match) return false;
	});

	return match;
}
