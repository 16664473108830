import ActionCreator, { CrudActions } from 'lib/store/actions';
import { Event } from './model';

const actionCreator = new ActionCreator("@@orm/event");

const crudOperators = actionCreator.crudOperators<Event>();

export const operators = {
	...crudOperators
};

export interface Actions extends CrudActions<Event> {
}
