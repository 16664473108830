import { Action, actionCreatorFactory } from 'typescript-fsa';
import { FiltersFacetGroup, FilterFacet } from 'class/Filter';
import { TopicObject } from 'store/entities/Topic';
import { State as FiltersState } from './reducers';

const actionCreator = actionCreatorFactory('@@search/filters');

type Payloads = {
	ResetSearchFiltersData: void;
	ResetSearchFiltersDMRData: void;
	ResetSearchFiltersDMRAndParkluData: void;
	SetFilters: { filters: FiltersState };
	ToggleFacetFilter: { facetGroupKey: string, facetFilter: FilterFacet };
	RemoveFacetFilterGroup: { facetGroupKey: string };
	SetFacetFilters: { facetsGroups: FiltersFacetGroup };
	SetFacetGroupFilters: { groupKey: string, groupFacetsFiltered: FilterFacet[], doSearch?: boolean };
	ToggleFilteredTopic: { topic: TopicObject<"tags">};
	ToggleFilteredFocus: { focusId: string };
	ToggleFilteredFeed: { focusId: string, feedId: string };
	SetFilteredFocusFeeds: { focus: string[], feeds: string[] };
	RemoveFocusFeedFilter: void;
}

export type Actions = {
	ResetSearchFiltersData: Action<Payloads["ResetSearchFiltersData"]>;
	ResetSearchFiltersDMRData: Action<Payloads["ResetSearchFiltersData"]>;
	ResetSearchFiltersDMRAndParkluData: Action<Payloads["ResetSearchFiltersData"]>;
	SetFilters: Action<Payloads["SetFilters"]>;
	ToggleFacetFilter: Action<Payloads["ToggleFacetFilter"]>;
	RemoveFacetFilterGroup: Action<Payloads["RemoveFacetFilterGroup"]>;
	SetFacetFilters: Action<Payloads["SetFacetFilters"]>;
	SetFacetGroupFilters: Action<Payloads["SetFacetGroupFilters"]>;
	ToggleFilteredTopic: Action<Payloads["ToggleFilteredTopic"]>;
	ToggleFilteredFocus: Action<Payloads["ToggleFilteredFocus"]>;
	ToggleFilteredFeed: Action<Payloads["ToggleFilteredFeed"]>;
	SetFilteredFocusFeeds: Action<Payloads["SetFilteredFocusFeeds"]>;
	RemoveFocusFeedFilter: Action<Payloads["RemoveFocusFeedFilter"]>;
}

export const operators = {
	resetSearchFiltersData: actionCreator<Payloads["ResetSearchFiltersData"]>('RESET_SEARCH_FILTERS_DATA'),
	resetSearchFiltersDMRData: actionCreator<Payloads["ResetSearchFiltersDMRData"]>('RESET_SEARCH_FILTERS_DMR_DATA'),
	resetSearchFiltersDMRAndParkluData: actionCreator<Payloads["ResetSearchFiltersDMRAndParkluData"]>('RESET_SEARCH_FILTERS_DMR_PARKLU_DATA'),
	setFilters: actionCreator<Payloads["SetFilters"]>('SET_FILTERS'),
	toggleFacetFilter: actionCreator<Payloads["ToggleFacetFilter"]>('TOGGLE_FACET_FILTER'),
	removeFacetFilterGroup: actionCreator<Payloads["RemoveFacetFilterGroup"]>('REMOVE_FACET_FILTER_GROUP'),
	setFacetFilters: actionCreator<Payloads["SetFacetFilters"]>('SET_FACET_FILTERS'),
	setFacetGroupFilters: actionCreator<Payloads["SetFacetGroupFilters"]>('SET_FACET_GROUP_FILTERS'),
	toggleFilteredTopic: actionCreator<Payloads["ToggleFilteredTopic"]>('TOGGLE_FILTERED_TOPIC'),
	toggleFilteredFocus: actionCreator<Payloads["ToggleFilteredFocus"]>('TOGGLE_FILTERED_FOCUS'),
	toggleFilteredFeed: actionCreator<Payloads["ToggleFilteredFeed"]>('TOGGLE_FILTERED_FEED'),
	setFilteredFocusFeeds: actionCreator<Payloads["SetFilteredFocusFeeds"]>('SET_FILTERED_FOCUS_FEEDS'),
	removeFocusFeedFilter: actionCreator<Payloads["RemoveFocusFeedFilter"]>('REMOVE_FOCUS_FEED_FILTER')
};
