import { createSelector } from 'reselect';
import { sessionSelector } from "store/entities/selectors";

export const getAllEvents = createSelector(sessionSelector, (_: any) => _,
	({Event}): {[id: string]: string} => {
		const eventObject: {[id: string]: string} = {};
		Event.all().toModelArray().map(event => eventObject[event.id] = event.name);
		return eventObject;
	}
);

export const getEventName = createSelector(sessionSelector, (_: any, id: string) => id,
	({Event}, id): string | undefined => {
		const event = Event.withId(id);
		return event ? event.name : undefined;
	}
);
