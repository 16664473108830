import ActionCreators from 'lib/store/actions';

const actions = new ActionCreators('@@newsletter/emailList/list');

const [createEmailList, createEmailListSuccess, createEmailListError] = actions.apiOperators<{ name: string }>('CREATE_EMAIL_LIST');
const [deleteEmailList, deleteEmailListSuccess, deleteEmailListError] = actions.apiOperators<{ listId: string }>('DELETE_EMAIL_LIST', { reload: true });

export type Actions = {
	CreateEmailList: ReturnType<typeof createEmailList>;
	CreateEmailListSuccess: ReturnType<typeof createEmailListSuccess>;
	CreateEmailListError: ReturnType<typeof createEmailListError>;
	DeleteEmailList: ReturnType<typeof deleteEmailList>;
	DeleteEmailListSuccess: ReturnType<typeof deleteEmailListSuccess>;
	DeleteEmailListError: ReturnType<typeof deleteEmailListError>;
}

export const operators = {
	createEmailList, createEmailListSuccess, createEmailListError,
	deleteEmailList, deleteEmailListSuccess, deleteEmailListError
};
