import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/ui/report/setup';
import setupSelectors from 'store/ui/report/setup/selectors';
import { DispatchProps, StateProps } from './types';

import ReportSetupSettingsLanguageCurrency from './ReportSetupSettingsLanguageCurrency';

const mapStateToProps = (state: State): StateProps => ({
	reportLanguage: setupSelectors.getLanguage(state),
	reportCurrency: setupSelectors.getCurrency(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onChangeLanguage: language => dispatch(operators.changeSettings({ language })),
	onChangeCurrency: currency => dispatch(operators.changeSettings({ currency }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSetupSettingsLanguageCurrency);
