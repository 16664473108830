import React from 'react';
import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import { Button } from 'lib/imports/react-md';

import './AuditPaging.scss';

const AuditPaging = ({ onNextPage, isLoadMoreVisible, t }: ComponentProps) => {

	return (
		<>
			{
				isLoadMoreVisible && <div className="audit-paging-container">
					<Button id='loadMoreAuditsButton' className='next-page-button' flat onClick={() => {
						onNextPage();
					}}>{t('audit_page_load_more_button')}</Button>
				</div>
			}
		</>
	);
};

export default withT(AuditPaging);
