import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { operators as feedOperators } from 'store/focus/feed';
import { operators as focusOperators } from 'store/focus/list';
import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import FocusFeed from './FocusFeed';

const mapStateToProps = ({ focus }: State): StateProps => ({
	focusList: focus.list.focusList,
	feedType: focus.feed.feedType,
	brand_sync: focus.feed.brand_sync,
	loadingSaveFeed: focus.feed.loadingSaveFeed
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onFetchFocusList: () => dispatch(focusOperators.fetchFocusList()),
	onResetFeed: (focusId, focusName, feedType) => dispatch(feedOperators.resetFeed({ focusId, focusName, feedType })),
	onFetchFeed: (focusId, feedId) => dispatch(feedOperators.fetchFeed({ focusId, feedId }))
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusFeed);
