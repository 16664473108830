import { Action, actionCreatorFactory } from 'typescript-fsa';

import { FocusLogoImageMeta } from './reducers';
import { ImageCrop } from 'components/Focus/Dashboard/Logo/Upload/types';

const actionCreator = actionCreatorFactory('@@focus/dashboard/logo');

type Payloads = {
	FetchImageMeta: void;
	FetchImageMetaSuccess: { imageMeta: FocusLogoImageMeta };
	FetchImageMetaError: { readonly error: object };
	RemoveLogo: void;
	RemoveLogoSuccess: void;
	RemoveLogoError: { readonly error: object };
	AcceptFacebook: {
		readonly facebookPageUrl?: string,
		readonly facebookImageUrl?: string
	};
	AcceptUpload: {
		readonly image: File,
		readonly imageCrop: ImageCrop
	};
	AcceptSuccess: void;
	AcceptError: { readonly error: object };
}

export type Actions = {
	FetchImageMeta: Action<Payloads['FetchImageMeta']>;
	FetchImageMetaSuccess: Action<Payloads['FetchImageMetaSuccess']>;
	FetchImageMetaError: Action<Payloads['FetchImageMetaError']>;
	RemoveLogo: Action<Payloads["RemoveLogo"]>;
	RemoveLogoSuccess: Action<Payloads["RemoveLogoSuccess"]>;
	RemoveLogoError: Action<Payloads["RemoveLogoError"]>;
	AcceptFacebook: Action<Payloads["AcceptFacebook"]>;
	AcceptUpload: Action<Payloads["AcceptUpload"]>;
	AcceptSuccess: Action<Payloads["AcceptSuccess"]>;
	AcceptError: Action<Payloads["AcceptError"]>;
}

export const operators = {
	fetchImageMeta: actionCreator<Payloads["FetchImageMeta"]>('FETCH_IMAGE_META', { api: 'start' }),
	fetchImageMetaSuccess: actionCreator<Payloads["FetchImageMetaSuccess"]>('FETCH_IMAGE_META_SUCCESS', { api: 'success' }),
	fetchImageMetaError: actionCreator<Payloads["FetchImageMetaError"]>('FETCH_IMAGE_META_ERROR', { api: 'error' }),
	removeLogo: actionCreator<Payloads["RemoveLogo"]>('REMOVE_LOGO', { api: 'start' }),
	removeLogoSuccess: actionCreator<Payloads["RemoveLogoSuccess"]>('REMOVE_LOGO_SUCCESS', { api: 'success' }),
	removeLogoError: actionCreator<Payloads["RemoveLogoError"]>('REMOVE_LOGO_ERROR', { api: 'error' }),
	acceptFacebook: actionCreator<Payloads["AcceptFacebook"]>('ACCEPT_FACEBOOK', { api: 'start' }),
	acceptUpload: actionCreator<Payloads["AcceptUpload"]>('ACCEPT_UPLOAD', { api: 'start' }),
	acceptSuccess: actionCreator<Payloads["AcceptSuccess"]>('ACCEPT_SUCCESS', { api: 'success' }),
	acceptError: actionCreator<Payloads["AcceptError"]>('ACCEPT_ERROR', { api: 'error' })
};
