import React, { useRef } from "react";
import { JWTStorage } from "lib/storage";
import { ItemProps } from "components/common/ButtonWithDropdown/ButtonWithDropdown";
import { FontIcon } from "react-md";

export default function DashboardDropDown({ filters, item }: { filters: any, item: ItemProps }) {
	const jwtRef = useRef<HTMLInputElement>(null);
	const insightsFiltersRef = useRef<HTMLInputElement>(null);
	const insightsForm = useRef<HTMLFormElement>(null);

	const handleSubmit = (event: any) => {
		event.preventDefault();
		item.onClick && item.onClick();

		jwtRef.current!.value = JWTStorage.getToken('insights')!;

		insightsFiltersRef.current!.value = JSON.stringify(filters);

		if (insightsForm && insightsForm.current) insightsForm.current.submit();
	};

	return (
		<form
			id="insights-form"
			ref={insightsForm}
			method="POST"
			action={process.env.REACT_APP_INSIGHTS_URL!}
			onClick={handleSubmit}
			target="_blank"
		>
			<input type="hidden" ref={jwtRef!} id="insights-jwt" name="jwt" />
			<input type='hidden' ref={insightsFiltersRef} id='insights-filters' name='filters' />
			<div>
				<button disabled={item.disabled} className='dropdown-item-content' type="submit" id={item.id}>
					<div className="dropdown-item-info">
						<div className="dropdown-item-icon"><FontIcon>{item.icon}</FontIcon></div>
						<p>{item.label}</p>
						<span className="beta">beta</span>
					</div>
					<div className="dropdown-item-link-icon"><FontIcon>open_in_new</FontIcon></div>
				</button>
			</div>
		</form>
	);
};
