import { Dispatch } from "redux";
import { State } from "store/types";
import { StateProps } from "./types";
import { connect } from "react-redux";
import EventPicker from "./EventPicker";
import { DispatchProps } from "./types";

const mapStateToProps = (state: State): StateProps => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EventPicker);