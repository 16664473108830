import React from 'react';

import { Button, FontIcon } from 'lib/imports/react-md';
import { withT } from 'lib/i18n';
import { map } from 'lib/imports/lodash';
import Dialog from 'components/common/dialog';

import { allPermissions } from 'store/entities/FacebookUser';

import { ComponentProps } from './types';

import './FacebookAuthInstagram.scss';

const FacebookAuthInstagram = (props: ComponentProps) => {
	const { dialogMode, permissionsMissing, t } = props;
	const { onSetDialogMode, onUpdateAuth, onDeauthorize } = props;

	const onAuthenticated = React.useCallback(({ status, authResponse }: fb.StatusResponse) => {
		if (status === 'connected') onUpdateAuth(authResponse);
		onSetDialogMode('closed');
	}, [onUpdateAuth, onSetDialogMode]);

	React.useEffect(() => {
		if (dialogMode === 'instagram_auth') {
			window.FB.login(onAuthenticated, {
				scope: allPermissions.join(','),
				enable_profile_selector: true
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialogMode]);

	// authorization dialog

	const authDialog = React.useMemo(() => {
		if (dialogMode !== 'instagram') return null;
		return (
			<Dialog
				id="instagramLoginDialog"
				icon={<FontIcon iconClassName="icon-instagram" />}
				title={t('facebook.instagram.auth.title')}
				content={(
					<>
						<div className="dialog-text">
							<p>{t('facebook.instagram.auth.description')}</p>
						</div>
						<div className="instagram-login">
							<Button id="settingsUserAddInstagramButton" flat
								onClick={() => onSetDialogMode('instagram_auth')}
							>
								<span>{t('facebook.instagram.auth.continue')}</span>
							</Button>
						</div>
					</>
				)}
				onCancel={() => onSetDialogMode('closed')}
			/>
		);
	}, [dialogMode, onSetDialogMode, t]);

	// Remove dialog

	const onAcceptRemove = React.useCallback(() => {
		if (dialogMode === 'instagram_auth_remove') onUpdateAuth();
		else if (dialogMode === 'instagram_deauthorize_remove') onDeauthorize();
		onSetDialogMode('closed');
	}, [dialogMode, onSetDialogMode, onUpdateAuth, onDeauthorize]);

	const onCancelRemove = React.useCallback(() => {
		if (dialogMode === 'instagram_auth_remove') onSetDialogMode('instagram_auth');
		else if (dialogMode === 'instagram_deauthorize_remove') onSetDialogMode('closed');
	}, [dialogMode, onSetDialogMode]);

	const removeDialog = React.useMemo(() => {
		if (dialogMode !== 'instagram_auth_remove' && dialogMode !== 'instagram_deauthorize_remove') return null;
		return (
			<Dialog
				id="removeInstagramDialog"
				icon={<FontIcon>warning</FontIcon>}
				title={t('facebook.instagram.remove.title')}
				content={(
					<div className="dialog-text">
						<p>{t('facebook.instagram.remove.description')}</p>
					</div>
				)}
				onAcceptText={t('facebook.instagram.remove.disconnect')}
				onCancelText={t('facebook.instagram.remove.cancel')}
				onAccept={onAcceptRemove}
				onCancel={onCancelRemove}
			/>
		);
	}, [dialogMode, onAcceptRemove, onCancelRemove, t]);

	// Permissions missing dialog

	const onAcceptPermissionsMissing = React.useCallback(() => {
		onSetDialogMode('instagram_auth');
	}, [onSetDialogMode]);

	const onCancelPermissionsMissing = React.useCallback(() => {
		if (dialogMode === 'auth_permissions_missing') onUpdateAuth();
		onSetDialogMode('closed');
	}, [dialogMode, onSetDialogMode, onUpdateAuth]);

	const permissionsDialog = React.useMemo(() => {
		if (dialogMode !== 'auth_permissions_missing' && dialogMode !== 'check_permissions_missing') return null;
		return <Dialog
			id="permissionsMissingDialog"
			icon={<FontIcon>warning</FontIcon>}
			title={t('facebook.instagram.permissions_missing.title')}
			content={(
				<div id="permissionsContent">
					<div className="dialog-text">
						<p>{t('facebook.instagram.permissions_missing.description')}</p>
						<p>{t('facebook.instagram.permissions_missing.description_2')}:</p>
					</div>
					<ul>
						{map(permissionsMissing, permission => (
							<li key={`permission-${permission}`}>{permission.replace(/_/g, ' ')}</li>
						))}
					</ul>
				</div>
			)}
			onAcceptText={t('facebook.instagram.permissions_missing.update')}
			onCancelText={t('facebook.instagram.permissions_missing.continue')}
			onAccept={onAcceptPermissionsMissing}
			onCancel={onCancelPermissionsMissing}
		/>;
	}, [dialogMode, permissionsMissing, onAcceptPermissionsMissing, onCancelPermissionsMissing, t]);

	const instagramWebhookErrorDialog = React.useMemo(() => {
		if (dialogMode !== 'instagram_webhook_failed') return null;
		return <Dialog
			id="instagramWebhookErrorDialog"
			icon={<FontIcon>warning</FontIcon>}
			title={t('facebook.instagram.webhook.failed_title')}
			content={(
				<div id="permissionsContent">
					<div className="dialog-text">
						<p>{t('facebook.instagram.webhook.failed_text_1')}</p>
						<p>{t('facebook.instagram.webhook.failed_text_2')}</p>
					</div>
				</div>
			)}
			onCancelText={'Close'}
			onCancel={() => onSetDialogMode('closed')}
		/>;
	}, [t, dialogMode, onSetDialogMode]);

	return (<>
		{authDialog}
		{removeDialog}
		{permissionsDialog}
		{instagramWebhookErrorDialog}
	</>);
};

export default withT(React.memo(FacebookAuthInstagram));
