import transform from 'lodash/transform';
import concat from 'lodash/concat';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';

import { Feed, FeedObject } from "./Feed";
import { OldestDocumentObject } from "./Document";

// TODO: Remove this class and move type to entities types when all application uses ORM
export type DiscoverRole = "viewer" | "contributor" | "manager" | "clipper";

export type FocusFeeds = {
	online: Feed[]
	socialmedia: Feed[]
	print: Feed[]
	print_dmr: Feed[]
};

export type ACLUser = {
	user_id: string
	discover_role: DiscoverRole,
	last_name: string
	first_name: string
};

export class FocusObject {
	public id: string;
	public name: string;
	public url_logo: string;
	public inserted_at: string;
	public updated_at: string;
	public deleted_at: string;
	public acl_users?: ACLUser[];
	public feeds?: FocusFeeds;
	public oldest_document?: OldestDocumentObject;

	constructor(values?: Partial<FocusObject>) {
		if (values) Object.assign(this, values);
	}
}

export class Focus extends FocusObject {

	public getFeeds() {
		let feeds: FeedObject[] = [];
		if (this.feeds) {
			if (this.feeds.online) feeds = concat(feeds, this.feeds.online);
			if (this.feeds.socialmedia) feeds = concat(feeds, this.feeds.socialmedia);
			if (this.feeds.print) feeds = concat(feeds, this.feeds.print);
			if (this.feeds.print_dmr) feeds = concat(feeds, this.feeds.print_dmr);
		}
		return orderBy(feeds, feed => feed.name.toLowerCase());
	}

	public static transformEntitiesResponse(feeds: Feed[]) {
		return transform(feeds, (result, feed) => {
			result[feed.type].push(feed);
			return result;
		}, { online: [], socialmedia: [], print: [], print_dmr: [] } as FocusFeeds);
	}

	public static findFeedInFocusList = (feedId: string, focusList: FocusObject[]): FeedObject | null => {
		for (const focus of focusList) {
			if (focus.feeds) {
				const feeds: FeedObject[] = concat(focus.feeds.online, focus.feeds.socialmedia, focus.feeds.print, focus.feeds.print_dmr);
				const feed = find(feeds, { id: feedId });
				if (feed) return feed;
			}
		}
		return null;
	};

}
