import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';

import FocusFeedDefinitionLayoutContent from './FocusFeedDefinitionLayoutContent';

const mapStateToProps = (state: State): StateProps => ({
	brand_sync: state.focus.feed.brand_sync
});

const mapDispatchToProps = (): DispatchProps => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedDefinitionLayoutContent);
