import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { merge, keyBy, map } from 'lib/imports/lodash';
import { ReportJob } from './types';

import { Actions, operators } from './actions';

export type State = {
	jobs: ReportJob[]
	jobsLoaded: boolean
};

export const INITIAL_STATE: State = {
	jobs: [],
	jobsLoaded: false
};

const reducerHandlers: ReducerHandlers<State> = {
	fetchFocusJobs: (state): State => ({
		...state,
		jobs: [],
		jobsLoaded: false
	}),
	fetchFocusJobsSuccess: (state, { payload: jobs }: Actions['FetchFocusJobsSuccess']): State => ({
		...state,
		jobs,
		jobsLoaded: true
	}),
	fetchFocusJobsError: (state): State => ({
		...state,
		jobsLoaded: true
	}),
	clearJobs: (state): State => ({
		...state,
		jobs: [],
		jobsLoaded: true
	}),
	fetchJobStatusSuccess: (state, { payload: jobs }: Actions['FetchJobStatusSuccess']): State => {
		const jobsToUpdate = keyBy(jobs, 'id');
		return {
			...state,
			jobs: map(state.jobs, job => {
				const jobToUpdate = jobsToUpdate[job.id];
				if (jobToUpdate) return merge({}, job, jobToUpdate);
				return job;
			})
		};
	}
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
