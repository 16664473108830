import { Action, actionCreatorFactory } from 'typescript-fsa';

import { State as FormState } from './reducers';
import { SearchPeriod, SearchDateType } from 'types/search/form';

const actionCreator = actionCreatorFactory('@@search/form');

type Payloads = {
	ResetSearchFormData: void;
	Submit: void;
	SetForm: { form: FormState; };
	SetSort: { sort: string; };
	SetQuery: { query: string; };
	SetLimit: { limit: number; };
	SetPeriod: { period: SearchPeriod; };
	SetBeginDate: { date: Date; };
	SetEndDate: { date: Date; };
	SetDateType: { type: SearchDateType; },
	SetDateTypeFilter: { type: SearchDateType; },
	SetPeriodAndDatesRange: { period: SearchPeriod, begin_date?: Date, end_date?: Date; };
	SetStart: { start: number; };
	GetInsightsFilters: void;
	SetInsightsFilters: { filters: any; };
};

export type Actions = {
	ResetSearchFormData: Action<Payloads["ResetSearchFormData"]>;
	Submit: Action<Payloads["Submit"]>;
	SetForm: Action<Payloads["SetForm"]>;
	SetSort: Action<Payloads["SetSort"]>;
	SetQuery: Action<Payloads["SetQuery"]>;
	SetLimit: Action<Payloads["SetLimit"]>;
	SetPeriod: Action<Payloads["SetPeriod"]>;
	SetBeginDate: Action<Payloads["SetBeginDate"]>;
	SetEndDate: Action<Payloads["SetEndDate"]>;
	SetDateType: Action<Payloads["SetDateType"]>;
	SetDateTypeFilter: Action<Payloads["SetDateTypeFilter"]>;
	SetPeriodAndDatesRange: Action<Payloads["SetPeriodAndDatesRange"]>;
	SetStart: Action<Payloads["SetStart"]>;
	GetInsightsFilters: Action<Payloads["GetInsightsFilters"]>;
	SetInsightsFilters: Action<Payloads["SetInsightsFilters"]>;
};

export const operators = {
	resetSearchFormData: actionCreator<Payloads["ResetSearchFormData"]>('RESET_SEARCH_FORM_DATA'),
	submit: actionCreator<Payloads["Submit"]>('SUBMIT'),
	setForm: actionCreator<Payloads["SetForm"]>('SET_FORM'),
	setSort: actionCreator<Payloads["SetSort"]>('SET_SORT'),
	setQuery: actionCreator<Payloads["SetQuery"]>('SET_QUERY'),
	setLimit: actionCreator<Payloads["SetLimit"]>('SET_LIMIT'),
	setPeriod: actionCreator<Payloads["SetPeriod"]>('SET_PERIOD'),
	setBeginDate: actionCreator<Payloads["SetBeginDate"]>('SET_BEGIN_DATE'),
	setEndDate: actionCreator<Payloads["SetEndDate"]>('SET_END_DATE'),
	setDateType: actionCreator<Payloads["SetDateType"]>('SET_DATE_TYPE'),
	setDateTypeFilter: actionCreator<Payloads["SetDateTypeFilter"]>('SET_DATE_TYPE_FILTER'),
	setPeriodAndDatesRange: actionCreator<Payloads["SetPeriodAndDatesRange"]>('SET_DATES_RANGE'),
	setStart: actionCreator<Payloads["SetStart"]>('SET_START'),
	getInsightsFilters: actionCreator<Payloads["GetInsightsFilters"]>('GET_INSIGHTS_FILTER'),
	setInsightsFilters: actionCreator<Payloads["SetInsightsFilters"]>('SET_INSIGHTS_FILTERS')
};
