import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';

import './ArticleInsertBulk.scss';
import { Button, SelectField, SelectionControl } from 'lib/imports/react-md';
import { Focus } from 'class/Focus';
import { Dropdown } from 'components/common/Dropdown';
import Dropzone from 'components/common/Dropzone';

type SelectItem = {label: string, id: string, checked?: boolean, isFocus?: boolean};

const ArticleInsertBulk = (props: ComponentProps) => {
	const { type, focusList, file, templateBlob } = props;
	const { t, onSetFile, onGetTemplate, onSetTemplateBlob } = props;

	const [selectedFeeds, setSelectedFeeds]: [SelectItem[], any] = useState([]);
	const [fileError, setFileError]: [string | null, any] = useState(null);
	const [isFeedDropdownOpen, setIsFeedDropdownOpen] = useState(false);

	const handleFile = useCallback(files => {
		onSetFile(files[0]);
		setFileError(null);
	}, [onSetFile, setFileError]);

	const handleFileError = useCallback(errors => {
		switch (errors[0].error) {
			case "incorrect_file_type":
				setFileError(t('insert_article.modal.bulk.incorrect_file_type'));
				break;
		}
	}, [t, setFileError]);

	const feedList = useMemo((): JSX.Element => {
		const selectedFeedIds = new Set(selectedFeeds.map(feed => feed.id));
		const selectItems: SelectItem[] = [];
		for (const focus of focusList || []) {
			const focusFeeds = new Focus(focus).getFeeds();
			// TODO: Handled using `.includes` to join print/print_dmr, test with `!==` when old print removed
			let actualType = type;
			if (type === 'print') actualType = 'print_dmr';
			const filteredFocusFeeds = focusFeeds.filter(feed => feed.type === actualType);
			if (!filteredFocusFeeds.length) continue;

			selectItems.push({isFocus: true, label: focus.name, id: focus.id});
			filteredFocusFeeds.map(feed => selectItems.push({label: feed.name, id: feed.id, checked: selectedFeedIds.has(feed.id)}));
		}

		const onCheck = (item: SelectItem) => {
			if (!item.checked) return setSelectedFeeds([...selectedFeeds, item]);
			// @ts-ignore
			for (const [idx, feed] of selectedFeeds.entries()) {
				if (feed.id !== item.id) continue;
				const newSelectedFeeds = [...selectedFeeds];
				newSelectedFeeds.splice(idx, 1);
				return setSelectedFeeds(newSelectedFeeds);
			}
		};

		return (
			<div className='feed-list'>
				{selectItems.map((item, idx) =>
					item.isFocus
						? <span key={idx} className='focus-row'>{item.label}</span>
						: <span key={idx} className='feed-row'>
							<SelectionControl
								label={item.label}
								type="checkbox"
								checked={item.checked}
								onChange={() => onCheck(item)}
								onClick={e => e.stopPropagation()}
								className={item.checked ? "checkbox-green" : ""}
								id={`dropdownItem-${item.id}`}
								key={`dropdownItem-${item.id}-${item.checked}`}
								name={`dropdownItem-${item.id}`}
							/>
						</span>
				)}
			</div>
		);
	}, [focusList, selectedFeeds, type, setSelectedFeeds]);

	useEffect(() => {
		if (templateBlob) {
			const url = window.URL.createObjectURL(new Blob([templateBlob]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `bulk-insert-${type}-${Date.now()}.xlsx`);
			document.body.appendChild(link);
			link.click();
			onSetTemplateBlob(null);
		}
	});

	const dropdownTarget = useMemo(() => {
		let placeholder;
		if (selectedFeeds.length) placeholder = selectedFeeds.length === 1 ? selectedFeeds[0].label : `${selectedFeeds.length} ${t('insert_article.modal.bulk.selected_feeds')}`;
		else placeholder = t('insert_article.modal.bulk.select_feeds');

		return (<SelectField
			id="feedSelect"
			fullWidth={true}
			placeholder={placeholder}
			onClick={() => setIsFeedDropdownOpen(true)}
		/>);
	}, [selectedFeeds, t, setIsFeedDropdownOpen]);

	const fileZone = useMemo(() => file ? 
		<div id="droppedFile" className='dropped-file'>
			{file.name}
			<Button className="close-btn btn--no-background" icon primary onClick={() => onSetFile(null)}>clear</Button>	
		</div>
		: <Dropzone
			files={[]}
			multiple={false}
			accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			maxSize={15000000}
			onFilesAdded={files => handleFile(files)}
			onAddFilesError={errors => handleFileError(errors)}
			onRemoveFile={() => onSetFile(null)}
		></Dropzone>
	, [file, handleFile, handleFileError, onSetFile]);

	return (
		<div className='bulk-steps'>
			<div className='step'>
				<span className='step-title'>{t('insert_article.modal.bulk.step_1')}</span>
				<span className='action-text'>{t('insert_article.modal.bulk.download_excel')}</span>
				<Dropdown
					id="bulkFeedDropdown"
					onClose={() => setIsFeedDropdownOpen(false)}
					isOpen={isFeedDropdownOpen}
					target={dropdownTarget}
					children={feedList}
				></Dropdown>
				<Button flat className='download-btn' onClick={() => onGetTemplate(selectedFeeds.map(feed => feed.id), type)}>
					{t('insert_article.modal.bulk.download_template')} {t(`type.${type}`)}
				</Button>
			</div>
			<div className='split'></div>
			<div className='step'>
				<span className='step-title'>{t('insert_article.modal.bulk.step_2')}</span>
				<span className='action-text'>{t('insert_article.modal.bulk.upload_excel')}</span>
				{fileZone}
				{fileError && <div className='file-error'>{t(fileError)}</div>}
			</div>
		</div>
	);
};

export default withT(ArticleInsertBulk);
