import React, { Fragment } from "react";
import { withT } from "lib/i18n";

import { Autocomplete, ListItem, FontIcon, Subheader, Collapse } from 'lib/imports/react-md';
import { transform, filter, isEmpty, includes } from 'lib/imports/lodash';

import { TagObject } from "store/entities/Tag";
import { TopicObject } from "store/entities/Topic";

import { ComponentProps } from "./types";

import "./TopicTag.scss";

type TopicsFolding = Record<string, boolean>;
type TopicsFoldingReducer = (state: TopicsFolding, action: { topics: TopicObject<"tags">[], value: boolean }) => TopicsFolding;
const topicsFoldingReducer: TopicsFoldingReducer = (state, { topics, value }) => {
	const newTopicsFolding = transform(topics, (result: TopicsFolding, topic) => { result[topic.id] = value; }, {});
	return { ...state, ...newTopicsFolding };
};

const TopicTag = (props: ComponentProps) => {
	const { availableTopicTags, selectedTags, allowCreate = true, t } = props;
	const { onAddTags } = props;

	const filteredAvailableTopicsWithoutTags = React.useMemo(() => filter(availableTopicTags, topic => !isEmpty(topic.tags)), [availableTopicTags]);
	const [topicsFolding, setTopicsFolding] = React.useReducer(topicsFoldingReducer, topicsFoldingReducer({}, { topics: availableTopicTags, value: true }));
	const [filteredTags, setFilteredTags] = React.useState(filteredAvailableTopicsWithoutTags);
	const [inputTagText, setInputTagText] = React.useState<string>("");

	const tagsItemList = React.useCallback((topic: TopicObject<"tags">) => topic.tags.map(tag => {
		const isListItemDisabled = includes(selectedTags.tagIds, `${topic.id}_${tag.id}`);

		let textFragments: JSX.Element[] = [<Fragment key="text">{tag.name}</Fragment>];
		if (inputTagText) {
			const splittedName = tag.name.split(inputTagText);
			textFragments = transform(splittedName, (result: typeof textFragments, textPart: string, index: number) => {
				result.push(<Fragment key={`text-${index}`}>{textPart}</Fragment>);
				result.push(<span key={`highlight-${index}`} className="tag-item-highlight">{inputTagText}</span>);
			}, []);
			textFragments.pop(); // remove last one
		}

		return <ListItem
			key={`topic${topic.id}_tag${tag.id}`}
			id={`topic${topic.id}_tag${tag.id}`}
			className="tag-item"
			disabled={isListItemDisabled}
			primaryText={textFragments}
			onClick={() => onAddTags([`${topic.id}_${tag.id}`], [])}
		/>;
	}), [inputTagText, onAddTags, selectedTags.tagIds]);

	const baseAutocompleterData = React.useMemo(() => filteredTags.map(topic =>
		(
			<div key={`topic${topic.id}`}>
				<Subheader
					id={`collapseTopic${topic.id}`}
					className="topic-header"
					primaryText={topic.name}
					onClick={() => setTopicsFolding({ topics: [topic], value: !topicsFolding![topic.id] })}
					children={topicsFolding![topic.id] ?
						<FontIcon className="topic-tag-folder" >keyboard_arrow_right</FontIcon> :
						<FontIcon className="topic-tag-folder">keyboard_arrow_down</FontIcon>}
				/>
				<Collapse collapsed={topicsFolding![topic.id]}>
					<>
						{tagsItemList(topic)}
					</>
				</Collapse>
			</div>
		)), [filteredTags, topicsFolding, tagsItemList]);

	const autocompleterData = React.useMemo(() => {
		if (!allowCreate || !inputTagText) return baseAutocompleterData;
		const newTagItem = <ListItem
			key="newTagItem"
			id="newTagItem"
			className="tag-item"
			primaryText={`${inputTagText} (new)`}
			onClick={() => onAddTags([], inputTagText.split(','))}
		/>;
		return [newTagItem, ...baseAutocompleterData];
	}, [inputTagText, onAddTags, allowCreate, baseAutocompleterData]);

	return (
		<>
			<div id="categorizationTopicTag" className="categorization-tags">
				<span className="categorization-tags-label">{t('results.categorize.label.tags')}</span>
				<Autocomplete
					id="tagsAutocompleter"
					className="categorization-tags-input"
					data={autocompleterData}
					fullWidth={false}
					clearOnAutocomplete={true}
					label={t('results.categorize.tags.placeholder')}
					showUnfilteredData={true}
					onAutocomplete={() => {
						setInputTagText("");
						setFilteredTags(availableTopicTags);
						return setTopicsFolding({ topics: availableTopicTags, value: true });
					}}
					onChange={text => {
						setInputTagText(text);
						if (isEmpty(text)) {
							setFilteredTags(availableTopicTags);
							return setTopicsFolding({ topics: availableTopicTags, value: true });
						}

						const filteredTagsFromInput: TopicObject<"tags">[] = transform(availableTopicTags, (result: TopicObject<"tags">[], topic: any) => {
							const tags: TagObject[] = filter(topic.tags, tag => tag.name.match(text)) as TagObject[];
							if (!isEmpty(tags)) result.push({ ...topic, tags });
						}, []);

						setFilteredTags(filteredTagsFromInput);
						setTopicsFolding({ topics: filteredTagsFromInput, value: false });
					}}
				/>
			</div>
		</>
	);
};

export default withT(React.memo(TopicTag));
