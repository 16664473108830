import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { operators as focusFeedOperators } from 'store/focus/feed';
import { operators } from 'store/focus/feed/definition/print';
import { PrintExpressionType } from 'class/Feed';
import { operators as notificationOperators } from 'store/app/notifications';
import { DispatchProps, StateProps } from './types';
import FocusFeedDefinitionPrint from './FocusFeedDefinitionPrint';

const mapStateToProps = (): StateProps => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onFetchSearch: () => dispatch(focusFeedOperators.fetchSearch()),
	onRemoveExpression: (type: PrintExpressionType, index: number) => dispatch(operators.removePrintExpression({ type, index })),
	onAddNotification: notification => dispatch(notificationOperators.add({ notification }))
});


export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedDefinitionPrint);
