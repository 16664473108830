import React from 'react';
import { Button } from 'lib/imports/react-md';

import { ComponentProps } from './types';

import './FocusCard.scss';

const FocusCard = (props: ComponentProps) => {
	const { focus, children } = props;
	const { onClear } = props;

	const componentId = React.useMemo(() => `focusCard_${focus.id}`, [focus]);

	return (
		<div id={componentId} key={componentId} className="focus-card">
			<div className="focus-card-logo">
				<img className="focus-card-logo-img" src={focus.url_logo} alt='' />
			</div>
			<div className="focus-card-content">
				<div className="focus-card-name">
					{focus.name}
					{onClear && (
						<Button id={componentId + "_clearButton"} className={'focus-card-clear-button'} icon
							onClick={() => onClear(focus.id)}
						>clear</Button>
					)}
				</div>
				<div className="children">
					{children}
				</div>
			</div>
		</div>
	);
};

export default React.memo(FocusCard);
