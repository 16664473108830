import React from 'react';
import DialogContainer from 'react-md/lib/Dialogs/DialogContainer';
import Button from 'react-md/lib/Buttons/Button';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';
import { get } from 'lib/imports/lodash';

import { withT } from 'lib/i18n';
import { ComponentProps, FocusLogoDialogStep } from './types';
import FacebookImageInput from 'components/common/facebook/ImageInput';
import FocusDashboardLogoUpload from 'components/Focus/Dashboard/Logo/Upload';
import { OnFacebookUrlsParams } from 'components/common/facebook/ImageInput/types';
import { ImageCrop } from 'components/Focus/Dashboard/Logo/Upload/types';

import './FocusDashboardLogoDialog.scss';

const FocusDashboardLogoDialog = (props: ComponentProps) => {
	const { t, imageMeta, loading } = props;
	const { onFetchImageMeta, onRemoveLogo, onAcceptFacebook, onAcceptUpload } = props;
	const { onClose } = props;

	const [step, setStep] = React.useState<FocusLogoDialogStep>("default");
	const [facebookUrls, setFacebookUrls] = React.useState<OnFacebookUrlsParams | null>(null);
	const [uploadImage, setUploadImage] = React.useState<File | null>(null);
	const [uploadImageCrop, setUploadImageCrop] = React.useState<ImageCrop | null>(null);
	const onShowFacebook = React.useCallback(() => setStep("use_facebook"), [setStep]);
	const onShowUploadImage = React.useCallback(() => setStep("upload_image"), [setStep]);
	const onFacebookUrlsUpdated = React.useCallback((urls: OnFacebookUrlsParams) => setFacebookUrls(urls), [setFacebookUrls]);
	const onRemoveLogoCallback = React.useCallback(() => {
		onRemoveLogo();
		onClose();
	}, [onClose, onRemoveLogo]);
	const onAcceptLogoCallback = React.useCallback(() => {
		if (step === "use_facebook") onAcceptFacebook(get(facebookUrls, 'pageUrl')!, get(facebookUrls, 'imageUrl')!);
		else if (step === "upload_image") onAcceptUpload(uploadImage!, uploadImageCrop!);
		onClose();
	}, [onClose, facebookUrls, step, uploadImageCrop, uploadImage, onAcceptFacebook, onAcceptUpload]);

	React.useEffect(() => { onFetchImageMeta(); }, []);// eslint-disable-line react-hooks/exhaustive-deps
	React.useEffect(() => {
		if (imageMeta) {
			if (imageMeta.url) {
				setFacebookUrls({ pageUrl: imageMeta.url, imageUrl: "" });
				setStep("use_facebook");
			} else if (imageMeta.custom) {
				setStep("upload_image");
			} else setStep('default');
		} else setStep('default');
	}, [imageMeta]);

	const content = React.useMemo(() => {
		if (loading) return <CircularProgress id="focusDashboardLogoDialogLoading" />;
		else if (step === "default") return <>
			<p>{t('focus.uploadLogo.description')}</p>
			<div className="buttons">
				<Button id="focusLogoDialogShowFacebookBtn" className="btn--grey btn--no-background" flat onClick={onShowFacebook}>
					<FontIcon iconClassName="icon-facebook-square" />
					{t('focus.uploadLogo.facebook')}
				</Button>
				<Button id="focusLogoDialogShowUploadBtn" className="btn--grey btn--no-background" flat onClick={onShowUploadImage}>
					<FontIcon iconClassName="icon-add_photo_alternate" />
					{t('focus.uploadLogo.upload')}
				</Button>
			</div>
		</>;
		else if (step === "use_facebook") return (
			<div id="focusDashboardLogoFacebook">
				<p>{t('focus.uploadLogo.facebookDescription')}</p>
				<FacebookImageInput url={get(facebookUrls, 'pageUrl') || ""} helpText={t('focus.uploadLogo.facebook_help')} onFacebookUrls={onFacebookUrlsUpdated} />
			</div>
		);
		else if (step === "upload_image") return <FocusDashboardLogoUpload onImageUpdated={setUploadImage} onSetUploadImageCrop={setUploadImageCrop} />;
	}, [t, loading, step, facebookUrls, onShowFacebook, onShowUploadImage, onFacebookUrlsUpdated, setUploadImage]);

	const buttons = [
		<Button id="focusLogoDialogCancelBtn" className="btn--grey btn--no-background" flat onClick={onClose}>{t('form.cancel')}</Button>
	];

	if (step === "use_facebook" || step === "upload_image") {
		const acceptEnabled = !!(get(facebookUrls, 'pageUrl') || (uploadImage && uploadImageCrop));
		if (imageMeta && !imageMeta.default)
			buttons.push(<Button id="focusLogoDialogRemoveLogoBtn" className="btn--grey btn--no-background" flat onClick={onRemoveLogoCallback}>{t('focus.uploadLogo.remove')}</Button>);
		buttons.push(<Button id="focusLogoDialogAcceptLogoBtn" className="btn--grey btn--no-background" flat onClick={onAcceptLogoCallback} disabled={!acceptEnabled}>{t('form.accept')}</Button>);
	}

	let titleSuffix = "";
	if (step === "use_facebook") titleSuffix = ` > ${t('focus.uploadLogo.title.facebook')}`;
	else if (step === "upload_image") titleSuffix = ` > ${t('focus.uploadLogo.title.custom')}`;

	return <DialogContainer
		id="focusDashboardLogoDialog"
		aria-describedby="focusLogoDialog"
		title={t('focus.uploadLogo.title.value') + titleSuffix}
		visible={true}
		onHide={onClose}
		actions={buttons}
		children={content}
		portal
		modal
	/>;
};

export default withT(React.memo(FocusDashboardLogoDialog));
