import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import newsletterSelectors from 'store/ui/newsletter/edit/selectors';
import { operators as notificationOperators } from 'store/app/notifications';

import NewsletterEditFormRecipients from './NewsletterEditFormRecipients';

const mapStateToProps = (state: State): StateProps => ({
	ormEmailLists: newsletterSelectors.getEmailLists(state) || []
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onAddNotification: notification => dispatch(notificationOperators.add({ notification }))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterEditFormRecipients);
