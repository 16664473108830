import React from 'react';
import featureFlags from 'lib/featureFlags';
import ButtonWithDropdown from 'components/common/ButtonWithDropdown';
import { FontIcon } from 'react-md';
import DashboardDropDown from './Export/DashboardDropDown';
import ReportConfirmationDialog from 'components/Report/ConfirmationDialog/ReportConfirmationDialog';

export function ExportDropDownMenu(
	{
		total,
		user,
		showExport,
		quickReportEnabled,
		reportConfirmationDialogEnbaled = false,
		reportedFocusNames,
		filters,
		handleQuickReport,
		setReportConfirmationDialogEnabled,
		onSetReportedFocusNames
	}: {
		total: number,
		user: any,
		showExport: boolean,
		quickReportEnabled: boolean,
		reportConfirmationDialogEnbaled?: boolean
		setReportConfirmationDialogEnabled: (enabled: boolean) => void
		handleQuickReport: () => void,
		reportedFocusNames: string[],
		filters: any
		onSetReportedFocusNames: (names: string[]) => void
	}) {

	const textKeys = total !== 0 && reportedFocusNames.length > 0 ? {
		title: 'reports.confirmation_message_1',
		content: 'reports.confirmation_message_2_article',
		footer: 'reports.confirmation_message_3'
	} : {
		title: 'reports.confirmation_reject_message_1',
		content: 'reports.confirmation_reject_message_2',
		footer: ''
	};

	return (
		<>

			<ReportConfirmationDialog
				enabled={reportConfirmationDialogEnbaled}
				onCancel={() => {
					setReportConfirmationDialogEnabled(false);
					onSetReportedFocusNames([]);
				}}
				textKeys={textKeys}
				focuses={reportedFocusNames}
				type='excel'
				user={user}
			/>
			{
				showExport && (
					<div>
						<ButtonWithDropdown text={'Analyze & Share'} id='analyze-and-share' items={[
							{
								hide: !featureFlags.isEnabled('new-dashboard'),
								component: <DashboardDropDown
									filters={filters}
									item={{
										id: 'analyse-and-share-dashboard',
										disabled: !quickReportEnabled,
										label: 'Dashboard',
										icon: <FontIcon >insert_chart_outlined</FontIcon>
									}} />
							},
							{
								id: 'analyse-and-search-export-excel',
								label: 'Export to Excel',
								onClick: handleQuickReport,
								disabled: !quickReportEnabled,
								icon: <FontIcon >save_alt</FontIcon>
							}
						]} />
					</div>
				)}
		</>
	);

}
