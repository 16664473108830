import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/focus/list';
import { operators as profileOperators } from 'store/app/profile';
import { DispatchProps, StateProps } from './types';
import FocusListManageUsers from './ManageUsers';
import { FocusObject } from 'class/Focus';

const mapStateToProps = ({ app: { profile: { tenant } }, focus: { list } }: State): StateProps => ({
	focusList: list.focusList as FocusObject[],
	tenantUsers: tenant!.users,
	loadingUsersIds: list.manageFocusLoadingUsers
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onFecthTenantUsers: () => dispatch(profileOperators.fetchTenantUsers()),
	onToggleAllowedUser: (focusId, user, allow) => dispatch(operators.updateFocusUserAllowAccess({ focusId, user, allow }))
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusListManageUsers);
