import React from 'react';

import { withT } from 'lib/i18n';
import { getTypeIcon } from 'lib/svg/report';
import { REPORT_TYPES } from 'store/ui/report/consts';
import { ComponentProps } from './types';

import './ReportSetupSettingsType.scss';

const ReportSetupSettingsType: React.FC<ComponentProps> = props => {
	const { t, reportType } = props;
	const { onChangeType } = props;

	const typesContent = React.useMemo(() => {
		return REPORT_TYPES.map(type => {
			const isSelected = reportType === type;
			const className = 'type-select' + (isSelected ? ' selected' : '');

			return (
				<div id={`reportSetupTypeSelect_${type}`} key={type} className={className} onClick={() => onChangeType(type)}>
					<img src={getTypeIcon(type, isSelected)} alt='' />
					<div className='type-texts'>
						<span className='title'>{t(`reports.${type}`)}</span>
						<span className='description'>{t(`reports.${type}_export`)}</span>
					</div>
				</div>
			);
		});
	}, [reportType, onChangeType, t]);

	return (
		<div id="reportSetupSettingsType" className="form-section" >
			<div className="form-section-title">
				{t('reports.export_format')}
			</div>
			<div className="form-section-content">
				{typesContent}
			</div>
		</div>
	);
};

export default withT(React.memo(ReportSetupSettingsType));
