import { createSelector } from 'reselect';

import EmailListSelectors from 'store/entities/EmailList/selectors';
import { State } from 'store/types';

const rootSelector = (state: State) => state.ui.newsletter.emailList.edit;

const getEmailListId = createSelector(rootSelector, state => state.emailListId);

export default {
	getEmailListId,
	getEmailList: createSelector(state => state, getEmailListId,
		(state, emailListId) => emailListId ? EmailListSelectors.get(state, emailListId) : null
	)
};
