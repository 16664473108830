import { createSelector } from "reselect";

import { State as RootState } from 'store/types';
import FacebookUserSelectors from 'store/entities/FacebookUser/selectors';

const rootSelector = (state: RootState) => state.app.facebook;

const getCurrentAuth = createSelector(rootSelector, state => state.currentAuth);
const getCurrentAuthId = createSelector(getCurrentAuth, currentAuth => currentAuth ? currentAuth.userID : null);
const isLoaded = createSelector(rootSelector, state => state.loaded);

export default {
	isLoaded,
	isSkipped: createSelector(rootSelector, state => state.skipped === true),
	getDialogMode: createSelector(rootSelector, state => state.dialogMode),
	getPermissionsMissing: createSelector(rootSelector, state => state.permissionsMissing),
	getCurrentAuth,
	getCurrentAuthId,
	getCurrentFacebookUser: createSelector(state => state, getCurrentAuthId, (state, currentAuthId) => {
		return currentAuthId ? FacebookUserSelectors.get(state, currentAuthId) : null;
	})
};
