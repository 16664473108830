import ActionCreators from 'lib/store/actions';

const actions = new ActionCreators("@@audit/list");

const auditNextPage = actions.operator("AUDIT_NEXT_PAGE");
const auditResetPage = actions.operator("RESET_PAGE");
const setIsLoadMoreVisible = actions.operator<boolean>("AUDIT_UPDATE_LOAD_MORE_DISPLAY");

export type Actions = {
    AuditNextPage: ReturnType<typeof auditNextPage>,
    AuditResetPage: ReturnType<typeof auditResetPage>,
    SetIsLoadMoreVisible: ReturnType<typeof setIsLoadMoreVisible>,
}

export const operators = {
	auditNextPage,
	auditResetPage,
	setIsLoadMoreVisible
};