import { all } from 'redux-saga/effects';

import managerSagas from './manager/sagas';
import tagSagas from './tag/sagas';

// define sagas generator function to register all sagas
export default function* sagas() {
	yield all([
		managerSagas(),
		tagSagas()
	]);
}
