import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { operators } from 'store/focus/feed';
import { operators as facetsOperators } from 'store/search/facets';
import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import FocusFeedHeader from './FocusFeedHeader';

const mapStateToProps = ({ focus }: State): StateProps => ({
	focusList: focus.list.focusList,
	focusId: focus.feed.focusId,
	feedId: focus.feed.feedId,
	feedName: focus.feed.feedName,
	feedType: focus.feed.feedType,
	feedEnabled: focus.feed.feedEnabled,
	editNameInputValue: focus.feed.editNameInputValue,
	loadingSaveFeedName: focus.feed.loadingSaveFeedName,
	feedChanged: focus.feed.feedChanged,
	showDuplicateFeedDialog: focus.feed.showDuplicateFeed,
	loadingSaveFeed: focus.feed.loadingSaveFeed,
	isComplex: focus.feed.is_complex,
	approximativeHits: focus.feed.approximate_hits,
	showComplexityDialog: focus.feed.show_complexity_dialog
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetShowComplexityDialog: show => dispatch(operators.setShowComplexityDialog({ show })),
	onSaveFeed: () => dispatch(operators.saveFeed()),
	onSaveFeedName: () => dispatch(operators.saveFeedName()),
	onSetEditNameInputValue: feedName => dispatch(operators.setEditNameInputValue({ feedName })),
	onToggleFeedEnabled: () => dispatch(operators.toggleFeedEnabled()),
	onToggleDrawer: () => dispatch(facetsOperators.toggleDrawer()),
	onDuplicateFeed: () => dispatch(operators.duplicateFeed())
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedHeader);
