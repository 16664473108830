import React from 'react';
import Button from 'react-md/lib/Buttons/Button';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import ListItem from 'react-md/lib/Lists/ListItem';
import DropdownMenu from 'react-md/lib/Menus/DropdownMenu';
import { Link } from 'react-router-dom';

import GA from 'lib/googleAnalytics';
import { withT } from 'lib/i18n';
import { FocusObject } from 'class/Focus';
import { User } from 'class/User';
import { Tenant } from 'class/Tenant';
import { Feed, FeedObject } from 'class/Feed';
import Dialog from 'components/common/dialog';
import FocusListManageUser from 'components/Focus/List/ManageUsers';

import { ComponentProps } from './types';
import { DialogAction } from './Dialog/FocusListDialog';
import FocusListDialog from './Dialog';

import './FocusList.scss';

type ComponentState = {
	dialogAction: DialogAction | null,
	limitationDialogOption: "printOnly" | "tier" | null,
	manageFocusId: string | null
};

class FocusList extends React.Component<ComponentProps, ComponentState> {

	private dialogSelectedFocus: FocusObject | null = null;
	private dialogSelectedFeed: FeedObject | null = null;

	constructor(props: ComponentProps) {
		super(props);
		this.state = { dialogAction: null, limitationDialogOption: null, manageFocusId: null };
	};

	private onDialogClosed = () => {
		this.setState({ dialogAction: null });
	};

	private getFocusOptions = (focus: FocusObject) => {
		const { t, tenant: tenantObject, user: userObject } = this.props;

		const user = new User(userObject);
		const tenant = new Tenant(tenantObject);

		const focusOptions = [];

		if (user.hasPermission('focus.rename')) {
			focusOptions.push(<ListItem id={`focusListRenameFocusOption${focus.id}`} key="renameFocus" primaryText={t('focus.rename.value')} onClick={() => {
				this.dialogSelectedFocus = focus;
				GA.trackEvent({ category: 'Focuses list', action: 'Focus actions', label: 'Rename focus' });
				this.setState({ dialogAction: "renameFocus" });
			}
			} />);
		}

		if (user.hasPermission('focus.manage_acl')) {
			focusOptions.push(<ListItem
				id={`focusListManageUsersInFocus${focus.id}`}
				key="manageFocusUsers"
				primaryText={t('focus.manage_allowed_users')}
				onClick={() => {
					GA.trackEvent({ category: 'Focuses list', action: 'Focus actions', label: 'Manage allowed users' });
					this.setState({ manageFocusId: focus.id });
				}} />);
		}

		if (user.hasPermission('feed.create')) {
			focusOptions.push(<ListItem
				id={`focusListCreateOnlineFeedOption${focus.id}`}
				key="createNewOnlineFeed"
				primaryText={t('focus.new_online_feed')}
				component={Link}
				to={`/focus/${focus.id}/feed/create/online`}
				onClick={ev => {
					if (tenant.hasPrintOnly()) {
						this.setState({ limitationDialogOption: "printOnly" });
						return ev.preventDefault();
					}
					GA.trackEvent({ category: 'Focuses list', action: 'Focus actions', label: 'New online feed' });
				}} />);
			focusOptions.push(<ListItem
				id={`focusListCreateSocialFeedOption${focus.id}`}
				key="createNewSocialMediaFeed"
				primaryText={t('focus.new_social_feed')}
				component={Link}
				to={`/focus/${focus.id}/feed/create/socialmedia`}
				onClick={ev => {
					if (tenant.hasPrintOnly() || !tenant.hasSocialMediaTier()) {
						this.setState({ limitationDialogOption: tenant.hasPrintOnly() ? "printOnly" : "tier" });
						return ev.preventDefault();
					}
					GA.trackEvent({ category: 'Focuses list', action: 'Focus actions', label: 'New socialmedia feed' });
				}} />);

			if (user.hasPermission('feed.print.manage')) {
				focusOptions.push(<ListItem
					id={`focusListCreatePrintDmrFeedOption${focus.id}`}
					key="createPrintDmrFeed"
					primaryText={t('focus.new_print_dmr_feed')}
					className="internal-action"
					component={Link}
					to={`/focus/${focus.id}/feed/create/print_dmr`}
					onClick={() => {
						GA.trackEvent({ category: 'Focuses list', action: 'Focus actions', label: 'New print DMR feed' });
					}} />);
			}
		}

		if (user.hasPermission('focus.delete')) {
			focusOptions.push(<ListItem id={`focusListRemoveFocusOption${focus.id}`} key="removeFocus" className="discover-entity-list-item-remove"
				primaryText={t('focus.remove.value')} onClick={() => {
					this.dialogSelectedFocus = focus;
					GA.trackEvent({ category: 'Focuses list', action: 'Focus actions', label: 'Delete focus' });
					this.setState({ dialogAction: "removeFocus" });
				}} />);
		}

		return focusOptions;
	};

	private getFeedOptions = (focus: FocusObject, feed: FeedObject) => {
		const { t, user: userObject } = this.props;

		const user = new User(userObject);
		const feedOptions = [];

		if (user.hasPermission('feed.definition.edit')) {
			feedOptions.push(
				<ListItem
					id={`focusListFeedEditDefinition${feed.id}`}
					key="editFeedDefinition"
					component={Link}
					to={`/focus/${focus.id}/feed/${feed.id}`}
					primaryText={t('focus_page.table.menu.option.edit_feed')}
					onClick={(ev: React.MouseEvent) => {
						GA.trackEvent({ category: "Focuses list", action: "Feed actions", label: "edit" });
					}} />
			);
		}

		feedOptions.push(
			<ListItem
				id={`focusListFeedViewDocuments${feed.id}`}
				component={Link}
				to={`/article?feedFilters=${feed.id}`}
				key="viewFeedDocuments"
				primaryText={t('focus_page.table.menu.option.view_feed_articles')}
				onClick={() => {
					GA.trackEvent({ category: "Focuses list", action: "Feed actions", label: "view mentions" });
				}}
			/>
		);

		if (user.hasPermission('feed.delete')) {
			feedOptions.push(<ListItem id={`focusListFeedRemove${feed.id}`} key="removeFeed" primaryText={t('focus_page.table.menu.option.remove_feed')} className="red" onClick={() => {
				this.dialogSelectedFocus = focus;
				this.dialogSelectedFeed = feed;
				this.setState({ dialogAction: "removeFeed" });
			}} />);
		}

		return feedOptions;
	};

	private renderTableContent = () => {
		const { t, focusList, user: userObject } = this.props;

		const user = new User(userObject);
		const feedTypes = ['online', 'social', 'print_dmr', 'print'];

		return (
			<tbody>
				{
					focusList!.map(focus => {
						const focusOptions = this.getFocusOptions(focus);
						return (
							<tr key={focus.id}>
								<td className="discover-entity-list-options" data-title="">
									<div className="min-height">
										{focusOptions.length > 0 ? <DropdownMenu
											id={`focusListMenu${focus.id}`}
											menuItems={focusOptions}
											position={DropdownMenu.Positions.TOP_LEFT}
										>
											<Button icon className="btn--no-background btn--no-ink">more_vert</Button>
										</DropdownMenu> : null}
									</div>
								</td>
								<td className="discover-entity-list-content bold" data-title={`${t('focus.name')}:`}>
									<div className="min-height">
										<Link to={`/focus/${focus.id}`} onClick={() => {
											GA.trackEvent({ category: 'Focuses list', action: 'Focus click' });
										}}>
											<span>{focus.name}</span>
										</Link>
									</div>
								</td>
								{user.hasPermission('focus.manage_acl') ?
									<td className="discover-entity-list-content" data-title={`${t('focus.allowed_users')}:`}>
										<div className="discover-entity-list">
											{
												focus.acl_users ? focus.acl_users.map(aclUser => (
													<div key={aclUser.user_id} className="min-height">
														<span>{`${aclUser.first_name} ${aclUser.last_name}`}</span>
													</div>
												)) : null
											}
										</div>
										{!focus.acl_users || focus.acl_users!.length === 0 ? <div className="min-height only-phone">-</div> : null}
									</td> : null}
								{
									feedTypes.map(feedType => {
										let feeds: Feed[] = [];

										if (feedType === 'online') {
											feeds = focus.feeds!.online;
										} else if (feedType === 'social') {
											feeds = focus.feeds!.socialmedia;
										} else if (feedType === 'print_dmr') {
											feeds = focus.feeds!.print_dmr;
										} else if (feedType === 'print') {
											feeds = focus.feeds!.print;
										}
										return (
											<td key={feedType} className="discover-entity-list-content" data-title={`${t(`focus.${feedType}_feeds`)}:`}>
												<div className="discover-entity-list-vertical">
													{
														feeds.map(feed => {

															const feedOptions = this.getFeedOptions(focus, feed);

															return (
																<div key={feed.id} className={`min-height ${feedType}-feed ${!feed.enabled ? "disabled" : ""}`}>
																	{
																		<DropdownMenu
																			id={`focusListMenuFeed${feed.id}`}
																			menuItems={feedOptions}
																			position={DropdownMenu.Positions.TOP_LEFT}
																			onVisibilityChange={(visible, ev) => {
																				if (visible) GA.trackEvent({ category: "Focuses list", action: "Feed click" });
																			}}
																		>
																			<Button flat className="btn--no-background btn--no-ink">
																				<FontIcon>check</FontIcon>
																				<span>{feed.name}</span>
																			</Button>
																		</DropdownMenu>
																	}
																</div>
															);
														})
													}
													{!feeds || feeds.length === 0 ? <div className="min-height only-phone">-</div> : null}
												</div>
											</td>
										);
									})
								}
							</tr>
						);
					})
				}
			</tbody>
		);
	};

	private getLimitationsDialog = () => {
		const { t } = this.props;

		if (this.state.limitationDialogOption) {
			const limitation = this.state.limitationDialogOption;
			return <Dialog
				id="focusListLimitationDialog"
				icon={<FontIcon>warning</FontIcon>}
				title={t(`${limitation}.limitations.title`)}
				content={t(`${limitation}.limitations.description`)}
				onAccept={() => this.setState({ limitationDialogOption: null })}
				onAcceptText={t(`${limitation}.limitations.ok`)}
			/>;
		}
		return null;
	};

	private getManageFocusUsersDialog = () => {
		const { manageFocusId } = this.state;

		if (manageFocusId) {
			return <FocusListManageUser
				focusId={manageFocusId}
				onClose={() => this.setState({ manageFocusId: null })}
			/>;
		}
		return null;
	};

	public componentDidMount() {
		// TODO: uncomment following line when sync reducers implemented
		// if (!this.props.focusList) this.props.onFetchFocusList();
		this.props.onFetchFocusList();
		GA.trackPage('/focus');
	}

	public render() {
		const { t, focusList, loading, user: userObject } = this.props;

		const user = new User(userObject);
		const tableHeader = (
			<thead>
				<tr id="focusListHeader">
					<th></th>
					<th>{t('focus.name')}</th>
					{user.hasPermission('focus.manage_acl') ? <th>{t('focus.allowed_users')}</th> : null}
					<th>{t('focus.online_feeds')}</th>
					<th>{t('focus.social_feeds')}</th>
					<th>{t('focus.print_dmr_feeds')}</th>
					<th>{t('focus.print_feeds')}</th>
				</tr>
			</thead>
		);

		const tableContent = !loading && focusList ? this.renderTableContent() : null;

		const limitationDialog = this.getLimitationsDialog();

		const manageFocusUsersDialog = this.getManageFocusUsersDialog();

		return (
			<div id="focusList" className="discover-entity-list">
				<div className="discover-entity-list-toolbar">
					<div className="discover-entity-list-title">{t('focus.insight_focus')}</div>
					{!loading && user.hasPermission('focus.create') ? <Button id="focusListCreateFocusBtn" icon className="btn--no-background" title={t('focus.create_title')} onClick={() => {
						GA.trackEvent({ category: 'Focuses list', action: 'Create new focus', label: 'Open modal' });
						this.setState({ dialogAction: "createFocus" });
					}}>add</Button> : null}
				</div>
				<div className="discover-entity-list-table">
					<table>
						{tableHeader}
						{tableContent}
					</table>
					{loading ? <CircularProgress id="focusListLoading" scale={1.25} /> : null}
				</div>
				{this.state.dialogAction ?
					<FocusListDialog
						action={this.state.dialogAction}
						focus={this.dialogSelectedFocus}
						feed={this.dialogSelectedFeed}
						onClose={this.onDialogClosed}
					/>
					: null}
				{limitationDialog}
				{manageFocusUsersDialog}
			</div>
		);
	}

}

export default withT(FocusList);
