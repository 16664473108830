
import ActionCreators from 'lib/store/actions';
import { ReportSettings, ReportFeeds, ReportFeed, ReportFeedClippings, ReportStep } from './types';
import { PartialAtLeastOne } from 'types/generics';

const actions = new ActionCreators('@@report/setup');

const setStep = actions.operator<ReportStep>('SET_STEP');
const changeSettings = actions.operator<PartialAtLeastOne<ReportSettings>>('CHANGE_REPORT_SETTINGS');
const setSettings = actions.operator<PartialAtLeastOne<ReportSettings>>('SET_REPORT_SETTINGS');
const resetSetup = actions.operator('RESET_REPORT_SETUP');
const applySettings = actions.operator('APPLY_REPORT_SETTINGS');
const [fetchFeeds, fetchFeedsSuccess, fetchFeedsError] = actions.apiOperators<void, ReportFeeds>('FETCH_FEEDS');
const changeFeedSettings = actions.operator<{ feedId: string, settings: PartialAtLeastOne<ReportFeed> }>('CHANGE_FEED_SETTINGS');
const setFeedClippings = actions.operator<ReportFeedClippings>('SET_FEED_CLIPPINGS');
const [queueReport, queueReportSuccess, queueReportError] = actions.apiOperators('QUEUE_REPORT');
const setLinkedBrandId = actions.operator<string | null>('SET_BRAND_LINKED_ID');
const unselectAllFeeds = actions.operator('UNSELECT_ALL_FEEDS');

export type Actions = {
	SetStep: ReturnType<typeof setStep>;
	ChangeSettings: ReturnType<typeof changeSettings>;
	SetSettings: ReturnType<typeof setSettings>;
	ResetSetup: ReturnType<typeof resetSetup>;
	ApplySettings: ReturnType<typeof applySettings>;
	FetchFeeds: ReturnType<typeof fetchFeeds>;
	FetchFeedsSuccess: ReturnType<typeof fetchFeedsSuccess>;
	FetchFeedsError: ReturnType<typeof fetchFeedsError>;
	ChangeFeedSettings: ReturnType<typeof changeFeedSettings>;
	SetFeedClippings: ReturnType<typeof setFeedClippings>;
	SetLinkedBrandId: ReturnType<typeof setLinkedBrandId>;
	UnselectAllFeeds: ReturnType<typeof unselectAllFeeds>;
};

export const operators = {
	setStep,
	changeSettings,
	setSettings,
	resetSetup,
	applySettings,
	fetchFeeds,	fetchFeedsSuccess,	fetchFeedsError,
	changeFeedSettings,
	setFeedClippings,
	queueReport, queueReportSuccess, queueReportError,
	setLinkedBrandId,
	unselectAllFeeds
};
