import React, { Reducer, useEffect, useRef, useState } from 'react';

import { FormFieldElement } from 'types/form';
import { PartialAtLeastOne } from 'types/generics';

// This hooks is used to know if component is yet mounted
const useIsMounted = () => {
	const isMounted = React.useRef(false);

	React.useEffect(function setIsMounted() {
		isMounted.current = true;

		return () => {
			isMounted.current = false;
		};
	}, []);

	return isMounted;
};

// This is a helper hook to only execute useEffect in update events
export const useUpdateEffect = (effect: React.EffectCallback, dependencies: readonly any[]) => {
	const isMounted = useIsMounted();
	const isInitialMount = React.useRef(true);

	React.useEffect(() => {
		let effectCleanupFunc = () => { };

		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			effectCleanupFunc = effect() || effectCleanupFunc;
		}
		return () => {
			effectCleanupFunc();
			if (!isMounted.current) {// eslint-disable-line react-hooks/exhaustive-deps
				isInitialMount.current = true;
			}
		};
	}, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};

// Hook to handle timeouts. Useful inside useEffect hook.
export const useTimeout = () => {
	const timeoutId = React.useRef<number>();

	const clear = React.useCallback(() => {
		if (timeoutId.current) clearTimeout(timeoutId.current);
	}, []);

	const run = React.useCallback((callback: Function, delay: number) => {
		clear();
		timeoutId.current = setTimeout(callback, delay);
	}, [clear]);

	return { run, clear };
};

// Hook to use in FormField to update the value from a prop
// convertValue can be a needed function to convert internal field value state to the value pushed up to form (see FormAutocompleteField as an example)
export const useForceFieldValue = (
	forcedValue: any, convertValue: ((value: any) => any) | null, element: FormFieldElement, setValueFn: Function, onUpdateFn: Function, onValidateFn: Function, setUpdate: boolean = true) => {
	React.useEffect(() => {
		if (forcedValue) {
			setValueFn(forcedValue);
			if (setUpdate) onUpdateFn(element.id, convertValue ? convertValue(forcedValue) : forcedValue, onValidateFn(element, forcedValue));
		}
	}, [forcedValue]); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useAssignReducer = <T>(initialValue: T) => {
	return React.useReducer<Reducer<T, Partial<T>>>((state: T, action: Partial<T>) => ({ ...state, ...action }), initialValue);
};

//hook for managing components with lot of state fields, allows partial setting of state
export function useManagedState<T>(initialValue: T): [T, (object: PartialAtLeastOne<T>) => void] {

	const [managedState, setState] = useState<T>(initialValue);

	function setManagedState(stateChanges: PartialAtLeastOne<T>): void {
		setState({
			...managedState,
			...stateChanges
		});
	}

	return [managedState, setManagedState];
}

//hook for accessing previous state inside functional component
export function usePrevious<T>(value: T): T | undefined {
	const ref = useRef<T>();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
}
