import React from 'react';

import Dropzone from 'components/common/Dropzone/Dropzone';
import { AddFileError } from 'components/common/Dropzone/types';
import { withT } from 'lib/i18n';
import { FileFieldElement } from 'types/form';
import { formFieldBaseValidation } from 'components/common/Form/Form';
import { useForceFieldValue } from 'lib/hooks';
import { ComponentProps } from './types';

import './FormFileField.scss';

const __validateFileField = (element: FileFieldElement, newValue: File[]) => {
	if (!formFieldBaseValidation(element, newValue)) return false;
	if (!element.validations) return true;
	return true;
};

const FormFileField = (props: ComponentProps) => {
	const { element, forceValue, error, onChangeFile, onAddNotification, t } = props;

	const [files, setFiles] = React.useState<File[] | null>(null);
	useForceFieldValue(forceValue, null, element, setFiles, onChangeFile, __validateFileField);

	const onFilesAdded = React.useCallback((files: File[]) => {
		setFiles(files);
		onChangeFile(element.id, files, __validateFileField(element, files));
	}, [element, onChangeFile]);

	const onFileErrors = React.useCallback((errors: AddFileError[]) => {
		const error = errors[0];
		if (element.onFileErrors) return element.onFileErrors(errors);
		if (error.error === 'file_too_large') {
			onAddNotification({ text: t('newsletter.digest.edit.custom_image.rejected_size', { sizeInMb: element.maxSize! / 1000000 }), level: 'warning' });
		}
		else if (error.error === 'incorrect_file_type') onAddNotification({ t: 'newsletter.digest.edit.custom_image.rejected_type', level: 'warning' });
		else onAddNotification({ text: t('newsletter.digest.edit.custom_image.rejected_size', { sizeInMb: element.maxSize! / 1000000 }), level: 'warning' });
	}, [element, onAddNotification, t]);

	const onRemoveFile = React.useCallback(() => setFiles(null), []);

	return (
		<div className='discover-form-file-field' >
			<Dropzone
				files={files || []}
				multiple={element.multiple}
				accept={element.accept}
				maxSize={element.maxSize}
				showError={error}
				onFilesAdded={onFilesAdded}
				onAddFilesError={onFileErrors}
				onRemoveFile={onRemoveFile}
			></Dropzone>
		</div >
	);
};

export default withT(React.memo(FormFileField));
