import { TFunction } from "i18next";
import React from "react";
import { Button, TextField } from "react-md";

export function SearchFormInput(
	{ t,
		onSetQuery,
		handleSubmit,
		query
	}: {
		t: TFunction,
		onSetQuery: (value: string) => void,
		handleSubmit: () => void,
		query: string
	}) {
	return (
		<div id="searchFormInput">
			<TextField
				id="searchFormInputText"
				label={t('form.search.documents')}
				onChange={value => { onSetQuery(value.toString()); }}
				value={query}
				onKeyDown={ev => { if (ev.keyCode === 13) handleSubmit(); }}
			/>
			<Button
				icon
				className="form-search-icon"
				tooltipPosition="right"
				onClick={() => handleSubmit()}
			>
				search_outline
			</Button>
		</div>
	);
}
