import { all } from 'redux-saga/effects';

import appSagas from './app/sagas';
import uiSagas from './ui/sagas';
import articleSagas from './article/sagas';
import searchSagas from './search/sagas';
import focusSagas from './focus/sagas';
import fletcherSagas from './fletcher/sagas';

// define sagas generator function to register all sagas
export default function* sagas() {
	yield all([
		fletcherSagas(),
		uiSagas(),
		appSagas(),
		articleSagas(),
		searchSagas(),
		focusSagas()
	]);
}
