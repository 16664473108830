import moment from 'moment';
import { keys } from 'lib/imports/lodash';
import { Audit } from './types';
import { State } from 'store/types';
import selectors from './selectors';
import Mekong from 'lib/ajax/Mekong';
import { Actions, operators } from './actions';
import { callCatch } from 'lib/store/sagas';
import pagingSelectors from '../paging/selectors';
import filterSelectors from '../filters/selectors';
import { operators as pagingOperators } from './../paging';
import { operators as notificationOperators } from 'store/app/notifications';

import { all, call, put, select, takeLatest } from 'redux-saga/effects';

const sortField = 'date';

export default function* sagas() {
	yield all([
		takeLatest(operators.applySort, applySort),
		takeLatest(operators.fetchAudit, fetchAudit),
		takeLatest(operators.fetchNextPage, fetchNextPage),
		takeLatest(operators.resubscribe, resubscribe)
	]);
}

function* fetchAudit() {
	const data: {data: Audit[]} = yield __getAudits();

	if(data) {
		yield put(operators.fetchAuditSuccess(data.data));
		yield put(pagingOperators.setIsLoadMoreVisible(data.data.length === 50));
	}
}

function* fetchNextPage() {
	const data: {data: Audit[]} = yield __getAudits();

	if(data) {
		yield put(operators.fetchNextPageSuccess(data.data));
		yield put(pagingOperators.setIsLoadMoreVisible(data.data.length === 50));
	}
}

function* resubscribe({payload: {id, email} }: Actions["Resubscribe"]) {
	try {
		yield call([Mekong, 'post'], `/v1/newsletter/${id}/resubscribe`, { data: { email } });
		yield put(notificationOperators.add({ notification: { t: 'audit.newsletter.resubscribe.success', level: 'success' } }));
	} catch(err) {
		console.error(err);
		yield put(notificationOperators.add({ notification: { t: 'audit.newsletter.resubscribe.error', level: 'danger' } }));
	}
}

function* __getAudits() {
	const state: State = yield select();
	const params: Record<string, string | number> = __getFilters(state);

	if(!__areFiltersValid(params)) return;

	const auditResponse: {data: Audit[]} = yield callCatch(
		[[Mekong, 'get'], `/v1/audit/history`, { params }],
		operators.fetchAuditError
	);

	return auditResponse;
}

function* applySort() {
	yield put(pagingOperators.auditResetPage());
	yield fetchAudit();
}

function __getFilters(state: State): Record<string, string | number> {
	const sort = selectors.getSort(state);
	const { startDate, endDate, events, searchTerm } = filterSelectors.getAllFilters(state);
	const pageNumber = pagingSelectors.getPageNumber(state);

	return {
		pageNumber,
		searchTerm: searchTerm,
		events: events.join(','),
		endDate: moment(endDate).endOf('day').toISOString(),
		startDate: startDate.toISOString(),
		sort: keys(sort).map(key => `${key}:${sort[key]}`).join(',')
	};
}

const isSortValue = (value: string) => value === sortField;
const __isPageNumberValid = (pageNumber: number) => pageNumber > 0;
const isSortOrder = (order: string) => order === 'asc' || order === 'desc';
const __areDatesValid = (startDate: Date, endDate: Date) => (new Date(endDate).getTime() >= new Date(startDate).getTime());
//sort = 'date:asc,name:desc'
const __isSortValid = (sort: string) => sort.split(',').map(s => s.split(':')).every(sortValue => isSortValue(sortValue[0]) && isSortOrder(sortValue[1]));

const __areFiltersValid = (filters: Record<string, string | number>) => {
	const { pageNumber, events, startDate, endDate, sort } = filters;
	if(!pageNumber || !__isPageNumberValid(pageNumber as any as number)) return false;
	if(!events) return false;
	if(!startDate || !endDate || !__areDatesValid(new Date(startDate), new Date(endDate))) return false;
	if(!sort || !__isSortValid(sort + '')) return false;
	return true;
};
