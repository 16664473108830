import { Model, attr, many } from "redux-orm";
import { createOrmReducer, ReducerOrm } from "lib/store/reducer";

import { Actions, operators } from "./actions";
import { NewsletterFields, NewsletterModel } from "./types";


export class Newsletter extends Model<typeof Newsletter, NewsletterFields> {
	static modelName = 'Newsletter' as const;
	static fields = {
		id: attr(),
		name: attr(),
		emailLists: many('EmailList', 'newsletters'),
		feeds: many('Feed', 'newsletters'),
		tags: many('Tag', 'newsletters')
	};
	static get idAttribute() { return 'id' as const; };

	static reducer: ReducerOrm<NewsletterModel> = createOrmReducer<NewsletterModel>(operators, Newsletter => ({
		addEmailList: ({ payload: { id, listId } }: Actions["AddEmailList"]) => {
			const newsletter = Newsletter.withId(id);
			if (!newsletter) console.warn('Newsletter <addEmailList> id not found:', id);
			else newsletter.emailLists.add(listId);
		},
		removeEmailList: ({ payload: { id, listId } }: Actions["RemoveEmailList"]) => {
			const newsletter = Newsletter.withId(id);
			if (!newsletter) console.warn('Newsletter <addEmailList> id not found:', id);
			else newsletter.emailLists.remove(listId);
		}
	}));
}
