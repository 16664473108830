import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import React, { useState } from 'react';
import EventPickerContent from './EventPickerContent';
import { Button, DialogContainer } from 'lib/imports/react-md';

import './EventsPicker.scss';

const EventPicker = ({ t }: ComponentProps) => {

	const [isVisible, setIsVisible] = useState(false);

	return <div className="audit-events-filter">
		<DialogContainer
			visible={isVisible}
			focusOnMount={false}
			id="auditEventChooserDialog"
			children={<EventPickerContent />}
			onHide={() => setIsVisible(false)}
			onClick={() => setIsVisible(false)}
			aria-labelledby="launchmetrics-dialog"
		/>
		<Button id="auditEventPickerButton" flat className="audit-event-picker-button" onClick={() => setIsVisible(!isVisible)}>
			{t('audit_page_event_chooser_title')}
		</Button>
	</div>;
};

export default withT(EventPicker);
