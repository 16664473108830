import { all } from 'redux-saga/effects';

import listSagas from './list/sagas';
import editSagas from './edit/sagas';

export default function* sagas() {
	yield all([
		listSagas(),
		editSagas()
	]);
}
