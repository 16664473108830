import { all, call, put, takeLatest } from 'redux-saga/effects';

import { Actions, operators } from './actions';
import { operators as entitiesOperators } from 'store/entities/Topic';

import Mekong from 'lib/ajax/Mekong';

export default function* sagas() {
	yield all([
		takeLatest(operators.createTopic.type, createTopic),
		takeLatest(operators.renameTopic.type, renameTopic),
		takeLatest(operators.deleteTopic.type, deleteTopic)
	]);
}

function* createTopic({ payload: { name } }: Actions['CreateTopic']) {
	try {
		const topic = yield call(Mekong.post, `/v1/topic`, { data: { name } });
		yield put(entitiesOperators.create(topic));
		yield put(operators.createTopicSuccess());
	} catch (error) {
		yield put(operators.createTopicError(error));
	}
}

function* renameTopic({ payload: { id, name, oldName } }: Actions['RenameTopic']) {
	try {
		yield put(entitiesOperators.update({ id, name }));
		yield call(Mekong.patch, `/v1/topic/${id}`, { data: { name, old_name: oldName } });
		yield put(operators.renameTopicSuccess());
	} catch (error) {
		yield put(operators.renameTopicError(error));
	}
}

function* deleteTopic({ payload: { id } }: Actions['DeleteTopic']) {
	try {
		yield put(entitiesOperators.delete(id));
		yield call(Mekong.delete, `/v1/topic/${id}`);
		yield put(operators.deleteTopicSuccess());
	} catch (error) {
		yield put(operators.deleteTopicError(error));
	}
}
