import React, { useCallback, useMemo } from 'react';
import { withT } from "lib/i18n";

import DropdownFilter from "components/common/DropdownFilter/DropdownFilter";
import { FilterFacet } from 'class/Filter';
import { Facet, FacetItem } from 'class/Facet';

import { ComponentProps } from './types';

const PlacementType = (props: ComponentProps) => {
	const { excluded, facetFilters, display, isOpen } = props;
	const { t, onSetExcludeField, onSetFacetGroupFilters, onToggleOpen } = props;

	const getFacetItems = React.useCallback(() => {
		return Facet.articleTypeValues.map(type =>({ key: type, name: t(`filters.article_type.${type}`) }));
	}, [t]);

	const placementTypeFilters = useMemo(() => (facetFilters && facetFilters[Facet.placementType]) || [], [facetFilters]);

	const handleChange = useCallback((items: FacetItem[]) => {
		onSetFacetGroupFilters(items as FilterFacet[]);
	}, [onSetFacetGroupFilters]);

	const handleExclude = useCallback((set: boolean) => onSetExcludeField(set), [onSetExcludeField]);

	return display ?
		<DropdownFilter
			id='placementTypeFilter'
			key='filters-placementtype-dropdown'
			title={t('filters.article_type.value')}
			excluded={excluded}
			filters={getFacetItems()}
			initSelectedFilters={placementTypeFilters as FacetItem[]}
			defaultFilter={{ name: t('reports.all_male_sing'), key: 'all' }}
			disable={false}
			isOpen={isOpen}
			onToggleOpen={onToggleOpen}
			onChange={handleChange}
			onExclude={handleExclude}
		/>
		: null;
};

export default withT(PlacementType);
