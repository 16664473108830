import i18n from 'i18next';
import { initReactI18next, withTranslation, WithTranslation } from 'react-i18next';
import moment from 'moment';

import { LanguageCode } from 'class/User';
import enLocales from 'locales/en.json';
import esLocales from 'locales/es.json';
import itLocales from 'locales/it.json';
import frLocales from 'locales/fr.json';

import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
moment.locale('en');

export const withT = withTranslation(); // no namespace applied, export the function directly if namespace needed
export type TProps = WithTranslation;

i18n
	.use(initReactI18next)
	.init({
		debug: process.env.NODE_ENV === 'development',
		lng: 'en',
		fallbackLng: 'en',
		resources: {
			en: { translation: enLocales }
		}
	});

const _loadedLocales: { [index: string]: true } = {
	en: true
};

export async function setLanguage(lang: LanguageCode) {
	if (i18n.language === lang) return;
	if (!_loadedLocales[lang]) {
		try {
			let langLocales;
			switch (lang) {
				case "es":
					langLocales = esLocales;
					break;
				case "it":
					langLocales = itLocales;
					break;
				case "fr":
					langLocales = frLocales;
					break;
				default:
					langLocales = enLocales;
			}
			_loadedLocales[lang] = true;
			i18n.addResourceBundle(lang, 'translation', langLocales);
		} catch (err) {
			console.error(err, lang);
		}
	}
	i18n.changeLanguage(lang);
}

export default i18n;
