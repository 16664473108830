import { all, call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { routerActions } from 'connected-react-router';
import { omit, sortBy, cloneDeep, find, remove } from 'lib/imports/lodash';

import GA from 'lib/googleAnalytics';
import Api from 'lib/ajax/Api';
import Mekong from 'lib/ajax/Mekong';
import { State } from "store/types";
import { operators as FocusOperators } from 'store/entities/Focus';
import { operators as FeedOperators } from 'store/entities/Feed';
import { Focus, FocusObject } from 'class/Focus';
import { Feed } from 'class/Feed';

import { Actions, operators } from './actions';

export default function* sagas() {
	yield all([
		takeLatest(operators.fetchFocusList.type, fetchFocusList),
		takeLatest(operators.createFocus.type, createFocus),
		takeLatest(operators.renameFocus.type, renameFocus),
		takeLatest(operators.removeFocus.type, removeFocus),
		takeLatest(operators.removeFeed.type, removeFeed),
		takeEvery(operators.updateFocusUserAllowAccess.type, updateFocusUserAllowAccess)
	]);
}

function* fetchFocusList({ payload }: Actions["FetchFocusList"]) {
	const api = new Api();

	try {
		const start = GA.startTimer();
		let focusList = yield call([api, 'get'], '/definition/focus', { params: { entities: 1 } });

		GA.endTimer(start, 'focus_list', 'load');
		focusList = focusList.map(({ entities: { feed }, ...focusProps }: { entities: { feed: Feed[] } }) => {
			return { ...focusProps, feeds: Focus.transformEntitiesResponse(feed) };
		});
		yield put(operators.fetchFocusListSuccess({ focusList }));
	} catch (error) {
		yield put(operators.fetchFocusListError({ error }));
	}
}

function* createFocus({ payload: { name } }: Actions["CreateFocus"]) {
	let focusList: FocusObject[] = yield select((state: State) => state.focus.list.focusList);

	try {
		const focus: FocusObject = yield call(Mekong.post, '/v1/definition/focus', { data: { name } });
		focus.feeds = { online: [], socialmedia: [], print: [], print_dmr: [] };
		focusList = sortBy(cloneDeep([...focusList, focus]), focusItem => focusItem.name);

		yield put(FocusOperators.replace(omit(focus, 'feeds')));

		yield put(operators.createFocusSuccess({ focusList }));
		yield put(routerActions.push(`/focus/${focus.id}`));
	} catch (error) {
		yield put(operators.createFocusError({ error }));
	}
}

function* renameFocus({ payload: { focus, name } }: Actions["RenameFocus"]) {
	let focusList: FocusObject[] = yield select((state: State) => state.focus.list.focusList);
	const api = new Api();

	try {
		yield call([api, 'put'], `/definition/focus/${focus.id}`, { data: { name, old_name: focus.name } });
		focusList = cloneDeep(focusList);
		(find(focusList, { name: focus.name }) as FocusObject).name = name;

		yield put(FocusOperators.update({ id: focus.id, name }));

		yield put(operators.renameFocusSuccess({ focusList }));
	} catch (error) {
		yield put(operators.renameFocusError({ error }));
	}
}

function* removeFocus({ payload: { focus } }: Actions["RemoveFocus"]) {
	let focusList: FocusObject[] = yield select((state: State) => state.focus.list.focusList);

	try {

		yield call(Mekong.delete, `/v1/definition/focus/${focus.id}`, { params: { entities: 1 } });
		focusList = cloneDeep(focusList);
		remove(focusList, { id: focus.id });

		yield put(FocusOperators.deleteCascade(focus.id));

		yield put(operators.removeFocusSuccess({ focusList }));
	} catch (error) {
		yield put(operators.removeFocusError({ error }));
	}
}

function* removeFeed({ payload: { focus, feed } }: Actions["RemoveFeed"]) {
	let focusList: FocusObject[] = yield select((state: State) => state.focus.list.focusList);

	try {
		yield call(Mekong.delete, `/v1/definition/focus/${focus.id}/feed/${feed.id}`);
		focusList = cloneDeep(focusList);
		focus = find(focusList, { id: focus.id })!;
		if (feed.type === "online") remove(focus.feeds!.online, { id: feed.id });
		else if (feed.type === "socialmedia") remove(focus.feeds!.socialmedia, { id: feed.id });
		else if (feed.type === "print") remove(focus.feeds!.print, { id: feed.id });
		else if (feed.type === "print_dmr") remove(focus.feeds!.print_dmr, { id: feed.id });

		yield put(FeedOperators.delete(feed.id));

		yield put(operators.removeFeedSuccess({ focusList }));
	} catch (error) {
		yield put(operators.removeFeedError({ error }));
	}
}

function* updateFocusUserAllowAccess({ payload: { focusId, user, allow } }: Actions["UpdateFocusUserAllowAccess"]) {
	try {
		yield put(operators.addManageFocusUserLoading({ userId: user.id }));
		if (allow) yield call(Mekong.post, `/v1/definition/focus/${focusId}/user/${user.id}`);
		else yield call(Mekong.delete, `/v1/definition/focus/${focusId}/user/${user.id}`);

		const focusList: FocusObject[] = yield select((state: State) => state.focus.list.focusList);
		const focusListCopy: FocusObject[] = cloneDeep(focusList);
		const focus = find(focusListCopy, { id: focusId }) as FocusObject;
		let aclUser = find(focus.acl_users, { user_id: user.id });
		if (allow && !aclUser) {
			aclUser = {
				user_id: user.id,
				first_name: user.first_name,
				last_name: user.last_name,
				discover_role: user.role
			};
			focus.acl_users!.push(aclUser);
		} else if (!allow && aclUser) remove(focus.acl_users!, { user_id: user.id });

		yield put(operators.removeManageFocusUserLoading({ userId: user.id }));
		yield put(operators.updateFocusUserAllowAccessSuccess({ focusList: focusListCopy }));
	} catch (error) {
		yield put(operators.removeManageFocusUserLoading({ userId: user.id }));
		yield put(operators.updateFocusUserAllowAccessError({ error }));
	}
}
