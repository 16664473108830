import { combineReducers } from 'redux';

import * as form from './form';
import * as results from './results';
import * as facets from './facets';
import * as filters from './filters';
import * as horizontalFilters from './horizontalFilters';

export type State = {
	form: form.State;
	results: results.State;
	facets: facets.State;
	filters: filters.State;
	horizontalFilters: horizontalFilters.State
};

export const INITIAL_STATE: State = {
	form: form.INITIAL_STATE,
	results: results.INITIAL_STATE,
	facets: facets.INITIAL_STATE,
	filters: filters.INITIAL_STATE,
	horizontalFilters: horizontalFilters.INITIAL_STATE
};

export const reducers = combineReducers({
	form: form.reducers,
	results: results.reducers,
	facets: facets.reducers,
	filters: filters.reducers,
	horizontalFilters: horizontalFilters.reducers
});
