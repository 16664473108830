import { createReducer, ReducerHandlers } from "lib/store/reducer";
import { Actions, operators } from "./actions";

export type State = {
	readonly quickReportEnabled: boolean
	readonly reportedFocusNames: string[]
};

export const INITIAL_STATE: State = {
	quickReportEnabled: false,
	reportedFocusNames: []
};

const reducerHandlers: ReducerHandlers<State> = {
	setReportedFocusNames: (state, { payload: { focuses } }: Actions["SetReportedFocusNames"]): State => ({
		...state,
		reportedFocusNames: focuses
	}),
	toggleQuickReport: (state, { payload }: Actions['ToggleQuickReport']): State => ({
		...state,
		quickReportEnabled: payload
	})
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
