import keys from 'lodash/keys';
import ls from 'lscache';
import { Session } from 'types/session';

/*
* /!\ WARNING /!\
*
* local storage (ls) survives browser restart and is shared between all tabs
*
*/

const expirations = { // in minutes
	facebookSkip: 60 * 24 * 3 // 3 days
} as const;

export const facebookStorage = {
	getSkipped: (): boolean => {
		const skipped = ls.get('facebook_skipped'); // null if not set or expired
		return (skipped && skipped === 'true');
	},
	setSkipped: (skipped: boolean) => ls.set('facebook_skipped', skipped ? 'true': 'false', expirations.facebookSkip)
};

let lastToken: string | null = null;
export const terStorage = {
	getToken: (): string | null => {
		const token = ls.get('api_token');
		if (lastToken && token && lastToken.split('-')[0] !== token.split('-')[0]) {
			terStorage.clear(); // prevent requests while reloading
			// eslint-disable-next-line no-restricted-globals
			location.reload(); // force reload if token changes
		}
		return token;
	},
	setToken: (token: string) => {
		lastToken = token;
		ls.set('api_token', token); // no expiration
	},
	getTokenExpiresAt: (): string | null => ls.get('api_tokenExpiresAt'),
	setTokenExpiresAt: (tokenExpiresAt: string) => ls.set('api_tokenExpiresAt', tokenExpiresAt),
	clear: () => {
		ls.remove('api_token');
		ls.remove('api_tokenExpiresAt');
	}
};

// TODO: Expire-at & clear to be done, from now expiration time is the same as the session so every time sesion is asked setToken should be called
export const JWTStorage = {
	getToken: (app: string): string | null => ls.get(`jwt_${app}`),
	setToken: (jwt: Session['jwt']) => keys(jwt).map(app => ls.set(`jwt_${app}`, jwt[app].access_token))
};
