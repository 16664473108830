import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import 'lib/i18n';
import { history, store, startSagas } from './store';
import sagas from './store/sagas';

import App from './components/App';
import PublicApp from './components/Public/App';

import './Main.scss';

export default function Main() {
	startSagas(sagas);

	return (
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<Switch>
					<Route path='/public/'>
						<PublicApp />
					</Route>
					<Route path='/'>
						<App />
					</Route>
				</Switch>
			</ConnectedRouter>
		</Provider>
	);
}
