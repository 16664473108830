import { Model, many, attr } from "redux-orm";
import { createOrmReducer, ReducerOrm } from "lib/store/reducer";
import { transform } from "lib/imports/lodash";

import { Actions, operators } from "./actions";
import { UserModel, UserFields, Permission, UserInstance } from "./types";

export class User extends Model<typeof User, UserFields> {
	static get idAttribute() { return 'id' as const; };
	static modelName = 'User' as const;
	static fields = {
		id: attr(),
		name: attr(),
		facebookUsers: many('FacebookUser', 'users')
	};

	static reducer: ReducerOrm<UserModel> = createOrmReducer<UserModel>(operators, (User, session) => ({
		addFacebookUser: ({ payload: { id, facebookUserId } }: Actions["AddFacebookUser"]) => {
			const user = User.withId(id);
			if (!user) return console.warn('User <addFacebookUser> id not found:', id);
			user.facebookUsers.add(facebookUserId);
		},
		removeFacebookUser: ({ payload: { id, facebookUserId } }: Actions["RemoveFacebookUser"]) => {
			const user = User.withId(id);
			if (!user) return console.warn('User <removeFacebookUser> id not found:', id);
			user.facebookUsers.remove(facebookUserId);
		},
		setFacebookUsersData: ({ payload: { id, facebookUsersData } }: Actions["SetFacebookUsersData"]) => {
			const user = User.withId(id);
			if (!user) return console.warn('User <setFacebookUsersData> id not found:', id);
			user.update({ facebookUsers: facebookUsersData.map(facebookUserData => (session.FacebookUser as any).parse(facebookUserData)) });
		}
	}));

	public hasPermission(permission: Permission): boolean {
		const instance = this as any as UserInstance;
		if (!instance._permissionsIndex) {
			instance._permissionsIndex = transform(instance.permissions, (result: UserFields['_permissionsIndex'], permission) => {
				result![permission] = true;
			}, {});
		}
		return (permission in instance._permissionsIndex!);
	}
}
