import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { operators as facebookOperators } from 'store/app/facebook';
import { FacebookPermission } from 'store/entities/FacebookUser';
import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';

import FocusFeedDefinitionSocialInstagramAccount from './FocusFeedDefinitionSocialInstagramAccount';

const mapStateToProps = (state: State): StateProps => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetDialogMode: dialogMode => dispatch(facebookOperators.setDialogMode(dialogMode)),
	onSetFacebookPermissionsMissing: (permissionsMissing: FacebookPermission[]) => dispatch(facebookOperators.setPermissionsMissing({ mode: 'check', permissionsMissing }))
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedDefinitionSocialInstagramAccount);
