import React from 'react';
import { ComponentProps } from './types';

export const Blanket = (props: ComponentProps) => {
	return (
		<div
			style={{
				bottom: 0,
				left: 0,
				top: 0,
				right: 0,
				position: 'fixed',
				zIndex: 1
			}}
			{...props}
		/>
	);
};