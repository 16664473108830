import React, { useCallback, useState } from 'react';
import moment from 'moment-timezone';

import { ComponentProps } from './types';
import { DateFactory } from 'lib/dateFactory';

import { INITIAL_STATE } from 'store/ui/audit';
import { PresetOption, Ranges } from 'components/common/DateFilter/types';
import DateFilter from 'components/common/DateFilter';

import './DatePicker.scss';

export const AuditDatePicker = ({ user, onDatesChange }: ComponentProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [pickerStartDate, setPickerStartDate] = useState(INITIAL_STATE.filters.startDate);
	const [pickerEndDate, setPickerEndDate] = useState(INITIAL_STATE.filters.endDate);

	React.useEffect(() => {
		onDatesChange(pickerStartDate, pickerEndDate);
	}, [onDatesChange, pickerEndDate, pickerStartDate]);

	const onToggleOpen = useCallback(() => {
		setIsOpen(!isOpen);
	}, [setIsOpen, isOpen]);

	const lastDay = React.useMemo(() => new Date('2021-12-23'), []);
	const maxDay = React.useMemo(() => new Date(), []);
	const presetOptions: PresetOption[] = React.useMemo(() => [
		{
			label: 'Last week',
			range: () => DateFactory.getPastDateRangeFor('week')
		},
		{
			label: 'Last month',
			range: () => DateFactory.getPastDateRangeFor('month')
		}
	], []);

	const getDateRanges = React.useCallback(() => {
		return {
			selectedDateRange: {
				key: 'selectedDateRange',
				endDate: pickerEndDate,
				startDate: pickerStartDate
			}
		};
	}, [pickerEndDate, pickerStartDate]);

	const onDatesChanged = React.useCallback((ranges: Ranges) => {
		setPickerStartDate(moment(ranges.selectedDateRange.startDate).tz(user.settings.timezone, true).toDate());
		setPickerEndDate(moment(ranges.selectedDateRange.endDate).tz(user.settings.timezone, true).toDate());
		onDatesChange(pickerStartDate, pickerEndDate);
	}, [onDatesChange, pickerEndDate, pickerStartDate, user.settings.timezone]);

	const content = React.useMemo(() => {
		return (
			<div className="audit-date-range-picker-wrapper">
				<DateFilter
					key={"audit-date-range-picker-wrapper"}
					isOpen={isOpen}
					onToggleOpen={onToggleOpen}
					options={[]}
					ranges={getDateRanges()}
					isCompareModeActive={false}
					fullDisplay={false}
					maxAvailableDate={maxDay}
					firstAvailableDate={lastDay}
					presetOptions={presetOptions}
					onDatesChange={onDatesChanged}
					defaultRangeKey="selectedDateRange"
					dateFormat={user.settings.locale === 'en-US' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
				/>
			</div>
		);
	}, [getDateRanges, isOpen, lastDay, maxDay, onDatesChanged, onToggleOpen, presetOptions, user.settings.locale]);

	return content;
};
