import React from "react";
import { withT } from "lib/i18n";
import { newsletterHours } from "store/entities/Newsletter/consts";

import { NewsletterTime } from 'store/entities/Newsletter';

import NewsletterEditFormTimeInput from './Input';

import { ComponentProps } from './types';
import { Button } from "react-md";

import './NewsletterEditFormTime.scss';

const NewsletterEditFormTime = (props: ComponentProps) => {
	const { t, weeklyTime, weeklyDay, dailyTimes, frequency, onlyWorkingDays } = props;
	const { onDailyTimesUpdated, onWeeklyTimeUpdated } = props;

	const isDaily = React.useMemo(() => frequency === 'daily', [frequency]);

	const onRemoveTime = React.useCallback((index: number) => {
		const newTimes = [...dailyTimes];
		newTimes.splice(index, 1);
		onDailyTimesUpdated(newTimes);
	}, [onDailyTimesUpdated, dailyTimes]);

	const onUpdateTime = React.useCallback((time: NewsletterTime, index?: number) => {
		const times = isDaily ? dailyTimes : [weeklyTime];
		if (isDaily) {
			const newTimes = [...times];
			newTimes[index!] = time;
			onDailyTimesUpdated(newTimes);
		}
		else onWeeklyTimeUpdated(time);
	}, [onDailyTimesUpdated, onWeeklyTimeUpdated, isDaily, dailyTimes, weeklyTime]);

	const addNewTime = React.useCallback(() => {
		const previoustime = dailyTimes[dailyTimes.length - 1];
		const hourString = ((parseInt(previoustime.hour, 10) + 1) % 23).toString();
		const formattedHour = hourString.length === 1 ? `0${hourString}` : hourString;
		const newTime: NewsletterTime = { hour: formattedHour as typeof newsletterHours[number], minute: previoustime.minute };
		onDailyTimesUpdated([...dailyTimes, newTime]);
	}, [dailyTimes, onDailyTimesUpdated]);

	const timeInputs = React.useMemo(() => {
		const times = [];
		if (isDaily) {
			times.push(<NewsletterEditFormTimeInput
				key={0}
				time={dailyTimes[0]}
				onUpdated={time => onUpdateTime(time, 0)}
			/>);
			for (let i = 1; i < dailyTimes.length; i++) {
				times.push(<NewsletterEditFormTimeInput
					key={i}
					time={dailyTimes[i]}
					onRemove={() => onRemoveTime(i)}
					onUpdated={time => onUpdateTime(time, i)}
				/>);
			}
		} else {
			times.push(<NewsletterEditFormTimeInput
				key={0}
				time={weeklyTime}
				onUpdated={onUpdateTime}
			/>);
		}
		return times;
	}, [dailyTimes, weeklyTime, isDaily, onRemoveTime, onUpdateTime]);

	const sendingSummary = React.useMemo(() => {
		let textSummary;
		if (!isDaily) {
			textSummary = `${t('newsletter.form.input.summary.weekly', { day: t(`newsletter.form.input.day.${weeklyDay}`) })} ${weeklyTime.hour}:${weeklyTime.minute}`;
		} else {
			let multiLang = '';
			if (onlyWorkingDays) multiLang = 'daily_working_days';
			else multiLang = 'daily';

			const formattedTimes = dailyTimes.map(time => `${time.hour}:${time.minute}`);
			const lastTimeFormatted = formattedTimes.pop();

			let sendingTimes = lastTimeFormatted;
			if (formattedTimes.length) sendingTimes = `${formattedTimes.join(', ')} ${t('newsletter.form.input.summary.and')} ${lastTimeFormatted}`;

			textSummary = `${t(`newsletter.form.input.summary.${multiLang}`)} ${sendingTimes}`;
		}

		return (
			<div className="sending-summary">
				<span>{textSummary}</span>
			</div>);
	}, [t, isDaily, weeklyDay, weeklyTime, dailyTimes, onlyWorkingDays]);

	return (
		<div id="newsletterEditSectionTime" className="form-section">
			<div className="form-section-title">
				{t('newsletter.form.label.time')}
			</div>
			<div className="form-section-content">
				<div className="form-section-content-times">
					{timeInputs}
					{isDaily &&
						(
							<Button
								className="newsletterEditSectionTimeAdd"
								flat
								onClick={addNewTime}
							>
								<span>+ {t('newsletter.form.input.add_time')}</span>
							</Button>
						)}
				</div>
				<div className="form-section-content-times-summary">
					{sendingSummary}
				</div>
			</div>
		</div>
	);
};

export default withT(React.memo(NewsletterEditFormTime));
