import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/ui/report/setup';
import setupSelectors from 'store/ui/report/setup/selectors';
import { DispatchProps, StateProps } from './types';

import CategoryTags from './ReportSetupSettingsCategoryTags';

const mapStateToProps = (state: State): StateProps => ({
	reportTopicTagIds: setupSelectors.getTopicTagIds(state),
	reportCategories: setupSelectors.getCategories(state),
	reportTopicTagOperator: setupSelectors.getTopicTagOperator(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onChangeTags: topicTagIds => dispatch(operators.changeSettings({ topicTagIds })),
	onChangeCategories: categories => dispatch(operators.changeSettings({ categories })),
	onChangeTopicTagOperator: topicTagOperator => dispatch(operators.changeSettings({ topicTagOperator }))
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTags);
