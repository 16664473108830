import React from 'react';
import { withT } from 'lib/i18n';
import { TextField, DatePicker, Autocomplete, CircularProgress, Button } from 'lib/imports/react-md';
import moment from 'moment';
import { cloneDeep, isEmpty, get, set, remove } from 'lib/imports/lodash';

import { getAvailableCountries, parseNumericInput } from 'lib/input';
import { getSymbol, convertReverse } from 'lib/currency';
import Categorization from 'components/common/Categorization';
import Dropzone from 'components/common/Dropzone/Dropzone';
import { AddFileError } from 'components/common/Dropzone/types';
import { ComponentProps } from './types';
import { InsertPrintForm } from 'types/article/insert';
import { date as dateFormat } from 'lib/format';

type InputErrors = {
	headline?: boolean,
	mediaName?: boolean,
	date?: boolean,
	pageNumber?: boolean
};

type ComponentState = {
	headline: string,
	image: File | null,
	mediaName: string,
	pageNumber: number | '',
	editionNumber: number | '',
	countryCode: string
	countryName: string,
	circulation: number | '',
	valuation: number | '',
	date: Date,
	tags: {
		tagIds: string[],
		newTagNames: string[]
	},
	category: string,
	inputErrors: InputErrors
};

class ArticleInsertFormPrint extends React.Component<ComponentProps, ComponentState> {

	constructor(props: ComponentProps) {
		super(props);
		this.state = {
			headline: '',
			image: null,
			mediaName: '',
			pageNumber: '',
			editionNumber: '',
			countryCode: '',
			countryName: '',
			circulation: '',
			valuation: 0,
			date: new Date(),
			tags: { tagIds: [], newTagNames: [] },
			category: '',
			inputErrors: {}
		};
	}

	private _handleHeadlineChange(headline: string) {
		this.setState({ headline, inputErrors: { ...this.state.inputErrors, headline: headline === '' } });
	}
	private _handlePageNumberChange(value: string) {
		this.setState({ pageNumber: parseNumericInput(value), inputErrors: { ...this.state.inputErrors, pageNumber: false } });
	}

	private _handleImageAdded(files: File[]) {
		if (isEmpty(files)) this.setState({ image: null });
		else this.setState({ image: files[0] });
	}

	private _handleImageAddedError(errors: AddFileError[]) {
		const { onAddNotification, t } = this.props;
		const error = errors[0];
		if (error.error === 'file_too_large') return onAddNotification({ text: t('newsletter.digest.edit.custom_image.rejected_size', { sizeInMb: 15000000 / 1000000 }), level: 'warning' });
		else if (error.error === 'incorrect_file_type') return onAddNotification({ t: 'newsletter.digest.edit.custom_image.rejected_type', level: 'warning' });
		else return onAddNotification({ t: 'newsletter.digest.edit.custom_image.upload_error', level: 'warning' });
	}

	private _handleMediaNameChange(mediaName: string) {
		this.setState({ mediaName, inputErrors: { ...this.state.inputErrors, mediaName: mediaName === '' } });
	}

	private _handleCountryChange(country: string = '', id: string = '') {
		this.setState({ countryCode: id, countryName: country });
	}

	private _handleDateChange(rawDate: Date) {
		const parsedDate = dateFormat.getNewDocumentDate(rawDate, "print");
		this.setState({ date: parsedDate });
	}

	private _removeTag(tag: string) {
		const tagsCopy = cloneDeep(this.state.tags);
		remove(tagsCopy.newTagNames, tagName => tagName === tag);
		remove(tagsCopy.tagIds, tagId => tagId === tag);
		this.setState({ tags: tagsCopy });
	}

	private _prepareForm() {
		const { headline, image, mediaName, pageNumber, editionNumber, circulation, countryCode, valuation, tags, category, date } = this.state;
		const parsedDate = moment(date).tz('utc').format();
		const parsedValuation = convertReverse(+valuation, parsedDate);
		const form: InsertPrintForm = {
			title: headline,
			country: countryCode,
			date: parsedDate,
			media_name: mediaName,
			image: image!,
			miv: parsedValuation,
			tags,
			category
		};

		if (pageNumber) form.page = pageNumber;
		if (editionNumber) form.edition_number = editionNumber;
		if (circulation) form.circulation = circulation;

		return form;
	}

	private _validateForm() {
		const { image, category, pageNumber } = this.state;
		const { onValidationSuccess, onValidationError, onAddNotification } = this.props;
		const checkInputs = ['headline', 'mediaName', 'date'];
		const inputErrors: InputErrors = {};
		let warningMessage = '';
		let hasErrors = false;

		if (!image) {
			hasErrors = true;
			warningMessage = 'document.insert.form.missingImage';
		}

		if (!category) {
			hasErrors = true;
			warningMessage = 'insert_article.modal.select_category';
		}

		if (pageNumber && pageNumber >= 32767) { // backend hard limit
			hasErrors = true;
			inputErrors.pageNumber = true;
		}

		checkInputs.forEach(input => {
			const inputValue = get(this.state, input);
			if (!inputValue) {
				set(inputErrors, input, true);
				hasErrors = true;
			} else set(inputErrors, input, false);
		});

		this.setState({ inputErrors });

		if (hasErrors) {
			if (warningMessage) onAddNotification({ t: warningMessage, level: 'warning' });
			onValidationError();
			return;
		}

		onValidationSuccess(this._prepareForm());
	}

	public componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState, snapshot: any) {
		if (this.props.submit && !prevProps.submit) this._validateForm();
	}

	public render() {
		const { user, tenant, feedType, loading } = this.props;
		const { t } = this.props;
		const { headline, mediaName, pageNumber, editionNumber, countryName, circulation, valuation, date, inputErrors } = this.state;
		return (
			<>
				<div id="insertMentionPrintForm" className="insert-mention-form-content">
					<div className="form-row">
						<div className="form-row-col half-wide">
							<TextField
								id="insertMentionPrintFormHeadline"
								className="text-field"
								fullWidth={true}
								label={t('insert_article.modal.form.title')}
								value={headline}
								onChange={text => this._handleHeadlineChange(text.toString())}
								required={true}
								error={inputErrors.headline}
							/>
						</div>
					</div>
					<div className="form-row-title">{t('insert_article.modal.form.image')}</div>
					<div className="form-row">
						<div id="insertMentionPrintFormDropzoneContainer" className="form-row-col">
							<Dropzone
								files={this.state.image ? [this.state.image] : []}
								multiple={false}
								accept="image/*"
								maxSize={15000000}
								onFilesAdded={files => this._handleImageAdded(files)}
								onAddFilesError={errors => this._handleImageAddedError(errors)}
								onRemoveFile={file => this.setState({ image: null })}
							></Dropzone>
						</div>
					</div>
					<div className="form-row">
						<div className="form-row-col">
							<TextField
								id="insertMentionPrintFormMediaName"
								className="text-field"
								fullWidth={true}
								label={t('insert_article.modal.form.media.name')}
								value={mediaName}
								onChange={text => this._handleMediaNameChange(text.toString())}
								required={true}
								error={inputErrors.mediaName}
							/>
						</div>
					</div>
					<div className="form-row">
						<div className="form-row-col">
							<TextField
								id="insertMentionPrintFormPageNumber"
								className="text-field"
								fullWidth={true}
								label={t('insert_article.modal.form.page')}
								value={pageNumber}
								type="number"
								step={1}
								min={0}
								onChange={value => this._handlePageNumberChange(value.toString())}
								error={inputErrors.pageNumber}
							/>
						</div>
						<div className="form-row-col">
							<TextField
								id="insertMentionPrintFormEditionNumber"
								className="text-field"
								fullWidth={true}
								label={t('insert_article.modal.form.edition_number')}
								value={editionNumber}
								type="number"
								step={1}
								min={0}
								onChange={value => this.setState({ editionNumber: parseNumericInput(value.toString()) })}
							/>
						</div>
					</div>
					<div className="form-row">
						<div className="form-row-col">
							<Autocomplete
								id="insertMentionPrintFormCountry"
								className="text-field"
								label={t('insert_article.modal.form.country')}
								data={getAvailableCountries()}
								dataLabel="name"
								dataValue="id"
								value={countryName}
								filter={Autocomplete.caseInsensitiveFilter}
								onChange={text => this._handleCountryChange(text)}
								onAutocomplete={(suggestion, index, results: any) => this._handleCountryChange(results[index].name, results[index].id)}
								showUnfilteredData={true}
							/>
							<Button
								id="insertMentionPrintFormCountryRemoveButton"
								icon
								iconChildren="close"
								children=""
								onClick={() => this._handleCountryChange()}
							/>
						</div>
						<div className="form-row-col">
							<TextField
								id="insertMentionPrintFormCirculation"
								className="text-field"
								fullWidth={true}
								label={t('insert_article.modal.form.circulation')}
								value={circulation}
								type="number"
								step={1}
								min={0}
								onChange={value => this.setState({ circulation: parseNumericInput(value.toString()) })}
							/>
						</div>
					</div>
					<div className="form-row">
						<div className="form-row-col">
							<TextField
								id="insertMentionPrintFormValuation"
								className="text-field price-field"
								fullWidth={true}
								label={t(`insert_article.modal.form.${tenant.settings.valuation_metric}`)}
								value={valuation}
								type="number"
								step={1}
								min={0}
								inlineIndicator={<span className="price-indicator">{getSymbol()}</span>}
								onChange={value => this.setState({ valuation: parseNumericInput(value.toString()) })}
							/>
						</div>
						<div className="form-row-col">
							<DatePicker
								id="insertMentionPrintFormDate"
								label={t("insert_article.modal.form.date")}
								okLabel={t('filters.period.custom.dialog.ok')}
								cancelLabel={t('filters.period.custom.dialog.cancel')}
								fullWidth={true}
								className="text-field"
								icon={null}
								defaultValue={date}
								value={date}
								locales={user.settings.locale}
								maxDate={moment().add(15, 'days').toDate()}
								onChange={(formattedDate, rawDate, ev) => this._handleDateChange(rawDate)}
								portal={true}
								lastChild={true}
								disableScrollLocking={true}
								renderNode={document.body}
								required={true}
								error={inputErrors.date}
							/>
						</div>
					</div>
					<div className="form-row-title">{t('insert_article.modal.form.category_and_tags')}</div>
					<div className="form-row">
						<div id="insertMentionPrintFormCategorizationContainer" className="form-row-col half-wide categorization-row">
							<Categorization
								hideOldCategories={feedType === 'print_dmr'}
								tags={this.state.tags}
								onAddTags={(tagIds, newTagNames) => this.setState({
									tags: {
										newTagNames: [...this.state.tags.newTagNames, ...newTagNames],
										tagIds: [...this.state.tags.tagIds, ...tagIds]
									}
								})}
								onRemoveTag={tag => this._removeTag(tag)}
								onSetCategory={category => this.setState({ category })}
							/>
						</div>
					</div>
				</div>
				{loading ? <CircularProgress id="insertDocumentDialogLoading" scale={1.5} /> : null}
			</>
		);
	}
}

export default withT(ArticleInsertFormPrint);
