import React from 'react';
import { Autocomplete, Button, DialogContainer, Paper } from 'lib/imports/react-md';
import { find, filter, includes, reject, some, uniq, without } from 'lib/imports/lodash';

import { withT } from 'lib/i18n';
import EmailListLayout from 'components/Newsletter/EmailList';
import { EmailListObject } from 'store/entities/EmailList';
import { ComponentProps } from './types';
import { cleanEmail } from 'lib/email';
import { isValidEmail } from 'lib/validate';

const getNewsletterEmailLists = (ormEmailLists: EmailListObject[], ids: string[]) => filter(ormEmailLists, list => ids.includes(list.id));

const NewsletterEditFormRecipients = (props: ComponentProps) => {
	const { t, emails, emailListsIds, ormEmailLists, error } = props;
	const { onEmailsUpdated, onEmailListsUpdated, onAddNotification } = props;

	const [showManageLists, setShowManageLists] = React.useState(false);
	const [autocompleteValue, setAutocompleteValue] = React.useState("");

	const onOpenManageList = React.useCallback(() => setShowManageLists(true), []);
	const onRemoveEmail = React.useCallback((ev: any) => {
		const email = ev.currentTarget.getAttribute("email");
		onEmailsUpdated(without(emails, email));
	}, [emails, onEmailsUpdated]);
	const onRemoveEmailList = React.useCallback((ev: any) => {
		const id = ev.currentTarget.getAttribute("email-list-id");
		onEmailListsUpdated(getNewsletterEmailLists(ormEmailLists, without(emailListsIds, id)));
	}, [ormEmailLists, emailListsIds, onEmailListsUpdated]);

	const autocompleteData = React.useMemo(() => {
		const notSelectedLists = reject(ormEmailLists, ormEmailList => includes(emailListsIds, ormEmailList.id));
		let data = notSelectedLists.map(ormEmailList => ({ primaryText: ormEmailList.name, value: ormEmailList.id }));
		if (autocompleteValue.trim()) {
			const labelText = `${autocompleteValue} (${autocompleteValue.includes(',') ? "emails" : "email"})`;
			data = [{ primaryText: labelText, value: autocompleteValue, is_email: "true" } as any, ...data];
		}
		return data;
	}, [autocompleteValue, ormEmailLists, emailListsIds]);

	const selectEmailListAutocomplete = React.useMemo(() => (
		<Autocomplete
			id="newsletterEditEmailListAutocompleter"
			data={autocompleteData}
			fullWidth
			showUnfilteredData
			clearOnAutocomplete
			onAutocomplete={(suggestion, index, results: any) => {
				const result = results[index];
				if (result.is_email) {
					const emailsTxt = cleanEmail(result.value);
					const newEmails = emailsTxt.split(',');
					if (!emailsTxt || some(newEmails, email => !isValidEmail(email))) onAddNotification({ level: "warning", t: 'newsletter.form.input.email.wrong_email' });
					else onEmailsUpdated(uniq([...emails, ...newEmails]));
				} else {
					const emailList = find(ormEmailLists, { id: result.value });
					if (emailList && !includes(emailListsIds, emailList.id)) onEmailListsUpdated(getNewsletterEmailLists(ormEmailLists, [...emailListsIds, emailList.id]));
				}
				setAutocompleteValue("");
			}}
			label={t('newsletter.form.input.email_lists.value')}
			filter={Autocomplete.caseInsensitiveFilter}
			onChange={setAutocompleteValue}
			error={!!error}
			value={autocompleteValue}
		/>
	), [t, autocompleteValue, ormEmailLists, emails, emailListsIds, autocompleteData, onEmailsUpdated, onEmailListsUpdated, onAddNotification, error]);

	const selectedItems = React.useMemo(() => {
		const emailsItems = emails.map(email => (
			<Paper
				id={`newsletterEditSelectedEmail${email}`}
				key={email}
				zDepth={1}
			>
				<span>{email}</span>
				<Button
					id={`newsletterEditSelectedEmailRemoveButton${email}`}
					className="email-remove-button"
					email={email}
					flat
					iconChildren="close"
					children=""
					onClick={onRemoveEmail}
				/>
			</Paper>
		));
		const emailListItems = getNewsletterEmailLists(ormEmailLists, emailListsIds).map(emailList => (
			<Paper
				id={`newsletterEditSelectedEmailList${emailList.id}`}
				key={emailList.id}
				zDepth={1}
			>
				<span>{emailList.name}</span>
				<Button
					id={`newsletterEditSelectedEmailListRemoveButton${emailList.id}`}
					email-list-id={emailList.id}
					flat
					iconChildren="close"
					children=""
					onClick={onRemoveEmailList}
				/>
			</Paper>
		));
		return [...emailListItems, emailsItems];

	}, [emails, emailListsIds, ormEmailLists, onRemoveEmail, onRemoveEmailList]);

	const recipientsSection = React.useMemo(() => (
		<div id="newsletterEditSectionRecipients" className="form-section vertical-align-top">
			<div className="form-section-title">
				{t('newsletter.form.label.recipients')}
			</div>
			<div className="form-section-content">
				<div className="form-section-content-column column-direction">
					{selectEmailListAutocomplete}
					<div className="selected-email-lists-wrapper">
						{selectedItems}
					</div>
				</div>
				<div className="form-section-content-column column-direction">
					<span>{t('newsletter.form.button.manage_lists.help')}</span>
					<Button
						id="newsletterEditShowManageList"
						className='form-btn'
						flat
						onClick={onOpenManageList}
					>
						{t('newsletter.form.button.manage_lists.value')}
					</Button>
				</div>
			</div>
		</div>
	), [t, selectEmailListAutocomplete, onOpenManageList, selectedItems]);

	const manageListDialog = React.useMemo(() => (
		showManageLists && <DialogContainer
			id="newsletterEditManageListsDialog"
			visible={true}
			onHide={() => setShowManageLists(false)}
			fullPage
			focusOnMount={false}
			children={<EmailListLayout onClose={() => setShowManageLists(false)} />}
			aria-labelledby="newsletter-edit-form-manage-email-lists-dialog"
		/>
	), [showManageLists]);

	return (
		<>
			{recipientsSection}
			{manageListDialog}
		</>
	);
};

export default withT(React.memo(NewsletterEditFormRecipients));
