import { all, put, takeLatest, select, call } from 'redux-saga/effects';
import moment, { Moment } from 'moment';
import Mekong from 'lib/ajax/Mekong';
import { transform, groupBy, isEmpty, union, cloneDeep } from 'lib/imports/lodash';

import Api from 'lib/ajax/Api';
import featureFlags from 'lib/featureFlags';
import { Facet } from 'class/Facet';

import { FeedObject } from 'store/entities/Feed';
import FocusSelectors from 'store/entities/Focus/selectors';
import FeedSelectors from 'store/entities/Feed/selectors';
import profileSelectors from 'store/app/profile/selectors';
import { ReportType, ReportPeriod } from 'store/ui/report/types';
import reportSelectors from 'store/ui/report/selectors';
import { operators as reportOperators } from 'store/ui/report/actions';
import { INITIAL_STATE } from 'store/ui/report/setup/reducers';

import { Actions, operators } from './actions';
import { operators as notificationOperators } from 'store/app/notifications/actions';
import selectors from './selectors';
import { ReportFeeds } from './types';

export default function* sagas() {
	yield all([
		takeLatest(operators.changeSettings, changeSettings),
		takeLatest(operators.resetSetup, resetSetup),
		takeLatest(operators.applySettings, applySettings),
		takeLatest(operators.fetchFeeds, fetchFeeds),
		takeLatest(operators.queueReport, queueReport)
	]);
}

function* changeSettings({ payload: changeSetupPayload }: Actions['ChangeSettings']) {
	const payload = { ...changeSetupPayload };

	if (payload.type) yield __youtubeDataBanner(payload.type);
	if (payload.type || payload.period) yield __resetDates(payload);
	else if (payload.minDate || payload.maxDate) yield __checkAndFixMinDate(payload);

	if ('groupedByCountry' in payload) payload.country = 'all';

	yield put(operators.setSettings(payload));
}

function* resetSetup() {
	const settings = cloneDeep(INITIAL_STATE.settings);
	yield __resetDates(settings);
	yield put(operators.setSettings({ minDate: settings.minDate, maxDate: settings.maxDate }));
}

function* applySettings() {
	yield put(operators.fetchFeeds());
}

function* fetchFeeds() {
	const state = yield select();
	const settings = selectors.getSettings(state);
	const user = profileSelectors.getUser(state)!;

	const focusId = reportSelectors.getFocusId(state)!;
	const focusFeeds = FocusSelectors.getFeeds(state, focusId)!;
	const settingsFeeds = selectors.getFeeds(state)!;
	const excludedChannelTypes = __getExcludedChannelTypes();

	const params: Record<string, string | number> = {
		facet_fields: Facet.feedGroupKey,
		date_type: 'publication_date',
		limit: 500, //TODO: Remove this once fixed terms.filter on backend
		'insights.filter': `focus:${focusId}`,
		query: excludedChannelTypes.length ? `-channel_type_id:(${excludedChannelTypes.join(' OR ')})` : ''
	};

	if (settings.country !== 'all') params[`${Facet.countryGroupKey}_filter`] = settings.country;
	if (!isEmpty(settings.categories)) params[`${Facet.categoriesGroupKeyVB}_filter`] = settings.categories.join(',');
	if (!isEmpty(settings.topicTagIds)) params.query = `tenant.topic_tag:(${settings.topicTagIds.join(` ${settings.topicTagOperator} `)})`;

	const minDate = settings.minDate!;
	const maxDate = settings.maxDate!;

	if (settings.type === "periodic") {
		if (settings.period === "monthly") {
			params.begin_date = moment(minDate).startOf('month').format('YYYY-MM-DDTHH:mm:ss');
			params.end_date = moment(maxDate).endOf('month').format('YYYY-MM-DDTHH:mm:ss');
		} else {
			params.begin_date = moment(minDate).startOf('quarter').format('YYYY-MM-DDTHH:mm:ss');
			params.end_date = moment(maxDate).endOf('quarter').format('YYYY-MM-DDTHH:mm:ss');
		}
	} else {
		params.begin_date = moment(minDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
		params.end_date = moment(maxDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
	}

	const minOriginalTime = moment.tz(moment(params.begin_date).format('YYYY-MM-DD'), user.settings.timezone);
	params.begin_date = minOriginalTime.startOf('day').format("YYYY-MM-DDTHH:mm:ssZ");
	const maxOriginalTime = moment.tz(moment(params.end_date).format('YYYY-MM-DD'), user.settings.timezone);
	params.end_date = maxOriginalTime.endOf('day').format("YYYY-MM-DDTHH:mm:ssZ");

	try {
		const api = new Api();
		const response = yield call([api, 'get'], '/documents/facets', { params });

		const feedHits = response.facets[Facet.feedGroupKey];
		const feeds = transform(focusFeeds, (result: ReportFeeds, feed) => {
			result[feed.id] = {
				hits: feedHits[feed.id] || 0,
				selected: settingsFeeds[feed.id] ? settingsFeeds[feed.id].selected : false
			};
		}, {});

		yield put(operators.fetchFeedsSuccess(feeds));
	} catch (error) {
		yield put(operators.fetchFeedsError(error));
	}
}

function* queueReport() {
	const state = yield select();

	const focus = FocusSelectors.get(state, reportSelectors.getFocusId(state)!)!;
	const feeds = transform(selectors.getFeeds(state), (result: FeedObject[], feed, feedId) => {
		if (feed.selected) result.push(FeedSelectors.get(state, feedId)!);
	}, []);
	const settings = selectors.getSettings(state);
	const brand_id = state.ui.report.setup.linkedBrandId;
	const feedClippings = settings.type === 'excel' ? [] : selectors.getFeedClippings(state);

	let categories: string[] = [];
	if (!isEmpty(settings.categories)) {
		const tenant = profileSelectors.getTenantInstance(state)!;
		categories = __getLeafCategories(settings.categories, tenant.getCategories());
	}

	const minDate = settings.minDate!;
	const maxDate = settings.maxDate!;

	const data = {
		type: settings.type,
		...(settings.type === 'periodic' && { period: settings.period }),
		minDate: moment(minDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
		maxDate: moment(maxDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
		focus: { id: focus.id, name: focus.name, logoUrl: focus.url_logo },
		feeds,
		feedClippings,
		language: settings.language,
		currency: settings.currency,
		country: settings.country,
		groupedByCountry: settings.groupedByCountry,
		topicTagIds: settings.topicTagIds,
		topicTagOperator: settings.topicTagOperator,
		categories,
		newPptx: featureFlags.isEnabled('parklu-ppt'),
		brand_id
	};

	try {
		yield call(Mekong.post, '/report', { data });
		yield put(operators.queueReportSuccess());
		yield put(reportOperators.refresh());
	} catch (err) {
		yield put(operators.queueReportError(err));
	}
};

function* __resetDates(payload: Actions['ChangeSettings']['payload']) {
	const type: ReportType = yield payload.type || select(selectors.getType);
	const period: ReportPeriod = yield payload.period || select(selectors.getPeriod);

	const maxDate = moment().endOf('day');
	const defaultMinDate = __getDefaultMinDate(maxDate, type, period);

	payload.maxDate = maxDate.format();
	payload.minDate = defaultMinDate.format();
};

function* __checkAndFixMinDate(payload: Actions['ChangeSettings']['payload']) {
	const type: ReportType = yield payload.type || select(selectors.getType);
	const period: ReportPeriod = yield payload.period || select(selectors.getPeriod);
	const minDate = moment(yield payload.minDate || select(selectors.getMinDate));
	const maxDate = moment(yield payload.maxDate || select(selectors.getMaxDate));

	let unitToCompare: 'day' | 'month' | 'quarter' = 'day';
	if (type === 'periodic') unitToCompare = (period === 'monthly') ? 'month' : 'quarter';

	if ((type === 'periodic' && maxDate.isSameOrBefore(minDate, unitToCompare))
		|| (type !== 'periodic' && maxDate.isBefore(minDate, unitToCompare))) {
		const defaultMinDate = __getDefaultMinDate(maxDate, type, period);
		payload.minDate = defaultMinDate.format();
	}
};

function __getDefaultMinDate(maxDate: Moment, type: ReportType, period: ReportPeriod): Moment {
	if (type !== 'periodic' && type !== 'coverage') return maxDate.clone().subtract(1, 'month').startOf('day');
	if (period === 'monthly') return maxDate.clone().subtract(1, 'month').startOf('day');
	return maxDate.clone().subtract(1, 'quarter').startOf('day');
}

/* Exported for test purposes */
export function __getLeafCategories(categories: string[], allCategories: string[]): string[] {
	const splitedCategories = groupBy(categories, category => category.split('-').length === 3 || category.length === 6 ? 'leaf' : 'grouped');

	if (isEmpty(splitedCategories.grouped)) return categories;

	const groupedCategoriesRegExp = new RegExp(`^(${splitedCategories.grouped.join('|')})`);
	const leafCategories = allCategories.filter(category => category.split('-').length === 3 || (category.length === 6 && category.match(groupedCategoriesRegExp)));

	return union(splitedCategories.leaf, leafCategories);
}

function __getExcludedChannelTypes() {
	if (!featureFlags.isEnabled('parklu')) {
		return [31, 46, 47, 52, 62];
	} else {
		return [];
	}
}

function* __youtubeDataBanner(reportType: ReportType) {
	const tenantId = profileSelectors.getTenantInstance(yield select())!.guid;

	if (!['3E75E26A-9797-11E7-BACE-EA7C63F109A7'].includes(tenantId)) return; // youtube tenant
	if (!['campaign', 'periodic', 'coverage'].includes(reportType)) return;

	yield put(notificationOperators.add({ notification: { t: 'reports.no_youtube_data', level: 'warning' } }));
}
