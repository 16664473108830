import React from 'react';
import { Autocomplete, Button, CircularProgress, DatePicker, TextField, SelectField, FontIcon } from 'lib/imports/react-md';
import moment from 'moment';
import { cloneDeep, get, remove } from 'lib/imports/lodash';
import isURL from 'validator/lib/isURL';

import { getAvailableLanguages, getAvailableCountries, parseNumericInput } from 'lib/input';
import { withT } from 'lib/i18n';
import { MediaOption } from 'types/article/insert';
import { Facet } from 'class/Facet';
import { getSymbol, convert, convertReverse } from 'lib/currency';
import Categorization from 'components/common/Categorization';
import { ComponentProps } from './types';
import { InsertOnlineForm } from 'types/article/insert';
import { date as dateFormat } from 'lib/format';

type PreparedMediaOption = MediaOption & {
	label: JSX.Element
};

type InputErrors = {
	headline: boolean,
	mediaUrl: boolean,
	mediaName: boolean,
	languageName: boolean,
	date: boolean,
	manualAudience: boolean
};

type ComponentState = {
	content: string,
	headline: string,
	mediaUrl: string,
	mediaId: string,
	mediaName: string,
	languageCode: string,
	selectedUrl: string,
	languageName: string,
	countryCode: string
	countryName: string,
	valuation: number | '',
	rawValuation: number | '',
	similarWebMonthlyVisits: number,
	monthlyUniqueVisitors: string,
	manualAudience: string,
	date: Date,
	tags: {
		tagIds: string[],
		newTagNames: string[]
	},
	category: string,
	inputErrors: InputErrors
};

const maxContentLength = 10000;

class ArticleInsertFormOnline extends React.Component<ComponentProps, ComponentState> {

	private timeout: NodeJS.Timeout | null = null;

	constructor(props: ComponentProps) {
		super(props);
		this.state = {
			content: '',
			headline: '',
			mediaUrl: '',
			mediaId: '',
			mediaName: '',
			languageCode: '',
			languageName: '',
			countryCode: '',
			countryName: '',
			valuation: 0,
			rawValuation: 0,
			selectedUrl: props.finalUrl,
			similarWebMonthlyVisits: 0,
			monthlyUniqueVisitors: '-',
			manualAudience: '',
			date: new Date(),
			tags: { tagIds: [], newTagNames: [] },
			category: '',
			inputErrors: {
				headline: false,
				mediaUrl: false,
				mediaName: false,
				languageName: false,
				manualAudience: false,
				date: false
			}
		};
	}

	private _handleHeadlineChange(headline: string) {
		this.setState({ headline, inputErrors: { ...this.state.inputErrors, headline: headline === '' } });
	}

	private _handleLanguageChange(language: string = '', code: string = '') {
		this.setState({ languageCode: code, languageName: language, inputErrors: { ...this.state.inputErrors, languageName: language === '' } });
	}

	private _handleCountryChange(country: string = '', code: string = '') {
		this.setState({ countryCode: code, countryName: country });
	}

	private _handleReachChange(manualReach: string | number) {
		const parsedReach = manualReach.toString();
		this.setState({ manualAudience: parsedReach, inputErrors: { ...this.state.inputErrors, manualAudience: parsedReach === '' } });
	}

	private _handleMUVChange(muv: string | number) {
		const parsedMUV = muv.toString();
		this.setState({ monthlyUniqueVisitors: parsedMUV });
	}

	private _handleMediaNameChange(mediaName: string) {
		this.setState({ mediaName, mediaId: '', inputErrors: { ...this.state.inputErrors, mediaName: mediaName === '' } });
	}

	private _handleUrlChange(newUrl: string) {
		const { onSelectUrl } = this.props;
		this.setState({ selectedUrl: newUrl });
		onSelectUrl(newUrl);
	}

	private _handleMediaUrlChange(url: string = '') {
		const { onFetchMedias } = this.props;

		if (this.timeout) clearTimeout(this.timeout);
		this.setState({ mediaId: '', mediaUrl: url, inputErrors: { ...this.state.inputErrors, mediaUrl: url === '' } });

		this.timeout = setTimeout(() => onFetchMedias(url), 200);
	}

	private _handleMediaUrlAutocomplete(media: PreparedMediaOption) {
		const { onFetchMediaInfo, fetchedMedia } = this.props;
		if (media.id !== '-1') {
			this.setState({ mediaName: media.name, mediaId: media.id, mediaUrl: media.url });
			if (fetchedMedia && fetchedMedia.id === +media.id) this._updateMediaInputs();
			else onFetchMediaInfo(media.id);
		} else this.setState({ mediaId: '' });
	}

	private _handleDateChange(rawDate: Date) {
		const parsedDate = dateFormat.getNewDocumentDate(rawDate, "online");
		this.setState({ date: parsedDate });
	}

	private _handleValuation(valuation: string) {
		const parsedValuation = parseNumericInput(valuation);
		this.setState({ rawValuation: parsedValuation, valuation: parsedValuation });
	}

	private _updateMediaInputs() {
		const { t, fetchedMedia } = this.props;
		const { date } = this.state;
		const languageKey = Facet.languageGroupKeyVB.replace(/\.(.*)/gi, '');
		const countryKey = Facet.countryGroupKey.replace(/_(.*)/gi, '');
		const languageCode = fetchedMedia!.language.code;
		const languageName = t(`filters.${languageKey}.${fetchedMedia!.language.code}`);
		const countryCode = get(fetchedMedia, 'location.country.code') ? fetchedMedia!.location.country.code : '';
		const countryName = get(fetchedMedia, 'location.country.code') ? t(`filters.${countryKey}.${fetchedMedia!.location.country.code}`) : '';
		const similarWebMonthlyVisits = +fetchedMedia!.rank.similarweb_monthly_visits;
		const monthlyUniqueVisitors = fetchedMedia!.rank.monthly_unique_visitors;
		const manualAudience = (((+fetchedMedia!.rank.similarweb_average_desktop_visitors || 0) + (+fetchedMedia!.rank.similarweb_average_mobile_visitors || 0)) || 0) as unknown as string;

		let documentMetricValue = fetchedMedia!.rank.miv.DISCOVER_VALUE;
		let rawValuation = +convert(documentMetricValue, date.toISOString());

		this.setState({
			mediaId: fetchedMedia!.id.toString(),
			languageCode,
			languageName,
			countryCode,
			countryName,
			valuation: +rawValuation.toFixed(2),
			rawValuation,
			similarWebMonthlyVisits,
			monthlyUniqueVisitors,
			manualAudience,
			inputErrors: {
				...this.state.inputErrors,
				languageName: false,
				mediaName: false,
				mediaUrl: false
			}
		});
	}

	private _getAvailableMedias(): PreparedMediaOption[] {
		const { availableMedias } = this.props;
		return availableMedias.map(media => ({ ...media, label: (<><span className="media-option-name">{media.name}</span> {media.url}</>) }));
	}

	private _removeTag(tag: string) {
		const tagsCopy = cloneDeep(this.state.tags);
		remove(tagsCopy.newTagNames, tagName => tagName === tag);
		remove(tagsCopy.tagIds, tagId => tagId === tag);
		this.setState({ tags: tagsCopy });
	}

	private _prepareForm() {
		const {
			headline, content, mediaId, mediaUrl, mediaName, languageCode: languageId, countryCode: countryId, rawValuation, tags,
			category, date, similarWebMonthlyVisits, manualAudience, monthlyUniqueVisitors
		} = this.state;
		const parsedDate = moment(date).tz('utc').format();
		const parsedValuation = convertReverse(+rawValuation, parsedDate);
		const form: InsertOnlineForm = {
			title: headline,
			content,
			country: countryId,
			date: parsedDate,
			language: languageId,
			media_id: mediaId,
			media_url: mediaUrl,
			media_name: mediaName,
			similarweb_monthly_visits: similarWebMonthlyVisits,
			manual_audience: manualAudience,
			monthly_unique_visitors: monthlyUniqueVisitors,
			miv: parsedValuation,
			tags,
			category
		};

		return form;
	}

	private _validateForm() {
		const { content } = this.state;
		const { onValidationSuccess, onValidationError, onAddNotification } = this.props;
		const checkInputs = ['headline', 'mediaName', 'languageCode', 'mediaUrl', 'date', 'manualAudience'];
		const errors: { [key: string]: boolean } = {};
		let hasErrors = false;

		checkInputs.forEach(input => {
			const inputValue = get(this.state, input);
			const targetInput = input === 'languageCode' ? 'languageName' : input;
			if (!inputValue) {
				errors[targetInput] = true;
				hasErrors = true;
			} else if (input === 'mediaUrl' && !isURL(inputValue, { require_protocol: true })) {
				onAddNotification({ t: 'error.url_not_valid', level: 'warning' });
				errors[targetInput] = true;
				hasErrors = true;
			} else errors[targetInput] = false;
		});

		// It's not necessary to control the content state since it is already controlled by the input component with the maxLength prop
		if (content.length > maxContentLength) hasErrors = true;

		this.setState({ inputErrors: errors as InputErrors });

		if (hasErrors) {
			onValidationError();
			return;
		}

		onValidationSuccess(this._prepareForm());
	}

	private _getUrlSelector() {
		const { url, finalUrl } = this.props;
		const { selectedUrl } = this.state;

		if (url === finalUrl) return <TextField
			id="insertMentionOnlineFormUrl"
			className="text-field"
			fullWidth={true}
			label="Url"
			value={finalUrl}
			disabled={true}
		/>;
		else return <SelectField
			id="insertMentionOnlineFormUrl"
			label="Url"
			fullWidth={true}
			menuItems={[url, finalUrl]}
			value={selectedUrl}
			onChange={val => this._handleUrlChange(val.toString())}
		/>;
	}

	private _getReportMissingBlock() {
		const { documentExists } = this.props;
		const { onChangeStep, t } = this.props;

		const missingEntity = documentExists ? 'article' : 'media';
		//TODO (GIS-3372): Feature disabled for now, remove this line when enabled back
		if (missingEntity === 'article') return <></>;

		const reportStep = documentExists ? 'REPORT_MISSING_ARTICLE' : 'REPORT_MISSING_MEDIA';
		return (<>
			<div className="info-grey">
				<div className="form-row">
					<FontIcon className="icon">error_outline</FontIcon>
					<div className="column">
						<div className="missing-title">{t(`insert_article.modal.missing.${missingEntity}.title`)}</div>
						<div>{t("insert_article.modal.missing.you_sure")}</div>
					</div>
				</div>
				<div className="form-row right">
					<Button mini flat id="insertDocumentDialogDoubleCheckBtn" onClick={() => { onChangeStep("SELECT_FEED"); }}>{t("insert_article.modal.missing.double_check")}</Button>
					<Button mini flat id="insertDocumentDialogMissingPlacementBtn" onClick={() => { onChangeStep(reportStep); }}>
						{t(`insert_article.modal.missing.${missingEntity}.report_btn`)}
					</Button>
				</div>
			</div>
			<div className="form-row-title center">
				{t("insert_article.modal.missing.or_manually")}
			</div>
		</>);
	}

	public componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState, snapshot: any) {
		const { submit: oldSubmit } = prevProps;
		const { fetchedMedia, submit } = this.props;

		if (submit && !oldSubmit) {
			this._validateForm();
			return;
		}
		if (fetchedMedia !== prevProps.fetchedMedia) this._updateMediaInputs();
	}

	public render() {
		const { headline, content, mediaUrl, mediaName, languageName, countryName, valuation, date, inputErrors, manualAudience, monthlyUniqueVisitors } = this.state;
		const { user, tenant, loading } = this.props;
		const { t } = this.props;
		const today = new Date();
		const urlSelector = this._getUrlSelector();
		const reportMissing = this._getReportMissingBlock();

		return (
			<>
				<div id="insertMentionOnlineForm" className="insert-mention-form-content">
					<div className="form-row">
						{urlSelector}
					</div>
					{reportMissing}
					<div className="form-row">
						<TextField
							id="insertMentionOnlineFormHeadline"
							className="text-field"
							fullWidth={true}
							label={t('insert_article.modal.form.title')}
							value={headline}
							onChange={text => this._handleHeadlineChange(text.toString())}
							required={true}
							error={inputErrors.headline}
						/>
					</div>
					<div className="form-row">
						<TextField
							id="insertMentionOnlineFormContent"
							className="text-field border-field"
							rows={4}
							maxRows={4}
							maxLength={maxContentLength}
							fullWidth={true}
							floating={true}
							label={t('insert_article.modal.form.content')}
							value={content}
							onChange={text => this.setState({ content: text.toString() })}
							errorText={t('error.insert_article_content_too_long')}
						/>
					</div>
					<div className="form-row">
						<div className="form-row-col half-wide">
							<Autocomplete
								id="insertMentionOnlineFormMediaUrl"
								className="text-field"
								fullWidth={true}
								label={t('insert_article.modal.form.media.url')}
								data={this._getAvailableMedias()}
								dataLabel="label"
								dataValue="id"
								value={mediaUrl}
								filter={null}
								onChange={text => this._handleMediaUrlChange(text)}
								onAutocomplete={(suggestion, index, results: any) => this._handleMediaUrlAutocomplete(results[index])}
								showUnfilteredData={true}
								error={inputErrors.mediaUrl}
							/>
							<Button
								id="insertMentionOnlineFormMediaUrlRemoveButton"
								icon
								iconChildren="close"
								children=""
								onClick={() => this._handleMediaUrlChange()}
							/>
						</div>
						<div className="form-row-col">
							<TextField
								id="insertMentionOnlineFormMediaName"
								className="text-field"
								fullWidth={true}
								label={t('insert_article.modal.form.media.name')}
								value={mediaName}
								onChange={text => this._handleMediaNameChange(text.toString())}
								required={true}
								error={inputErrors.mediaName}
							/>
						</div>
					</div>
					<div className="form-row">
						<div className="form-row-col">
							<Autocomplete
								id="insertMentionOnlineFormLanguage"
								className="text-field"
								label={`${t('insert_article.modal.form.language')} *`}
								data={getAvailableLanguages()}
								dataLabel="name"
								dataValue="id"
								value={languageName}
								filter={Autocomplete.caseInsensitiveFilter}
								onChange={text => this._handleLanguageChange(text)}
								onAutocomplete={(suggestion, index, results: any) => this._handleLanguageChange(results[index].name, results[index].id)}
								showUnfilteredData={true}
								error={inputErrors.languageName}
							/>
							<Button
								id="insertMentionOnlineFormLanguageRemoveButton"
								icon
								iconChildren="close"
								children=""
								onClick={() => this._handleLanguageChange()}
							/>
						</div>
						<div className="form-row-col">
							<Autocomplete
								id="insertMentionOnlineFormCountry"
								className="text-field"
								label={t('insert_article.modal.form.country')}
								data={getAvailableCountries()}
								dataLabel="name"
								dataValue="id"
								value={countryName}
								filter={Autocomplete.caseInsensitiveFilter}
								onChange={text => this._handleCountryChange(text)}
								onAutocomplete={(suggestion, index, results: any) => this._handleCountryChange(results[index].name, results[index].id)}
								showUnfilteredData={true}
							/>
							<Button
								id="insertMentionOnlineFormCountryRemoveButton"
								icon
								iconChildren="close"
								children=""
								onClick={() => this._handleCountryChange()}
							/>
						</div>
						<div className="form-row-col">
							<TextField
								id="insertMentionOnlineFormReach"
								className="text-field"
								required={true}
								error={inputErrors.manualAudience}
								fullWidth={true}
								label={t('insert_article.modal.form.reach')}
								value={manualAudience}
								type="number"
								onChange={inputReach => this._handleReachChange(inputReach)}
							/>
						</div>
					</div>
					<div className="form-row hidden-row">
						<div>
							<TextField
								id="insertMentionOnlineFormMUV"
								className="text-field"
								fullWidth={true}
								label={t('insert_article.modal.form.muv')}
								value={monthlyUniqueVisitors}
								onChange={inputMUV => this._handleMUVChange(inputMUV)}
							/>
						</div>
					</div>
					<div className="form-row">
						<div className="form-row-col">
							<TextField
								id="insertMentionOnlineFormValuation"
								className="text-field price-field"
								fullWidth={true}
								label={t(`insert_article.modal.form.${tenant.settings.valuation_metric}`)}
								value={valuation}
								type="number"
								step={1}
								min={0}
								inlineIndicator={<span className="price-indicator">{getSymbol()}</span>}
								pattern="^d+(\.|\,)\d{2}"
								onChange={value => this._handleValuation(value.toString())}
							/>
						</div>
						<div className="form-row-col">
							<DatePicker
								id="insertMentionOnlineFormDate"
								label={t("insert_article.modal.form.date")}
								okLabel={t('filters.period.custom.dialog.ok')}
								cancelLabel={t('filters.period.custom.dialog.cancel')}
								fullWidth={true}
								className="text-field"
								icon={null}
								defaultValue={date}
								value={date}
								locales={user.settings.locale}
								maxDate={today}
								onChange={(formattedDate, rawDate, ev) => this._handleDateChange(rawDate)}
								portal={true}
								lastChild={true}
								disableScrollLocking={true}
								renderNode={document.body}
								required={true}
								error={inputErrors.date}
							/>
						</div>
					</div>
					<div className="form-row-title">{t('insert_article.modal.form.category_and_tags')}</div>
					<div className="form-row">
						<div id="insertMentionOnlineFormCategorizationContainer" className="form-row-col half-wide categorization-row">
							<Categorization
								tags={this.state.tags}
								onAddTags={(tagIds, newTagNames) => this.setState({
									tags: {
										newTagNames: [...this.state.tags.newTagNames, ...newTagNames],
										tagIds: [...this.state.tags.tagIds, ...tagIds]
									}
								})}
								onRemoveTag={tag => this._removeTag(tag)}
								onSetCategory={category => this.setState({ category })}
							/>
						</div>
					</div>
				</div>
				{loading ? <CircularProgress id="insertDocumentDialogLoading" scale={1.5} /> : null}
			</>
		);
	}
}

export default withT(ArticleInsertFormOnline);
