import XRegExp from 'xregexp';

/* eslint-disable */

/*
Based on https://github.com/praized/lucene-query-validator
*/

// Special characters are + - && || ! ( ) { } [ ] ^ " ~ * ? : \
// Special words are (case-sensitive) AND NOT OR

// Makes wildcard queries case-insensitive if true.
// Refer to http://www.mail-archive.com/lucene-user@jakarta.apache.org/msg00646.html

let wildcardCaseInsensitive = true; // Mutator method for wildcardCaseInsensitive.
let alertUser = false; // Should the user be prompted with an alert box if validation fails?

// @param Should wildcard queries be case-insensitive?
const setWildcardCaseInsensitive = (bool: boolean) => {
	wildcardCaseInsensitive = bool;
};

const setAlertUser = (bool: boolean) => {
	alertUser = bool;
};

// validates a lucene query.
// @param query string
const checkLuceneQueryValue = (query: string) => {
	if (query != null && query.length > 0) {
		query = removeEscapes(query);

		// check for allowed characters
		if (!checkAllowedCharacters(query)) return false;

		// check * is used properly
		if (!checkAsterisk(query)) return false;

		// check for && usage
		if (!checkAmpersands(query)) return false;

		// check ^ is used properly
		if (!checkCaret(query)) return false;

		// check ~ is used properly
		if (!checkSquiggle(query)) return false;

		// check ! is used properly
		if (!checkExclamationMark(query)) return false;

		// check question marks are used properly
		if (!checkQuestionMark(query)) return false;

		// check parentheses are used properly
		if (!checkParentheses(query)) return false;

		// check '+' and '-' are used properly
		if (!checkPlusMinus(query)) return false;

		// check AND, OR and NOT are used properly
		if (!checkANDORNOT(query)) return false;

		// check that quote marks are closed
		if (!checkQuotes(query)) return false;

		// check ':' is used properly
		if (!checkColon(query)) return false;

		if (wildcardCaseInsensitive) {
			if (query.indexOf("*") !== -1) {
				const i = query.indexOf(':');
				if (i === -1) {
					query = query.toLowerCase();
				} else {// found a wildcard field search
					query = query.substring(0, i) + query.substring(i).toLowerCase();
				}
			}
		}
		return true;
	}
};

// remove the escape character and the character immediately following it
const removeEscapes = (query: string) => {
	return query.replace(/\\./g, "");
};

const checkAllowedCharacters = (query: string) => {
	const matches = query.match(XRegExp("[^\\pL\\pN\\pM_+\\-:.()\\\"*?&|!{}\\[\\]\\^~\\\\@#\\/$%'= ]"));
	if (matches != null && matches.length > 0) {
		if (alertUser) alert("Invalid search query! The allowed characters are a-z A-Z 0-9.  _ + - : () \" & * ? | ! {} [ ] ^ ~ \\ @ = # % $ ' /. Please try again.");
		return false;
	}
	return true;
};

const checkAsterisk = (query: string) => {
	const matches = query.match(/^[\*]*$|[\s]\*|^\*[^\s]/);
	if (matches != null) {
		if (alertUser) alert("Invalid search query! The wildcard (*) character must be preceded by at least one alphabet or number. Please try again.");
		return false;
	}
	return true;
};

const checkAmpersands = (query: string) => {
	// NB: doesn't handle term1 && term2 && term3 in Firebird 0.7
	let matches = query.match(/[&]{2}/);
	if (matches != null && matches.length > 0) {
		matches = query.match(XRegExp("^([\\pL\\pN\\pM_+\\-:.()\\\"*?&|!{}\\[\\]\\^~\\\\@#\\/$%'=]+( && )?[\\pL\\pN\\pM_+\\-:.()\\\"*?|!{}\\[\\]\\^~\\\\@#\\/$%'=]+[ ]*)+$")); // note missing & in pattern

		if (matches == null) {
			if (alertUser) alert("Invalid search query! Queries containing the special characters && must be in the form: term1 && term2. Please try again.");
			return false;
		}
	}
	return true;
};

const checkCaret = (query: string) => {
	// const matches = query.match(/^[^\^]*$|^([\\pL\\pN\\pM_+\-:.()\"*?&|!{}\[\]\~\\@#\/]+(\^[\d]+)?[ ]*)+$/); // note missing ^ in pattern
	const matches = query.match(/[^\\]\^([^\s]*[^0-9.]+)|[^\\]\^$/);
	if (matches != null) {
		if (alertUser) alert("Invalid search query! The caret (^) character must be preceded by alphanumeric characters and followed by numbers. Please try again.");
		return false;
	}
	return true;
};

const checkSquiggle = (query: string) => {
	// const matches = query.match(/^[^~]*$|^([a-zA-Z0-9_+\-:.()\"*?&|!{}\[\]\^\\@#\/]+(~[\d.]+|[^\\]\\~)?[ ]*)+$/); // note missing ~ in pattern
	const matches = query.match(/[^\\]~[^\s]*[^0-9\s]+/);
	if (matches != null) {
		if (alertUser) alert("Invalid search query! The tilde (~) character must be preceded by alphanumeric characters and followed by numbers. Please try again.");
		return false;
	}
	return true;
};

const checkExclamationMark = (query: string) => {
	// foo! is not a query, but !foo is. hmmmm...
	// NB: doesn't handle term1 ! term2 ! term3 or term1 !term2
	const matches = query.match(XRegExp("^[^!]*$|^([\\pL\\pN\\pM_+\\-:.()\\\"*?&|!{}\\[\\]\\^~\\\\@#\\/$%'=]+( ! )?[\\pL\\pN\\pM_+\\-:.()\\\"*?&|!{}\\[\\]\\^~\\\\@#\\/$%'=]+[ ]*)+$"));
	if (matches == null || matches.length === 0) {
		if (alertUser) alert("Invalid search query! Queries containing the special character ! must be in the form: term1 ! term2. Please try again.");
		return false;
	}

	return true;
};

const checkQuestionMark = (query: string) => {
	const matches = query.match(XRegExp("^(\\?)|([^\\pL\\pN\\pM_+\\-:.()\\\"*?&|!{}\\[\\]\\^~\\\\@#\\/$%'=]\\?+)"));
	if (matches != null && matches.length > 0) {
		if (alertUser) alert("Invalid search query! The question mark (?) character must be preceded by at least one alphabet or number. Please try again.");
		return false;
	}
	return true;
};

const checkParentheses = (query: string) => {
	let hasLeft = false;
	let hasRight = false;
	const matchLeft = query.match(/[(]/g) as RegExpMatchArray;
	if (matchLeft !== null) hasLeft = true;
	const matchRight = query.match(/[)]/g) as RegExpMatchArray;
	if (matchRight !== null) hasRight = true;

	if (hasLeft || hasRight) {
		if (hasLeft && !hasRight || hasRight && !hasLeft) {
			if (alertUser) alert("Invalid search query! Parentheses must be closed. Please try again.");
			return false;
		} else {
			const number = matchLeft.length + matchRight.length;
			if ((number % 2) > 0 || matchLeft.length !== matchRight.length) {
				if (alertUser) alert("Invalid search query! Parentheses must be closed. Please try again.");
				return false;
			}
		}
		const matches = query.match(/\(\)/);
		if (matches != null) {
			if (alertUser) alert("Invalid search query! Parentheses must contain at least one character. Please try again.");
			return false;
		}
	}
	return true;
};

const checkPlusMinus = (query: string) => {
	const matches = query.match(XRegExp("^[^\n+\\-]*$|^([+-]?[\\pL\\pN\\pM_:.()\\\"*?&|!{}\\[\\]\\^~\\\\@#\\/$%'=]+[ ]?)+$"));
	if (matches == null || matches.length === 0) {
		if (alertUser) alert("Invalid search query! '+' and '-' modifiers must be followed by at least one alphabet or number. Please try again.");
		return false;
	}
	return true;
};

const checkANDORNOT = (query: string) => {
	let matches = query.match(/AND|OR|NOT/);
	if (matches != null && matches.length > 0) {
		matches = query.match(XRegExp("^([\\pL\\pN\\pM_+\\-:.()\\\"*?&|!{}\\[\\]\\^~\\\\@\\/#$%'=]+\\s*((AND )|(OR )|(AND NOT )|(NOT ))?[\\pL\\pN\\pM_+\\-:.()\\\"*?&|!{}\\[\\]\\^~\\\\@\\/#$%'=]+[ ]*)+$"));
		if (matches == null || matches.length === 0) {
			if (alertUser) alert("Invalid search query!  Queries containing AND/OR/NOT must be in the form: term1 AND|OR|NOT|AND NOT term2 Please try again.");
			return false;
		}

		// its difficult to distinguish AND/OR/... from the usual [a-zA-Z] because they're...words!
		matches = query.match(/^((AND )|(OR )|(AND NOT )|(NOT ))|((AND)|(OR)|(AND NOT )|(NOT))[ ]*$/);
		if (matches != null && matches.length > 0) {
			if (alertUser) alert("Invalid search query!  Queries containing AND/OR/NOT must be in the form: term1 AND|OR|NOT|AND NOT term2 Please try again.");
			return false;
		}
	}
	return true;
};

const checkQuotes = (query: string) => {
	let matches = query.match(/\"/g);
	if (matches != null && matches.length > 0) {
		const number = matches.length;
		if ((number % 2) > 0) {
			if (alertUser) alert("Invalid search query! Please close all quote (\") marks.");
			return false;
		}
		matches = query.match(/""/);
		if (matches != null) {
			if (alertUser) alert("Invalid search query! Quotes must contain at least one character. Please try again.");
			return false;
		}
	}
	return true;
};

const checkColon = (query: string) => {
	const matches = query.match(/[^\\\s]:[\s]|[^\\\s]:$|[\s][^\\]?:|^[^\\\s]?:/);
	if (matches != null) {
		if (alertUser) alert("Invalid search query! Field declarations (:) must be preceded by at least one alphabet or number and followed by at least one alphabet or number. Please try again.");
		return false;
	}
	return true;
};

export { checkLuceneQueryValue };
