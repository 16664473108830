import React from 'react';

import FacebookAuthInstagram from './Auth/Instagram';
import FacebookAuth from './Auth';

import { ComponentProps } from './types';

const facebookInitProps = {
	appId: process.env.REACT_APP_FACEBOOK_APP_ID!,
	version: 'v10.0',
	status: false, // prevent get status automatically, we control it manually
	xfbml: false, // prevent parse, done later if needed
	cookie: true
};

const loadFB = (locale: string = 'en_US'): Promise<fb.FacebookStatic> => {
	return new Promise((resolve, reject) => {
		if (window.FB) return resolve();

		const src = `//connect.facebook.net/${locale}/sdk.js`;
		const script = document.createElement('script');
		script.id = 'facebook-jssdk';
		script.src = src;
		script.async = true;
		script.defer = true;
		script.addEventListener('load', () => resolve(), false);
		script.addEventListener('error', err => reject({ err, msg: `Error loading Facebook JS SDK from ${src}` }), false);
		window.document.body.appendChild(script);
	});
};

const Facebook = (props: ComponentProps) => {
	const { user, dialogMode, onUpdateStatus, onSetDialogMode, onAddNotification } = props;
	const [ready, setReady] = React.useState<boolean>(false);

	// load SDK, init and get current status
	React.useEffect(() => {
		const locale = user.settings.locale.replace('-', '_');
		(async () => {
			try {
				await loadFB(locale);
				window.FB.init(facebookInitProps);
			} catch (err) {
				delete window.FB; // clean
				console.warn('Error loading facebook SDK', err.toString());
				onAddNotification({ t: 'warn.browser_blocking_facebook_requests', level: 'warning' });
				onUpdateStatus(null);
				return;
			}
			// window.FB is loaded and initialized at this point
			setReady(true);
			try {
				window.FB.getLoginStatus(onUpdateStatus);
			} catch (err) {
				console.error('Error getting Facebook login status', err);
				onUpdateStatus(null);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (dialogMode !== 'closed' && !window.FB) {
			onSetDialogMode('closed');
			onAddNotification({ t: 'warn.browser_blocking_facebook_requests', level: 'warning' });
		}
	}, [dialogMode, onSetDialogMode, onAddNotification]);

	const content = React.useMemo(() => {
		if (!ready) return null;
		// renders Auth components only when window.FB is loaded and initialized
		return <>
			<FacebookAuth />
			<FacebookAuthInstagram />
		</>;
	}, [ready]);

	return content;
};

export default Facebook;
