import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import Event from './Event';
import { State } from 'store/types';
import { StateProps, DispatchProps } from './types';

import { operators as horizontalFiltersOperators } from 'store/search/horizontalFilters';
import { operators as filtersOperators } from 'store/search/filters';
import { operators as facetOperators } from 'store/search/facets';
import { FilterFacet } from 'class/Filter';
import { Facet } from 'class/Facet';
import { getAllEvents } from 'store/entities/Event/selectors';

const eventGroupKey = Facet.eventKey;

const mapStateToProps = (state: State): StateProps => ({
	excluded: state.search.horizontalFilters.excludedFields.includes(eventGroupKey),
	printEvents: getAllEvents(state),
	facetFilters: state.search.filters.facetsGroups
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetExcludeField: (set: boolean) => dispatch(horizontalFiltersOperators.setExcludeField({ field: eventGroupKey, set })),
	onSetFacetGroupFilters: (items: FilterFacet[]) => dispatch(filtersOperators.setFacetGroupFilters({
		groupKey: eventGroupKey,
		groupFacetsFiltered: items,
		doSearch: false
	})),
	onFetchEventFacetsGroup: () => dispatch(facetOperators.fetchGroupAPIFacets({ groupKey: eventGroupKey }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Event);
