import React from 'react';
import EventPicker from './Events';
import AuditSearch from './Search/';
import { ComponentProps } from './types';
import AuditDatePicker from './DateRange';

import './AuditFilters.scss';

const AuditFilters = (props: ComponentProps) => {
	return <div className='audit-filter-container'>
		<AuditDatePicker />
		<EventPicker />
		<AuditSearch />
	</div>;
};

export default AuditFilters;