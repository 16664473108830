import { connect } from 'react-redux';

import { DispatchProps, StateProps } from './types';

import FocusFeedDefinitionLayoutProductType from './FocusFeedDefinitionLayoutProductType';

const mapStateToProps = (): StateProps => ({
});

const mapDispatchToProps = (): DispatchProps => ({
});


export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedDefinitionLayoutProductType);
