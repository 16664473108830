import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/ui/report/setup';
import setupSelectors from 'store/ui/report/setup/selectors';
import { DispatchProps, StateProps } from './types';

import ReportSetupSettingsType from './ReportSetupSettingsType';

const mapStateToProps = (state: State): StateProps => ({
	reportType: setupSelectors.getType(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onChangeType: type => dispatch(operators.changeSettings({ type }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSetupSettingsType);
