import React from 'react';
import { withT } from 'lib/i18n';
import { DateColumn } from './DateColumn';
import { DetailsColumn } from './DetailsColumn';
import { AuditDisplay, ComponentProps } from './types';
import { CircularProgress } from 'lib/imports/react-md';
import { Columns, DiscoverTable } from 'components/common/DiscoverTable';
import { Audit, Entity } from 'store/ui/audit/list/types';

import './AuditList.scss';

const AuditList = ({ audits, auditLoaded, onResubscribe, getBrandName, applySort, t }: ComponentProps) => {

	function mapAuditToAuditDisplay(audit: Audit): AuditDisplay {
		return {
			date: audit.date,
			id: getIdText(audit.action, audit.entity),
			user: audit.userName,
			details: getDetailsForAudit(audit.action, audit.entity),
			productSection: audit.productSection
		};
	}

	function getIdText(action: string, entity: Entity) {
		if (!action.includes("Branding") || action.includes("Branding Sync")) return entity.id;
		if (entity.id) return `${entity.id} - ${getBrandName(entity.id)}`;
		return '';
	}

	function getDetailsForAudit(action: string, entity: Entity) {
		return `${action}d: ${entity.id}`;
	}

	function onDateSort(value: 'asc' | 'desc') {
		applySort('date', value);
	}

	const renderDetails = (audit: Audit) => (
		<DetailsColumn
			audit={audit}
			action={audit.action}
			onResubscribe={(id:string, email?: string) => onResubscribe(id, email as string)}
		/>
	);

	const renderDate = (audit: Audit) => (
		<DateColumn utcDate={audit.date} />
	);

	const renderId = (audit: Audit) => {
		if (audit.productSection === 'Newsletter') return <a href={`/newsletter/${audit.entity.id}`}>{audit.entity.id}</a>;
		else return <div>{getIdText(audit.action, audit.entity)}</div>;
	};

	const auditColumns: Columns<AuditDisplay, Audit>[] = [
		{
			sort: true,
			key: 'date',
			type: 'custom',
			render: renderDate,
			onClick: onDateSort,
			initialValue: 'desc',
			alignVertically: 'center',
			displayName: t('audit_page_list_date_column'),
			help: {
				helpAlignment: 'right',
				tooltipPosition: 'bottom',
				text: t('audit_page_list_date_column_help'),
				tooltipStyles: ['audit-page-tooltip-help']
			}
		},
		{
			key: 'user',
			type: 'text',
			alignHorizontally: 'left',
			alignVertically: 'center',
			displayName: t('audit_page_list_user_column')
		},
		{
			type: 'text',
			key: 'productSection',
			alignVertically: 'center',
			displayName: t('audit_page_list_product_section_column')
		},
		{
			key: 'details',
			type: 'custom',
			render: renderDetails,
			alignVertically: 'center',
			displayName: t('audit_page_list_details_column')
		},
		{
			key: 'id',
			type: 'custom',
			render: renderId,
			displayName: t('audit_page_list_id_column'),
			alignVertically: 'center',
			help: {
				helpAlignment: 'right',
				tooltipPosition: 'bottom',
				text: t('audit_page_list_id_column_help'),
				tooltipStyles: ['audit-page-id-column-help']
			}
		}
	];

	return (
		<div className="audit-table-container">
			<DiscoverTable
				data={audits}
				columns={auditColumns}
				map={mapAuditToAuditDisplay}
				rowStyle={["audit-list-row"]}
				tableStyle={['discover-entity-list-table', 'audit-list']}
			/>
			{!auditLoaded && <CircularProgress id="auditsListLoading" scale={1.25} />}
		</div>
	);
};

export default withT(AuditList);
