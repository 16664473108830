import { Action, actionCreatorFactory } from 'typescript-fsa';
import { FocusObject } from 'class/Focus';
import { TenantUser } from 'class/Tenant';
import { FeedObject } from 'class/Feed';

const actionCreator = actionCreatorFactory('@@focus/list');

type Payloads = {
	FetchFocusList: void;
	FetchFocusListSuccess: { focusList: FocusObject[] };
	FetchFocusListError: { error: object };
	CreateFocus: { name: string };
	CreateFocusSuccess: { focusList: FocusObject[] };
	CreateFocusError: { error: object };
	RenameFocus: { focus: FocusObject, name: string };
	RenameFocusSuccess: { focusList: FocusObject[] };
	RenameFocusError: { error: object };
	RemoveFocus: { focus: FocusObject };
	RemoveFocusSuccess: { focusList: FocusObject[] };
	RemoveFocusError: { error: object };
	RemoveFeed: { focus: FocusObject, feed: FeedObject };
	RemoveFeedSuccess: { focusList: FocusObject[] };
	RemoveFeedError: { error: object };
	UpdateFocusUserAllowAccess: { readonly focusId: string, readonly user: TenantUser, readonly allow: boolean };
	UpdateFocusUserAllowAccessSuccess: { readonly focusList: FocusObject[] };
	UpdateFocusUserAllowAccessError: { readonly error: object };
	AddManageFocusUserLoading: { readonly userId: string };
	RemoveManageFocusUserLoading: { readonly userId: string };
}

export type Actions = {
	FetchFocusList: Action<Payloads['FetchFocusList']>;
	FetchFocusListSuccess: Action<Payloads["FetchFocusListSuccess"]>;
	FetchFocusListError: Action<Payloads["FetchFocusListError"]>;
	CreateFocus: Action<Payloads["CreateFocus"]>;
	CreateFocusSuccess: Action<Payloads["CreateFocusSuccess"]>;
	CreateFocusError: Action<Payloads["CreateFocusError"]>;
	RenameFocus: Action<Payloads["RenameFocus"]>;
	RenameFocusSuccess: Action<Payloads["RenameFocusSuccess"]>;
	RenameFocusError: Action<Payloads["RenameFocusError"]>;
	RemoveFocus: Action<Payloads["RemoveFocus"]>;
	RemoveFocusSuccess: Action<Payloads["RemoveFocusSuccess"]>;
	RemoveFocusError: Action<Payloads["RemoveFocusError"]>;
	RemoveFeed: Action<Payloads["RemoveFeed"]>;
	RemoveFeedSuccess: Action<Payloads["RemoveFeedSuccess"]>;
	RemoveFeedError: Action<Payloads["RemoveFeedError"]>;
	UpdateFocusUserAllowAccess: Action<Payloads["UpdateFocusUserAllowAccess"]>;
	UpdateFocusUserAllowAccessSuccess: Action<Payloads["UpdateFocusUserAllowAccessSuccess"]>;
	UpdateFocusUserAllowAccessError: Action<Payloads["UpdateFocusUserAllowAccessError"]>;
	AddManageFocusUserLoading: Action<Payloads["AddManageFocusUserLoading"]>;
	RemoveManageFocusUserLoading: Action<Payloads["RemoveManageFocusUserLoading"]>;
}

export const operators = {
	fetchFocusList: actionCreator<Payloads["FetchFocusList"]>('FETCH_FOCUS_LIST', { api: 'start' }),
	fetchFocusListSuccess: actionCreator<Payloads["FetchFocusListSuccess"]>('FETCH_FOCUS_LIST_SUCCESS', { api: 'success' }),
	fetchFocusListError: actionCreator<Payloads["FetchFocusListError"]>('FETCH_FOCUS_LIST_ERROR', { api: 'error' }),
	createFocus: actionCreator<Payloads["CreateFocus"]>('CREATE_FOCUS', { api: 'start' }),
	createFocusSuccess: actionCreator<Payloads["CreateFocusSuccess"]>('CREATE_FOCUS_SUCCESS', { api: 'success' }),
	createFocusError: actionCreator<Payloads["CreateFocusError"]>('CREATE_FOCUS_ERROR', { api: 'error' }),
	renameFocus: actionCreator<Payloads["RenameFocus"]>('RENAME_FOCUS', { api: 'start' }),
	renameFocusSuccess: actionCreator<Payloads["RenameFocusSuccess"]>('RENAME_FOCUS_SUCCESS', { api: 'success' }),
	renameFocusError: actionCreator<Payloads["RenameFocusError"]>('RENAME_FOCUS_ERROR', { api: 'error' }),
	removeFocus: actionCreator<Payloads["RemoveFocus"]>('REMOVE_FOCUS', { api: 'start' }),
	removeFocusSuccess: actionCreator<Payloads["RemoveFocusSuccess"]>('REMOVE_FOCUS_SUCCESS', { api: 'success' }),
	removeFocusError: actionCreator<Payloads["RemoveFocusError"]>('REMOVE_FOCUS_ERROR', { api: 'error' }),
	removeFeed: actionCreator<Payloads["RemoveFeed"]>('REMOVE_FEED', { api: 'start' }),
	removeFeedSuccess: actionCreator<Payloads["RemoveFeedSuccess"]>('REMOVE_FEED_SUCCESS', { api: 'success' }),
	removeFeedError: actionCreator<Payloads["RemoveFeedError"]>('REMOVE_FEED_ERROR', { api: 'error' }),
	updateFocusUserAllowAccess: actionCreator<Payloads["UpdateFocusUserAllowAccess"]>('UPDATE_FOCUS_USER_ALLOW_ACCESS', { api: 'start' }),
	updateFocusUserAllowAccessSuccess: actionCreator<Payloads["UpdateFocusUserAllowAccessSuccess"]>('UPDATE_FOCUS_USER_ALLOW_ACCESS_SUCCESS', { api: 'success' }),
	updateFocusUserAllowAccessError: actionCreator<Payloads["UpdateFocusUserAllowAccessError"]>('UPDATE_FOCUS_USER_ALLOW_ACCESS_ERROR', { api: 'error' }),
	addManageFocusUserLoading: actionCreator<Payloads["AddManageFocusUserLoading"]>('ADD_MANAGE_FOCUS_USER_LOADING'),
	removeManageFocusUserLoading: actionCreator<Payloads["RemoveManageFocusUserLoading"]>('REMOVE_MANAGE_FOCUS_USER_LOADING')
};
