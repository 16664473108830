import React, { useEffect, useRef, useState } from "react";
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import './ButtonWithDropdown.scss';

export type ItemProps = {
	id?: string,
	label?: string,
	url?: string,
	icon?: JSX.Element,
	onClick?: () => void,
	hide?: boolean,
	disabled?: boolean
	component?: JSX.Element
}

export default function ButtonWithDropdown({ text, items, id }: { text: string, items: ItemProps[], id: string }) {
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = useState(false);

	const handleClickOutside = (event: MouseEvent) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleClick = (item: ItemProps) => {
		setIsOpen(false);
		if(item.url) {
			window.open(item.url, '_blank');
		} else {
			if(item.onClick) item.onClick();
		}
	};

	return (
		<div id={id} className="button-dropdown" ref={dropdownRef}>
			<button onClick={() => setIsOpen(!isOpen)}>
				{text} {isOpen ? <FontIcon>keyboard_arrow_up</FontIcon> : <FontIcon>expand_more</FontIcon>}
			</button>
			{
				isOpen && <div className="dropdown-wrapper">
					<div className='dropdown-content-wrapper'>
						{items.map((item, index) => {
							if (item.hide) return null;
							if(item.component) return <div key={`dropdown-item-${index}`}>{item.component}</div>;
							return (
								<div key={item.id || `dropdown-item-${item.label}-${index}`}>
									<button className="dropdown-item-content" id={item.id} onClick={() => handleClick(item)} disabled={item.disabled}>
										<div className="dropdown-item-info">
											<div className="dropdown-item-icon">{item.icon}</div>
											<p>{item.label}</p>
										</div>
										{item.url && <div className="dropdown-item-link-icon"><FontIcon>open_in_new</FontIcon></div>}
									</button>
								</div>
							);
						})}
					</div>
				</div>
			}
		</div>
	);
}
