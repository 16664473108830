import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import selectors from 'store/ui/newsletter/draft/edit/selectors';
import { operators as notificationOperators } from 'store/app/notifications';

import { DispatchProps, StateProps } from './types';
import NewsletterDraftEditContent from './NewsletterDraftEditContent';

const mapStateToProps = (state: State): StateProps => ({
	previewHtml: selectors.getPreviewHtml(state)!,
	updatedSectionsHtml: selectors.getUpdatedSectionsHtml(state)!,
	status: selectors.getStatus(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onAddNotification: notification => dispatch(notificationOperators.add({ notification }))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterDraftEditContent);
