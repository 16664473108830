import { Model, attr } from "redux-orm";
import { isArray } from "lib/imports/lodash";

import { createOrmReducer, ReducerOrm } from "lib/store/reducer";
import { Schema } from "store/entities/schema";

import { FeedInstance } from "store/entities/Feed";
import { Actions, operators } from "./actions";
import { FocusFields, FocusData, FocusInstance, FocusModel } from "./types";

export class Focus extends Model<typeof Focus, FocusFields> {
	static get modelName() { return 'Focus' as const; };
	static get idAttribute() { return 'id' as const; };
	static get fields() {
		return {
			id: attr(),
			name: attr()
		};
	};

	static reducer: ReducerOrm<FocusModel> = createOrmReducer<FocusModel>(operators, Focus => ({
		parse: ({ payload: focusData }: Actions["Parse"]) => {
			const focusDataArray = isArray(focusData) ? focusData : [focusData];
			focusDataArray.forEach(focusData => (Focus as any).parse(focusData));
		},
		deleteCascade: ({ payload: id }: Actions["DeleteCascade"]) => {
			const model = Focus.withId(id);
			if (!model) return;
			model.feeds.all().toModelArray().forEach((feed: FeedInstance) => feed.delete());
			model.delete();
		}
	}));

	public static parse(focusData: FocusData): FocusInstance {
		const { Feed }: Schema = (this as any).session;
		const { feeds, ...upsertData } = focusData;

		const focus: FocusInstance = this.upsert(upsertData);
		if (feeds) feeds.forEach(feed => Feed.parse({ ...feed, focus }));

		return focus;
	}
}
