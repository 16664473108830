import React from 'react';
import { Button } from 'lib/imports/react-md';
import { isEmpty, transform } from 'lib/imports/lodash';

import GA from 'lib/googleAnalytics';
import { withT } from 'lib/i18n';
import { DocumentPerson } from 'class/Document';
import CategorizationTopicTagChip from 'components/common/Categorization/TopicTag/Chip';
import { ComponentProps } from './types';

import "./DocumentCategorization.scss";

const getPersonName = (person: DocumentPerson) => {
	const replaceRegex = /^-+$/;
	return `${!person.person_name ? '' : person.person_name.replace(replaceRegex, '')} ${!person.person_surname ? '' : person.person_name.replace(replaceRegex, '')}`;
};

const DocumentCategorization = (props: ComponentProps) => {
	const { t, document, user, getFullCategoryName } = props;
	const { onRemoveDocumentCategory, onRemoveDocumentTag } = props;

	const [showPopup, setShowPopup] = React.useState(false);
	const [editMode, setEditMode] = React.useState(false);

	const canRemoveCategories = React.useMemo(() => !['Print', 'DMR'].includes(document.provider) && editMode && user.hasPermission('document.categorize'), [editMode, document, user]);
	const canRemoveTags = React.useMemo(() => editMode && user.hasPermission('document.tag'), [editMode, user]);

	const getCategoryName = React.useCallback((categoryId: string, indexSplit: number = 0) => t('filters.categories.' + (indexSplit ? categoryId.substring(0, indexSplit) : categoryId)), [t]);
	const onShowPopup = React.useCallback(() => setShowPopup(true), []);
	const onHidePopup = React.useCallback(() => setShowPopup(false), []);
	const onToggleEditMode = React.useCallback(() => setEditMode(mode => !mode), []);

	const companyContent = React.useMemo(() => {
		if ((!showPopup && !editMode) || !document.company) return null;
		return (
			<div className="company-container">
				<div className="popup-info-label">{t('doc.company.company')} » {t('doc.company.brand')} » {t('doc.company.line')}</div>
				<div className="popup-info-content">{document.company} » {document.brand_associated} » {document.line}</div>
			</div>
		);
	}, [t, document, showPopup, editMode]);

	const categoryContent = React.useMemo(() => {
		if ((!showPopup && !editMode) || !document.category_id) return null;

		const oldCategoryName = `${getCategoryName(document.category_id, 2)} » ${getCategoryName(document.category_id, 4)} » ${getCategoryName(document.category_id)}`;
		const dmrCategoryName = getFullCategoryName(document.category_id);
		const categoryName = document.category_id.includes('-') ? dmrCategoryName : oldCategoryName;

		return (
			<div className="category-container">
				<div className="popup-info-label">{t('doc.categorization.group')} » {t('doc.categorization.category')} » {t('doc.categorization.sector')}</div>
				<div className="popup-info-content">
					<div className={`categorization-label${canRemoveCategories ? ' can-remove-categorization' : ''}`}>
						<span>
							{categoryName}
						</span>
						{canRemoveCategories ?
							<Button
								icon
								className='delete-categorization-btn'
								data-id={document.id}
								onClick={() => {
									GA.trackEvent({ category: "Feed results", action: 'Categorize mentions', label: 'Delete Category' });
									onRemoveDocumentCategory(document.id, document.category!);
								}}>
								close
							</Button>
							: null}
					</div>
				</div>
			</div>
		);
	}, [showPopup, editMode, document.category_id, document.id, document.category, getCategoryName, getFullCategoryName, t, canRemoveCategories, onRemoveDocumentCategory]);

	const getPersonContent = React.useCallback((persons: DocumentPerson[], type: string) => {
		if (isEmpty(persons)) return null;

		return (
			<div className={`${type}-container`}>
				<div className="popup-info-label">{t(`doc.categorization.${type}`)}</div>
				<div className="popup-info-content">{persons.map(getPersonName).join(', ')}</div>
			</div>
		);
	}, [t]);

	const personsContent = React.useMemo(() => {
		if (!document.persons) return null;

		const { stylists, photographers } = transform<DocumentPerson, Record<string, DocumentPerson[]>>(document.persons, (result, person) => {
			if (person.person_role === 'Stylist') result.stylists.push(person);
			else if (person.person_role === 'Photographer') result.photographers.push(person);
		}, { stylists: [], photographers: [] });

		return (
			<>
				{getPersonContent(stylists, 'stylist')}
				{getPersonContent(photographers, 'photographer')}
			</>
		);
	}, [document, getPersonContent]);

	const tagsContent = React.useMemo(() => {
		if ((!showPopup && !editMode) || isEmpty(document.topic_tag)) return null;

		let tags: JSX.Element[] = [];
		tags = document.topic_tag!.map(topicTagId => (
			<CategorizationTopicTagChip
				key={topicTagId}
				tagId={topicTagId.split('_')[1]}
				isRemovable={canRemoveTags}
				onTagRemoved={() => {
					GA.trackEvent({ category: "Feed results", action: 'Categorize mentions', label: 'Delete Tag' });
					onRemoveDocumentTag(document.id, topicTagId);
				}}
			/>
		));

		return (
			<div className="tags-container">
				<div className="popup-info-label">{t('doc.categorization.tag')}</div>
				<div className="popup-info-content">
					{tags}
				</div>
			</div>
		);
	}, [t, document, showPopup, editMode, canRemoveTags, onRemoveDocumentTag]);

	const popupContent = React.useMemo(() => {
		if (!showPopup && !editMode) return null;
		return (
			<div className="popup-container">
				{companyContent}
				{categoryContent}
				{personsContent}
				{tagsContent}
			</div>
		);
	}, [companyContent, categoryContent, personsContent, tagsContent, showPopup, editMode]);

	const buttonClass = `icon-tag${showPopup ? ' active-popup' : ''}`;
	return (
		<div className="document-item-categorization">
			{popupContent}
			<Button
				icon
				className={buttonClass}
				data-id={document.id}
				onMouseEnter={onShowPopup}
				onMouseLeave={onHidePopup}
				onClick={onToggleEditMode}
			></Button>
		</div>
	);
};

export default withT(React.memo(DocumentCategorization));
