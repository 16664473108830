import React from 'react';
import { withT } from 'lib/i18n';
import { SelectField } from 'lib/imports/react-md';
import { map } from 'lib/imports/lodash';

import { REPORT_LANGUAGES, REPORT_CURRENCIES } from "store/ui/report/consts";
import { ComponentProps } from './types';


const ReportSetupSettingsLanguageCurrency: React.FC<ComponentProps> = props => {
	const { t, reportLanguage, reportCurrency } = props;
	const { onChangeLanguage, onChangeCurrency } = props;

	const languageSelectItems = React.useMemo(() => map(REPORT_LANGUAGES, language => (
		{ label: t(`reports.language_${language}`), value: language }
	)), [t]);

	const currencySelectItems = React.useMemo(() => map(REPORT_CURRENCIES, currency => (
		{ label: t(`reports.currency_${currency}`), value: currency }
	)), [t]);

	return (
		<div id="reportSetupSettingsLanguageCurrency" className="form-section" >
			<div className="form-section-title">
				{t('reports.language_and_currency')}
			</div>
			<div className="form-section-content">
				<SelectField
					id="reportSetupSettingsLanguageSelect"
					label={t("reports.export_in")}
					menuItems={languageSelectItems}
					value={reportLanguage}
					position="br"
					onChange={language => onChangeLanguage(language.toString() as typeof reportLanguage)}
				/>
				<SelectField
					id="reportSetupSettingsCurrencySelect"
					label={t("reports.currency")}
					menuItems={currencySelectItems}
					value={reportCurrency}
					position="br"
					onChange={currency => onChangeCurrency(currency.toString() as typeof reportCurrency)}
				/>
			</div>
		</div>
	);
};

export default withT(React.memo(ReportSetupSettingsLanguageCurrency));
