import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { DispatchProps, StateProps } from './types';
import { State } from 'store/types';
import { operators } from 'store/focus/feed/definition/printDmr/actions';

import FocusFeedDefinitionPrintDmr from './FocusFeedDefinitionPrintDmr';

const mapStateToProps = ({ focus: { feed: { printDmr: { definition, mediaItems, brandItems, productTypeItems } } } }: State): StateProps => ({
	definition,
	mediaItems,
	brandItems,
	brands: definition.brands,
	productTypeItems,
	productTypes: definition.product_types
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onFetchMedias: query => dispatch(operators.fetchDmrMedias({query})),
	onResetMedias: () => dispatch(operators.resetDmrMedias()),
	onSetDMRDefinition: definition => dispatch(operators.setDMRDefinition({ definition })),
	onSetDMRExpression: (expressionKey, index, values) => dispatch(operators.setDMRExpression({ expressionKey, index, values })),
	onFetchProductTypes: (query, exclude?) => dispatch(operators.fetchDmrProductTypes({ query, exclude })),
	onResetProductTypes: () => dispatch(operators.resetDmrProductTypes()),
	onFetchBrands: (query, exclude?) => dispatch(operators.fetchDmrBrands({ query, exclude })),
	onResetBrands: () => dispatch(operators.resetDmrBrands()),
	onAddBrandExpression: expression => dispatch(operators.addPrintDmrExpression({ type: "brands", expression })),
	onAddProductTypeExpression: expression => dispatch(operators.addPrintDmrExpression({ type: "product_types", expression })),
	onUpdateBrandExpression: (index, expression) => dispatch(operators.updatePrintDmrExpression({ type: "brands", index, expression })),
	onUpdateProductTypeExpression: (index, expression) => dispatch(operators.updatePrintDmrExpression({ type: "product_types", index, expression })),
	onRemoveBrandExpression: index => dispatch(operators.removePrintDmrExpression({type: "brands", index})),
	onRemoveProductTypeExpression: index => dispatch(operators.removePrintDmrExpression({type: "product_types", index}))
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedDefinitionPrintDmr);
