import { Middleware, AnyAction } from 'redux';
import { get, has, forEach, isObject, isArray } from 'lib/imports/lodash';

import errorsConfig from 'config/apiResponse/error';
import successConfig from 'config/apiResponse/success';
import { operators } from 'store/app/notifications';
import { Notification } from 'types/notification';

const defaultApiErrorNotification: Notification = {
	t: "error.api_server.value",
	level: "danger"
};

const middleware: Middleware = store => next => (action: AnyAction) => {
	const notification = getNotification(action);
	if (notification) store.dispatch(operators.add({ notification }));
	next(action);
};

function getNotification(action: AnyAction): Notification | null {
	const [actionGroup, actionName] = action.type.split(/\/(?=[^/]+$)/);

	if (get(action, ['meta', 'api'], '') === "success") {
		const notification = get(successConfig, [actionGroup, actionName]);
		if (notification) return notification;

	} else if (get(action, ['meta', 'api'], '') === "error") {
		const errorCode = get(action, ['payload', 'error', 'code']) || get(action, ['payload', 'error']) || get(action, ['payload', 'code']);
		if (!errorCode) {
			console.error("Error code not found in action:", action);
			return defaultApiErrorNotification;
		}

		const explicitAvoidNotification = __avoidNotification(actionGroup, actionName, errorCode);
		if (explicitAvoidNotification === true) return null; // avoid notification by config

		const configResult = __getNotificationConfig(actionGroup, actionName, errorCode);

		if (configResult === false) return null; // avoid notification by config

		const notification: Notification = configResult || defaultApiErrorNotification;

		if (notification.t && isArray(notification.t)) {
			const interpolation = notification.t[1];
			const parsedInterpolation: { [key: string]: string } = {};
			forEach(interpolation, (value, key) => {
				if (has(value, 'path')) parsedInterpolation[key] = get(action.payload, get(value, 'path'));
				else parsedInterpolation[key] = value as string;
			});
			notification.t[1] = parsedInterpolation;
		}
		if (notification.text && has(notification.text, 'path')) {
			notification.text = get(action.payload, get(notification.text, 'path'));
		}
		if (get(action, ['meta', 'reload'])) {
			notification.reload = true;
			notification.timeout = false;
		}
		return notification;
	}
	return null;
}

function __getNotificationConfig(actionGroup: string, actionName: string, errorCode: string): Notification | false | undefined {
	let result = get(errorsConfig, [actionGroup, actionName, errorCode]);
	if (__isNotificationConfig(result)) return result;
	result = get(errorsConfig, [actionGroup, actionName]);
	if (__isNotificationConfig(result)) return result;
	result = get(errorsConfig, [actionGroup]);
	if (__isNotificationConfig(result)) return result;
	result = get(errorsConfig, ["common", errorCode]);
	if (__isNotificationConfig(result)) return result;
	return undefined;
}

function __avoidNotification(actionGroup: string, actionName: string, errorCode: string) {
	return get(errorsConfig, [actionGroup, actionName, errorCode, 'avoid']);
}

function __isNotificationConfig(candidate: any) {
	if (candidate === false) return true;
	if (isObject(candidate) && ('t' in candidate || 'text' in candidate)) return true;
	return false;
}

export default middleware;
