import React from 'react';
import './DocumentItemImage.scss';
import GA from 'lib/googleAnalytics';

export type OwnProps = {
	mainImage: string,
	mainLink: string,
	secondaryImage?: string,
	secondaryLink?: string,
};

type ImageType = 'main' | 'secondary';

type ComponentState = {
	activeImage: ImageType,
};

class DocumentItemImage extends React.Component<OwnProps, ComponentState> {

	constructor(props: OwnProps) {
		super(props);
		this.state = { activeImage: 'main' };
	}

	public render() {
		const { mainImage, secondaryImage, mainLink, secondaryLink } = this.props;
		const { activeImage } = this.state;
		const imagesToDisplay: ImageType[] = secondaryImage ? ['secondary', 'main'] : ['main'];
		const containerClass = imagesToDisplay.length === 2 ? "document-item-image two-images" : "document-item-image";
		const baseClass = "document-item-image-link";
		const activeClass = baseClass + " active-image";

		return (
			<div className={containerClass}>
				{
					imagesToDisplay.map((type: ImageType) => {
						const targetLink = type === 'secondary' ? secondaryLink : mainLink;
						const imageUrl = type === 'secondary' ? secondaryImage : mainImage;
						return (
							<a
								key={"item_image_" + type}
								href={targetLink}
								target="_blank"
								rel="noopener noreferrer"
								type={type}
								className={activeImage === type ? activeClass : baseClass}
								onClick={ev => {
									if (type === this.state.activeImage) GA.trackEvent({ category: 'Feed results', action: 'Article' });
									else {
										ev.preventDefault();
										this.setState({ activeImage: type });
									}
								}
								}>
								<img src={imageUrl} onError={e => (e.target as HTMLImageElement).remove()} alt=""></img>
							</a>
						);
					})
				}
			</div>
		);
	}
}

export default DocumentItemImage;
