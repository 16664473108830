import { FacetItem } from "class/Facet";

export enum CheckStyle {
	UNCHECKED,
	DASHED,
	CHECKED
}

export type ComponentProps = {
	optionItem: FacetItem,
	isChecked: number,
	onClick: (op: FacetItem) => void
	isChildrenOpen?: boolean
	onChildrenToggle?: (parent: FacetItem) => void
};
