import React from 'react';

import { Autocomplete } from 'lib/imports/react-md';
import { ComponentProps } from './types';
import { MediaExpression } from 'class/Feed';

import './MediaAutocompleter.scss';

type PreparedMediaOption = MediaExpression & {
	label: JSX.Element
};

const MediaAutocompleter = (props: ComponentProps) => {
	const { expressionKey, mediaItems, showUrlPlaceholder = false } = props;
	const { onFetchMedias, onResetMedias, onAddMedia } = props;

	const onChangeHandler = React.useCallback((value: string) => {
		const parsedQuery = value.trim().replace(/https?:\/\//, '').replace(':', ' ');
		if (parsedQuery.length < 3) onResetMedias();
		else onFetchMedias(parsedQuery);
	}, [onFetchMedias, onResetMedias]);

	const onSelectedMedia = React.useCallback((value: React.ReactText, index: number) => {
		const media = mediaItems[index];
		onAddMedia(expressionKey, media);
	}, [expressionKey, mediaItems, onAddMedia]);

	const items: PreparedMediaOption[] = React.useMemo(() => mediaItems.map(media => {
		const mediaCountry = media.country ? ' - ' + media.country : '';
		const mediaUrl = media.url ? media.url : '';
		return {
			...media,
			label: (<span className="media-option-name">{media.name}{mediaCountry}<span className="media-option-url">{mediaUrl}</span></span>)
		};
	}), [mediaItems]);

	return (
		<Autocomplete
			id={`FocusFeedDefinitionMediaAutocompleter-${expressionKey}`}
			className="focus-feed-definition-media-autocompleter"
			fullWidth={false}
			sameWidth={false}
			// @ts-ignore
			data={items}
			dataLabel="label"
			dataValue="id"
			filter={null}
			placeholder={showUrlPlaceholder ? "https://" : ''}
			clearOnAutocomplete={true}
			onChange={onChangeHandler}
			onAutocomplete={onSelectedMedia}
		/>
	);
};

export default React.memo(MediaAutocompleter);
