import React from "react";
import { isEmpty, map } from "lodash";

import { withT } from "lib/i18n";
import Autocompleter, { AutocompleterItem } from 'components/common/Input/Autocompleter';

import { ComponentProps } from './types';

import './LinkedBrand.scss';

const LinkedBrand = (props: ComponentProps) => {
	const { t, onFetchLinkedBrand, onResetLinkedBrand, onAddLinkedBrand } = props;
	const { linkedBrandsResult, linkedBrandId, linkedBrandList } = props;

	const [selectedLinkedBrand, setSelectedLinkedBrand] = React.useState('');

	const onChangeBrand = React.useCallback((value: string) => {
		const parsedQuery = value.trim();
		if (parsedQuery.length < 2) onResetLinkedBrand();
		else onFetchLinkedBrand(parsedQuery);
	}, [onFetchLinkedBrand, onResetLinkedBrand]);

	const onSelectedBrand = React.useCallback((brand: AutocompleterItem | null) => {
		if (!brand) {
			setSelectedLinkedBrand('');
			onAddLinkedBrand({ id: '', name: '', isCrawled: false});
		} else {
			setSelectedLinkedBrand(brand.text);
			onAddLinkedBrand({ id: brand.id, name: brand.text, isCrawled: brand.isCrawled });
		}
	}, [onAddLinkedBrand]);

	const items: { id: string, text: any }[] = React.useMemo(() => {
		if (isEmpty(linkedBrandsResult)) return [];
		return map(linkedBrandsResult, brand => ({
			id: brand.id,
			text: <span title={`${brand.id}`}>{brand.name}</span>,
			isCrawled: brand.isCrawled
		}));
	}, [linkedBrandsResult]);

	const brandInput = React.useMemo(() => {
		const linkedBrand = linkedBrandList.find(brand => brand.id === linkedBrandId);
		setSelectedLinkedBrand(linkedBrand ? linkedBrand.name : '');
		return (
			<Autocompleter
				key={`LinkedBrandAutocompleter`}
				id={`LinkedBrandAutocompleter`}
				className="definition-linked-brand-autocompleter"
				fullWidth={false}
				sameWidth={false}
				filter={null}
				items={items}
				placeholder={t('feed.form.label.not_linked_to_brand')}
				defaultText={selectedLinkedBrand}
				onChange={value => onChangeBrand(value)}
				onSelected={item => onSelectedBrand(item)}
			/>
		);
	}, [linkedBrandList, items, selectedLinkedBrand, linkedBrandId, onChangeBrand, onSelectedBrand, t]);

	return brandInput;
};

export default withT(React.memo(LinkedBrand));
