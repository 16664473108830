import { transform } from "./imports/lodash";

export type FeatureFlagsData = string[];
export type FeatureFlagsConfig = Record<string, true>;

class FeatureFlags {

	private config: FeatureFlagsConfig;

	public setConfig(ffData: FeatureFlagsData) {
		this.config = transform(ffData, (result: FeatureFlagsConfig, ff) => {
			result[ff] = true;
		}, {});
	}

	public isEnabled(ff: string): boolean {
		if (!this.config) {
			console.warn(`Missing Feature Flags config on isEnabled(${ff}). returning false`);
			return false;
		}
		return ff in this.config;
	}
}

export default new FeatureFlags();
