import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { operators } from 'store/focus/feed/definition/print/actions';
import { State } from 'store/types';

import { DispatchProps, StateProps } from './types';
import FocusFeedDefinitionPrintSectionBrand from './FocusFeedDefinitionPrintSectionBrand';

const mapStateToProps = ({ focus: { feed: { print: { brandsApiResponse, definition } } } }: State): StateProps => ({
	brandsApiResponse,
	brands: definition.brands
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onFetchBrands: (query, excluded = {}, limit = 50) => dispatch(operators.fetchBrands({ query, excluded, limit })),
	onResetBrands: () => dispatch(operators.resetBrands()),
	onAddExpression: expression => dispatch(operators.addPrintExpression({ type: 'brands', expression })),
	onUpdateExpression: (index, expression) => dispatch(operators.updatePrintExpression({ type: 'brands', index, expression }))
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedDefinitionPrintSectionBrand);
