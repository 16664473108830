import actionCreatorFactory, { Action } from 'typescript-fsa';

const actionCreator = actionCreatorFactory('@@report/direct');

type Payloads = {
	ExportQuickReport: void;
	ToggleQuickReport: boolean;
	SetReportedFocusNames: { focuses: string[] };
}

export type Actions = {
	ExportQuickReport: Action<Payloads["ExportQuickReport"]>;
	ToggleQuickReport: Action<Payloads["ToggleQuickReport"]>;
	SetReportedFocusNames: Action<Payloads["SetReportedFocusNames"]>;
}

export const operators = {
	exportQuickReport: actionCreator<Payloads["ExportQuickReport"]>('EXPORT_QUICK_REPORT', { api: 'start' }),
	toggleQuickReport: actionCreator<Payloads["ToggleQuickReport"]>('TOGGLE_QUICK_REPORT'),
	setReportedFocusNames: actionCreator<Payloads["SetReportedFocusNames"]>('SET_CREATED_FOCUS_NAMES')
};
