import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { Actions, operators } from './actions';

import { ReportFeeds, ReportStep, ReportFeedClippings, ReportSettings } from './types';

export type State = Readonly<{
	step: ReportStep
	settings: ReportSettings,
	feeds: ReportFeeds,
	feedClippings: ReportFeedClippings,
	feedsLoaded: boolean,
	linkedBrandId: string | null
}>;

export const INITIAL_STATE: State = {
	step: "settings",
	settings: {
		type: 'coverage',
		period: 'monthly',
		language: 'en',
		currency: 'EUR',
		minDate: null,
		maxDate: null,
		country: 'all',
		groupedByCountry: false,
		topicTagIds: [],
		categories: [],
		topicTagOperator: 'OR'
	},
	feeds: {},
	feedClippings: [],
	feedsLoaded: false,
	linkedBrandId: null
};

const reducerHandlers: ReducerHandlers<State> = {
	resetSetup: (state): State => INITIAL_STATE,
	setStep: (state, { payload: step }: Actions['SetStep']): State => ({ ...state, step }),
	setSettings: (state, { payload: settings }: Actions['SetSettings']): State => ({ ...state, settings: { ...state.settings, ...settings } }),
	applySettings: (state): State => ({ ...state, step: "feed" }),
	fetchFeeds: (state): State => ({ ...state, feedsLoaded: false }),
	fetchFeedsSuccess: (state, { payload: feeds }: Actions['FetchFeedsSuccess']): State => ({
		...state,
		feeds,
		feedsLoaded: true
	}),
	changeFeedSettings: (state, { payload: { feedId, settings } }: Actions['ChangeFeedSettings']): State => ({
		...state,
		feeds: {
			...state.feeds,
			[feedId]: {
				...state.feeds[feedId],
				...settings
			}
		}
	}),
	unselectAllFeeds: (state): State => {
		const unselectedFeeds: ReportFeeds = {};
		Object.keys(state.feeds).forEach(feedId => {
			unselectedFeeds[feedId] = {
				...state.feeds[feedId],
				selected: false
			};
		});
		return {
			...state,
			feeds: unselectedFeeds
		};
	},
	setFeedClippings: (state, { payload: feedClippings }: Actions['SetFeedClippings']): State => ({ ...state, feedClippings }),
	setLinkedBrandId: (state, { payload: linkedBrandId }: Actions["SetLinkedBrandId"]): State => ({ ...state, linkedBrandId })
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
