import React from 'react';

import { withT } from 'lib/i18n';
import { Button } from 'lib/imports/react-md';
import { ComponentProps } from './types';

import './Paginator.scss';

const buttonPlaceholder = <div className="button-placeholder"></div>;

const Paginator = (props: ComponentProps) => {
	const { limit, start, total } = props;
	const { onStartChange, t } = props;

	const totalPages = Math.ceil(Math.min(total, 5000) / limit);
	const page = Math.ceil((start / limit) + 1);

	const pages = [];
	const offset = 3;

	// first two pages
	pages.push({ number: 1, selected: page === 1 });
	if (totalPages > 1) pages.push((page <= (offset + 3)) ? { number: 2, selected: page === 2 } : { morepages: true });

	for (let pageNumber = (page - offset); pageNumber <= (page + offset); pageNumber++) { // middle pages (with two pages of offset on each side)
		if (pageNumber > 2 && pageNumber < (totalPages - 1)) pages.push({ number: pageNumber, selected: page === pageNumber });
	}

	// last two pages
	if (totalPages > 3) pages.push((page >= (totalPages - offset - 2)) ? { number: totalPages - 1, selected: page === totalPages - 1 } : { morepages: true });
	if (totalPages > 2) pages.push({ number: totalPages, selected: page === totalPages });

	const lt = "<<";
	const gt = ">>";

	return (
		<div id="paginator">
			{page !== 1 ? <Button flat title={t('resuts.pagination.previous_page')} onClick={() => onStartChange((page - 2) * limit)}> {lt} </Button> : buttonPlaceholder}
			{
				pages.map((pageObj: any, i) =>
					<Button
						flat
						key={i}
						className={pageObj.selected ? "selected" : ""}
						disabled={!!pageObj.morepages || pageObj.number === page}
						onClick={() => onStartChange((pageObj.number - 1) * limit)}
					>
						{pageObj.morepages ? "..." : pageObj.number}
					</Button>
				)
			}

			{page !== totalPages ? <Button flat title={t('resuts.pagination.next_page')} onClick={() => onStartChange(page * limit)}> {gt} </Button> : buttonPlaceholder}
		</div>
	);
};

export default React.memo(withT(Paginator));
