import React from 'react';
import { Button, SelectionControl, TextField } from 'lib/imports/react-md';
import { withT } from 'lib/i18n';

import { ComponentProps } from './types';
import { isEmpty } from 'lodash';

const FocusFeedDefinitionLayoutCampaignExpression = (props: ComponentProps) => {
	const { campaign, index, t } = props;
	const { onSetCampaignExpression, onRemoveCampaignExpression } = props;
	const { enabled, campaign_id: campaignId } = campaign;

	const inputRef: React.RefObject<any> = React.useRef();
	const [campaignIdValue, setCampaignIdValue] = React.useState(campaignId);
	const [enabledValue, setEnabledValue] = React.useState(enabled);

	React.useEffect(() => {
		if (isEmpty(campaignId)) inputRef.current.focus();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		setCampaignIdValue(campaignId);
		setEnabledValue(enabled);
	}, [campaignId, enabled]);

	const onBlurHandler = React.useCallback((event: any) => {
		const id = event.target.value;
		onSetCampaignExpression(index, { enabled: enabledValue, campaign_id: id });
	}, [onSetCampaignExpression, index, enabledValue]);

	const enableCheckbox = React.useMemo(() => (
		<SelectionControl
			id={`focusFeedDefinitionSocialCampaignEnabled${index}`}
			name={`focusFeedDefinitionSocialCampaignEnabled${index}`}
			aria-label={`focusFeedDefinitionSocialCampaignEnabled${index}`}
			className={`definition-expression-enabled ${enabledValue ? "checkbox-green" : ''}`}
			type="checkbox"
			checked={enabledValue}
			onChange={_ => onSetCampaignExpression(index, { enabled: !enabledValue, campaign_id: campaignIdValue })}
		/>
	), [onSetCampaignExpression, index, enabledValue, campaignIdValue]);

	const queryInput = React.useMemo(() => (
		<TextField
			id={`focusFeedSocialCampaign${index}`}
			className={`definition-campaign-text`}
			fullWidth={false}
			value={campaignIdValue}
			onChange={value => setCampaignIdValue(value.toString())}
			onBlur={onBlurHandler}
			placeholder={t('feed.form.instagram_stories.campaign.placeholder')}
			ref={inputRef}
		/>
	), [setCampaignIdValue, campaignIdValue, onBlurHandler, index, t]);

	const removeCampaignButton = React.useMemo(() => (
		<Button
			id={`focusFeedSocialCampaignRemove${index}`}
			className='definition-campaign-button'
			icon
			onClick={() => onRemoveCampaignExpression(index)}
		>
			delete
		</Button>
	), [index, onRemoveCampaignExpression]);

	let className = 'definition-campaign';
	className += enabled ? " enabled" : " disabled";
	return (
		<div className={className}>
			{enableCheckbox}
			{queryInput}
			{removeCampaignButton}
		</div>
	);
};

export default React.memo(withT(FocusFeedDefinitionLayoutCampaignExpression));
