import { Action, actionCreatorFactory } from 'typescript-fsa';

import { MediaOption } from 'types/article/insert';
import { ApiMediaObj } from 'types/medias/medias';

const actionCreator = actionCreatorFactory('@@article/insert/online');

type Payloads = {
	FetchMedias: { media_url: string };
	FetchMediasError: { error: object };
	SetAvailableMedias: { medias: MediaOption[] };
	FetchMediaInfo: { media_id: string };
	SetMediaInfo: { media: ApiMediaObj };
	FetchMediaInfoError: { error: object };
}

export type Actions = {
	FetchMedias: Action<Payloads["FetchMedias"]>;
	FetchMediasError: Action<Payloads["FetchMediasError"]>;
	setAvailableMedias: Action<Payloads["SetAvailableMedias"]>;
	FetchMediaInfo: Action<Payloads["FetchMediaInfo"]>;
	SetMediaInfo: Action<Payloads["SetMediaInfo"]>;
	FetchMediaInfoError: Action<Payloads["FetchMediaInfoError"]>;
}

export const operators = {
	fetchMedias: actionCreator<Payloads["FetchMedias"]>('FETCH_MEDIAS', { api: 'start' }),
	fetchMediasError: actionCreator<Payloads["FetchMediasError"]>('FETCH_MEDIAS_ERROR', { api: 'error' }),
	setAvailableMedias: actionCreator<Payloads["SetAvailableMedias"]>('SET_AVAILABLE_MEDIAS'),
	fetchMediaInfo: actionCreator<Payloads["FetchMediaInfo"]>('FETCH_MEDIA_INFO'),
	setMediaInfo: actionCreator<Payloads["SetMediaInfo"]>('SET_MEDIA_INFO'),
	fetchMediaInfoError: actionCreator<Payloads["FetchMediaInfoError"]>('FETCH_MEDIA_ERROR', { api: 'error' })
};
