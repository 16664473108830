import React from 'react';
import { get } from 'lib/imports/lodash';
import { Button, CircularProgress } from 'lib/imports/react-md';

import FletcherLoader from 'components/common/Fletcher/Loader/';
import { withT } from 'lib/i18n';
import { User } from 'class/User';
import { ComponentProps } from './types';
import NewsletterEditForm from './Form';

import './NewsletterEdit.scss';

const NewsletterEdit = (props: ComponentProps) => {
	const { t, newsletterId, newsletter, loading, user: userObject } = props;
	const { onQueueNewsletter } = props;

	const [queued, setQueued] = React.useState(false);
	const user = React.useMemo(() => new User(userObject!), [userObject]);

	React.useEffect(() => {
		if (!queued || !newsletter) return;
		onQueueNewsletter(newsletter);
		setQueued(false);
	}, [queued, onQueueNewsletter, newsletter]);

	return (
		<div id="newsletterEdit" className='discover-form-wrapper'>
			<div id="newsletterEditDescription" className='discover-form-sidebar'>
				<div className="sticky-wrapper">
					<div className="description-title">
						<div className="description-title-header">{t('newsletter.form.title')}</div>
						<div className="description-title-text">{!loading && get(newsletter, 'name')}</div>
					</div>
					<div className="description-text">
						<p>{t('newsletter.form.description_1')}</p>
						<p>{t('newsletter.form.description_2')}</p>
					</div>
					<div className="description-button" hidden={!user.hasPermission('queue_newsletter')}>
						<Button
							id="newsletterEditQueueBtn"
							className='form-btn save-btn'
							flat
							onClick={() => setQueued(true)}
						>
							Queue
						</Button>
					</div>
				</div>
			</div>
			<div id="newsletterEditFormWrapper" className='discover-form-content'>
				{loading ?
					(
						<CircularProgress id="newsletterEditSaveLoading" />
					) : (
						<FletcherLoader resource={['newsletters', 'focus']}>
							<NewsletterEditForm newsletterId={newsletterId} />
						</FletcherLoader>
					)
				}
			</div>
		</div >
	);
};

export default withT(React.memo(NewsletterEdit));
