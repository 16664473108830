import React from 'react';
import { withT } from 'lib/i18n';
import { ComponentProps } from './types';

import './EmailPopupBase.scss';

const EmailPopupBase: React.FC<ComponentProps> = ({title, subTitle, headerContent, children, t, onClose}) => {

	return <div className="audit-email-popup-base-wrapper" onClick={e => e.stopPropagation()}>
		<div className="audit-email-popup-base-header">
			<div className="audit-email-popup-base-header-title">{title}</div>
			{subTitle && <div className="audit-email-popup-base-header-subtitle">{subTitle}</div>}
			{headerContent}
		</div>
		{children}
		<div className="audit-email-popup-base-footer" >
			<button className="audit-email-popup-close-btn" type="button" onClick={onClose}>
				{t('audit_email_popup_close_btn')}
			</button>
		</div>
	</div>;
};

export default withT(EmailPopupBase);