import React from 'react';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import { reject } from 'lodash';
import { Button, Collapse, FontIcon } from 'react-md';
import { ExpressionsType } from 'class/Feed';
import SimpleDialog from 'components/common/dialog/simple';
import FocusFeedDefinitionHelpDialog from '../../../HelpDialog/FocusFeedDefinitionHelpDialog';
import FocusFeedDefinitionExpression from '../../../Layout/Sections/Content/Expression/FocusFeedDefinitionExpression';

type ExpressionToRemove = {
	expressionKey: ExpressionsType,
	index: number;
};

const FocusFeedDefinitionLayoutContent = (props: ComponentProps) => {
	const { definition, activeScope, scopeMenuItems, brand_sync } = props;
	const { t, onSetDefinition, expressionFunctions } = props;

	const [helpDialogVisible, setShowHelpDialog] = React.useState(false);
	const [specificationCollapse, setSpecificationCollapse] = React.useState(false);
	const [expressionToRemove, setExpressionToRemove] = React.useState<ExpressionToRemove | null>(null);
	const [showRemoveDialog, setShowRemoveDialog] = React.useState(false);

	const onClickSpecificationSection = React.useCallback(() => {
		setSpecificationCollapse(!specificationCollapse);
	}, [specificationCollapse]);

	const showHelpDialog = React.useCallback(() => {
		setShowHelpDialog(true);
	}, [setShowHelpDialog]);

	const hideHelpDialog = React.useCallback(() => {
		setShowHelpDialog(false);
	}, [setShowHelpDialog]);

	const addExpression = React.useCallback((expressionKey: ExpressionsType) => {
		onSetDefinition({
			...definition,
			[expressionKey]: [
				...definition[expressionKey],
				{
					q: "",
					enabled: true,
					scope: activeScope
				}
			]
		});
	}, [onSetDefinition, definition, activeScope]);

	const removeExpression = React.useCallback((expressionKey: ExpressionsType, index: number) => {
		onSetDefinition({
			...definition,
			[expressionKey]: reject(definition[expressionKey], (expression, pos: number) => index === pos)
		});
	}, [onSetDefinition, definition]);

	const confirmExpressionRemoval = React.useCallback(() => {
		const { expressionKey, index } = expressionToRemove!;
		removeExpression(expressionKey, index);
		setShowRemoveDialog(false);
	}, [expressionToRemove, removeExpression]);

	const cancelExpressionRemoval = React.useCallback(() => {
		setShowRemoveDialog(false);
	}, []);

	const removeExpressionHandler = React.useCallback((expressionKey: ExpressionsType, index: number) => {
		const expression = definition[expressionKey][index];
		if (expression.q === '') return removeExpression(expressionKey, index);
		setExpressionToRemove({ expressionKey, index });
		setShowRemoveDialog(true);
	}, [definition, removeExpression]);

	const getExpression = React.useCallback((expressionKey: ExpressionsType) => {
		const expressions: any[] = [];
		definition[expressionKey].forEach((expression, index) => {
			if (expression.scope.some(expressionScope => activeScope.includes(expressionScope))) {
				expressions.push(<FocusFeedDefinitionExpression
					key={`${expressionKey}-${index}`}
					expression={expression}
					index={index}
					expressionKey={expressionKey}
					onRemoveExpression={removeExpressionHandler}
					scopeMenuItems={scopeMenuItems}
					{...expressionFunctions}
				/>);
			}
		});
		return expressions;
	}, [activeScope, definition, expressionFunctions, removeExpressionHandler, scopeMenuItems]);

	const getExpressionSection = React.useCallback((expressionKey: ExpressionsType) => {
		const headerTxt = expressionKey === 'include_expressions' ? 'feed.form.header_positive_expressions' : 'feed.form.header_negative_expressions';
		return (
			<div id={`focusFeedDefinition-${activeScope.join('_')}-${expressionKey}`}>
				<div className="definition-text" dangerouslySetInnerHTML={{ __html: t(headerTxt) + ":" }} ></div>
				{getExpression(expressionKey)}
				<div className="definition-add-expression">
					<Button
						id={`focusFeedDefinitionAddButton-${activeScope.join('_')}-${expressionKey}`}
						className="definition-add-expression-button"
						flat
						onClick={() => addExpression(expressionKey)}
					>
						<span>+</span> {t('feed.form.button.add_words_react')}
					</Button>
				</div>
			</div>
		);
	}, [activeScope, addExpression, getExpression, t]);

	const helpDialog = React.useMemo(() => (helpDialogVisible ? <FocusFeedDefinitionHelpDialog onClose={hideHelpDialog} /> : null), [helpDialogVisible, hideHelpDialog]);
	const removeDialog = React.useMemo(() => (showRemoveDialog ?
		(
			<SimpleDialog
				id="focusFeedDefinitionRemoveExpressionWarning"
				content={<></>}
				text={t('feed.form.confirm_remove_expression')}
				onAccept={confirmExpressionRemoval}
				onCancel={cancelExpressionRemoval}
				acceptColor="red"
			/>
		)
		: null), [cancelExpressionRemoval, confirmExpressionRemoval, showRemoveDialog, t]);

	const contentSpecificationsSection = React.useMemo(() => {
		const isCollapsed = specificationCollapse || brand_sync;

		return (
			<div id="focusFeedDefinitionContentSection" className={`definition-section ${brand_sync ? 'definition-section-disabled' : ''}`}>
				<div className="definition-section-header">
					<div className="definition-header-title" onClick={onClickSpecificationSection}>
						{
							isCollapsed ?
								<FontIcon className="definition-header-title-toggle" >keyboard_arrow_right</FontIcon> :
								<FontIcon className="definition-header-title-toggle">keyboard_arrow_down</FontIcon>
						}
						<span className="definition-header-title-text">{activeScope.includes('print.product.name') ?
							t('feed.form.header_product_content') :
							t('feed.form.header_main_expression_react')}
						</span>
					</div>
					<div className="definition-header-search-show-help-link" onClick={showHelpDialog}>
						{t('definition.show_help')}
					</div>
				</div>
				<Collapse collapsed={isCollapsed}>
					<div className={`definition-section-content ${brand_sync && 'disabled'}`}>
						<div className="definition-expressions">
							{"main" in definition &&
								<div className="main-expression-wrapper">
									<FocusFeedDefinitionExpression
										key={`main-0`}
										expression={definition.main}
										index={0}
										expressionKey={"main"}
										onRemoveExpression={removeExpressionHandler}
										scopeMenuItems={scopeMenuItems}
										{...expressionFunctions}
									/>
								</div>
							}
							{getExpressionSection("include_expressions")}
							{getExpressionSection("exclude_expressions")}
						</div>
					</div>
				</Collapse>
			</div>
		);
	}, [
		onClickSpecificationSection,
		specificationCollapse,
		activeScope,
		t,
		showHelpDialog,
		definition,
		removeExpressionHandler,
		scopeMenuItems,
		expressionFunctions,
		getExpressionSection,
		brand_sync
	]);

	return (
		<>
			{helpDialog}
			{removeDialog}
			{contentSpecificationsSection}
		</>
	);
};

export default withT(React.memo(FocusFeedDefinitionLayoutContent));
