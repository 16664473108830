import React from 'react';
import { Autocomplete, CircularProgress } from 'lib/imports/react-md';
import { cloneDeep, remove } from 'lib/imports/lodash';
import moment from 'moment';

import { withT } from 'lib/i18n';
import providerDiscovery from 'lib/providerDiscovery';
import { FormFieldElement } from 'types/form';
import Form from 'components/common/Form/Form';
import { ComponentProps } from './types';
import Categorization from 'components/common/Categorization';
import { getAvailableLanguages, getAvailableCountries } from 'lib/input';
import { InsertSocialForm } from 'types/article/insert';
import { date as dateFormat } from 'lib/format';
import { convertReverse } from "lib/currency";

import './ArticleInsertFormSocial.scss';

const urlField = (url: string): FormFieldElement => ({
	type: "text",
	id: "url",
	label: "Url",
	defaultValue: url,
	disabled: true,
	required: true
});

const titleField: FormFieldElement = {
	type: "text",
	id: "title",
	label: { key: 'insert_article.modal.form.title' },
	style: { width: 100 },
	className: "with-border",
	inputProps: {
		rows: 1,
		maxRows: 4,
		maxLength: 10000,
		floating: true
	}
};

const imageField: FormFieldElement = {
	type: "file",
	id: "image",
	multiple: false,
	maxSize: 15000000,
	accept: "image/*",
	required: true,
	style: { uniqueInLine: true }
};

const profileUrlField = (smallWidth: boolean = false): FormFieldElement => ({
	type: "text",
	id: "profile_url",
	label: { key: 'insert_article.modal.form.profile_url' },
	required: true,
	validations: {
		url: true
	},
	style: { width: smallWidth ? 33 : 66 }
});

const followersField: FormFieldElement = {
	type: "number",
	id: "followers",
	label: { key: 'insert_article.modal.form.num_of_followers' },
	style: { width: 33 }
};

const languageField = (newLine: boolean = true): FormFieldElement => ({
	type: "autocomplete",
	id: "language",
	items: getAvailableLanguages().map(lang => ({ label: lang.name, value: lang.id })),
	label: { key: 'insert_article.modal.form.language' },
	alwaysShowItems: true,
	closeButton: true,
	inputProps: { filter: Autocomplete.caseInsensitiveFilter },
	style: { width: 33, newLine: newLine }
});

const countryField: FormFieldElement = {
	type: "autocomplete",
	id: "country",
	items: getAvailableCountries().map(lang => ({ label: lang.name, value: lang.id })),
	label: { key: 'insert_article.modal.form.country' },
	alwaysShowItems: true,
	closeButton: true,
	inputProps: { filter: Autocomplete.caseInsensitiveFilter },
	style: { width: 33 }
};

const dateField: FormFieldElement = {
	type: "date",
	id: "date",
	defaultValue: new Date(),
	maxDate: new Date(),
	style: { width: 33 },
	required: true,
	inputProps: {
		portal: true,
		lastChild: true,
		disableScrollLocking: true,
		renderNode: document.body
	}
};

const countryLanguageField = (t: Function): FormFieldElement => ({
	id: "country_language_text",
	type: "component",
	component: <div id="insertMentionSocialFormCountryLanguageText">{t('insert_article.modal.form.country_and_lang_reminder')}</div>,
	style: { uniqueInLine: true }
});

const likesField: FormFieldElement = {
	type: "number",
	id: "likes",
	label: { key: 'insert_article.modal.form.num_of_likes' },
	style: { width: 33, newLine: true }
};

const sharesField: FormFieldElement = {
	type: "number",
	id: "shares",
	label: { key: 'insert_article.modal.form.num_of_shares' },
	style: { width: 33 }
};

const mivField: FormFieldElement = {
	type: "number",
	id: "miv",
	label: { key: 'insert_article.modal.form.miv' },
	style: { width: 33 }
};

const profileNameField = (required: boolean = false): FormFieldElement => ({
	type: "text",
	id: "profile_name",
	label: { key: 'insert_article.modal.form.profile_name' },
	required,
	style: { width: 33 }
});

const viewsField: FormFieldElement = {
	type: "number",
	id: "views",
	label: { key: 'insert_article.modal.form.num_of_views' },
	style: { width: 33 }
};

const wowsField: FormFieldElement = {
	type: "number",
	id: "wows",
	label: { key: 'insert_article.modal.form.num_of_wows' },
	style: { width: 33 }
};

const favouritesField: FormFieldElement = {
	type: "number",
	id: "favourites",
	label: { key: 'insert_article.modal.form.num_of_favourites' },
	style: { width: 33 }
};

const coinsField: FormFieldElement = {
	type: "number",
	id: "coins",
	label: { key: 'insert_article.modal.form.num_of_coins' },
	style: { width: 33 }
};

const danmakuField: FormFieldElement = {
	type: "number",
	id: "danmaku",
	label: { key: 'insert_article.modal.form.num_of_danmaku' },
	style: { width: 33 }
};

const contentField: FormFieldElement = {
	type: "text",
	id: "content",
	label: { key: 'insert_article.modal.form.content' },
	style: { newLine: true },
	className: "with-border",
	inputProps: {
		rows: 4,
		maxRows: 4,
		maxLength: 10000,
		floating: true
	}
};

const __getDefaultForm = (url: string, t: Function, smallWith?: boolean, newLine?: boolean): FormFieldElement[] => {
	return [
		urlField(url),				// 0
		titleField,				    // 1
		imageField,				    // 2
		profileUrlField(smallWith),	// 3
		followersField,			    // 4
		languageField(newLine),		// 5
		countryField,			    // 6
		dateField,				    // 7
		countryLanguageField(t),	// 8
		likesField,				    // 9
		sharesField,			    // 10
		mivField				    // 11
	];
};

const __getFormFields = (url: string, t: Function) => {
	let fields = __getDefaultForm(url, t);
	switch (providerDiscovery.getProviderFromUrl(url)) {
		case 'youtube':
			fields = __getDefaultForm(url, t, true);
			fields.splice(4, 0, profileNameField(true));
			fields.push(viewsField);
			fields.splice(3, 0, contentField);
			break;
		case 'tiktok':
			fields = __getDefaultForm(url, t, true, false);
			fields.splice(4, 0, profileNameField());
			fields.push(viewsField);
			break;
		case 'wechat':
			fields.splice(10, 1);
			fields.splice(4, 0, profileNameField());
			fields.splice(2, 0, contentField);
			fields.push(wowsField);
			fields.push(viewsField);
			break;
		case 'red':
			fields.splice(4, 0, profileNameField());
			fields.push(favouritesField);
			break;
		case 'bilibili':
			fields.splice(4, 0, profileNameField());
			fields.splice(2, 0, contentField);
			fields.push(favouritesField);
			fields.push(viewsField);
			fields.push(coinsField);
			fields.push(danmakuField);
			break;
		case 'weibo':
			fields.splice(4, 0, profileNameField());
			break;
		case 'douyin':
			fields.splice(4, 0, profileNameField());
			break;
		default:
			break;
	}
	return fields;
};

const ArticleInsertFormSocial = (props: ComponentProps) => {
	const { t, submit, url, loading, onValidationSuccess, onValidationError } = props;

	const formRef = React.useRef<Form>(null);
	const [tags, setTags] = React.useState<{
		tagIds: string[],
		newTagNames: string[]
	}>({ tagIds: [], newTagNames: [] });
	const [category, setCategory] = React.useState('');

	React.useEffect(() => {
		if (submit && formRef.current) {
			if (formRef.current.validate()) {
				const values = formRef.current.getValues();
				const parsedDate = dateFormat.getNewDocumentDate(values.date, "socialmedia");
				if (values.miv) values.miv = convertReverse(values.miv, moment(parsedDate).tz('utc').format());
				onValidationSuccess({
					...values,
					image: values.image[0],
					date: moment(parsedDate).tz('utc').format(),
					category,
					tags
				} as InsertSocialForm);
			} else onValidationError();
		}
	}, [submit]); //eslint-disable-line react-hooks/exhaustive-deps

	const fieldElements = React.useMemo(() => __getFormFields(url, t), [t, url]);
	const onAddTags = React.useCallback((tagIds, newTagNames) => setTags({
		newTagNames: [...tags.newTagNames, ...newTagNames],
		tagIds: [...tags.tagIds, ...tagIds]
	}), [tags]);
	const onRemoveTag = React.useCallback((tag: string) => {
		const tagsCopy = cloneDeep(tags);
		remove(tagsCopy.newTagNames, tagName => tagName === tag);
		remove(tagsCopy.tagIds, tagId => tagId === tag);
		setTags(tagsCopy);
	}, [tags]);

	return (
		<div id="insertMentionSocialForm">
			{loading ? <CircularProgress id="insertDocumentSocialDialogLoading" scale={1.25} /> : null}
			<div className={loading ? "hidden" : ""}>
				<Form ref={formRef} elements={fieldElements} />
				<div className="form-row-title">{t('insert_article.modal.form.category_and_tags')}</div>
				<Categorization
					tags={tags}
					onAddTags={onAddTags}
					onRemoveTag={onRemoveTag}
					onSetCategory={setCategory}
				/>
			</div>
		</div>
	);
};

export default withT(React.memo(ArticleInsertFormSocial));
