import { Action, actionCreatorFactory } from 'typescript-fsa';
import { FocusObject } from 'class/Focus';
import { FeedObject } from 'class/Feed';

export const prefix = '@@focus/dashboard';

const actionCreator = actionCreatorFactory(prefix);

type Payloads = {
	FetchFocus: { readonly id: FocusObject["id"] };
	FetchFocusSuccess: { readonly focus: FocusObject, readonly feeds: FeedObject[] };
	FetchFocusError: { readonly error: object };
	RenameFocus: { readonly name: FocusObject["name"] };
	RenameFocusSuccess: { readonly focus: FocusObject };
	RenameFocusError: { readonly error: object };
	RemoveFocus: void;
	RemoveFocusSuccess: void;
	RemoveFocusError: { readonly error: object };
	RemoveFeed: { readonly id: FeedObject["id"] };
	RemoveFeedSuccess: { readonly id: FeedObject["id"] };
	RemoveFeedError: { readonly error: object };
}

export type Actions = {
	FetchFocus: Action<Payloads['FetchFocus']>;
	FetchFocusSuccess: Action<Payloads["FetchFocusSuccess"]>;
	FetchFocusError: Action<Payloads["FetchFocusError"]>;
	RenameFocus: Action<Payloads['RenameFocus']>;
	RenameFocusSuccess: Action<Payloads["RenameFocusSuccess"]>;
	RenameFocusError: Action<Payloads["RenameFocusError"]>;
	RemoveFocus: Action<Payloads['RemoveFocus']>;
	RemoveFocusSuccess: Action<Payloads["RemoveFocusSuccess"]>;
	RemoveFocusError: Action<Payloads["RemoveFocusError"]>;
	RemoveFeed: Action<Payloads['RemoveFeed']>;
	RemoveFeedSuccess: Action<Payloads["RemoveFeedSuccess"]>;
	RemoveFeedError: Action<Payloads["RemoveFeedError"]>;
}

export const operators = {
	fetchFocus: actionCreator<Payloads["FetchFocus"]>('FETCH_FOCUS', { api: 'start' }),
	fetchFocusSuccess: actionCreator<Payloads["FetchFocusSuccess"]>('FETCH_FOCUS_SUCCESS', { api: 'success' }),
	fetchFocusError: actionCreator<Payloads["FetchFocusError"]>('FETCH_FOCUS_ERROR', { api: 'error' }),
	renameFocus: actionCreator<Payloads["RenameFocus"]>('RENAME_FOCUS', { api: 'start' }),
	renameFocusSuccess: actionCreator<Payloads["RenameFocusSuccess"]>('RENAME_FOCUS_SUCCESS', { api: 'success' }),
	renameFocusError: actionCreator<Payloads["RenameFocusError"]>('RENAME_FOCUS_ERROR', { api: 'error' }),
	removeFocus: actionCreator<Payloads["RemoveFocus"]>('REMOVE_FOCUS', { api: 'start' }),
	removeFocusSuccess: actionCreator<Payloads["RemoveFocusSuccess"]>('REMOVE_FOCUS_SUCCESS', { api: 'success' }),
	removeFocusError: actionCreator<Payloads["RemoveFocusError"]>('REMOVE_FOCUS_ERROR', { api: 'error' }),
	removeFeed: actionCreator<Payloads["RemoveFeed"]>('REMOVE_FEED', { api: 'start' }),
	removeFeedSuccess: actionCreator<Payloads["RemoveFeedSuccess"]>('REMOVE_FEED_SUCCESS', { api: 'success' }),
	removeFeedError: actionCreator<Payloads["RemoveFeedError"]>('REMOVE_FEED_ERROR', { api: 'error' })
};
