import React from 'react';
import { StateProps } from './types';
import { HeaderCellHelp } from './HeaderCellHelp';

import './TableHeaderCell.scss';

export const TableHeaderCell = ({
	children,
	helpText = '',
	cellClasses = [],
	tooltipStyles = [],
	helpAlignment = 'none',
	alignVertically = 'top',
	tooltipPosition = 'right',
	alignHorizontally = 'left'
}: StateProps) => {
	const isHelpIconLeft = () => helpAlignment !== 'none' && helpAlignment === 'left';
	const isHelpIconRight = () => helpAlignment !== 'none' && helpAlignment === 'right';

	return <th
		className={cellClasses.join(" ")}
		style={{
			textAlign: alignHorizontally,
			verticalAlign: alignVertically
		}}>
		<div className="table-header-cell">
			{isHelpIconLeft() && <HeaderCellHelp text={helpText} position={tooltipPosition} tooltipStyles={tooltipStyles} />}
			{children}
			{isHelpIconRight() && <HeaderCellHelp text={helpText} position={tooltipPosition} tooltipStyles={tooltipStyles} />}
		</div>
	</th>;
};
