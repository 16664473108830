import React from 'react';
import { FontIcon, SelectionControl } from 'lib/imports/react-md';
import { FeedObject } from 'store/entities/Feed/types';

import { ComponentProps } from './types';

import './FocusFeedCheckbox.scss';

const getFeedIconClass = (type: FeedObject['type']) => {
	if (type === 'online') return "icon-news";
	if (type === 'print_dmr') return "icon-print";
	else return `icon-${type}`;
};

const FocusFeedCheckbox = (props: ComponentProps) => {
	const {feed, checked, disabled} = props;
	const {onChange} = props;

	const componentId = `focusFeedCheckbox_${feed.id}`;
	const inputName = `focusFeedCheckbox_${feed.id}_input`;

	return (
		<div id={componentId} key={componentId} className="focus-feed-checkbox">
			<SelectionControl
				id={inputName}
				name={inputName}
				type="checkbox"
				label=""
				className={`focus-feed-checkbox-input ${checked ? 'checkbox-green' : ''} ${disabled ? 'checkbox-disabled' : ''}`}
				checked={checked}
				value={feed.id}
				onChange={() => onChange(!checked)}
			/>
			<FontIcon iconClassName={`focus-feed-checkbox-icon ${getFeedIconClass(feed.type)}`} />
			<div id={`${inputName}-name`} className="focus-feed-checkbox-name">
				{feed.name}
			</div>
		</div>
	);
};

export default React.memo(FocusFeedCheckbox);
