import React, { ReactText } from 'react';
import Button from 'react-md/lib/Buttons/Button';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import isEqual from 'lodash/isEqual';

import FacebookImageInput from 'components/common/facebook/ImageInput/';
import { OnFacebookUrlsParams } from 'components/common/facebook/ImageInput/types';
import { Settings } from 'class/Tenant';
import { withT } from 'lib/i18n';
import { ComponentProps } from './types';

import './TenantSettings.scss';

class TenantSettings extends React.Component<ComponentProps> {

	public state = {
		settings: {} as Settings,
		facebookInputUrlError: false
	};

	constructor(props: ComponentProps) {
		super(props);
		this.state = { settings: { ...props.tenantSettings }, facebookInputUrlError: false };
	}

	private save() {
		if (!isEqual(this.state.settings, this.props.tenantSettings)) this.props.onUpdateTenantSettings(this.state.settings);
	};

	private onFacebookImageChange = (urls: OnFacebookUrlsParams) => {
		const url = urls ? urls.pageUrl : "";
		this.setState({ settings: { ...this.state.settings, facebook_url: url }, facebookInputUrlError: false });
	};

	public render() {
		const { loading, t } = this.props;
		const { onClose } = this.props;

		const header = (
			<div className="tenant-settings-header md-grid">
				<FontIcon className="">person</FontIcon>
				<span>{t('tenant.settings.header')}</span>
				<Button id="closeTenantSettingsButton" className="close-btn btn--no-background" icon primary onClick={onClose}>clear</Button>
			</div>
		);
		if (loading) return (
			<div className="tenant-settings">
				{header}
				<div><CircularProgress id="tenantSettingsLoading" centered /></div>
			</div>
		);

		return (
			<div className="tenant-settings">
				{header}
				<div className="tenant-settings-form md-grid md-grid--stacked">
					<FacebookImageInput url={this.props.tenantSettings.facebook_url} helpText={t('tenant.settings.facebook_page.help')} onFacebookUrls={this.onFacebookImageChange} />
					<div className="tenant-settings-form-field">
						<SelectField
							id="tenantCurrency"
							className="tenant-settings-select-field"
							label={t('tenant.settings.currency')}
							menuItems={[
								{ label: 'EUR (€)', value: 'EUR' },
								{ label: 'USD ($)', value: 'USD' },
								{ label: 'GBP (£)', value: 'GBP' },
								{ label: 'JPY (¥)', value: 'JPY' },
								{ label: 'CNY (¥)', value: 'CNY' }
							]}
							onChange={(val: ReactText) => this.setState({ settings: { ...this.state.settings, currency: val.toString() } })}
							value={this.state.settings.currency}
						/>
					</div>

					<div className="tenant-settings-form-field">
						<SelectField
							id="tenantAvailableCategories"
							className="tenant-settings-select-field"
							label={t('tenant.settings.categorization_mode.value')}
							menuItems={[
								{ value: 'flc', label: t('tenant.settings.categorization_mode.flc') },
								{ value: 'no_flc', label: t('tenant.settings.categorization_mode.no_flc') },
								{ value: 'all', label: t('tenant.settings.categorization_mode.all') }
							]}
							onChange={(val: ReactText) => this.setState({ settings: { ...this.state.settings, categorization_mode: val.toString() } })}
							value={this.state.settings.categorization_mode}
						/>
					</div>

					<div className="tenant-settings-form-buttons md-grid">
						<Button id="tenantSettingsCancel" className="btn--no-background btn--red-ink" flat onClick={onClose}>{t('filters.period.custom.dialog.cancel')}</Button>
						<Button
							id="tenantSettingsSave"
							disabled={this.state.facebookInputUrlError}
							raised
							iconEl={<FontIcon>save</FontIcon>}
							iconBefore={true}
							onClick={() => this.save()}>
							{t('filters.period.custom.dialog.ok')}
						</Button>
					</div>

				</div>
			</div>
		);
	}
}

export default withT(TenantSettings);
