import { ComponentProps } from './types';
import { FontIcon } from 'lib/imports/react-md';
import React, { MouseEvent, useState } from 'react';
import './SortIcon.scss';

export const SortIcon = ({
	initialValue = 'asc',
	onClick
}: ComponentProps) => {
	const [direction, setDirection] = useState<'asc' | 'desc'>(initialValue);

	function toggleDirection(e: MouseEvent<HTMLDivElement>) {
		e.stopPropagation();
		const sortValue = direction === 'asc' ? 'desc' : 'asc';
		setDirection(sortValue);
		onClick(sortValue);
	}

	return (
		<>
			{direction === 'asc' ?
				<div onClick={(e: MouseEvent<HTMLDivElement>) => toggleDirection(e)}>
					<FontIcon className='sort-icon'>arrow_upward</FontIcon>
				</div> :
				<div onClick={(e: MouseEvent<HTMLDivElement>) => toggleDirection(e)}>
					<FontIcon className='sort-icon'>arrow_downward</FontIcon>
				</div>
			}
		</>
	);
};
