import React from 'react';
import { StateProps } from './types';
export const TableHeader = ({
	headerClasses = [],
	children
}: StateProps) => {
	return <thead className={headerClasses.join(' ')}>
		<tr>
			{children}
		</tr>
	</thead>;
};