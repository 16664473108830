import ActionCreators from 'lib/store/actions';

const actions = new ActionCreators('@@topic/manager');

const [createTopic, createTopicSuccess, createTopicError] = actions.apiOperators<{ name: string }>('CREATE_TOPIC');
const [renameTopic, renameTopicSuccess, renameTopicError] = actions.apiOperators<{ id: string, oldName: string, name: string }>('RENAME_TOPIC', { reload: true });
const [deleteTopic, deleteTopicSuccess, deleteTopicError] = actions.apiOperators<{ id: string }>('DELETE_TOPIC', { reload: true });

export type Actions = {
	CreateTopic: ReturnType<typeof createTopic>;
	CreateTopicSuccess: ReturnType<typeof createTopicSuccess>;
	CreateTopicError: ReturnType<typeof createTopicError>;
	RenameTopic: ReturnType<typeof renameTopic>;
	RenameTopicSuccess: ReturnType<typeof renameTopicSuccess>;
	RenameTopicError: ReturnType<typeof renameTopicError>;
	DeleteTopic: ReturnType<typeof deleteTopic>;
	DeleteTopicSuccess: ReturnType<typeof deleteTopicSuccess>;
	DeleteTopicError: ReturnType<typeof deleteTopicError>;
};

export const operators = {
	createTopic,
	createTopicSuccess,
	createTopicError,
	renameTopic,
	renameTopicSuccess,
	renameTopicError,
	deleteTopic,
	deleteTopicSuccess,
	deleteTopicError
};
