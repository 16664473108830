import { createSelector } from 'reselect';
import { sessionSelector } from "store/entities/selectors";

export const getLanguageIso = createSelector(sessionSelector, (_: any, id: string) => id,
	({Language}, id): string => {
		const language = Language.withId(id);
		return language ? language.iso_code : 'undefined';
	}
);

export const getAllLanguages = createSelector(sessionSelector, (_: any) => _,
	({Language}): {[id: string]: string} => {
		const languageObject: {[id: string]: string} = {};
		Language.all().toModelArray().map(language => languageObject[language.id] = language.name);
		return languageObject;
	}
);
