import { combineReducers } from 'redux';

import * as edit from './edit';

export const reducers = combineReducers({
	edit: edit.reducers
});

export interface State {
	edit: edit.State;
}

export const INITIAL_STATE = {
	edit: edit.INITIAL_STATE
};
