import React from "react";
import { TextField } from 'lib/imports/react-md';

import { TProps, withT } from "lib/i18n";

type OwnProps = {
	name: string,
	description: string,
	nameError?: string,
	onNameUpdated: (name: string) => void
	onDescriptionUpdated: (description: string) => void
};

type ComponentProps = OwnProps & TProps;

const NewsletterEditFormSetup = (props: ComponentProps) => {
	const { t, name, description, nameError } = props;
	const { onNameUpdated, onDescriptionUpdated } = props;

	const nameField = React.useMemo(() => (
		<TextField
			id="newsletterEditNameInput"
			label={t('newsletter.form.input.name.value')}
			defaultValue={name}
			onBlur={(event: any) => onNameUpdated(event.target.value.trim())}
			required
			error={!!nameError}
			errorText={nameError}
		/>
	), [t, name, nameError, onNameUpdated]);

	const descriptionField = React.useMemo(() => (
		<TextField
			id="newsletterEditDescriptionInput"
			label={t('newsletter.form.input.description')}
			defaultValue={description}
			onBlur={(event: any) => onDescriptionUpdated(event.target.value.trim())}
		/>
	), [t, description, onDescriptionUpdated]);

	return (
		<div id="newsletterEditSectionName" className="form-section">
			<div className="form-section-title">
				{t('newsletter.form.label.setup')}
			</div>
			<div className="form-section-content">
				<div className="form-section-content-column">
					{nameField}
				</div>
				<div className="form-section-content-column">
					{descriptionField}
				</div>
			</div>
		</div>
	);
};

export default withT(React.memo(NewsletterEditFormSetup));
