import { createSelector } from "reselect";

import { ormEntities, createOrmSelector, OrmSelector, sessionSelector } from "store/entities/selectors";
import { TopicObject } from "store/entities/Topic";
import { TagObject } from "./types";
import { isEmpty } from "lodash";
import { TagAutomation } from "../TagAutomation";

export const get: OrmSelector<TagObject> = createOrmSelector(ormEntities.Tag);
export const getTopic: OrmSelector<TopicObject> = createOrmSelector(ormEntities.Tag.topic);
export const getTagAutomation: OrmSelector<TagAutomation> = createOrmSelector(ormEntities.Tag.tag_automation);
export const getInstance = createSelector(sessionSelector, (_: any, id: string) => id,
	({ Tag }, id) => Tag.withId(id)
);
export const getTagWithTagAutomation = createSelector(sessionSelector, (_: any, id: string) => id, ({ Tag }, id) => {
	const tag = Tag.withId(id)!;
	if (isEmpty(tag)) return {};
	return {
		...tag.ref,
		tag_automation: tag.tag_automation ? tag.tag_automation.ref : undefined
	};
});
export const getAllTagsAndTagAutomation = createSelector(sessionSelector, ({ Tag }) => {
	const tagHash: {[key: string]: any} = {};
	Tag.all().toModelArray().forEach(tag => (tagHash[tag.id] = {
		...tag.ref,
		tag_automation: tag.tag_automation ? tag.tag_automation.ref : undefined
	}));
	return tagHash;
});

export default {
	get,
	getTopic,
	getTagAutomation,
	getInstance,
	getTagWithTagAutomation,
	getAllTagsAndTagAutomation
};
