import ActionCreators from 'lib/store/actions';

const actions = new ActionCreators('@@newsletter/emailList/edit');

const setEditList = actions.operator<{ id: string }>('SET_EDIT_LIST');
const resetEditList = actions.operator('RESET_EDIT_LIST');
const [renameEmailList, renameEmailListSuccess, renameEmailListError] = actions.apiOperators<{ name: string }>('RENAME_EMAIL_LIST', { reload: true });
const [addNewEmailAddress, addNewEmailAddressSuccess, addNewEmailAddressError] = actions.apiOperators<{ email: string }>('ADD_NEW_EMAIL_ADDRESS', { reload: true });
const [deleteEmailAddress, deleteEmailAddressSuccess, deleteEmailAddressError] = actions.apiOperators<{ email: string }>('DELETE_EMAIL_ADDRESS', { reload: true });
const [renameEmailAddress, renameEmailAddressSuccess, renameEmailAddressError] = actions.apiOperators<{ oldEmail: string, newEmail: string }>('RENAME_EMAIL_ADDRESS', { reload: true });

export type Actions = {
	SetEditList: ReturnType<typeof setEditList>;
	ResetEditList: ReturnType<typeof resetEditList>;
	RenameEmailList: ReturnType<typeof renameEmailList>;
	RenameEmailListSuccess: ReturnType<typeof renameEmailListSuccess>;
	RenameEmailListError: ReturnType<typeof renameEmailListError>;
	AddNewEmailAddress: ReturnType<typeof addNewEmailAddress>;
	AddNewEmailAddressSuccess: ReturnType<typeof addNewEmailAddressSuccess>;
	AddNewEmailAddressError: ReturnType<typeof addNewEmailAddressError>;
	DeleteEmailAddress: ReturnType<typeof deleteEmailAddress>;
	DeleteEmailAddressSuccess: ReturnType<typeof deleteEmailAddressSuccess>;
	DeleteEmailAddressError: ReturnType<typeof deleteEmailAddressError>;
	RenameEmailAddress: ReturnType<typeof renameEmailAddress>;
	RenameEmailAddressSuccess: ReturnType<typeof renameEmailAddressSuccess>;
	RenameEmailAddressError: ReturnType<typeof renameEmailAddressError>;
}

export const operators = {
	setEditList,
	resetEditList,
	renameEmailList,
	renameEmailListSuccess,
	renameEmailListError,
	addNewEmailAddress,
	addNewEmailAddressSuccess,
	addNewEmailAddressError,
	deleteEmailAddress,
	deleteEmailAddressSuccess,
	deleteEmailAddressError,
	renameEmailAddress,
	renameEmailAddressSuccess,
	renameEmailAddressError
};
