import { Actions } from './actions';
import { ReducerHandlers } from 'lib/store/reducer';
import { State as ArticleInsertState } from '../../reducers';
import { MediaOption } from 'types/article/insert';
import { ApiMediaObj } from 'types/medias/medias';

export type State = {
	readonly availableMedias: MediaOption[],
	readonly fetchedMedia: ApiMediaObj | null
};

export const INITIAL_STATE: State = {
	availableMedias: [],
	fetchedMedia: null
};

export const reducerHandlers: ReducerHandlers<ArticleInsertState> = {
	setAvailableMedias: (state, { payload: { medias } }: Actions["setAvailableMedias"]) => ({
		...state,
		form: {
			...state.form,
			online: {
				...state.form.online,
				availableMedias: medias
			}
		}
	}),
	fetchMediasError: (state, payload: Actions["FetchMediasError"]) => ({
		...state,
		form: {
			...state.form,
			online: {
				...state.form.online,
				availableMedias: []
			}
		}
	}),
	setMediaInfo: (state, { payload: { media } }: Actions["SetMediaInfo"]) => ({
		...state,
		form: {
			...state.form,
			online: {
				...state.form.online,
				fetchedMedia: media
			}
		}
	}),
	fetchMediaInfoError: (state, payload: Actions["FetchMediaInfoError"]) => ({
		...state,
		form: {
			...state.form,
			online: {
				...state.form.online,
				fetchedMedia: null
			}
		}
	})
};
