import React from 'react';

import ReportSetupFeeds from './Feeds';
import ReportSetupSettings from './Settings';
import ReportSetupSettingsSummary from './SettingsSummary';
import ReportSetupFocus from './Focus';

import { ComponentProps } from './types';

import './ReportSetup.scss';

const ReportSetup = (props: ComponentProps) => {
	const { focusId, step } = props;
	const { onResetSetup } = props;

	React.useEffect(() => {
		onResetSetup();
	}, [focusId]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div id="reportSetup">
			<ReportSetupFocus />
			{focusId && (<>
				{step === 'settings' && <ReportSetupSettings />}
				<ReportSetupSettingsSummary />
				{step === 'feed' && <ReportSetupFeeds />}
			</>)}
		</div>
	);
};

export default React.memo(ReportSetup);
