import FocusFeedDefinitionInformation from './FocusFeedDefinitionInformation';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';

import selectors from 'store/app/profile/selectors';
import { operators as focusFeedOperators } from 'store/focus/feed';
import { LinkedBrand } from 'class/Feed';
import { getAllBrands, getIsCrawled } from 'store/entities/Brand/selectors';

const mapStateToProps = (state: State): StateProps => ({
	user: selectors.getUserInstance(state)!,
	linkedBrandId: state.focus.feed.brand_id,
	linkedBrandList: getAllBrands(state),
	brand_sync: state.focus.feed.brand_sync,
	sentiment_check: state.focus.feed.sentiment_check,
	getBrandIsCrawled: (brandId: string) => getIsCrawled(state, brandId)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onAddLinkedBrand: (linkedBrand: LinkedBrand) => dispatch(focusFeedOperators.addLinkedBrand({ linkedBrand })),
	onSetBrandSync: (brand_sync: boolean) => dispatch(focusFeedOperators.setBrandSync({ brand_sync })),
	onSetSentimentCheck: (sentiment_check: boolean) => dispatch(focusFeedOperators.setSentimentCheck({ sentiment_check }))
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedDefinitionInformation);
