import { createSelector } from "reselect";
import { createOrmSelector, OrmSelector, ormEntities, sessionSelector } from "store/entities/selectors";

import { EmailListObject } from "store/entities/EmailList";
import { FeedObject } from "store/entities/Feed";
import { NewsletterObject } from "./types";

const get: OrmSelector<NewsletterObject> = createOrmSelector(ormEntities.Newsletter);
const getEmailLists: OrmSelector<EmailListObject[]> = createOrmSelector(ormEntities.Newsletter.emailLists);
const getFeeds: OrmSelector<FeedObject[]> = createOrmSelector(ormEntities.Newsletter.feeds);

const getInstance = createSelector(sessionSelector, (_: any, id: string) => id,
	({ Newsletter }, id) => Newsletter.withId(id));

const getInstances = createSelector(sessionSelector,
	({ Newsletter }) => Newsletter.all().toModelArray());

export default {
	get,
	getEmailLists,
	getFeeds,
	getInstance,
	getInstances
};
