import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import profileSelectors from 'store/app/profile/selectors';

import ReportConfirmationDialog from './ReportConfirmationDialog';
import selectors from 'store/ui/report/setup/selectors';

const mapStateToProps = (state: State): StateProps => ({
	type: selectors.getType(state),
	user: profileSelectors.getUser(state)!
});

const mapDispatchToProps = (): DispatchProps => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportConfirmationDialog);
