import React from 'react';

import { Button, FontIcon, Collapse } from 'lib/imports/react-md';

import { ComponentProps } from './types';
import { withT } from 'lib/i18n';
import featureFlags from 'lib/featureFlags';
import providerDiscovery from 'lib/providerDiscovery';
import { ErrorProfile, ProfileExpressionsType, SocialProfileExpression } from 'class/Feed';
import ListDialog from 'components/common/dialog/list/ListDialog';
import { Columns } from 'components/common/DiscoverTable';
import { FocusFeedDefinitionMultipleExpressions } from "../../../MultipleExpressions";
import SimpleDialog from 'components/common/dialog/simple';
import FocusFeedDefinitionSocialExpressionProfile from './Expression/FocusFeedDefinitionSocialExpressionProfile';

import redCircledBin from 'vendor/svg/feed/definition/bin_icon.svg';
import "./FocusFeedDefinitionLayoutProfile.scss";
import { reject } from 'lodash';

type ProfileExpressionToRemove = {
	expressionKey: ProfileExpressionsType,
	index: number;
};

const FocusFeedDefinitionLayoutProfile = (props: ComponentProps) => {
	const { definition, loadingProfiles, errorProfiles, brand_sync, t } = props;
	const { onSetDefinition, onSearchMultipleProfiles, onClearErrorProfiles, expressionFunctions } = props;

	const [collapse, setCollapse] = React.useState<boolean>(false);
	const [profileExpressionToRemove, setProfileExpressionToRemove] = React.useState<ProfileExpressionToRemove | null>(null);
	const [profileExpressionTypeToRemove, setProfileExpressionTypeToRemove] = React.useState<ProfileExpressionsType>();
	const [showRemoveProfileExpressionDialog, setShowRemoveProfileExpressionDialog] = React.useState<boolean>(false);
	const [showMassiveRemoveProfileExpressionDialog, setShowMassiveRemoveProfileExpressionDialog] = React.useState<boolean>(false);
	const [showMassiveSocialInclude, setShowMassiveSocialInclude] = React.useState<boolean>(false);
	const [showMassiveSocialExclude, setShowMassiveSocialExclude] = React.useState<boolean>(false);

	const onMultipleProfiles = React.useCallback((input: string, type: ProfileExpressionsType) => {
		const splitProfileURLs = input.split('\n').map(pu => pu.toLowerCase()).filter(u => u && u !== '');
		onSearchMultipleProfiles(splitProfileURLs, type);
		type === 'include_profiles' ? setShowMassiveSocialInclude(false) : setShowMassiveSocialExclude(false);
	}, [onSearchMultipleProfiles]);

	/* START OF REMOVAL SECTION */
	const cancelProfileExpressionRemoval = React.useCallback(() => {
		setShowRemoveProfileExpressionDialog(false);
	}, []);

	const removeProfileExpression = React.useCallback((expressionKey: ProfileExpressionsType, index: number) => {
		onSetDefinition({
			...definition,
			[expressionKey]: reject(definition[expressionKey], (expression, pos: number) => index === pos)
		});
	}, [onSetDefinition, definition]);

	const confirmProfileExpressionRemoval = React.useCallback(() => {
		const { expressionKey, index } = profileExpressionToRemove!;
		removeProfileExpression(expressionKey, index);
		setShowRemoveProfileExpressionDialog(false);
	}, [profileExpressionToRemove, removeProfileExpression]);

	const removeProfileExpressionHandler = React.useCallback((expressionKey: ProfileExpressionsType, index: number, value: string) => {
		if (value === '') return removeProfileExpression(expressionKey, index);
		setProfileExpressionToRemove({ expressionKey, index });
		setShowRemoveProfileExpressionDialog(true);
	}, [removeProfileExpression]);
	/* END OF REMOVAL SECTION */

	/* START OF MASSIVE REMOVAL SECTION */
	const cancelMassiveProfileExpressionRemoval = React.useCallback(() => {
		setShowMassiveRemoveProfileExpressionDialog(false);
	}, []);

	const removeAllProfileExpressions = React.useCallback((expressionKey: ProfileExpressionsType) => {
		onSetDefinition({
			...definition,
			[expressionKey]: []
		});
	}, [definition, onSetDefinition]);

	const confirmMassiveProfileExpressionRemoval = React.useCallback(() => {
		removeAllProfileExpressions(profileExpressionTypeToRemove!);
		setShowMassiveRemoveProfileExpressionDialog(false);
	}, [profileExpressionTypeToRemove, removeAllProfileExpressions]);

	const removeAllProfileExpressionHandler = React.useCallback((expressionKey: ProfileExpressionsType) => {
		setProfileExpressionTypeToRemove(expressionKey);
		setShowMassiveRemoveProfileExpressionDialog(true);
	}, []);
	/* END OF MASSIVE REMOVAL SECTION */

	const addProfileExpression = React.useCallback((key: ProfileExpressionsType) => {
		onSetDefinition({
			...definition,
			[key]: [
				...definition[key],
				{
					id: "",
					url: "",
					name: "",
					screen_name: "",
					picture: "",
					channel_type_id: 0,
					api_version: "",
					enabled: true
				}
			]
		});
	}, [onSetDefinition, definition]);

	const massiveClearAll = React.useCallback((type: string) => {
		const buttonId = type === 'include' ? 'Include' : 'Exclude';
		return <div className="definition-social-text-header">
			<div className="definition-text" dangerouslySetInnerHTML={{ __html: t(`feed.form.header_${type}_profiles_react`) + ":" }}></div>
			{
				((type === 'include' && definition.include_profiles.length === 0) || (type === 'exclude' && definition.exclude_profiles.length === 0)) ? <></> :
					<Button
						id={`definitionSocialRemoveAll${buttonId}Profiles`}
						className='definition-social-remove-all-button'
						flat
						onClick={() => removeAllProfileExpressionHandler(`${type}_profiles` as ProfileExpressionsType)}
					>
						<div>{t('feed.form.header_clear_all')}</div>
						<FontIcon className='definition-social-remove-all-button-icon'>delete</FontIcon>
					</Button>
			}
		</div>;
	}, [definition.exclude_profiles.length, definition.include_profiles.length, removeAllProfileExpressionHandler, t]);

	const massiveSectionButtons = React.useCallback((type: string) => {
		const buttonId = type === 'include' ? 'Include' : 'Exclude';
		if ((type === 'include' && showMassiveSocialInclude) || (type === 'exclude' && showMassiveSocialExclude)) return <></>;
		return <div className="definition-add-expression">
			<Button
				id={`focusFeedDefinitionSocialAdd${buttonId}ProfileBtn`}
				className="definition-add-expression-button"
				flat
				onClick={() => { addProfileExpression(`${type}_profiles` as ProfileExpressionsType); }}
			>
				<span>+</span> {t('feed.form.button.add_words_react')}
			</Button>
			<Button
				id={`focusFeedDefinitionSocialAdd${buttonId}ManyProfilesBtn`}
				className="definition-add-expression-button"
				flat
				onClick={() => { type === 'include' ? setShowMassiveSocialInclude(true) : setShowMassiveSocialExclude(true); }}
			>
				<span>+</span> {t('feed.form.button.add_url_list')}
			</Button>
		</div>;
	}, [addProfileExpression, showMassiveSocialExclude, showMassiveSocialInclude, t]);

	const massiveSectionInput = React.useCallback((type: string) => {
		return <FocusFeedDefinitionMultipleExpressions
			key={`socialMultipleExpressions${type}`}
			enabled={type === 'include' ? showMassiveSocialInclude : showMassiveSocialExclude}
			placeholder={t('feed.form.multiple_urls.placeholder_enter_urls')}
			cancelLabel={t('feed.form.multiple_urls.cancel')}
			submitLabel={t('feed.form.multiple_urls.submit')}
			onCancel={() => { type === 'include' ? setShowMassiveSocialInclude(false) : setShowMassiveSocialExclude(false); }}
			onSubmit={input => onMultipleProfiles(input, `${type}_profiles` as ProfileExpressionsType)}
		/>;
	}, [onMultipleProfiles, showMassiveSocialExclude, showMassiveSocialInclude, t]);

	const setProfileExpression = React.useCallback((type: string, expression: SocialProfileExpression, index: number) => {
		return <FocusFeedDefinitionSocialExpressionProfile
			key={`${type}Profiles${index}`}
			expressionKey={`${type}_profiles` as ProfileExpressionsType}
			index={index}
			expression={expression}
			loading={type === 'include' ? loadingProfiles.include_profiles[index] : loadingProfiles.exclude_profiles[index]}
			onRemoveProfileExpression={removeProfileExpressionHandler}
			{...expressionFunctions}
		/>;
	}, [expressionFunctions, loadingProfiles.exclude_profiles, loadingProfiles.include_profiles, removeProfileExpressionHandler]);

	const removeDialog = React.useMemo(() => (showRemoveProfileExpressionDialog ?
		(
			<SimpleDialog
				id="focusFeedDefinitionRemoveExpressionWarning"
				content={<></>}
				text={t('feed.form.confirm_remove_expression')}
				onAccept={confirmProfileExpressionRemoval}
				onCancel={cancelProfileExpressionRemoval}
				acceptColor="red"
			/>
		)
		: null
	), [cancelProfileExpressionRemoval, confirmProfileExpressionRemoval, showRemoveProfileExpressionDialog, t]);

	const removeMassiveDialog = React.useMemo(() => (showMassiveRemoveProfileExpressionDialog ?
		(
			<SimpleDialog
				id="focusFeedDefinitionRemoveAllExpressionWarning"
				content={<></>}
				text={t('feed.form.confirm_remove_all_expressions')}
				headerImage={redCircledBin}
				onAccept={confirmMassiveProfileExpressionRemoval}
				onCancel={cancelMassiveProfileExpressionRemoval}
				acceptColor="red"
			/>
		)
		: null
	), [cancelMassiveProfileExpressionRemoval, confirmMassiveProfileExpressionRemoval, showMassiveRemoveProfileExpressionDialog, t]);

	const errorDialog = React.useMemo(() => {
		if (errorProfiles.length === 0) return <></>;
		return <ListDialog
			id="socialErrorProfilesListDialog"
			title={t("definition.online.media.error.title")}
			headerText={t("definition.online.media.error.header")}
			tableData={{
				data: errorProfiles,
				columns: [{
					key: 'url',
					type: 'text',
					displayName: t('instagram.hashtags.form.profile.label')
				} as Columns<ErrorProfile, ErrorProfile>, {
					key: 'error',
					type: 'text',
					displayName: t('definition.online.media.error.reason')
				} as Columns<ErrorProfile, ErrorProfile>]
			}}
			onCancel={onClearErrorProfiles}
			onFillClipboard={() => (errorProfiles.map(profileError => profileError.url)).join('\n')}
		/>;

	}, [errorProfiles, onClearErrorProfiles, t]);

	const profileSection = React.useMemo(() => {
		const isCollapsed = collapse || brand_sync;
		return <div id="focusFeedDefinitionSocialProfileSection" className={`definition-section ${brand_sync ? 'definition-section-disabled' : ''}`}>
			<div className="definition-section-header">
				<div className="definition-header-title" onClick={() => setCollapse(!collapse)}>
					{
						isCollapsed ?
							<FontIcon className="definition-header-title-toggle" >keyboard_arrow_right</FontIcon> :
							<FontIcon className="definition-header-title-toggle">keyboard_arrow_down</FontIcon>
					}
					<span className="definition-header-title-text">{t('feed.form.header_source_expression')}</span>
				</div>
			</div>
			<Collapse collapsed={isCollapsed}>
				<div id="focusFeedDefinitionProfileSection" className={`definition-section-content ${brand_sync && 'disabled'}`}>
					<div className="definition-expressions">
						{massiveClearAll('include')}
						{massiveSectionButtons('include')}
						{massiveSectionInput('include')}
						{definition.include_profiles.map((expression, index) => {
							if (providerDiscovery.isParklu(expression.channel_type_id) && !featureFlags.isEnabled('parklu')) expression = { ...expression, error: true };
							if (providerDiscovery.isTikTok(expression.channel_type_id)) expression.picture = '';
							return setProfileExpression('include', expression, index);
						})}

						{massiveClearAll('exclude')}
						{massiveSectionButtons('exclude')}
						{massiveSectionInput('exclude')}
						{definition.exclude_profiles.map((expression, index) => {
							if (providerDiscovery.isParklu(expression.channel_type_id) && !featureFlags.isEnabled('parklu')) expression = { ...expression, error: true };
							if (providerDiscovery.isTikTok(expression.channel_type_id)) expression.picture = '';
							return setProfileExpression('exclude', expression, index);
						})}
					</div>
				</div>
			</Collapse>
		</div>;
	}, [brand_sync, collapse, definition.exclude_profiles, definition.include_profiles, massiveClearAll, massiveSectionButtons, massiveSectionInput, setProfileExpression, t]);

	return (
		<>
			{errorDialog}
			{removeDialog}
			{removeMassiveDialog}
			{profileSection}
		</>
	);
};

export default withT(React.memo(FocusFeedDefinitionLayoutProfile));
