import React from 'react';
import { DatePicker } from 'react-md/lib/Pickers';
import moment from 'moment';

import { withT } from 'lib/i18n';
import { DateFieldElement } from 'types/form';
import { formFieldBaseValidation } from 'components/common/Form/Form';
import { useForceFieldValue } from 'lib/hooks';
import { ComponentProps } from './types';

import './FormDateField.scss';

const __validateDateField = (element: DateFieldElement, newValue: Date) => {
	if (!formFieldBaseValidation(element, newValue)) return false;
	if (!element.validations) return true;
	return true;
};

const FormDateField = (props: ComponentProps) => {
	const { t, element, forceValue, user, error, onChangeDate, label } = props;

	const [date, setDate] = React.useState(element.defaultValue || new Date());
	useForceFieldValue(forceValue, null, element, setDate, onChangeDate, __validateDateField);

	const handleDateChange = React.useCallback((formattedDate, rawDate, ev) => {
		setDate(rawDate);

		const parsedDate = moment.tz(moment(rawDate).format('YYYY-MM-DD'), user.settings.timezone).toDate();
		onChangeDate(element.id, parsedDate, __validateDateField(element, parsedDate));
	}, [element, onChangeDate, user]);

	return (
		<div className='discover-form-date-field' >
			<DatePicker
				id={`formDateField-${element.id}`}
				label={label ? label : t("insert_article.modal.form.date")}
				okLabel={t('filters.period.custom.dialog.ok')}
				cancelLabel={t('filters.period.custom.dialog.cancel')}
				icon={null}
				value={date}
				locales={user.settings.locale}
				minDate={element.minDate}
				maxDate={element.maxDate}
				onChange={handleDateChange}
				required={element.required}
				error={error}
				disabled={element.disabled}
				{...element.inputProps}
			/>
		</div>
	);
};

export default withT(React.memo(FormDateField));
