import React from 'react';
import { ComponentProps } from './types';
import { Button } from 'lib/imports/react-md';

import './HeaderCellHelp.scss';

export const HeaderCellHelp = ({ text, position = 'right', tooltipStyles }: ComponentProps) => {
	const tootltip = (
		<div
			dangerouslySetInnerHTML={{ __html: text }}
			className={tooltipStyles ? tooltipStyles.join(' ') : ''}
		>
		</div>);

	return (
		<Button
			icon
			tooltipLabel={tootltip}
			tooltipPosition={position}
		>
			help_outline
		</Button>
	);
};