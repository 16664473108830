const config: { [index: string]: any } = {
	"common": {
		AXIOS_CANCELLED: false,
		SAGA_CANCELLED: false,
		USER_NOT_ALLOWED: {
			t: ["error.api_alert_prefix_react", { errorMessage: { path: "error.message" } }],
			level: "warning"
		},
		MAINTENANCE: {
			t: "maintenance_mode.notification",
			level: "warning"
		}
	},
	"@@app/facebook": {
		UPDATE_AUTH_ERROR: {
			DUPLICATED_FACEBOOK_USER: {
				t: "facebook_login.account_already_exists",
				level: "info"
			},
			FACEBOOK_USER_DIFFERENT_TENANT: {
				t: ["facebook_login.account_owned_by_other_tenant", { tenant: { path: "error.tenant" } }],
				level: "info"
			},
			FACEBOOK_API_ERROR: {
				t: "facebook_login.graph_api_error",
				level: "warning"
			}
		},
		UPDATE_STATUS_ERROR: false
	},
	"@@focus/list": {
		CREATE_FOCUS_ERROR: {
			NAME_ALREADY_EXISTS: {
				t: "error.name_already_exists",
				level: "danger"
			}
		},
		RENAME_FOCUS_ERROR: {
			NAME_ALREADY_EXISTS: {
				t: "error.name_already_exists",
				level: "danger"
			}
		}
	},
	"@@search": {
		FETCH_SEARCH_ERROR: {
			ERROR_IN_QUERY: {
				t: "error.query_syntax",
				level: "warning"
			}
		}
	},
	"@@search/results": {
		SET_TAGS_CATEGORY_ERROR: {
			INVALID_TAG: {
				t: "error.invalid_tag",
				level: "danger"
			},
			WRONG_PARAMETERS: {
				text: { path: "error.message" },
				level: 'warning'
			}
		},
		FETCH_SEARCH_ERROR: {
			ERROR_IN_QUERY: {
				t: "error.query_syntax",
				level: "warning"
			}
		}
	},
	"@@focus/feed": {
		RECOVER_FEED_ERROR: {
			ANOTHER_RECOVERY_ALREADY_EXISTS: {
				t: "feed.labels.recovery.warning.another_in_flight",
				level: "warning"
			},
			ERROR_IN_QUERY: {
				t: "error.query_syntax",
				level: "warning"
			},
			NAME_ALREADY_EXISTS: {
				t: "feed.form.duplicated_name",
				level: "danger"
			}
		},
		SAVE_FEED_ERROR: {
			ERROR_IN_QUERY: {
				t: "error.query_syntax",
				level: "warning"
			},
			NAME_ALREADY_EXISTS: {
				t: "feed.form.duplicated_name",
				level: "danger"
			}
		},
		DUPLICATE_FEED_ERROR: {
			ERROR_IN_QUERY: {
				t: "error.query_syntax",
				level: "warning"
			},
			NAME_ALREADY_EXISTS: {
				t: "feed.form.duplicated_name",
				level: "danger"
			},
			FEED_DEFINITION_ERROR: {
				t: "feed.form.invalid_expressions",
				level: "warning"
			}
		},
		SAVE_FEED_NAME_ERROR: {
			ERROR_IN_QUERY: {
				t: "error.query_syntax",
				level: "warning"
			},
			NAME_ALREADY_EXISTS: {
				t: "feed.form.duplicated_name",
				level: "danger"
			}
		},
		REMOVE_FEED_ERROR: {
			ERROR_IN_QUERY: {
				t: "error.query_syntax",
				level: "warning"
			}
		}
	},
	"@@focus/feed/social": {
		AUTHORIZE_DIALOG_SELECTED_ACCOUNTS_ERROR: {
			TENANT_NOT_ALLOWED: {
				t: "facebook_login.instagram.account_already_in_tenant",
				level: "info"
			}
		},
		SEARCH_PROFILE_ERROR: {
			UNKNOWN_ERROR: {
				t: "feed.form.error.profile.unknown_error",
				level: "warning"
			},
			UNKNOWN_PROVIDER: {
				t: "feed.form.error.profile.unknown_provider",
				level: "warning"
			},
			OBJECT_NOT_FOUND: {
				t: "feed.form.error.profile.not_found",
				level: "warning"
			},
			USER_NOT_ALLOWED: {
				t: "feed.form.error.profile.user_not_allowed",
				level: "warning"
			},
			RATE_LIMIT: {
				t: "feed.form.error.profile.rate_limit",
				level: "warning"
			},
			NOT_PARKLU_DATA: {
				t: "feed.form.error.profile.no_parklu_activated",
				level: "warning"
			}
		}
	},
	"@@article/insert": {
		CHANGE_STEP_ERROR: {
			URL_NOT_VALID: {
				t: "error.url_not_valid",
				level: "warning"
			},
			WRONG_PARAMETERS: {
				t: 'insert_article.modal.wrong_params',
				level: 'warning'
			}
		}
	},
	// TODO: put this in a more generic way ?
	"@@newsletter/list": {
		TOGGLE_NEWSLETTER_ENABLED_ERROR: {
			WRONG_PARAMETERS: {
				t: ["error.api_alert_prefix_react", { errorMessage: { path: "error.message" } }],
				level: "warning"
			}
		},
		REMOVE_NEWSLETTER_ERROR: {
			WRONG_PARAMETERS: {
				t: ["error.api_alert_prefix_react", { errorMessage: { path: "error.message" } }],
				level: "warning"
			}
		}
	},
	"@@topic": {
		CREATE_TOPIC_ERROR: {
			TOPIC_ALREADY_EXISTS: {
				t: "topics.name_already_exists",
				level: "danger"
			}
		},
		RENAME_TOPIC_ERROR: {
			TOPIC_ALREADY_EXISTS: {
				t: "topics.name_already_exists",
				level: "danger"
			}
		}
	},
	"@@topic/tag/manager": {
		MIGRATE_TAG_ERROR: {
			TAG_ALREADY_EXISTS: {
				t: "tags.already_exists_msg",
				level: "warning"
			}
		}
	}
};

export default config;
