import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/ui/report/setup';
import setupSelectors from 'store/ui/report/setup/selectors';
import { DispatchProps, StateProps } from './types';

import ReportSetupSettingsPeriod from './ReportSetupSettingsPeriod';

const mapStateToProps = (state: State): StateProps => ({
	reportType: setupSelectors.getType(state),
	reportPeriod: setupSelectors.getPeriod(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onChangePeriod: period => dispatch(operators.changeSettings({ period }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSetupSettingsPeriod);
