const config: { [index: string]: any } = {
	"@@app/profile": {
		UPDATE_USER_SETTINGS_SUCCESS: {
			t: "account.saved",
			level: "success"
		},
		UPDATE_TENANT_SETTINGS_SUCCESS: {
			t: "account.saved",
			level: "success"
		}
	},
	"@@app/facebook": {
		DEAUTHORIZE_SUCCESS: {
			t: "account.facebook_accounts.deauthorize.success",
			level: "success"
		}
	}
};

export default config;
