import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { Actions, operators } from './actions';

export type State = {
	startStopLoading: boolean,
	selectedTag: any | null,
};

export const INITIAL_STATE: State = {
	startStopLoading: false,
	selectedTag: null
};

const reducerHandlers: ReducerHandlers<State> = {
	setSelectedTag: (state, { payload: tag }: Actions['SetSelectedTag']): State => ({
		...state,
		selectedTag: {...tag.tag}
	}),
	updateTagAutomation: state => ({ ...state, startStopLoading: true }),
	updateTagAutomationSuccess: (state, { payload: tagAutomation }: Actions['UpdateTagAutomationSuccess']): State => ({
		...state,
		startStopLoading: false,
		selectedTag: {...state.selectedTag, tag_automation: {...tagAutomation}}
	}),
	updateTagAutomationError: state => ({ ...state, startStopLoading: false })
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
