import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import selectors from 'store/app/facebook/selectors';
import { operators } from 'store/app/facebook';

import { DispatchProps, StateProps } from './types';

import FacebookAuth from './FacebookAuthInstagram';

const mapStateToProps = (state: State): StateProps => ({
	dialogMode: selectors.getDialogMode(state),
	permissionsMissing: selectors.getPermissionsMissing(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetDialogMode: mode => dispatch(operators.setDialogMode(mode)),
	onUpdateAuth: authResponse => dispatch(operators.updateAuth(authResponse)),
	onDeauthorize: () => dispatch(operators.deauthorize())
});

export default connect(mapStateToProps, mapDispatchToProps)(FacebookAuth);
