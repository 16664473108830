import React from 'react';

import './DropdownFilterClear.scss';

interface Props {
  onClick(): void;
}

function DropdownFilterClear(props: Props) {
	const { onClick } = props;

	return (
		<div id='dropdown-filter-clear' key='dropdown-filter-clear' className="btn btn-clear btn-sm" role="button" onClick={() => onClick()}>
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M12 4L4 12" stroke="#424242" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M4 4L12 12" stroke="#424242" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</div>
	);
}

export default DropdownFilterClear;
