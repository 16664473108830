import React from 'react';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import axios from 'axios';
import moment from 'moment';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import FocusDashboardLogoDialog from './Dialog';

import './FocusDashboardLogo.scss';

const FocusDashboardLogo = (props: ComponentProps) => {
	const { t, focus, imageMeta, loading } = props;

	const [showDialog, setShowDialog] = React.useState(false);
	const [imgBlob, setImgBlob] = React.useState<string | null>(null);

	const onOpenDialog = React.useCallback(() => {
		setShowDialog(true);
	}, [setShowDialog]);

	const onCloseDialog = React.useCallback(() => {
		setShowDialog(false);
	}, [setShowDialog]);

	React.useEffect(() => {
		if (!loading) {
			(async () => {
				try {
					if (imgBlob) URL.revokeObjectURL(imgBlob);
					const { data: imageData } = await axios.get(`${focus.url_logo}?time=${moment().unix()}`, { responseType: 'blob' });
					setImgBlob(URL.createObjectURL(imageData));
				} catch (err) {
					setImgBlob(null);
				}
			})();
		} else setImgBlob(null);
	}, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

	const content = React.useMemo(() => {
		if ((loading && imageMeta) || !imgBlob) return <CircularProgress id="focusDashboardLogoLoading" />;
		return <>
			<img id="focusDashboardLogoImg" alt="Focus logo" src={imgBlob} />
			<FontIcon iconClassName="icon-edit" />
		</>;
	}, [loading, imageMeta, imgBlob]);

	return <>
		<div id="focusDashboardLogo" title={t('focus.uploadLogo.icon_title')} onClick={onOpenDialog}>
			{content}
		</div>
		{showDialog ? <FocusDashboardLogoDialog onClose={onCloseDialog} /> : null}
	</>;
};

export default withT(React.memo(FocusDashboardLogo));
