import { createSelector } from 'reselect';
import { ormEntities, sessionSelector, createOrmSelector, OrmSelector } from "store/entities/selectors";
import { FacebookUserObject } from "store/entities/FacebookUser/types";

import { UserObject } from "./types";

export const get: OrmSelector<UserObject> = createOrmSelector(ormEntities.User);
export const getFacebookUsers: OrmSelector<FacebookUserObject[]> = createOrmSelector(ormEntities.User.facebookUsers);

export const getInstance = createSelector(sessionSelector, (_: any, id: string) => id,
	({ User }, id) => User.withId(id)
);

export const getFacebookUserInstances = createSelector(sessionSelector, (_: any, id: string) => id,
	({ User }, id) => {
		const user = User.withId(id);
		if (!user) return null;
		return user.facebookUsers.toModelArray();
	}
);

export default {
	get,
	getFacebookUsers,
	getInstance,
	getFacebookUserInstances
};
