import React, { useCallback, useMemo } from 'react';
import { concat } from 'lodash';
import DropdownMenu from 'react-md/lib/Menus/DropdownMenu';
import { Link } from 'react-router-dom';
import { Button, FontIcon, IconSeparator } from 'react-md';
import Subheader from 'react-md/lib/Subheaders/Subheader';
import ListItem from 'react-md/lib/Lists/ListItem';
import Divider from 'react-md/lib/Dividers/Divider';
import GA from 'lib/googleAnalytics';
import { TFunction } from 'i18next';
import { User } from 'class/User';

export function FormOptions(
	{
		t,
		showExport,
		limit,
		userClass,
		onSetLimit,
		onSetShowInsertDocument
	}: {
		t: TFunction,
		showExport: boolean,
		limit: number,
		userClass: User,
		onSetLimit: (value: number) => void
		onSetShowInsertDocument: (show: boolean) => void
	}) {

	const handleLimit = useCallback(targetLimit => {
		GA.trackEvent({ category: "Feed results", action: 'Placements per page', label: String(targetLimit) });
		onSetLimit(targetLimit);
	}, [onSetLimit]);


	const paginationOpts = useMemo(() => [
		<Subheader key={4} primaryText={t('results.page_size.header')} />,
		/* eslint-disable max-len */
		<ListItem key={5} leftIcon={limit === 20 ? <FontIcon>check</FontIcon> : null} primaryText={t('results.page_size.value', { size: 20 })} onClick={() => { handleLimit(20); }} />,
		<ListItem key={6} leftIcon={limit === 50 ? <FontIcon>check</FontIcon> : null} primaryText={t('results.page_size.value', { size: 50 })} onClick={() => { handleLimit(50); }} />,
		<ListItem key={7} leftIcon={limit === 100 ? <FontIcon>check</FontIcon> : null} primaryText={t('results.page_size.value', { size: 100 })} onClick={() => { handleLimit(100); }} />,
		<ListItem key={8} leftIcon={limit === 200 ? <FontIcon>check</FontIcon> : null} primaryText={t('results.page_size.value', { size: 200 })} onClick={() => { handleLimit(200); }} />
	], [limit, handleLimit, t]);

	const managerOpts = useMemo(() =>
		userClass.hasPermission('document.create') ?
			[
				<ListItem key={1} primaryText={t('results.manage_my_feeds')} component={Link} to="/focus" onClick={() => {
					GA.trackEvent({ category: 'Feed results', action: 'Manage feeds' });
				}} />,
				<ListItem key={2} primaryText={t('results.insert_mention')} onClick={() => { onSetShowInsertDocument(true); }} />,
				<Divider key={3} />
			] : [], [userClass, onSetShowInsertDocument, t]);
	return (
		<DropdownMenu
			id="formOptions"
			className={`form-options-select ${showExport ? 'formOptions-short' : ''}`}
			position={DropdownMenu.Positions.BOTTOM_RIGHT}
			menuItems={concat(managerOpts, paginationOpts)}
		>
			<Button flat iconChildren="settings arrow_drop_down" >
				<IconSeparator label="" children="" />
			</Button>
		</DropdownMenu>
	);
}
