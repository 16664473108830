import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { Actions, operators } from './actions';

import * as social from './definition/social';
import * as print from './definition/print';
import * as online from './definition/online';
import * as printDmr from './definition/printDmr';

import { FeedCampaign, FeedType } from 'class/Feed';

export type FocusFeedStateDefinitions = { social: social.State, print: print.State, online: online.State, printDmr: printDmr.State; };

export type State = {
	readonly social: social.State;
	readonly print: print.State;
	readonly online: online.State;
	readonly printDmr: printDmr.State;
	readonly feedId: string | null;
	readonly feedName: string | null;
	readonly feedType: FeedType | null;
	readonly focusId: string | null;
	readonly feedEnabled: boolean;
	readonly editNameInputValue: string | null;
	readonly loadingSaveFeedName: boolean;
	readonly feedChanged: boolean;
	readonly loadingSaveFeed: boolean;
	readonly recoveryId: string | null;
	readonly recoveryStatus: string | null;
	readonly showDuplicateFeed: boolean;
	readonly duplicatedFeedId: string | null;
	readonly loadingDuplicateFeed: boolean;
	readonly brand_id: string;
	readonly is_complex: boolean;
	readonly brand_sync: boolean;
	readonly sentiment_check: boolean;
	readonly approximate_hits: number;
	readonly feed_campaign: FeedCampaign[];
	readonly show_complexity_dialog: boolean;
};

export const INITIAL_STATE: State = {
	social: social.INITIAL_STATE,
	print: print.INITIAL_STATE,
	online: online.INITIAL_STATE,
	printDmr: printDmr.INITIAL_STATE,
	feedId: null,
	feedName: null,
	feedType: null,
	focusId: null,
	feedEnabled: false,
	editNameInputValue: null,
	loadingSaveFeedName: false,
	feedChanged: false,
	loadingSaveFeed: false,
	recoveryId: null,
	recoveryStatus: null,
	showDuplicateFeed: false,
	duplicatedFeedId: null,
	loadingDuplicateFeed: false,
	brand_id: '',
	is_complex: false,
	brand_sync: false,
	sentiment_check: false,
	approximate_hits: 0,
	feed_campaign: [],
	show_complexity_dialog: false
};

const reducerHandlers: ReducerHandlers<State> = {
	...social.reducerHandlers,
	...print.reducerHandlers,
	...online.reducerHandlers,
	...printDmr.reducerHandlers,
	setInitialFeed: (state, { payload: { feedType, focusId, definitions } }: Actions["SetInitialFeed"]): State => ({
		...INITIAL_STATE,
		focusId,
		feedType,
		...definitions
	}),
	fetchFeedError: (state, { payload }: Actions["fetchFeedError"]): State => ({
		...state,
		feedId: null,
		feedName: null,
		feedType: null,
		focusId: null,
		feedEnabled: false,
		feedChanged: false
	}),
	setFeed: (state, { payload: { feed, definitions } }: Actions["SetFeed"]): State => ({
		...state,
		feedId: feed.id,
		feedName: feed.name,
		feedType: feed.type,
		focusId: feed.focus_id,
		feedEnabled: feed.enabled,
		feedChanged: false,
		loadingSaveFeed: false,
		brand_id: feed.brand_id || '',
		brand_sync: feed.brand_sync,
		sentiment_check: feed.sentiment_check,
		feed_campaign: feed.feed_campaign || [{ campaign_id: '', enabled: true }],
		...definitions
	}),
	setEditNameInputValue: (state, { payload: { feedName } }: Actions["SetEditNameInputValue"]): State => ({
		...state,
		editNameInputValue: feedName
	}),
	setLoadingSaveFeedName: (state, { payload: { loading } }: Actions["SetLoadingSaveFeedName"]): State => ({
		...state,
		loadingSaveFeedName: loading
	}),
	saveFeedError: (state, { payload: { error } }: Actions["SaveFeedError"]): State => ({
		...state,
		loadingSaveFeed: false
	}),
	setLoadingSaveFeed: (state, { payload: { loading } }: Actions["SetLoadingSaveFeed"]): State => ({
		...state,
		loadingSaveFeed: loading
	}),
	saveFeedNameError: (state, { payload }: Actions["SaveFeedNameError"]): State => ({
		...state,
		loadingSaveFeedName: false
	}),
	saveFeedNameSuccess: (state, { payload: { feedName } }: Actions["SaveFeedNameSuccess"]): State => ({
		...state,
		feedName,
		loadingSaveFeedName: false
	}),
	toggleFeedEnabled: (state, { payload }: Actions["ToggleFeedEnabled"]): State => ({
		...state,
		feedEnabled: !state.feedEnabled,
		feedChanged: true
	}),
	recoverFeedSuccess: (state, { payload }: Actions["RecoverFeedSuccess"]): State => ({
		...state,
		recoveryId: payload.recoveryId
	}),
	recoverPollingStart: (state, { payload }: Actions["RecoverPollingStart"]): State => ({
		...state,
		recoveryStatus: null
	}),
	setRecoverPollingProgress: (state, { payload: { status } }: Actions["SetRecoverPollingProgress"]): State => ({
		...state,
		recoveryStatus: status
	}),
	toggleShowDuplicateFeed: (state, { payload }: Actions["ToggleShowDuplicateFeed"]): State => ({
		...state,
		showDuplicateFeed: !state.showDuplicateFeed,
		duplicatedFeedId: null
	}),
	duplicateFeedStart: (state, { payload }: Actions["DuplicateFeedStart"]): State => ({
		...state,
		loadingDuplicateFeed: true
	}),
	duplicateFeedSuccess: (state, { payload }: Actions["DuplicateFeedSuccess"]): State => ({
		...state,
		loadingDuplicateFeed: false,
		duplicatedFeedId: payload.feedId
	}),
	duplicateFeedError: (state, { payload }: Actions["DuplicateFeedError"]): State => ({
		...state,
		loadingDuplicateFeed: false
	}),
	addLinkedBrand: (state, { payload: { linkedBrand } }: Actions["AddLinkedBrand"]): State => ({
		...state,
		brand_id: linkedBrand.id
	}),
	setBrandSync: (state, { payload: { brand_sync } }: Actions["SetBrandSync"]): State => ({
		...state,
		brand_sync
	}),
	setSentimentCheck: (state, { payload: { sentiment_check } }: Actions["SetSentimentCheck"]): State => ({
		...state,
		sentiment_check
	}),
	setComplexityInfo: (state, { payload: { took, total } }: Actions["SetComplexityInfo"]): State => ({
		...state,
		is_complex: took > 2000,
		approximate_hits: total
	}),
	setShowComplexityDialog: (state, { payload: { show } }: Actions["SetShowComplexityDialog"]): State => ({
		...state,
		show_complexity_dialog: show
	}),
	setCampaigns: (state, { payload: { campaigns } }: Actions["SetCampaigns"]): State => ({
		...state,
		feed_campaign: campaigns
	}),
	setCampaignsExpression: (state, { payload: { index, campaign } }: Actions["SetCampaignsExpression"]): State => {
		const feedCampaigns = [...state.feed_campaign];
		feedCampaigns[index] = campaign;
		return {
			...state,
			feed_campaign: feedCampaigns
		};
	}
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
