import { Action, actionCreatorFactory } from 'typescript-fsa';
import { FacetObject, APIFacetItemsGroup, FacetItemArray } from 'class/Facet';

import * as extended from './extended';

const actionCreator = actionCreatorFactory('@@search/facets');

type Payloads = {
	ResetSearchFacetsData: void;
	ToggleDrawer: void;
	ToggleFacetsGroupOpened: { groupKey: string };
	SetFacetsGroupsOpened: { facetsGroupsOpened: string[] };
	FetchAPIFacets: void;
	SetAPIFacets: { facetsAPI: APIFacetItemsGroup };
	SetFacets: { facets: FacetObject };
	SetFacet: { itemFacets: FacetItemArray, groupKey: string };
	SetShowMoreFacetGroupKey: { facetGroupKey: string | null };
	SetShowMoreFacetGroupKeySuccess: { facetGroupKey: string | null };
	ToggleHiddenFacets: void;
	FetchGroupAPIFacets: { groupKey: string };
	FetchFacetsError: void;
}

export type Actions = {
	ResetSearchFacetsData: Action<Payloads["ResetSearchFacetsData"]>;
	ToggleDrawer: Action<Payloads["ToggleDrawer"]>;
	ToggleFacetsGroupOpened: Action<Payloads["ToggleFacetsGroupOpened"]>;
	SetFacetsGroupsOpened: Action<Payloads["SetFacetsGroupsOpened"]>;
	FetchAPIFacets: Action<Payloads["FetchAPIFacets"]>;
	SetAPIFacets: Action<Payloads["SetAPIFacets"]>;
	SetFacets: Action<Payloads["SetFacets"]>;
	SetFacet: Action<Payloads["SetFacet"]>;
	SetShowMoreFacetGroupKey: Action<Payloads["SetShowMoreFacetGroupKey"]>;
	SetShowMoreFacetGroupKeySuccess: Action<Payloads["SetShowMoreFacetGroupKeySuccess"]>;
	ToggleHiddenFacets: Action<Payloads["ToggleHiddenFacets"]>;
	FetchGroupAPIFacets: Action<Payloads["FetchGroupAPIFacets"]>;
	FetchFacetsError: Action<Payloads["FetchFacetsError"]>;
}

export const operators = {
	...extended.operators,
	resetSearchFacetsData: actionCreator<Payloads["ResetSearchFacetsData"]>('RESET_SEARCH_FACETS_DATA'),
	toggleDrawer: actionCreator<Payloads["ToggleDrawer"]>('TOGGLE_DRAWER'),
	toggleFacetsGroupOpened: actionCreator<Payloads["ToggleFacetsGroupOpened"]>('TOGGLE_FACETS_GROUP_OPENED'),
	setFacetsGroupsOpened: actionCreator<Payloads["SetFacetsGroupsOpened"]>('SET_FACETS_GROUPS_OPENED'),
	fetchAPIFacets: actionCreator<Payloads["FetchAPIFacets"]>('FETCH_API_FACETS'),
	setAPIFacets: actionCreator<Payloads["SetAPIFacets"]>('SET_API_FACETS'),
	setFacets: actionCreator<Payloads["SetFacets"]>('SET_FACETS'),
	setFacet: actionCreator<Payloads["SetFacet"]>('SET_FACET'),
	setShowMoreFacetGroupKey: actionCreator<Payloads["SetShowMoreFacetGroupKey"]>('SET_SHOW_MORE_FACET_GROUP_KEY'),
	setShowMoreFacetGroupKeySuccess: actionCreator<Payloads["SetShowMoreFacetGroupKeySuccess"]>('SET_SHOW_MORE_FACET_GROUP_KEY_SUCCESS'),
	toggleHiddenFacets: actionCreator<Payloads["ToggleHiddenFacets"]>('SHOW_HIDDEN_FACETS'),
	fetchGroupAPIFacets: actionCreator<Payloads["FetchGroupAPIFacets"]>('FETCH_GROUP_API_FACETS'),
	fetchFacetsError: actionCreator<Payloads["FetchFacetsError"]>('FETCH_FACETS_SEARCH_ERROR')
};
