import React from 'react';
import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import { Audit } from 'store/ui/audit/list/types';
import { NewsletterErrorDetails } from './NewsletterErrorDetails/';

import './DetailsColumn.scss';

function getDateFromTimestamp(tmsp: string) {
	return tmsp ? new Date(parseInt(tmsp) * 1000).toUTCString() : "Invalid Date";
}

const DetailsColumn = ({ audit, action, onResubscribe, newsletterData, t }: ComponentProps) => {

	function getDocumentData(audit: Audit) {
		switch (audit.entity.type) {
			case 'online':
			case 'social':
				const urlLink = audit.entity.url ? (<>- <a href={audit.entity.url}>URL</a></>) : null;
				return (<> {audit.entity.typology} placement ({audit.entity.type}) {urlLink} </>);
			case 'print':
				return "Print placement";
		};
	}

	function getTableDetails(audit: Audit) {
		switch (audit.action) {
			case 'Manual insertion':
				return (<> {getDocumentData(audit)} <br /> Feed: {audit.entity.otherNames} </>);
			case 'Manual deletion':
				return (<> {getDocumentData(audit)} </>);
			case 'Tagged':
			case 'Categorized':
				return (<> {audit.entity.otherNames} <br /> {getDocumentData(audit)} </>);
			case 'Tag removal':
			case 'Category removal':
				return (<> {audit.entity.otherNames} <br /> From: {getDocumentData(audit)} </>);
			case 'Focus creation':
			case 'Focus renaming':
			case 'Focus deletion':
			case 'Topic creation':
			case 'Topic renaming':
			case 'Topic deletion':
			case 'Feed Branding Sync linked':
			case 'Feed Branding Sync unlinked':
			case 'Feed Sentiment enable':
			case 'Feed Sentiment disable':
			case 'Newsletter update':
			case 'Newsletter creation':
			case 'Newsletter delete':
			case "Newsletter resubscribe":		
				return (<> {audit.entity.name} </>);
			case 'Tag creation':
			case 'Tag renaming':
			case 'Tag deletion':
			case 'Tag automation':
				return (<> {audit.entity.name} <br /> From topic: {audit.entity.parentEntity ? audit.entity.parentEntity.name : 'Not available'}</>);
			case 'Feed creation':
			case 'Feed renaming':
			case 'Feed update':
			case 'Feed deletion':
				return (<> {audit.entity.type} - {audit.entity.name} <br /> From focus: {audit.entity.parentEntity ? audit.entity.parentEntity.name : 'Not available'} </>);
			case 'Feed Branding update':
			case 'Feed Branding creation':
			case 'Feed Branding deletion':
			case 'Feed Branding unknown':
				return (<> {audit.entity.type} - {audit.entity.name} <br /> From feed: {audit.entity.parentEntity
					? `${audit.entity.parentEntity.name} (${audit.entity.parentEntity.id})`
					: 'Not available'} </>);
			case 'Newsletter open':
			case 'Newsletter delivery':
			case 'Newsletter click':
				return (<>{audit.entity.email}</>);
			case 'Newsletter failure':
				return (<>{audit.entity.email} <br /> {audit.entity.error}</>);
			case "Newsletter unsubscribe":
				return (<>
					{audit.entity.name}<br />
					<button className='link-button' onClick={() => onResubscribe(audit.entity.id, audit.entity.name)}>{t('audit.newsletter.resubscribe.label')}</button>
				</>);
			case "Newsletter queue":
				return (<>{getDateFromTimestamp(audit.entity.sendTime!)}</>);
			case "Newsletter generate":
				return (<>
					{getDateFromTimestamp(audit.entity.sendTime!)} <br />
					{audit.entity.error ? "Error: " + audit.entity.error : ""}
				</>);
			case "Newsletter send":
				return (<>
					{audit.entity.sentTo} <br />
					{getDateFromTimestamp(audit.entity.sendTime!)} <br />
					{audit.entity.error ? "Error: " + audit.entity.error : ""}
				</>);
			default:
				return 'Unknown';
		}
	}

	return (
		<div className="audit-list-details-column">
			<div className="audit-list-details-column-details">
				<b>{action}</b>
				<p>{getTableDetails(audit)}</p>
			</div>
			{newsletterData && <NewsletterErrorDetails details="" newsletterData={newsletterData} />}
		</div>
	);
};

export default withT(DetailsColumn);
