import React from 'react';

import { Button } from 'lib/imports/react-md';
import { ComponentProps } from './types';

import './MediaItem.scss';

const MediaItem = (props: ComponentProps) => {
	const { expressionKey, index, media } = props;
	const { onRemoveMedia } = props;

	return (
		<div
			id={`focusFeedDefinitionMediaItem-${expressionKey}-${index}`}
			className="focus-feed-definition-media-item"
		>
			<div className="media-item-info">
				<span className={`media-item-info-name`}>{media.name}{media.country ? ' - ' + media.country : ''}</span>
			</div>

			<Button
				id={`focusFeedDefinitionMediaItemRemoveButton-${expressionKey}-${index}`}
				className={`media-item-remove-button`}
				icon
				onClick={() => onRemoveMedia(expressionKey, index)}
			>
				clear
			</Button>
		</div>
	);
};

export default React.memo(MediaItem);
