import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import FocusFeedDefinitionSocial from './FocusFeedDefinitionSocial';
import { ProfileExpressionsType } from 'class/Feed';

import { operators } from 'store/focus/feed/definition/social';
import { DispatchProps, StateProps } from './types';

const mapStateToProps = (state: State): StateProps => ({
	brand_sync: state.focus.feed.brand_sync,
	definition: state.focus.feed.social.definition,
	loadingProfiles: state.focus.feed.social.loadingProfiles,
	errorProfiles: state.focus.feed.social.errorProfiles
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetDefinition: definition => dispatch(operators.setSocialDefinition({ definition })),

	onSetExpression: (expressionKey, index, values) => dispatch(operators.setSocialExpression({ expressionKey, index, values })),
	onToggleInstagramAccount: account => dispatch(operators.toggleDefinitionInstagramAccount({ account })),

	onSetThreshold: (channel, threshold) => dispatch(operators.setThreshold({ channel, threshold })),

	onSearchProfile: (url: string, type: ProfileExpressionsType, index: number) => dispatch(operators.searchProfile({ url, type, index })),
	onSearchMultipleProfiles: (urls: string[], type: ProfileExpressionsType) => dispatch(operators.searchMultipleProfiles({ urls, type })),
	onSetProfileExpression: (expressionKey, index, values) => dispatch(operators.setProfileExpression({ expressionKey, index, values })),
	onClearErrorProfiles: () => dispatch(operators.clearErrorProfiles())
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedDefinitionSocial);
