import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import GA from 'lib/googleAnalytics';
import { operators as notificationsOperators } from 'store/app/notifications';

export function* setWrongPath(info: { message?: string, redirectTo?: string }) {
	GA.trackPage('/wrongpath');
	if (info.message) yield put(notificationsOperators.add({ notification: { text: info.message, level: "warning" } }));
	else yield put(notificationsOperators.add({ notification: { t: "error.page_not_found", level: "warning" } }));
	yield put(push(info.redirectTo || "/"));
}
