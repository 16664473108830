import React from 'react';
import { withT } from 'lib/i18n';
import Mekong from 'lib/ajax/Mekong';
import { Link } from 'react-router-dom';
import { ComponentProps } from './types';
import UserSettings from './Settings/User';
import { isEmpty } from 'lib/imports/lodash';
import Header from 'components/common/Header';
import TenantSettings from './Settings/Tenant';
import { DialogContainer, ListItem, MenuButton } from 'lib/imports/react-md';

import './Toolbar.scss';

const PRINT_TIMING_URL = "https://lookerstudio.google.com/u/0/reporting/45386da0-ca70-4aff-a634-fc925e431d1e/page/vzqDD";

class Toolbar extends React.PureComponent<ComponentProps> {

	private dialogChildren: { [index: string]: JSX.Element } = {
		tenant: <TenantSettings onClose={() => this.onHideDialog()} />,
		user: <UserSettings onClose={() => this.onHideDialog()} />
	};

	public state = {
		dialogVisible: false,
		dialogType: 'tenant'
	};

	private onShowDialog = (dialogType: 'tenant' | 'user') => {
		this.setState({ dialogVisible: true, dialogType });
	};

	private onHideDialog = () => {
		this.setState({ dialogVisible: false });
	};

	public render() {
		const { t, tenant, user, tenantFacebookUsers } = this.props;

		let appAlert;

		// If a tenant doesnt has any linked acc and it doesn't has the ff to skip it show to internal users the banner
		if (tenant.needsFacebookLogin() &&
			user.hasPermission('facebook_visible') &&
			tenantFacebookUsers !== null &&
			isEmpty(tenantFacebookUsers)
		) {
			appAlert = (
				<div id="appAlert" className="md-cell">
					<span>{t('message.tenant_without_facebook_login')}</span>
				</div>
			);
		}

		// build tenant menu or show just name if no permissions
		let tenantMenu = <span>{tenant.name}</span>;

		let tenantMenuOptions = [];

		if (user.hasPermission('tenant_settings.edit')) {
			tenantMenuOptions.push(<ListItem key="settings" id="appTolbarMenuTenantSettings" primaryText={t('tenant.settings.header')} onClick={() => this.onShowDialog('tenant')} />);
		}

		if (user.hasPermission('document.tag')) {
			tenantMenuOptions.push(<ListItem key="topics"
				id="appTolbarMenuTenantTopics"
				primaryText={t('tenant.settings.tags')}
				component={Link}
				to="/topic"
			/>);
		}

		if (!isEmpty(tenantMenuOptions)) {
			tenantMenu = (
				<MenuButton className="btn--no-background btn--green-ink" flat id="tenantMenuButton" position="below" menuItems={tenantMenuOptions}>
					<span>{tenant.name}</span>
				</MenuButton>
			);
		}

		// build user menu

		const userMenuItems = [
			<ListItem key="settings" id="appTolbarMenuUserSettings" primaryText={t('account.displaySettings')} onClick={() => this.onShowDialog('user')} />,
			<ListItem key="terms" primaryText={t('account.privacyPolicy')} component={Link} to="/public/terms" />,
			<ListItem id="printTimingLink" key="print_timing" primaryText={t('account-print-timing')} component="a" target="_blank" href={PRINT_TIMING_URL} />
		];
		if (user.hasPermission('admin_access')) userMenuItems.push(
			<ListItem key="discover_admin" primaryText={t('account.discover_admin')} component="a" href={process.env.REACT_APP_ADMIN_URL} />
		);

		if (user.role !== "user") userMenuItems.push(
			<ListItem key="vies_audit_menu_option" primaryText={t('account-view-audit')} component={Link} to="/audit" />
		);

		userMenuItems.push(
			<ListItem key="logout" primaryText={t('account.logout')} component="a" href={Mekong.getUrl('/logout')} />
		);

		const userMenu = (
			<MenuButton className="btn--no-background btn--green-ink" flat id="userMenuButton" position="below" menuItems={userMenuItems}>
				<span>{user.name}</span>
			</MenuButton>
		);

		const toolbarButtons = <div id="toolbarButtons" className="md-grid">
			{userMenu}
			{tenantMenu}
			{user.usePortal ?
				<div className="app-switcher-wrapper">
					{/*
					 // @ts-ignore */}
					<app-switcher value={tenant.id} apiUrl={process.env.REACT_APP_ECO_CONNECT_URL} />
				</div>
				: null
			}
		</div>;

		return (
			<>
				<Header alert={appAlert}>
					{toolbarButtons}
				</Header>
				<DialogContainer
					id="userSettingsDialog"
					visible={this.state.dialogVisible}
					onHide={this.onHideDialog}
					fullPage
					focusOnMount={false}
					aria-labelledby="settings-dialog"
					children={this.dialogChildren[this.state.dialogType]}
				/>
			</>
		);
	}

}

export default withT(Toolbar);
