import React from 'react';

import { withT } from 'lib/i18n';

import DefinitionLayout from '../Layout';
import { ComponentProps } from './types';

import './FocusFeedDefinitionSocial.scss';

const FocusFeedDefinitionSocial = (props: ComponentProps) => {
	const { t,
		definition, loadingProfiles, errorProfiles,
		onSetDefinition, onSetExpression, onToggleInstagramAccount,
		onSetThreshold,
		onSearchProfile, onSearchMultipleProfiles, onSetProfileExpression, onClearErrorProfiles
	} = props;

	return (
		<div id="focusFeedDefinition">
			<DefinitionLayout
				type="social"
				showFeedLinkedBrands={true}
				socialContentQueries={{
					definition,
					activeScope: ['tags', 'mentions', 'title', 'content', 'instagram.users_in_photo', 'media_mentions', 'sponsorized_mentions', 'audio_to_text'],
					scopeMenuItems: [
						{ value: 'tags,mentions,title,content,instagram.users_in_photo,media_mentions,sponsorized_mentions,audio_to_text', label: t('feed.form.scope.option.anywhere') },
						{ value: 'tags', label: t('feed.form.scope.option.tag') },
						{ value: 'mentions,media_mentions,sponsorized_mentions,instagram.users_in_photo', label: t('feed.form.scope.option.mention') },
						{ value: 'media_mentions,instagram.users_in_photo', label: t('feed.form.scope.option.tap_tags') },
						{ value: 'audio_to_text', label: t('feed.form.scope.option.speech') },
						{ value: 'sponsorized_mentions', label: t('feed.form.scope.option.sponsored_by') }
					],
					onSetDefinition,
					onToggleInstagramAccount,
					expressionFunctions: { onSetExpression }
				}}
				stories={{}}
				followers={{
					definition,
					onSetThreshold
				}}
				profiles={{
					definition,
					loadingProfiles,
					errorProfiles,
					onSetDefinition,
					onSearchMultipleProfiles,
					onClearErrorProfiles,
					expressionFunctions: { onSearchProfile, onSetProfileExpression }
				}}
			></DefinitionLayout>
		</div>
	);
};

export default withT(React.memo(FocusFeedDefinitionSocial));
