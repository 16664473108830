import { connect } from "react-redux";
import { State } from "store/types";
import { StateProps, DispatchProps } from "./types";
import { Dispatch } from 'redux';
import AuditLayout from "./AuditLayout";

const mapStateToProps = (state: State): StateProps => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AuditLayout);
