import React from 'react';
import XRegExp from 'xregexp';
import lucene from 'lucene';
import { CSSTransition } from 'react-transition-group';
import { Button, SelectField, SelectionControl, TextField } from 'lib/imports/react-md';

import { isEmpty } from 'lib/imports/lodash';
import { withT } from 'lib/i18n';
import { checkLuceneQueryValue } from 'lib/luceneValidator';
import { Scope } from 'class/Feed';
import { ComponentProps } from './types';

import './FocusFeedDefinitionExpression.scss';

const _queryHasField = (parsedQuery: any) => {
	if (parsedQuery.field) return parsedQuery.field !== '<implicit>';
	if (parsedQuery.left && _queryHasField(parsedQuery.left)) return true;
	if (parsedQuery.right && _queryHasField(parsedQuery.right)) return true;
	return false;
};

const _isSensitiveQuery = (query: string) => query.match('sen:');

const isValidQuery = (query: string) => {
	if (query.trim() === "") return true;
	if (!checkLuceneQueryValue(query)) return false;
	else if (!query.match(XRegExp('^$|[\\p{L}\\p{N}"\\p{Z}]{2,}'))) return false;
	else {
		try {
			// Check lucene syntax
			const results = lucene.parse(query);
			if (_queryHasField(results) && !_isSensitiveQuery(query)) return false;
			else return true;
		} catch (err) {
			return false;
		}
	}
};

const FocusFeedDefinitionExpression = (props: ComponentProps) => {
	const { expression, expressionKey, index, scopeMenuItems } = props;
	const { t, onSetExpression, onRemoveExpression } = props;
	const { enabled, scope, error, q: query, proximity } = expression;
	const isMainExpression = expressionKey === "main";

	const [queryValue, setQueryValue] = React.useState(query);
	const [showProximity, setShowProximity] = React.useState(!!proximity);
	const [proximityValue, setProximityValue] = React.useState(proximity);

	const inputRef: React.RefObject<any> = React.useRef();
	React.useEffect(() => {
		if (isEmpty(query)) inputRef.current.focus();
	}, []);// eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		setQueryValue(query);
	}, [query]);

	React.useEffect(() => {
		setProximityValue(proximity);
		setShowProximity(!!proximity);
	}, [proximity]);

	const onChangeScope = React.useCallback((selectedScope: React.ReactText) => {
		const scopes = selectedScope.toString().split(',');
		onSetExpression(expressionKey, index, { scope: scopes as Scope[] });
	}, [expressionKey, index, onSetExpression]);

	const onBlurHandler = React.useCallback((event: any) => {
		const query = event.target.value;
		onSetExpression(expressionKey, index, { q: query, error: isValidQuery(query) ? undefined : true });
	}, [expressionKey, index, onSetExpression]);

	const enableCheckbox = React.useMemo(() => (
		<SelectionControl
			id={`focusFeedDefinitionEnabled-${expressionKey}-${index}`}
			name={`focusFeedDefinitionExpressionEnabled-${expressionKey}-${index}`}
			aria-label={`focusFeedDefinitionExpressionEnabled-${expressionKey}-${index}`}
			className={`definition-expression-enabled ${enabled ? "checkbox-green" : ''} `}
			type="checkbox"
			checked={enabled}
			onChange={() => onSetExpression(expressionKey, index, { enabled: !enabled })}
		/>
	), [enabled, expressionKey, index, onSetExpression]);

	const scopeSelector = React.useMemo(() => (
		<SelectField
			id={`focusFeedDefinitionExpressionScope-${expressionKey}-${index}`}
			className="definition-expression-scope"
			menuItems={scopeMenuItems}
			onChange={onChangeScope}
			value={scope.join(',')}
			disabled={!enabled}
		/>
	), [enabled, expressionKey, index, onChangeScope, scope, scopeMenuItems]);

	const queryInput = React.useMemo(() => (
		<TextField
			id={`focusFeedExpressionQuery-${expressionKey}-${index}`}
			className={`definition-expressions-query ${error ? 'wrong-query' : ''}`}
			fullWidth={false}
			value={queryValue}
			error={error}
			onChange={value => setQueryValue(value.toString())}
			onBlur={onBlurHandler}
			disabled={!enabled}
			ref={inputRef}
		/>
	), [enabled, error, expressionKey, index, onBlurHandler, queryValue]);

	const proximityButton = React.useMemo(() => (isMainExpression ? null : (
		<Button
			id={`focusFeedDefinitionProximity-${expressionKey}-${index}`}
			className={`definition-expression-proximity-button ${showProximity ? 'active-button' : ''}`}
			icon
			onClick={() => setShowProximity(!showProximity)}
		>
			settings_ethernet
		</Button>
	)), [isMainExpression, expressionKey, index, showProximity]);

	const proximityInput = React.useMemo(() => (!isMainExpression ? (
		<CSSTransition
			in={showProximity}
			timeout={300}
			mountOnEnter={true}
			unmountOnExit={true}
			classNames="definition-expression-proximity-animation"
		>
			<div className={`definition-expression-proximity-input-container`}>
				<span className="proximity-description">{t('feed.form.proximity_max_words.left_part')}</span>
				<TextField
					id={`focusFeedDefinitionExpressionProximityInput-${expressionKey}-${index}`}
					className="proximity-input"
					name={`focusFeedDefinitionExpressionProximityInput-${expressionKey}-${index}`}
					step={1}
					fullWidth={false}
					value={proximityValue || ''}
					onChange={value => setProximityValue(value.toString().substr(0, 2))}
					onBlur={() => onSetExpression(expressionKey, index, { proximity: proximityValue })}
				/>
				<span className="proximity-description">{t('feed.form.proximity_max_words.right_part')}</span>
			</div>
		</CSSTransition>
	) : null), [isMainExpression, showProximity, t, expressionKey, index, proximityValue, onSetExpression]);

	const removeExpressionButton = React.useMemo(() => (!isMainExpression && onRemoveExpression ?
		(
			<Button
				id={`focusFeedExpressionRemove-${expressionKey}-${index}`}
				className='definition-expression-remove-button'
				icon
				onClick={() => onRemoveExpression(expressionKey as Exclude<typeof expressionKey, 'main'>, index)}
			>
				delete
			</Button>
		) : null
	), [expressionKey, index, isMainExpression, onRemoveExpression]);

	let className = "content-expression";
	if (isMainExpression) className += " main-expression";
	if (showProximity) className += " proximity-shown";
	if (!enabled) className += " disabled";

	return (
		<div className={className}>
			{enableCheckbox}
			{isMainExpression ?
				<span className="definition-expression-label">{t('feed.form.label.main_expression_social_media_react')}</span> :
				<span className="definition-expression-label">{t('feed.form.or')}</span>
			}
			{scopeSelector}
			{queryInput}
			{proximityButton}
			{removeExpressionButton}
			{proximityInput}
		</div>
	);
};

export default React.memo(withT(FocusFeedDefinitionExpression));
