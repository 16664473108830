import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';

import { DispatchProps, StateProps } from './types';
import FocusFeedDefinitionLayoutMedia from './FocusFeedDefinitionLayoutMedia';
import { operators as notificationOperators } from 'store/app/notifications';

const mapStateToProps = (state: State): StateProps => ({
	brand_sync: state.focus.feed.brand_sync
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onAddNotification: notification => dispatch(notificationOperators.add({ notification }))
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedDefinitionLayoutMedia);
