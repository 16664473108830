import axios, { AxiosRequestConfig } from 'axios';
import map from 'lodash/map';
import ApiError from './ApiError';

import { Session } from 'types/session';
import { get } from 'lib/imports/lodash';
import Api from './Api';

export const BASE_URL = process.env.REACT_APP_MEKONG_URL;

type MEKONG_METHODS = 'get' | 'put' | 'post' | 'patch' | 'delete';

const ajax = axios.create({
	baseURL: BASE_URL,
	withCredentials: true,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json'
	}
});

export default class Mekong {

	static async getSession(): Promise<Session> {
		return Mekong.get('/session', {});
	}

	static async getUpdatedSession(): Promise<Session> {
		return Mekong.get('/token', {}, false);
	}

	static async get(url: string, config?: AxiosRequestConfig, sessionCheck = true) {
		return Mekong.request('get', url, config, sessionCheck);
	}

	static async post(url: string, config?: AxiosRequestConfig) {
		return Mekong.request('post', url, config);
	}

	static async patch(url: string, config?: AxiosRequestConfig) {
		return Mekong.request('patch', url, config);
	}

	static async put(url: string, config?: AxiosRequestConfig) {
		return Mekong.request('put', url, config);
	}

	static async delete(url: string, config?: AxiosRequestConfig) {
		return Mekong.request('delete', url, config);
	}

	static getUrl(path: string, query?: object) {
		return `${BASE_URL}${path}${Mekong.getQueryParams(query)}`;
	}

	private static async request(method: MEKONG_METHODS, url: string, config: AxiosRequestConfig = {}, sessionCheck: boolean = true, retries: number = 0): Promise<any> {
		if (sessionCheck && Api.__isTokenExpired()) await Api.__refreshSession();

		const request: AxiosRequestConfig = { headers: {}, ...config, method, url };
		request.headers.Authorization = 'Bearer ' + Api.__getToken();
		try {
			const response = await ajax.request(request);
			return response.data;
		} catch (err) {
			if (axios.isCancel(err)) throw new ApiError({ code: 'AXIOS_CANCELLED', message: 'request cancelled' });
			const error = get(err, "response.data.error");
			if (!error) throw new ApiError({ code: 'SERVER_DOWN', message: 'Server is down' });
			if (!error.code) throw new ApiError({ code: 'SERVER_UNKNOWN_ERROR', message: 'Server unknown error' });
			if (['OAUTH2_WRONG_AUTHORIZATION', 'AUTHENTICATION_ERROR'].includes(error.code)) return await Mekong.redirectLogin();
			if (error.code === 'OAUTH2_TOKEN_NOT_VALID') {
				if (retries < 2) {
					await Api.__refreshSession();
					return this.request(method, url, config, sessionCheck, ++retries);
				}
				throw new ApiError({ code: 'API_AUTHENTICATION_FAILED', message: 'Could not authenticate' });
			}
			throw error;
		}
	}

	private static async redirectLogin() {
		window.location.href = Mekong.getUrl('/login');
		await Mekong.timeout(); // wait for redirection
	}


	private static getQueryParams(query?: object) {
		return query ? '?' + map(query, (value, name) => `${name}=${value}`).join('&') : '';
	}

	private static async timeout(ms = 60000) { // defaults to 60 seconds
		return new Promise(resolve => setTimeout(resolve, ms));
	}
};
