import React from 'react';

import { Link } from 'react-router-dom';
import { withT } from 'lib/i18n';
import GA from 'lib/googleAnalytics';
import { map } from 'lib/imports/lodash';
import { Chip } from 'react-md';
import { Button, FontIcon, DropdownMenu, ListItem, CircularProgress } from 'lib/imports/react-md';

import { FeedType } from 'class/Feed';
import { User } from 'class/User';
import { Tenant } from 'class/Tenant';
import Dialog from 'components/common/dialog';
import { DialogAction } from './Dialog/types';
import FocusDashboardDialog from './Dialog';
import { ComponentProps } from './types';
import FocusDashboardLogo from './Logo/';

import './FocusDashboard.scss';

/* eslint-disable react-hooks/exhaustive-deps */
const FocusDashboard = (props: ComponentProps) => {
	const { t, focus, feeds, urlFocusId, loadingUpdate, loadingFeeds } = props;
	const { onFetchFocus, getBrandName } = props;
	const { user: userObject, tenant: tenantObject } = props;

	const user = React.useMemo(() => new User(userObject), [userObject]);
	const tenant = React.useMemo(() => new Tenant(tenantObject), [tenantObject]);

	const [dialogAction, setDialogAction] = React.useState<DialogAction | null>(null);
	const [limitation, setLimitation] = React.useState<"printOnly" | "tier" | null>(null);

	const inconsistentId: Boolean = React.useMemo(() => !focus || focus.id !== urlFocusId, [focus, urlFocusId]);

	React.useEffect(() => {
		GA.trackPage('/focus/:focus');
		// TODO: switch the following line when migrating to use entities
		// if (inconsistentId) onFetchFocus(urlFocusId);
		onFetchFocus(urlFocusId);
	}, []);

	const focusHeaderActions = React.useMemo(() => {
		const actionsEnabled = focus && !loadingUpdate && !inconsistentId;
		const renameAction = (actionsEnabled && user.hasPermission('focus.rename')) ?
			<span className="focus-action focus-action-rename" onClick={() => setDialogAction({ type: 'renameFocus' })}> {t('focus_page.toolbar.rename')}</span>
			:
			<span className="focus-action focus-action-rename disabled"> {t('focus_page.toolbar.rename')}</span>;

		const removeAction = (actionsEnabled && user.hasPermission('focus.delete')) ?
			<span className="focus-action focus-action-remove" onClick={() => setDialogAction({ type: 'removeFocus' })}> {t('focus_page.toolbar.remove')}</span>
			:
			<span className="focus-action focus-action-remove disabled"> {t('focus_page.toolbar.remove')}</span>;
		return <>{renameAction} {removeAction}</>;
	}, [focus, inconsistentId, loadingUpdate]);

	const header = React.useMemo(() => (
		<div id="focusDashboardHeader">
			<div className="focus-dashboard-title">
				<Link className="back-link" to="/focus">
					<Button flat key="back-button" className="back-button">
						<FontIcon className="back-icon">chevron_left</FontIcon>
						<span className="back-text">{t(`focus_page.toolbar.all_focus`)}</span>
					</Button>
				</Link>
				<div className="focus-dashboard-title-text">{t('focus_page.toolbar.focus_dashboard')}</div>
			</div>

			<div className="focus-dashboard-actions">
				{focusHeaderActions}
			</div>

		</div>
	), [focusHeaderActions]);

	const feedRows = React.useMemo(() => {
		if (!focus || !feeds) return null;
		return map(feeds, feed => {
			const actionItems = [];

			let editAllowed = true;
			if ((feed.type === 'print' || feed.type === 'print_dmr') && !user.hasPermission('feed.print.manage')) editAllowed = false;
			else if (!user.hasPermission('feed.definition.edit')) editAllowed = false;

			if (editAllowed) actionItems.push(
				<ListItem key="edit" className="option-menu-edit"
					primaryText={t('focus_page.table.menu.option.edit_feed')}
					component={Link} to={`/focus/${focus.id}/feed/${feed.id}`}
					onClick={ev => {
						GA.trackEvent({ category: "Focus Dashboard", action: "Feed actions", label: "edit" });
					}}
				/>
			);
			actionItems.push(
				<ListItem key="view" className="option-menu-view"
					primaryText={t('focus_page.table.menu.option.view_feed_articles')}
					component={Link} to={`/article?feedFilters=${feed.id}`}
					onClick={() => GA.trackEvent({ category: "Focus Dashboard", action: "Feed actions", label: "view mentions" })}
				/>
			);
			if (user.hasPermission('feed.delete')) actionItems.push(
				<ListItem key="remove" className="option-menu-remove"
					primaryText={t('focus_page.table.menu.option.remove_feed')}
					onClick={() => setDialogAction({ type: 'removeFeed', feed })}
				/>
			);

			return (
				<tr key={`table-row-${feed.id}`} className={`feed-row ${feed.type}-feed ${feed.enabled ? '' : 'feed-disabled'}`}>
					<td className="options-field">
						<DropdownMenu id={`optionsMenuFeed-${feed.id}`} key={`options-${feed.id}`}
							position={DropdownMenu.Positions.TOP_LEFT}
							menuItems={actionItems}>
							<Button icon key={`options-button-${feed.id}`} className="btn--no-background btn--no-ink">more_vert</Button>
						</DropdownMenu>
					</td>
					<td>
						{
							(editAllowed) ?
								<Link className="table-feed-name" to={`/focus/${focus.id}/feed/${feed.id}`} title={t('focus_page.table.menu.option.edit_feed')}>
									<FontIcon>check</FontIcon><span>{feed.name}</span>
								</Link>
								:
								<span className="table-feed-name nolink">
									<FontIcon>check</FontIcon><span>{feed.name}</span>
								</span>
						}
					</td>
					<td>{t(`focus_page.table.feed_type.${feed.type}`)}</td>
					<td>{feed.brand_id ? getBrandName(feed.brand_id) || '' : null}</td>
					<td>
						{user.role !== 'user' && feed.brand_sync && (
							<FontIcon className="table-feed-name">check_circle</FontIcon>
						)}
					</td>
					<td>
						{feed.disambiguation_model_id && (
							<Chip className="feed-disambiguation-chip"
								label={t('focus_page.table.feed_disambiguation')}
							/>
						)}
					</td>
				</tr>
			);
		});
	}, [focus, feeds]);

	const onCreateFeed = React.useCallback((ev: React.MouseEvent, feedType: FeedType) => {
		if (!['print', 'print_dmr'].includes(feedType) && tenant.hasPrintOnly()) {
			setLimitation("printOnly");
			return ev.preventDefault();
		}
		if (feedType === 'socialmedia' && !tenant.hasSocialMediaTier()) {
			setLimitation("tier");
			return ev.preventDefault();
		}
		GA.trackEvent({ category: 'Focus Dashboard', action: 'Add feed', label: feedType });
	}, [focus]);

	const onViewMentions = React.useCallback(() => {
		GA.trackEvent({ category: 'Focus Dashboard', action: 'See all mentions' });
	}, []);

	const content = React.useMemo(() => {
		if (!focus || !feedRows || inconsistentId) return <CircularProgress id="focusLoading" scale={1.25} />;

		const links = [];
		if (user.hasPermission('feed.create')) {
			links.push(
				<Link key="create-online-feed"
					to={`/focus/${focus.id}/feed/create/online`}
					onClick={ev => onCreateFeed(ev, 'online')}
				>{t('focus_page.button.new_online_feed')}</Link>
			);
			links.push(
				<Link key="create-social-feed"
					to={`/focus/${focus.id}/feed/create/socialmedia`}
					onClick={ev => onCreateFeed(ev, 'socialmedia')}
				>{t('focus_page.button.new_social_feed')}</Link>
			);
			if (user.hasPermission('feed.print.manage')) {
				links.push(
					<Link key="create-print-dmr-feed"
						to={`/focus/${focus.id}/feed/create/print_dmr`}
						onClick={ev => onCreateFeed(ev, 'print_dmr')}
					>{t('focus_page.button.new_print_dmr_feed')}</Link>
				);
			}
		}
		links.push(
			<Link key="view-focus-articles"
				to={`/article?focusFilters=${focus.id}`}
				onClick={onViewMentions}
			>{t('focus_page.link.all_articles')}</Link>
		);

		let feedList = null;
		if (loadingFeeds) feedList = <CircularProgress id="loadingFeeds" scale={1.25} />;
		else if (feedRows) {
			if (feedRows.length === 0) feedList = <div className="no-feed-list">{t('focus_page.table.text.no_feeds')}</div>;
			else feedList = <>
				<table>
					<thead>
						<tr>
							<th className="options-header">{t('focus_page.table.header.options')}</th>
							<th className="feed-name-header">{t('focus_page.table.header.name')}</th>
							<th>{t('focus_page.table.header.type')}</th>
							<th className="feed-brand-header">{t('focus_page.table.header.brand')}</th>
							{user.role !== 'user' ? <th className="feed-brand-header">{t('focus_page.table.header.brand_sync')}</th> : <th></th>}
							<th></th>
						</tr>
					</thead>
					<tbody>
						{feedRows}
					</tbody>
				</table>
			</>;
		}

		return <>
			<div className="focus-title">
				<div className="focus-logo"><FocusDashboardLogo /></div>
				<div className="focus-name">{loadingUpdate ? <CircularProgress id="loadingUpdate" scale={1.25} /> : focus.name}</div>
			</div>

			<div className="feed-list">

				<div className="feed-list-info">
					<div className="feed-list-info-title">
						<span>{`${t('focus_page.section.feeds')} ( ${feedRows.length} )`}</span>
					</div>
					<div className="feed-list-info-create">	{links} </div>
				</div>

				{feedList}

				<FocusDashboardDialog action={dialogAction} onClose={() => setDialogAction(null)}></FocusDashboardDialog>

				{limitation ? <Dialog
					id="focusDashboardLimitationDialog"
					icon={<FontIcon>warning</FontIcon>}
					title={t(`${limitation}.limitations.title`)}
					content={t(`${limitation}.limitations.description`)}
					onAccept={() => setLimitation(null)}
					onAcceptText={t(`${limitation}.limitations.ok`)}
				/> : null}

			</div>
		</>;
	}, [focus, feedRows, inconsistentId, loadingUpdate, loadingFeeds, dialogAction, limitation]);

	return (
		<div id="focusDashboard">
			{header}
			<div id="focusDashboardBody">
				{content}
			</div>
		</div>
	);
};

export default withT(React.memo(FocusDashboard));
