import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';

import reportSelectors from 'store/ui/report/selectors';
import selectors from 'store/ui/report/status/selectors';
import FocusSelectors from 'store/entities/Focus/selectors';
import { operators } from 'store/ui/report/status/actions';

import { DispatchProps, StateProps } from './types';
import ReportStatus from './ReportStatus';

const mapStateToProps = (state: State): StateProps => ({
	focusId: reportSelectors.getFocusId(state),
	focus: reportSelectors.getFocusId(state)? FocusSelectors.get(state, reportSelectors.getFocusId(state)!): null,
	jobs: selectors.getJobs(state),
	jobsLoaded: selectors.areJobsLoaded(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onFetchFocusJobs: () => dispatch(operators.fetchFocusJobs()),
	onClearJobs: () => dispatch(operators.clearJobs())
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportStatus);
