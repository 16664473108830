import { createSelector } from 'reselect';
import { ormEntities, sessionSelector, createOrmSelector, OrmSelector } from "store/entities/selectors";

import { FacebookUserObject } from "store/entities/FacebookUser/types";
import { TenantObject } from "./types";

export const get: OrmSelector<TenantObject> = createOrmSelector(ormEntities.Tenant);
export const getFacebookUsers: OrmSelector<FacebookUserObject[]> = createOrmSelector(ormEntities.Tenant.facebookUsers);
export const getInstance = createSelector(sessionSelector, (_: any, id: string) => id,
	({ Tenant }, id: string) => Tenant.withId(id)
);
export const getFacebookUserInstances = createSelector(sessionSelector, (_: any, id: string) => id,
	({ Tenant }, id: string) => {
		const tenantInstance = Tenant.withId(id);
		if (!tenantInstance) return null;
		return tenantInstance.facebookUsers.toModelArray();
	}
);

export default {
	get,
	getFacebookUsers,
	getInstance,
	getFacebookUserInstances
};
