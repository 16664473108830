import React, { useCallback, useMemo, useState } from 'react';
import { withT } from 'lib/i18n';

import { ComponentProps } from './types';
import { size } from 'lib/imports/lodash';
import LinkedBrand from 'components/common/LinkedBrand';
import { LinkedBrand as LinkedBrandType } from 'class/Feed';
import FocusFeedSelect from 'components/common/Focus/Feed/Select';

import './NewsletterEditFormFeeds.scss';

const NewsletterEditFormFeeds: React.FC<ComponentProps> = props => {
	const { user, selectedFeedIds, error, linkedBrandList, linkedBrandId } = props;
	const { onChange, onChangeLinkedBrand, onAddNotification, t } = props;

	const [linkedBrandsResult, setLinkedBrandsResult] = useState<LinkedBrandType[]>(linkedBrandList);

	const onFocusChange = useCallback(focusIds => {
		if (focusIds.length === 2) {
			onAddNotification({ t: 'newsletter.form.warning_switching_single_to_multiple', level: 'warning' });
		}
	}, [onAddNotification]);

	const onFetchLinkedBrand = useCallback((query: string) => {
		const lowerQuery = query.toLowerCase();
		setLinkedBrandsResult(linkedBrandList.filter(brand => brand.name.toLowerCase().includes(lowerQuery)));
	}, [linkedBrandList, setLinkedBrandsResult]);

	const onResetLinkedBrand = useCallback(() => {
		setLinkedBrandsResult(linkedBrandList);
	}, [linkedBrandList, setLinkedBrandsResult]);

	const onAddLinkedBrand = useCallback((brand: LinkedBrandType) => {
		onChangeLinkedBrand(brand);
	}, [onChangeLinkedBrand]);

	const content = useMemo(() => (
		<FocusFeedSelect
			multiple={user.hasPermission('newsletter.multiple_focus')}
			selectedFeedIds={selectedFeedIds}
			linkedBrandId={linkedBrandId}
			error={error}
			onFeedChange={onChange}
			onFocusChange={onFocusChange}
		/>
	), [user, error, selectedFeedIds, linkedBrandId, onFocusChange, onChange]);

	const linkedBrand = useMemo(() => {
		return <div id="NewsletterLinkedBrand" className="form-section vertical-align-top">
			<div className="form-section-title">
				{t('feed.form.label.brands')}
			</div>

			<div className="form-section-content">
				<span className="definition-text">{t('feed.form.label.select_brand').toUpperCase()}</span>
				<LinkedBrand
					linkedBrandList={linkedBrandList}
					linkedBrandId={linkedBrandId}
					linkedBrandsResult={linkedBrandsResult}
					onFetchLinkedBrand={onFetchLinkedBrand}
					onResetLinkedBrand={onResetLinkedBrand}
					onAddLinkedBrand={onAddLinkedBrand} />
			</div>
		</div>;
	}, [
		linkedBrandId, linkedBrandList, linkedBrandsResult,
		onFetchLinkedBrand, onResetLinkedBrand, onAddLinkedBrand, t
	]);

	return (
		<>
			{linkedBrand}
			<div id="newsletterEditSectionFeeds" className="form-section">
				<div className="form-section-title">
					{t('newsletter.form.label.feeds')}
					<span className="section-title-info">{`${size(selectedFeedIds)} ${t('newsletter.feeds')}`}</span>
				</div>
				<div className="form-section-content">
					{content}
				</div>
			</div>
		</>
	);
};

export default React.memo(withT(NewsletterEditFormFeeds));
