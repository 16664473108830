import React from "react";
import { Button, Collapse, SelectionControl, TextField } from 'lib/imports/react-md';

import { TProps, withT } from "lib/i18n";
import { NewsletterObject } from "store/entities/Newsletter";
import { cleanEmail } from "lib/email";

type NewsletterType = NewsletterObject["type"];
type NewsletterDeliveryMode = NewsletterObject["send_configuration_json"]["delivery_mode"];
type NewsletterSendTo = NewsletterObject["send_to"];

type OwnProps = {
	type: NewsletterType,
	deliveryMode: NewsletterDeliveryMode,
	sendTo: NewsletterSendTo,
	editorEmailError?: string
	onDeliveryModeUpdated: (deliveryMode: NewsletterDeliveryMode) => void
	onSendToUpdated: (sendTo: NewsletterSendTo) => void
};

type ComponentProps = OwnProps & TProps;

const NewsletterEditFormDeliveryMode = (props: ComponentProps) => {
	const { t, type, deliveryMode, sendTo, editorEmailError } = props;
	const { onDeliveryModeUpdated, onSendToUpdated } = props;

	const deliveryModeInput = React.useMemo(() => (
		<>
			<SelectionControl
				id={`newsletterEditSectionDeliveryModeDirect`}
				name="newsletterEditSectionDeliveryMode"
				type="radio"
				value="direct"
				label={t('newsletter.form.input.delivery_mode.direct')}
				checked={deliveryMode === "direct"}
				onChange={(value: any) => onDeliveryModeUpdated(value)}
			/>
			<SelectionControl
				id={`newsletterEditSectionDeliveryModeSupervised`}
				name="newsletterEditSectionDeliveryMode"
				type="radio"
				value="supervised"
				label={t('newsletter.form.input.delivery_mode.supervised.value')}
				checked={deliveryMode === "supervised"}
				onChange={(value: any) => onDeliveryModeUpdated(value)}
			/>
		</>
	), [t, deliveryMode, onDeliveryModeUpdated]);

	const sendToInput = React.useMemo(() => (
		<TextField
			id="newsletterEditEditorNameEmailInput"
			label={t('newsletter.form.input.editor_email.value')}
			defaultValue={sendTo}
			onBlur={(event: any) => {
				const editorEmail = cleanEmail(event.target.value);
				onSendToUpdated(editorEmail);
			}}
			required
			error={!!editorEmailError}
			errorText={editorEmailError}
		/>
	), [t, sendTo, editorEmailError, onSendToUpdated]);

	return (
		<Collapse collapsed={type !== "digest"} animate={false}>
			<div id="newsletterEditSectionDeliveryMode" className="form-section">
				<div className="form-section-title">
					{t('newsletter.form.label.delivery_mode')}
				</div>
				<div className="form-section-content">
					<div className="form-section-content-column">
						{deliveryModeInput}
						<Button
							icon
							tooltipLabel={<span dangerouslySetInnerHTML={{ __html: t('newsletter.form.input.delivery_mode.supervised.tooltip') }}></span>}
							tooltipPosition="right">help_outline</Button>
					</div>
					<div className="form-section-content-column">
						{deliveryMode === "supervised" && sendToInput}
					</div>
				</div>
			</div>
		</Collapse>
	);
};

export default withT(React.memo(NewsletterEditFormDeliveryMode));
