import React from 'react';
import { Link } from 'react-router-dom';
import find from 'lodash/find';
import replace from 'lodash/replace';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import TextField from 'react-md/lib/TextFields';
import Button from 'react-md/lib/Buttons';
import SelectionControl from 'react-md/lib/SelectionControls/SelectionControl';
import ListItem from 'react-md/lib/Lists/ListItem';
import IconSeparator from 'react-md/lib/Helpers/IconSeparator';
import { DropdownMenu } from 'react-md/lib/Menus';
import { CircularProgress } from 'react-md/lib/Progress';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import { FocusObject } from 'class/Focus';
import FocusFeedDuplicateFeedDialog from 'components/Focus/Feed/DuplicateFeedDialog';

import './FocusFeedHeader.scss';
import SimpleDialog from 'components/common/dialog/simple';

const COMPLEX_HITS_THRESHOLD = 50000;

type ComponentState = {
	editNameEnabled: boolean;
};

class FocusFeedHeader extends React.Component<ComponentProps, ComponentState> {

	private queryInputRef: React.RefObject<any> = React.createRef();

	constructor(props: ComponentProps) {
		super(props);
		this.state = { editNameEnabled: false };
	};

	private _setEditNameEnabled = (editable: boolean) => {
		this.setState({ editNameEnabled: editable });
	};

	private _onEditNameBlur = () => {
		const { onSaveFeedName, editNameInputValue, feedName } = this.props;
		if (editNameInputValue !== feedName) onSaveFeedName();
		this._setEditNameEnabled(false);
	};

	private _getEditNameContent = () => {
		const { editNameEnabled } = this.state;
		const { t, feedName, editNameInputValue, loadingSaveFeedName } = this.props;
		const { onSetEditNameInputValue } = this.props;
		if (loadingSaveFeedName) return <CircularProgress id="focusFeedHeaderSavingFeedLoading" scale={0.75} />;
		if (editNameEnabled || feedName === null)
			return <TextField
				id="focusFeedHeaderEditName"
				className="focus-feed-header-edit-name"
				label={t('name')}
				placeholder={t('name')}
				fullWidth={false}
				lineDirection="center"
				onChange={val => onSetEditNameInputValue(val.toString())}
				onBlur={() => this._onEditNameBlur()}
				onKeyDown={ev => { if (ev.keyCode === 13) this._onEditNameBlur(); }}
				value={editNameInputValue !== null ? editNameInputValue : feedName || ""}
				ref={this.queryInputRef}
			/>;
		return <div className="edit-name-handler" onClick={() => this._setEditNameEnabled(true)}><span>{feedName}</span><FontIcon>edit</FontIcon></div>;
	};

	private _getBasicInfoContent = (focus: FocusObject | undefined) => {
		const { t, feedType } = this.props;
		const { onToggleDrawer } = this.props;
		if (!focus || !feedType) return null;

		return (
			<div className="focus-basic-info">
				<Button id="focusFeedToggleDrawerBtn" icon onClick={() => onToggleDrawer()}>filter_list</Button>
				<div className="focus-name-link">
					{t('focus.value')}: <Link className="back-link" to={`/focus/${focus.id}`}>{focus.name}</Link>
				</div>
				<FontIcon>chevron_right</FontIcon>
				<span>{t(`focus.${replace(feedType, /media|_percolator/, "")}_feed`)}: </span>
				{this._getEditNameContent()}
			</div>
		);
	};

	private _handleSaveClick() {
		const { onSaveFeed, onSetShowComplexityDialog } = this.props;
		const { isComplex, approximativeHits } = this.props;

		if (isComplex || approximativeHits > COMPLEX_HITS_THRESHOLD) onSetShowComplexityDialog(true);
		else onSaveFeed();
	};

	private _getBasicOptionsContent = (focus: FocusObject | undefined) => {
		const { t, feedType, feedEnabled, feedChanged } = this.props;
		const { onToggleFeedEnabled, onSaveFeed, onDuplicateFeed } = this.props;
		if (!focus || !feedType) return null;

		return (
			<div className="focus-options">
				<SelectionControl
					id="focusFeedHeaderToggleFeedEnabled"
					type="switch"
					label={feedEnabled ? t('feed.status.active') : t('feed.status.inactive')}
					name="focusFeedHeaderToggleFeedEnabled"
					checked={feedEnabled}
					labelBefore={true}
					onChange={() => onToggleFeedEnabled()}
				/>
				<div className="menu-options">
					<div className="menu-options-save">
						<Button id="focusFeedHeaderSaveFeedBtn"
							disabled={feedType === 'print'} flat iconEl={<FontIcon>save</FontIcon>}
							onClick={() => this._handleSaveClick()}>{t('feed.form.save')}
						</Button>
					</div>
					<div className="menu-options-dropdown">
						<DropdownMenu id="focusFeedHeaderDropdownMenu" position="br"
							menuItems={[
								<ListItem
									disabled={feedType === 'print'}
									id="focusFeedHeaderDropdownMenuDuplicateFeed"
									key="duplicate_feed"
									primaryText={t('feed.form.duplicate.value')}
									onClick={() => onDuplicateFeed()} />
							]}
						>
							<Button disabled={feedType === 'print'} id="focusFeedHeaderShowDrowpdownMenuBtn" flat iconEl={<FontIcon>arrow_drop_down</FontIcon>} >
								<IconSeparator label="" children="" />
							</Button>
						</DropdownMenu>
					</div>
				</div>
				<div className="menu-options-responsive">
					<DropdownMenu id="focusFeedHeaderDropdownMenuResponsive" position="br"
						menuItems={[
							<ListItem
								id="focusFeedHeaderDropdownMenuResponsiveToggleEnableFeed"
								key="toggle_enabled_feed" primaryText={feedEnabled ? t('feed.form.disable') : t('feed.form.enable')}
								onClick={() => onToggleFeedEnabled()} />,
							<ListItem
								id="focusFeedHeaderDropdownMenuResponsiveSaveFeed"
								key="save_feed" primaryText={t('feed.form.save')}
								disabled={!feedChanged}
								onClick={() => onSaveFeed()} />
						]}
					>
						<Button id="focusFeedHeaderShowDrowpdownMenuResponsiveBtn" flat iconEl={<FontIcon>more_vert</FontIcon>} >
							<IconSeparator label="" children="" />
						</Button>
					</DropdownMenu>
				</div>
			</div>
		);
	};

	private _confirmComplexFeedSave() {
		const { onSaveFeed, onSetShowComplexityDialog, t } = this.props;

		return <SimpleDialog
			id={'confirmComplexityDialog'}
			text={t("definition.feed.form.info.complexity.title")}
			content={<>
				<p>{t("definition.feed.form.info.complexity.first_paragraph")}</p>
				<p>{t("definition.feed.form.info.complexity.second_paragraph")}</p>
				<p><b>{t("definition.feed.form.info.complexity.continue")}</b></p>
			</>}
			onAccept={onSaveFeed}
			onCancel={() => onSetShowComplexityDialog(false)}
		/>;
	};

	public componentDidUpdate() {
		if (this.state.editNameEnabled && this.queryInputRef.current) this.queryInputRef.current.focus();
	}

	public componentWillUnmount() {
		const { onSetEditNameInputValue } = this.props;
		onSetEditNameInputValue(null);
	}

	public render() {
		const { focusList, focusId, feedType, showDuplicateFeedDialog, loadingSaveFeed, showComplexityDialog } = this.props;

		const focus = focusList && focusId && feedType && !loadingSaveFeed ? find(focusList, { id: focusId }) : undefined;

		return (
			<div id="focusFeedHeader">
				{this._getBasicInfoContent(focus)}
				{this._getBasicOptionsContent(focus)}
				{showComplexityDialog ? this._confirmComplexFeedSave() : null}
				{showDuplicateFeedDialog ? <FocusFeedDuplicateFeedDialog /> : null}
			</div>
		);
	}
}

export default withT(FocusFeedHeader);
