import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { State } from 'store/types';

import { DispatchProps, StateProps } from './types';
import FocusFeedDefinitionLayoutFollowers from './FocusFeedDefinitionLayoutFollowers';

const mapStateToProps = ({ focus: { feed: {brand_sync } } }: State): StateProps => ({
	brand_sync
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedDefinitionLayoutFollowers);
