import { createSelector } from "reselect";
import { transform } from 'lib/imports/lodash';

import { State } from 'store/types';

const rootSelector = (state: State) => state.search.filters;

export const selectors = {
	getFacetFilters: createSelector((state: State, groupKey: string) => ({ facetsGroups: rootSelector(state).facetsGroups, groupKey }),
		({ facetsGroups, groupKey }) => transform(facetsGroups[groupKey], (result: Record<string, boolean>, filterFacet) => {
			result[filterFacet.key] = true;
		}, {})
	)
};
