import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import FocusFeedDefinitionOnline from './FocusFeedDefinitionOnline';
import { operators as focusFeedOperators } from 'store/focus/feed';
import { operators } from 'store/focus/feed/definition/online';
import { operators as onlineOperators } from 'store/focus/feed/definition/online';
import { MediaExpressionType } from 'class/Feed';

const mapStateToProps = ({ focus: { feed: { online: { definition, errorMedias, mediaResults } } } }: State): StateProps => ({
	definition, errorMedias, mediaItems: mediaResults
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onClearErrorMedias: () => dispatch(focusFeedOperators.clearErrorMedias()),
	onSetDefinition: definition => dispatch(operators.setDefinition({ definition })),
	onSetExpression: (expressionKey, index, values) => dispatch(onlineOperators.setExpression({ expressionKey, index, values })),
	onMassiveFetchMedia: (query: string[], expressionKey: MediaExpressionType) => dispatch(focusFeedOperators.massiveFetchMedia({ query, expressionKey })),
	onResetMedias: () => dispatch(operators.resetMedias()),
	onFetchMedias: (query: string) => dispatch(operators.fetchMedias({ query }))
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedDefinitionOnline);
