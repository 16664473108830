import ActionCreators from 'lib/store/actions';

import { Profile } from 'types/session';
import { Settings as TenantSettings, TenantUser } from 'class/Tenant';
import { Settings as UserSettings } from 'class/User';

const actions = new ActionCreators('@@app/profile');

const setProfile = actions.operator<Profile>('SET_PROFILE');
const profileReady = actions.operator('PROFILE_READY');
const [updateTenantSettings, updateTenantSettingsSuccess, updateTenantSettingsError] =
	actions.apiOperators<TenantSettings, TenantSettings>('UPDATE_TENANT_SETTINGS');
const [updateUserSettings, updateUserSettingsSuccess, updateUserSettingsError] =
	actions.apiOperators<UserSettings, UserSettings>('UPDATE_USER_SETTINGS');
const [fetchTenantUsers, fetchTenantUsersSuccess, fetchTenantUsersError] =
	actions.apiOperators<void, TenantUser[]>('FETCH_TENANT_USERS');

export const operators = {
	setProfile,
	profileReady,
	updateTenantSettings, updateTenantSettingsSuccess, updateTenantSettingsError,
	updateUserSettings, updateUserSettingsSuccess, updateUserSettingsError,
	fetchTenantUsers, fetchTenantUsersSuccess, fetchTenantUsersError
};

export type Actions = {
	SetProfile: ReturnType<typeof setProfile>,
	UpdateTenantSettings: ReturnType<typeof updateTenantSettings>,
	UpdateTenantSettingsSuccess: ReturnType<typeof updateTenantSettingsSuccess>,
	UpdateUserSettings: ReturnType<typeof updateUserSettings>,
	UpdateUserSettingsSuccess: ReturnType<typeof updateUserSettingsSuccess>,
	FetchTenantUsersSuccess: ReturnType<typeof fetchTenantUsersSuccess>,
}
