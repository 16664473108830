import React from 'react';
import moment from 'moment';
import { withT } from 'lib/i18n';
import { ComponentProps } from './types';

const TargetButton = ({
	t,
	isOpen,
	endDate,
	startDate,
	toggleOpen
}: ComponentProps) => {
	return (
		<>
			<button style={{zIndex: 50,position: 'relative'}} name="dropdown-btn" className={`dropdown-btn btn dropdown shadow-none ${isOpen ? 'is-open' : ''}`} onClick={toggleOpen} type="button">
				<label>
					<div className="d-flex align-items-center">
						<div className="d-flex flex-column align-items-start">
							<span>{t('audit_page_date_picker_title')}  :  </span>
							<span className="font-weight-semi-bold ml-1">
								{moment(startDate).format('L')} - {moment(endDate).format('L')}
							</span>
						</div>
					</div>
				</label>
			</button>
		</>
	);
};

export default withT(TargetButton);