import { Document, PreparedDocument } from "class/Document";
import format from "lib/format";
import i18n from "lib/i18n";

export class GalleryDocument extends Document {
	public prepare(): PreparedDocument {
		const counters = {
			audience: {
				audience: this.rank ? (this.rank.audience || 0) : 0
			},
			reach: {
				reach: this.rank ? (this.rank.similarweb_monthly_visitors || 0) : 0
			},
			echo: {
				shares: this.social ? (this.social.shares || 0) : 0
			}
		};
		return new PreparedDocument({
			id: this.id,
			provider: this.provider,
			providerName: 'Gallery',
			link: this.link,
			icon: "icon-gallery",
			categories_id: this.category || "",
			title: this.title,
			content: this.content,
			date: format.date.doc(this.date_from_provider || this.date),
			dateTitle: format.date.localeTitle(this.date_from_provider || this.date),
			country: this.location.country.iso,
			author_name: this.source.title,
			author_icon: `https://faviewhaiy.s3.amazonaws.com/${this.source.id[this.source.id.length - 1]}/${this.source.id}.ico`,
			author_link: this.source.url,
			page: i18n.t('result.subtitle.gallery.page', { page: this.page_number }),
			image: this.image_url,
			counters: {
				reach: {
					value: format.number.counter(counters, 'audience'),
					title: format.text.counter(counters, 'audience')
				},
				engagement: {
					value: format.number.counter(counters, 'echo'),
					title: format.text.counter(counters, 'echo')
				},
				echo: {
					value: format.number.currency(this.rank, this.date_from_provider || this.date),
					title: format.number.currency(this.rank, this.date_from_provider || this.date, 'locale', true)
				},
				branded_miv: this.branded_miv ? this.branded_miv.map(brand => ({
					id: brand.id,
					value: format.number.brandedCurrency(brand.value, this.date_from_provider || this.date),
					title: format.number.brandedCurrency(brand.value, this.date_from_provider || this.date, 'locale', true)
				})) : []
			}
		});
	}
}
