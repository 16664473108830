import React from 'react';
import { FontIcon, CircularProgress } from 'lib/imports/react-md';
import { withT } from 'lib/i18n';

import FocusFeedDefinitionSocialInstagramAccount from 'components/Focus/Feed/Definition/Layout/Sections/SocialContent/SocialExpression/InstagramAccount';

import { ComponentProps } from './types';

import './FocusFeedDefinitionSocialInstagramAccountsList.scss';

const FocusFeedDefinitionSocialInstagramAccountsList = (props: ComponentProps) => {
	const { t, instagramAccounts, expressionKey, index } = props;
	const { onToggleInstagramAccount, onOpenFacebookDialog } = props;

	const content = React.useMemo(() => {
		if (!instagramAccounts) return <CircularProgress id={`focusFeedSocialDefinitionInstagramAccountsLoading${expressionKey}${index}`} scale={0.75} />;
		const instagramAccountsContent = instagramAccounts.map(account => (
			<FocusFeedDefinitionSocialInstagramAccount
				key={account.id}
				account={account}
				removable={false}
				onSelected={() => onToggleInstagramAccount({ id: account.id, screen_name: account.screen_name, linkedExpression: { type: expressionKey, index } })}
			/>
		));
		return (
			<>
				{instagramAccountsContent}
				<div id="focusFeedDefinitionSocialInstagramAccountAddNew" className="instagram-account-add" onClick={onOpenFacebookDialog}>
					<FontIcon>add</FontIcon>
					<span>{t('feed.form.instagram.legend.button_react')}</span>
				</div>
			</>
		);
	}, [instagramAccounts, expressionKey, index, onToggleInstagramAccount, onOpenFacebookDialog, t]);

	return (
		<div id={`focusFeedSocialDefinitionInstagramAccounts${expressionKey}${index}`} className="social-definition-instagram-accounts-list">
			{content}
		</div>
	);
};

export default withT(FocusFeedDefinitionSocialInstagramAccountsList);
