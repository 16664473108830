import { Model, attr, fk } from "redux-orm";
import { isArray } from "lodash";
import { createOrmReducer, ReducerOrm } from "lib/store/reducer";
import { Schema } from "store/entities/schema";

import { Actions, operators } from "./actions";
import { TagFields, TagData, TagModel, TagInstance } from "./types";

export class Tag extends Model<typeof Tag, TagFields> {
	static get modelName() { return 'Tag' as const; }
	static get idAttribute() { return 'id' as const; }
	static get fields() {
		return {
			id: attr(),
			name: attr(),
			topic: fk('Topic', 'tags')
		};
	}

	static reducer: ReducerOrm<TagModel> = createOrmReducer<TagModel>(operators, Tag => ({
		parse: ({ payload: tagData }: Actions["Parse"]) => {
			const tagDataArray = isArray(tagData) ? tagData : [tagData];
			tagDataArray.forEach(tagData => (Tag as any).parse(tagData));
		}
	}));

	public static parse(tagData: TagData): TagInstance {
		const { TagAutomation }: Schema = (this as any).session;
		const { tag_automation, ...upsertData } = tagData;

		const tag: TagInstance = this.upsert(upsertData);
		if (tag_automation) TagAutomation.parse({ ...tag_automation, tag });

		return tag;
	}
}
