import { TFunction } from 'i18next';
import React from 'react';

export function DisplayItems({loadingDocuments, start, limit, total, t}: {loadingDocuments: boolean, start: number, limit: number, total: number, t: TFunction}) {
	if (loadingDocuments || !total) {
		return null;
	}

	return (
		<div className="form-display-items">
			<div className="form-results-display-label">{t('results.search.documents.pagination_label')}</div>
			<div className="form-results-display-content">{t('results.search.documents.pagination_info', {
				start: start + 1,
				end: total < (limit + start) ? total : start + limit,
				count: total > 5000 ? 5000 : total
			})}</div>
		</div>
	);
}
