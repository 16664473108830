import React, { useCallback, useMemo } from 'react';
import { map } from 'lodash';
import { FontIcon } from 'react-md';
import { withT } from "lib/i18n";

import { FilterFacet } from 'class/Filter';
import { Facet, FacetItem } from 'class/Facet';
import DropdownFilter from "components/common/DropdownFilter/DropdownFilter";
import { BadgeType } from 'components/common/Badge/types';
import { TopicObject } from 'store/entities/Topic';

import { ComponentProps } from './types';


const TopicTag = (props: ComponentProps) => {
	const { excluded, facetFilters, topicTags, display, isOpen } = props;
	const { t, onSetExcludeField, onSetFacetGroupFilters, onToggleOpen } = props;

	const untagged = useMemo(() => ({ key: Facet.untaggedDocumentKey, name: t(`filters.untagged_documents_facet_tag`), children: [] }), [t]);

	const facetItems = React.useMemo(() => {
		const sortedListOfRelatedTags = (topic: { id: string, tags: any; }) => {
			const listOfTags = map(topic.tags, tag => {
				let icon = undefined;
				if (tag.tag_automation) icon = <FontIcon>bolt</FontIcon>;
				return {
					key: topic.id + '_' + tag.id,
					name: tag.name,
					icon: icon
				};
			});
			return listOfTags.sort((a, b) => a.name.localeCompare(b.name));
		};

		const isReadOnly = (topic: TopicObject) => topic.type !== 'general' && topic.read_only;
		const readOnlyBadge = { label: t('filters.read_only'), type: 'info' as BadgeType };

		return map(topicTags, topic => ({
			key: topic.id,
			name: topic.name,
			badge: isReadOnly(topic) ? readOnlyBadge : undefined,
			children: sortedListOfRelatedTags(topic)
		})).sort((a, b) => a.name.localeCompare(b.name));
	}, [t, topicTags]);

	const topicTagFilters = useMemo(() => (facetFilters && facetFilters[Facet.tagsGroupKey]) || [], [facetFilters]);

	const handleChange = useCallback((items: FacetItem[]) => {
		onSetFacetGroupFilters(items as FilterFacet[]);
	}, [onSetFacetGroupFilters]);

	const handleExclude = useCallback((set: boolean) => onSetExcludeField(set), [onSetExcludeField]);

	const dropdown = useMemo(() =>
		<DropdownFilter
			id='topicTagFilter'
			key='filters-topicTag-dropdown'
			title={t('filters.topic_tags')}
			excluded={excluded}
			filters={[untagged, ...facetItems]}
			initSelectedFilters={topicTagFilters as FacetItem[]}
			defaultFilter={{ name: t('reports.all_male_sing'), key: 'all' }}
			disable={false}
			isOpen={isOpen}
			onToggleOpen={onToggleOpen}
			onChange={handleChange}
			onExclude={handleExclude}
		/>

	, [t, excluded, untagged, facetItems, topicTagFilters, isOpen, onToggleOpen, handleChange, handleExclude]);
	return display ? dropdown : null;
};

export default withT(TopicTag);
