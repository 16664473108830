import { ReducerHandlers, createReducer } from 'lib/store/reducer';
import { Facet, FacetItem } from 'class/Facet';

import { Actions, operators } from './actions';
import { State as FacetsState } from './reducers';
import moment from 'moment';

export type State = {
	readonly excludedFields: string[],
	readonly queriedMedia: FacetItem[],
	readonly begin_date: Date,
	readonly end_date: Date
};

export const INITIAL_STATE: State = {
	excludedFields: [],
	queriedMedia: Facet.defaultMediaValues,
	begin_date: moment().subtract(7, 'days').toDate(),
	end_date: moment().toDate()
};

export const reducerHandlers: ReducerHandlers<FacetsState> = {
	setExcludedFields: (state, { payload: { excludedFields } }: Actions["SetExcludedFields"]): FacetsState =>({
		...state,
		excludedFields
	}),

	setQueriedMedia: (state, { payload: { queriedMedia } }: Actions["SetQueriedMedia"]): FacetsState => ({
		...state,
		queriedMedia
	}),

	resetQueriedMedia: (state, { payload }: Actions["ResetQueriedMedia"]): FacetsState => ({
		...state,
		queriedMedia: Facet.defaultMediaValues
	}),

	setDateRange: (state, { payload: { startDate, endDate } }: Actions["SetDateRange"]): FacetsState => ({
		...state,
		begin_date: startDate,
		end_date: endDate	
	})
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
