import { combineReducers } from 'redux';

import * as draft from './draft';
import * as list from './list';
import * as edit from './edit';
import * as emailList from './emailList';

export const reducers = combineReducers({
	draft: draft.reducers,
	list: list.reducers,
	edit: edit.reducers,
	emailList: emailList.reducers
});

export interface State {
	draft: draft.State;
	list: list.State;
	edit: edit.State;
	emailList: emailList.State;
}

export const INITIAL_STATE = {
	draft: draft.INITIAL_STATE,
	list: list.INITIAL_STATE,
	edit: edit.INITIAL_STATE,
	emailList: emailList.INITIAL_STATE
};
