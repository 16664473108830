import React from "react";
import "./DocumentPlaceHolder.scss";

const DocumentPlaceHolder = () => (
	<div className="document-place-holder">
		<div className="document-place-holder-left-info">
			<div className="document-place-holder-provider-icon animated"></div>
			<div className="document-place-holder-counters animated"></div>
		</div>
		<div className="document-place-holder-content">
			<div className="document-place-holder-content-title animated"></div>
			<div className="document-place-holder-content-source animated"></div>
		</div>
	</div>
);

export default DocumentPlaceHolder;
