import React from 'react';
import TextField from 'react-md/lib/TextFields/TextField';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import Subheader from 'react-md/lib/Subheaders/Subheader';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';
import isEmpty from 'lodash/isEmpty';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import { Focus } from 'class/Focus';

import './ArticleInsertSelectFeed.scss';

class ArticleInsertSelectFeed extends React.Component<ComponentProps> {

	private __getSelectFieldFeedItems = () => {
		const { t, focusList, type } = this.props;

		const fieldItems = [];
		for (const focus of focusList || []) {
			const focusFeeds = new Focus(focus).getFeeds();
			// TODO: Handled using `.includes` to join print/print_dmr, test with `!==` when old print removed
			const filteredFocusFeeds = focusFeeds.filter(feed => feed.type.includes(type));
			if (!filteredFocusFeeds.length) continue;

			fieldItems.push(<Subheader key={`subheader-focus-${focus.id}`} primaryText={`Focus ${focus.name}`} />);
			filteredFocusFeeds.map(feed => fieldItems.push({ label: `${feed.name} ${['print', 'print_dmr'].includes(type) ? `(${t('type.' + feed.type)})` : ""}`, value: feed.id }));
		}
		return fieldItems;
	};

	public render() {
		const { t, selectedFeed, url, invalidUrlError, loading, type } = this.props;
		const { onSetSelectedFeedId, onSetUrl, onCheckUrl, onChangeStep } = this.props;

		const selectFieldFeedItems = this.__getSelectFieldFeedItems();
		const isNextButtonDisabled = !selectedFeed || !!invalidUrlError || isEmpty(url);

		return (
			<>
				<div id="articleInsertDocumentDialogSelectFeed">
					<SelectField
						id="insertDocumentDialogSelectFeed"
						className="select-field"
						label={t("feed.value")}
						menuItems={selectFieldFeedItems}
						fullWidth={true}
						position="tr"
						value={selectedFeed ? selectedFeed.id : undefined}
						onChange={value => onSetSelectedFeedId(value.toString())}
					/>
					{type !== 'print' ?
						<TextField
							id="insertDocumentDialogUrlInput"
							className="text-field"
							label="Url"
							fullWidth={true}
							value={url || ""}
							onChange={text => onSetUrl(text.toString())}
							onBlur={() => onCheckUrl()}
							error={!!invalidUrlError}
							errorText={invalidUrlError ? t(invalidUrlError) : ""}
							onKeyDown={e => { if (!isNextButtonDisabled && e.keyCode === 13) onChangeStep("SELECT_FOUND_DOCUMENTS"); }}
						/> : null}

				</div>
				{loading ? <CircularProgress id="insertDocumentDialogLoading" /> : null}
			</>
		);

	}
}

export default withT(ArticleInsertSelectFeed);
