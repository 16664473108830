import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/search/facets';
import { operators as filterOperators } from 'store/search/filters';
import TopicSelectors from 'store/entities/Topic/selectors';
import SearchFacetsDialog from './SearchFacetsDialog';
import { DispatchProps, StateProps } from './types';
import { getAllCategories } from 'store/entities/Category/selectors';
import { getEventName } from 'store/entities/Event/selectors';

const mapStateToProps = (state: State): StateProps => ({
	facetFilters: state.search.filters.facetsGroups,
	facets: state.search.facets.facets,
	loadingFacetsGroup: state.search.facets.loadingFacetsGroup,
	facetItemsHash: state.search.facets.extended.facetItemsHash,
	facetItems: state.search.facets.extended.facetItems,
	seeOnlySelected: state.search.facets.extended.seeOnlySelected,
	filterText: state.search.facets.extended.filterText,
	queriedMedias: state.search.facets.extended.queriedMedias,
	topics: TopicSelectors.getWithTags(state),
	feedType: state.focus.feed.feedType,
	dmrCategories: getAllCategories(state),
	getEventName: (id: string) => getEventName(state, id)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetShowMoreDialogFacetGroupKey: facetGroupKey => dispatch(operators.setShowMoreFacetGroupKey({ facetGroupKey })),
	onSetGroupFacetsFilters: (groupKey, groupFacetsFiltered) => dispatch(filterOperators.setFacetGroupFilters({ groupKey, groupFacetsFiltered })),
	onFetchFacetsGroup: groupKey => dispatch(operators.fetchGroupAPIFacets({ groupKey })),
	onToggleItemCollapsed: itemValue => dispatch(operators.toggleItemCollapsed({ itemValue })),
	onToggleCollapseAll: collapse => dispatch(operators.toggleCollapseAll({ collapse })),
	onToggleSelectedFilter: (node, isSelected) => dispatch(operators.toggleSelectedFilter({ node, isSelected })),
	onToggleSelectedAll: allSelected => dispatch(operators.toggleSelectedAll({ allSelected })),
	onToggleSeeOnlySelected: () => dispatch(operators.toggleSeeOnlySelected()),
	onSetAllState: (facetItemsHash, facetItems, seeOnlySelected) => dispatch(operators.setAll({ facetItemsHash, facetItems, seeOnlySelected })),
	onSetFilterText: filterText => dispatch(operators.setFilterText({ filterText })),
	onFetchQueriedMedias: query => dispatch(operators.fetchQueriedMedias({ query }))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchFacetsDialog);
