import React, { useCallback, useMemo } from 'react';
import { FontIcon, SelectionControl } from 'lib/imports/react-md';
import Badge from 'components/common/Badge/Badge';
import { CheckStyle, ComponentProps } from './types';

const EventPickerCheckbox = ({ optionItem, isChecked, isChildrenOpen, onClick, onChildrenToggle }: ComponentProps) => {

	const handleToggle = useCallback(item => {
		if (onChildrenToggle) onChildrenToggle(item);
	}, [onChildrenToggle]);

	const uncheckedStyle = useMemo(() => {
		if (isChecked === CheckStyle.UNCHECKED) return <FontIcon>check_box_outline_blank</FontIcon>;
		else if (isChecked === CheckStyle.DASHED) return <FontIcon>indeterminate_check_box</FontIcon>;
	}, [isChecked]);

	const label = useMemo(() => <div className='dropdown-filter-options-label'>
		{optionItem.icon}
		<span>{optionItem.name}</span>
	</div>, [optionItem]);

	return (
		<div
			className="dropdown-filter-item"
			key={`dropdown-filter-${optionItem.key}-${isChecked}`}
			id={`dropdown-filter-${optionItem.key}`}
		>
			<SelectionControl
				label={label}
				type="checkbox"
				checked={isChecked === CheckStyle.CHECKED}
				onChange={() => onClick(optionItem)}
				onClick={e => e.stopPropagation()}
				className="checkbox-green"
				uncheckedCheckboxIcon={uncheckedStyle}
				id={`dropdownFilterOption-${optionItem.key}`}
				key={`dropdownFilterOption-${optionItem.key}-${isChecked}`}
				name={`dropdownFilterOption-${optionItem.key}`}
			/>
			<div className='dropdown-filter-item-right'>
				{optionItem.badge && <Badge label={optionItem.badge.label} type={optionItem.badge.type}/>}
				{optionItem.children && optionItem.children.length ?
					<div className='children-info'>
						<span className='children-count'>{optionItem.children.length}</span>
						<div onClick={() => handleToggle(optionItem)} className={`children-toggle ${isChildrenOpen ? 'children-toggle-active': ''}`}>
							<span>{isChildrenOpen ? '<' : '>'}</span>
						</div>
					</div>
					: null
				}
			</div>
		</div>
	);
};

export default EventPickerCheckbox;
