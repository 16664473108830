import { all, takeLatest, call, put } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import isURL from 'validator/lib/isURL';

import i18n from 'lib/i18n';
import Api from 'lib/ajax/Api';
import Mekong from 'lib/ajax/Mekong';
import { ApiMediaObj } from 'types/medias/medias';
import { operators, Actions } from './actions';

export default function* sagas() {
	yield all([
		takeLatest(operators.fetchMedias, fetchMedias),
		takeLatest(operators.fetchMediaInfo, fetchMediaInfo)
	]);
}

function* fetchMedias({ payload: { media_url } }: Actions["FetchMedias"]) {
	if (media_url.length < 2) return yield put(operators.setAvailableMedias({ medias: [] }));
	const query = media_url.trim().replace(/https?:\/\//, '').replace(':', ' ') + '*';
	try {
		let medias = yield call(Mekong.get, '/v1/mediaSource/online/autocomplete', { params: { query } });
		if (isURL(media_url)) medias = union(medias, [{ id: '-1', name: "'" + i18n.t('custom') + "'", url: media_url }]);
		yield put(operators.setAvailableMedias({ medias }));
	} catch (error) {
		yield put(operators.fetchMediasError({ error }));
	}
}

function* fetchMediaInfo({ payload: { media_id } }: Actions["FetchMediaInfo"]) {
	const api = new Api();
	if (!media_id) return;

	try {
		const result = yield call([api, 'get'], "/medias/" + media_id);
		if (isEmpty(result)) return;

		const mediaInfo: ApiMediaObj = result[0];
		yield put(operators.setMediaInfo({ media: mediaInfo }));
	} catch (error) {
		yield put(operators.fetchMediaInfoError({ error }));
	}
}
