import React from 'react';
import TextField from 'react-md/lib/TextFields';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import { CircularProgress } from 'react-md/lib/Progress';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import SimpleDialog from 'components/common/dialog/simple';

import './FocusFeedDuplicateFeedDialog.scss';

type ComponentState = {
	editedFeedName: string,
	selectedFocusId: string
};

class FocusFeedDuplicateFeedDialog extends React.Component<ComponentProps, ComponentState> {

	constructor(props: ComponentProps) {
		super(props);

		const { t, feedName, focusId } = props;
		this.state = { editedFeedName: `${t('feed.form.duplicate.feed_name.value')}${feedName}`, selectedFocusId: focusId! };
	}

	private _getDialogContent: () => JSX.Element = () => {
		const { selectedFocusId, editedFeedName } = this.state;
		const { t, loadingDuplicatedFeed, duplicatedFeedId, focusList } = this.props;

		if (loadingDuplicatedFeed) return <CircularProgress id="focusFeedDuplicateFeedDialogLoading" scale={1.25} />;
		if (duplicatedFeedId) return (
			<>
				<div>{t('feed.form.duplicate.dupped_ok')}</div>
				<div><a href={`/focus/${selectedFocusId}/feed/${duplicatedFeedId}`}>{t('feed.form.duplicate.go_to_new_feed')}</a></div>
			</>
		);
		const menuItems = focusList!.map(focus => ({ label: focus.name, value: focus.id, key: focus.id }));
		return (
			<>
				<TextField
					id="focusFeedDuplicateFeedDialogFeedName"
					label={t('feed.form.duplicate.feed_name.label')}
					fullWidth
					value={editedFeedName}
					onChange={value => this.setState({ editedFeedName: value.toString() })}
				/>
				<SelectField
					id="focusFeedDuplicateFeedDialogFocusSelect"
					label={t("focus.value")}
					fullWidth
					menuItems={menuItems}
					value={selectedFocusId}
					position="tr"
					onChange={value => this.setState({ selectedFocusId: value.toString() })}
				/>
			</>
		);
	};

	private _duplicateFeed = () => {
		const { editedFeedName, selectedFocusId } = this.state;
		const { onDuplicateFeedStart } = this.props;

		onDuplicateFeedStart(editedFeedName, selectedFocusId);
	};

	public render() {
		const { t, focusList, feedName, focusId, duplicatedFeedId } = this.props;
		const { onToggleShowDuplicateFeed } = this.props;

		if (!focusList || !feedName || !focusId) return null;

		return (
			<div id="focusFeedDuplicateFeed">
				<SimpleDialog
					id="focusFeedDuplicateFeedDialog"
					text={t('feed.form.duplicate.dialog.title')}
					content={this._getDialogContent()}
					onAccept={() => duplicatedFeedId ? onToggleShowDuplicateFeed() : this._duplicateFeed()}
					onCancel={!duplicatedFeedId ? () => onToggleShowDuplicateFeed() : undefined}
					onClose={onToggleShowDuplicateFeed}
				/>
			</div>
		);
	}
}

export default withT(FocusFeedDuplicateFeedDialog);
