import ActionCreator from 'lib/store/actions';
import { PrintDmrExpressionType, Brand, ProductType, PrintDMRExpressionValues, DefinitionPrint, ExpressionsType } from 'class/Feed';
import { BrandItem, FetchQuery as FetchBrandsQuery } from 'types/focus/feed/definition/printDmr/brands';
import { FetchQuery as FetchProductTypesQuery, ProductTypeItem } from 'types/focus/feed/definition/printDmr/productTypes';
import { FetchQuery as FetchMediasQuery } from 'types/focus/feed/definition/printDmr/medias';
import { MediaExpression } from 'class/Feed';

const actionCreator = new ActionCreator('@@focus/feed/print_dmr');

// TODO use proper payloads as we have in the rest of the application

type Expression = Brand | ProductType;

type AddPrintExpression = { type: PrintDmrExpressionType, expression: Expression };
const addPrintDmrExpression = actionCreator.operator<AddPrintExpression>('ADD_PRINT_DMR_EXPRESSION');

type UpdatePrintExpression = { type: PrintDmrExpressionType, index: number, expression: Expression };
const updatePrintDmrExpression = actionCreator.operator<UpdatePrintExpression>('UPDATE_PRINT_DMR_EXPRESSION');

type RemovePrintExpression = { type: PrintDmrExpressionType, index: number };
const removePrintDmrExpression = actionCreator.operator<RemovePrintExpression>('REMOVE_PRINT_DMR_EXPRESSION');

const fetchDmrBrands = actionCreator.operator<FetchBrandsQuery>('FETCH_DMR_BRANDS_DEBOUNCED');
const [fetchDmrBrandsStart, fetchDmrBrandsSuccess, fetchDmrBrandsError] = actionCreator.apiOperators<void, BrandItem[]>('FETCH_DMR_BRANDS');
const fetchDmrProductTypes = actionCreator.operator<FetchProductTypesQuery>('FETCH_DMR_PRODUCT_TYPES_DEBOUNCED');
const [fetchDmrProductTypesStart, fetchDmrProductTypesSuccess, fetchDmrProductTypesError] = actionCreator.apiOperators<void, ProductTypeItem[]>('FETCH_DMR_PRODUCT_TYPES');
const fetchDmrMedias = actionCreator.operator<FetchMediasQuery>('FETCH_DMR_MEDIAS_DEBOUNCED');
const [fetchDmrMediasStart, fetchDmrMediasSuccess, fetchDmrMediasError] = actionCreator.apiOperators<void, MediaExpression[]>('FETCH_DMR_MEDIAS');

const resetDmrBrands = actionCreator.operator('RESET_DMR_BRANDS');
const resetDmrProductTypes = actionCreator.operator('RESET_DMR_PRODUCT_TYPES');
const resetDmrMedias = actionCreator.operator('RESET_DMR_MEDIAS');

type SetDMRDefinitionParams = { definition: DefinitionPrint };
const setDMRDefinition = actionCreator.operator<SetDMRDefinitionParams>('SET_DEFINITION');
type SetDMRExpressionParams = { expressionKey: ExpressionsType, index: number, values: PrintDMRExpressionValues };
const setDMRExpression = actionCreator.operator<SetDMRExpressionParams>('SET_EXPRESSION');

export const operators = {
	setDMRDefinition, setDMRExpression,

	addPrintDmrExpression, updatePrintDmrExpression, removePrintDmrExpression,

	fetchDmrBrands, fetchDmrBrandsStart, fetchDmrBrandsSuccess, fetchDmrBrandsError, resetDmrBrands,

	fetchDmrProductTypes, fetchDmrProductTypesStart, fetchDmrProductTypesSuccess, fetchDmrProductTypesError, resetDmrProductTypes,

	fetchDmrMedias, fetchDmrMediasStart, fetchDmrMediasSuccess, fetchDmrMediasError, resetDmrMedias
};

export type Actions = {
	SetDefinition: ReturnType<typeof operators.setDMRDefinition>;
	SetExpression: ReturnType<typeof operators.setDMRExpression>;

	AddPrintDmrExpression: ReturnType<typeof operators.addPrintDmrExpression>;
	RemovePrintDmrExpression: ReturnType<typeof operators.removePrintDmrExpression>;
	UpdatePrintDmrExpression: ReturnType<typeof operators.updatePrintDmrExpression>;

	FetchDmrBrands: ReturnType<typeof operators.fetchDmrBrands>;
	FetchDmrBrandsSuccess: ReturnType<typeof operators.fetchDmrBrandsSuccess>;

	FetchDmrProductTypes: ReturnType<typeof operators.fetchDmrProductTypes>;
	FetchDmrProductTypesSuccess: ReturnType<typeof operators.fetchDmrProductTypesSuccess>;

	FetchDmrMedias: ReturnType<typeof operators.fetchDmrMedias>;
	FetchDmrMediasSuccess: ReturnType<typeof operators.fetchDmrMediasSuccess>;
}
