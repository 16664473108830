import React, { } from 'react';
import { MAX_FEED_DOCUMENTS } from 'store/ui/report/consts';
import { withT } from 'lib/i18n';
import { number } from 'lib/format';
import { CircularProgress } from 'lib/imports/react-md';
import { FontIcon } from 'lib/imports/react-md';

import { getTypeIcon } from 'lib/svg/feed';

import { ComponentProps } from './types';

import './ReportSetupFeedsSelect.scss';

const ReportSetupFeedsSelect: React.FC<ComponentProps> = props => {
	const { type, feeds, reportFeeds, loaded, linkedBrandId } = props;
	const { onChangeFeedSelected, t } = props;

	const feedsContent = React.useMemo(() => {
		if (!loaded) return <CircularProgress id="reportSetupFeedsLoader" />;
		if (!reportFeeds) return null;
		let sizeWarningEnabled = false;

		const feedSelection = feeds.map(feed => {
			let isDisabled = false;
			if (feed.type === 'online') {
				if (!linkedBrandId) isDisabled = !!feed.brand_id && feed.brand_id !== "";
				else isDisabled = linkedBrandId !== feed.brand_id;
			}
			const reportFeed = reportFeeds[feed.id];
			const selectedClass = reportFeed.selected ? "feed-box-selected" : "";
			const disabledClass = isDisabled ? "feed-box-disabled" : "";
			const feedSizeWarning = (type === 'excel' && reportFeed.selected && reportFeed.hits > MAX_FEED_DOCUMENTS);
			if (feedSizeWarning) sizeWarningEnabled = true;
			return (
				<div
					key={feed.id}
					id={`reportSetupFeedsSelectFeed-${feed.id}`}
					className={`feed-box ${disabledClass} ${selectedClass}`}
					onClick={() => onChangeFeedSelected(feed.id, !reportFeed.selected)}
				>
					<img alt='' src={getTypeIcon(feed.type, reportFeed.selected)}></img>
					<span className="feed-box-title">{feed.name}</span>
					<span className="feed-box-hits">
						<span className="feed-box-hits-text">{t(`reports.${feed.type === 'print' ? 'references' : 'placements'}_hits`, { hits: number.locale(reportFeed.hits) })}</span>
						{feedSizeWarning && (<span className="feed-box-hits-warning"><FontIcon>warning</FontIcon></span>)}
					</span>
				</div>
			);
		});

		return (
			<div className="form-section-content">
				{feedSelection}
				{sizeWarningEnabled && (
					<div className="feeds-max-results-warning">
						<FontIcon>warning</FontIcon>
						<span>{t('reports.feed_size_limit_warning_message', { maxResults: number.locale(MAX_FEED_DOCUMENTS) })}</span>
					</div>
				)}
			</div>
		);
	}, [t, feeds, type, reportFeeds, loaded, linkedBrandId, onChangeFeedSelected]);

	return (
		<div id="reportSetupFeedsSelect" className="form-section vertical-align-top" >
			<div className="form-section-title">
				{t('reports.limit_search')}
			</div>
			<div className="form-section-content">
				{feedsContent}
			</div>
		</div>
	);
};

export default withT(React.memo(ReportSetupFeedsSelect));
