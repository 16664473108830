import { all } from 'redux-saga/effects';

import setupSagas from './setup/sagas';
import statusSagas from './status/sagas';
import directSagas from './direct/sagas';

export default function* sagas() {
	yield all([
		setupSagas(),
		statusSagas(),
		directSagas()
	]);
}
