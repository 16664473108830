import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuditLayout from './Layout';
import { ComponentProps } from './types';

const Audit = ({ user }: ComponentProps) => {
	return <>
		<Switch>
			{
				user.role === "user" ?
					<Redirect to={{ pathname: "/" }} /> :
					<Route path="/audit" component={AuditLayout} />
			}
		</Switch>
	</>;
};

export default Audit;
