import React from 'react';

import { withT } from 'lib/i18n';
import FocusSelect from 'components/common/Focus/Select';

import { ComponentProps } from './types';

import './ReportSetupFocus.scss';

const ReportSetupFocus: React.FC<ComponentProps> = props => {
	const { focusId } = props;
	const { onPushRoute } = props;

	const selectedFocusIds = React.useMemo(() => focusId ? [focusId] : [], [focusId]); // eslint-disable-line react-hooks/exhaustive-deps

	const onChange = React.useCallback(([focusId]: string[]) => {
		if (!focusId) onPushRoute(`/report`);
		else onPushRoute(`/report/${focusId}`);
	}, [onPushRoute]);

	return (
		<div id="reportSetupFocus" className="form-section">
			<div className="form-section-title">
				Focus:
			</div>
			<div className="form-section-content">
				<FocusSelect selectedFocusIds={selectedFocusIds} onChange={onChange} />
			</div>
		</div>
	);
};

export default withT(React.memo(ReportSetupFocus));
