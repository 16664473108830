import React from "react";
import { SelectionControl } from 'lib/imports/react-md';

import { TProps, withT } from "lib/i18n";

type OwnProps = {
	enabled: boolean,
	onEnabledUpdated: (enabled: boolean) => void
};

type ComponentProps = OwnProps & TProps;

const NewsletterEditFormEnable = (props: ComponentProps) => {
	const { t, enabled } = props;
	const { onEnabledUpdated } = props;

	return (
		<div id="newsletterEditSectionEnable" className="form-section">
			<div className="form-section-title">
				{t('newsletter.form.label.status')}
			</div>
			<div className="form-section-content">
				<div className="form-section-content-column">
					<SelectionControl
						id={`newsletterEditSectionEnableSwitch`}
						name="newsletterEditSectionEnableSwitch"
						type="switch"
						label={enabled ? t('newsletter.form.input.status.active') : t('newsletter.form.input.status.inactive')}
						checked={enabled}
						onChange={enabled => onEnabledUpdated(!!enabled)}
					/>
				</div>
			</div>
		</div>
	);
};

export default withT(React.memo(NewsletterEditFormEnable));
