
import React from 'react';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import Button from 'react-md/lib/Buttons/Button';
import DropdownMenu from 'react-md/lib/Menus/DropdownMenu';
import { useMemo, useCallback } from 'react';
import featureFlags from 'lib/featureFlags';
import { Tenant } from "class/Tenant";
import ListItem from 'react-md/lib/Lists/ListItem';
import GA from 'lib/googleAnalytics';


export function SortButton({t, tenant, sort, onSetSort, onToggleDrawer}: { t: any, tenant: any, sort: any, onSetSort: any, onToggleDrawer: any}) {
	const audienceSort = useMemo(() => new Tenant(tenant).getAudienceFieldSort() + ':desc', [tenant]);
	const tooltip = useMemo(() => <div dangerouslySetInnerHTML={{ __html: t('results.sort.help') }}></div>, [t]);

	const selectMenuItems = useMemo(() => [
		{ label: t('results.sort.audience'), value: audienceSort, key: audienceSort },
		{ label: t('results.sort.echo'), value: 'echo:desc', key: 'echo:desc' },
		{ label: t('results.sort.publication_date'), value: 'publication_date:desc', key: 'publication_date:desc' },
		{ label: t('results.sort.inserted_at'), value: '_uid:desc', key: '_uid:desc' },
		!featureFlags.isEnabled('no-miv') ? { label: t('insert_article.modal.form.miv'), value: 'miv:desc', key: 'miv:desc' } : false,
		{ label: t('results.sort.relevance'), value: '_score:desc', key: '_score:desc' }
	].filter(Boolean), [t, audienceSort]);

	const handleSort = useCallback(value => {
		const { 0: label } = value.split(':');
		if (label !== '_uid') GA.trackEvent({ category: "Feed results", action: 'Sort by', label: t(`results.sort.${label}`, { lng: "en" }) });
		onSetSort(value);
	}, [onSetSort, t]);

	const dropDownMenuItems = useMemo(() => [
		<ListItem key={1} primaryText={t('results.sort.audience')} onClick={() => { handleSort(audienceSort); }} />,
		<ListItem key={2} primaryText={t('results.sort.echo')} onClick={() => { handleSort('echo:desc'); }} />,
		<ListItem key={3} primaryText={t('results.sort.publication_date')} onClick={() => { handleSort('publication_date:desc'); }} />,
		<ListItem key={4} primaryText={t('results.sort.inserted_at')} onClick={() => { handleSort('_uid:desc'); }} />,
		<ListItem key={5} primaryText={t('insert_article.modal.form.miv')} onClick={() => { handleSort('miv:desc'); }} />,
		<ListItem key={6} primaryText={t('results.sort.relevance')} onClick={() => { handleSort('_score:desc'); }} />
	], [handleSort, audienceSort, t]);
	return (
		<>
			<Button id="searchFormToggleDrawerBtn" icon onClick={() => onToggleDrawer()}>filter_list</Button>
			<div className="search-form-sort-by">
				<SelectField
					id="sortByInputSelect"
					className="sort-by-select"
					label={t('results.sort.value')}
					repositionOnResize
					menuItems={selectMenuItems}
					onChange={value => { handleSort(value.toString()); }}
					defaultValue={sort}
					value={sort}
				/>
				<DropdownMenu id="formSortByButton" className="form-sort-by-button" position="below"
					menuItems={dropDownMenuItems}
				>
					<Button icon iconClassName="icon-documents_sort" />
				</DropdownMenu>
				<Button className="form-sort-by-help" icon tooltipLabel={tooltip} tooltipPosition="right">help_outline</Button>
			</div>
		</>
	);
}
