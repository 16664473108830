import { combineReducers } from 'redux';

import * as list from './list';
import * as edit from './edit';

export const reducers = combineReducers({
	list: list.reducers,
	edit: edit.reducers
});

export interface State {
	list: list.State;
	edit: edit.State;
}

export const INITIAL_STATE = {
	list: list.INITIAL_STATE,
	edit: edit.INITIAL_STATE
};
