import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import ArticleBulkPreview from './ArticleBulkPreview';
import { operators } from 'store/article/insert';
import { operators as notificationOperators } from 'store/app/notifications/actions';

const mapStateToProps = (state: State): StateProps => ({
	type: state.article.insert.type,
	documents: state.article.insert.bulkPreviewDocuments,
	totalErrors: state.article.insert.bulkPreviewTotalErrors,
	errorFileUrl: state.article.insert.bulkPreviewErrorFileUrl,
	tenant: state.app.profile.tenant!
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onAddDocumentImage: (file, imageType, docIndex) => dispatch(operators.addDocumentImage({ image: file, imageType, docIndex })),
	onRemoveDocumentImage: (imageType, docIndex) => dispatch(operators.removeDocumentImage({ imageType, docIndex })),
	onAddNotification: notification => dispatch(notificationOperators.add({ notification })),
	onBulkImport: () => dispatch(operators.bulkImportDocuments())
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleBulkPreview);
