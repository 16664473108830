import { connect } from "react-redux";
import { State } from "store/types";
import { StateProps } from "./types";
import profileSelectors from 'store/app/profile/selectors';
import Audit from "./Audit";

const mapStateToProps = (state: State): StateProps => ({
	user: profileSelectors.getUserInstance(state)!
});

export default connect(mapStateToProps)(Audit);