import { all } from "redux-saga/effects";
import listSagas  from 'store/ui/audit/list/sagas';
import pagingSagas  from 'store/ui/audit/paging/sagas';
import filterSagas  from 'store/ui/audit/filters/sagas';

export default function* sagas() {
	yield all([
		listSagas(),
		pagingSagas(),
		filterSagas()
	]);
}