import React from 'react';

import { withT } from 'lib/i18n';

import FocusFeedDefinitionLayoutMedia from './Sections/Media';
import FocusFeedDefinitionLayoutBrand from './Sections/Brand';
import FocusFeedDefinitionLayoutContent from './Sections/Content';
import FocusFeedDefinitionLayoutProductType from './Sections/ProductType';
import FocusFeedDefinitionInformation from './Sections/Information';

import FocusFeedDefinitionLayoutSocialContent from './Sections/SocialContent';
import FocusFeedDefinitionLayoutStories from './Sections/Stories';
import FocusFeedDefinitionLayoutFollowers from './Sections/Followers';
import FocusFeedDefinitionLayoutProfile from './Sections/Profile';

import { ComponentProps } from './types';

import './FocusFeedDefinitionLayout.scss';

const FocusFeedDefinitionLayout = (props: ComponentProps) => {
	const {
		productNameQueries, contentQueries, socialContentQueries, brands, productTypes, medias, stories, followers, profiles,
		showAutocompleteUrlPlaceholder = false
	} = props;
	return (
		<>
			{<FocusFeedDefinitionInformation {...props}></FocusFeedDefinitionInformation>}
			{brands && <FocusFeedDefinitionLayoutBrand {...brands}></FocusFeedDefinitionLayoutBrand>}
			{productTypes && <FocusFeedDefinitionLayoutProductType {...productTypes}></FocusFeedDefinitionLayoutProductType>}
			{/* TODO: ContentSection should be renamed to QuerySearchSection or something like that */}
			{productNameQueries && <FocusFeedDefinitionLayoutContent {...productNameQueries}></FocusFeedDefinitionLayoutContent>}
			{contentQueries && <FocusFeedDefinitionLayoutContent {...contentQueries}></FocusFeedDefinitionLayoutContent>}
			{medias && <FocusFeedDefinitionLayoutMedia {...medias} showAutocompleteUrlPlaceholder={showAutocompleteUrlPlaceholder}></FocusFeedDefinitionLayoutMedia>}

			{/* SOCIAL MEDIA FEED DEFINITION */}
			{socialContentQueries && <FocusFeedDefinitionLayoutSocialContent {...socialContentQueries}></FocusFeedDefinitionLayoutSocialContent>}
			{profiles && <FocusFeedDefinitionLayoutProfile {...profiles}></FocusFeedDefinitionLayoutProfile>}
			{stories && <FocusFeedDefinitionLayoutStories {...stories}></FocusFeedDefinitionLayoutStories>}
			{followers && <FocusFeedDefinitionLayoutFollowers {...followers}></FocusFeedDefinitionLayoutFollowers>}
		</>
	);
};

export default withT(React.memo(FocusFeedDefinitionLayout));
