import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import reportSelectors from 'store/ui/report/selectors';
import selectors from 'store/ui/report/setup/selectors';
import { operators } from 'store/ui/report/setup';
import { DispatchProps, StateProps } from './types';

import ReportSetup from './ReportSetup';

const mapStateToProps = (state: State): StateProps => ({
	focusId: reportSelectors.getFocusId(state),
	step: selectors.getStep(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onResetSetup: () => dispatch(operators.resetSetup())
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSetup);
