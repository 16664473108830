import React, { useCallback, useMemo } from 'react';
import { withT } from "lib/i18n";

import DropdownFilter from "components/common/DropdownFilter/DropdownFilter";
import { FilterFacet } from 'class/Filter';
import { Facet, FacetItem } from 'class/Facet';

import { ComponentProps } from './types';

const Origin = (props: ComponentProps) => {
	const { excluded, facetFilters, display, isOpen } = props;
	const { t, onSetExcludeField, onSetFacetGroupFilters, onToggleOpen } = props;

	const getFacetItems = React.useCallback(() => {
		return Facet.originFacetValues.map(origin =>({ key: origin, name: t(`filters.origin.${origin}`) }));
	}, [t]);

	const originFilters = useMemo(() => (facetFilters && facetFilters[Facet.originGroupKey]) || [], [facetFilters]);

	const handleChange = useCallback((items: FacetItem[]) => {
		onSetFacetGroupFilters(items as FilterFacet[]);
	}, [onSetFacetGroupFilters]);

	const handleExclude = useCallback((set: boolean) => onSetExcludeField(set), [onSetExcludeField]);

	return display ?
		<DropdownFilter
			id='originFilter'
			key='filters-origin-dropdown'
			title={t('filters.origin.value')}
			excluded={excluded}
			filters={getFacetItems()}
			initSelectedFilters={originFilters as FacetItem[]}
			defaultFilter={{ name: t('reports.all_male_sing'), key: 'all' }}
			disable={false}
			isOpen={isOpen}
			onToggleOpen={onToggleOpen}
			onChange={handleChange}
			onExclude={handleExclude}
		/>
		: null;
};

export default withT(Origin);
