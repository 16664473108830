import ActionCreator, { CrudActions } from 'lib/store/actions';
import { Feed } from './model';

const actionCreator = new ActionCreator("@@orm/feed");

const crudOperators = actionCreator.crudOperators<Feed>();

export const operators = {
	...crudOperators
};

export interface Actions extends CrudActions<Feed> {
}
