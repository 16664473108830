import ActionCreator, { CrudActions } from "lib/store/actions";
import { Focus } from "./model";
import { FocusData } from "./types";

const actionCreator = new ActionCreator('@@orm/focus');

const crudOperators = actionCreator.crudOperators<Focus>();
const parse = actionCreator.operator<FocusData | FocusData []>('PARSE');
const deleteCascade = actionCreator.operator<string>('DELETE_CASCADE');

export const operators = {
	...crudOperators,
	parse,
	deleteCascade
};

export interface Actions extends CrudActions<Focus> {
	Parse: ReturnType<typeof operators.parse>
	DeleteCascade: ReturnType<typeof operators.deleteCascade>
}
