import { connect } from 'react-redux';

import profileSelectors from 'store/app/profile/selectors';
import { MapStateToProps, MapDispatchToProps } from './types';
import { getBrandName, getIsCrawled } from 'store/entities/Brand/selectors';
import NewsletterEditFormFeeds from './NewsletterEditFormFeeds';
import { operators as notificationOperators } from 'store/app/notifications';
import FocusSelectors from 'store/entities/Focus/selectors';

const mapStateToProps: MapStateToProps = state => ({
	user: profileSelectors.getUserInstance(state)!,
	linkedBrandList: FocusSelectors.getAllLinkedBrandIds(state).map((brandId: string) => ({
		id: brandId,
		name: getBrandName(state, brandId),
		isCrawled: getIsCrawled(state, brandId)
	}))
});

const mapDispatchToProps: MapDispatchToProps = dispatch => ({
	onAddNotification: notification => dispatch(notificationOperators.add({ notification }))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterEditFormFeeds);
