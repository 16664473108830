export type ErrorInfo = {
	code: string,
	message: string
};

export default class ApiError extends Error implements ErrorInfo {
	public code: string;
	public message: string;

	constructor(errorInfo: ErrorInfo) {
		super(errorInfo.message);
		this.code = errorInfo.code;
		this.message = errorInfo.message;
	}
}