import { createSelector } from 'reselect';
import { sessionSelector } from "store/entities/selectors";

export const getAllCategories = createSelector(sessionSelector, (_: any) => _,
	({Category}): {[id: string]: string} => {
		const categoryObject: {[id: string]: string} = {};
		Category.all().toModelArray().map(category => categoryObject[category.id] = category.name);
		return categoryObject;
	}
);

export const getCategoryName = createSelector(sessionSelector, (_: any, id: string) => id,
	({Category}, id): string | undefined => {
		const category = Category.withId(id);
		return category ? category.name : undefined;
	}
);

// TODO: Move this to mekong, IE: Categories.load(esResult);
export const getFullCategoryName = createSelector(sessionSelector, (_: any, id: string) => id,
	({Category}, id): string => {
		const idSlices = id.split('-');
		const categories = [Category.withId(idSlices[0]), Category.withId(`${idSlices[0]}-${idSlices[1]}`), Category.withId(`${idSlices[0]}-${idSlices[1]}-${idSlices[2]}`)];
		return categories.map(category => category ? category.name : "").join(" » ");
	}
);