import { connect } from "react-redux";
import { Dispatch } from "redux";
import { State } from 'store/types';
import DateForm from "./DateForm";
import { UserObject } from "class/User";

import { operators as searchFormOperators } from 'store/search/form';
import { operators as focusFeedOperators } from 'store/focus/feed';
import feedSelectors from 'store/focus/feed/selectors';

import { DispatchProps, StateProps } from "./types";

const mapStateToProps = (state: State): StateProps => ({
	recoveryId: state.focus.feed.recoveryId,
	recoveryStatus: state.focus.feed.recoveryStatus,
	feedType: state.focus.feed.feedType,
	brand_sync: feedSelectors.getBrandSync(state),
	user: state.app.profile.user as UserObject
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetPeriod: period => dispatch(searchFormOperators.setPeriod({ period })),
	onFetchSearch: () => dispatch(focusFeedOperators.fetchSearch()),
	onSetBeginDate: beginDate => dispatch(focusFeedOperators.setBeginDate({ date: beginDate })),
	onSetEndDate: endDate => dispatch(focusFeedOperators.setEndDate({ date: endDate })),
	onRecoverFeed: () => dispatch(focusFeedOperators.recoverFeed())
});
export default connect(mapStateToProps, mapDispatchToProps)(DateForm);
