import React from 'react';

import { FontIcon, Button } from 'lib/imports/react-md';
import { withT } from 'lib/i18n';
import NewsletterEmailLists from './Lists';
import NewsletterEmailListEdit from './Edit';

import { ComponentProps } from './types';
import './EmailListLayout.scss';

const NewsletterEmailListLayout = (props: ComponentProps) => {
	const { selectedListId } = props;
	const { t, onClose } = props;

	const header = (
		<div className="email-list-header md-grid">
			<FontIcon>email</FontIcon>
			<span>{t('email_list.header')}</span>
			<Button id="closeEmailListButton" className="close-btn btn--no-background" icon primary onClick={onClose}>clear</Button>
		</div>
	);

	const content = selectedListId ?
		<NewsletterEmailListEdit></NewsletterEmailListEdit>
		: <NewsletterEmailLists></NewsletterEmailLists>;

	return (
		<div className="email-list-layout">
			{header}
			<div className="email-list-content-container">
				{content}
			</div>
		</div>
	);
};

export default React.memo(withT(NewsletterEmailListLayout));
