import ActionCreator, { CrudActions } from "lib/store/actions";
import { Topic } from "./model";
import { TopicData } from "./types";

const actionCreator = new ActionCreator('@@orm/topic');

const crudOperators = actionCreator.crudOperators<Topic>();
const parse = actionCreator.operator<TopicData | TopicData []>('PARSE');

export const operators = {
	...crudOperators,
	parse
};

export interface Actions extends CrudActions<Topic> {
	Parse: ReturnType<typeof operators.parse>
}
