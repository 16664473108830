import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import NewsletterSelectors from 'store/entities/Newsletter/selectors';
import { operators } from 'store/ui/newsletter/draft/edit';
import selectors from 'store/ui/newsletter/draft/edit/selectors';

import { DispatchProps, StateProps, OwnProps } from './types';
import NewsletterDraftEdit from './NewsletterDraftEdit';

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
	newsletter: NewsletterSelectors.get(state, ownProps.id),
	status: selectors.getStatus(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onFetchContent: (id, timestamp) => dispatch(operators.fetchContent({id, timestamp}))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterDraftEdit);
