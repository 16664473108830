import { State } from "store/types";
import { StateProps } from "./types";
import selectors from "store/ui/audit/filters/selectors";
import { operators } from "store/ui/audit/filters";
import { Dispatch } from "redux";
import { DispatchProps } from "./types";
import { connect } from "react-redux";
import {EventPickerContent} from "./EventPickerContent";

const mapStateToProps = (state: State): StateProps => ({
	events: selectors.getEvents(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onEventsChanged: events => dispatch(operators.eventsChanged({
		events
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EventPickerContent);
