import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import AppToolbar from './Toolbar';
import selectors from 'store/app/profile/selectors';
import facebookSelectors from 'store/app/facebook/selectors';
import profileSelectors from 'store/app/profile/selectors';

const mapStateToProps = (state: State): StateProps => ({
	tenant: selectors.getTenantInstance(state)!,
	user: selectors.getUserInstance(state)!,
	tenantFacebookUsers: facebookSelectors.isLoaded(state) ? profileSelectors.getTenantActiveFacebookUsers(state) : null
});

const mapDispatchToProps = (): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AppToolbar);
