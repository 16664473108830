import React from 'react';
import { withT } from 'lib/i18n';
import { ComponentProps } from "./types";
import { Button, TextField } from 'lib/imports/react-md';

import './AuditSearch.scss';

const AuditSearch = ({ onFilterChanged, t }: ComponentProps) => {
	const [searchTerm, setSearchTerm] = React.useState('');

	React.useEffect(() => {
		if (!searchTerm) onFilterChanged(searchTerm);
	}, [onFilterChanged, searchTerm]);


	const submit = React.useCallback(() => {
		const buildSearchQuery = (searchTerm: string) => {
			if (!searchTerm.includes('@')) return `*${searchTerm}*`;
			return `*${searchTerm}* OR *${encodeURIComponent(searchTerm)}*`;
		};

		onFilterChanged(buildSearchQuery(searchTerm));
	}, [onFilterChanged, searchTerm]);

	const content = React.useMemo(() => (
		<div id="auditSearchBar" key="auditSearchBar">
			<TextField
				value={searchTerm}
				label={t('audit-search-bar-label')}
				id="auditSearchBar"
				key="auditSearchBarTextField"
				onChange={value => setSearchTerm(value.toString())}
				onKeyDown={ev => { if (ev.keyCode === 13) submit(); }}
			/>
			<Button
				icon
				tooltipPosition="right"
				onClick={() => submit()}
				className="audit-search-icon"
			>
				search
			</Button>
		</div>
	), [searchTerm, submit, t]);

	return content;
};

export default withT(AuditSearch);
