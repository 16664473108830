import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import { operators as searchOperators } from 'store/search';
import Article from './Article';

const mapStateToProps = ({ router }: State): StateProps => ({
	location: router.location
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetFormAndFilters: query => dispatch(searchOperators.setFormAndFilters({ query }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Article);
