import { createSelector } from "reselect";
import { get, includes ,transform} from 'lib/imports/lodash';

import { State } from 'store/types';

const rootSelector = (state: State) => state.search.facets;

export const selectors = {
	isLoading: createSelector(rootSelector, state => state.loadingFacets),
	isFacetGroupOpened: createSelector((state: State, groupKey: string) => ({ openedGroupKeys: rootSelector(state).facetsGroupsOpened, groupKey }),
		({ openedGroupKeys, groupKey }) => includes(openedGroupKeys, groupKey)),
	getFacetCounters: createSelector((state: State, groupKey: string) => ({ facets: rootSelector(state).facets, groupKey }),
		({ facets, groupKey }) => transform(get(facets, ['groups', groupKey]) || [], (result: Record<string, number>, facet) => {
			result[facet.key] = facet.counter || 0;
		}, {})
	)
};
