import React, { useState } from 'react';
import { Button } from 'react-md';

import { ComponentProps } from './types';

import './FocusFeedDefinitionMultipleExpressions.scss';

export const FocusFeedDefinitionMultipleExpressions = (props: ComponentProps) => {

	const { enabled, placeholder, submitLabel, cancelLabel, onCancel, onSubmit } = props;
	const [multipleUrlQuery, setMultipleUrlQuery] = useState('');

	return (
		<>{
			enabled &&
			<>
				<textarea
					id='focusFeedDefinitionMultipleTextarea'
					className='definition-add-multiple-textarea'
					placeholder={placeholder}
					onChange={input => setMultipleUrlQuery(input.target.value)}
				></textarea>

				<Button
					flat
					id='focusFeedDefinitionMultipleSubmitBtn'
					className='definition-add-multiple-button definition-submit-button'
					onClick={() => onSubmit(multipleUrlQuery)}
				>
					{submitLabel}
				</Button>
				<Button
					flat
					id='focusFeedDefinitionMultipleCancelBtn'
					className='definition-add-multiple-button'
					onClick={onCancel}
				>
					{cancelLabel}
				</Button>
			</>
		}</>
	);
};

export default React.memo(FocusFeedDefinitionMultipleExpressions);
