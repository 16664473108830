import { all, put, select, takeLatest } from 'redux-saga/effects';
import { map, uniq, keyBy } from 'lib/imports/lodash';
import moment from 'moment';

import { operators, Actions } from './actions';
import { State } from 'store/types';
import { State as FormState } from 'store/search/form/reducers';
import { State as FiltersState } from 'store/search/filters/reducers';
import { State as HorizontalFiltersState } from 'store/search/horizontalFilters/reducers';
import profileSelectors from 'store/app/profile/selectors';
import { UserObject } from 'store/entities/User';
import { operators as resultsOperators } from 'store/search/results';
import { operators as directReportOperators } from 'store/ui/report/direct';
import { getArticleSearchParams } from 'lib/searchParams';
import { Dictionary } from 'lodash';
import { ArticleSearchParams } from 'types/search/form';
import { getLanguageIso } from 'store/entities/Language/selectors';
import { getCountryIso } from 'store/entities/Country/selectors';

export default function* sagas() {
	yield all([
		takeLatest(operators.submit.type, formSubmit),
		takeLatest(operators.setSort.type, setSort),
		takeLatest(operators.setLimit.type, setLimit),
		takeLatest(operators.setPeriod.type, setPeriod),
		takeLatest(operators.setBeginDate.type, setBeginDate),
		takeLatest(operators.setDateType.type, setDateType),
		takeLatest(operators.setEndDate.type, setEndDate),
		takeLatest(operators.setStart.type, setStart),
		takeLatest(operators.getInsightsFilters.type, getInsightsFilters)
	]);
}

function* getInsightsFilters() {
	const state: State = yield select((state: State) => state);
	const searchFilters: FiltersState = state.search.filters;
	const searchForm: FormState = state.search.form;
	const excludedFields: string[] = state.search.horizontalFilters.excludedFields;
	const horizontalFilters: HorizontalFiltersState = state.search.horizontalFilters;

	const finalSearchForm = {
		...searchForm,
		period: 'custom',
		begin_date: horizontalFilters.begin_date,
		end_date: horizontalFilters.end_date
	} as FormState;

	const params = getArticleSearchParams(finalSearchForm, searchFilters, excludedFields);
	const excludedFieldsHash = keyBy(horizontalFilters.excludedFields);
	const filters = __transformFiltersToInsightsFilters(state, params, excludedFieldsHash);
	yield put(operators.setInsightsFilters({ filters }));
}

function* formSubmit() {
	yield put(operators.setStart({ start: 0 }));
}

function* setSort() {
	yield put(resultsOperators.fetchSearch());
}

function* setLimit() {
	yield put(resultsOperators.fetchSearch());
}

function* setStart() {
	yield put(directReportOperators.toggleQuickReport(true));
	yield put(resultsOperators.fetchSearch());
}

function* setPeriod({ payload: { period } }: Actions["SetPeriod"]) {
	const searchForm: FormState = yield select((state: State) => state.search.form);
	const user: UserObject = yield select(profileSelectors.getUser);

	let begin_date: Date | undefined;
	let end_date: Date | undefined;
	const previousPeriod = searchForm.period;
	if (period === previousPeriod) return;
	if (period === 'custom') {
		if (previousPeriod === "last_week") {
			begin_date = moment().startOf('day').subtract(1, 'week').toDate();
			end_date = moment().endOf('day').tz(user.settings.timezone, true).toDate();
		} else if (previousPeriod === "last_month") {
			begin_date = moment().startOf('day').subtract(1, 'month').toDate();
			end_date = moment().endOf('day').tz(user.settings.timezone, true).toDate();
		} else if (previousPeriod === "last_three_months") {
			begin_date = moment().startOf('day').subtract(3, 'months').toDate();
			end_date = moment().endOf('day').tz(user.settings.timezone, true).toDate();
		} else if (previousPeriod === "next_fifteen_days") {
			begin_date = moment().startOf('day').tz(user.settings.timezone, true).toDate();
			end_date = moment().endOf('day').add(15, 'days').toDate();
		}
	}
	yield put(operators.setPeriodAndDatesRange({ period, begin_date, end_date }));
	if (period !== "custom") yield put(resultsOperators.fetchSearch());
}

function* setBeginDate({ payload: { date } }: Actions["SetBeginDate"]) {
	const searchForm: FormState = yield select((state: State) => state.search.form);

	yield put(operators.setPeriodAndDatesRange({ period: "custom", begin_date: date, end_date: searchForm.end_date! }));
	yield put(resultsOperators.fetchSearch());
}

function* setEndDate({ payload: { date } }: Actions["SetEndDate"]) {
	const searchForm: FormState = yield select((state: State) => state.search.form);

	yield put(operators.setPeriodAndDatesRange({ period: "custom", begin_date: searchForm.begin_date!, end_date: date }));
	yield put(resultsOperators.fetchSearch());
}

function* setDateType() {
	yield put(resultsOperators.fetchSearch());
}

const __transformFiltersToInsightsFilters = (state: State, params: ArticleSearchParams, excludedFieldsHash: Dictionary<string>) => (
	{
		channelType: __buildInsightsFilter(params, 'channel_type_id_filter', excludedFieldsHash, (chanelId: string) => __channelIdToName(parseInt(chanelId))),
		countries: __buildInsightsFilter(params, 'country_path_filter', excludedFieldsHash, (countryPath: string) => getCountryIso(state, countryPath)),
		endDate: moment.utc(params.end_date).format("YYYY/MM/DD"),
		events: __buildInsightsFilter(params, 'print.event.id_filter', excludedFieldsHash, (eventId: string) => `dmr:${eventId}`),
		feeds: __buildInsightsFilter(params, 'insights.filter', excludedFieldsHash, (feed: string) => feed.split(':')[1]),
		langs: __buildInsightsFilter(params, 'language.id_filter', excludedFieldsHash, (languageId: string) => getLanguageIso(state, languageId)),
		medias: __buildInsightsFilter(params, 'media_id_filter', excludedFieldsHash),
		startDate: moment.utc(params.begin_date).format("YYYY/MM/DD"),
		placementsType: __buildInsightsFilter(params, 'print.article_type_filter', excludedFieldsHash),
		tags: __buildInsightsFilter(params, 'tenant.topic_tag_filter', excludedFieldsHash, (tagId: string) => tagId.split('_')[1]),
		origins: __buildInsightsFilter(params, 'document_origin_filter', excludedFieldsHash, (origin: string) => origin.toUpperCase()),

		// TODO: No voiceType in Discover
		voicesType: __buildInsightsFilter(params, 'todo' as keyof ArticleSearchParams, excludedFieldsHash),
		...__buildInsightsCategoryValues(params, state, excludedFieldsHash)
	}
);

const __buildInsightsFilter = (params: ArticleSearchParams, discoverFilter: keyof ArticleSearchParams, excludedFieldsHash: Dictionary<string>, fn?: Function) => {
	const discoverFilterValue = params[discoverFilter] as string;
	if (!discoverFilterValue) return {
		isExclusion: false,
		values: []
	};

	const excludeField = discoverFilter.replace(/_filter/g, '');
	return {
		isExclusion: !!excludedFieldsHash[excludeField],
		values: uniq(map(discoverFilterValue.split(','), (value: string) => fn ? fn(value) : value))
	};
};

const __buildInsightsCategoryValues = (params: ArticleSearchParams, state: State, excludedFieldsHash: Dictionary<string>) => {
	const discoverFilter = 'tenant.categories_id_filter';
	const discoverFilterValue = params[discoverFilter] as string;
	const result = {
		lines: { isExclusion: false, values: [] as string[] },
		subLines: { isExclusion: false, values: [] as string[] }
	};

	if (!discoverFilterValue) return result;

	const excludeField = discoverFilter.replace(/_filter/g, '');
	result.lines.isExclusion = !!excludedFieldsHash[excludeField];
	result.subLines.isExclusion = !!excludedFieldsHash[excludeField];

	discoverFilterValue.split(',').forEach((category: string) => {
		const splittedCategory = category.split('-');
		result.lines.values.push(`dmr:${splittedCategory[1]}`);
		result.subLines.values.push(`dmr:${splittedCategory[2]}`);
	});

	return result;
};

//TODO: SHouldn't be this already somewhere?
const __channelIdToName = (channelId: number) => {
	if (channelId < 24) return "ONLINE:online";
	else switch (channelId) {
		case 24:
			return "PRINT:vb"; //TODO ask Insights what is "vb"
		case 25:
			return "PRINT:dmr_editorial";
		case 30:
			return "SOCIAL:twitter";
		case 31:
			return "SOCIAL:weibo";
		case 40:
			return "SOCIAL:youtube";
		case 41:
			return "SOCIAL:tiktok";
		case 51:
			return "SOCIAL:instagram";
		case 60:
			return "SOCIAL:facebook";
		case 46:
			return "SOCIAL:douyin";
		case 47:
			return "SOCIAL:bilibili";
		case 52:
			return "SOCIAL:xiaohongshu";
		case 62:
			return "SOCIAL:wechat_public";
		default:
			return "";
	}
};
