import React from 'react';
import { uniq, without } from 'lib/imports/lodash';
import SelectField from 'react-md/lib/SelectFields/SelectField';

import { withT } from 'lib/i18n';
import SelectCategory from 'components/common/Categorization/SelectCategory';
import TopicTag from 'components/common/Categorization/TopicTag';
import Selected from 'components/common/Categorization/TopicTag/Selected/Selected';
import { ComponentProps } from './types';

import './ReportSetupSettingsCategoryTags.scss';

const ReportSetupSettingsCategoryTags: React.FC<ComponentProps> = props => {
	const { t, reportTopicTagIds, reportCategories, reportTopicTagOperator } = props;
	const { onChangeTags, onChangeCategories, onChangeTopicTagOperator } = props;

	const categoriesContent = React.useMemo(() => {
		return <SelectCategory
			categories={reportCategories}
			onAddCategory={category => onChangeCategories(uniq([...reportCategories, category]))}
			onRemoveCategory={category => onChangeCategories(without(reportCategories, category))}
			levels={[1, 2, 3]}
			multiple
		/>;
	}, [reportCategories, onChangeCategories]);

	const newCategoryContent = React.useMemo(() => {
		return (
			<SelectCategory
				categories={reportCategories}
				showNewlabel={true}
				onAddCategory={category => onChangeCategories(uniq([...reportCategories, category]))}
				onRemoveCategory={category => onChangeCategories(without(reportCategories, category))}
				levels={[1, 2, 3]}
				clearOnAutocomplete
			/>
		);
	}, [onChangeCategories, reportCategories]);

	const tagsContent = React.useMemo(() => {
		return <TopicTag
			selectedTags={{ newTagNames: [], tagIds: reportTopicTagIds }}
			onAddTags={tagIds => onChangeTags(uniq([...reportTopicTagIds, tagIds[0]]))}
			allowCreate={false}
		/>;
	}, [reportTopicTagIds, onChangeTags]);

	const tagsOperator = React.useMemo(() => {
		return <SelectField
			className="report-settings-tags-operator"
			id="reportsSettingsTagsOperator"
			menuItems={[{ value: 'AND', label: t('reports.selected.operator.AND') }, { value: 'OR', label: t('reports.selected.operator.OR') }]}
			value={reportTopicTagOperator}
			position="br"
			onChange={operator => onChangeTopicTagOperator(operator as 'OR' | 'AND')}
		/>;
	}, [t, reportTopicTagOperator, onChangeTopicTagOperator]);

	const selectedTagsElements = React.useMemo(() => {
		return <Selected
			selectedTags={{ newTagNames: [], tagIds: reportTopicTagIds }}
			onRemoveTag={tag => onChangeTags(without(reportTopicTagIds, tag))}
			operator={reportTopicTagOperator}
		/>;
	}, [reportTopicTagIds, reportTopicTagOperator, onChangeTags]);

	return (
		<div id="reportSetupSettingsCategoryTags" className="form-section" >
			<div className="form-section-title">
				{t('reports.selection')}
			</div>
			<div className="form-section-content">
				{newCategoryContent}
				{categoriesContent}
				<div className="form-section-content-tags">
					{tagsContent}
					{tagsOperator}
				</div>
				<div className="categorization-tags-selected"> {selectedTagsElements} </div>
			</div>
		</div>
	);
};

export default withT(React.memo(ReportSetupSettingsCategoryTags));
