import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { NewsletterDraftChannels, NewsletterDraftStatus, NewsletterDraftSection } from './types';
import { operators, Actions } from './actions';

export type State = Readonly<{
	id: string | null;
	timestamp: string | null;
	status: NewsletterDraftStatus;
	channels: NewsletterDraftChannels | null;
	previewHtml: string | null;
	updatedSectionsHtml: Partial<{ [index in NewsletterDraftSection]: string }>
}>;

export const INITIAL_STATE: State = {
	id: null,
	timestamp: null,
	status: 'loading',
	channels: null,
	previewHtml: null,
	updatedSectionsHtml: {}
};

const reducerHandlers: ReducerHandlers<State> = {
	fetchContent: (state, { payload: { id, timestamp } }: Actions["FetchContent"]): State => ({
		...state,
		id,
		timestamp,
		status: 'loading',
		channels: null,
		previewHtml: null
	}),
	fetchContentSuccess: (state, { payload: { channels, previewHtml } }: Actions["FetchContentSuccess"]): State => ({
		...state,
		status: 'ready',
		channels,
		previewHtml
	}),
	fetchContentError: (state): State => ({
		...state,
		status: 'error'
	}),

	updateSection: (state): State => ({ ...state, status: 'updating' }),
	updateSectionSuccess: (state, { payload: { section, sectionChannel, sectionHtml } }: Actions["UpdateSectionSuccess"]): State => ({
		...state,
		status: 'ready',
		channels: { ...state.channels!, [section]: sectionChannel },
		updatedSectionsHtml: { ...state.updatedSectionsHtml, [section]: sectionHtml }
	}),
	updateSectionError: (state): State => ({
		...state,
		status: 'error'
	}),

	updateCustomImageSection: (state): State => ({ ...state, status: 'updating' }),
	updateCustomImageSectionSuccess: (state, { payload: { section, sectionChannel, sectionHtml } }: Actions["UpdateCustomImageSectionSuccess"]): State => ({
		...state,
		status: 'ready',
		channels: { ...state.channels!, [section]: sectionChannel },
		updatedSectionsHtml: { ...state.updatedSectionsHtml, [section]: sectionHtml }
	}),
	updateCustomImageSectionError: (state): State => ({
		...state,
		status: 'error'
	}),
	setStatus: (state, { payload: status }: Actions['SetStatus']): State => ({ ...state, status })
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
