import ActionCreators from 'lib/store/actions';
import { NewsletterDraftChannels, NewsletterDraftStatus, NewsletterDraftSection, NewsletterDraftChannel } from './types';

const actions = new ActionCreators('@@newsletter/draft/edit');

const [fetchContent, fetchContentSuccess, fetchContentError] = actions.apiOperators<
	{ id: string, timestamp: string },
	{ previewHtml: string, channels: NewsletterDraftChannels }
>('FETCH_CONTENT');

const [updateSection, updateSectionSuccess, updateSectionError] = actions.apiOperators<
	{ section: NewsletterDraftSection, sectionChannel: NewsletterDraftChannel },
	{ section: NewsletterDraftSection, sectionHtml: string, sectionChannel: NewsletterDraftChannel }
>('UPDATE_SECTION');

const [updateCustomImageSection, updateCustomImageSectionSuccess, updateCustomImageSectionError] = actions.apiOperators<
	{ section: NewsletterDraftSection, sectionChannel: NewsletterDraftChannel, documentId: string, imageFile: File },
	{ section: NewsletterDraftSection, sectionHtml: string, sectionChannel: NewsletterDraftChannel }
>('UPDATE_CUSTOM_IMAGE_SECTION');

const setStatus = actions.operator<NewsletterDraftStatus>('SET_STATUS');

export type Actions = {
	FetchContent: ReturnType<typeof fetchContent>;
	FetchContentSuccess: ReturnType<typeof fetchContentSuccess>;
	UpdateSection: ReturnType<typeof updateSection>;
	UpdateSectionSuccess: ReturnType<typeof updateSectionSuccess>;
	UpdateCustomImageSection: ReturnType<typeof updateCustomImageSection>;
	UpdateCustomImageSectionSuccess: ReturnType<typeof updateCustomImageSectionSuccess>;
	SetStatus: ReturnType<typeof setStatus>;
};

export const operators = {
	fetchContent, fetchContentSuccess, fetchContentError,
	updateSection, updateSectionSuccess, updateSectionError,
	updateCustomImageSection, updateCustomImageSectionSuccess, updateCustomImageSectionError,
	setStatus
};
