import React from 'react';
import SelectField from 'react-md/lib/SelectFields/SelectField';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import { Tenant } from "class/Tenant";

import './FocusFeedForm.scss';
import featureFlags from 'lib/featureFlags';

type ComponentState = {
	dateRangeAnimating: boolean;
};

class FocusFeedForm extends React.Component<ComponentProps, ComponentState> {

	constructor(props: ComponentProps) {
		super(props);
		this.state = { dateRangeAnimating: false };
	}

	private _getSortSelectField = () => {
		const { t, feedType, sort, tenant: tenantObject } = this.props;
		const { onSetSort } = this.props;

		const audienceSort = new Tenant(tenantObject).getAudienceFieldSort() + ':desc';

		let menuItems;
		if (feedType === 'print') {
			menuItems = [
				{ label: t('results.sort.audience'), value: 'photo.section.issue.circulation:desc', key: 'photo.section.issue.circulation:desc' },
				{ label: t('results.sort.publication_date'), value: 'publication_date:desc', key: 'photo.section.issue.date:desc' },
				{ label: t('results.sort.echo'), value: 'echo:desc', key: 'echo:desc' },
				{ label: t('insert_article.modal.form.miv'), value: 'valorization:desc', key: 'valorization:desc' }
			];
		}
		else if (feedType === 'print_dmr') {
			menuItems = [
				{ label: t('results.sort.audience'), value: 'print.source.circulation:desc', key: 'print.source.circulation:desc' },
				{ label: t('results.sort.publication_date'), value: 'publication_date:desc', key: 'published_at:desc' },
				{ label: t('insert_article.modal.form.miv'), value: 'miv.value:desc', key: 'miv.value:desc' }
			];
		}
		else {
			menuItems = [
				{ label: t('results.sort.audience'), value: audienceSort, key: audienceSort },
				{ label: t('results.sort.publication_date'), value: 'publication_date:desc', key: 'publication_date:desc' },
				{ label: t('results.sort.echo'), value: 'echo:desc', key: 'echo:desc' },
				{ label: t('insert_article.modal.form.miv'), value: 'miv:desc', key: 'miv:desc' }
			];
		}

		if (featureFlags.isEnabled('no-miv')) {
			menuItems = menuItems.filter(item => item.label !== t('insert_article.modal.form.miv'));
		}

		return <SelectField
			id="focusFeedSortBySelectField"
			label={t('results.sort.value')}
			repositionOnResize
			menuItems={menuItems}
			onChange={value => onSetSort(value.toString())}
			defaultValue={sort}
			value={sort}
		/>;
	};

	private _getPrivateDataLabel = () => {
		const { t, haveMentions, feedType } = this.props;
		if (feedType !== 'socialmedia' || !haveMentions) return '';
		return (
			<div>
				<span className='private-data-warning'>{t('definition.feed.form.info.no_private_data')}</span>
			</div>
		);
	};

	private _getDisplayingCounterContent = () => {
		const { t, total, loadingDocuments, start, limit } = this.props;

		if (loadingDocuments || !total) return null;
		return (
			<div className="form-display-items">
				<div className="form-results-display-label">{t('results.search.documents.pagination_label')}</div>
				<div className="form-results-display-content">{t('results.search.documents.pagination_info', {
					start: start + 1,
					end: total < limit ? total : start + limit,
					count: total > 5000 ? 5000 : total
				})}</div>
			</div>
		);
	};

	public render() {
		const { feedType, brand_sync } = this.props;

		if (!feedType) return null;

		return (
			<div id="focusFeedForm" className={`${brand_sync && 'disabled'}`}>
				{this._getPrivateDataLabel()}
				<div className="flex-between">
					{this._getSortSelectField()}
					{this._getDisplayingCounterContent()}
				</div>
			</div>
		);
	}
}

export default withT(FocusFeedForm);
