import React from 'react';
import { SortIcon } from './SortIcon';
import { TableRow } from './TableRow';
import { TableCell } from './TableCell';
import { TableBody } from './TableBody';
import { TableHeader } from './TableHeader';
import { TableHeaderCell } from './TableHeaderCell';
import { ColumnDefinitionTypeBase, ColumnTypes, HelpAlignment, SortableColumn, TableProps } from './types';

export const DiscoverTable = <TDisplayData, TRealData extends object>({
	data,
	columns,
	map,
	hasHeader = true,
	rowStyle = [],
	tableStyle = [],
	headerStyle = []
}: TableProps<TDisplayData, TRealData>) => {

	function getCellForColumn(
		column: ColumnTypes<TDisplayData, TRealData>,
		data: TRealData
	): JSX.Element {
		const displayData = map ? map(data) : ((data as unknown) as TDisplayData);
		switch (column.type) {
			case "text":
				return getCell(column, displayData[column.key]);
			case "custom":
				return getCell(column, column.render(data));
			default:
				break;
		}
		return <TableCell key={"default cell"}></TableCell>;
	}

	function getHeaderCellForColumn(
		column: ColumnTypes<TDisplayData, TRealData>
	) {
		return column.sort ?
			getHeaderCellWithSort(column as SortableColumn<typeof column, TRealData>) :
			getHeaderCell(column, column.displayName);
	}

	function getCell(column: ColumnTypes<TDisplayData, TRealData>, data: any, cellKey?: string) {
		return (
			<TableCell
				cellClasses={column.styles}
				key={cellKey ? cellKey : `${column.key}`}
				alignVertically={column.alignVertically ? column.alignVertically : 'top'}
				alignHorizontally={column.alignHorizontally ? column.alignHorizontally : 'left'}
			>
				{data}
			</TableCell>);
	}

	function getHeaderCellWithSort<T extends ColumnDefinitionTypeBase<TDisplayData>>(
		column: SortableColumn<T, TRealData>
	): JSX.Element {
		return getHeaderCell(
			column as ColumnTypes<TDisplayData, TRealData>,
			<div style={{ display: "flex", flexDirection: "row" }}>
				{column.displayName}
				<span style={{ marginLeft: "0.5rem" }}>
					<SortIcon initialValue={column.initialValue} onClick={column.onClick} />
				</span>
			</div>
		);
	}

	function getHeaderCell(column: ColumnTypes<TDisplayData, TRealData>, data: any) {
		const tooltipStyle = column.help ? column.help.tooltipStyles : [];
		const text = column.help ? column.help.text : '';
		const helpAlignment: HelpAlignment = column.help ? column.help.helpAlignment : 'none';
		const tooltipPosition = column.help && column.help.tooltipPosition ? column.help.tooltipPosition : 'right';
		return (
			<TableHeaderCell
				helpText={text}
				cellClasses={column.styles}
				tooltipStyles={tooltipStyle}
				tooltipPosition={tooltipPosition}
				key={`table-header-key-for-${column.displayName}`}
				helpAlignment={helpAlignment ? helpAlignment : 'none'}
				alignVertically={column.alignVertically ? column.alignVertically : 'top'}
				alignHorizontally={column.alignHorizontally ? column.alignHorizontally : 'left'}
			>
				{data}
			</TableHeaderCell>
		);
	}

	return (
		<table className={`${tableStyle.join(' ')}`} style={{
			width: "100%"
		}}>
			{hasHeader &&
				<TableHeader key={"Discover table header"}
					headerClasses={headerStyle}
				>
					{
						columns.map((column, index) =>
							getHeaderCellForColumn(column)
						)
					}
				</TableHeader>
			}
			<TableBody key={"Discover table body"}>
				{data.map((row, index) => {
					const cells = [];
					for (const column of columns) {
						cells.push(getCellForColumn(column, row));
					}
					return (
						<TableRow key={`discover-table-row-id-${index}`} rowClasses={rowStyle}>{cells}</TableRow>
					);
				})}
			</TableBody>
		</table>
	);
};
