import { combineReducers } from 'redux';

import * as newsletter from './newsletter';
import * as report from './report';
import * as topic from './topic';
import * as audit from './audit';
import * as urlQuery from './urlQuery';

export const reducers = combineReducers({
	newsletter: newsletter.reducers,
	report: report.reducers,
	topic: topic.reducers,
	audit: audit.reducers,
	urlQuery: urlQuery.reducers
});

export interface State {
	newsletter: newsletter.State;
	report: report.State;
	topic: topic.State;
	audit: audit.State;
	urlQuery: urlQuery.State;
}

export const INITIAL_STATE = {
	newsletter: newsletter.INITIAL_STATE,
	report: report.INITIAL_STATE,
	topic: topic.INITIAL_STATE,
	audit: audit.INITIAL_STATE,
	urlQuery: urlQuery.INITIAL_STATE
};

