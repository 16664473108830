import React from 'react';
import { Button, Paper } from 'lib/imports/react-md';
import { transform } from 'lib/imports/lodash';
import { FacetDmrCategories } from 'class/Facet';

import { TProps, withT } from "lib/i18n";

import './CategorizationCategoryChip.scss';

export type ComponentProps = TProps & {
	categoryId: string
	isRemovable: boolean
	dmrCategories?: FacetDmrCategories,
	getCategoryName(id: string): string | undefined,
	onCategoryRemoved?: () => void
};

const CategorizationCategoryChip = (props: ComponentProps) => {
	const { t, categoryId, isRemovable, dmrCategories = {} } = props;
	const { onCategoryRemoved, getCategoryName } = props;

	const [finalLevelCategoryName, allLevelCategoryNames] = React.useMemo(() => {
		if (categoryId.includes('-')) {
			const split = categoryId.split('-');
			const allLevel = `${getCategoryName(split[0])} > ${getCategoryName(`${split[0]}-${split[1]}`)}`;
			return [dmrCategories[categoryId], allLevel];
		}

		const categoryName = t(`filters.categories.${categoryId}`);

		const parentsNames = transform([2, 4], (result, sliceLength) => {
			if (categoryId.length <= sliceLength) return;
			result.push(t(`filters.categories.${categoryId.slice(0, sliceLength)}`));
		}, []).join(' > ');

		return [categoryName, parentsNames];
	}, [categoryId, dmrCategories, getCategoryName, t]);

	return <Paper
		className="categorization-category-chip"
		zDepth={1}
	>
		<div className="categorization-category-chip-text">
			<span>{allLevelCategoryNames}</span>
			<span>{finalLevelCategoryName}</span>
		</div>
		{isRemovable && <Button
			className="categorization-category-chip-remove-btn"
			flat
			iconChildren="close"
			children=""
			onClick={onCategoryRemoved}
		/>}
	</Paper>;
};

export default withT(React.memo(CategorizationCategoryChip));
