import ActionCreator from 'lib/store/actions';

const actionCreator = new ActionCreator('@@fletcher');

const [fetchAuthStart, fetchAuthSuccess, fetchAuthError] = actionCreator.apiOperators('FETCH_AUTH');
const [fetchFocusStart, fetchFocusSuccess, fetchFocusError] = actionCreator.apiOperators('FETCH_FOCUS');
const [fetchNewslettersStart, fetchNewslettersSuccess, fetchNewslettersError] = actionCreator.apiOperators('FETCH_NEWSLETTERS');
const [fetchTopicsStart, fetchTopicsSuccess, fetchTopicsError] = actionCreator.apiOperators('FETCH_TOPICS');
const [fetchDmrCategoriesStart, fetchDmrCategoriesSuccess, fetchDmrCategoriesError] = actionCreator.apiOperators('FETCH_DMR_CATEGORIES');
const [fetchEventsStart, fetchEventsSuccess, fetchEventsError] = actionCreator.apiOperators('FETCH_EVENTS');
const [fetchBrandsStart, fetchBrandsSuccess, fetchBrandsError] = actionCreator.apiOperators('FETCH_BRANDS');
const [fetchLanguagesStart, fetchLanguagesSuccess, fetchLanguagesError] = actionCreator.apiOperators('FETCH_LANGUAGES');
const [fetchCountriesStart, fetchCountriesSuccess, fetchCountriesError] = actionCreator.apiOperators('FETCH_COUNTRIES');

export const operators = {
	fetchAuthStart, fetchAuthSuccess, fetchAuthError,
	fetchFocusStart, fetchFocusSuccess, fetchFocusError,
	fetchNewslettersStart, fetchNewslettersSuccess, fetchNewslettersError,
	fetchTopicsStart, fetchTopicsSuccess, fetchTopicsError,
	fetchDmrCategoriesStart, fetchDmrCategoriesSuccess, fetchDmrCategoriesError,
	fetchEventsStart, fetchEventsSuccess, fetchEventsError,
	fetchBrandsStart, fetchBrandsSuccess, fetchBrandsError,
	fetchLanguagesStart, fetchLanguagesSuccess, fetchLanguagesError,
	fetchCountriesStart, fetchCountriesSuccess, fetchCountriesError
};
