import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import FocusList from 'components/Focus/List';
import FocusFeed from 'components/Focus/Feed';
import FocusDashboard from 'components/Focus/Dashboard';
import { User } from 'class/User';
import { ComponentProps } from './types';

const guidExpr = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';
const feedTypeExpr = 'online|socialmedia|print|print_dmr';

const Focus = ({ user: userObject }: ComponentProps) => {
	const user = React.useMemo(() => new User(userObject!), [userObject]);
	if (!user.hasPermission('focus.view')) return <Redirect to={{ pathname: '/' }} />;

	return <>
		<Switch>
			<Route
				path={`/focus/:focusId(${guidExpr})/feed/create/:type(${feedTypeExpr})`}
				render={({ match }) => <FocusFeed urlFocusId={match.params.focusId} urlFeedType={match.params.type} isCreatePage={true} />}
			/>
			<Route
				path={`/focus/:focusId(${guidExpr})/feed/:feedId(${guidExpr})`}
				render={({ match }) => <FocusFeed urlFocusId={match.params.focusId} urlFeedId={match.params.feedId} isCreatePage={false} />}
			/>
			<Route
				path={`/focus/:focusId(${guidExpr})`} exact
				render={({ match }) => <FocusDashboard urlFocusId={match.params.focusId} />}
			/>
			<Route path={`/focus/:focusId(${guidExpr})`} render={({ match }) => (<Redirect to={`/focus/${match.params.focusId}`} />)} /> {/* Fallback */}
			<Route path="/focus" exact component={FocusList} />
			<Route path="/focus" render={() => (<Redirect to="/focus" />)} /> {/* Fallback */}
		</Switch>
	</>;
};

export default Focus;
