import { Document, PreparedDocument } from "class/Document";
import format from "lib/format";
import i18n from "lib/i18n";
import ForcedIcon from 'vendor/png/document/youtube.png';

export class YoutubeDocument extends Document {
	public prepare(): PreparedDocument {
		const counters = {
			audience: {
				subscribers: this.author ? (this.author.subscriber_count || 0) : 0
			},
			reach: {
				subscribers: this.author ? (this.author.subscriber_count || 0) : 0
			},
			echo: {
				likes: this.social ? (this.social.likes || 0) : 0,
				replies: this.social ? (this.social.replies || 0) : 0
			}
		};
		return new PreparedDocument({
			id: this.id,
			provider: this.provider,
			providerName: 'YouTube',
			link: this.link,
			icon: "icon-youtube",
			iconForced: ForcedIcon,
			categories_id: this.category || "",
			title: this.title,
			content: this.content,
			date: format.date.doc(this.date_from_provider),
			dateTitle: format.date.localeTitle(this.date_from_provider),
			country: "",
			author_name: this.author ? (this.author.name || '') : '',
			author_icon: "",
			author_link: this.author ? this.author.url : undefined,
			image: this.image,
			counters: {
				reach: {
					value: format.number.counter(counters, 'audience'),
					title: format.text.counter(counters, 'audience')
				},
				engagement: {
					value: format.number.counter(counters, 'echo'),
					title: format.text.counter(counters, 'echo')
				},
				views: {
					value: format.number.metric(this.social ? this.social.views : 0),
					title: i18n.t('result.counters.views') + `: ${this.social ? this.social.views : 0}`
				},
				echo: {
					value: format.number.currency(this.rank, this.date_from_provider),
					title: format.number.currency(this.rank, this.date_from_provider, 'locale', true)
				},
				branded_miv: this.branded_miv ? this.branded_miv.map(brand => ({
					id: brand.id,
					value: format.number.brandedCurrency(brand.value, this.date_from_provider),
					title: format.number.brandedCurrency(brand.value, this.date_from_provider, 'locale', true)
				})) : []
			}
		});
	}
}
