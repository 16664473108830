import React from 'react';
import queryString from 'query-string';
import { withT } from 'lib/i18n';

import GA from 'lib/googleAnalytics';
import SearchResults from 'components/Search/Results';
import SearchPaginator from 'components/Search/Paginator';
import ArticleActions from './Actions';
import { ComponentProps } from './types';

import './Article.scss';

class Article extends React.Component<ComponentProps> {

	public componentDidMount() {
		this.props.onSetFormAndFilters(queryString.parse(this.props.location!.search));
		GA.trackPage('/article');
	}

	public render() {
		return (
			<div id="article">
				<div className="main-drawer">
					<ArticleActions />
					<SearchResults />
					<SearchPaginator />
				</div>
			</div>
		);
	}
}

export default withT(Article);
