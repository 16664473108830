import { Reducer } from 'redux';
import { Action, ActionCreator, isType } from 'typescript-fsa';
import { ModelType } from 'redux-orm';
import { AnyModel } from 'redux-orm/Model';
import { OrmSession } from 'redux-orm/Session';
import { Session } from 'store/entities/orm';
import { isArray, transform } from 'lib/imports/lodash';
import { CrudActions } from './actions';

export type Operators = { [index: string]: ActionCreator<any> };

// standar reducer object handlers
export type ReducerHandler<State> = (state: State, action: Action<any>) => State;
export type ReducerHandlers<State> = { [index: string]: ReducerHandler<State> };

export function createReducer<State>(INITIAL_STATE: State, reducerHandlers: ReducerHandlers<State>, operators: Operators): Reducer<State, Action<any>> {

	const operationTypes: { [index: string]: string } = transform(reducerHandlers, (result, reducer, operatorName) => {
		if (!operators[operatorName]) console.error(`Reducer for operator ${operatorName} not found in action operators`);
		// @ts-ignore
		else result[operators[operatorName].type] = operatorName;
	}, {});

	return (state: State = INITIAL_STATE, action: Action<any>): State => {
		const actionType = action.type;
		const operatorName = operationTypes[actionType];
		if (operatorName) {
			if (isType(action, operators[operatorName])) return reducerHandlers[operatorName](state, action);
			else console.warn(`Reducer type matched (${actionType} - ${operatorName}) but operator isType failed`);
		}
		return state;
	};
}

// orm class reducer function => object handlers
export type ReducerOrm<M extends ModelType<any>> = (action: Action<any>, model: M, session: OrmSession<any>) => void;
export type ReducerOrmHandler = (action: Action<any>) => void;
export type ReducerOrmHandlers<M extends ModelType<any>> = (model: M, session: Session) => { [index: string]: ReducerOrmHandler };

export function createOrmReducer<M extends ModelType<any>>(operators: Operators, reducerOrmHandlers?: ReducerOrmHandlers<M>): ReducerOrm<M> {

	const operatorNamesByActionType: { [index: string]: string } = transform(operators, (result, operator, operatorName) => {
		// @ts-ignore
		result[operators[operatorName].type] = operatorName;
	}, {});

	return (action, model, session) => {
		const operatorName = operatorNamesByActionType[action.type];
		if (operatorName) { // operator for this entity found. Call function
			const reducerHandlers = reducerOrmHandlers ? reducerOrmHandlers(model, session as unknown as Session) : {};
			if (reducerHandlers[operatorName]) reducerHandlers[operatorName](action); // execute class reducers
			else if (crudRecuderHandlers[operatorName]) crudRecuderHandlers[operatorName](action, model); // execute generic crud reducers
		}
	};
}

// default crud orm reducer handlers
type ReducerCrudOrmHandler<M extends ModelType<AnyModel>> = (action: Action<any>, model: M) => void;
type Actions = CrudActions<AnyModel>;
const crudRecuderHandlers: { [index: string]: ReducerCrudOrmHandler<ModelType<AnyModel>> } = {
	create: ({ payload: modelData }: Actions['create'], Model: ModelType<AnyModel>) => {
		if (isArray(modelData)) modelData.forEach(singleModelData => {
			if (Model.idExists(singleModelData.id)) console.warn(Model.modelName, '<create[bulk]> id already exists:', singleModelData.id);
			else Model.create(singleModelData);
		});
		else {
			if (Model.idExists(modelData.id)) console.warn(Model.modelName, '<create> id already exists:', modelData.id);
			else Model.create(modelData);
		}
	},
	replace: ({ payload: modelData }: Actions['replace'], Model: ModelType<AnyModel>) => {
		if (isArray(modelData)) modelData.forEach(singleModelData => Model.upsert(singleModelData));
		else Model.upsert(modelData);
	},
	update: ({ payload: { id, ...modelData } }: Actions['update'], Model: ModelType<AnyModel>) => {
		const model = Model.withId(id);
		if (!model) console.warn(Model.modelName, '<update> id not found:', id);
		else model.update(modelData);
	},
	delete: ({ payload: id }: Actions['delete'], Model: ModelType<AnyModel>) => {
		const model = Model.withId(id);
		if (model) model.delete();
	}
};
