import React from 'react';
import { withT } from 'lib/i18n';
import Button from 'react-md/lib/Buttons/Button';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import SelectionControl from 'react-md/lib/SelectionControls/SelectionControl';
import Collapse from 'react-md/lib/Helpers/Collapse';

import { isEmpty, size, cloneDeep, remove } from 'lib/imports/lodash';
import GA from 'lib/googleAnalytics';

import { ComponentProps } from './types';

import './ArticleActions.scss';
import Categorization from 'components/common/Categorization';

type ComponentState = {
	removeAction: boolean,
	tagAction: boolean,
	tags: {
		tagIds: string[],
		newTagNames: string[]
	},
	category: string
};

class ArticleActions extends React.Component<ComponentProps, ComponentState> {
	constructor(props: ComponentProps) {
		super(props);
		this.state = {
			removeAction: false,
			tagAction: false,
			tags: { tagIds: [], newTagNames: [] },
			category: ""
		};
	}

	componentDidUpdate(prevProps: ComponentProps) {
		if (prevProps.showTagActionPanel !== this.props.showTagActionPanel) {
			this.setState({ removeAction: false, tagAction: false });
			this.setState({ tags: { newTagNames: [], tagIds: [] }});
		}
	}

	private _removeTag(tag: string) {
		const tagsCopy = cloneDeep(this.state.tags);
		remove(tagsCopy.newTagNames, tagName => tagName === tag);
		remove(tagsCopy.tagIds, tagId => tagId === tag);
		this.setState({ tags: tagsCopy });
	}

	private removeActionContainer() {
		const { documentsChecked, onToggleTagActionPanel, t } = this.props;
		const { onRemoveDocumentBulk } = this.props;

		return (<div className="confirm-action-content" id="removeActionContent">
			<div className="confirm-message confirm-message-remove">{t('results.bulk_action.remove.confirm.question')}</div>
			<div className="confirm-buttons">
				<Button
					raised
					disabled={!size(documentsChecked)}
					className="confirm-accept"
					onClick={() => {
						const trackAction = size(documentsChecked) > 1 ? 'Remove multiple mentions' : 'Remove mention';
						GA.trackEvent({ category: 'Feed results', action: trackAction });
						onRemoveDocumentBulk();

						onToggleTagActionPanel();
						this.setState({ removeAction: false, tagAction: false });
					}}
				>{t('results.bulk_action.remove.confirm.yes')}</Button>
				<span className="confirm-conjuntion">{t('results.bulk_action.remove.confirm.or')}</span>
				<span className="confirm-cancel" onClick={() => { this.setState({ removeAction: false }); }}>{t('results.bulk_action.remove.confirm.cancel')}</span>
			</div>
		</div>);
	};

	private tagActionContainer() {
		const { toggleAutomateTags, documentsChecked, suggestedTags, t } = this.props;
		const { onBulkTag, onToggleTagActionPanel, onAddNotification, onToggleAutomateTags, onSetTags, onAutomateTags } = this.props;

		const collapseAndClear = () => {
			onToggleTagActionPanel();
			this.setState({ removeAction: false, tagAction: false });
			this.setState({ tags: { newTagNames: [], tagIds: [] }, category: "" });
		};

		return (<div className="confirm-action-content" id="tagActionContent">
			<Categorization
				hideOldCategories={true}
				hideNewCategories={true}
				tags={this.state.tags}
				onAddTags={(tagIds: string[], newTagNames: string[]) => {
					this.setState({
						tags: {
							newTagNames: [...this.state.tags.newTagNames, ...newTagNames],
							tagIds: [...this.state.tags.tagIds, ...tagIds]
						}
					});
					onToggleAutomateTags();
				}}
				onRemoveTag={tag => this._removeTag(tag)}
				onSetCategory={category => this.setState({ category })}
			/>
			<div className="confirm-buttons">
				<Button
					raised
					disabled={(isEmpty(this.state.tags.tagIds) && isEmpty(this.state.tags.newTagNames) && isEmpty(suggestedTags)) || !size(documentsChecked)}
					className="confirm-accept confirm-primary"
					onClick={() => {
						if (!isEmpty(this.state.tags.tagIds) || !isEmpty(this.state.tags.newTagNames)) GA.trackEvent({ category: 'Feed results', action: 'Categorize mentions', label: 'Add tag' });
						onSetTags(this.state.tags.tagIds, this.state.tags.newTagNames);
						collapseAndClear();
					}}>
					<FontIcon>label</FontIcon>
					{t('results.bulk_action.tag.confirm')}
				</Button>
				<Button
					raised
					className={'bulk-all-tags'}
					disabled={isEmpty(this.state.tags.tagIds) && isEmpty(this.state.tags.newTagNames) && isEmpty(suggestedTags)}
					onClick={() => {
						onBulkTag(this.state.tags.tagIds, this.state.tags.newTagNames);
						collapseAndClear();
						onAddNotification({ level: 'info', text: t('results.bulk_action.tag_all.notification') });
					}}>
					{t('results.bulk_action.tag_all.title')}
				</Button>
				<span className="confirm-cancel" onClick={() => { this.setState({ tagAction: false }); }}>{t('results.bulk_action.remove.confirm.cancel')}</span>
				<Button
					raised
					disabled={!toggleAutomateTags || (isEmpty(this.state.tags.tagIds) && isEmpty(this.state.tags.newTagNames) && isEmpty(suggestedTags))}
					className="document-actions-categorization confirm-primary confirm-automate automate-tags-button"
					onClick={() => {
						onAutomateTags(this.state.tags.tagIds, this.state.tags.newTagNames);
					}}>
					<FontIcon>bolt</FontIcon>
					{t('results.bulk_action.remove.confirm.automate_tag')}
				</Button>
			</div>
		</div>);
	};

	private articleActions() {
		const { documentsChecked, documentSources, t } = this.props;
		const { onToggleAllDocuments } = this.props;

		return (<div id="articleActions">
			<div id="actionsButtons">
				<SelectionControl
					id={`documentSelectAll`}
					name="documentSelectAll"
					aria-label="documentSelectAll"
					type="checkbox"
					onChange={() => { onToggleAllDocuments(size(documentsChecked) !== size(documentSources)); }}
					checked={size(documentsChecked) === size(documentSources)}
					className={`${!!size(documentsChecked) ? "checkbox-green" : ""} document-actions-select-checkbox`}
					uncheckedCheckboxIcon={!!size(documentsChecked) ? <FontIcon>indeterminate_check_box</FontIcon> : <FontIcon>check_box_outline_blank</FontIcon>}
				/>
				<Button
					icon
					className={`${this.state.removeAction ? "action-selected" : ""} document-actions-remove`}
					onClick={() => { this.setState({ removeAction: true, tagAction: false }); }}
				>delete</Button>
				<div className="separator" />
				<Button
					icon
					className={`${this.state.tagAction ? "action-selected" : ""} document-actions-categorization`}
					onClick={() => { this.setState({ tagAction: true, removeAction: false }); }}
				>sell</Button>

				<span>{size(documentsChecked)} {t('results.bulk_selection.articles_count')}</span>
			</div>
			<div id="actionsConfirm">
				<Collapse collapsed={!this.state.removeAction && !this.state.tagAction}>
					{this.state.removeAction ? this.removeActionContainer() : this.tagActionContainer()}
				</Collapse>
			</div>
		</div>
		);
	};

	public render() {
		const { showTagActionPanel } = this.props;

		return (
			<Collapse collapsed={!showTagActionPanel}>{this.articleActions()}</Collapse>
		);
	}
}

export default withT(ArticleActions);
