import { Action, actionCreatorFactory } from 'typescript-fsa';
import {
	ScopeSocial, SocialExpressionsType, ProfileExpressionsType, DefinitionSocialInstagramAccount, SocialThreshold, ErrorProfile,
	ExpressionsType, DefinitionSocial, SocialExpressionValues, ProfileExpressionValues
} from 'class/Feed';

const actionCreator = actionCreatorFactory('@@focus/feed/social');

type Payloads = {
	SetSocialDefinition: { definition: DefinitionSocial };
	SetSocialExpression: { expressionKey: ExpressionsType | 'main', index: number, values: SocialExpressionValues };
	SetProfileExpression: { expressionKey: ProfileExpressionsType, index: number, values: ProfileExpressionValues };

	// TODO GIS-4501 REMOVE UNUSED PAYLOADS, ACTIONS, OPERATORS
	ClearErrorProfiles: void;
	ChangeMainQuery: { q: string };
	ChangeMainScope: { scope: ScopeSocial[] };
	ToggleEnableMainQuery: { enabled: boolean };
	SetMainExpressionError: { hasError: boolean };
	ChangeQuery: { q: string, type: SocialExpressionsType, index: number };
	ChangeQueryProfile: { url: string, type: ProfileExpressionsType, index: number };
	ChangeScope: { scope: ScopeSocial[], type: SocialExpressionsType, index: number };
	ToggleEnableQuery: { enabled: boolean, type: SocialExpressionsType, index: number };
	AddExpression: { type: SocialExpressionsType };
	AddProfileExpression: { type: ProfileExpressionsType };
	AddMultipleProfileExpressions: { type: ProfileExpressionsType, url: string };
	SetExpressionError: { hasError: boolean, type: SocialExpressionsType, index: number };
	RemoveAllProfileExpressions: { type: SocialExpressionsType };
	RemoveExpression: { type: SocialExpressionsType, index: number };
	RemoveProfileExpression: { type: ProfileExpressionsType, index: number };
	SearchMultipleProfiles: { urls: string[], type: ProfileExpressionsType };
	SearchMultipleProfilesError: { errorProfiles: ErrorProfile[], type: ProfileExpressionsType };
	SearchProfile: { url: string, type: ProfileExpressionsType, index: number };
	SearchProfileSuccess: { profile: object, type: ProfileExpressionsType, index: number };
	SearchProfileError: { error: object | string, type: ProfileExpressionsType, index: number };
	ToggleDefinitionInstagramAccount: { account: DefinitionSocialInstagramAccount };
	SetDefinitionInstagramAccounts: { definitionInstagramAccounts: DefinitionSocialInstagramAccount[] };
	SetThreshold: { channel: number, threshold: SocialThreshold };
}

export type Actions = {
	SetSocialDefinition: Action<Payloads["SetSocialDefinition"]>
	SetSocialExpression: Action<Payloads["SetSocialExpression"]>
	SetProfileExpression: Action<Payloads["SetProfileExpression"]>


	ClearErrorProfiles: Action<Payloads["ClearErrorProfiles"]>;
	ChangeMainQuery: Action<Payloads["ChangeMainQuery"]>;
	ChangeMainScope: Action<Payloads["ChangeMainScope"]>;
	ToggleEnableMainQuery: Action<Payloads["ToggleEnableMainQuery"]>;
	SetMainExpressionError: Action<Payloads["SetMainExpressionError"]>;
	ChangeQuery: Action<Payloads["ChangeQuery"]>;
	ChangeQueryProfile: Action<Payloads["ChangeQueryProfile"]>;
	ChangeScope: Action<Payloads["ChangeScope"]>;
	ToggleEnableQuery: Action<Payloads["ToggleEnableQuery"]>;
	AddExpression: Action<Payloads["AddExpression"]>;
	AddProfileExpression: Action<Payloads["AddProfileExpression"]>;
	AddMultipleProfileExpressions: Action<Payloads["AddMultipleProfileExpressions"]>;
	SetExpressionError: Action<Payloads["SetExpressionError"]>;
	RemoveAllProfileExpressions: Action<Payloads["RemoveAllProfileExpressions"]>;
	RemoveExpression: Action<Payloads["RemoveExpression"]>;
	RemoveProfileExpression: Action<Payloads["RemoveProfileExpression"]>;
	SearchMultipleProfiles: Action<Payloads["SearchMultipleProfiles"]>;
	SearchMultipleProfilesError: Action<Payloads["SearchMultipleProfilesError"]>;
	SearchProfile: Action<Payloads["SearchProfile"]>;
	SearchProfileSuccess: Action<Payloads["SearchProfileSuccess"]>;
	SearchProfileError: Action<Payloads["SearchProfileError"]>;
	ToggleDefinitionInstagramAccount: Action<Payloads["ToggleDefinitionInstagramAccount"]>;
	SetDefinitionInstagramAccounts: Action<Payloads["SetDefinitionInstagramAccounts"]>;
	SetThreshold: Action<Payloads["SetThreshold"]>;
}

export const operators = {
	setSocialDefinition: actionCreator<Payloads["SetSocialDefinition"]>('SET_SOCIAL_DEFINITION'),
	setSocialExpression: actionCreator<Payloads["SetSocialExpression"]>('SET_EXPRESSION'),
	setProfileExpression: actionCreator<Payloads["SetProfileExpression"]>('SET_PROFILE_EXPRESSION'),


	clearErrorProfiles: actionCreator<Payloads["ClearErrorProfiles"]>("CLEAR_ERROR_PROFILES"),
	changeMainQuery: actionCreator<Payloads["ChangeMainQuery"]>('CHANGE_MAIN_QUERY'),
	changeMainScope: actionCreator<Payloads["ChangeMainScope"]>('CHANGE_MAIN_SCOPE'),
	toggleEnableMainQuery: actionCreator<Payloads["ToggleEnableMainQuery"]>('TOGGLE_ENABLE_MAIN_QUERY'),
	setMainExpressionError: actionCreator<Payloads["SetMainExpressionError"]>('SET_MAIN_EXPRESSION_ERROR'),
	changeQuery: actionCreator<Payloads["ChangeQuery"]>('CHANGE_QUERY'),
	changeQueryProfile: actionCreator<Payloads["ChangeQueryProfile"]>('CHANGE_QUERY_PROFILE'),
	changeScope: actionCreator<Payloads["ChangeScope"]>('CHANGE_SCOPE'),
	toggleEnableQuery: actionCreator<Payloads["ToggleEnableQuery"]>('TOGGLE_ENABLE_QUERY'),
	addExpression: actionCreator<Payloads["AddExpression"]>('ADD_EXPRESSION'),
	addProfileExpression: actionCreator<Payloads["AddProfileExpression"]>('ADD_PROFILE_EXPRESSION'),
	addMultipleProfileExpressions: actionCreator<Payloads["AddMultipleProfileExpressions"]>('ADD_MULTIPLE_PROFILE_EXPRESSION'),
	setExpressionError: actionCreator<Payloads["SetExpressionError"]>('SET_EXPRESSION_ERROR'),
	removeAllProfileExpressions: actionCreator<Payloads["RemoveAllProfileExpressions"]>('REMOVE_ALL_EXPRESSIONS'),
	removeExpression: actionCreator<Payloads["RemoveExpression"]>('REMOVE_EXPRESSION'),
	removeProfileExpression: actionCreator<Payloads["RemoveProfileExpression"]>('REMOVE_PROFILE_EXPRESSION'),
	searchMultipleProfiles: actionCreator<Payloads["SearchMultipleProfiles"]>('SEARCH_MULTIPLE_PROFILES'),
	searchMultipleProfilesError: actionCreator<Payloads["SearchMultipleProfilesError"]>('SEARCH_MULTIPLE_PROFILES_ERROR'),
	searchProfile: actionCreator<Payloads["SearchProfile"]>('SEARCH_PROFILE'),
	searchProfileSuccess: actionCreator<Payloads["SearchProfileSuccess"]>('SEARCH_PROFILE_SUCCESS'),
	searchProfileError: actionCreator<Payloads["SearchProfileError"]>('SEARCH_PROFILE_ERROR', { api: 'error' }),
	toggleDefinitionInstagramAccount: actionCreator<Payloads["ToggleDefinitionInstagramAccount"]>('TOGGLE_INSTAGRAM_ACCOUNT'),
	setDefinitionInstagramAccounts: actionCreator<Payloads["SetDefinitionInstagramAccounts"]>('SET_INSTAGRAM_ACCOUNTS'),
	setThreshold: actionCreator<Payloads["SetThreshold"]>('SET_THRESHOLD')
};
