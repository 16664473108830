import { cloneDeep } from "lib/imports/lodash";
import { FeedObject } from "store/entities/Feed";
import { FocusObject } from "store/entities/Focus";
import { LayoutSettings, TreeElement, TreeLayers } from "./Layout/types";

type FeedWithFocus = FeedObject & { focusId: string, focusName: string };

export default function generateDataTree(layoutData: TreeElement[], layoutSettings: LayoutSettings, feedIds: string[], focusList: FocusObject<'feeds'>[]) {

	const layers = getLayersArray(layoutSettings);
	const feedObjects: FeedWithFocus[] = [];
	for (const focus of focusList) {
		for (const feed of focus.feeds) {
			if (feedIds.includes(feed.id)) feedObjects.push({
				...feed,
				focusId: focus.id,
				focusName: focus.name
			});
		}
	}

	function addLayers(layoutData: TreeElement[], feed: FeedWithFocus, layerIdx: number = 0) {
		const layer = layers[layerIdx];
		if (!layer) return;

		const layerData = getLayerData(feed, layer);
		let dataLayer = layoutData.filter(element => element.id === layerData.id)[0];
		if (!dataLayer) {
			dataLayer = {
				type: layer,
				id: layerData.id,
				children: [],
				name: layerData.name!,
				subtype: layerData.subtype!
			};
			layoutData.push(dataLayer);
		}

		addLayers(dataLayer.children, feed, ++layerIdx);
	}

	const newLayoutData: TreeElement[] = [];
	const updatedFeeds = getUpdatedFeedList(layoutData, feedObjects, layers);
	updatedFeeds.forEach(feed => addLayers(newLayoutData, feed));

	return newLayoutData;
}

function getLayerData(feed: FeedWithFocus, layer: TreeLayers) {
	switch (layer) {
		case 'channel':
			return { id: getFeedType(feed) };
		case 'focus':
			return { id: feed.focusId, name: feed.focusName };
		case 'feed':
			return { id: feed.id, name: feed.name, subtype: getFeedType(feed) };
	}
}

function getLayersArray(layersSettings: LayoutSettings): TreeLayers[] {
	const layersArray: TreeLayers[] = [];
	for (let i = 0; i < Object.keys(layersSettings).length; i++) {
		let layerType: TreeLayers;
		for (layerType in layersSettings) {
			const layerObj = layersSettings[layerType];
			if (layerObj.order === i && layerObj.enabled) layersArray.push(layerType);
		}
	}
	return layersArray;
}

function getUpdatedFeedList(layoutData: TreeElement[], selectedFeeds: FeedWithFocus[], layers: TreeLayers[]) {
	const layoutDataWithFeeds = addFeedsToLayout(cloneDeep(layoutData), selectedFeeds);
	const layoutFeeds = extractOrderedFeeds(layoutDataWithFeeds, selectedFeeds);

	// Remove unselected feeds, add selected feeds
	const layoutSelectedFeeds = layoutFeeds.filter(layoutFeed => feedExists(selectedFeeds, layoutFeed));
	const selectedFeedsNotInLayout = selectedFeeds.filter(selectedFeed => !feedExists(layoutFeeds, selectedFeed));
	return [...layoutSelectedFeeds, ...selectedFeedsNotInLayout];
}

function feedExists(list: FeedWithFocus[], feed: FeedWithFocus): Boolean {
	return !!list.filter(feedInList => feedInList.id === feed.id).length;
}

function extractOrderedFeeds(layoutData: TreeElement[], feedObjects: FeedWithFocus[]) {
	const orderedFeeds: FeedWithFocus[] = [];
	for (const element of layoutData || []) {
		if (element.type === 'feed') {
			const existantFeeds = feedObjects.filter(feed => feed.id === element.id)[0];
			if (existantFeeds) orderedFeeds.push(existantFeeds);
		}
		else orderedFeeds.push(...extractOrderedFeeds(element.children, feedObjects));
	}
	return orderedFeeds;
}

const getFeedType = (feed: FeedWithFocus) => {
	return feed.type === 'print_dmr' ? 'print' : feed.type;
};

function addFeedsToLayout(layouts: TreeElement[], feedObjects: FeedWithFocus[]) {
	function filterByChannel(channelId: string, feeds: FeedWithFocus[]) {
		return feeds.filter(feed => channelId === getFeedType(feed));
	}
	function filterByFocus(focusId: string, feeds: FeedWithFocus[]) {
		return feeds.filter(feed => focusId === feed.focusId);
	}

	function filterFeedsByLayer(layout: TreeElement, feeds: FeedWithFocus[]) {

		const filteredFeeds: FeedWithFocus[] = layout.type === 'channel' ? filterByChannel(layout.id, feeds) : filterByFocus(layout.id, feeds);
		if (!layout.children.length) {
			return (layout.children = filteredFeeds.map(feed => ({
				id: feed.id,
				type: 'feed',
				name: feed.name,
				subtype: feed.type,
				children: []
			})));
		}
		layout.children.forEach(childLayout => filterFeedsByLayer(childLayout, filteredFeeds));
	}

	layouts.forEach(layout => filterFeedsByLayer(layout, feedObjects));
	return layouts;
}
