import { all, put, debounce } from 'redux-saga/effects';
import { callCatch } from 'lib/store/sagas';

import { Actions, operators } from './actions';
import Mekong from 'lib/ajax/Mekong';
import { ApiResponse as BrandApiResponse, BrandItem, FormattedApiResponse } from 'types/focus/feed/definition/printDmr/brands';
import { ApiResponse as ProductTypeApiResponse } from 'types/focus/feed/definition/printDmr/productTypes';
import { ProductTypeItem } from 'types/focus/feed/definition/printDmr/productTypes';
import { MediaQueryResponse } from 'types/focus/feed/definition/printDmr/medias';

type GenericApiResponse = {
	id: string,
	description: string,
	level2: {
		id: string,
		description: string,
		level1: {
			id: string,
			description: string
		}
	}
}
type Scope = 'print.holding.id' | 'print.company.id' | 'print.brand.id' | 'print.product.type.id' | 'print.product.line.id' | 'print.product.subline.id';
type Scopes = [Scope, Scope, Scope];
type Items = BrandItem | ProductTypeItem;

const BRAND_SCOPES: Scopes = ['print.holding.id', 'print.company.id', 'print.brand.id'];
const PRODUCT_TYPE_SCOPES: Scopes = ['print.product.type.id', 'print.product.line.id', 'print.product.subline.id'];

export default function* sagas() {
	yield all([
		debounce(200, operators.fetchDmrBrands.type, fetchDmrBrands),
		debounce(200, operators.fetchDmrProductTypes.type, fetchDmrProductTypes),
		debounce(200, operators.fetchDmrMedias.type, fetchDmrMedias)
	]);
}

function* fetchDmrMedias({ payload: { query }}: Actions["FetchDmrMedias"]) {
	yield put(operators.fetchDmrMediasStart());
	const mediaResults: MediaQueryResponse[] = yield callCatch(
		[Mekong.post, '/v1/print/medias/search', { data: { search: query } }],
		operators.fetchDmrMediasError
	);
	if (mediaResults) yield put(operators.fetchDmrMediasSuccess(mediaResults.map(mediaResponse => ({name: mediaResponse.description, id: `${mediaResponse.id}`, country: mediaResponse.country}))));
}

function* fetchDmrBrands({ payload: { query, exclude } }: Actions["FetchDmrProductTypes"]) {
	yield put(operators.fetchDmrBrandsStart());
	const brandsApiResponse: BrandApiResponse[] = yield callCatch(
		[Mekong.post, `/v1/print/brands/search`, { data: {search: query, exclude } }],
		operators.fetchDmrBrandsError
	);

	if (brandsApiResponse) {
		const genericResponse: GenericApiResponse[] = brandsApiResponse.map(apiResponse => ({
			...apiResponse,
			level2: {
				...apiResponse.company,
				level1: {
					...apiResponse.company.holding
				}
			}
		}));
		yield put(operators.fetchDmrBrandsSuccess(__getItems(genericResponse, BRAND_SCOPES)));
	}
}

function* fetchDmrProductTypes({ payload: { query, exclude } }: Actions["FetchDmrProductTypes"]) {
	yield put(operators.fetchDmrProductTypesStart());
	const productTypesApiResponse: ProductTypeApiResponse[] = yield callCatch(
		[Mekong.post, `/v1/print/products/search`, { data: {search: query, exclude } }],
		operators.fetchDmrProductTypesError
	);

	if (productTypesApiResponse) {
		const genericResponse: GenericApiResponse[] = productTypesApiResponse.map(apiResponse => ({
			...apiResponse,
			level2: {
				...apiResponse.line,
				level1: {
					...apiResponse.line.type
				}
			}
		}));
		yield put(operators.fetchDmrProductTypesSuccess(__getItems(genericResponse, PRODUCT_TYPE_SCOPES)));
	}
}

function __getItems(apiResponse: GenericApiResponse[], scopes: Scopes) {
	return __buildItems(__formatResponse(apiResponse), scopes);
}

function __formatResponse(apiResponse: GenericApiResponse[]) {
	const formattedBrands: FormattedApiResponse = {};
	apiResponse.forEach(level3Data => {
		const level2Data = level3Data.level2;
		const level1Data = level2Data.level1;
		if (!formattedBrands[level1Data.id]) formattedBrands[level1Data.id] = {name: level1Data.description, level2: {}};
		if (!formattedBrands[level1Data.id].level2[level2Data.id]) formattedBrands[level1Data.id].level2[level2Data.id] = {name: level2Data.description, level3: {}};
		if (!formattedBrands[level1Data.id].level2[level2Data.id].level3[level3Data.id]) formattedBrands[level1Data.id].level2[level2Data.id].level3[level3Data.id] = {name: level3Data.description};
	});

	return formattedBrands;
}

function __buildItems(formattedItems: FormattedApiResponse, scopes: Scopes): Items[] {
	const items: Items[] = [];
	for (const level1Id in formattedItems) {
		const level1 = formattedItems[level1Id];
		items.push({id: level1Id, names: [level1.name], scope: scopes[0]});
		for (const level2Id in level1.level2) {
			const level2 = level1.level2[level2Id];
			items.push({id: level2Id, names: [level1.name, level2.name], scope: scopes[1]});
			for (const level3Id in level2.level3) {
				const brand = level2.level3[level3Id];
				items.push({id: level3Id, names: [level1.name, level2.name, brand.name], scope: scopes[2]});
			}
		}
	}
	return items;
}
