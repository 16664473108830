import React from 'react';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';
import Checkbox from 'react-md/lib/SelectionControls/Checkbox';
import Button from 'react-md/lib/Buttons/Button';
import isEmpty from 'lodash/isEmpty';

import DocumentPlaceHolder from 'components/common/Document/PlaceHolder/DocumentPlaceHolder';
import DocumentItem from 'components/common/Document/Item/DocumentItem';
import DocumentCategorization from './Categorization';
import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import { ApiSearchDocument, DocumentObject, Document, isArticleApiSearchDocument } from 'class/Document';
import documentFactory from 'class/Document/factory';
import { User } from 'class/User';
import { isCurrentPage } from 'lib/pathCheck';
import { ReactComponent as PlacementInsertedSvg } from 'vendor/svg/feed/placement_inserted.svg';
import PaywallIcon from 'vendor/png/document/paywall.png';

import './SearchResults.scss';
import format from 'lib/format';

class SearchResults extends React.Component<ComponentProps> {

	private _canShowCategorization(document: DocumentObject): boolean {
		return !!document.company || !isEmpty(document.topic_tag) || !!document.category_id;
	}

	private _canShowSelectCheckbox() {
		const { location, user: userObject } = this.props;

		const user = new User(userObject);
		return isCurrentPage(location.pathname, "article") && user.hasPermission('document.tag') && user.hasPermission('document.categorize');
	}

	private _canShowDeleteCheckbox() {
		const { location, user: userObject } = this.props;

		const user = new User(userObject);
		return isCurrentPage(location.pathname, "article") && user.hasPermission('document.delete');
	}

	private _conditionalWrapper(condition: boolean, children: JSX.Element) {
		return (
			condition ? <div>{children}</div> : children
		);
	}

	private _paywallIcon(document: DocumentObject) {
		if (!document.paywall) return null;
		return <Button icon onClick={_ => window.open('https://lm.nicklpass.com/', '_blank')}>
			<img className="document-paywall-icon" src={PaywallIcon} onError={e => (e.target as HTMLImageElement).remove()} alt="" />
		</Button>;
	}

	private _getDocuments(documentSources: DocumentObject[] | null): any {
		const { t, loadingRemoveDocument, loadingSetTagsCategoryDocument, documentsChecked, getCategoryName, tenant, showTagActionPanel } = this.props;
		const { onRemoveDocument, onChangeDocumentCheck } = this.props;
		const canShowSelectCheckbox = this._canShowSelectCheckbox();
		const canShowDeleteCheckbox = this._canShowDeleteCheckbox();

		return documentSources && documentSources.length > 0 ? documentSources.map((documentSource: DocumentObject, index: number) => {
			const document: Document = documentFactory(documentSource);
			if (!document) return undefined;

			if (document.category) document.category_id = document.category;
			document.category = getCategoryName(document.category_id) || "";
			const formatedDate = format.date.localeTitle(document.date);

			const documentItem = (
				<div key={`${document.id}_${index}`} id={`document-${document.id}`} className="document-results-item">
					<div className="document-results-item-select">
						{canShowSelectCheckbox && showTagActionPanel ?
							<Checkbox
								id={`documentSelect-${document.id}`}
								className="document-results-item-select-checkbox"
								name="documentSelect"
								aria-label={document.id}
								checked={!!documentsChecked[document.id]}
								onChange={() => onChangeDocumentCheck(document.id)}
							/>
							: null}
					</div>
					<DocumentItem document={document.prepare()} tenant={tenant} />
					<div className="document-results-item-actions">
						{canShowDeleteCheckbox ?
							<Button
								icon
								data-id={document.id}
								className="icon-cancel remove-document-button"
								title={t('result.remove')}
								onClick={ev => { onRemoveDocument(ev.currentTarget.dataset.id || ""); }}
							/> : null}
						{this._canShowCategorization(document) ? <DocumentCategorization document={document} /> : null}
						{document.manual ?
							this._conditionalWrapper(
								!this._canShowCategorization(document),
								<Button
									className="placement-inserted-btn"
									icon
									tooltipLabel={
										<span dangerouslySetInnerHTML={{ __html: t('result.placement_inserted', { user_id: document.manual_user_name, date: formatedDate }) }}></span>
									}
									tooltipPosition="left"
								>
									<PlacementInsertedSvg className="placement-inserted-icon" />
								</Button>
							)
							: null
						}
						{this._paywallIcon(document)}
					</div>
				</div>
			);

			const showedDocumentItem =
				loadingRemoveDocument[document.id] || loadingSetTagsCategoryDocument[document.id] ?
					(<div key={document.id} className="removing-document">
						<div className="removing-document-box">
							<CircularProgress id={`removingDocument-${document.id}`} className="removing-document-loading" scale={1.25} />
						</div>
						{documentItem}
					</div>)
					: documentItem;

			return showedDocumentItem;

		}) : <div id="documentResultsNoResults">{t('results.no_results')}</div>;
	}

	private _getDocumentsPlaceHolders(documents: ApiSearchDocument[] | null): any {
		return documents ? documents.map((document, index) => (
			isArticleApiSearchDocument(document) ?
				<DocumentPlaceHolder key={document.id} /> :
				<DocumentPlaceHolder key={`${document}_${index}`} />
		)) : null;
	}

	public render() {
		const { documentSources, documents, loadingDocuments, loadingDocumentsSources, brand_sync, location } = this.props;

		let results;

		if (loadingDocuments) results = <CircularProgress id="documentResultsLoading" scale={1.25} />;
		else results = loadingDocumentsSources ? this._getDocumentsPlaceHolders(documents) : this._getDocuments(documentSources);
		const isArticlePage = isCurrentPage(location.pathname, "article");

		return (
			<div id="results" className={`${!isArticlePage && brand_sync && 'disabled'}`}>
				{results}
			</div>
		);
	}
}

export default withT(SearchResults);
