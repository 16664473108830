import React from 'react';
import { Button } from 'lib/imports/react-md';

import { withT } from 'lib/i18n';
import AuditPaging from '../Paging';
import AuditFilters from '../Filters';
import AuditList from '../List';

import { ComponentProps } from './types';

import './AuditLayout.scss';

const AuditLayout = ({ t }: ComponentProps) => {
	const tootltip = (<div>{t('audit_page_help')}</div>);
	return <>
		<div className="audit-page-title-container">
			{t('audit_page_title')}
			<div className="audit-page-tooltip-help" >
				<Button icon tooltipLabel={tootltip} tooltipPosition="right">help_outline</Button>
			</div>
		</div>
		<AuditFilters />
		<AuditList />
		<AuditPaging />
	</>;
};

export default withT(AuditLayout);
