import React from 'react';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import { reject } from 'lodash';
import { Button, Collapse, FontIcon } from 'react-md';
import { ExpressionsType, SocialExpression, SocialExpressionsType } from 'class/Feed';
import SimpleDialog from 'components/common/dialog/simple';
import FocusFeedDefinitionSocialExpression from './SocialExpression/FocusFeedDefinitionSocialExpression';

type ExpressionToRemove = {
	expressionKey: ExpressionsType,
	index: number;
};

const HELPJUICE_SOCIAL_HELP = "https://help.launchmetrics.com/en_US/feed-management/management-of-social-feeds";

const FocusFeedDefinitionLayoutSocialContent = (props: ComponentProps) => {
	const { definition, activeScope, scopeMenuItems, instagramAccounts, brand_sync, t } = props;
	const { onSetDefinition, onToggleInstagramAccount, expressionFunctions } = props;

	const [collapse, setCollapse] = React.useState<boolean>(false);
	const [expressionToRemove, setExpressionToRemove] = React.useState<ExpressionToRemove | null>(null);
	const [showRemoveDialog, setShowRemoveDialog] = React.useState(false);

	const addExpression = React.useCallback((expressionKey: SocialExpressionsType) => {
		onSetDefinition({
			...definition,
			[expressionKey]: [
				...definition[expressionKey],
				{
					q: "",
					enabled: true,
					scope: activeScope
				}
			]
		});
	}, [onSetDefinition, definition, activeScope]);

	const removeExpression = React.useCallback((expressionKey: ExpressionsType, index: number) => {
		onSetDefinition({
			...definition,
			[expressionKey]: reject(definition[expressionKey], (expression, pos: number) => index === pos)
		});
	}, [onSetDefinition, definition]);

	const confirmExpressionRemoval = React.useCallback(() => {
		const { expressionKey, index } = expressionToRemove!;
		removeExpression(expressionKey, index);
		setShowRemoveDialog(false);
	}, [expressionToRemove, removeExpression]);

	const cancelExpressionRemoval = React.useCallback(() => {
		setShowRemoveDialog(false);
	}, []);

	const removeExpressionHandler = React.useCallback((expressionKey: ExpressionsType, index: number) => {
		const expression = definition[expressionKey][index];
		if (expression.q === '') return removeExpression(expressionKey, index);
		setExpressionToRemove({ expressionKey, index });
		setShowRemoveDialog(true);
	}, [definition, removeExpression]);

	const getExpression = React.useCallback((expression: SocialExpression, type: 'main' | 'include' | 'exclude', index?: number) => {
		const expressionType: SocialExpressionsType = type === 'main' ? type as SocialExpressionsType : `${type}_expressions` as SocialExpressionsType;

		if (!index) index = 0;
		return <FocusFeedDefinitionSocialExpression
			key={`${type}Expression${index}`}
			expressionKey={expressionType}
			index={index}
			expression={expression}
			scopeMenuItems={scopeMenuItems}
			instagramAccounts={instagramAccounts}
			definitionInstagramAccounts={definition.instagram_accounts}
			onRemoveExpression={removeExpressionHandler}
			{...expressionFunctions}
			onInstagramAccountToggled={type === 'exclude' ? undefined : onToggleInstagramAccount}
		/>;
	}, [definition.instagram_accounts, expressionFunctions, instagramAccounts, onToggleInstagramAccount, removeExpressionHandler, scopeMenuItems]);

	const getExpressionContent = React.useCallback((expressionKey: SocialExpressionsType) => {
		const headerText = expressionKey === 'include_expressions' ? 'feed.form.header_positive_expressions' : 'feed.form.header_negative_expressions';
		const expressionType = expressionKey === 'include_expressions' ? 'include' : 'exclude';
		const buttonId = expressionKey === 'include_expressions' ? 'focusFeedDefinitionSocialAddIncludeExpressionBtn' : 'focusFeedDefinitionSocialAddExcludeExpressionBtn';
		return <>
			<div className="definition-text" dangerouslySetInnerHTML={{ __html: t(headerText) + ":" }} ></div>
			{expressionKey === 'include_expressions' ?
				definition.include_expressions.map((expression, index) => getExpression(expression as SocialExpression, expressionType, index)) :
				definition.exclude_expressions.map((expression, index) => getExpression(expression as SocialExpression, expressionType, index))
			}
			<div className="definition-add-expression">
				<Button
					id={buttonId}
					className="definition-add-expression-button"
					flat
					onClick={() => addExpression(expressionKey)}
				>
					<span>+</span> {t('feed.form.button.add_words_react')}
				</Button>
			</div>
		</>;
	}, [addExpression, definition.exclude_expressions, definition.include_expressions, getExpression, t]);

	const removeDialog = React.useMemo(() => (showRemoveDialog ?
		(
			<SimpleDialog
				id="focusFeedDefinitionRemoveExpressionWarning"
				content={<></>}
				text={t('feed.form.confirm_remove_expression')}
				onAccept={confirmExpressionRemoval}
				onCancel={cancelExpressionRemoval}
				acceptColor="red"
			/>
		)
		: null), [cancelExpressionRemoval, confirmExpressionRemoval, showRemoveDialog, t]);

	const contentSection = React.useMemo(() => {
		const isCollapsed = collapse || brand_sync;
		return <div id="focusFeedDefinitionSocialContentSection" className={`definition-section ${brand_sync ? 'definition-section-disabled' : ''}`}>
			<div className="definition-section-header">
				<div className="definition-header-title" onClick={() => setCollapse(!collapse)}>
					{
						isCollapsed ?
							<FontIcon className="definition-header-title-toggle" >keyboard_arrow_right</FontIcon> :
							<FontIcon className="definition-header-title-toggle">keyboard_arrow_down</FontIcon>
					}
					<span className="definition-header-title-text"> {t('feed.form.header_main_expression_react')} </span>
				</div>
				<a className="definition-header-search-show-help-link" href={HELPJUICE_SOCIAL_HELP} target='blank'>
					<div>{t('definition.show_help')}</div>
				</a>
			</div>

			<Collapse collapsed={isCollapsed}>
				<div className={`definition-section-content ${brand_sync && 'disabled'}`}>
					<div className="definition-expressions">
						<div className="definition-expression-main">
							{getExpression(definition.main as SocialExpression, 'main')}
						</div>
						{getExpressionContent('include_expressions')}
						{getExpressionContent('exclude_expressions')}
					</div>
				</div>
			</Collapse>
		</div>;
	}, [brand_sync, collapse, definition.main, getExpression, getExpressionContent, t]);

	return (
		<>
			{removeDialog}
			{contentSection}
		</>
	);
};

export default withT(React.memo(FocusFeedDefinitionLayoutSocialContent));
