import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { Actions, operators } from './actions';
import omit from 'lodash/omit';

import { FeedType } from 'class/Feed';
import { DocumentExists, InsertDocumentStep } from 'types/article/insert';
import { BulkDocument, DocumentObject } from 'class/Document';
import * as form from './form';

export type State = {
	readonly form: form.State,
	readonly showDialog: boolean,
	readonly step: InsertDocumentStep
	readonly type: FeedType
	readonly file: File | null
	readonly isBulkInsert: number
	readonly continueInsert: boolean
	readonly templateBlob: Blob | null
	readonly selectedFeed: { id: string, type: FeedType } | null
	readonly url: string
	readonly finalUrl: string
	readonly selectedUrl: string
	readonly invalidUrlError: string | false
	readonly loading: boolean
	readonly searchNotFoundReason: string | null
	readonly selectedDocumentIds: { [id: string]: boolean }
	readonly tenantDocuments: DocumentObject[]
	readonly historicalDocuments: DocumentObject[]
	readonly feedDocuments: DocumentObject[]
	readonly documentExists: DocumentExists
	readonly bulkPreviewDocuments: BulkDocument[]
	readonly bulkPreviewTotalErrors: number
	readonly bulkPreviewErrorFileUrl: string
	readonly bulkInsertS3Path: string
};

export const INITIAL_STATE: State = {
	form: form.INITIAL_STATE,
	showDialog: false,
	step: "SELECT_TYPE",
	type: 'online',
	file: null,
	isBulkInsert: 0,
	continueInsert: false,
	selectedFeed: null,
	templateBlob: null,
	url: "",
	finalUrl: "",
	selectedUrl: "",
	invalidUrlError: false,
	loading: false,
	searchNotFoundReason: null,
	selectedDocumentIds: {},
	tenantDocuments: [],
	historicalDocuments: [],
	feedDocuments: [],
	documentExists: null,
	bulkPreviewDocuments: [],
	bulkPreviewTotalErrors: 0,
	bulkPreviewErrorFileUrl: '',
	bulkInsertS3Path: ''
};

const reducerHandlers: ReducerHandlers<State> = {
	...form.reducerHandlers,
	setShowDialog: (state, { payload: { show } }: Actions["SetShowDialog"]): State => ({
		...INITIAL_STATE,
		showDialog: show
	}),
	setStep: (state, { payload: { step } }: Actions["SetStep"]): State => ({
		...state,
		step
	}),
	setType: (state, { payload: { type } }: Actions["SetType"]): State => ({
		...state,
		type
	}),
	setSelectedFeed: (state, { payload: feed }: Actions["SetSelectedFeed"]): State => ({
		...state,
		selectedFeed: feed
	}),
	setUrl: (state, { payload: { url } }: Actions["SetUrl"]): State => ({
		...state,
		url
	}),
	setUrlError: (state, { payload: { invalidError } }: Actions["SetUrlError"]): State => ({
		...state,
		invalidUrlError: invalidError
	}),
	setFinalUrl: (state, { payload: { url } }: Actions["SetFinalUrl"]): State => ({
		...state,
		finalUrl: url
	}),
	setSelectedUrl: (state, { payload: { url: selectedUrl } }: Actions["SetSelectedUrl"]): State => ({
		...state,
		selectedUrl
	}),
	setLoading: (state, { payload: { loading } }: Actions["SetLoading"]): State => ({
		...state,
		loading
	}),
	changeStepError: (state, { payload }: Actions["ChangeStepError"]): State => ({
		...state,
		loading: false
	}),
	setFeedDocuments: (state, { payload: { feedDocuments } }: Actions["SetFeedDocuments"]): State => ({
		...state,
		feedDocuments,
		searchNotFoundReason: null
	}),
	setTenantDocuments: (state, { payload: { tenantDocuments } }: Actions["SetTenantDocuments"]): State => ({
		...state,
		tenantDocuments,
		searchNotFoundReason: null
	}),
	setHistoricalDocuments: (state, { payload: { historicalDocuments } }: Actions["SetHistoricalDocuments"]): State => ({
		...state,
		historicalDocuments,
		searchNotFoundReason: null
	}),
	setDocumentNotFoundReason: (state, { payload: { reason } }: Actions["SetDocumentNotFoundReason"]): State => ({
		...state,
		historicalDocuments: [],
		tenantDocuments: [],
		feedDocuments: [],
		searchNotFoundReason: reason
	}),
	setSelectedDocumentId: (state, { payload: { id } }: Actions["ToggleCheckedDocument"]): State => ({
		...state,
		selectedDocumentIds: state.selectedDocumentIds[id] ? omit(state.selectedDocumentIds, id) : { ...state.selectedDocumentIds, [id]: true }
	}),
	setDocumentExists: (state, { payload: { media } }: Actions["SetDocumentExists"]): State => ({
		...state,
		documentExists: media
	}),
	setFile: (state, { payload: { file }}: Actions["SetFile"]): State => ({
		...state,
		file
	}),
	setIsBulkInsert: (state, { payload: { isBulkInsert }}: Actions["SetIsBulkInsert"]): State => ({
		...state,
		isBulkInsert
	}),
	setTemplateBlob: (state, { payload: { templateBlob }}: Actions["SetTemplateBlob"]): State => ({
		...state,
		templateBlob
	}),

	setBulkDocuments: (state, payload: Actions["SetBulkDocuments"]): State => ({
		...state,
		bulkPreviewDocuments: [],
		bulkPreviewTotalErrors: 0,
		bulkPreviewErrorFileUrl: ''
	}),

	setBulkDocumentsError: (state, payload: Actions["SetBulkDocuments"]): State => ({
		...state,
		bulkPreviewDocuments: [],
		bulkPreviewTotalErrors: 0,
		bulkPreviewErrorFileUrl: ''
	}),

	setBulkDocumentsSuccess: (state, { payload: { documents, totalErrors, errorFileUrl, bulkInsertS3Path } }: Actions["SetBulkDocumentsSuccess"]): State => ({
		...state,
		bulkPreviewDocuments: documents,
		bulkPreviewTotalErrors: totalErrors,
		bulkPreviewErrorFileUrl: errorFileUrl,
		bulkInsertS3Path
	}),
	addDocumentImageSuccess: (state, { payload: { docIndex, imageType, imagePath } }: Actions["AddDocumentImageSuccess"]): State => {
		const docs = state.bulkPreviewDocuments;
		docs[docIndex][imageType === 'cover' ? 'coverImage' : 'pageImage'] = imagePath;
		return {
			...state,
			bulkPreviewDocuments: docs
		};
	},
	removeDocumentImage: (state, { payload: { docIndex, imageType } }: Actions["RemoveDocumentImage"]): State => {
		const docs = state.bulkPreviewDocuments;
		docs[docIndex][imageType === 'cover' ? 'coverImage' : 'pageImage'] = undefined;
		return {
			...state,
			bulkPreviewDocuments: docs
		};
	},
	setContinueInsert: (state, { payload }: Actions["SetContinueInsert"]): State => ({
		...state,
		continueInsert: payload
	}),
	resetState: (state, { payload }: Actions["ResetState"]): State => ({
		...INITIAL_STATE,
		...payload
	})
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
