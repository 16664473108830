import React from 'react';
import { cloneDeep } from 'lodash';
import { Button, SelectionControl } from 'react-md';
import { withT } from 'lib/i18n';
import LayoutLine from './LayoutLine';

import { ComponentProps, TreeLayers } from './types';

import './NewsletterEditFormLayout.scss';

const NewsletterEditFormLayout: React.FC<ComponentProps> = props => {
	const { layoutData, layoutSettings } = props;
	const { onUpdateLayout, t } = props;

	const [switchSettingsOrder, setSwitchSettingsOrder] = React.useState(layoutSettings.channel.order !== 0);
	const settings = React.useMemo(() => cloneDeep(layoutSettings), [layoutSettings]);

	const onEnableLayer = React.useCallback((layer: TreeLayers) => {
		settings[layer].enabled = !settings[layer].enabled;
		onUpdateLayout({layoutSettings: settings});
	}, [settings, onUpdateLayout]);

	const onSwitchSettings = React.useCallback(() => {
		const previousFocusOrder = settings.focus.order;
		settings.focus.order = settings.channel.order;
		settings.channel.order = previousFocusOrder;
		onUpdateLayout({layoutSettings: settings});
		setSwitchSettingsOrder(!switchSettingsOrder);
	}, [switchSettingsOrder, settings, onUpdateLayout]);

	const layoutContent = React.useMemo(() => {
		return (
			<>
				<div className="layout-subtitle">{t('newsletter.form.layout.header.subtitle')}</div>
				<div className='layout-container'>
					<LayoutLine
						layoutData={layoutData}
						layoutSettings={settings}
						onUpdateLayout={onUpdateLayout}
					/>
				</div>
			</>
		);
	}, [t, layoutData, settings, onUpdateLayout]);

	const channelSelection = React.useMemo(() => {
		return (
			<SelectionControl
				key={`newsletterEditLayoutSectionChannelSwitch-${settings.channel.order}`}
				id={`newsletterEditLayoutSectionChannelSwitch`}
				name="newsletterEditLayoutSectionChannelSwitch"
				type="switch"
				label={t('newsletter.form.layout.settings.channel_layer')}
				checked={settings.channel.enabled}
				onChange={_ => onEnableLayer('channel')}
			/>
		);
	}, [settings, onEnableLayer, t]);

	const focusSelection = React.useMemo(() => {
		return (
			<SelectionControl
				key={`newsletterEditLayoutSectionFocusSwitch-${settings.focus.order}`}
				id={`newsletterEditLayoutSectionFocusSwitch`}
				name="newsletterEditLayoutSectionFocusSwitch"
				type="switch"
				label={t('newsletter.form.layout.settings.focus_layer')}
				checked={settings.focus.enabled}
				onChange={_ => onEnableLayer('focus')}
			/>
		);
	}, [settings, onEnableLayer, t]);

	const feedSelection = React.useMemo(() => {
		return (
			<SelectionControl
				key={`newsletterEditLayoutSectionFeedsSwitch-${settings.feed.order}`}
				id={`newsletterEditLayoutSectionFeedsSwitch`}
				name="newsletterEditLayoutSectionFeedsSwitch"
				type="switch"
				label={t('newsletter.form.layout.settings.feed_layer')}
				checked={settings.feed.enabled}
				onChange={_ => onEnableLayer('feed')}
			/>
		);
	}, [settings, onEnableLayer, t]);

	const layoutSettingsSection = React.useMemo(() => {
		return (
			<>
				<div className='layout-settings-title'>{t('newsletter.form.layout.settings.title')}</div>

				<div className='layout-settings layout-settings-changeable' key='layoutSettingsSwitchBox'>
					<div key='layoutSettingsSwitch' id='layoutSettingsSwitch'>
						{ !switchSettingsOrder ? channelSelection : focusSelection }
						<div className='layout-settings-divider'></div>
						{ switchSettingsOrder ? channelSelection : focusSelection }
					</div>
					<Button
						icon
						tooltipLabel={t('newsletter.form.layout.settings.swap_tooltip')}
						tooltipClassName='switch-button-tooltip'
						key='layoutSettingsSwitchButton'
						id='layoutSettingsSwitchButton'
						className='switch-button'
						onClick={onSwitchSettings}
					>swap_vertical_circle</Button>
				</div>

				<div className='layout-settings layout-settings-fixed' key='layoutSettingsFixed'>
					{feedSelection}
				</div>
			</>
		);
	}, [channelSelection, feedSelection, focusSelection, onSwitchSettings, switchSettingsOrder, t]);

	return (<>
		<div id="newsletterEditLayoutSectionFeeds" className="form-section grid-container">

			<div className="form-section-title layout-column">
				{t('newsletter.form.layout.header.title')}
			</div>

			<div className="layout-column" key='layoutContent'>{layoutContent}</div>
			<div className="layout-column setting-container" key='layoutSettings'>{layoutSettingsSection}</div>

		</div>
	</>);
};

export default React.memo(withT(NewsletterEditFormLayout));
