import { createSelector } from "reselect";

import { State } from "store/types";

const rootSelector = (state: State) => state.ui.newsletter.draft.edit;

export default {
	getIdAndTimestamp: createSelector(rootSelector, ({ id, timestamp }) => ({ id, timestamp })),
	getStatus: createSelector(rootSelector, state => state.status),
	getChannels: createSelector(rootSelector, state => state.channels),
	getPreviewHtml: createSelector(rootSelector, state => state.previewHtml),
	getUpdatedSectionsHtml: createSelector(rootSelector, state => state.updatedSectionsHtml)
};
