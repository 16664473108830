import { Document, PreparedDocument } from "class/Document";
import format from "lib/format";
import { ReactComponent as IconSVG } from 'vendor/svg/document/bilibili.svg';
import i18n from "lib/i18n";

export class BilibiliDocument extends Document {
	public prepare(): PreparedDocument {
		const counters = {
			audience: {
				followers: this.author ? (this.author.followers || 0) : 0
			},
			reach: {
				followers: this.author ? (this.author.followers || 0) : 0
			},
			echo: {
				likes: this.social ? (this.social.likes || 0) : 0,
				shares: this.social ? (this.social.shares || 0) : 0,
				replies: this.social ? (this.social.replies || 0) : 0,
				coin: this.social ? (this.social.coin || 0) : 0,
				danmaku: this.social ? (this.social.danmaku || 0) : 0,
				favourites: this.social ? (this.social.favourites || 0) : 0
			}
		};
		const text = this.splitTags(this.content);
		return new PreparedDocument({
			id: this.id,
			provider: this.provider,
			providerName: 'Bilibili',
			link: this.link,
			iconSVG: IconSVG,
			categories_id: this.category || '',
			title: text.title,
			content: text.content,
			date: format.date.doc(this.date_from_provider),
			dateTitle: format.date.localeTitle(this.date_from_provider),
			country: this.place.country_code,
			author_name: this.author ? (this.author.name || '') : '',
			author_icon: this.author ? this.author.image : undefined,
			author_link: this.author ? this.author.url : undefined,
			image: this.images ? (this.images[0] || '') : '',
			counters: {
				reach: {
					value: format.number.counter(counters, 'audience'),
					title: format.text.counter(counters, 'audience')
				},
				views: {
					value: format.number.metric(this.social ? this.social.views : 0),
					title: i18n.t('result.counters.views') + `: ${this.social ? this.social.views : 0}`
				},
				engagement: {
					value: format.number.counter(counters, 'echo'),
					title: format.text.counter(counters, 'echo')
				},
				echo: {
					value: format.number.currency(this.rank, this.date_from_provider),
					title: format.number.currency(this.rank, this.date_from_provider, 'locale', true)
				},
				branded_miv: this.branded_miv ? this.branded_miv.map(brand => ({
					id: brand.id,
					value: format.number.brandedCurrency(brand.value, this.date_from_provider),
					title: format.number.brandedCurrency(brand.value, this.date_from_provider, 'locale', true)
				})) : []
			}
		});
	}
}
