import onlineIcon from 'vendor/svg/feed/online.svg';
import socialmediaIcon from 'vendor/svg/feed/socialmedia.svg';
import printIcon from 'vendor/svg/feed/print.svg';
import onlineGreyIcon from 'vendor/svg/feed/online_grey.svg';
import socialmediaGreyIcon from 'vendor/svg/feed/socialmedia_grey.svg';
import printGreyIcon from 'vendor/svg/feed/print_grey.svg';

import { FeedAttributes } from 'store/entities/Feed/types';

const selectedIcons = {
	online: onlineIcon,
	print: printIcon,
	socialmedia: socialmediaIcon,
	print_dmr: printIcon
};

const icons = {
	online: onlineGreyIcon,
	print: printGreyIcon,
	socialmedia: socialmediaGreyIcon,
	print_dmr: printGreyIcon
};

export const getTypeIcon = (type: FeedAttributes['type'], selected: boolean = false) => {
	if (selected) return selectedIcons[type];
	return icons[type];
};
