import ActionCreators from 'lib/store/actions';
import { TagObject } from 'store/entities/Tag';
import { TagAutomation, TagAutomationDefinition } from 'store/entities/TagAutomation';

type UpdateTagAutomationPayload = {
	tagId: string;
	tagAutomationId: string;
	enabled: boolean;
	definition: TagAutomationDefinition;
}
const actions = new ActionCreators('@@topic/tag/details');

const [setSelectedTag] = actions.apiOperators<{ tag: TagObject<"tag_automation"> }, TagAutomation>('SET_SELECTED_TAG');
const [updateTagAutomation, updateTagAutomationSuccess, updateTagAutomationError] =
	actions.apiOperators<UpdateTagAutomationPayload, TagAutomation>('UPDATE_TAG_AUTOMATION');

export type Actions = {
	SetSelectedTag: ReturnType<typeof setSelectedTag>;
	UpdateTagAutomation: ReturnType<typeof updateTagAutomation>;
	UpdateTagAutomationSuccess: ReturnType<typeof updateTagAutomationSuccess>;
	UpdateTagAutomationError: ReturnType<typeof updateTagAutomationError>;
};

export const operators = {
	setSelectedTag,
	updateTagAutomation,
	updateTagAutomationSuccess,
	updateTagAutomationError
};
