import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { Actions, operators } from './actions';

export type ApiTags = Array<{ tag: string, occurences: number }>;
export type State = {
	tags: ApiTags;
};

export const INITIAL_STATE: State = {
	tags: []
};

const reducerHandlers: ReducerHandlers<State> = {
	fetchTags: (state, { payload }: Actions["FetchTags"]): State => ({
		...state
	}),
	fetchTagsSuccess: (state, { payload: { tags } }: Actions["FetchTagsSuccess"]): State => ({
		...state,
		tags
	}),
	fetchTagsError: (state, { payload }: Actions["FetchTagsError"]): State => ({
		...state,
		tags: []
	})
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
