import { all, call, put, takeLatest } from 'redux-saga/effects';

import { Actions, operators } from './actions';
import Mekong from 'lib/ajax/Mekong';
import { operators as entityOperators, TagAutomation } from 'store/entities/TagAutomation';

export default function* sagas() {
	yield all([
		takeLatest(operators.updateTagAutomation.type, updateTagAutomation)
	]);
}

function* updateTagAutomation({ payload: { tagId, tagAutomationId, enabled, definition = {} } }: Actions['UpdateTagAutomation']) {
	try {
		const tagAutomationInfo: TagAutomation = yield call([Mekong, 'put'], `/v1/tag/${tagId}/automation/${tagAutomationId}`, { data: { enabled, definition } });
		yield put(entityOperators.update({ id: tagAutomationId, ...tagAutomationInfo}));
		yield put(operators.updateTagAutomationSuccess(tagAutomationInfo));
	} catch (error) {
		yield put(operators.updateTagAutomationError(error as any));
	}
}
