import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { operators as notificationsOperators } from 'store/app/notifications';
import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import ArticleInsertPrintForm from './ArticleInsertFormPrint';
import profileSelectors from 'store/app/profile/selectors';

const mapStateToProps = (state: State): StateProps => ({
	user: profileSelectors.getUser(state)!,
	tenant: state.app.profile.tenant!,
	loading: state.article.insert.loading,
	feedType: state.article.insert.selectedFeed!.type
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onAddNotification: notification => dispatch(notificationsOperators.add({notification}))
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleInsertPrintForm);
