import React, { useCallback, useMemo, useState } from 'react';

import { withT } from 'lib/i18n';
import ArticleInsert from 'components/Article/Insert';
import GA from 'lib/googleAnalytics';
import { User } from 'class/User';

import { ComponentProps } from './types';

import './ArticleForm.scss';
import { SortButton } from './SortButton';
import { SearchFormInput } from './SearchFormInput';
import { DisplayItems } from './DisplayItems';
import { FormOptions } from './FormOptions';
import { ExportDropDownMenu } from './ExportDropdownMenu';
import { Button } from 'react-md';

const ArticleForm: React.FC<ComponentProps> = (props: ComponentProps) => {
	const { user, quickReportEnabled, reportedFocusNames, tenant, showInsertDocument, sort, start, limit, total, loadingDocuments, query, filters, showTagActionPanel } = props;
	const { t, onSetReportedFocusNames, onQuickReport, onSetSort, onSetLimit, onSetShowInsertDocument, onToggleDrawer, onSetQuery, onSubmit, onToggleTagPanel } = props;

	const [reportConfirmationDialogEnbaled, setReportConfirmationDialogEnabled] = useState(false);

	const userClass = useMemo(() => new User(user), [user]);
	const showExport = useMemo(() => userClass.hasPermission('reports.view'), [userClass]);

	const handleSubmit = useCallback(() => {
		GA.trackEvent({ category: "Feed results", action: 'Search mentions' });
		onSubmit();
	}, [onSubmit]);

	const handleQuickReport = useCallback(() => {
		onQuickReport();
		setReportConfirmationDialogEnabled(true);
	}, [onQuickReport, setReportConfirmationDialogEnabled]);

	return (
		<div id="articleForm">
			<SortButton t={t} tenant={tenant} sort={sort} onSetSort={onSetSort} onToggleDrawer={onToggleDrawer} />
			<SearchFormInput t={t} onSetQuery={onSetQuery} handleSubmit={handleSubmit} query={query} />
			<DisplayItems loadingDocuments={loadingDocuments} start={start} limit={limit} total={total} t={t} />
			<FormOptions t={t} showExport={showExport} onSetLimit={onSetLimit} limit={limit} userClass={userClass} onSetShowInsertDocument={onSetShowInsertDocument} />
			{
				user.permissions.includes('document.categorize') &&
				<div className='tag-panel-button'>
					<Button icon className={showTagActionPanel ? 'selected' : undefined} onClick={() => onToggleTagPanel()}>edit</Button>
				</div>
			}
			<ExportDropDownMenu
				total={total}
				user={user}
				showExport={showExport}
				quickReportEnabled={quickReportEnabled}
				reportConfirmationDialogEnbaled={reportConfirmationDialogEnbaled}
				reportedFocusNames={reportedFocusNames}
				filters={filters}
				handleQuickReport={handleQuickReport}
				setReportConfirmationDialogEnabled={setReportConfirmationDialogEnabled}
				onSetReportedFocusNames={onSetReportedFocusNames}
			/>

			{showInsertDocument ? <ArticleInsert /> : null}

			<div className="verticalDivider"></div>
		</div >
	);
};

export default withT(React.memo(ArticleForm));
