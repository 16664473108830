import { createSelector } from "reselect";

import fletcherSelectors from 'store/fletcher/selectors';
import NewsletterSelectors from 'store/entities/Newsletter/selectors';
import EmailListSelectors from 'store/entities/EmailList/selectors';
import { State } from "store/types";

const rootSelector = (state: State) => state.ui.newsletter.edit;

export default {
	isLoading: createSelector(rootSelector, state => state.loading),
	getNewsletter: createSelector((state: State, newsletterId: string) => ({ state, newsletterId }), fletcherSelectors.isNewslettersFetched,
		({ state, newsletterId }, isFetched) => {
			if (!isFetched) return null;
			return NewsletterSelectors.get(state, newsletterId);
		}),
	getNewsletterWithEmailLists: createSelector((state: State, newsletterId: string) => ({ state, newsletterId }), fletcherSelectors.isNewslettersFetched,
		({ state, newsletterId }, isFetched) => {
			if (!isFetched) return null;
			const newsletter = NewsletterSelectors.get(state, newsletterId);
			if (!newsletter) return null;
			const emailLists = NewsletterSelectors.getEmailLists(state, newsletter.id)!;
			return { ...newsletter, emailLists };
		}),
	getEmailLists: createSelector((state: State) => state, fletcherSelectors.isNewslettersFetched,
		(state, isFetched) => {
			if (!isFetched) return null;
			return EmailListSelectors.get(state);
		})
};
