import React from "react";
import axios from 'axios';

import { CircularProgress } from 'lib/imports/react-md';

import { withT, TProps } from 'lib/i18n';
import './NewsletterClipDownload.scss';

type ComponentProps = {
	id: string;
	sendTime: string;
} & TProps;

const NewsletterClipDownload = ({ t, id, sendTime }: ComponentProps) => {
	const [loading, setLoading] = React.useState<boolean | null>(true);
	const [url, setUrl] = React.useState<string>();

	React.useEffect(() => {
		(async () => {
			const apiUrl = process.env.REACT_APP_API_URL;
			const apiCall = `${apiUrl}/v1/newsletter/${id}/download/${sendTime}${window.location.search}`;

			try {
				const { data: apiData } = await axios.get(apiCall);

				if (apiData.error) throw new Error(apiData.error);

				setLoading(false);
				setUrl(apiData.response);
				window.location.assign(apiData.response);
			} catch (error) {
				console.error(`Some error happened trying this query [${apiCall}]`);
				console.error(error);
				setLoading(null);
			}
		})();
	}, [id, sendTime]);

	const content = React.useMemo(() => {
		if (loading === true) return <CircularProgress id="clipDownloadLoadingRequest" />;
		if (loading === null) return <>{t('newsletter.download.error')}</>;
		if (loading === false) return (
			<>
				<span>{t('newsletter.download.success')}</span>
				<a href={url} target='_blank' rel="noopener noreferrer">{t('newsletter.download.link')}</a>
			</>
		);
	}, [t, loading, url]);

	return (
		<div id="clipDownloadContent">
			{content}
		</div>
	);
};

export default withT(NewsletterClipDownload);
