import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/app/facebook';
import { operators as notificationOperators } from 'store/app/notifications';
import selectors from 'store/app/facebook/selectors';
import profileSelectors from 'store/app/profile/selectors';

import { StateProps, DispatchProps } from './types';
import Facebook from './Facebook';

const mapStateToProps = (state: State): StateProps => ({
	user: profileSelectors.getUser(state)!,
	dialogMode: selectors.getDialogMode(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onUpdateStatus: statusResponse => dispatch(operators.updateStatus(statusResponse)),
	onSetDialogMode: mode => dispatch(operators.setDialogMode(mode)),
	onAddNotification: notification => dispatch(notificationOperators.add({ notification }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Facebook);
