import { isEmail } from 'validator';
import XRegExp from 'xregexp';

export function checkName(name: string): string | null {
	let error: string | null = null;
	if (!name) error = 'name_empty';
	else if (name.length < 2) error = 'name_too_short';
	else if (!__validateAllowedCharacters(name)) error = 'name_invalid_characters';

	return error;
}

function __validateAllowedCharacters(name: string): boolean {
	// https://en.wikipedia.org/wiki/Unicode_character_property#General_Category
	return !XRegExp('[\\p{Zl}\\p{Zp}\\p{Cc}\\p{Co}]').test(name);
}

export const isYoutubeDocumentUrl = (url: string) => {
	return !!(url.match(/^https?:\/\/(www\.)?youtube\.com\/watch\?v=.*$/i) || url.match(/^https?:\/\/(www\.)?youtube\.com\/video\/.+$/i));
};

export const isTiktokDocumentUrl = (url: string) => {
	return !!(url.match(/^https?:\/\/(www\.)?tiktok\.com\/@.*\/video\/.*$/i));
};

export const isWeChatDocumentUrl = (url: string) => {
	return !!(url.match(/^https?:\/\/(?:www\.)?mp\.weixin\.qq\.com\/(?:.*?)mid=(\d+).*$/i));
};

export const isWeiboDocumentUrl = (url: string) => {
	return !!(url.match(/^https?:\/\/(?:www\.)?weibo\.com\/(?:u\/)*(?:\d+).*$/i));
};

export const isBilibiliDocumentUrl = (url: string) => {
	return !!(url.match(/^https?:\/\/(?:www\.)?bilibili\.com\/video\/(\w+).$/i));
};

export const isRedDocumentUrl = (url: string) => {
	return !!(url.match(/^https?:\/\/(?:www\.)?xiaohongshu\.com\/(discovery\/item\/|explore\/)(\w+)$/i));
};

export const isDouyinDocumentUrl = (url: string) => {
	return !!(url.match(/^https?:\/\/(?:www\.)?(?:douyin|iesdouyin)\.com\/(?:video|note|share\/video)\/(\d+)$/i));
};

export const isEmailListNameTooLong = (name: string) => name.length > 100;

export function isValidEmail(email: string) {
	return isEmail(email, { allow_display_name: false });
}
