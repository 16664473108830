import { all } from 'redux-saga/effects';

import newsletterSagas from './newsletter/sagas';
import reportSagas from './report/sagas';
import topicSagas from './topic/sagas';
import auditSagas from './audit/sagas';

// define sagas generator function to register all sagas
export default function* sagas() {
	yield all([
		newsletterSagas(),
		reportSagas(),
		topicSagas(),
		auditSagas()
	]);
}
