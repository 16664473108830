import React from "react";
import TextField from 'react-md/lib/TextFields';
import Button from "react-md/lib/Buttons";
import FontIcon from "react-md/lib/FontIcons/FontIcon";
import Avatar from "react-md/lib/Avatars/Avatar";
import SelectionControl from 'react-md/lib/SelectionControls/SelectionControl';
import CircularProgress from "react-md/lib/Progress/CircularProgress";
import { ComponentProps } from './types';

import { withT } from "lib/i18n";
import { isEmpty } from "lodash";

import YoutubeForcedIcon from 'vendor/png/document/youtube.png';
import { ReactComponent as BiliBiliSvg } from 'vendor/svg/document/bilibili.svg';
import { ReactComponent as DouyinSvg } from 'vendor/svg/document/douyin.svg';
import { ReactComponent as TikTokSvg } from 'vendor/svg/document/tiktok.svg';
import { ReactComponent as WechatSvg } from 'vendor/svg/document/wechat.svg';
import { ReactComponent as WeiboSvg } from 'vendor/svg/document/weibo.svg';
import { ReactComponent as RedSvg } from 'vendor/svg/document/red.svg';

import "./FocusFeedDefinitionSocialExpression.scss";

const FocusFeedDefinitionSocialExpressionProfile = (props: ComponentProps) => {
	const { expressionKey, index, expression, loading, t } = props;
	const { onSetProfileExpression, onRemoveProfileExpression, onSearchProfile } = props;
	const { enabled, error, name } = expression;
	const query = expression.url;
	const channel_type = expression.channel_type_id;
	const avatar = expression.picture;

	const inputRef: React.RefObject<any> = React.useRef();
	const [queryValue, setQueryValue] = React.useState(query);
	const [unsearchedQuery, setUnsearchedQuery] = React.useState<string>(query);

	React.useEffect(() => {
		if (isEmpty(query)) inputRef.current.focus();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		setQueryValue(query);
	}, [query]);

	const getIcon = React.useCallback((iconClassName: string) => {
		return <FontIcon className="definition-expression-icon" iconClassName={iconClassName} />;
	}, []);

	const getForcedIcon = React.useCallback((forcedIconSource: string) => {
		return <img className="definition-expression-forced-icon" src={forcedIconSource} onError={e => (e.target as HTMLImageElement).remove()} alt="" />;
	}, []);

	const getIconFromChannelType = React.useCallback((channelType: number) => {
		switch (channelType) {
			case 30: return getIcon('icon-twitter');
			case 40: return getForcedIcon(YoutubeForcedIcon);
			case 51: return getIcon('icon-instagram');
			case 60: return getIcon('icon-facebook');
			case 47: return <BiliBiliSvg />;
			case 46: return <DouyinSvg />;
			case 41: return <TikTokSvg />;
			case 62: return <WechatSvg />;
			case 31: return <WeiboSvg />;
			case 52: return <RedSvg />;
			default: return getIcon('icon-unknown');
		}
	}, [getIcon, getForcedIcon]);

	const checkSearchProfile = React.useCallback(() => {
		if (!queryValue || queryValue.toLowerCase() === unsearchedQuery.toLowerCase()) return;
		onSearchProfile(queryValue, expressionKey, index);
		setUnsearchedQuery(queryValue);
	}, [expressionKey, index, onSearchProfile, queryValue, unsearchedQuery]);

	const enableCheckbox = React.useMemo(() => (
		<SelectionControl
			id={`focusFeedDefinitionSocialExpressionProfileEnabled${expressionKey}${index}`}
			name={`focusFeedDefinitionSocialExpressionProfileEnabled${expressionKey}${index}`}
			aria-label={`focusFeedDefinitionSocialExpressionProfileEnabled${expressionKey}${index}`}
			className={`definition-expression-enabled ${enabled ? "checkbox-green" : ''} `}
			type="checkbox"
			checked={enabled}
			onChange={() => onSetProfileExpression(expressionKey, index, { enabled: !enabled })}
		/>
	), [enabled, expressionKey, index, onSetProfileExpression]);

	const queryInput = React.useMemo(() => {
		return <TextField
			id={`focusFeedSocialExpressionProfileQuery${expressionKey}${index}`}
			className={`definition-expressions-query ${error ? 'wrong-query' : ''}`}
			fullWidth={false}
			value={queryValue}
			error={error}
			onChange={value => setQueryValue(value.toString())}
			onBlur={() => checkSearchProfile()}
			onKeyDown={ev => { if (ev.keyCode === 13) checkSearchProfile(); }}
			disabled={!enabled}
			placeholder="https://"
			ref={inputRef}
		/>;
	}, [expressionKey, index, error, queryValue, enabled, checkSearchProfile]);

	const profileInfo = React.useMemo(() => (query && channel_type && !loading && !error ? (
		<div className="definition-expressions-profile-info">
			<div className="definition-expression-icon-holder">{getIconFromChannelType(channel_type)}</div>
			{avatar && <Avatar className="definition-expression-avatar" src={avatar} />}
			<a href={query} target="_blank" className="definition-expression-name" rel="noopener noreferrer">{name}</a>
		</div>
	) : null), [avatar, channel_type, error, getIconFromChannelType, loading, name, query]);

	const removeExpressionButton = React.useMemo(() => {
		return <Button
			id={`focusFeedSocialExpressionRemove${expressionKey}${index}`}
			className='definition-expression-remove-button'
			icon
			onClick={() => onRemoveProfileExpression(expressionKey, index, queryValue)}
		>
			delete
		</Button>;
	}, [expressionKey, index, queryValue, onRemoveProfileExpression]);

	let className = 'social-definition-expression profile-expression';
	if (index === 0) className += " is-first-expression";
	className += enabled ? " enabled" : " disabled";
	return (
		<div className={className}>
			<span className="definition-expression-label">{t('feed.form.or')}</span>
			{enableCheckbox}
			{queryInput}
			<Button className="social-definition-profile-expression-help-tooltip" icon tooltipLabel={t('feed.form.profile_tooltip')} tooltipPosition="right">help_outline</Button>
			{loading ? (<CircularProgress id="definitionExpressionProfileLoading" />) : null}
			{profileInfo}
			{removeExpressionButton}
		</div>
	);
};

export default withT(React.memo(FocusFeedDefinitionSocialExpressionProfile));
