import * as manager from './manager';
import * as tag from './tag';
import { combineReducers } from 'redux';

export const reducers = combineReducers({
	manager: manager.reducers,
	tag: tag.reducers
});

export interface State {
	manager: manager.State;
	tag: tag.State
};

export const INITIAL_STATE: State = {
	manager: manager.INITIAL_STATE,
	tag: tag.INITIAL_STATE
};
