import React from 'react';

import { isEmpty } from 'lib/imports/lodash';
import { Button, SelectionControl, FontIcon, Collapse } from 'lib/imports/react-md';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import { ProductType } from 'class/Feed';
import Autocompleter from 'components/common/Input/Autocompleter';
import { Excluded, ProductTypeItem } from 'types/focus/feed/definition/printDmr/productTypes';

type ProductTypeItemText = ProductTypeItem & {text: string};

const FocusFeedDefinitionLayoutProductType = (props: ComponentProps) => {
	const { productTypes, productTypeItems, t } = props;
	const { onAddExpression, onUpdateExpression, onRemoveExpression, onResetProductTypes, onFetchProductTypes } = props;

	const [collapse, setCollapse] = React.useState<boolean>(false);

	const getNamesText = React.useCallback((names?: string[]): string => {
		if (!names) return '';
		if (names.length === 1) return [...names, `(${t('categories.all_categories_and_products')})`].join(' > ');
		if (names.length === 2) return [...names, `(${t('categories.all_products')})`].join(' > ');
		return names.join(' > '); // length === 3
	}, [t]);

	const onUpdateItems = React.useCallback((value: string, currentProductType: ProductType) => {
		if (value.length < 2) return onResetProductTypes();

		const excluded: Excluded = {};
		productTypes.filter(productType => productType.id && currentProductType.id !== productType.id).forEach(productType => {
			let scope: keyof Excluded;
			if (productType.scope === 'print.product.type.id') scope = 'line.type.id';
			else if (productType.scope === 'print.product.line.id') scope = 'line.id';
			else if (productType.scope === 'print.product.subline.id') scope = 'id';
			else return;

			if (!excluded[scope]) excluded[scope] = [];
			excluded[scope]!.push(productType.id);
		});

		return isEmpty(excluded) ? onFetchProductTypes(value) : onFetchProductTypes(value, excluded);
	}, [productTypes, onFetchProductTypes, onResetProductTypes]);

	const items: ProductTypeItemText[] = React.useMemo(() => productTypeItems.map(productType => ({...productType, text: getNamesText(productType.names)})), [productTypeItems, getNamesText]);

	const getExpressionComponent = React.useCallback((index: number, productType: ProductType) => (
		<div key={`productType-${index}`}
			className={`definition-expression content-expression ${productType.enabled ? 'enabled' : 'disabled'}`}
		>
			<SelectionControl
				id={`focusFeedDefinitionExpressionEnableProductType-${index}`}
				name={`focusFeedDefinitionExpressionEnableProductType-${index}`}
				aria-label={`focusFeedDefinitionExpressionEnableProductType-${index}`}
				className={`definition-expression-enabled ${productType.enabled ? 'checkbox-green' : ''} `}
				type="checkbox"
				checked={productType.enabled}
				onChange={val => onUpdateExpression(index, { ...productType, enabled: val as boolean })}
			/>
			<span className="definition-expression-label">
				{`${index > 0 ? t('feed.form.or') : ''} ${t('feed.form.label.product_types')}:`}
			</span>
			<Autocompleter id={`productTypeAutocompleter-${index}`} className="definition-expressions-query"
				items={items}
				defaultText={getNamesText(productType.names)}
				onChange={value => onUpdateItems(value, productType)}
				onSelected={item => {
					const [id, names, scope] = item ? [item.id, item.names, item.scope] : ['', [], ''];
					onUpdateExpression(index, { ...productType, id, names, scope });
				}}
				onBlur={() => onResetProductTypes()}
				disabled={!productType.enabled}
			/>
			<Button id={`focusFeedProductTypeRemove-${index}`}
				className="definition-expression-remove-button"
				icon
				onClick={() => onRemoveExpression(index)}
			>delete</Button>
		</div>
	), [items, onRemoveExpression, onUpdateExpression, getNamesText, onUpdateItems, onResetProductTypes, t]);

	return (
		<div id="focusFeedDefinitionProductTypesSection" className="definition-section">
			<div className="definition-section-header">
				<div className="definition-header-title definition-header-title-product-types" onClick={() => setCollapse(!collapse)}>
					{
						collapse ?
							<FontIcon className="definition-header-title-toggle">keyboard_arrow_right</FontIcon> :
							<FontIcon className="definition-header-title-toggle">keyboard_arrow_down</FontIcon>
					}
					<span className="definition-header-title-text"> {t('feed.form.product_types')} </span>
				</div>
			</div>
			<Collapse collapsed={collapse}>
				<div className="definition-section-content">
					<div className="definition-expressions">
						{
							productTypes.map((productType, index) => getExpressionComponent(index, productType))
						}
						<div className="definition-add-expression">
							<Button
								id="focusFeedDefinitionAddProductTypeBtn"
								className="definition-add-expression-button"
								flat
								onClick={() => { onAddExpression({ id: '', enabled: true }); }}
							>
								<span>+</span> {t('feed.form.button.add_product_types')}
							</Button>
						</div>
					</div>
				</div>
			</Collapse>
		</div>
	);
};

export default withT(React.memo(FocusFeedDefinitionLayoutProductType));
