import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { operators } from 'store/app/notifications';
import { State } from 'store/types';
import { Notification } from 'types/notification';
import { DispatchProps, StateProps } from './types';

import Notifications from './Notifications';

const mapStateToProps = ({ app: { notifications: { queue } } }: State): StateProps => ({
	notifications: queue
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onAddNotification: (notification: Notification) => dispatch(operators.add({ notification })),
	onUnqueueNotification: () => dispatch(operators.unqueue())
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
