import { all, takeLatest, call, select, put } from 'redux-saga/effects';
import { isEmpty, keys, concat, forEach } from 'lib/imports/lodash';
import moment from 'moment-timezone';
import isURL from 'validator/lib/isURL';
import URLParse from 'url-parse';
import { truncate } from 'lodash';

import Api from 'lib/ajax/Api';
import Mekong from 'lib/ajax/Mekong';
import i18n from 'lib/i18n';
import format from "lib/format";
import { State } from 'store/types';
import { operators, Actions } from './actions';
import { operators as notificationsOperators } from 'store/app/notifications';
import { operators as insertOperators } from 'store/article/insert';
import { operators as searchOperators } from 'store/search/results/actions';
import { operators as tagOperators } from 'store/entities/Tag/actions';
import { Focus, FocusObject } from 'class/Focus';
import { FeedType } from 'class/Feed';
import { convertReverse } from 'lib/currency';
import { getInsertMentionSearchParams } from 'lib/searchParams';
import { InsertSearchLevel, InsertForm } from 'types/article/insert';

import { BulkDocument, DocumentObject } from 'class/Document';
import { getCategoryName } from 'store/entities/Category/selectors';
import { DocParams } from 'store/search/results';

import formSagas from './form/sagas';

export default function* sagas() {
	yield all([
		formSagas(),
		takeLatest(operators.changeStep.type, changeStep),
		takeLatest(operators.setSelectedFeedId.type, setSelectedFeedId),
		takeLatest(operators.checkUrl.type, checkUrl),
		takeLatest(operators.addDocumentImage.type, addDocumentImage),
		takeLatest(operators.getBulkTemplate.type, getBulkTemplate),
		takeLatest(operators.bulkImportDocuments.type, bulkImportDocuments)
	]);
}

function* changeStep({ payload: { step, form: documentForm } }: Actions["ChangeStep"]) {
	if (['SELECT_TYPE', 'SELECT_FEED'].includes(step)) {
		yield put(operators.setStep({ step }));
	}
	else if (step === "SELECT_FOUND_DOCUMENTS") {
		yield __selectFoundDocuments();
	} else if (step === "BULK_PREVIEW") {
		yield __sendBulkFile();
	} else if (step === "INSERT_DOCUMENTS") {
		try {
			yield __insertDocuments();
		} catch (error) {
			yield put(operators.changeStepError({ error }));
		}
	} else if (step === "CREATE_DOCUMENT") {
		if (!documentForm) return;
		try {
			yield __createDocument(documentForm);
		} catch (error) {
			yield put(operators.changeStepError({ error }));
		}
	} else if (step === "REPORT_MISSING_ARTICLE" || step === "REPORT_MISSING_MEDIA") {
		yield __reportMissingDocument();
	} else {
		yield put(operators.changeStepError({ error: {} }));
	}
}

function* getBulkTemplate({ payload: { selectedFeeds, type } }: Actions["GetBulkTemplate"]) {
	const url = `/v1/document/insert/template`;
	const templateBlob: Blob = yield call(Mekong.post, url, {
		data: {
			feed_ids: selectedFeeds,
			type: type === 'print' ? 'print_dmr' : type
		},
		responseType: 'blob'
	});
	yield put(operators.setTemplateBlob({ templateBlob }));
}

function* __sendBulkFile() {
	const formData = new FormData();
	const bulkFile: File = yield select((state: State) => state.article.insert.file);
	let bulkType: string = yield select((state: State) => state.article.insert.type);
	if (bulkType === 'print') bulkType = 'print_dmr';
	const state: State = yield select((state: State) => state);

	formData.append('mime', bulkFile.type);
	formData.append('file', bulkFile, bulkFile.name);
	const requestObject = {
		headers: { 'Content-Type': 'multipart/form-data' },
		params: { type: bulkType },
		data: formData
	};

	try {
		const response: {
			valid: BulkDocument[],
			invalid: BulkDocument[],
			errorFileUrl: string,
			uploadedFileName: string
		} = yield call(Mekong.post, '/v1/document/insert/upload', requestObject);
		if (bulkType === "online" || bulkType === "socialmedia") {
			yield put(operators.setStep({ step: ("INSERT_DISPATCHED") }));
		} else {
			yield put(operators.setBulkDocumentsSuccess({
				documents: __formatBulkPreviewDocuments(state, response.valid),
				totalErrors: response.invalid.length,
				errorFileUrl: response.errorFileUrl,
				bulkInsertS3Path: response.uploadedFileName
			}));
			yield put(operators.setStep({ step: ("BULK_PREVIEW") }));
		}
	} catch (error) {
		const errorObj = error as { code: string };
		if (errorObj.code && errorObj.code === 'TEMPLATE_INCONSISTANT_CURRENCY') {
			yield put(notificationsOperators.add({ notification: { t: "insert_article.modal.bulk.currency_inconsistent", level: "warning" } }));
		}
		else yield put(operators.changeStepError({ error: errorObj }));
	}
}

function __formatBulkPreviewDocuments(state: State, validDocuments: BulkDocument[]) {
	return validDocuments.map(doc => {
		const productText = getCategoryName(state, doc.category.id);
		const brand = `<span class="bold">${i18n.t('doc.company.not_aplicable')}</span> -`;
		const product = `${i18n.t('doc.categorization.sector')} <span class="bold">${productText}</span>`;
		const pageIssue = `${i18n.t('results.content.print.page')} ${doc.page ? doc.page : '-'}, ${i18n.t('results.content.print.issue')} ${doc.editionNumber ? doc.editionNumber : '-'},`;
		const occupation = ' 100' + i18n.t('results.content.print.page_occupation');
		const getShortTitle = (title: string) => {
			const shorTitle = truncate(title, { length: 60, separator: /[,?!\s]+/, omission: '' });
			const join = shorTitle.length === title.length ? '' : '...';
			return shorTitle.trim() + join;
		};
		doc.title = getShortTitle(doc.title);
		doc.content = `${brand} ${product}</br> ${pageIssue}${occupation}`;
		doc.formatedData = {
			date: {
				title: format.date.docFromFormat(doc.date),
				value: format.date.localeTitleFromFormat(doc.date)
			},
			miv: {
				value: format.number.singleCurrency(doc.miv),
				title: format.number.singleCurrency(doc.miv, 'locale')
			},
			reach: {
				value: format.number.metric(+doc.circulation),
				title: format.text.singleCounter(doc.circulation, 'audience')
			}
		};
		return doc;
	});
}

function* __selectFoundDocuments() {
	try {
		const url: string = yield select((state: State) => state.article.insert.url);
		const feedId: string = yield select((state: State) => state.article.insert.selectedFeed!.id);
		const feedType: FeedType = yield select((state: State) => state.article.insert.selectedFeed!.type);

		if (feedType.match("print") || feedType.match("print_dmr")) return yield put(operators.setStep({ step: "FILL_FORM" }));

		// Check url
		yield put(operators.setLoading({ loading: true }));
		const { url: finalUrl, reason, valid, provider } = yield call(Mekong.get, '/v1/document/urlCheck', { params: { url, type: feedType } });

		if (!valid) {
			if (reason === "story") {
				yield put(operators.setFinalUrl({ url }));
				yield put(operators.setSelectedUrl({ url: url }));
				yield put(operators.setStep({ step: "FILL_FORM" }));
			}
			else yield put(operators.setUrlError({ invalidError: 'insert_article.modal.url_error.' + reason }));
			return yield put(operators.setLoading({ loading: false }));
		}

		let verifiedUrl: string;
		if (__isDomainUrl(finalUrl)) verifiedUrl = url; // If checkUrl returns a domain url, fallback to user inserted url
		else verifiedUrl = finalUrl;

		yield put(operators.setFinalUrl({ url: verifiedUrl }));
		yield put(operators.setSelectedUrl({ url: verifiedUrl }));
		if (feedType === "socialmedia") yield __searchForSocialDocuments(finalUrl, feedId, provider);
		else yield __searchForOnlineDocuments(url, verifiedUrl, feedId, provider);
	} catch (error) {
		yield put(operators.changeStepError({ error }));
	}
}

function* __reportMissingDocument() {
	try {
		const media = (yield select((state: State) => state.article.insert.documentExists)) || { url: null, id: null };
		const feed_id = yield select((state: State) => state.article.insert.selectedFeed!.id);
		const insert_url = yield select((state: State) => state.article.insert.finalUrl);
		yield call(Mekong.post, '/v1/mediaSource/online/notifyMissing', {
			data: {
				insert_url: insert_url,
				media_url: media.url,
				media_id: media.id,
				feed_id: feed_id
			}
		});
		if (media.id) yield put(operators.setStep({ step: "REPORT_MISSING_ARTICLE" }));
		else yield put(operators.setStep({ step: "REPORT_MISSING_MEDIA" }));
	} catch (error) {
		yield put(operators.changeStepError({ error }));
	}
}

function __isDomainUrl(finalUrl: string): boolean {
	const regex = /\/$/;
	return finalUrl.replace(regex, '') === URLParse(finalUrl).origin;
}

function* setSelectedFeedId({ payload: { id } }: Actions["SetSelectedFeedId"]) {
	const focusList: FocusObject[] = yield select((state: State) => state.focus.list.focusList);

	const feed = Focus.findFeedInFocusList(id, focusList);

	yield put(operators.setSelectedFeed({ id, type: feed!.type }));
}

function* checkUrl({ payload }: Actions["SetUrl"]) {
	const url = yield select((state: State) => state.article.insert.url);

	const invalidError = !isEmpty(url) && !isURL(url, { require_protocol: true }) ? 'error.url_not_valid' : false;

	yield put(operators.setUrlError({ invalidError }));
}

function* __searchForSocialDocuments(url: string, feedId: string, provider: string = "*") {
	//TODO: Remove once url is properly analyzed in historical & tenant indexes.
	const providerTransformations = ['31', '52', '62'].includes(provider) ? provider : '*';
	const feedDocuments = yield __searchDocument(url, null, feedId, 'feed', 'socialmedia', providerTransformations);
	if (!isEmpty(feedDocuments)) return yield __setUrlFound([], [], feedDocuments);
	const tenantDocuments = yield __searchDocument(url, null, feedId, 'tenant', 'socialmedia', providerTransformations);
	if (!isEmpty(tenantDocuments)) return yield __setUrlFound([], tenantDocuments, []);
	const historicalDocuments = yield __searchDocument(url, null, feedId, 'global', 'socialmedia', providerTransformations);
	if (!isEmpty(historicalDocuments)) return yield __setUrlFound(historicalDocuments, [], []);

	const response = yield __performSocialMediaAPISearch(url);
	if (response.error) return yield __setUrlNotFound("socialmedia", response.error.code);
	const apiDocuments = yield __getDocuments([response.id]);
	if (apiDocuments && !isEmpty(apiDocuments)) return yield __setUrlFound(apiDocuments, [], []);
	return yield __setUrlNotFound("socialmedia", "not_found");
}

function* __searchForOnlineDocuments(formUrl: string, finalUrl: string, feedId: string, provider: string = "*") {
	// Note: For some reason yield all ({call()..call()..call()})  is not posible to test via provide
	const { tenantDocuments, feedDocuments, historicalDocuments } = yield all({
		feedDocuments: yield __searchDocument(formUrl, finalUrl, feedId, 'feed', 'online', provider),
		tenantDocuments: yield __searchDocument(formUrl, finalUrl, feedId, 'tenant', 'online'),
		historicalDocuments: yield __searchDocument(formUrl, finalUrl, feedId, 'global', 'online')
	});

	if (isEmpty(concat(tenantDocuments, feedDocuments, historicalDocuments))) {
		const documentExists = yield call(Mekong.get, '/v1/mediaSource/online/exists', { params: { url: encodeURIComponent(finalUrl) } });
		yield put(operators.setDocumentExists(documentExists));
		return yield __setUrlNotFound("online", 'missing_online_document');
	}
	return yield __setUrlFound(historicalDocuments, tenantDocuments, feedDocuments);
}

function* __searchDocument(formUrl: string, finalUrl: string | null, feedId: string, level: InsertSearchLevel, feedType: FeedType, provider: string = "*") {
	const api = new Api();

	const params = getInsertMentionSearchParams(formUrl, finalUrl, level, feedId, provider);

	if (level === "feed" || level === "tenant") {
		const { documents: documentIds } = yield call([api, 'get'], '/documents/search', { params });
		return yield __getDocuments(documentIds);
	} else {
		params.type = feedType;
		const { documents: documentIds } = yield call([api, 'get'], '/documents/search/historical', { params });
		return yield __getDocuments(documentIds);
	}
}

function* __insertDocuments() {
	const selectedDocumentIds = yield select((state: State) => state.article.insert.selectedDocumentIds);
	const feedId: string = yield select((state: State) => state.article.insert.selectedFeed!.id);

	// No selected documents, nothing to do
	if (isEmpty(keys(selectedDocumentIds))) return;

	yield put(operators.setLoading({ loading: true }));

	yield all(
		keys(selectedDocumentIds).map(documentId => call(Mekong.put, `/v1/document/${documentId}`, { params: { feed: feedId } }))
	);

	yield put(operators.setStep({ step: "INSERT_DOCUMENTS" }));
	yield __reset();
}

function* __addNewTagsToORM(tagCreationResponse: any) {
	const newCreatedTags: any[] = tagCreationResponse.map((newTag: any) => ({ // FIXME GIS-4405 types -> newCreatedTags: TagData[]
		id: newTag.id,
		topic: newTag.topic_id,
		name: newTag.name,
		tag_automation: undefined
	}));
	yield put(tagOperators.create(newCreatedTags));
}

function* __createDocument(documentForm: InsertForm) {
	const feed: { id: string, type: FeedType } = yield select((state: State) => state.article.insert.selectedFeed);
	if (!feed) return;

	const isPrintFeed = feed.type === 'print';
	const isPrintDmrFeed = feed.type === 'print_dmr';
	const isSocialFeed = feed.type === 'socialmedia';

	const url = yield select((state: State) => state.article.insert.selectedUrl);
	if (!url && !isPrintFeed && !isPrintDmrFeed) return;

	yield put(operators.setLoading({ loading: true }));

	const api = new Api();
	const { tags, category, ...formContent } = documentForm;
	const requestObject: any = {};
	let sendForm;

	if (isPrintFeed || isPrintDmrFeed || isSocialFeed) {
		const formData = new FormData();
		forEach(formContent, (value: any, key) => {
			if (value) {
				if (key === 'image') {
					formData.append('mime', (value as File).type);
					formData.append('file', value, (value as File).name);
				} else formData.append(key, String(value));
			}
		});
		formData.append('category', category);
		sendForm = formData;
		requestObject.headers = { 'Content-Type': 'multipart/form-data' };
	} else sendForm = { ...formContent, url };

	requestObject.params = { feed: feed.id };
	requestObject.data = sendForm;

	let docKey;
	if (isPrintDmrFeed) {
		const { CLAU } = yield call([api, 'post'], `/documents/create/printdmr`, requestObject);
		docKey = CLAU;
	} else {
		const { CLAU } = yield call([api, 'post'], `/documents/create/${isPrintFeed ? 'print' : feed.type}`, requestObject);
		docKey = CLAU;
	}

	const data = {
		documentIds: [docKey],
		tagIds: tags.tagIds
	};

	if (!isEmpty(tags.newTagNames)) {
		const tagCreationResponse = yield call(Mekong.post, "/v1/tag/multiple", { data: { tags: tags.newTagNames } });
		if (tagCreationResponse) {
			yield __addNewTagsToORM(tagCreationResponse);
			const newTagIdsToInsert: string[] = tagCreationResponse.map((newTag: any) => (`${newTag.topic_id}_${newTag.id}`));
			data.tagIds = data.tagIds.concat(newTagIdsToInsert);
		}
	}
	yield all([
		!isEmpty(data.tagIds) ? call(Mekong.post, "/v1/document/tag", { data }) : null,
		category && !isPrintFeed && !isPrintDmrFeed ? call([api, 'put'], "/documents/categories", { params: { ids: docKey, category } }) : null
	]);

	yield __reset();

	yield put(searchOperators.fetchSearch());
}

function* __reset() {
	const continueInsert: boolean = yield select((state: State) => state.article.insert.continueInsert);
	yield put(notificationsOperators.add({ notification: { t: "insert_article.success", level: "success" } }));
	if (!continueInsert) yield put(insertOperators.setShowDialog({ show: false }));
	else {
		const type: FeedType = yield select((state: State) => state.article.insert.type);
		yield put(operators.resetState({
			type,
			showDialog: true,
			step: 'SELECT_FEED'
		}));
	}
}

function* __getDocuments(documentIds: string[]) {
	if (isEmpty(documentIds)) return [];

	const documentsParam: DocParams[] = documentIds.map(documentId => {
		const docParam: DocParams = { id: documentId };
		docParam.highlight = ""; // No highlight due we're searching by url
		return docParam;
	});

	const data: { documents: DocParams[], highlight?: string, formatter: string } = { documents: documentsParam, formatter: "discover" };
	const apiDocuments: DocumentObject[] = yield call(Mekong.post, '/v1/document/format', { data });
	return apiDocuments;
}

function* __performSocialMediaAPISearch(url: string) {
	const apiNap = new Api('documents'); // TODO: Change to ter when endpoint to search to discoverDocuments cluster is implemented

	const response = yield call([apiNap, 'get'], '/documents/socialMediaSearch', { params: { url } });
	return response;
}

function* __setUrlFound(
	historicalDocuments: DocumentObject[], tenantDocuments: DocumentObject[], feedDocuments: DocumentObject[]
) {
	yield put(operators.setLoading({ loading: false }));
	yield put(operators.setHistoricalDocuments({ historicalDocuments }));
	yield put(operators.setFeedDocuments({ feedDocuments }));
	yield put(operators.setTenantDocuments({ tenantDocuments }));
	yield put(operators.setStep({ step: "SELECT_FOUND_DOCUMENTS" }));
}

function* __setUrlNotFound(feedType: FeedType, reason: string) {
	yield put(operators.setLoading({ loading: false }));
	yield put(operators.setDocumentNotFoundReason({ reason }));
	const onlineNotFound = feedType === "online" && reason === "missing_online_document";
	const socialNotFound = feedType === "socialmedia" && (reason === "not_found" || reason === "unavailable");
	if (onlineNotFound || socialNotFound) {
		yield put(operators.setStep({ step: "FILL_FORM" }));
	} else {
		yield put(operators.setStep({ step: "SELECT_FOUND_DOCUMENTS" }));
	}
}

function* addDocumentImage({ payload: { image, imageType, docIndex } }: Actions["AddDocumentImage"]) {
	const bulkInsertS3Path: string = yield select((state: State) => state.article.insert.bulkInsertS3Path);

	const formData = new FormData();
	formData.append('mime', image.type);
	formData.append('file', image, image.name);
	formData.append('bucketPath', bulkInsertS3Path);
	formData.append('imageType', imageType);
	const requestObject = {
		headers: { 'Content-Type': 'multipart/form-data' },
		params: { type: 'print_dmr' },
		data: formData
	};

	const { insertUploadImage } = yield call(Mekong.post, '/v1/document/insert/upload/image', requestObject);
	yield put(operators.addDocumentImageSuccess({ docIndex, imagePath: insertUploadImage, imageType }));
}

function* bulkImportDocuments() {
	const bulkPreviewDocuments: BulkDocument[] = yield select((state: State) => state.article.insert.bulkPreviewDocuments);
	const type: FeedType = yield select((state: State) => state.article.insert.type);
	if (!bulkPreviewDocuments.length) return;

	const now = moment();
	try {
		const cleanedDocuments = bulkPreviewDocuments.map(doc => ({
			title: doc.title,
			mediaName: doc.mediaName,
			page: doc.page,
			editionNumber: doc.editionNumber,
			circulation: doc.circulation,
			date: doc.date,
			tags: (doc.tags || []).map(tag => tag.id),
			feed: doc.feed.id,
			country: doc.country ? doc.country.id : null,
			category: doc.category ? doc.category.id : null,
			cover: doc.coverImage,
			image: doc.pageImage,
			miv: convertReverse(doc.miv as unknown as number, moment(doc.date, 'DD/MM/YYYY').set('hour', now.get('hour')).tz('UTC').toISOString())
		}));
		yield call(Mekong.post, "/v1/document/create", { data: { documents: cleanedDocuments, type } });
		yield put(notificationsOperators.add({ notification: { t: "insert_article.bulk_success", level: "success" } }));

		yield put(operators.setLoading({ loading: false }));
		yield put(insertOperators.setShowDialog({ show: false }));

	} catch (error) {
		yield put(operators.changeStepError({ error: error as object }));
	}
}
