import React, { useCallback } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Button } from 'lib/imports/react-md';

import { withT } from 'lib/i18n';
import FletcherLoader from 'components/common/Fletcher/Loader';
import { ComponentProps } from './types';
import TopicManager from '../Manager';
import TopicTagManager from '../Tag/Manager';

import './TopicManagerLayout.scss';

const TopicManagerLayout = (props: ComponentProps) => {
	const { t, topicId, onMigrateTag } = props;

	const handleDragEnd = useCallback((data: DropResult) => {
		if(!data || !data.destination || isNaN(+data.destination.droppableId)) return false;
		onMigrateTag(data.draggableId, data.destination.droppableId);
	}, [onMigrateTag]);

	return (
		<div id="topicManagerLayout" className="discover-section">
			<div className="discover-section-header">
				<div className="discover-section-header-title">{t('tags.title')}</div>
				<Button
					className="form-sort-by-help"
					icon tooltipLabel={<span dangerouslySetInnerHTML={{ __html: t('tags.help') }}></span>} tooltipPosition="bottom">help_outline</Button>
			</div>
			<FletcherLoader resource="topics">
				<div className="discover-section-content">
					<DragDropContext onDragEnd={handleDragEnd}>
						<TopicManager id={topicId} />
						<TopicTagManager topicId={topicId} />
					</DragDropContext>
				</div>
			</FletcherLoader>
		</div>
	);
};

export default withT(React.memo(TopicManagerLayout));
