import React from 'react';
import { Button, FontIcon } from 'react-md';

import { withT } from 'lib/i18n';
import DocumentItem from 'components/common/Document/Item/DocumentItem';
import { ComponentProps } from './types';
import BulksPreviewTags from './Tags';
import ImageDropzone, { AddFileError } from 'components/common/ImageDropzone';

import coverImage from 'vendor/svg/document/insert/ico_cover.svg';
import pageImage from 'vendor/svg/document/insert/ico_page.svg';

import './ArticleBulkPreview.scss';

const ArticleBulkPreview = (props: ComponentProps) => {
	const { type, documents, totalErrors, errorFileUrl, tenant } = props;
	const { t, onClose, onAddNotification, onBulkImport, onAddDocumentImage, onRemoveDocumentImage } = props;

	const [showDownloadErrorsMsg, setShowDownloadErrorsMsg] = React.useState(false);
	const [showPageImgError, setShowPageImgError] = React.useState(false);

	React.useEffect(() => {
		setShowDownloadErrorsMsg(totalErrors > 0);
	}, [totalErrors]);

	function requestErrorFileUrl(errorFileUrl: string) {
		window.location.href = errorFileUrl;
	};

	const feedTypeName = React.useMemo(() => {
		switch(type) {
			case 'online': return t('type.online');
			case 'socialmedia': return t('type.socialmedia');
			case 'print':
			case 'print_dmr':
			default: return t('type.print');
		}
	}, [t, type]);

	const onAddImageError = React.useCallback((error: AddFileError) => {
		switch(error.error) {
			case 'file_too_large': return onAddNotification({ text: t('newsletter.digest.edit.custom_image.rejected_size', { sizeInMb: 15000000 / 1000000 }), level: 'warning' });
			case 'incorrect_file_type': return onAddNotification({ text: t('newsletter.digest.edit.custom_image.rejected_type'), level: 'warning' });
			default: return onAddNotification({ t: 'newsletter.digest.edit.custom_image.upload_error', level: 'warning' });
		}
	}, [onAddNotification, t]);

	const onImportDocuments = React.useCallback(() => {
		const notAllPageImgs = documents.find(doc => !doc.pageImage);
		if (notAllPageImgs !== undefined) {
			setShowPageImgError(true);
			return onAddNotification({ text: t('document.bulk_insert.notification.missing_images'), level: 'warning' });
		}
		return onBulkImport();
	}, [documents, onBulkImport, onAddNotification, t]);

	const header = React.useMemo(() => {
		return <div className="bulkPreview-header">
			<span id="bulkInsertHeader" className='bulkPreview-centered'>{t('document.bulk_insert.header.message', { valid: documents.length })}</span>
			<div className="bulkPreview-header-btns">
				<Button flat id="insertBulkDocumentDialogCancelBtn" onClick={() => onClose()}>{t('form.cancel')}</Button>
				<Button flat id="insertBulkDocumentDialogAcceptBtn" onClick={onImportDocuments}>{t('document.bulk_insert.header.import_button')}</Button>
			</div>
		</div>;
	}, [t, documents.length, onImportDocuments, onClose]);

	const downloadErrorsMsg = React.useMemo(() => {
		return (
			<div key={'bulkPreview-downloadErrors'} className='bulkPreview-downloadErrors bulkPreview-centered'>
				<div className='bulkPreview-downloadErrors-message'>
					<FontIcon>error_outline</FontIcon>
					<div className='bulkPreview-downloadErrors-body'>
						<div className='alert-header'>{t('document.bulk_insert.error.header', { failures: totalErrors, total: totalErrors + documents.length })}</div>
						<div className='alert-message'>{t('document.bulk_insert.error.message')}</div>
					</div>
				</div>
				<div key='bulkPreview-downloadErrors-buttons' className='bulkPreview-downloadErrors-buttons'>
					<Button id="bulkErrorsDownloadButton" primary flat onClick={() => requestErrorFileUrl(errorFileUrl)}>{t('document.bulk_insert.error.button')}</Button>
					<Button id="bulkErrorsCloseButton" className="close-btn btn--no-background" icon primary onClick={() => setShowDownloadErrorsMsg(false)}>clear</Button>
				</div>
			</div>
		);
	}, [t, totalErrors, documents, errorFileUrl]);

	const body = React.useMemo(() => {
		return documents.map((doc, idx) => {
			return (
				<div key={`bulkPreview-${idx}-container`} className='bulkPreview-container bulkPreview-centered'>
					<div key={`bulkPreview-${idx}-feed`} className='bulkPreview-container-feed'>{doc.feed.name} - {feedTypeName}</div>
					<div className='bulkPreview-container-item'>
						<DocumentItem key={`bulkPreview-${idx}`} document={doc} tenant={tenant}/>
						<div key={`bulkPreview-${idx}-uploadImg`} className='bulkPreview-container-uploadImg'>
							<ImageDropzone
								key={`bulkPreview-${idx}-uploadImg-cover`}
								fileInputLabel={t('document.bulk_insert.upload_image.input_label')}
								className='bulkPreview-container-uploadImg-first'
								message={t('document.bulk_insert.upload_image.img_cover')}
								icon={coverImage}
								maxSize={15000000}
								onAddFilesError={error => onAddImageError(error)}
								onFileAdded={file => { setShowPageImgError(false); onAddDocumentImage(file, 'cover', idx); }}
								onRemoveFile={_ => onRemoveDocumentImage('cover', idx)}
							/>
							<ImageDropzone
								key={`bulkPreview-${idx}-uploadImg-page`}
								fileInputLabel={t('document.bulk_insert.upload_image.input_label')}
								message={t('document.bulk_insert.upload_image.img_page')}
								icon={pageImage}
								maxSize={15000000}
								showError={showPageImgError}
								onAddFilesError={error => onAddImageError(error)}
								onFileAdded={file => { setShowPageImgError(false); onAddDocumentImage(file, 'page', idx); }}
								onRemoveFile={_ => { setShowPageImgError(false); onRemoveDocumentImage('page', idx); }}
							/>
							<BulksPreviewTags key={`bulkPreviewTag-${idx}`} bulkdDocument={doc} />
						</div>
					</div>
				</div>);
		});
	}, [tenant, documents, feedTypeName, onAddDocumentImage, onAddImageError, onRemoveDocumentImage, showPageImgError, t]);

	return (
		<div className='bulkPreview' id='bulkPreview'>
			{header}
			{showDownloadErrorsMsg && downloadErrorsMsg}
			<div className={`bulkPreview-mandatoryMsg ${showPageImgError ? 'error' : ''}`}>
				<div>{t('document.bulk_insert.mandatory_page')}</div>
			</div>
			{body}
		</div>
	);
};

export default withT(ArticleBulkPreview);
