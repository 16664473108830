import React, { ReactNode } from "react";
import { Button, Divider, FontIcon } from "react-md";
import { concat, findKey, flatMap, keys, map, mapValues, reduce, startCase, toLower, truncate, uniqBy } from "lodash";
import { withT } from "lib/i18n";
import CircularProgress from 'react-md/lib/Progress/CircularProgress';
import Drawer from 'react-modern-drawer';
import { parseISO, format } from 'date-fns';

import { groupFacets } from "lib/searchParams";
import { Facet } from "class/Facet";
import { FilterFacet } from "class/Filter";

import { Filters } from "../Manager/types";
import { ComponentProps } from "./types";
import { FiltersParams } from "types/search/form";

import 'react-modern-drawer/dist/index.css';
import './TagDetails.scss';
import { TagObject } from "store/entities/Tag";

const TagDetails = (props: ComponentProps) => {
	const { tag, isVisible, languages, countries, categories, events, focus, loading } = props;
	const { t, setVisible, onUpdateTagAutomation } = props;

	const [filters, setFilters] = React.useState<Filters | null>(null);
	const [definition, setDefinition] = React.useState<any | null>(null);
	const [tooltipInfo, setTooltipInfo] = React.useState<any | null>();

	const getTranslatedFacetItems = React.useCallback((values, filterField) => {
		return values.reduce((acc: any, value: string) => {
			return {
				...acc,
				[value]: t(`filters.${filterField}.${value}`)
			};
		}, {});
	}, [t]);

	const getChannelFacetItems = React.useCallback(() => {
		return getTranslatedFacetItems(
			concat(Facet.channelFacetTypes.online, Facet.channelFacetTypes.socialmedia, Facet.channelFacetTypes.print, Facet.channelFacetTypes.print_dmr),
			'channel'
		);
	}, [getTranslatedFacetItems]);

	const getPlacementTypeFacetItems = React.useCallback(() => {
		if (!definition || !definition['print.article_type_filter']) return;
		const placementItems = definition['print.article_type_filter'].split(',');
		return getTranslatedFacetItems(placementItems, 'article_type');
	}, [definition, getTranslatedFacetItems]);

	const getFeedsFacetItems = React.useCallback(() => {
		return reduce(flatMap(focus, item => item.feeds), (result: any, feed) => {
			if (!feed) return;
			result[feed.id] = feed.name;
			return result;
		}, {});
	}, [focus]);

	const getCountryFacetItems = React.useCallback(() => {
		return mapValues(countries, value => startCase(toLower(value)));
	}, [countries]);

	React.useEffect(() => {
		setTooltipInfo({
			'language.id': languages,
			'country_path': getCountryFacetItems(),
			'tenant.categories_id': categories,
			'print.event.id': events,
			'channel_type_id': getChannelFacetItems(),
			'insights.filter': getFeedsFacetItems(),
			'print.article_type': getPlacementTypeFacetItems()
		});
	}, [getChannelFacetItems, getFeedsFacetItems, getPlacementTypeFacetItems, getCountryFacetItems, languages, countries, categories, events, focus]);

	const encodeFilters = React.useCallback((definition: FiltersParams) => {
		const encodedFilters: { [key: string]: FilterFacet[] } = {};
		keys(definition).forEach(filterKey => {
			if (filterKey === 'query') return;

			const filterValues = definition[filterKey as keyof FiltersParams]!.split(',');
			const cleanFilterKey = filterKey.split('_filter')[0];
			encodedFilters[cleanFilterKey] = filterValues.map((value: string) => ({ key: value })) as FilterFacet[];

			switch (cleanFilterKey) {
				case Facet.channelGroupKey: {
					groupFacets(encodedFilters[cleanFilterKey], Facet.channelGroups);
					encodedFilters[cleanFilterKey] = uniqBy(encodedFilters[cleanFilterKey], 'key');
					break;
				}
				case Facet.insightsFilterKey: {
					encodedFilters[cleanFilterKey] = encodedFilters[cleanFilterKey].map((filter: FilterFacet) => {
						return filter.key.replace('feed:', '');
					}).join(',') as unknown as FilterFacet[];
					break;
				}
				case Facet.excludedFieldsKey: {
					encodedFilters[cleanFilterKey] = encodedFilters[cleanFilterKey].map((filter: FilterFacet) => {
						return filter.key;
					}).join(',') as unknown as FilterFacet[];
					break;
				}
				default: break;
			}
		});
		return encodedFilters;
	}, []);

	const parseFilters = React.useCallback((encodedFilters: { [key: string]: FilterFacet[] }) => {
		const notDisplayedFilters = [Facet.excludedFieldsKey];
		const filtersToParse = keys(encodedFilters).filter(key => !notDisplayedFilters.includes(key));
		return map(filtersToParse, key => {
			switch (key) {
				case Facet.insightsFilterKey: {
					const feedFilters = encodedFilters[Facet.insightsFilterKey] as unknown as string;
					const splittedFeedFilters = feedFilters.split(',');
					return { key: Facet.insightsFilterKey, count: splittedFeedFilters.length, ids: splittedFeedFilters };
				}
				default: return { key, count: encodedFilters[key].length, ids: encodedFilters[key].map((filter: FilterFacet) => filter.key) };
			}
		});
	}, []);

	const parseDefinition = React.useCallback((definition: string) => {
		const parsedDefinition = JSON.parse(definition);

		delete parsedDefinition[`${Facet.tagsGroupKey}_filter`];
		delete parsedDefinition[`${Facet.originGroupKey}_filter`];

		return parsedDefinition;
	}, []);

	React.useEffect(() => {
		if (!tag || !tag.tag_automation) return;
		const parsedTagDefinition = parseDefinition(tag.tag_automation.definition as string);
		const encodedFilters = encodeFilters(parsedTagDefinition);
		const parsedFilters = parseFilters(encodedFilters);

		setDefinition(parsedTagDefinition);
		setFilters(parsedFilters);
	}, [encodeFilters, parseFilters, parseDefinition, tag]);

	const getStartedOnText = (tag: TagObject<"tag_automation">) => {
		if (!tag || !tag.tag_automation || !tag.tag_automation.inserted_at) return '';
		const dateString = tag.tag_automation.inserted_at;
		const date = parseISO(dateString);
		return <p>{t('tags.automated_tag_info.started_on')}: {format(date, 'dd/MM/yyyy')}</p>;
	};

	function formatQueryString(query: string): (string | JSX.Element)[] {
		const keywords = ['AND NOT', 'AND', 'OR'];
		let elements: (string | JSX.Element)[] = [query];

		keywords.forEach((keyword, index) => {
			const newElements: (string | JSX.Element)[] = [];

			elements.forEach((element, elementIndex) => {
				if (typeof element === 'string') {
					const parts = element.split(new RegExp(`(\\s${keyword}\\s)`));
					parts.forEach((part, partIndex) => {
						if (part === ` ${keyword} `) {
							newElements.push(<strong key={`${index}-${elementIndex}-${partIndex}`}>{part}</strong>);
						} else if (part) {
							newElements.push(part);
						}
					});
				} else {
					newElements.push(element);
				}
			});

			elements = newElements;
		});

		return elements;
	};

	const buildArticleUrl = (tag: TagObject<"tag_automation">) => {
		if (!tag || !tag.tag_automation) return '';

		const encodedUrl = new URL(process.env.REACT_APP_URL + '/article');
		const definition = JSON.parse(tag.tag_automation.definition as string);
		const encodedFilters = encodeFilters(definition);

		if (definition.query && definition.query !== '') {
			encodedUrl.searchParams.append('query', definition.query);
		}
		if (encodedFilters[Facet.insightsFilterKey]) {
			encodedUrl.searchParams.append('feedFilters', encodedFilters[Facet.insightsFilterKey] as unknown as string);
			delete encodedFilters[Facet.insightsFilterKey];
		}
		if (encodedFilters[Facet.excludedFieldsKey]) {
			encodedUrl.searchParams.append('excluded_fields', encodedFilters[Facet.excludedFieldsKey] as unknown as string);
			delete encodedFilters[Facet.excludedFieldsKey];
		}

		encodedUrl.searchParams.append('facetFilters', JSON.stringify(encodedFilters));
		return encodedUrl.toString();
	};

	const getTooltipInfo = (key: string, ids: string[]): ReactNode => {
		if (!definition) return;

		const elements = ids.reduce((acc: ReactNode[], id: string) => {
			const element = tooltipInfo[key] && tooltipInfo[key][id];

			if (element) {
				const elementId = findKey(tooltipInfo[key], val => val === element);
				acc.push(<p className="tooltip-item" id={elementId} key={element}>{truncate(element, { length: 24 })}</p>);
			}
			return acc;
		}, []);

		const hasMoreThanTen = elements.length > 10;
		const elementsToShow = hasMoreThanTen ? elements.slice(0, 10) : elements;

		if (definition[Facet.excludedFieldsKey] && definition[Facet.excludedFieldsKey].includes(key)) {
			elementsToShow.unshift(
				<p className="tooltip-item" key={`excluded-${key}`}>
					<b>{t('tags.automated_tag_info.excluded')}:</b>
				</p>
			);
		}

		if (hasMoreThanTen) {
			elementsToShow.push(
				<p className="tooltip-item" key={`more-${key}`}>
					{`+${elements.length - 10} ${t('tags.automated_tag_info.more')}...`}
				</p>
			);
		}

		return elementsToShow.length > 0 ? elementsToShow : undefined;
	};

	const checkTooltipable = (key: string) => {
		return keys(tooltipInfo).includes(key);
	};

	const startStopButton = React.useMemo(() => {
		const updateTagAutomation = () => {
			onUpdateTagAutomation(tag.id, tag.tag_automation.id, !tag.tag_automation.enabled, {});
		};
		if (!tag || !tag.tag_automation) return null;

		const { enabled } = tag.tag_automation;
		const stopStartIcon = enabled ? 'stop' : 'play_arrow';

		return (
			<Button
				flat
				id="tagAutomationStartStop"
				disabled={loading}
				className={`start-stop-btn ${!enabled && 'start'}`}
				onClick={updateTagAutomation}
			>
				{loading && <CircularProgress id="loadingAutomateTagStartStop" className={enabled ? "loader" : "loader white-loader"} />}
				{loading ? null : <FontIcon>{stopStartIcon}</FontIcon>}
				{enabled ? t('tags.automated_tag_info.stop') : t('tags.automated_tag_info.start')}
			</Button>
		);
	}, [loading, tag, onUpdateTagAutomation, t]);

	const tagItemContent = (tag: TagObject<"tag_automation">) => {
		return (
			<>
				<Button flat id="tagAutomationViewPlacements" href={buildArticleUrl(tag)} target="_blank" rel="noopener noreferrer">
					{t('tags.automated_tag_info.view_placements')}
					<FontIcon>open_in_new</FontIcon>
				</Button>
				<div className="header">
					<h1>{tag.name}</h1>
				</div>
				<h2>{t('tags.automateg_tag_info.definition_preview_title')}</h2>
				<p>{t('tags.automateg_tag_info.definition_preview_text')}</p>
				<div>
					<h3>{t('tags.automated_tag_info.query_title')}</h3>
					<p className="query" id="tagInformationQuery">({definition && definition.query ? formatQueryString(definition.query) : t('tags.automated_tag_info.no_query')})</p>
				</div>
				{filters && filters.length !== 0 &&
					<div>
						<h3>{t('tags.automated_tag_info.filters_title')}</h3>
						<div className="filter-box-container">
							{
								map((filters), filter => {
									if (checkTooltipable(filter.key)) {
										return (
											<Button
												flat
												disabled={true} //This is to hide all css focus effects and animations
												className="filter-tooltip-button"
												tooltipLabel={getTooltipInfo(filter.key, filter.ids)}
												tooltipPosition="bottom"
												key={filter.key}
											>
												<div className="filter-box">
													<p className="filter-box-name">{t(`tag_manager.${filter.key}_filter`)}</p>
													<p className="filter-box-number">{filter.count}</p>
												</div>
											</Button>
										);
									} else {
										return (
											<div className="filter-box" key={filter.key}>
												<p className="filter-box-name">{t(`tag_manager.${filter.key}_filter`)}</p>
												<p className="filter-box-number">{filter.count}</p>
											</div>
										);
									}
								})
							}
						</div>
					</div>
				}
				<Divider className="divider" />
				{startStopButton}
				<div>{getStartedOnText(tag)}</div>
			</>
		);
	};

	const tagDetailsContainer = (tag: TagObject<"tag_automation">) => {
		return tag && tag.tag_automation ? tagItemContent(tag) : (<CircularProgress id="loadingAutomatedTag" className="loader" />);
	};

	return (
		<div id="automatedTagInfo">
			<Drawer
				open={isVisible}
				onClose={() => setVisible(false)}
				direction='right'
				lockBackgroundScroll={true}
				size={460}
			>
				<div className='automated-tag-info'>
					{tagDetailsContainer(tag)}
				</div>
				<div className="close-wrapper">
					<Divider />
					<Button flat id="automatedTagInfoClose" onClick={() => setVisible(false)}>{t('tags.automated_tag_info.close')}</Button>
				</div>
			</Drawer>
		</div>
	);
};

export default withT(React.memo(TagDetails));
