import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { Store } from 'redux';
import ApiError from './ApiError';

import { get } from 'lib/imports/lodash';
import { terStorage } from 'lib/storage';
import { operators as sessionOperators } from 'store/app/session/actions';

import Mekong from './Mekong';
import { Session } from 'types/session';

export const NAP_BASE_URL = process.env.REACT_APP_API_NAP_URL;
export const TER_BASE_URL = process.env.REACT_APP_API_URL;
export const NAP_VERSION = process.env.REACT_APP_API_NAP_VERSION;
export const TER_VERSION = process.env.REACT_APP_API_VERSION;

export const APP_VERSION = process.env.REACT_APP_VERSION;

const napConfig = {
	baseURL: `${NAP_BASE_URL}`,
	headers: {
		'Content-Type': 'application/json',
		'X-App-Version': APP_VERSION
	}
};

const terConfig = {
	baseURL: `${TER_BASE_URL}`,
	headers: {
		'Content-Type': 'application/json',
		'X-App-Version': APP_VERSION
	}
};

type Method = 'get' | 'post' | 'put' | 'patch' | 'delete';
type Version = 'v0' | 'v1' | 'v2' | 'v3';

let __refreshSessionPromise: Promise<void> | null = null;

export default class Api {
	private __api: AxiosInstance;

	constructor(namespace?: string, version?: Version) {
		let config;
		// TODO: remove namespace when only use Ter
		if (!namespace) { // Ter
			config = { ...terConfig };
			config.baseURL += `/${version || TER_VERSION}`;
		} else { // Nap
			config = { ...napConfig };
			config.baseURL += `/${namespace}/${version || NAP_VERSION}`;
		}
		this.__api = axios.create(config);
	}

	private async __request(method: Method, url: string, config: AxiosRequestConfig = {}, retry: number = 0): Promise<any> {
		if (Api.__isTokenExpired()) await Api.__refreshSession();

		const requestConfig: AxiosRequestConfig = { headers: {}, ...config, method, url };
		requestConfig.headers.Authorization = 'Bearer ' + Api.__getToken();

		try {
			const response = await this.__api.request(requestConfig);
			return response.data.response;
		} catch (err) {
			if (axios.isCancel(err)) throw new ApiError({ code: 'AXIOS_CANCELLED', message: 'request cancelled' });
			const error = get(err, "response.data.error");
			if (!error) throw new ApiError({ code: 'API_DOWN', message: 'Api is down' });
			if (!error.code) throw new ApiError({ code: 'API_UNKNOWN_ERROR', message: 'Api unknown error' });
			if (error.code === 'OAUTH2_TOKEN_NOT_VALID') {
				if (retry < 2) {
					await Api.__refreshSession();
					return this.__request(method, url, config, ++retry);
				}
				throw new ApiError({ code: 'API_AUTHENTICATION_FAILED', message: 'Could not authenticate' });
			}
			throw error;
		}
	}

	public async get(url: string, config?: AxiosRequestConfig) {
		return this.__request('get', url, config);
	}

	public async post(url: string, config?: AxiosRequestConfig) {
		return this.__request('post', url, config);
	}

	public async put(url: string, config?: AxiosRequestConfig) {
		return this.__request('put', url, config);
	}

	public async patch(url: string, config?: AxiosRequestConfig) {
		return this.__request('patch', url, config);
	}

	public async delete(url: string, config?: AxiosRequestConfig) {
		return this.__request('delete', url, config);
	}

	// request updated session with new token and updated profile
	public static async __refreshSession() {
		if (__refreshSessionPromise) {
			await __refreshSessionPromise;
		} else {
			__refreshSessionPromise = new Promise(async (resolve, reject) => {
				try {
					Api.clearAuthData(); // prevent new requests
					const session = await Mekong.getUpdatedSession();
					Api.setAuthData(session.api);
					dispatchUpdateSession(session); // async update. feature flags may change here
					resolve();
				} catch (err) {
					reject(err);
				}
			});
			await __refreshSessionPromise;
			__refreshSessionPromise = null;
		}
	}

	public static __getToken(): string | null {
		return terStorage.getToken();
	}

	public static __isTokenExpired(): boolean {
		const expiration = terStorage.getTokenExpiresAt();
		return !expiration || moment().isAfter(expiration);
	}

	public static setAuthData(sessionApiData: Session['api']) {
		terStorage.setToken(sessionApiData.token);
		terStorage.setTokenExpiresAt(sessionApiData.token_expires_at);
	}

	public static clearAuthData() {
		terStorage.clear();
	}

	public static getCancelTokenSource() {
		return axios.CancelToken.source();
	}

}

// dispatch function requires connection with store
type DispatchSetProfile = (session: Session) => void;
let dispatchUpdateSession: DispatchSetProfile = () => {
	console.error('[Api] Store not connected in order to dispatch updateSession action');
};
export function connectApi(store: Store) {
	dispatchUpdateSession = (session: Session) => {
		store.dispatch(sessionOperators.updateSession(session));
	};
}
