import { Action } from 'typescript-fsa';

import * as logo from './logo';

import { operators as dashboardOperators } from './actions';
import dashboardReducers, { State as DashboardState, INITIAL_STATE as DASHBOARD_INITIAL_STATE } from './reducers';

export const INITIAL_STATE = { ...DASHBOARD_INITIAL_STATE, logo: logo.INITIAL_STATE };
export const operators = { ...dashboardOperators, logo: logo.operators };

export interface State extends DashboardState {
	logo: logo.State
}

export const reducers = ({ logo: logoState, ...state }: State = INITIAL_STATE, action: Action<any>): State => {
	return { ...dashboardReducers(state, action), logo: logo.reducers(logoState, action) };
};
