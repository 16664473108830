import React from 'react';
import { withT } from 'lib/i18n';
import { getSmallTypeIcon } from 'lib/svg/report';
import { CircularProgress } from 'lib/imports/react-md';

import { map } from 'lib/imports/lodash';
import { date } from 'lib/format';
import moment from 'moment';

import { ComponentProps } from './types';

import './ReportStatusItem.scss';
import { FontIcon } from 'react-md';

const ReportStatusItem: React.FC<ComponentProps> = props => {
	const { job, t } = props;

	const [showDetail, setDetail] = React.useState(false);
	const toggleDetail = React.useCallback(() => setDetail(currentValue => !currentValue), [setDetail]);

	const detailContent = React.useMemo(() => {
		if (!showDetail) return null;

		let detailDates: string;
		if (job.type === 'periodic') {
			const pattern = (job.period! === 'monthly') ? 'MMM YY' : '[Q]Q YYYY';
			const beginDate = moment(job.minDate).format(pattern);
			const endDate = moment(job.maxDate).format(pattern);
			detailDates = t('reports.0_vs_1', { beginDate, endDate, interpolation: { escapeValue: false } }); // prevent escape '/' character
		} else {
			const beginDate = moment(job.minDate).format("L");
			const endDate = moment(job.maxDate).format("L");
			detailDates = t('reports.from_to', { beginDate, endDate, interpolation: { escapeValue: false } });
		}

		return (
			<div className="status-item-detail">
				<span className="status-item-detail-dates">{detailDates}</span>
				<span className="status-item-detail-feeds">{map(job.feeds, 'name').join(', ')}</span>
			</div>
		);
	}, [showDetail, job, t]);

	const statusContent = React.useMemo(() => {
		if (job.status === 'failed') return (
			<span title={t(`reports.${job.errorMessage!}`)}><FontIcon>error</FontIcon></span>
		);
		if (job.status === 'complete') return (
			<a href={job.url!} target="_blank" rel="noopener noreferrer">
				<FontIcon>{job.type === 'coverage' ? 'launch' : 'get_app'}</FontIcon>
			</a>
		);
		return (
			<span className="status-item-status-progress">
				<CircularProgress id="reportJobProgress" value={job.progress || 0} />
				<span className="status-item-status-progress-legend">{Math.round(job.progress || 0)}%</span>
			</span>
		);
	}, [job, t]);

	return (
		<div id={`statusItem_${job.id}`} className="status-item">
			<span className="status-item-date">{date.format(job.createdAt, 'MMM DD')}</span>
			<img className="status-item-icon" src={getSmallTypeIcon(job.type)} alt="status type icon" />
			<div className="status-item-group">
				<span className="status-item-type">{t(`reports.${job.type}`)}</span>
				{detailContent}
				<div>
					<span className="status-item-detail-toggle" onClick={toggleDetail}>
						{t(showDetail ? 'reports.hide_details' : 'reports.show_details')}
					</span>
				</div>
			</div>
			<div className="status-item-status">
				{statusContent}
			</div>
		</div >
	);
};

export default withT(React.memo(ReportStatusItem));
