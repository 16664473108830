import ActionCreators from 'lib/store/actions';
import { Audit } from './types';

const actions = new ActionCreators('@@audit/list');

const [fetchAudit, fetchAuditSuccess, fetchAuditError] = actions.apiOperators<void, Audit[]>('FETCH_AUDIT');
const [fetchNextPage, fetchNextPageSuccess, fetchNextPageError] = actions.apiOperators<void, Audit[]>('AUDIT_NEXT_PAGE_LIST');
const applySort = actions.operator<{field: string, order: 'asc' | 'desc'}>('AUDIT_APPLY_SORT');
const resubscribe = actions.operator<{id: string, email: string}>('AUDIT_RESUBSCRIBE');

export type Actions = {
    FetchAudit: ReturnType<typeof fetchAudit>,
    FetchAuditSuccess: ReturnType<typeof fetchAuditSuccess>,
    FetchAuditError: ReturnType<typeof fetchAuditError>,
    FetchNextPage: ReturnType<typeof fetchNextPage>,
    FetchNextPageSuccess: ReturnType<typeof fetchNextPageSuccess>,
    FetchNextPageError: ReturnType<typeof fetchNextPageError>,
    ApplySort: ReturnType<typeof applySort>
	Resubscribe: ReturnType<typeof resubscribe>
}

export const operators = {
	fetchAudit,
	fetchAuditSuccess,
	fetchAuditError,
	fetchNextPage,
	fetchNextPageSuccess, 
	fetchNextPageError,
	applySort,
	resubscribe
};