import React, { useCallback, useEffect, useMemo } from 'react';
import { withT } from "lib/i18n";
import { find, uniq } from 'lodash';

import { FacetItem } from 'class/Facet';
import { Focus } from 'class/Focus';

import DropdownFilter from "components/common/DropdownFilter/DropdownFilter";
import { ComponentProps } from './types';
import { FeedObject } from 'class/Feed';
import { FontIcon } from 'react-md';

const FocusFeed = (props: ComponentProps) => {
	const { excluded, focusFilters, feedFilters, focusList, display, isOpen } = props;
	const { t, onSetExcludeField, onSetFilteredFocusFeeds, onToggleOpen, fetchFocusList } = props;

	useEffect(() => {
		fetchFocusList();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const feedIcon = useCallback((type: FeedObject['type']) => {
		if (type === 'online') return "icon-news";
		if (type === 'print_dmr') return "icon-print";
		else return `icon-${type}`;
	}, []);

	const facetItems = useMemo(() => (focusList || []).map(focus => ({
		key: focus.id,
		name: focus.name,
		children: new Focus(focus).getFeeds().map(feed => ({
			key: feed.id,
			name: feed.name,
			icon: <FontIcon iconClassName={`focus-feed-checkbox-icon ${feedIcon(feed.type)}`} />
		}))
	})), [focusList, feedIcon]);

	const facetFilters = useMemo(() => {
		const filters: FacetItem[] = [];
		feedFilters.forEach(feedId => {
			const feed = Focus.findFeedInFocusList(feedId, focusList || []);
			if (!feed) return;
			filters.push({key: feed.id, name: feed.name});
		});
		focusFilters.forEach(focus =>
			new Focus(find(focusList, {id: focus}))
				.getFeeds()
				.forEach(feed => filters.push({key: feed.id, name: feed.name}))
		);
		return uniq(filters);
	}, [feedFilters, focusFilters, focusList]);

	const handleChange = useCallback((items: FacetItem[]) => {
		const feeds: string[] = items.map(item => item.key);
		// TODO: Pending to see if we need to rebuild the focus array to lighten the payload when many feeds selected
		const focus: string[] = [];

		onSetFilteredFocusFeeds(focus, feeds);
	}, [onSetFilteredFocusFeeds]);

	const handleExclude = useCallback((set: boolean) => onSetExcludeField(set), [onSetExcludeField]);

	const dropdown = useMemo(() =>
		<DropdownFilter
			id='focusFeedFilter'
			key='filters-focusFeed-dropdown'
			title={t('filters.source.value')}
			excluded={excluded}
			filters={facetItems}
			initSelectedFilters={facetFilters as FacetItem[]}
			defaultFilter={{ name: t('reports.all_male_sing'), key: 'all' }}
			disable={false}
			isOpen={isOpen}
			onToggleOpen={onToggleOpen}
			onChange={handleChange}
			onExclude={handleExclude}
		/>

	, [t, excluded, facetItems, facetFilters, isOpen, onToggleOpen, handleChange, handleExclude]);
	return display ? dropdown : null;
};

export default withT(FocusFeed);
