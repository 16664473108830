import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { Actions, operators } from './actions';
import { FacetObject } from 'class/Facet';

import * as extended from './extended';

export type State = {
	readonly extended: extended.State
	readonly facets: FacetObject | null
	readonly loadingFacets: boolean
	readonly facetsGroupsOpened: string[]
	readonly drawerVisible: boolean
	readonly showMoreFacetGroupKey: string | null
	readonly loadingFacetsGroup: boolean
	readonly showHiddenFacets: boolean
};

export const INITIAL_STATE: State = {
	extended: extended.INITIAL_STATE,
	facets: null,
	loadingFacets: false,
	facetsGroupsOpened: [],
	drawerVisible: false,
	showMoreFacetGroupKey: null,
	loadingFacetsGroup: false,
	showHiddenFacets: false
};

const reducerHandlers: ReducerHandlers<State> = {
	...extended.reducerHandlers,
	resetSearchFacetsData: (state, { payload }: Actions["ResetSearchFacetsData"]): State => ({
		...INITIAL_STATE
	}),
	toggleDrawer: (state): State => ({
		...state,
		drawerVisible: !state.drawerVisible
	}),
	setFacetsGroupsOpened: (state, { payload: { facetsGroupsOpened } }: Actions["SetFacetsGroupsOpened"]): State => ({
		...state,
		facetsGroupsOpened
	}),
	fetchAPIFacets: (state, { payload }: Actions["FetchAPIFacets"]): State => ({
		...state,
		loadingFacets: true
	}),
	setFacets: (state, { payload: { facets } }: Actions["SetFacets"]): State => ({
		...state,
		loadingFacets: false,
		facets
	}),
	setFacet: (state, { payload: { itemFacets, groupKey } }: Actions["SetFacet"]): State => ({
		...state,
		loadingFacetsGroup: false,
		facets: {groups: {...state.facets!.groups, [groupKey]: itemFacets}}
	}),
	setShowMoreFacetGroupKeySuccess: (state, { payload: { facetGroupKey } }: Actions["SetShowMoreFacetGroupKeySuccess"]): State => ({
		...state,
		showMoreFacetGroupKey: facetGroupKey,
		extended: extended.INITIAL_STATE
	}),
	toggleHiddenFacets: (state): State => ({
		...state,
		showHiddenFacets: !state.showHiddenFacets
	}),
	fetchGroupAPIFacets: (state, { payload }: Actions["FetchGroupAPIFacets"]): State => ({
		...state,
		loadingFacetsGroup: true
	}),
	fetchFacetsError: (state): State => ({
		...state,
		loadingFacets: false,
		loadingFacetsGroup: false
	})
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
