import { Model, attr } from "redux-orm";
import { createOrmReducer, ReducerOrm } from "lib/store/reducer";

import { EmailListFields, EmailListModel } from './types';
import { operators } from './actions';

export class EmailList extends Model<typeof EmailList, EmailListFields> {
	static modelName = 'EmailList' as const;
	static fields = {
		id: attr(),
		name: attr()
	};
	static get idAttribute() { return 'id' as const; };

	static reducer: ReducerOrm<EmailListModel> = createOrmReducer<EmailListModel>(operators);
}
