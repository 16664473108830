import {
	isBilibiliDocumentUrl,
	isRedDocumentUrl,
	isTiktokDocumentUrl,
	isWeChatDocumentUrl,
	isYoutubeDocumentUrl,
	isDouyinDocumentUrl,
	isWeiboDocumentUrl
} from "./validate";

export type socialMediaProviders = 'youtube' | 'tiktok' | 'wechat' | 'bilibili' | 'red' | 'douyin' | 'weibo';

export const parkluChanelTypes: { [index: number]: Boolean } = { 31: true, 46: true, 47: true, 52: true, 62: true };
export const tikTokChanelType: number = 41;

class ProviderDiscovery {

	public isParklu(channelType: number): boolean {
		return channelType in parkluChanelTypes;
	}

	public isTikTok(channelType: number): boolean {
		return channelType === tikTokChanelType;
	}

	public getProviderFromUrl(url: string): socialMediaProviders | '' {
		if (isYoutubeDocumentUrl(url)) return 'youtube';
		if (isTiktokDocumentUrl(url)) return 'tiktok';
		if (isWeChatDocumentUrl(url)) return 'wechat';
		if (isBilibiliDocumentUrl(url)) return 'bilibili';
		if (isRedDocumentUrl(url)) return 'red';
		if (isDouyinDocumentUrl(url)) return 'douyin';
		if (isWeiboDocumentUrl(url)) return 'weibo';
		return '';
	}
}

export default new ProviderDiscovery();
