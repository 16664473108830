import React, { useCallback, useMemo } from 'react';
import { withT } from "lib/i18n";

import DropdownFilter from "components/common/DropdownFilter/DropdownFilter";
import { FilterFacet } from 'class/Filter';
import { Facet, FacetItem } from 'class/Facet';

import { ComponentProps } from './types';

const Event = (props: ComponentProps) => {
	const { excluded, facetFilters, printEvents, display, isOpen } = props;
	const { t, onSetExcludeField, onSetFacetGroupFilters, onToggleOpen } = props;

	const facetItems = React.useMemo(() => {
		return Object.keys(printEvents)
			.map(eventId => ({
				key: eventId,
				name: printEvents[eventId].trim()
			})).sort((a, b) => a.name.localeCompare(b.name));
	}, [printEvents]);

	const eventFilters = useMemo(() => (facetFilters && facetFilters[Facet.eventKey]) || [], [facetFilters]);

	const handleChange = useCallback((items: FacetItem[]) => {
		onSetFacetGroupFilters(items as FilterFacet[]);
	}, [onSetFacetGroupFilters]);

	const handleExclude = useCallback((set: boolean) => onSetExcludeField(set), [onSetExcludeField]);

	return display ?
		<DropdownFilter
			id='eventFilter'
			key='filters-event-dropdown'
			title={t('filters.event.value')}
			excluded={excluded}
			filters={facetItems}
			initSelectedFilters={eventFilters as FacetItem[]}
			defaultFilter={{ name: t('reports.all_male_sing'), key: 'all' }}
			disable={false}
			isOpen={isOpen}
			onToggleOpen={onToggleOpen}
			onChange={handleChange}
			onExclude={handleExclude}
		/>
		: null;
};

export default withT(Event);
