import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { routerActions } from 'connected-react-router';
import Api from 'lib/ajax/Api';
import Mekong from 'lib/ajax/Mekong';

import { State } from "store/types";
import { operators as FocusOperators } from 'store/entities/Focus';
import { operators as FeedOperators } from 'store/entities/Feed';
import { FocusObject } from 'class/Focus';
import logoSagas from './logo/sagas';

import { Actions, operators } from './actions';

export default function* sagas() {
	yield all([
		logoSagas(),
		takeLatest(operators.fetchFocus.type, fetchFocus),
		takeLatest(operators.renameFocus.type, renameFocus),
		takeLatest(operators.removeFeed.type, removeFeed),
		takeLatest(operators.removeFocus.type, removeFocus)
	]);
}

function* fetchFocus({ payload: { id } }: Actions["FetchFocus"]) {
	const api = new Api();
	try {
		const response = yield call([api, 'get'], '/definition/focus/' + id, { params: { entities: 1 } });
		const { entities: { feed: feeds }, ...focus } = response;
		yield put(operators.fetchFocusSuccess({ focus, feeds }));
	} catch (error) {
		yield put(operators.fetchFocusError({ error }));
	}
}

function* renameFocus({ payload: { name } }: Actions["RenameFocus"]) {
	const focus: FocusObject = yield select((state: State) => state.focus.dashboard.focus!);
	const api = new Api();

	try {
		yield call([api, 'put'], `/definition/focus/${focus.id}`, { data: { name, old_name: focus.name} });

		yield put(FocusOperators.update({id: focus.id, name}));

		yield put(operators.renameFocusSuccess({ focus: { ...focus, name } }));
	} catch (error) {
		yield put(operators.renameFocusError({ error }));
	}
}

function* removeFeed({ payload: { id } }: Actions["RemoveFeed"]) {
	const focus: FocusObject = yield select((state: State) => state.focus.dashboard.focus!);

	try {
		yield call(Mekong.delete, `/v1/definition/focus/${focus.id}/feed/${id}`);

		yield put(FeedOperators.delete(id));

		yield put(operators.removeFeedSuccess({ id }));
	} catch (error) {
		yield put(operators.removeFeedError({ error }));
	}
}

function* removeFocus() {
	const focus: FocusObject = yield select((state: State) => state.focus.dashboard.focus!);
	try {
		yield call(Mekong.delete, `/v1/definition/focus/${focus.id}`, { params: { entities: 1 } });

		yield put(FocusOperators.deleteCascade(focus.id));

		yield put(operators.removeFocusSuccess());
		yield put(routerActions.push('/focus'));
	} catch (error) {
		yield put(operators.removeFocusError({ error }));
	}
}
