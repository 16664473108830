import { all, put, takeLatest, select, call, fork } from 'redux-saga/effects';

import Api from 'lib/ajax/Api';
import { isEmpty, differenceBy, some } from 'lib/imports/lodash';
import { facebookStorage } from 'lib/storage';
import FacebookGraph, { GetDetails, GetPicture, GetPermissions, GetAccounts } from 'lib/ajax/FacebookGraph';

import { Notification } from 'types/notification';
import { operators as notificationOperators } from 'store/app/notifications';
import { operators as UserOperators } from 'store/entities/User';
import { operators as TenantOperators } from 'store/entities/Tenant';
import { operators as FacebookUserOperators, FacebookUser } from 'store/entities/FacebookUser';
import { FacebookUserData, FacebookPermission } from 'store/entities/FacebookUser/types';

import facebookSelectors from 'store/app/facebook/selectors';
import profileSelectors from 'store/app/profile/selectors';
import FacebookUserSelectors from 'store/entities/FacebookUser/selectors';

import { operators, Actions } from './actions';

export default function* sagas() {
	yield all([
		takeLatest(operators.updateStatus.type, updateStatus),
		takeLatest(operators.updateAuth.type, updateAuth),
		takeLatest(operators.deauthorize.type, deauthorize),
		takeLatest(operators.skip.type, skip)
	]);
}

type FacebookUsersResponse = {
	tenant: FacebookUserData[];
	user: FacebookUserData[];
	action_result: 'created' | 'extended' | 'refreshed' | 'ignored' | 'error';
}
type FacebookUpdateData = {
	id: string,
	name: string,
	email: string,
	scope: string,
	picture_url: string,
	token: string
};

type UpdateStatusData = {
	status: string,
	facebook_data?: FacebookUpdateData
}
function* updateStatus({ payload: statusResponse }: Actions["UpdateStatus"]) {
	const data: UpdateStatusData = {
		status: statusResponse ? statusResponse.status : 'banned'
	};
	try {
		if (data.status === 'connected') data.facebook_data = yield _fetchFacebookUpdateData(statusResponse!.authResponse.accessToken);

		const api = new Api();
		const facebookUsersResponse: FacebookUsersResponse = yield call([api, 'post'], '/facebook/status', { data });

		yield _setFacebookUsersDataFromResponse(facebookUsersResponse);
		yield _showResultNotification(facebookUsersResponse.action_result);

		yield put(operators.updateStatusSuccess());

		const state = yield select();
		if (facebookSelectors.isSkipped(state)) return;
		if (isEmpty(profileSelectors.getUserActiveFacebookUsers(state))) {
			const tenant = profileSelectors.getTenantInstance(state);
			if (tenant!.needsFacebookLogin()) {
				yield put(operators.setDialogMode('facebook'));
			}
		}
	} catch (error) {
		yield put(operators.updateStatusError(error));
	}
}

type UpdateAuthData = FacebookUpdateData & {
	instagram_accounts: GetAccounts
};
function* updateAuth({ payload: authResponse }: Actions["UpdateAuth"]) {
	const state = yield select();

	// the following case happens only after showing an auth warning dialog (remove instagram or missing permissions)
	// if the user selects to continue anyway, the action is dispatched without authResponse payload
	// as it has been already stored in the original updateAuth call (dialogMode === 'instagram_auth')
	if (!authResponse) authResponse = facebookSelectors.getCurrentAuth(state)!;

	try {
		const dialogMode = facebookSelectors.getDialogMode(state);

		const instagramAccounts: GetAccounts = yield _fetchInstagramAccounts(authResponse.accessToken);

		// detect deleting accounts
		if (dialogMode === 'instagram_auth') {
			const currentInstagramAccounts = FacebookUserSelectors.getInstagramAccounts(state, authResponse.userID);
			const removingAccounts = differenceBy(currentInstagramAccounts, instagramAccounts, 'id');
			if (!isEmpty(removingAccounts)) {
				yield put(operators.setDialogMode('instagram_auth_remove'));
				return;
			}
		}

		const facebookData: FacebookUpdateData = yield _fetchFacebookUpdateData(authResponse.accessToken);

		// detect missing permissions
		if (dialogMode === 'instagram_auth') {
			if (!isEmpty(instagramAccounts)) { // accounts linked
				const permissionsMissing = FacebookUser.getMissingPermissions(facebookData.scope.split(',') as FacebookPermission[]);
				if (!isEmpty(permissionsMissing)) { // permissions missing
					yield put(operators.setPermissionsMissing({ mode: 'auth', permissionsMissing }));
					return;
				}
			}
		}

		const data: UpdateAuthData = { ...facebookData, instagram_accounts: instagramAccounts };

		const api = new Api();
		const facebookUsersResponse: FacebookUsersResponse = yield call([api, 'post'], '/facebook/authorization', { data });

		yield _setFacebookUsersDataFromResponse(facebookUsersResponse);

		if (some(facebookUsersResponse.user, facebookUser => some(facebookUser.instagram_accounts, igAccount => !igAccount.mentions_subscribed))) {
			yield put(operators.setDialogMode('instagram_webhook_failed'));
		}
		else yield put(operators.setDialogMode('closed'));

		yield _showResultNotification(facebookUsersResponse.action_result);
		yield put(operators.updateAuthSuccess());

	} catch (error) {
		yield put(operators.updateAuthError(error));
	}
}

function* deauthorize() {
	const state = yield select();

	const currentAuth = facebookSelectors.getCurrentAuth(state)!;
	const facebookUserId = currentAuth.userID;

	try {
		yield _revokeFacebookPermissions(currentAuth.accessToken);

		const api = new Api();
		// slow request, fork to run in backgound
		yield fork([api, 'delete'], `/facebook/${facebookUserId}`);

		yield put(FacebookUserOperators.delete(facebookUserId));
		yield put(operators.deauthorizeSuccess());
		yield put(operators.setDialogMode('closed'));

	} catch (error) {
		yield put(operators.deauthorizeError(error));
	}
}

function* skip() {
	yield call(facebookStorage.setSkipped, true); // persist the flag
}

/* private functions */

// dispatches actions to store facebookUsers data for the tenant and user
function* _setFacebookUsersDataFromResponse(facebookUsersResponse: FacebookUsersResponse) {
	const state = yield select();
	const userId = profileSelectors.getUser(state)!.id;
	const tenantId = profileSelectors.getTenant(state)!.id;

	yield put(UserOperators.setFacebookUsersData({ id: userId, facebookUsersData: facebookUsersResponse.user }));
	yield put(TenantOperators.setFacebookUsersData({ id: tenantId, facebookUsersData: facebookUsersResponse.tenant }));
}

// fetch facebook api functions
function* _fetchFacebookUpdateData(accessToken: string) {
	const facebookGraph = new FacebookGraph(accessToken);
	const [details, permissions, picture]: [GetDetails, GetPermissions, GetPicture] =
		yield all([
			call([facebookGraph, 'getDetails']),
			call([facebookGraph, 'getPermissions']),
			call([facebookGraph, 'getPicture'])
		]);
	const facebookUpdateData: FacebookUpdateData = {
		id: details.id,
		name: details.name,
		email: details.email,
		scope: permissions.join(','),
		picture_url: picture.data.url,
		token: accessToken
	};
	return facebookUpdateData;
}

function* _fetchInstagramAccounts(accessToken: string) {
	const facebookGraph = new FacebookGraph(accessToken);
	const instagramAccounts: GetAccounts = yield call([facebookGraph, 'getAccounts']);
	return instagramAccounts;
}

function* _revokeFacebookPermissions(accessToken: string) {
	const facebookGraph = new FacebookGraph(accessToken);
	yield call([facebookGraph, 'revokePermissions']);
}

function* _showResultNotification(actionResult: FacebookUsersResponse['action_result']) {
	let notification: Notification | null = null;
	if (actionResult === 'created') {
		notification = { t: 'account.facebook_accounts.update.success', level: 'success' };
	} else if (actionResult === 'refreshed') {
		notification = { t: 'account.facebook_accounts.update.success', level: 'info' };
	}
	if (!notification) return;
	yield put(notificationOperators.add({ notification }));
}
