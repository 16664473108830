import React from 'react';
import { withT } from 'lib/i18n';
import GA from 'lib/googleAnalytics';
import Paginator from 'components/common/Paginator';

import { ComponentProps } from './types';

class SearchPaginator extends React.Component<ComponentProps> {

	private _setStart(start: number) {
		const { onSetStart } = this.props;
		GA.trackEvent({ category: 'Feed results', action: 'Page navigation' });
		onSetStart(start);
	}

	public render() {
		const { limit, start, total, loadingDocuments } = this.props;
		if (loadingDocuments || !!!total) return null;
		return (
			<Paginator
				start={start}
				limit={limit}
				total={total}
				onStartChange={start => this._setStart(start)}
			>
			</Paginator>
		);
	}
}

export default withT(SearchPaginator);
