import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/ui/report/setup';
import setupSelectors from 'store/ui/report/setup/selectors';
import { DispatchProps, StateProps } from './types';

import ReportSetupSettingsCountry from './ReportSetupSettingsCountry';

const mapStateToProps = (state: State): StateProps => ({
	reportCountries: setupSelectors.getCountry(state),
	isGroupedByCountry: setupSelectors.getGroupedByCountry(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onChangeCountry: countries => {
		const countriesQuery = countries.map(c => c.key);
		dispatch(operators.changeSettings({ country: countriesQuery.toString() }));
	},
	onChangeGroupedByCountry: isGrouped => dispatch(operators.changeSettings({ groupedByCountry: isGrouped }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSetupSettingsCountry);
