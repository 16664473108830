import { Action, actionCreatorFactory } from 'typescript-fsa';

import { FeedCampaign, FeedObject, FeedType, LinkedBrand } from 'class/Feed';
import * as social from './definition/social';
import * as print from './definition/print';
import * as printDmr from './definition/printDmr';
import * as online from './definition/online';
import { FocusFeedStateDefinitions } from './reducers';

const actionCreator = actionCreatorFactory('@@focus/feed');

type Payloads = {
	ResetFeed: { feedType: FeedType, focusId: string, focusName: string; };
	SetInitialFeed: { feedType: FeedType, focusId: string, definitions: FocusFeedStateDefinitions; };
	FetchFeed: { focusId: string, feedId: string; };
	FetchFeedError: { error: object; };
	SetFeed: { feed: FeedObject, definitions: FocusFeedStateDefinitions; };
	SetLoadingSaveFeedName: { loading: boolean; };
	SetEditNameInputValue: { feedName: string | null; };
	SaveFeed: void;
	SaveFeedError: { error: object; };
	SetLoadingSaveFeed: { loading: boolean; };
	CheckRecovery: void;
	RecoverFeed: void;
	RecoverFeedError: { error: object; };
	RecoverFeedSuccess: { recoveryId: string; };
	RecoverPollingStart: void;
	SetRecoverPollingProgress: { status: string | null; };
	SaveFeedName: void;
	SaveFeedNameError: { error: object; };
	SaveFeedNameSuccess: { feedName: string; };
	ToggleFeedEnabled: void;
	ToggleShowDuplicateFeed: void;
	DuplicateFeed: void;
	DuplicateFeedStart: { feedName: string, focusId: string; };
	DuplicateFeedError: { error: object; } | void;
	DuplicateFeedSuccess: { feedId: string; };
	SetBeginDate: { date: Date; };
	SetEndDate: { date: Date; };
	FetchSearch: void;
	AddLinkedBrand: { linkedBrand: LinkedBrand; };
	SetBrandSync: { brand_sync: boolean; };
	SetSentimentCheck: { sentiment_check: boolean; };
	SetComplexityInfo: { took: number, total: number; };
	SetShowComplexityDialog: { show: boolean; };
	SetCampaigns: { campaigns: FeedCampaign[]; };
	SetCampaignsExpression: { index: number, campaign: FeedCampaign; };
};

export type Actions = {
	ResetFeed: Action<Payloads["ResetFeed"]>;
	SetInitialFeed: Action<Payloads["SetInitialFeed"]>;
	FetchFeed: Action<Payloads["FetchFeed"]>;
	fetchFeedError: Action<Payloads["FetchFeedError"]>;
	SetFeed: Action<Payloads["SetFeed"]>;
	SetEditNameInputValue: Action<Payloads["SetEditNameInputValue"]>;
	SetLoadingSaveFeedName: Action<Payloads["SetLoadingSaveFeedName"]>;
	SaveFeed: Action<Payloads["SaveFeed"]>;
	SaveFeedError: Action<Payloads["SaveFeedError"]>;
	SetLoadingSaveFeed: Action<Payloads["SetLoadingSaveFeed"]>;
	CheckRecovery: Action<Payloads["CheckRecovery"]>;
	RecoverFeed: Action<Payloads["RecoverFeed"]>;
	RecoverFeedError: Action<Payloads["RecoverFeedError"]>;
	RecoverFeedSuccess: Action<Payloads["RecoverFeedSuccess"]>;
	RecoverPollingStart: Action<Payloads["RecoverPollingStart"]>;
	SetRecoverPollingProgress: Action<Payloads["SetRecoverPollingProgress"]>;
	SaveFeedName: Action<Payloads["SaveFeedName"]>;
	SaveFeedNameError: Action<Payloads["SaveFeedNameError"]>;
	SaveFeedNameSuccess: Action<Payloads["SaveFeedNameSuccess"]>;
	ToggleFeedEnabled: Action<Payloads["ToggleFeedEnabled"]>;
	ToggleShowDuplicateFeed: Action<Payloads["ToggleShowDuplicateFeed"]>;
	DuplicateFeed: Action<Payloads["DuplicateFeed"]>;
	DuplicateFeedStart: Action<Payloads["DuplicateFeedStart"]>;
	DuplicateFeedError: Action<Payloads["DuplicateFeedError"]>;
	DuplicateFeedSuccess: Action<Payloads["DuplicateFeedSuccess"]>;
	SetBeginDate: Action<Payloads["SetBeginDate"]>;
	SetEndDate: Action<Payloads["SetEndDate"]>;
	FetchSearch: Action<Payloads["FetchSearch"]>;
	AddLinkedBrand: Action<Payloads["AddLinkedBrand"]>;
	SetBrandSync: Action<Payloads["SetBrandSync"]>;
	SetSentimentCheck: Action<Payloads["SetSentimentCheck"]>;
	SetComplexityInfo: Action<Payloads["SetComplexityInfo"]>;
	SetShowComplexityDialog: Action<Payloads["SetShowComplexityDialog"]>;
	SetCampaigns: Action<Payloads["SetCampaigns"]>;
	SetCampaignsExpression: Action<Payloads["SetCampaignsExpression"]>;
};

export const operators = {
	...social.operators,
	...print.operators,
	...online.operators,
	...printDmr.operators,
	resetFeed: actionCreator<Payloads["ResetFeed"]>('RESET_FEED'),
	setInitialFeed: actionCreator<Payloads["SetInitialFeed"]>('SET_INITIAL_FEED'),
	fetchFeed: actionCreator<Payloads["FetchFeed"]>('FETCH_FEED'),
	fetchFeedError: actionCreator<Payloads["FetchFeedError"]>('FETCH_FEED_ERROR', { api: 'error' }),
	setEditNameInputValue: actionCreator<Payloads["SetEditNameInputValue"]>('SET_EDIT_NAME_INPUT_VALUE'),
	setFeed: actionCreator<Payloads["SetFeed"]>('SET_FEED'),
	setLoadingSaveFeedName: actionCreator<Payloads["SetLoadingSaveFeedName"]>('SET_LOADING_SAVE_FEED_NAME'),
	saveFeed: actionCreator<Payloads["SaveFeed"]>('SAVE_FEED'),
	saveFeedError: actionCreator<Payloads["SaveFeedError"]>('SAVE_FEED_ERROR', { api: 'error' }),
	setLoadingSaveFeed: actionCreator<Payloads["SetLoadingSaveFeed"]>('SET_LOADING_SAVE_FEED'),
	checkRecovery: actionCreator<Payloads["CheckRecovery"]>('CHECK_RECOVERY'),
	recoverFeed: actionCreator<Payloads["RecoverFeed"]>('RECOVER_FEED'),
	recoverFeedError: actionCreator<Payloads["RecoverFeedError"]>('RECOVER_FEED_ERROR', { api: 'error' }),
	recoverFeedSuccess: actionCreator<Payloads["RecoverFeedSuccess"]>('RECOVER_FEED_SUCCESS'),
	recoverPollingStart: actionCreator<Payloads["RecoverPollingStart"]>('RECOVER_POLLING_START'),
	setRecoverPollingProgress: actionCreator<Payloads["SetRecoverPollingProgress"]>('SET_RECOVER_POLLING_PROGRESS'),
	saveFeedName: actionCreator<Payloads["SaveFeedName"]>('SAVE_FEED_NAME'),
	saveFeedNameError: actionCreator<Payloads["SaveFeedNameError"]>('SAVE_FEED_NAME_ERROR', { api: 'error' }),
	saveFeedNameSuccess: actionCreator<Payloads["SaveFeedNameSuccess"]>('SAVE_FEED_NAME_SUCCESS'),
	toggleFeedEnabled: actionCreator<Payloads["ToggleFeedEnabled"]>('TOGGLE_FEED_ENABLED'),
	toggleShowDuplicateFeed: actionCreator<Payloads["ToggleShowDuplicateFeed"]>('TOGGLE_SHOW_DUPLICATE_FEED'),
	duplicateFeed: actionCreator<Payloads["DuplicateFeed"]>('DUPLICATE_FEED'),
	duplicateFeedStart: actionCreator<Payloads["DuplicateFeedStart"]>('DUPLICATE_FEED_START'),
	duplicateFeedError: actionCreator<Payloads["DuplicateFeedError"]>('DUPLICATE_FEED_ERROR', { api: 'error' }),
	duplicateFeedSuccess: actionCreator<Payloads["DuplicateFeedSuccess"]>('DUPLICATE_FEED_SUCCESS'),
	setBeginDate: actionCreator<Payloads["SetBeginDate"]>('SET_BEGIN_DATE'),
	setEndDate: actionCreator<Payloads["SetEndDate"]>('SET_END_DATE'),
	fetchSearch: actionCreator<Payloads["FetchSearch"]>('FETCH_SEARCH'),
	addLinkedBrand: actionCreator<Payloads["AddLinkedBrand"]>('ADD_LINKED_BRAND'),
	setBrandSync: actionCreator<Payloads["SetBrandSync"]>('SET_BRAND_SYNC'),
	setSentimentCheck: actionCreator<Payloads["SetSentimentCheck"]>('SET_SENTIMENT_CHECK'),
	setComplexityInfo: actionCreator<Payloads["SetComplexityInfo"]>('SET_COMPLEXITY_INFO'),
	setShowComplexityDialog: actionCreator<Payloads["SetShowComplexityDialog"]>("SET_SHOW_COMPLEXITY_DIALOG"),
	setCampaigns: actionCreator<Payloads["SetCampaigns"]>('SET_CAMPAIGNS'),
	setCampaignsExpression: actionCreator<Payloads["SetCampaignsExpression"]>('SET_CAMPAIGNS_EXPRESSION')
};
