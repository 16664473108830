import { createSelector } from "reselect";

import { State as RootState } from 'store/types';
import { State } from './reducers';
import UserSelectors from 'store/entities/User/selectors';
import TenantSelectors from 'store/entities/Tenant/selectors';
import moment from "moment";
import { FacebookUserObject } from "store/entities/FacebookUser/types";

const rootSelector = (state: RootState): State => state.app.profile;

const getUserId = createSelector(rootSelector, state => state.user!.id);
const getTenantId = createSelector(rootSelector, state => state.tenant!.id);

export default {
	getUserId,
	getTenantId,
	isSettingsLoading: createSelector(rootSelector, state => state.loading),
	getUser: createSelector(state => state, getUserId,
		(state, userId) => UserSelectors.get(state, userId)
	),
	getTenant: createSelector(state => state, getTenantId,
		(state, tenantId) => TenantSelectors.get(state, tenantId)
	),
	getUserInstance: createSelector(state => state, getUserId,
		(state, userId) => UserSelectors.getInstance(state, userId)!
	),
	getTenantInstance: createSelector(state => state, getTenantId,
		(state, tenantId) => TenantSelectors.getInstance(state, tenantId)!
	),
	getUserActiveFacebookUsers: createSelector(state => state, getUserId,
		(state, userId) => __filterActiveFacebookUsers(UserSelectors.getFacebookUsers(state, userId)!)
	),
	getTenantActiveFacebookUsers: createSelector(state => state, getTenantId,
		(state, tenantId) => __filterActiveFacebookUsers(TenantSelectors.getFacebookUsers(state, tenantId)!)
	)
};

function __filterActiveFacebookUsers (facebookUsers: FacebookUserObject[]) {
	return facebookUsers.filter(facebookUser => facebookUser.is_valid && moment().isBefore(facebookUser.expires_at));
}
