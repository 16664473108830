import { Model, attr } from "redux-orm";

import { createOrmReducer, ReducerOrm } from "lib/store/reducer";
import { operators } from './actions';
import { LanguageFields, LanguageModel } from "./types";

export class Language extends Model<typeof Language, LanguageFields> {
	static get modelName() { return 'Language' as const; };
	static get idAttribute() { return 'id' as const; };
	static get fields() {
		return {
			id: attr(),
			name: attr(),
			iso_code: attr()
		};
	};

	static reducer: ReducerOrm<LanguageModel> = createOrmReducer<LanguageModel>(operators);
}