import { Model, attr, fk } from "redux-orm";

import { createOrmReducer, ReducerOrm } from "lib/store/reducer";
import { operators } from './actions';
import { FeedFields, FeedModel, FeedData, FeedInstance } from "./types";

export class Feed extends Model<typeof Feed, FeedFields> {
	static get modelName() { return 'Feed' as const; };
	static get idAttribute() { return 'id' as const; };
	static get fields() {
		return {
			id: attr(),
			name: attr(),
			focus: fk('Focus', 'feeds')
		};
	};

	static reducer: ReducerOrm<FeedModel> = createOrmReducer<FeedModel>(operators);

	static parse(feedData: FeedData): FeedInstance {
		return this.upsert(feedData);
	}
}
