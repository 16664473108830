import React from "react";
import { SelectionControl, SelectField } from 'lib/imports/react-md';

import { TProps, withT } from "lib/i18n";
import { SendConfiguration } from 'store/entities/Newsletter';
import { newsletterDays } from "store/entities/Newsletter/consts";

type NewsletterFrequency = SendConfiguration["frequency"];
type NewsletterWeeklySchedule = SendConfiguration["weekly_schedule"];
type NewsletterDailySchedule = SendConfiguration["daily_schedule"];

type OwnProps = {
	frequency: NewsletterFrequency,
	weekly_schedule: NewsletterWeeklySchedule,
	daily_schedule: NewsletterDailySchedule,
	onUpdated: (payload: Partial<SendConfiguration>) => void,
};

type ComponentProps = OwnProps & TProps;


const NewsletterEditFormFrequency = (props: ComponentProps) => {
	const { t, frequency, weekly_schedule, daily_schedule } = props;
	const { onUpdated } = props;

	const updateFrequency = React.useCallback((newFrequency: any) => {
		onUpdated({ frequency: newFrequency });
	}, [onUpdated]);

	const updateDay = React.useCallback((day: any) => {
		onUpdated({ weekly_schedule: { ...weekly_schedule, day } });
	}, [weekly_schedule, onUpdated]);

	const updateWorkingDay = React.useCallback((onlyWorkingDays: any) => {
		onUpdated({ daily_schedule: { ...daily_schedule, only_work_days: onlyWorkingDays } });
	}, [daily_schedule, onUpdated]);


	const frequencyInputs = React.useMemo(() => (
		<>
			<SelectionControl
				id={`newsletterEditFrequencyDaily`}
				name="newsletterEditFrequency"
				type="radio"
				value="daily"
				label={t('newsletter.form.input.frequency.daily')}
				checked={frequency === "daily"}
				onChange={updateFrequency}
			/>
			<SelectionControl
				id={`newsletterEditFrequencyWeekly`}
				name="newsletterEditFrequency"
				type="radio"
				value="weekly"
				label={t('newsletter.form.input.frequency.weekly')}
				checked={frequency === "weekly"}
				onChange={updateFrequency}
			/>
		</>
	), [t, frequency, updateFrequency]);

	const weeklyDayInput = React.useMemo(() => (
		<SelectField
			id="newsletterEditFrequencyWeeklySelect"
			label={t('newsletter.form.input.day.value')}
			menuItems={newsletterDays.map(day => ({ value: day, label: t(`newsletter.form.input.day.${day}`) }))}
			onChange={updateDay}
			value={weekly_schedule.day}
			fullWidth
		/>
	), [t, weekly_schedule.day, updateDay]);

	const workingDaysInput = React.useMemo(() => (
		<SelectionControl
			id="newsletterEditFrequencyDailyWorkingDays"
			name="newsletterEditFrequency"
			type="checkbox"
			label={t('newsletter.form.input.working_days')} //TODO: New multilang
			onChange={updateWorkingDay}
			checked={daily_schedule.only_work_days}
		/>
	), [t, daily_schedule.only_work_days, updateWorkingDay]);

	return (
		<div id="newsletterEditSectionFrequency" className="form-section">
			<div className="form-section-title">
				{t('newsletter.form.label.frequency')}
			</div>
			<div className="form-section-content">
				<div className="form-section-content-column">
					{frequencyInputs}
				</div>
				<div className="form-section-content-column">
					{frequency === "weekly" && weeklyDayInput}
					{frequency === "daily" && workingDaysInput}
				</div>
			</div>
		</div>
	);
};

export default withT(React.memo(NewsletterEditFormFrequency));
