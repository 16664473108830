import { Model, many, attr } from "redux-orm";
import { createOrmReducer, ReducerOrm } from "lib/store/reducer";
import { CATEGORIES, FLC_CATEGORIES, NO_FLC_CATEGORIES } from 'config/categorization';
import featureFlags from "lib/featureFlags";

import { Actions, operators } from "./actions";
import { TenantInstance, TenantModel, TenantFields } from './types';

export class Tenant extends Model<typeof Tenant, TenantFields> {
	static get idAttribute() { return 'id' as const; };
	static modelName = 'Tenant' as const;
	static fields = {
		id: attr(),
		guid: attr(),
		name: attr(),
		facebookUsers: many('FacebookUser', 'tenants')
	};

	static reducer: ReducerOrm<TenantModel> = createOrmReducer<TenantModel>(operators, (Tenant, session) => ({
		addFacebookUser: ({ payload: { id, facebookUserId } }: Actions["AddFacebookUser"]) => {
			const tenant = Tenant.withId(id);
			if (!tenant) return console.warn('Tenant <addFacebookUser> id not found:', id);
			tenant.facebookUsers.add(facebookUserId);
		},
		removeFacebookUser: ({ payload: { id, facebookUserId } }: Actions["RemoveFacebookUser"]) => {
			const tenant = Tenant.withId(id);
			if (!tenant) return console.warn('Tenant <removeFacebookUser> id not found:', id);
			tenant.facebookUsers.remove(facebookUserId);
		},
		setFacebookUsersData: ({ payload: { id, facebookUsersData } }: Actions["SetFacebookUsersData"]) => {
			const tenant = Tenant.withId(id);
			if (!tenant) return console.warn('Tenant <setFacebookUsersData> id not found:', id);
			tenant.update({ facebookUsers: facebookUsersData.map(facebookUserData => (session.FacebookUser as any).parse(facebookUserData)) });
		}
	}));

	public needsFacebookLogin(): boolean {
		const instance = this as any as TenantInstance;
		return !featureFlags.isEnabled('facebook.visible') && instance.tier_properties.results.social && !instance.print_only;
	}

	public getCategories(): string[] {
		const instance = this as any as TenantInstance;
		const mode = instance.settings.categorization_mode;
		if (mode === 'all') return Array.from(CATEGORIES);
		if (mode === 'flc') return Array.from(FLC_CATEGORIES);
		return Array.from(NO_FLC_CATEGORIES);
	}
}
