import { combineReducers } from 'redux';

import * as _root from './reducers';
import * as setup from './setup';
import * as status from './status';
import * as direct from './direct';

export const reducers = combineReducers({
	_: _root.reducers,
	setup: setup.reducers,
	status: status.reducers,
	direct: direct.reducers
});

export interface State {
	_: _root.State,
	setup: setup.State;
	status: status.State;
	direct: direct.State;
}

export const INITIAL_STATE = {
	_: _root.INITIAL_STATE,
	setup: setup.INITIAL_STATE,
	status: status.INITIAL_STATE,
	direct: direct.INITIAL_STATE
};
