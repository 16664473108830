import React, { useCallback, useEffect, useState } from 'react';

import { withT } from 'lib/i18n';
import ReportSetupFeedsSummary from './Summary';
import ReportSetupFeedsSelect from './Select';
import ReportSetupFeedsClipping from './Clipping';
import { ComponentProps } from './types';
import LinkedBrand from 'components/common/LinkedBrand';
import { LinkedBrand as LinkedBrandType } from 'class/Feed';

import './ReportSetupFeeds.scss';

const ReportSetupFeeds: React.FC<ComponentProps> = props => {
	const { onUnselectAllFeeds, onSetLinkedBrandId, t } = props;
	const { reportType, linkedBrandList, linkedBrandId } = props;

	const [linkedBrandsResult, setLinkedBrandsResult] = useState<LinkedBrandType[]>(linkedBrandList);

	useEffect(() => {
		if (linkedBrandList.length) setLinkedBrandsResult(linkedBrandList);
	}, [linkedBrandList]);

	const onAddLinkedBrand = useCallback((brand: {id: string, name: string, isCrawled: boolean}) => {
		onUnselectAllFeeds();
		onSetLinkedBrandId(brand);
	}, [onUnselectAllFeeds, onSetLinkedBrandId]);

	const onFetchLinkedBrand = useCallback((query: string) => {
		const lowerCaseQuery = query.toLowerCase();
		setLinkedBrandsResult(linkedBrandList.filter(brand => brand.name.toLowerCase().includes(lowerCaseQuery)));
	}, [linkedBrandList, setLinkedBrandsResult]);

	return (
		<>
			<div id="ReportLinkedBrand" className="form-section vertical-align-top">
				<div className="form-section-title">
					{t('feed.form.label.brands')}
				</div>

				<div className="form-section-content">
					<span className="definition-text">{t('feed.form.label.select_brand').toUpperCase()}</span>
					<LinkedBrand
						linkedBrandId={linkedBrandId}
						linkedBrandList={linkedBrandList}
						linkedBrandsResult={linkedBrandsResult}
						onFetchLinkedBrand={onFetchLinkedBrand}
						onResetLinkedBrand={() => setLinkedBrandsResult(linkedBrandList)}
						onAddLinkedBrand={onAddLinkedBrand} />
				</div>
			</div>
			<ReportSetupFeedsSelect />
			{reportType !== 'excel' && <ReportSetupFeedsClipping />}
			<ReportSetupFeedsSummary />
		</>
	);
};

export default withT(React.memo(ReportSetupFeeds));
