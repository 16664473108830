import { all, call, put, takeLatest, select } from 'redux-saga/effects';

import { Actions, operators } from './actions';
import { operators as entitiesOperators } from 'store/entities/Tag';
import { operators as tagAutomationOperators } from 'store/entities/TagAutomation';
import entitiesSelectors from 'store/entities/Tag/selectors';
import Mekong from 'lib/ajax/Mekong';

export default function* sagas() {
	yield all([
		takeLatest(operators.createTag.type, createTag),
		takeLatest(operators.renameTag.type, renameTag),
		takeLatest(operators.deleteTag.type, deleteTag),
		takeLatest(operators.migrateTag.type, migrateTag)
	]);
}

function* createTag({ payload: { name, topicId } }: Actions["CreateTag"]) {
	try {
		const tag = yield call([Mekong, 'post'], `/v1/tag`, { data: { name, topic_id: topicId } });
		yield put(entitiesOperators.create({ ...tag, topic: topicId }));
		yield put(operators.createTagSuccess());
	} catch (error) {
		yield put(operators.createTagError(error));
	}
}

function* renameTag({ payload: { id, oldName, name } }: Actions['RenameTag']) {
	try {
		const topicId = yield _getTopicId(id);
		yield put(entitiesOperators.update({ id, name }));
		yield call([Mekong, 'patch'], `/v1/tag/${id}`, { data: { name, old_name: oldName, topic_id: topicId } });
		yield put(operators.renameTagSuccess());
	} catch (error) {
		yield put(operators.renameTagError(error));
	}
}

function* deleteTag({ payload: { id } }: Actions['DeleteTag']) {
	try {
		const topicId = yield _getTopicId(id);
		yield put(entitiesOperators.delete(id));
		yield call([Mekong, 'delete'], `/v1/tag/${id}`, { data: { topic_id: topicId } });
		yield put(operators.deleteTagSuccess());
	} catch (error) {
		yield put(operators.deleteTagError(error));
	}
}

function* migrateTag({ payload: { tagId, newTopicId } }: Actions['MigrateTag']) {
	try {
		const oldTopicId = yield _getTopicId(tagId);
		if (oldTopicId === newTopicId) return;
		const tagWithAutomation: any = entitiesSelectors.getTagWithTagAutomation(yield select(), tagId);
		const tag = yield call([Mekong, 'post'], `/v1/tag/${tagId}/migrate`, { data: { old_topic_id: oldTopicId, new_topic_id: newTopicId } });
		yield put(entitiesOperators.delete(tagId));
		yield put(entitiesOperators.create({ ...tag, topic: newTopicId }));
		if (tagWithAutomation.tag_automation) yield put(tagAutomationOperators.update({ id: tagWithAutomation.tag_automation.id, tag: tag.id }));
		yield put(operators.migrateTagSuccess());
	} catch (error) {
		if (error.code && error.code === 'INVALID_NAME') {
			return yield put(operators.migrateTagError({ error: 'TAG_ALREADY_EXISTS' }));
		} else {
			return yield put(operators.migrateTagError({}));
		}
	}
}

function* _getTopicId(tagId: string) {
	const topic = entitiesSelectors.getTopic(yield select(), tagId)!;
	return topic.id;
}
