import { createSelector } from 'reselect';

import { orderBy } from 'lib/imports/lodash';
import { State } from 'store/types';
import EmailListSelectors from 'store/entities/EmailList/selectors';

const rootSelector = (state: State) => state.ui.newsletter.emailList.list;

export default {
	isLoading: createSelector(rootSelector, state => state.loading),
	getEmailLists: (state: State) => orderBy(EmailListSelectors.get(state), emailList => +emailList.id, ['desc'])
};
