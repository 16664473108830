import { Document, PreparedDocument } from "class/Document";
import format from "lib/format";

export class FacebookDocument extends Document {
	public prepare(): PreparedDocument {
		const counters = {
			audience: {
				followers: this.author ? (this.author.followers || 0) : 0
			},
			reach: {
				followers: this.author ? (this.author.followers || 0) : 0
			},
			echo: {
				likes: this.social ? (this.social.likes || 0) : 0,
				shares: this.social ? (this.social.shares || 0) : 0,
				replies: this.social ? (this.social.replies || 0) : 0
			}
		};
		return new PreparedDocument({
			id: this.id,
			provider: this.provider,
			providerName: 'Facebook',
			link: this.link,
			icon: "icon-facebook",
			categories_id: this.category || "",
			title: this.content,
			content: "",
			date: format.date.doc(this.date_from_provider),
			dateTitle: format.date.localeTitle(this.date_from_provider),
			author_name: this.author ? (this.author.name || '') : '',
			author_icon: 'http://graph.facebook.com/' + (this.author ? this.author.id : undefined) + '/picture?type=square',
			author_link: this.author ? this.author.url : undefined,
			image: this.image,
			counters: {
				reach: {
					value: format.number.counter(counters, 'audience'),
					title: format.text.counter(counters, 'audience')
				},
				engagement: {
					value: format.number.counter(counters, 'echo'),
					title: format.text.counter(counters, 'echo')
				},
				echo: {
					value: format.number.currency(this.rank, this.date_from_provider),
					title: format.number.currency(this.rank, this.date_from_provider, 'locale', true)
				},
				branded_miv: this.branded_miv ? this.branded_miv.map(brand => ({
					id: brand.id,
					value: format.number.brandedCurrency(brand.value, this.date_from_provider),
					title: format.number.brandedCurrency(brand.value, this.date_from_provider, 'locale', true)
				})) : []
			}
		});
	}
}
