import ActionCreator, { CrudActions } from "lib/store/actions";
import { FacebookUser } from "./model";
import { FacebookUserData } from "./types";

const actionCreator = new ActionCreator('@@orm/facebookUser');

const crudOperators = actionCreator.crudOperators<FacebookUser>();
const addUser = actionCreator.operator<{ id: string, userId: string }>('ADD_USER');
const removeUser = actionCreator.operator<{ id: string, userId: string }>('REMOVE_USER');
const parse = actionCreator.operator<FacebookUserData | FacebookUserData []>('PARSE');

export const operators = {
	...crudOperators,
	addUser,
	removeUser,
	parse
};

export interface Actions extends CrudActions<FacebookUser> {
	AddUser: ReturnType<typeof operators.addUser>
	RemoveUser: ReturnType<typeof operators.removeUser>
	Parse: ReturnType<typeof operators.parse>
}
