import ActionCreators from 'lib/store/actions';
import { PartialAtLeastOne } from 'types/generics';
import { AuditFilters, Event } from './types';

const actions = new ActionCreators('@@audit/filters');

const filterReset = actions.operator('RESET_AUDIT_FILTER_EVENTS');
const filterChanged = actions.operator<PartialAtLeastOne<AuditFilters>>('CHANGE_AUDIT_FILTER');
const eventsChanged = actions.operator<{events: Event[]}>('CHANGE_AUDIT_FILTER_EVENTS');

export type Actions = {
	FilterReset: ReturnType<typeof filterReset>;
	FilterChanged: ReturnType<typeof filterChanged>;
	EventsChanged: ReturnType<typeof eventsChanged>
};

export const operators = {
	filterReset,
	filterChanged,
	eventsChanged
};
