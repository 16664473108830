import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ReportLayout from './Layout';
import { ComponentProps } from './types';
import { User } from 'class/User';

const guidExpr = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';

const Report = ({ user: userObject }: ComponentProps) => {
	const user = React.useMemo(() => new User(userObject!), [userObject]);
	if (!user.hasPermission('reports.view')) return <Redirect to={{ pathname: '/' }} />;
	return <>
		<Switch>
			<Route path={`/report/:focusId(${guidExpr})`} exact render={({ match }) => (<ReportLayout pathFocusId={match.params.focusId} />)} />
			<Route path={`/report/:focusId(${guidExpr})`} render={({ match }) => (<Redirect to={`/report/${match.params.focusId}`} />)} /> {/* Fallback */}
			<Route path="/report" strict exact component={ReportLayout} />
			<Route path="/report" render={() => (<Redirect to="/report" />)} />
		</Switch>
	</>;
};


export default Report;
