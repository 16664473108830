import React from 'react';
import { createRoot } from 'react-dom/client';
import WebFontLoader from 'webfontloader';
import * as FullStory from '@fullstory/browser';

import GA from 'lib/googleAnalytics';
import Main from './Main';

import 'vendor/icomoon/style.css';

WebFontLoader.load({
	google: {
		families: ['Raleway', 'Montserrat', 'Libre Baskerville', 'Material Icons']
	}
});

GA.init();
FullStory.init({ orgId: '12EXGZ' });


const container = document.getElementById('root');
const root = createRoot(container!);
root.render((<Main />));
