import React from "react";

import { ComponentProps } from "./types";

import "./Badge.scss";

const Badge = ({ label, type }: ComponentProps) => {
	return (
		<div className={`badge ${type}`}>
			{label}
		</div>
	);
};

export default Badge;
