import React from 'react';

import { keys, trim, has } from 'lib/imports/lodash';
import { withT } from 'lib/i18n';
import { TextField, Button, FontIcon } from 'lib/imports/react-md';
import { isEmailListNameTooLong, isValidEmail } from 'lib/validate';
import SimpleDialog from 'components/common/dialog/simple';
import NewsletterEmailListResults from 'components/Newsletter/EmailList/Results';
import { ResultRow } from 'components/Newsletter/EmailList/Results/types';

import { ComponentProps } from './types';
import './EmailListEdit.scss';

type EditingEmails = {
	[key: string]: boolean
}

const NewsletterEmailListEdit = (props: ComponentProps) => {
	const { list } = props;
	const { onAddNotification, onEditListName, onResetEditList, onAddNewEmailAddress, onDeleteEmailAddress, onRenameEmailAddress, t } = props;

	const [newListNameValue, setNewListNameValue] = React.useState('');
	const [newEmailValue, setNewEmailValue] = React.useState<string>('');
	const [errorEmailValue, setErrorEmailValue] = React.useState(false);
	const [searchValue, setSearchValue] = React.useState<string>('');
	const [start, setStart] = React.useState(0);
	const [editingEmails, setEditingEmails] = React.useState<EditingEmails>({});
	const [focusEmailInput, setFocusEmailInput] = React.useState(false);
	const [showNameInput, setShowNameInput] = React.useState(false);
	const [showRemoveDialog, setShowRemoveDialog] = React.useState(false);
	const [emailToDelete, setEmailToRemove] = React.useState<string | null>(null);

	const newListNameInputRef = React.useRef<any>();
	const editEmailInputRef = React.useRef<any>();

	React.useEffect(() => {
		if (showNameInput) newListNameInputRef.current.focus();
	}, [showNameInput]);

	React.useEffect(() => {
		if (focusEmailInput) editEmailInputRef.current.focus();
	}, [focusEmailInput]);

	const onListNameInputChange = React.useCallback((value: React.ReactText) => setNewListNameValue(value.toString()), []);
	const onAddEmailInputChange = React.useCallback((value: React.ReactText) => setNewEmailValue(value.toString()), []);

	const emailValidation = React.useCallback((email, setErrorFn: (value: boolean) => void, onDuplicateEmail?: () => void) => {
		const isValid = isValidEmail(email);

		if (!isValid) {
			setErrorFn(true);
			return false;
		}
		if (list.emails[email]) {
			onAddNotification({ level: 'danger', t: 'error.api_server.email_already_exists' });
			if (onDuplicateEmail) onDuplicateEmail();
			return false;
		}
		return true;
	}, [list.emails, onAddNotification]);

	const onAddEmailHandler = React.useCallback(() => {
		const newEmail = trim(newEmailValue);
		if (newEmail !== '' && emailValidation(newEmail, setErrorEmailValue)) {
			setSearchValue('');
			setStart(0);
			onAddNewEmailAddress(newEmail);
			setNewEmailValue('');
			setErrorEmailValue(false);
		}
	}, [emailValidation, newEmailValue, onAddNewEmailAddress]);

	const changeListNameSection = React.useMemo(() => {
		let content;
		if (showNameInput) content = (
			<TextField
				id="emailListEditListNameInput"
				className="email-list-edit-list-name-input"
				onBlur={() => {
					const parsedNewListName = trim(newListNameValue);
					if (parsedNewListName !== '' && parsedNewListName !== list.name && !isEmailListNameTooLong(parsedNewListName)) {
						onEditListName(parsedNewListName);
					}
					setShowNameInput(false);
				}}
				fullWidth={false}
				value={newListNameValue}
				onChange={onListNameInputChange}
				ref={newListNameInputRef}
				error={isEmailListNameTooLong(trim(newListNameValue))}
				errorText={t('error.email_list_name_too_long')}
			></TextField>
		);
		else content = (
			<>
				<span className="email-list-edit-list-name-title">{list.name}</span>
				<Button
					id="emailListEditNameButton"
					icon
					onClick={() => {
						setNewListNameValue(list.name);
						setShowNameInput(true);
					}}
				>
					edit
				</Button>
			</>
		);

		return (
			<div className="email-lists-edit-list-name-container">
				<span className="email-list-edit-list-name-label">{t('email_list.email.list.name')}</span>
				{content}
			</div>
		);
	}, [list.name, newListNameValue, onEditListName, onListNameInputChange, showNameInput, t]);

	const addEmailSection = React.useMemo(() => (
		<div className="email-list-add-entity-container">
			<span className="email-list-add-entity-label">{t('email_list.email.new')}</span>
			<form id="emailListAddEmailForm" onSubmit={ev => {
				ev.preventDefault();
				onAddEmailHandler();
			}}>
				<TextField
					id="emailListAddEmailInput"
					className="email-list-add-entity-input"
					label="Email"
					fullWidth={true}
					value={newEmailValue}
					onChange={onAddEmailInputChange}
					error={errorEmailValue}
					errorText={t('email_list.email.error')}
				/>
			</form>
			<Button
				id="emailListAddEmailButton"
				flat
				className="email-list-add-entity-button"
				onClick={onAddEmailHandler}
			>
				Add
			</Button>
		</div >
	), [errorEmailValue, newEmailValue, onAddEmailHandler, onAddEmailInputChange, t]);

	const onSearchInputChange = React.useCallback((value: React.ReactText) => {
		setSearchValue(value.toString());
	}, []);

	const searchSection = React.useMemo(() => (
		<div className="email-list-search-entity-container">
			<span className="email-list-search-entity-label">{t('email_list.email.table.header')}</span>
			<TextField
				id="emailListSearchEmailInput"
				className="email-list-search-entity-input"
				placeholder={'search'}
				fullWidth={true}
				inlineIndicator={<FontIcon className="search-indicator">search</FontIcon>}
				value={searchValue}
				onChange={onSearchInputChange}
			/>
		</div>
	), [onSearchInputChange, searchValue, t]);

	const deleteEmailInput = React.useCallback((email: string) => {
		delete editingEmails[email];
		setEditingEmails({ ...editingEmails });
	}, [editingEmails]);

	const getEmailRow = React.useCallback((email: string) => {
		if (has(editingEmails, email)) return (
			<TextField
				id={`emailListEmailNameInput-${email}`}
				className="email-list-email-name-input"
				defaultValue={email}
				onBlur={(ev: any) => {
					const newEmail = trim(ev.currentTarget.value);
					setFocusEmailInput(false);
					if (newEmail === email || newEmail === '') return deleteEmailInput(email);
					if (!emailValidation(newEmail, (error: boolean) => setEditingEmails({ ...editingEmails, [email]: error }), () => deleteEmailInput(email))) return;
					onRenameEmailAddress(email, newEmail);
					delete editingEmails[email];
					setEditingEmails({ ...editingEmails });
				}}
				error={editingEmails[email]}
				errorText={t('email_list.email.error')}
				ref={editEmailInputRef}
			></TextField>
		);
		else return (
			<>
				<span className="email-list-item-name">{email}</span>
				<Button className="email-list-item-icon email-list-item-edit-button"
					icon
					onClick={() => {
						setFocusEmailInput(true);
						setEditingEmails({ ...editingEmails, [email]: false });
					}}
				>
					edit
				</Button>
			</>
		);
	}, [deleteEmailInput, editingEmails, emailValidation, onRenameEmailAddress, t]);

	const emailsSection = React.useMemo(() => {
		const emails = keys(list.emails);
		const rows: ResultRow[] = emails.map(email => {
			const row = (
				<div id={`emailListEmailItem-${email}`} className="email-list-item" key={email}>
					{getEmailRow(email)}
					<Button
						className="email-list-item-icon email-list-item-remove-button"
						icon
						onClick={() => {
							setEmailToRemove(email);
							setShowRemoveDialog(true);
						}}
					>
						clear
					</Button>
				</div>
			);
			return {
				filterValue: email,
				row
			};
		});

		return (
			<NewsletterEmailListResults
				pageLimit={20}
				searchValue={searchValue}
				rows={rows}
				start={start}
				onStartChange={start => setStart(start)}
			>
			</NewsletterEmailListResults>
		);
	}, [getEmailRow, list.emails, searchValue, start]);

	const backButton = React.useMemo(() => (
		<div className="email-list-edit-list-back-container">
			<Button
				id="emailListEditListBackButton"
				iconChildren="reply"
				onClick={onResetEditList}
				flat
			>
				{t('email_list.email.back')}
			</Button>
		</div>
	), [onResetEditList, t]);

	const resetDeleteEmail = React.useCallback(() => {
		setEmailToRemove(null);
		setShowRemoveDialog(false);
	}, []);

	const removeDialog = React.useMemo(() => (showRemoveDialog ?
		(
			<SimpleDialog
				id="emailListRemoveEmailWarning"
				text={t('email_list.email.remove.title')}
				content={<p>{t('email_list.email.remove.message')}</p>}
				onAccept={() => {
					onDeleteEmailAddress(emailToDelete!!);
					setStart(0);
					resetDeleteEmail();
				}}
				onCancel={resetDeleteEmail}
				acceptColor="red"
			/>
		)
		: null), [emailToDelete, onDeleteEmailAddress, resetDeleteEmail, showRemoveDialog, t]);

	return (
		<div
			id="emailListEdit" className="email-list-entity-content-wrapper"
		>
			{changeListNameSection}
			{addEmailSection}
			{searchSection}
			{emailsSection}
			{backButton}
			{removeDialog}
		</div>
	);
};

export default React.memo(withT(NewsletterEmailListEdit));
