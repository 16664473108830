import { ormEntities, createOrmSelector, OrmSelector } from "store/entities/selectors";
import { FocusObject } from "store/entities/Focus";
import { FeedObject } from "store/entities/Feed";

export const get: OrmSelector<FeedObject> = createOrmSelector(ormEntities.Feed);
export const getFocus: OrmSelector<FocusObject> = createOrmSelector(ormEntities.Feed.focus);

export default {
	get,
	getFocus
};
