import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import Origin from './Origin';
import { State } from 'store/types';
import { StateProps, DispatchProps } from './types';

import { operators as filtersOperators } from 'store/search/filters';
import { operators as horizontalFiltersOperators } from 'store/search/horizontalFilters';
import { operators as facetOperators } from 'store/search/facets';
import { FilterFacet } from 'class/Filter';
import { Facet } from 'class/Facet';

const originGroupKey = Facet.originGroupKey;

const mapStateToProps = (state: State): StateProps => ({
	excluded: state.search.horizontalFilters.excludedFields.includes(originGroupKey),
	facetFilters: state.search.filters.facetsGroups
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetExcludeField: (set: boolean) => dispatch(horizontalFiltersOperators.setExcludeField({ field: originGroupKey, set })),
	onSetFacetGroupFilters: (items: FilterFacet[]) => dispatch(filtersOperators.setFacetGroupFilters({
		groupKey: originGroupKey,
		groupFacetsFiltered: items,
		doSearch: false
	})),
	onFetchOriginFacetsGroup: () => dispatch(facetOperators.fetchGroupAPIFacets({ groupKey: originGroupKey }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Origin);
