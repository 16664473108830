import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { operators } from 'store/focus/list';
import { DispatchProps, StateProps } from './types';
import FocusListDialog from './FocusListDialog';

const mapStateToProps = (): StateProps => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onCreateFocus: name => dispatch(operators.createFocus({ name })),
	onRenameFocus: (focus, name) => dispatch(operators.renameFocus({ focus, name })),
	onRemoveFocus: focus => dispatch(operators.removeFocus({ focus })),
	onRemoveFeed: (focus, feed) => dispatch(operators.removeFeed({ focus, feed }))
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusListDialog);
