import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { CircularProgress, Button, FontIcon, DropdownMenu, ListItem } from 'lib/imports/react-md';

import { NewsletterObject } from 'store/entities/Newsletter';
import { ComponentProps } from './types';
import { withT } from 'lib/i18n';
import GA from 'lib/googleAnalytics';
import SimpleDialog from 'components/common/dialog/simple';

import './NewsletterList.scss';

const NewsletterList = (props: ComponentProps) => {
	const { t, user, newsletters } = props;
	const { onToggleNewsletterEnabled, onRemoveNewsletter } = props;

	const [newsletterToRemove, setNewsletterToRemove] = React.useState<NewsletterObject | null>(null);

	const onAddNewsletter = React.useCallback((ev: React.MouseEvent) => {
		GA.trackEvent({ category: 'Newsletters list', action: 'Add' });
	}, []);

	const onUpdateNewsletter = React.useCallback((ev: React.MouseEvent) => {
		GA.trackEvent({ category: 'Newsletters list', action: 'Edit' });
	}, []);

	const onAcceptRemoveNewsletter = React.useCallback(() => {
		GA.trackEvent({ category: 'Newsletters list', action: 'Remove' });
		onRemoveNewsletter(newsletterToRemove!.id);
		setNewsletterToRemove(null);
	}, [newsletterToRemove, onRemoveNewsletter]);

	const onCancelRemoveNewsletter = React.useCallback(() => {
		setNewsletterToRemove(null);
	}, []);

	const getNewsletterTimeText = React.useCallback((newsletter: NewsletterObject, action: "inserted" | "edited") => {
		const m = moment(action === "inserted" ? newsletter.inserted_at : newsletter.edited_at);
		const langParameters = {
			day: m.date(),
			month: t('month.' + (m.month() + 1)),
			year: m.year()
		};
		return t('newsletter.' + (action === 'inserted' ? 'created_on' : 'updated_on'), langParameters);
	}, [t]);

	const getNewsletterOptions = React.useCallback((newsletter: NewsletterObject) => {

		const newsletterOptions = [
			<ListItem
				id={`newsletterListOptionsToggleEnabledNewsletter${newsletter.id}`}
				key="toggleNewsletterEnabled"
				primaryText={newsletter.enabled ? t('newsletter.disable') : t('newsletter.enable')}
				onClick={() => onToggleNewsletterEnabled(newsletter.id)}
			/>,
			<ListItem
				id={`newsletterListOptionsEditNewsletter${newsletter.id}`}
				key="editNewsletter"
				primaryText={t('newsletter.update')}
				component={Link}
				to={`/newsletter/${newsletter.id}`}
				onClick={onUpdateNewsletter}
			/>
		];

		if (user.hasPermission('newsletter.delete')) {
			newsletterOptions.push(<ListItem
				id={`newsletterListOptionsRemoveNewsletter${newsletter.id}`}
				key="removeNewsletter"
				primaryText={t('newsletter.remove.value')}
				className="discover-entity-list-item-remove"
				onClick={() => setNewsletterToRemove(newsletter)}
			/>);
		}

		return newsletterOptions;
	}, [t, user, onUpdateNewsletter, onToggleNewsletterEnabled]);

	const getNewsletterRowContent = React.useCallback((newsletter: NewsletterObject<'feeds'>) => (
		<tr key={newsletter.id} className={newsletter.enabled ? "" : "disabled"}>
			<td className={`discover-entity-list-content newsletter-info`} data-title='Info:'>
				<div className="discover-entity-list-vertical">
					<div>
						<Link id={`newsletterListNewsletterNameLink${newsletter.id}`}
							to={`/newsletter/${newsletter.id}`}
							onClick={onUpdateNewsletter}
						>
							<FontIcon>check</FontIcon>
							<span>{newsletter.name}</span>
						</Link>
					</div>
					<div className="left-padding">{newsletter.description}</div>
					<div className="left-padding top-margin">{t('newsletter.based_on')}<span>{` ${newsletter.feeds.length} `}</span>{t('newsletter.feeds')}</div>
				</div>
			</td>
			<td className="discover-entity-list-content newsletter-dates" data-title="Dates:">
				<div className="discover-entity-list-vertical">
					<div>
						{getNewsletterTimeText(newsletter, "inserted")}
						<span>{` ${newsletter.user_created}`}</span>
					</div>
					<div>
						{getNewsletterTimeText(newsletter, "edited")}
						<span>{` ${newsletter.user_edited}`}</span>
					</div>
				</div>
			</td>
			<td className="discover-entity-list-options" data-title="">
				<div className="min-height">
					<DropdownMenu
						id={`newsletterListMenuNewsletter${newsletter.id}`}
						className="newsletter-options-dropdown-menu"
						menuItems={getNewsletterOptions(newsletter)}
						position={DropdownMenu.Positions.TOP_RIGHT}
					>
						<Button icon className="btn--no-background btn--no-ink">more_vert</Button>
					</DropdownMenu>
				</div>
			</td>
		</tr>
	), [t, getNewsletterOptions, getNewsletterTimeText, onUpdateNewsletter]);

	const header = React.useMemo(() => (
		<div className="discover-entity-list-toolbar">
			<div className="discover-entity-list-title">{t('newsletters.insight_newsletters')}</div>
			{
				newsletters && user.hasPermission('newsletter.create') ?
					<Link to="/newsletter/create">
						<Button id="newsletterListCreateNewsletterBtn" icon className="btn--no-background" title={t('add')} onClick={onAddNewsletter}>add</Button>
					</Link>
					: null
			}
		</div>
	), [user, newsletters, t, onAddNewsletter]);

	const removeDialog = React.useMemo(() => {
		if (newsletterToRemove) return <SimpleDialog
			id="newsletterListRemoveDialog"
			text={t('newsletter.remove.confirm')}
			onAccept={onAcceptRemoveNewsletter}
			onCancel={onCancelRemoveNewsletter}
			acceptColor="red"
		/>;
		return null;
	}, [t, newsletterToRemove, onCancelRemoveNewsletter, onAcceptRemoveNewsletter]);

	const content = React.useMemo(() => {
		if (!newsletters) return <CircularProgress id="newsletterListLoading" scale={1.25} />;

		return (
			<div className="discover-entity-list-table">
				<table id="newsletterListTable">
					<tbody>
						{newsletters.map(newsletter => getNewsletterRowContent(newsletter))}
					</tbody>
				</table>
			</div>
		);
	}, [newsletters, getNewsletterRowContent]);

	return (
		<div id="newsletterList" className="discover-entity-list">
			{header}
			{content}
			{removeDialog}
		</div>
	);
};

export default withT(React.memo(NewsletterList));
