import * as list from './list';
import * as paging from './paging';
import * as filters from './filters';
import { combineReducers } from 'redux';

export interface State {
	list: list.State,
	paging: paging.State,
	filters: filters.State,
};

export const INITIAL_STATE: State = {
	list: list.INITIAL_STATE,
	paging: paging.INITIAL_STATE,
	filters: filters.INITIAL_STATE
};

export const reducers = combineReducers({
	list: list.reducers,
	paging: paging.reducers,
	filters: filters.reducers
});