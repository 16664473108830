import { User } from './User';
import { Tenant } from './Tenant';
import { FacebookUser } from './FacebookUser';
import { InstagramAccount } from './InstagramAccount';
import { Newsletter } from './Newsletter';
import { EmailList } from './EmailList';
import { Topic } from './Topic';
import { Tag } from './Tag';
import { TagAutomation } from './TagAutomation';
import { Focus } from './Focus';
import { Feed } from './Feed';
import { Category } from './Category';
import { Brand } from './Brand';
import { Event } from './Event';
import { Language } from './Language';
import { Country } from './Country';

const schema = {
	User,
	Tenant,
	FacebookUser,
	InstagramAccount,
	Newsletter,
	EmailList,
	Topic,
	Tag,
	TagAutomation,
	Focus,
	Feed,
	Category,
	Brand,
	Event,
	Language,
	Country
};

export type Schema = typeof schema;

export default schema;
