import moment from "moment";

export class DateFactory {
	static getPastDateRangeFor = (period: 'day' | 'week' | 'month' | 'year', value: number = 1) => ({
		endDate: moment(new Date()).endOf('day').toDate(),
		startDate: moment(new Date()).subtract(value, period).startOf('day').toDate()
	});

	static getFutureDateRangeFor = (period: 'day' | 'week' | 'month' | 'year', value: number = 1) => ({
		endDate: moment(new Date()).add(value, period).startOf('day').toDate(),
		startDate: moment(new Date()).endOf('day').toDate()
	});
}
