import React, { useCallback, useState } from 'react';
import { withT } from "lib/i18n";

import Event from './Event';
import Origin from './Origin';
import Category from './Category';
import TopicTag from './TopicTag';
import Language from './Language';
import ChannelType from './ChannelType';
import PlacementType from './PlacementType';
import LocationFilter from './Location';
import DateRangeFilter from './DateRange';
import FocusFeed from './FocusFeed';

import { ComponentProps } from "./types";

import './Filters.scss';
import Media from './Media';

enum EFilter {
	DateRange = 1,
	Location,
	Language,
	ChannelType,
  	PlacementType,
	Media,
	FocusFeed,
	Origin,
	Event,
	Category,
	TopicTag
}

const Filters: React.FC<ComponentProps> = props => {
	const { toggleQuickReport, onFetchSearch } = props;

	// TODO Change for feature flag check?
	const [locationEnabled] = useState(true);
	const [languageEnabled] = useState(true);
	const [channelTypeEnabled] = useState(true);
	const [placementTypeEnabled] = useState(true);
	const [mediaEnabled] = useState(true);
	const [focusFeedEnabled] = useState(true);
	const [originEnabled] = useState(true);
	const [eventEnabled] = useState(true);
	const [categoryEnabled] = useState(true);
	const [topicTagEnabled] = useState(true);

	const [openFilter, setOpenFilter] = useState<EFilter | null>(null);

	const isOpen = useCallback((filter: EFilter) => openFilter === filter, [openFilter]);

	const toggleFilter = useCallback((toggledFilter: EFilter) => {
		if (toggledFilter === openFilter) setOpenFilter(null);
		else setOpenFilter(toggledFilter);
	}, [openFilter, setOpenFilter]);

	const getToggleProps = useCallback((filter: EFilter) => ({
		isOpen: isOpen(filter),
		onToggleOpen: () => toggleFilter(filter)
	}), [isOpen, toggleFilter]);

	const handleApply = () => {
		toggleQuickReport(true);
		onFetchSearch();
	};

	const filters = React.useMemo(() => <>
		<DateRangeFilter {...getToggleProps(EFilter.DateRange)}/>
		<FocusFeed display={focusFeedEnabled} {...getToggleProps(EFilter.FocusFeed)}/>
		<LocationFilter display={locationEnabled} {...getToggleProps(EFilter.Location)}/>
		<Language display={languageEnabled} {...getToggleProps(EFilter.Language)}/>
		<TopicTag display={topicTagEnabled} {...getToggleProps(EFilter.TopicTag)}/>
		<Category display={categoryEnabled} {...getToggleProps(EFilter.Category)}/>
		<Event display={eventEnabled} {...getToggleProps(EFilter.Event)}/>
		<Origin display={originEnabled} {...getToggleProps(EFilter.Origin)}/>
		<ChannelType display={channelTypeEnabled} {...getToggleProps(EFilter.ChannelType)}/>
		{/* PUBLISHED FILTER */}
		<Media display={mediaEnabled} {...getToggleProps(EFilter.Media)}/>
		<PlacementType display={placementTypeEnabled} {...getToggleProps(EFilter.PlacementType)}/>
	</>, [getToggleProps, focusFeedEnabled, locationEnabled, languageEnabled, topicTagEnabled, categoryEnabled, eventEnabled, originEnabled, channelTypeEnabled, mediaEnabled, placementTypeEnabled]);

	return (
		<div id="filters">
			<div id="filtersSelectors">{filters}</div>
			<button id="applyBtn" onClick={handleApply} className='md-btn md-btn--flat md-btn--text md-pointer--hover md-text selected'>Apply</button>
		</div>
	);
};

export default withT(Filters);
