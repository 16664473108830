import React, { useCallback, useMemo, useState } from 'react';
import DialogContainer from 'react-md/lib/Dialogs/DialogContainer';
import Button from 'react-md/lib/Buttons/Button';
import isEmpty from 'lodash/isEmpty';
import { FontIcon, Tab, Tabs, TextField } from 'lib/imports/react-md';

import { ComponentProps } from './types';
import { withT } from 'lib/i18n';

import featureFlags from 'lib/featureFlags';

import ArticleInsertSelectFeed from './SelectFeed/';
import ArticleInsertBulk from './Bulk';
import ArticleBulkPreview from './BulkPreview';
import ArticleInsertSelectType from './SelectType';
import ArticleInsertSelectDocument from './SelectDocument';
import ArticleInsertFormOnline from './Form/Online';
import ArticleInsertPrintForm from './Form/Print';
import ArticleInsertFormSocial from './Form/Social/';
import { InsertForm, InsertOnlineForm, InsertPrintForm, InsertSocialForm } from 'types/article/insert';

import './ArticleInsert.scss';

const ArticleInsert = (props: ComponentProps) => {
	const { selectedFeed, url, step, invalidUrlError, type, file, isBulkInsert } = props;
	const { t, onChangeStep, onSetShowInsertDocument, onSetSelectedFeed, onSetContinueInsert, onSetUrl, onSetInvalidUrl, onSetIsBulkInsert } = props;

	const [formSubmitted, setFormSubmitted] = useState(false);

	const handleValidationSuccess = useCallback((form: InsertForm) => {
		onChangeStep("CREATE_DOCUMENT", form);
		setFormSubmitted(false);
	}, [onChangeStep, setFormSubmitted]);

	const mentionForm = useMemo((): JSX.Element | null => {
		if (!selectedFeed) return null;
		switch (selectedFeed.type) {
			case 'online':
				return <ArticleInsertFormOnline
					submit={formSubmitted}
					onValidationSuccess={(form: InsertOnlineForm) => handleValidationSuccess(form)}
					onValidationError={() => setFormSubmitted(false)}
				/>;
			case 'socialmedia':
				return <ArticleInsertFormSocial
					submit={formSubmitted}
					onValidationSuccess={(form: InsertSocialForm) => handleValidationSuccess(form)}
					onValidationError={() => setFormSubmitted(false)}
				/>;
			case 'print_dmr':
			case 'print':
				return <ArticleInsertPrintForm
					submit={formSubmitted}
					onValidationSuccess={(form: InsertPrintForm) => handleValidationSuccess(form)}
					onValidationError={() => setFormSubmitted(false)}
				/>;
			default:
				return null;
		}
	}, [setFormSubmitted, formSubmitted, handleValidationSuccess, selectedFeed]);

	const dispatchedBulkInsert = useMemo(() => {
		return (
			<div id="insertMentionBulkDispatched" className="insert-bulk">
				<div>{t(`insert_article.modal.dispatched.header`)}</div>
				<div>{t(`insert_article.modal.dispatched.text`)}</div>
				<div>{t(`insert_article.modal.dispatched.text2`)}</div>
			</div>
		);
	}, [t]);

	const reportMissingSuccess = useMemo(() => {
		const missingEntity = step === "REPORT_MISSING_ARTICLE" ? "article" : "media";
		return (
			<div id="insertMentionOnlineForm" className="insert-mention-form-content">
				<div className="form-row">
					<TextField
						id="insertMentionOnlineFormUrl"
						className="text-field"
						fullWidth={true}
						label="Url"
						value={url}
						disabled={true}
					/>
				</div>
				<div className="info-green">
					<div className="form-row">
						<FontIcon className="icon">done</FontIcon>
						<div className="column">
							<div className="missing-title green-title">{t("insert_article.modal.missing.success")}</div>
							<div>{t(`insert_article.modal.missing.${missingEntity}.await`)}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}, [step, t, url]);

	const dialogContent = useMemo(() => {
		let content: any;

		switch (step) {
			case "SELECT_TYPE":
				content = <ArticleInsertSelectType />;
				break;
			case "SELECT_FEED":
				content = isBulkInsert ? <ArticleInsertBulk /> : <ArticleInsertSelectFeed />;
				break;
			case "FILL_FORM":
				content = mentionForm;
				break;
			case "BULK_PREVIEW":
				content = <ArticleBulkPreview onClose={() => onSetShowInsertDocument(false)}/>;;
				break;
			case "INSERT_DISPATCHED":
				content = dispatchedBulkInsert;
				break;
			case "SELECT_FOUND_DOCUMENTS":
				content = <ArticleInsertSelectDocument />;
				break;
			case "REPORT_MISSING_MEDIA":
			case "REPORT_MISSING_ARTICLE":
				content = reportMissingSuccess;
				break;
			default:
				content = null;
				break;
		}

		return (
			<div className="insert-document-dialog-content">
				{content}
			</div>
		);
	}, [step, isBulkInsert, mentionForm, dispatchedBulkInsert, reportMissingSuccess, onSetShowInsertDocument]);

	const title = useMemo((): JSX.Element => {
		let titleStr, tabs, icon;
		if (step === "SELECT_TYPE") titleStr = `${t('insert_article.modal.title')} - ${t('insert_article.modal.choose_channel')}`;
		else {
			titleStr = t(`insert_article.modal.title_${type}`);
			icon = <FontIcon className='insert-title-icon' iconClassName={`icon-${type === "online" ? "news" : type}`} />;
		}

		if (step === "SELECT_FEED") {
			const isBulkEnabled = type === 'print' || featureFlags.isEnabled(`bulk-manual-import-${type}`);
			const tabChange = () => {
				if (!isBulkEnabled) return;
				onSetIsBulkInsert(isBulkInsert ? 0 : 1);
			};
			tabs = <Tabs className="insert-tabs" mobile tabId="insertTabs" activeTabIndex={isBulkInsert} onTabChange={tabChange}>
				<Tab key='single' label={t('insert_article.modal.tabs.single')} />
				<Tab key='bulk' className={!isBulkEnabled ? "disabled-tab" : ""} disabled={!isBulkEnabled} label={t('insert_article.modal.tabs.bulk')} />
			</Tabs>;
		}

		return (
			<div className="insert-title">
				{icon}
				<span>{titleStr}</span>
				{tabs}
			</div>
		);
	}, [step, type, isBulkInsert, onSetIsBulkInsert, t]);

	const handleSubmit = useCallback((continueInsert: boolean = false) => {
		onSetContinueInsert(continueInsert);
		if (step === "FILL_FORM") {
			if (!selectedFeed) return;
			setFormSubmitted(true);
		} else if (step === "SELECT_FOUND_DOCUMENTS") onChangeStep("INSERT_DOCUMENTS");
	}, [step, selectedFeed, setFormSubmitted, onSetContinueInsert, onChangeStep]);

	const buttons = useMemo(() => {
		let isNextButtonDisabled;
		if (isBulkInsert) isNextButtonDisabled = !file;
		else isNextButtonDisabled = !selectedFeed || !!invalidUrlError || (selectedFeed && selectedFeed.type !== 'print' && selectedFeed.type !== 'print_dmr' && isEmpty(url));

		const buttons = [
			<Button flat id="insertDocumentDialogCancelBtn" onClick={() => onSetShowInsertDocument(false)}>
				{t('form.cancel')}
			</Button>
		];
		const backToSelectType = () => {
			onChangeStep("SELECT_TYPE");
			onSetSelectedFeed(null);
			onSetUrl("");
			onSetIsBulkInsert(0);
			onSetInvalidUrl({invalidError: false});
		};

		if (step === "SELECT_FEED") {
			buttons.push(<Button flat id="insertDocumentDialogBackBtn" onClick={backToSelectType}>{t('form.back')}</Button>);
			buttons.push(<Button flat className='left-btn' id="insertDocumentDialogNextBtn"
				onClick={() => onChangeStep(isBulkInsert ? "BULK_PREVIEW" : "SELECT_FOUND_DOCUMENTS")}
				disabled={isNextButtonDisabled}>{t('form.next')}</Button>);
		} else if (step === "FILL_FORM" || step === "SELECT_FOUND_DOCUMENTS") {
			buttons.push(<Button flat id="insertDocumentDialogBackBtn" onClick={() => { onChangeStep("SELECT_FEED"); }}>{t('form.back')}</Button>);
			buttons.push(<Button flat id="insertDocumentDialogAcceptContinueBtn" className="left-btn" onClick={() => handleSubmit(true)}>{t('insert_article.modal.save_and_continue')}</Button>);
			buttons.push(<Button flat id="insertDocumentDialogAcceptBtn" onClick={() => handleSubmit()}>{t('insert_article.modal.save_and_finish')}</Button>);
		} else if (step === "INSERT_DISPATCHED") {
			buttons.pop();
			buttons.push(<Button flat id="insertDocumentDialogAcceptBtn" className="left-btn" onClick={() => onSetShowInsertDocument(false)}>{t('form.accept')}</Button>);
		} else if (step === "REPORT_MISSING_ARTICLE" || step === "REPORT_MISSING_MEDIA") {
			buttons.push(<Button flat id="insertDocumentDialogBackBtn" onClick={() => { onChangeStep("SELECT_FEED"); }}>{t('form.back')}</Button>);
		}
		return buttons;
	}, [isBulkInsert, file, selectedFeed, invalidUrlError, url, t, step, onSetShowInsertDocument, onChangeStep, onSetSelectedFeed, onSetUrl, onSetIsBulkInsert, onSetInvalidUrl, handleSubmit]);

	const modalContainer = React.useMemo(() => (
		<DialogContainer
			id="articleInsertDocumentDialog"
			dialogClassName={`${step === "FILL_FORM" ? "fill-form-step" : ""}`}
			aria-describedby="articleInsertDocumentDialog"
			title={title}
			visible={true}
			onHide={() => onSetShowInsertDocument(false)}
			actions={buttons}
			contentClassName={`${step !== "SELECT_FEED" ? "scrollable" : ""}`}
			children={dialogContent}
			focusOnMount={false}
			portal
			modal
		/>
	), [buttons, dialogContent, onSetShowInsertDocument, step, title]);

	const fullContainer = React.useMemo(() => (
		<DialogContainer
			id="articleInsertDocumentFullDialog"
			aria-describedby="articleInsertDocumentDialog"
			visible={true}
			onHide={() => onSetShowInsertDocument(false)}
			actions={buttons}
			contentClassName={``}
			children={dialogContent}
			focusOnMount={false}
			portal
			fullPage
		/>
	), [buttons, dialogContent, onSetShowInsertDocument]);

	return (
		step !== "BULK_PREVIEW" ? modalContainer : fullContainer
	);
};

export default withT(ArticleInsert);
