import React from "react";
import FontIcon from "react-md/lib/FontIcons/FontIcon";
import moment from "moment";

import { some, map, minBy, size, isEmpty } from "lib/imports/lodash";
import { withT } from "lib/i18n";

import { Button } from "lib/imports/react-md";
import { FacebookUser, FacebookPermission } from "store/entities/FacebookUser";
import Image from "components/common/Image/Image";

import { ComponentProps } from "./types";

import "./FocusFeedDefinitionSocialInstagramAccount.scss";

const FocusFeedDefinitionSocialInstagramAccount = (props: ComponentProps) => {
	const { t, account, removable } = props;
	const { onSelected, onSetFacebookPermissionsMissing, onSetDialogMode } = props;

	const isFacebookUserValid = some(account.facebookUsers, facebookUser =>
		facebookUser.is_valid && moment().isBefore(facebookUser.expires_at)
	);
	const isValid = isFacebookUserValid && account.mentions_subscribed;

	let permissionsMissing: FacebookPermission[] = [];
	if (isFacebookUserValid) {
		const facebookUsersPermissionsMissing = map(account.facebookUsers,
			facebookUser => FacebookUser.getMissingPermissions(facebookUser.scope.split(',') as FacebookPermission[])
		);
		permissionsMissing = minBy(facebookUsersPermissionsMissing, size) || [];
	}

	return (
		<div
			id={`focusFeedDefinitionSocialInstagramAccount${account.id}`}
			className={`definition-instagram-account ${!isValid ? 'not_valid' : ''}`}
			title={!isFacebookUserValid ? t('feed.form.instagram.expired_account') : undefined}
			onClick={() => onSelected()}
		>
			<Image url={account.profile_picture_url} ><FontIcon iconClassName="icon-instagram"></FontIcon></Image>
			<span>{account.name}</span>
			<span>(@{account.screen_name})</span>
			{!isFacebookUserValid && <span className="no-line-through"> [{t('feed.form.instagram.expired')}]</span>}
			{!account.mentions_subscribed && <span className="no-line-through"> [{t('feed.form.instagram.webhook_failed')}]</span>}
			{!isEmpty(permissionsMissing) && (
				<div className="instagram-account-permissions-warn">
					<Button icon
						title={t('facebook.instagram.permissions_missing.icon_title')}
						onClick={ev => {
							ev.stopPropagation();
							onSetFacebookPermissionsMissing(permissionsMissing);
						}}
					>warning</Button>
				</div>
			)}
			{!account.mentions_subscribed && (
				<div id={`focusFeedDefinitionSocialWebhookFailedWarningBtnInstagramAccount${account.id}`} className="instagram-account-permissions-warn">
					<Button icon
						title={t('feed.form.instagram.webhook_failed_title')}
						onClick={ev => {
							ev.stopPropagation();
							onSetDialogMode('instagram_webhook_failed');
						}}
					>warning</Button>
				</div>
			)}
			{removable && <FontIcon>clear</FontIcon>}
		</div>
	);
};

export default withT(FocusFeedDefinitionSocialInstagramAccount);
