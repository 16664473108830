import axios from 'axios';
import moment from 'moment';
import { Currency } from 'class/Tenant';

const exchangeRatesBaseUrl = process.env.REACT_APP_CURRENCY_EXCHANGE_RATES_URL;

type ExchangeRates = { [date: string]: number | string };
/* exact format is:
{
	<YYYYMMDD>: number,
	... [ all days in a year ]
	lastDay: '<YYYYMMDD>',
	firstDay: '<YYYYMMDD>'
}
*/

// internal settings needed
interface Settings {
	currency: Currency;
	exchangeRates: ExchangeRates;
}

const _settings: Settings = {
	currency: 'EUR',
	exchangeRates: {}
};

const _symbols = {
	EUR: '€',
	USD: '$',
	GBP: '£',
	JPY: '¥',
	CNY: '¥'
};

export function convert(eurValue: number, date: string) {
	if (_settings.currency === 'EUR') return eurValue;
	return eurValue * _getCurrencyMultiplier(date);
}

export function convertReverse(currencyValue: number, date: string) {
	if (_settings.currency === 'EUR') return currencyValue;
	return currencyValue / _getCurrencyMultiplier(date);
}

export function getSymbol() {
	return _symbols[_settings.currency];
}

function _getCurrencyMultiplier(date: string): number {
	const day = moment(date).format('YYYYMMDD');
	const rates = _settings.exchangeRates; // shorthand
	const multiplier = rates[day];
	if (multiplier) return multiplier as number;
	// day not found, if the date is after lastDay apply lastDay, otherwise is in the past, apply firstDay
	if (parseInt(day, 10) > parseInt(rates.lastDay as string, 10)) return rates[rates.lastDay] as number;
	return rates[rates.firstDay] as number;
}

export async function setCurrency(newCurrency: Currency) {
	_settings.currency = newCurrency;
	try {
		if (_settings.currency === 'EUR') _settings.exchangeRates = {};
		else _settings.exchangeRates = await axios.get(`${exchangeRatesBaseUrl}/${_settings.currency}.json`).then(response => response.data as ExchangeRates);
	} catch (err) {
		console.warn(`Can not retrieve exchange rates for ${_settings.currency}. Fallback to EUR`);
		console.error(err);
		_settings.exchangeRates = {};
		_settings.currency = 'EUR';
		throw err;
	}
}
