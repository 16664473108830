import React from 'react';
import moment from 'moment';
import { Button } from 'lib/imports/react-md';
import { isEmpty, map } from 'lib/imports/lodash';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';

const ReportSetupSettingsSummary: React.FC<ComponentProps> = props => {
	const { t, step, type, period, minDate, maxDate, currency, language, country, categories, tags, tagsTopics, allDmrCategories = {} } = props;
	const { onApply, onGoBackToSettings } = props;

	const tagsWithTopic = React.useMemo(() => {
		return map(tags, (tag, i) => ({ ...tag, topic: tagsTopics[i] }));
	}, [tags, tagsTopics]);

	const dateText = React.useMemo(() => {
		if (type === 'periodic') {
			if (period === 'monthly') return t('reports.0_vs_1', { beginDate: moment(minDate).format('MMMM YYYY'), endDate: moment(maxDate).format('MMMM YYYY') });
			else return t('reports.0_vs_1', { beginDate: moment(minDate).format('[Q]Q YYYY'), endDate: moment(maxDate).format('[Q]Q YYYY') });
		}
		return t('reports.from_to', { beginDate: moment.parseZone(minDate).format('L'), endDate: moment.parseZone(maxDate).format('L'), interpolation: { escapeValue: false } });
	}, [t, type, period, minDate, maxDate]);

	const countryText = React.useMemo(() => {
		if (country === 'all') return t('reports.all_male_sing');
		const splitCountries = country.split(',');
		return map(splitCountries, countryCode => t(`filters.country.${countryCode}`)).join(', ');
	}, [t, country]);

	const categoriesText = React.useMemo(() => {
		if (isEmpty(categories)) return '-';
		return map(categories, category => category.includes('-') ? allDmrCategories[category] : t(`filters.categories.${category}`)).join(', ');
	}, [categories, allDmrCategories, t]);

	const tagsText = React.useMemo(() => {
		if (isEmpty(tagsWithTopic)) return '-';
		return map(tagsWithTopic, tag => `${tag.topic.name} > ${tag.name}`).join(', ');
	}, [tagsWithTopic]);

	const button = React.useMemo(() => {
		if (step === 'settings') return <Button id='reportSetupSettingsSummaryApplyBtn' className='form-btn save-btn' flat onClick={onApply}>{t('reports.apply_and_select_feeds')}</Button>;
		return <Button id='reportSetupSettingsSummaryEditSettingsBtn' className='form-btn' flat onClick={onGoBackToSettings}>{t('reports.edit_settings')}</Button>;
	}, [step]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div id="reportSetupSettingsSummary" className="report-summary form-section buttons-section" >
			<div className="form-section-content">
				<div>
					<div className='setup-summary-info'>
						<div className='setup-summary-info-header'>
							<span className='setup-summary-info-header-type'>{t(`reports.${type}_report`)}</span>
							<span className='setup-summary-info-header-date'>{dateText}</span>
						</div>
						<div className='setup-summary-info-table'>
							<div>
								<span className='setup-summary-info-table-name'>{t('reports.language')}:</span>
								<span className='setup-summary-info-table-value'>{t(`reports.language_${language}`)}</span>
							</div>
							<div>
								<span className='setup-summary-info-table-name'>{t('reports.categories')}:</span>
								<span className='setup-summary-info-table-value'>{categoriesText}</span>
							</div>
							<div>
								<span className='setup-summary-info-table-name'>{t('reports.currency')}:</span>
								<span className='setup-summary-info-table-value'>{t(`reports.currency_${currency}`)}</span>
							</div>
							<div>
								<span className='setup-summary-info-table-name'>{t('reports.tags')}:</span>
								<span className='setup-summary-info-table-value'>{tagsText}</span>
							</div>
							<div>
								<span className='setup-summary-info-table-name'>{t('reports.country')}:</span>
								<span className='setup-summary-info-table-value'>{countryText}</span>
							</div>
						</div>
					</div>
				</div>
				<div>{button}</div>
			</div>
		</div>
	);
};

export default withT(React.memo(ReportSetupSettingsSummary));
