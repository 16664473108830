import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { operators } from 'store/focus/list';
import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import FocusList from './FocusList';
import { TenantObject } from 'class/Tenant';
import { UserObject } from 'class/User';

const mapStateToProps = ({ app: { profile }, focus: { list } }: State): StateProps => ({
	tenant: profile.tenant as TenantObject,
	user: profile.user as UserObject,
	focusList: list.focusList,
	loading: list.loading
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onFetchFocusList: () => dispatch(operators.fetchFocusList())
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusList);
