import { all } from 'redux-saga/effects';

import editSagas from './edit/sagas';

// define sagas generator function to register all sagas
export default function* sagas() {
	yield all([
		editSagas()
	]);
}
