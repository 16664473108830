import ReactGA from 'react-ga';

export type GoogleAnalyticsTrackEvent = {
	category: string,
	action: string,
	label?: string,
	value?: number
};

const init = () => {
	ReactGA.initialize([{
		trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID!,
		titleCase: false,
		gaOptions: {
			siteSpeedSampleRate: 100
		}
	}]);
};

const trackEvent = (eventData: GoogleAnalyticsTrackEvent) => {
	ReactGA.event({
		category: eventData.category,
		action: eventData.action,
		label: eventData.label,
		value: eventData.value
	});
};

const trackPage = (page: string) => {
	ReactGA.set({ page });
	ReactGA.pageview(page);
};

const startTimer = () => {
	return new Date();
};

const endTimer = (startTime: Date, category: string, variable: string, label?: string) => {
	ReactGA.timing({
		category,
		variable,
		value: new Date().getTime() - startTime.getTime(),
		label
	});
};

export default { init, trackEvent, trackPage, startTimer, endTimer };
