import React from 'react';
import { CircularProgress } from 'lib/imports/react-md';

import { isCurrentPage, getCurrentPage } from 'lib/pathCheck';
import { withT } from 'lib/i18n';
import { Facet } from 'class/Facet';
import SearchFacetsGroup from './Group/';
import SearchFacetsDialog from './Dialog/';
import SearchFacetsFeedsGroup from './FeedsGroup/';
import SearchFacetsTreeGroup from './TopicTagsGroup';
import { ComponentProps } from './types';

import './SearchFacets.scss';

class SearchFacets extends React.PureComponent<ComponentProps> {

	public componentDidMount() {
		const { location } = this.props;
		if (!this.props.focusList && isCurrentPage(location.pathname, "article")) this.props.onFetchFocusList();
	}

	public render() {
		const { location, focusList, facets, loadingFacets, areTopicsFetched, showMoreFacetGroupKey, feedType } = this.props;
		const currentPage = getCurrentPage(location.pathname);

		if (!["article", "preview"].includes(currentPage)) return null;

		const shouldShowFocusAndFeeds = currentPage === "article";
		const waitingForTopics = !areTopicsFetched && currentPage === "article";
		let facetsKeys = Facet.facetGroupKeys[currentPage as "article" | "preview"];

		if (currentPage === 'preview' && feedType) {
			if (feedType === 'print') facetsKeys = Facet.facetGroupKeys.printPreview;
			else if (feedType === 'print_dmr') facetsKeys = Facet.facetGroupKeys.printDmrPreview;
		}

		const facetGroups = (
			<div id="searchFacetsGroups">
				{
					(waitingForTopics || (loadingFacets && !facets) || (!focusList && shouldShowFocusAndFeeds)) ? <CircularProgress id="searchFacetsGroupsLoading" /> :
						<>
							{shouldShowFocusAndFeeds ? <SearchFacetsFeedsGroup /> : null}
							{facets ? facetsKeys.map(groupKey => {
								if (groupKey === Facet.tagsGroupKey) return <SearchFacetsTreeGroup key={groupKey} />;
								return <SearchFacetsGroup key={groupKey} groupKey={groupKey} groupItems={facets.groups[groupKey] || []} />;
							})
								: null}
						</>
				}
			</div>
		);

		return (
			<>
				{facetGroups}
				{showMoreFacetGroupKey ? <SearchFacetsDialog groupKey={showMoreFacetGroupKey} /> : null}
			</>
		);
	}
}

export default withT(SearchFacets);
