import React from "react";
import { FontIcon } from "lib/imports/react-md";

import GA from 'lib/googleAnalytics';
import { BulkDocument, PreparedDocument } from "class/Document";
import { FeedType } from "class/Feed";
import { TenantObject } from "class/Tenant";
import DocumentItemImage from './DocumentItemImage/DocumentItemImage';
import DocumentItemValuation from "./DocumentItemValuation";

import "./DocumentItem.scss";

type OwnProps = {
	document: (PreparedDocument | BulkDocument),
	type?: FeedType,
	tenant: TenantObject
};

const trackClick: () => void = () => {
	GA.trackEvent({ category: 'Feed results', action: 'Article' });
};

function getIcon(document: PreparedDocument | BulkDocument, type: FeedType = 'print') {
	if ("iconForced" in document && document.iconForced) {
		return (<span title={("providerName" in document && document.providerName) || ''}>
			<img className="forced-icon" src={document.iconForced} onError={e => (e.target as HTMLImageElement).remove()} alt="" />
		</span>);
	} else if ("iconSVG" in document && document.iconSVG) {
		return (<span title={document.providerName } className={`document-item-provider-icon-svg ${document.provider}`}><document.iconSVG /></span>);
	} else {
		return (<span title={("providerName" in document && document.providerName) || ("mediaName" in document && document.mediaName) || ''}>
			<FontIcon
				className={`document-item-provider-icon ${("providerName" in document && document.providerName) || type}`}
				iconClassName={("icon" in document && document.icon) || `icon-${type}`} />
		</span>);
	}
}

const DocumentItem = ({ document, type, tenant }: OwnProps) => {
	const reachCounter = React.useMemo(() => {
		let reachTitle;
		let reachValue;
		if ("counters" in document) {
			reachTitle = document.counters.reach ? document.counters.reach.title : '';
			reachValue = document.counters.reach ? document.counters.reach.value : null;
		} else if ("circulation" in document && "formatedData" in document) {
			reachTitle = document.formatedData!.reach!.title;
			reachValue = document.formatedData!.reach!.value;
		}

		return (reachValue ? <div className="document-item-counter-value">
			{ 
				document.provider && document.provider === 'YouTube' && tenant && tenant.guid === '3E75E26A-9797-11E7-BACE-EA7C63F109A7' ? 
					<span className="document-item-text-counter" title={reachTitle}>Subscribers: {reachValue}</span> :
					<>
						<span title={reachTitle}>{reachValue}</span>
						<i className="document-item-counter-icon reach material-icons" title={reachTitle}>wifi</i>
					</>
			}
		</div> : null);
	}, [document, tenant]);

	const viewsCounter = React.useMemo(() => {
		let viewsTitle;
		let viewsValue;
		if ("counters" in document) {
			viewsTitle = document.counters.views ? document.counters.views.title : '';
			viewsValue = document.counters.views ? document.counters.views.value : null;
		}

		return (viewsValue ?
			<div className="document-item-counter-value">
				{ 
					document.provider && document.provider === 'YouTube' && tenant && tenant.guid === '3E75E26A-9797-11E7-BACE-EA7C63F109A7' ? 
						<span className="document-item-text-counter" title={viewsTitle}>Views: {viewsValue}</span> :
						<>
							<span title={viewsTitle}>{viewsValue}</span>
							<i className={"document-item-counter-icon icon-views"} title={viewsTitle} />
						</>
				}				
			</div> : null);
	}, [document, tenant]);

	const engagementCounter = React.useMemo(() => {
		return "counters" in document && document.counters.engagement ?
			<div className="document-item-counter-value">
				<span title={document.counters.engagement.title}>{document.counters.engagement.value}</span>
				<i className={"document-item-counter-icon icon-like"} title={document.counters.engagement.title} />
			</div> : null;
	}, [document]);

	const repliesCounter = React.useMemo(() => {
		return "counters" in document && document.counters.replies ?
			<div className="document-item-counter-value">
				<span title={document.counters.replies.title}>{document.counters.replies.value}</span>
				<i className={"document-item-counter-icon icon-replies"} title={document.counters.replies.title} />
			</div> : null;
	}, [document]);

	const authorIcon = React.useMemo(() => {
		return "author_icon" in document && document.author_icon ?
			<div className="document-item-author-icon">
				<a href={document.author_link} target="_blank" rel="noopener noreferrer">
					<img className="author-icon" src={document.author_icon} onError={e => (e.target as HTMLImageElement).remove()} alt="" />
				</a>
			</div> : null;
	}, [document]);

	const authorName = React.useMemo(() => {
		if ("author_link" in document && "author_name" in document) {
			return (<div className="document-item-author-name">
				<a href={document.author_link} target="_blank" rel="noopener noreferrer">
					<div dangerouslySetInnerHTML={{ __html: document.author_name + (!!document.country ? ` (${document.country})` : '') }}></div>
				</a>
			</div>);
		} else if ("mediaName" in document) {
			return (<div className="document-item-author-name">
				<div dangerouslySetInnerHTML={{ __html: document.mediaName + (!!document.country ? ` (${document.country.id})` : '') }}></div>
			</div>);
		}

		return null;
	}, [document]);

	const docDate = React.useMemo(() => {
		if("formatedData" in document && document.formatedData!.date) {
			return <div className="document-item-date" title={"date" in document ? document.formatedData!.date.value : ''}>
				{document.formatedData!.date.title}
			</div>;
		} else if("date" in document) {
			return (<div className="document-item-date" title={"dateTitle" in document ? document.dateTitle : ''}>
				{document.date}
			</div>);
		}
	}, [document]);

	const docContent = React.useMemo(() => {
		if (!("link" in document)) {
			return (<div className="document-item-content">
				<div className="document-item-content-title" dangerouslySetInnerHTML={{ __html: document.title as string || '' }}></div>
				<div className="document-item-content-source" dangerouslySetInnerHTML={{ __html: document.content || '' }}></div>
				<DocumentItemValuation defaultValuation={document.formatedData!.miv} brandedValuation={[]}></DocumentItemValuation>
			</div>);
		} else {
			return (<div className="document-item-content">
				<a href={document.link} onClick={() => trackClick()} target="_blank" rel="noopener noreferrer">
					<div className="document-item-content-title" dangerouslySetInnerHTML={{ __html: document.title }}></div>
				</a>
				<a href={document.link} onClick={() => trackClick()} target="_blank" rel="noopener noreferrer">
					<div className="document-item-content-source" dangerouslySetInnerHTML={{ __html: document.content }}></div>
				</a>
				<DocumentItemValuation defaultValuation={document.counters.echo!} brandedValuation={document.counters.branded_miv}></DocumentItemValuation>
			</div>);
		}
	}, [document]);

	const docItemImg = React.useMemo(() => {
		return "link" in document && "image" in document && "cover" in document && "cover_link" in document ?
			<DocumentItemImage
				mainLink={document.link}
				mainImage={document.image}
				secondaryImage={document.cover}
				secondaryLink={document.cover_link}
			></DocumentItemImage> : null;
	}, [document]);

	return (
		<div className="document-item">
			<div className="document-item-left-info">
				<div className="document-item-provider-icon-box">
					{getIcon(document, type)}
				</div>
				<div className="document-item-counters">
					{reachCounter}
					{viewsCounter}
					{engagementCounter}
					{repliesCounter}
				</div>
			</div>
			<div className="document-item-author-info">
				{authorIcon}
				{authorName}
				{docDate}
			</div>
			{docContent}
			{docItemImg}
		</div>
	);
};

export default DocumentItem;
