import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import FocusDashboardLogo from './FocusDashboardLogo';

const mapStateToProps = ({ focus: { dashboard } }: State): StateProps => ({
	focus: dashboard.focus!,
	imageMeta: dashboard.logo.imageMeta,
	loading: dashboard.logo.loading
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FocusDashboardLogo);
