import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { operators } from 'store/app/facebook';
import selectors from 'store/app/facebook/selectors';
import { DispatchProps, StateProps } from './types';
import { State } from 'store/types';

import FacebookAuth from './FacebookAuth';

const mapStateToProps = (state: State): StateProps => ({
	dialogMode: selectors.getDialogMode(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onUpdateAuth: authResponse => dispatch(operators.updateAuth(authResponse)),
	onSetDialogMode: mode => dispatch(operators.setDialogMode(mode)),
	onSkip: () => dispatch(operators.skip())
});

export default connect(mapStateToProps, mapDispatchToProps)(FacebookAuth);
