import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/search/results';
import profileSelectors from 'store/app/profile/selectors';
import { DispatchProps, StateProps } from './types';

import DocumentCategorization from './DocumentCategorization';
import { getFullCategoryName } from 'store/entities/Category/selectors';

const mapStateToProps = (state: State): StateProps => ({
	user: profileSelectors.getUserInstance(state),
	getFullCategoryName: (id: string) => getFullCategoryName(state, id)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onRemoveDocumentCategory: (id, category) => dispatch(operators.removeDocumentCategory({ id, category })),
	onRemoveDocumentTag: (id, tagId) => dispatch(operators.removeDocumentTag({ id, tagId }))
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentCategorization);
