import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { push } from "connected-react-router";

import { State } from 'store/types';
import reportSelectors from 'store/ui/report/selectors';
import { DispatchProps, StateProps } from './types';

import ReportSetupFocus from './ReportSetupFocus';

const mapStateToProps = (state: State): StateProps => ({
	focusId: reportSelectors.getFocusId(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onPushRoute: path => dispatch(push(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSetupFocus);
