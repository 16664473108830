//TODO: Move this class to gis-shared libraries (it's implemented in Mekong & Discover)
//TODO: Extend the base class here in Discover to add specific things from Discover
import keysBy from 'lodash/keyBy';
export type Permission = "tenant_settings.edit" | "feed.print.manage" | "search.unrestricted_period" | "recovery.unrestricted_period" |
	"feed.save.apply_to_past_results" | "switch_tenant" | "admin_access" | "facebook_visible" | "focus.view" | "focus.manage_acl" |
	"document.tag" | "document.categorize" | "document.delete" | "focus.create" | "focus.rename" | "focus.change_logo" | "focus.delete" |
	"feed.create" | "feed.rename" | "feed.definition.edit" | "feed.delete" | "newsletter.list" | "newsletter.edit" | "newsletter.multiple_logo" |
	"newsletter.multiple_focus" | "newsletter.delete" | "newsletter.create" | "document.create" | "queue_newsletter" | "reports.view";

export type LanguageCode = "en" | "fr" | "es" | "it";
export type Locale = "en-US" | "en-GB" | "fr-FR" | "es-ES" | "it-IT";
export type ResultsPageSize = 20 | 50 | 100 | 200;

export type Settings = {
	language_code: LanguageCode
	locale: Locale
	results_page_size: ResultsPageSize
	timezone: string
};

export class UserObject {
	public id: string;
	public name: string;
	public email: string;
	public role: string;
	public permissions: Permission[];
	public settings: Settings;
	public usePortal: boolean;
	public ecosystem_id: string;

	constructor(values?: Partial<UserObject>) {
		if (values) Object.assign(this, values);
	}
}

export class User extends UserObject {

	private _permissionsIndex = keysBy(this.permissions);

	public hasPermission(permission: Permission): boolean {
		return (permission in this._permissionsIndex);
	}

	public isInternal(): boolean {
		return !!this.email.match(/@launchmetrics\.com/);
	}
}
