import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { pickBy } from 'lib/imports/lodash';
import { State } from 'store/types';
import profileSelectors from 'store/app/profile/selectors';

import { DispatchProps, StateProps } from './types';
import SelectCategory from './SelectCategory';
import { getAllCategories, getCategoryName } from 'store/entities/Category/selectors';

const mapStateToProps = (state: State): StateProps => ({
	tenant: profileSelectors.getTenantInstance(state)!,
	allDmrCategories: getAllCategories(state),
	filteredDmrCategories: pickBy(getAllCategories(state), (_, key) => key.split('-').length === 3),
	getCategoryName: (id: string) => getCategoryName(state, id)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SelectCategory);
