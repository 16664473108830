import React from 'react';
import { StateProps } from './types';

export const TableBody = ({
	bodyClasses = [],
	children
}: StateProps) => {
	return <tbody className={bodyClasses.join(' ')}>
		{children}
	</tbody>;
};