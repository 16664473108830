import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps, OwnProps } from './types';
import newsletterSelectors from 'store/ui/newsletter/edit/selectors';
import { operators } from 'store/ui/newsletter/edit';
import profileSelectors from 'store/app/profile/selectors';

import NewsletterEdit from './NewsletterEdit';

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
	loading: newsletterSelectors.isLoading(state),
	newsletter: ownProps.newsletterId ? newsletterSelectors.getNewsletter(state, ownProps.newsletterId) : null,
	user: profileSelectors.getUserInstance(state)!
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onQueueNewsletter: newsletter => dispatch(operators.queueNewsletter({formNewsletter: newsletter}))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterEdit);
