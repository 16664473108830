import React from 'react';

import { TextField, Button, FontIcon, CircularProgress } from 'lib/imports/react-md';
import { size, find, trim } from 'lib/imports/lodash';
import { withT } from 'lib/i18n';
import { isEmailListNameTooLong } from 'lib/validate';
import { EmailListObject } from "store/entities/EmailList/types";
import SimpleDialog from 'components/common/dialog/simple';
import NewsletterEmailListResults from '../Results';
import { ResultRow } from '../Results/types';

import { ComponentProps } from './types';
import './EmailLists.scss';

const NewsletterEmailLists = (props: ComponentProps) => {
	const { lists, loadingLists } = props;
	const { onSelectList, onCreateEmailList, onDeleteEmailList, onAddNotification, t } = props;

	const [newListName, setNewListName] = React.useState<string>('');
	const [searchValue, setSearchValue] = React.useState<string>('');
	const [start, setStart] = React.useState(0);
	const [showRemoveDialog, setShowRemoveDialog] = React.useState(false);
	const [listToRemove, setListToRemove] = React.useState<EmailListObject | null>(null);

	const listNameExists = React.useCallback((name: string) => {
		const regex = new RegExp(`^${name}$`, "gi");
		return !!find(lists, list => list.name.match(regex));
	}, [lists]);

	const onAddListInputChange = React.useCallback((value: React.ReactText) => {
		setNewListName(value.toString());
	}, []);

	const onAddListSubmit = React.useCallback(() => {
		const parsedName = trim(newListName);
		if (parsedName === '' || isEmailListNameTooLong(parsedName)) return;
		if (listNameExists(parsedName)) return onAddNotification({ level: 'danger', t: 'error.api_server.list_name_already_exists' });
		setSearchValue('');
		setStart(0);
		onCreateEmailList(parsedName);
		setNewListName('');
	}, [listNameExists, newListName, onAddNotification, onCreateEmailList]);

	const addListSection = React.useMemo(() => (
		<div className="email-list-add-entity-container">
			<span className="email-list-add-entity-label">{t('email_list.new')}</span>
			<form id="emailListsAddListForm" onSubmit={ev => {
				ev.preventDefault();
				onAddListSubmit();
			}}>
				<TextField
					id="emailListsAddListInput"
					className="email-list-add-entity-input"
					label={t('email_list.table.header')}
					fullWidth={true}
					value={newListName}
					onChange={onAddListInputChange}
					error={isEmailListNameTooLong(trim(newListName))}
					errorText={t('error.email_list_name_too_long')}
				/>
			</form>
			<Button
				id="emailListsAddListButton"
				flat
				className="email-list-add-entity-button"
				onClick={onAddListSubmit}
			>
				Add
			</Button>
		</div>
	), [t, newListName, onAddListInputChange, onAddListSubmit]);

	const onSearchInputChange = React.useCallback((value: React.ReactText) => {
		setSearchValue(value.toString());
	}, []);

	const searchSection = React.useMemo(() => (
		<div className="email-list-search-entity-container">
			<span className="email-list-search-entity-label">{t('email_list.table.header')}</span>
			<TextField
				id="emailListsSearchListInput"
				className="email-list-search-entity-input"
				placeholder={'search'}
				fullWidth={true}
				inlineIndicator={<FontIcon className="search-indicator">search</FontIcon>}
				value={searchValue}
				onChange={onSearchInputChange}
			/>
		</div>
	), [onSearchInputChange, searchValue, t]);

	const listsSections = React.useMemo(() => {
		if (loadingLists || !lists) return <CircularProgress id="emailListsLoading" scale={1.25} />;

		const rows: ResultRow[] = lists.map(list => {
			const row = (
				<div id={`emailListsItem-${list.id}`} className="email-list-item" key={list.id}>
					<span className="email-list-item-name">{`${list.name} [${size(list.emails)}]`}</span>
					<Button className="email-list-item-icon email-list-item-edit-button"
						icon
						onClick={() => onSelectList(list.id)}
					>
						edit
					</Button>
					<Button
						className="email-list-item-icon email-list-item-remove-button"
						icon
						onClick={() => {
							setListToRemove(list);
							setShowRemoveDialog(true);
						}}
					>
						clear
					</Button>
				</div>
			);
			return {
				filterValue: list.name,
				row
			};
		});

		return (
			<NewsletterEmailListResults
				pageLimit={20}
				searchValue={searchValue}
				rows={rows}
				start={start}
				onStartChange={start => setStart(start)}
			>
			</NewsletterEmailListResults>
		);
	}, [lists, loadingLists, onSelectList, searchValue, start]);

	const resetDeleteList = React.useCallback(() => {
		setListToRemove(null);
		setShowRemoveDialog(false);
	}, []);

	const removeDialog = React.useMemo(() => (showRemoveDialog ?
		(
			<SimpleDialog
				id="emailListsRemoveListWarning"
				text={t('email_list.remove.title')}
				content={<p>{t('email_list.remove.message')}</p>}
				onAccept={() => {
					onDeleteEmailList(listToRemove!!.id);
					setStart(0);
					resetDeleteList();
				}}
				onCancel={resetDeleteList}
				acceptColor="red"
			/>
		)
		: null), [listToRemove, onDeleteEmailList, resetDeleteList, showRemoveDialog, t]);

	return (
		<div id="emailLists" className="email-list-entity-content-wrapper">
			{removeDialog}
			{addListSection}
			{searchSection}
			{listsSections}
		</div>
	);
};

export default React.memo(withT(NewsletterEmailLists));
