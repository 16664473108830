import {operators} from './actions';
import { Actions } from './actions';
import { createReducer, ReducerHandlers} from 'lib/store/reducer';

export type State = {
    pageNumber: number,
	isLoadMoreVisible: boolean
};

export const INITIAL_STATE: State = {
	pageNumber: 1,
	isLoadMoreVisible: true
};

const reducerHandlers: ReducerHandlers<State> = {
	auditNextPage: (state: State) => ({
		...state,
		pageNumber: state.pageNumber + 1
	}),
	auditResetPage: (state: State) => ({
		...state,
		pageNumber: 1
	}),
	setIsLoadMoreVisible: (state: State, {payload: isLoadMoreVisible}: Actions['SetIsLoadMoreVisible']) => ({
		...state,
		isLoadMoreVisible: isLoadMoreVisible
	})
};

export const reducers = createReducer(INITIAL_STATE, reducerHandlers, operators);