import React from 'react';
import { StateProps } from './types';

export const TableCell = ({
	children,
	cellClasses = [],
	alignVertically = 'top',
	alignHorizontally = 'left'
}: StateProps) => {
	return <td
		className={cellClasses.join(' ')}
		style={{
			textAlign: alignHorizontally,
			verticalAlign: alignVertically
		}}
	>
		{children}
	</td>;
};
