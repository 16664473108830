import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware as reduxApplyMiddleware, Middleware } from 'redux';

import apiResponseMiddleware from './apiResponse';

const customMiddleware = [
	apiResponseMiddleware
];

export const getSagaMiddleware = () => createSagaMiddleware({
	onError: (error: Error, { sagaStack }) => {
		// This is the last resort to catch a saga error, it should never trigger
		console.error('Error catched at root saga level!!', error.toString(), sagaStack);
		if (window.confirm('An unexpected error acurred. Do you want to reload the page?')) {
			window.location.reload();
		}
	}
});

export const applyMiddleware = (history: History, ...middleware: Middleware[]) => {
	return reduxApplyMiddleware(routerMiddleware(history), ...customMiddleware, ...middleware);
};
