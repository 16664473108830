import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/ui/newsletter/draft/edit';
import selectors from 'store/ui/newsletter/draft/edit/selectors';
import NewsletterDraftEditContentLayer from './NewsletterDraftEditContentLayer';

import { DispatchProps, StateProps, OwnProps } from './types';

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
	sectionChannel: selectors.getChannels(state)![ownProps.section]
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onUpdateSection: (section, sectionChannel) => dispatch(operators.updateSection({ section, sectionChannel })),
	onUpdateCustomImageSection: (documentId, imageFile, section, sectionChannel) =>
		dispatch(operators.updateCustomImageSection({ section, sectionChannel, documentId, imageFile }))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterDraftEditContentLayer);
