import { createReducer, ReducerHandlers } from 'lib/store/reducer';

import { TenantObject } from 'class/Tenant';
import { UserObject } from 'class/User';

import { Actions, operators } from './actions';

export type State = {
	readonly tenant: TenantObject | null,
	readonly user: UserObject | null,
	readonly loading: boolean
};

export const INITIAL_STATE: State = {
	tenant: null,
	user: null,
	loading: false
};

const reducerHandlers: ReducerHandlers<State> = {
	// TODO: store only ids and get the data from the orm
	setProfile: (state, { payload: profile }: Actions["SetProfile"]) => ({
		...state,
		tenant: profile.tenant,
		user: profile.user
	}),
	updateTenantSettings: state => ({
		...state,
		loading: true
	}),
	updateTenantSettingsSuccess: (state, { payload: settings }: Actions["UpdateTenantSettingsSuccess"]) => ({
		...state,
		tenant: { ...state.tenant!, settings },
		loading: false
	}),
	updateTenantSettingsError: state => ({
		...state,
		loading: false
	}),
	updateUserSettings: state => ({
		...state,
		loading: true
	}),
	updateUserSettingsSuccess: (state, { payload: settings }: Actions["UpdateUserSettingsSuccess"]) => ({
		...state,
		user: { ...state.user!, settings },
		loading: false
	}),
	updateUserSettingsError: state => ({
		...state,
		loading: false
	}),
	fetchTenantUsersSuccess: (state, { payload: tenantUsers }: Actions["FetchTenantUsersSuccess"]) => ({
		...state,
		tenant: { ...state.tenant as TenantObject, users: tenantUsers }
	}),
	fetchTenantUsersError: state => ({
		...state,
		tenant: { ...(state.tenant as TenantObject), users: undefined }
	})
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
