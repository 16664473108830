import { createReducer, ReducerHandlers } from "lib/store/reducer";
import { Actions, operators } from "./actions";

export type State = {
	readonly suggested_tags: string[] | null
};

export const INITIAL_STATE: State = {
	suggested_tags: null
};

const reducerHandlers: ReducerHandlers<State> = {
	setSuggestedTags: (state, { payload: { suggested_tags } }: Actions["SetSuggestedTags"]): State => ({
		...state,
		suggested_tags
	})
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
