import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { operators as notificationOperators } from 'store/app/notifications';
import { operators as profileOperators } from 'store/app/profile';
import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import { TenantObject } from 'class/Tenant';
import TenantSettings from './TenantSettings';
import { UserObject } from 'class/User';

const mapStateToProps = ({ app: { profile: { tenant, user, loading } } }: State): StateProps => ({
	tenantSettings: (tenant as TenantObject).settings,
	loading,
	user: user as UserObject
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onUpdateTenantSettings: tenantSettings => dispatch(profileOperators.updateTenantSettings(tenantSettings)),
	onAddNotification: notification => dispatch(notificationOperators.add({ notification }))
});

export default connect(mapStateToProps, mapDispatchToProps)(TenantSettings);
