import React from 'react';
import { Redirect, Route, Switch, Link } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { Tab, Tabs } from 'lib/imports/react-md';
import { keys } from 'lib/imports/lodash';

import Facebook from './Facebook';
import AppToolbar from 'components/App/Toolbar';
import Notifications from 'components/App/Notifications';
import Article from 'components/Article';
import Focus from 'components/Focus';
import Newsletter from 'components/Newsletter';
import Topic from 'components/Topic';
import Report from 'components/Report';
import ArticleForm from 'components/Article/Form';
import { User } from 'class/User';
import { ComponentProps } from './types';

import { withT } from 'lib/i18n';

import './App.scss';
import Audit from 'components/Audit';
import Filters from './Filters';
import { isCurrentPage } from 'lib/pathCheck';

const tabIndex: { [tab: string]: number } = {
	article: 0, // Feeds
	focus: 0, // Feeds
	topic: 0, // Feeds
	newsletter: 1, // Newsletter
	report: 2, // Reports
	audit: 3 // TODO delete if not needed
};

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_KEY,
	environment: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'production' : process.env.REACT_APP_ENVIRONMENT
});

const appPath = `/:section(${keys(tabIndex).join('|')})`;

const App: React.FC<ComponentProps> = props => {
	const { authenticated, location, user: userObject, t } = props;

	const user = React.useMemo(() => new User(userObject!), [userObject]);
	const filters = React.useMemo(() => {
		if (!isCurrentPage(location.pathname, "article")) return null;
		return (
			<div className="app-filter-line">
				<div className="app-tabs-wrapper">
					<Filters></Filters>
					<ArticleForm />
				</div>
			</div>
		);
	}, [location]);

	if (!authenticated) return (<div id="appLoader"><img src="/appLoader.svg" alt="" /></div>);

	const tabs: JSX.Element[] = [];
	tabs.push(<Tab key='feeds' component={Link} to="/article" label={t('tabs.articles')} />);
	if (user.hasPermission('newsletter.list')) tabs.push(<Tab key='newsletter' component={Link} to="/newsletter" label={t('tabs.newsletters')} />);
	if (user.hasPermission('reports.view')) tabs.push(<Tab key='report' component={Link} to="/report" label={t('tabs.analytics')} />);

	return (
		<div id="app">
			<AppToolbar />
			<Switch>
				<Route path={appPath} render={({ match }) => {
					const activeTabIndex = tabIndex[match.params.section];
					return (
						<div id="appWrapper">
							<div className="app-tabs-line">
								<div className="app-tabs-wrapper">
									<Tabs mobile tabId="appTabs" activeTabIndex={activeTabIndex} onTabChange={() => { }}>
										{tabs}
									</Tabs>
								</div>
							</div>
							{filters}
							<div id="appContent">
								<Switch>
									<Route path="/article" component={Article} />
									<Route path="/focus" component={Focus} />
									<Route path="/newsletter" component={Newsletter} />
									<Route path="/topic" component={Topic} />
									<Route path="/report" component={Report} />
									<Route path="/audit" component={Audit} />
								</Switch>
							</div>
						</div>
					);
				}} />
				<Route path="/" render={() => (<Redirect to="/article" />)} />
			</Switch>
			<Facebook />
			<Notifications />
		</div >
	);
};

export default withT(App);
