import { Action, actionCreatorFactory } from 'typescript-fsa';
import { ApiSearchDocument, DocumentObject } from 'class/Document';

const actionCreator = actionCreatorFactory('@@search/results');

type Payloads = {
	BulkTag: { tagIds: string[], newTagNames: string[] };
	BulkTagSuccess: void;
	BulkTagError: { error: object };

	ResetSearchResultsData: void;

	FetchSearch: void;
	FetchSearchSuccess: { documents: ApiSearchDocument[], total: number };
	FetchSearchError: void;

	FetchDocuments: void;
	FetchDocumentsSuccess: { documents: DocumentObject[] };
	FetchDocumentsError: { error: object };

	SetDocuments: { documentSources: DocumentObject[] };

	RemoveDocument: { id: string };
	RemoveDocumentSuccess: { id: string };
	RemoveDocumentError: { error: object, id: string };

	ToggleDocumentChecked: { id: string };
	ToggleAllDocumentsChecked: { check: boolean };
	ToggleTagActionPanel: void;

	RemoveDocumentBulk: void;
	RemoveDocumentBulkSuccess: void;
	RemoveDocumentBulkError: { error: object };

	SetTags: { tagIds: string[], newTagNames: string[] };
	SetTagsSuccess: { ids: string[], topicTag: string[] };
	SetTagsError: { error: object };

	AutomateTags: { tagIds: string[], newTagNames: string[] };
	AutomateTagsError: { error: object };

	RemoveDocumentCategory: { id: string, category: string };
	RemoveDocumentCategorySuccess: { id: string };
	RemoveDocumentCategoryError: { error: object };

	RemoveDocumentTag: { id: string, tagId: string };
	RemoveDocumentTagSuccess: { id: string, tagId: string };
	RemoveDocumentTagError: { error: object };

	ToggleAutomateTags: boolean;
}

export type Actions = {

	BulkTag: Action<Payloads["BulkTag"]>;
	BulkTagSuccess: Action<Payloads["BulkTagSuccess"]>;
	BulkTagError: Action<Payloads["BulkTagError"]>;

	ResetSearchResultsData: Action<Payloads["ResetSearchResultsData"]>;

	FetchSearch: Action<Payloads["FetchSearch"]>;
	FetchSearchSuccess: Action<Payloads["FetchSearchSuccess"]>;
	FetchSearchError: Action<Payloads["FetchSearchError"]>;

	FetchDocuments: Action<Payloads["FetchDocuments"]>;
	FetchDocumentsSuccess: Action<Payloads["FetchDocumentsSuccess"]>;
	FetchDocumentsError: Action<Payloads["FetchDocumentsError"]>;

	SetDocuments: Action<Payloads["SetDocuments"]>;

	RemoveDocument: Action<Payloads["RemoveDocument"]>;
	RemoveDocumentSuccess: Action<Payloads["RemoveDocumentSuccess"]>;
	RemoveDocumentError: Action<Payloads["RemoveDocumentError"]>;

	ToggleDocumentChecked: Action<Payloads["ToggleDocumentChecked"]>;
	ToggleAllDocumentsChecked: Action<Payloads["ToggleAllDocumentsChecked"]>;
	ToggleTagActionPanel: Action<Payloads["ToggleTagActionPanel"]>;

	RemoveDocumentBulk: Action<Payloads["RemoveDocumentBulk"]>;
	RemoveDocumentBulkSuccess: Action<Payloads["RemoveDocumentBulkSuccess"]>;
	RemoveDocumentBulkError: Action<Payloads["RemoveDocumentBulkError"]>;

	SetTags: Action<Payloads["SetTags"]>;
	SetTagsSuccess: Action<Payloads["SetTagsSuccess"]>;
	SetTagsError: Action<Payloads["SetTagsError"]>;

	AutomateTags: Action<Payloads["AutomateTags"]>;
	AutomateTagsError: Action<Payloads["AutomateTagsError"]>;

	RemoveDocumentCategory: Action<Payloads["RemoveDocumentCategory"]>;
	RemoveDocumentCategorySuccess: Action<Payloads["RemoveDocumentCategorySuccess"]>;
	RemoveDocumentCategoryError: Action<Payloads["RemoveDocumentCategoryError"]>;

	RemoveDocumentTag: Action<Payloads["RemoveDocumentTag"]>;
	RemoveDocumentTagSuccess: Action<Payloads["RemoveDocumentTagSuccess"]>;
	RemoveDocumentTagError: Action<Payloads["RemoveDocumentTagError"]>;

	ToggleAutomateTags: Action<Payloads["ToggleAutomateTags"]>;
}

export const operators = {
	bulkTag: actionCreator<Payloads["BulkTag"]>('BULK_TAG', { api: 'start' }),
	bulkTagSuccess: actionCreator<Payloads["BulkTagSuccess"]>('BULK_TAG_SUCCESS', { api: 'success' }),
	bulkTagError: actionCreator<Payloads["BulkTagError"]>('BULK_TAG_ERROR', { api: 'error' }),

	resetSearchResultsData: actionCreator<Payloads["ResetSearchResultsData"]>('RESET_SEARCH_RESULTS_DATA', { api: 'start' }),

	fetchSearch: actionCreator<Payloads["FetchSearch"]>('FETCH_SEARCH', { api: 'start' }),
	fetchSearchSuccess: actionCreator<Payloads["FetchSearchSuccess"]>('FETCH_SEARCH_SUCCESS', { api: 'success' }),
	fetchSearchError: actionCreator<Payloads["FetchSearchError"]>('FETCH_SEARCH_ERROR'),

	fetchDocuments: actionCreator<Payloads["FetchDocuments"]>('FETCH_DOCUMENTS', { api: 'start' }),
	fetchDocumentsSuccess: actionCreator<Payloads["FetchDocumentsSuccess"]>('FETCH_DOCUMENTS_SUCCESS', { api: 'success' }),
	fetchDocumentsError: actionCreator<Payloads["FetchDocumentsError"]>('FETCH_DOCUMENTS_ERROR', { api: 'error' }),

	setDocuments: actionCreator<Payloads["SetDocuments"]>('SET_DOCUMENTS'),

	removeDocument: actionCreator<Payloads["RemoveDocument"]>('REMOVE_DOCUMENT', { api: 'start' }),
	removeDocumentSuccess: actionCreator<Payloads["RemoveDocumentSuccess"]>('REMOVE_DOCUMENT_SUCCESS', { api: 'success' }),
	removeDocumentError: actionCreator<Payloads["RemoveDocumentError"]>('REMOVE_DOCUMENT_ERROR', { api: 'error' }),

	toggleDocumentChecked: actionCreator<Payloads["ToggleDocumentChecked"]>('TOGGLE_DOCUMENT_CHECKED'),
	toggleAllDocumentsChecked: actionCreator<Payloads["ToggleAllDocumentsChecked"]>('TOGGLE_ALL_DOCUMENTS_CHECKED'),
	toggleTagActionPanel: actionCreator<Payloads["ToggleTagActionPanel"]>('TOGGLE_TAG_ACTION_PANEL'),

	removeDocumentBulk: actionCreator<Payloads["RemoveDocumentBulk"]>('REMOVE_DOCUMENT_BULK', { api: 'start' }),
	removeDocumentBulkSuccess: actionCreator<Payloads["RemoveDocumentBulkSuccess"]>('REMOVE_DOCUMENT_BULK_SUCCESS', { api: 'success' }),
	removeDocumentBulkError: actionCreator<Payloads["RemoveDocumentBulkError"]>('REMOVE_DOCUMENT_BULK_ERROR', { api: 'error' }),

	setTags: actionCreator<Payloads["SetTags"]>('SET_TAGS_CATEGORY', { api: 'start' }),
	setTagsSuccess: actionCreator<Payloads["SetTagsSuccess"]>('SET_TAGS_CATEGORY_SUCCESS', { api: 'success' }),
	setTagsError: actionCreator<Payloads["SetTagsError"]>('SET_TAGS_CATEGORY_ERROR', { api: 'error' }),

	automateTags: actionCreator<Payloads["AutomateTags"]>('AUTOMATE_TAGS', { api: 'start' }),
	automateTagsError: actionCreator<Payloads["AutomateTagsError"]>('AUTOMATE_TAGS_ERROR', { api: 'error' }),

	removeDocumentCategory: actionCreator<Payloads["RemoveDocumentCategory"]>('REMOVE_DOCUMENT_CATEGORY', { api: 'start' }),
	removeDocumentCategorySuccess: actionCreator<Payloads["RemoveDocumentCategorySuccess"]>('REMOVE_DOCUMENT_CATEGORY_SUCCESS', { api: 'success' }),
	removeDocumentCategoryError: actionCreator<Payloads["RemoveDocumentCategoryError"]>('REMOVE_DOCUMENT_CATEGORY_ERROR', { api: 'error' }),

	removeDocumentTag: actionCreator<Payloads["RemoveDocumentTag"]>('REMOVE_DOCUMENT_TAG', { api: 'start' }),
	removeDocumentTagSuccess: actionCreator<Payloads["RemoveDocumentTagSuccess"]>('REMOVE_DOCUMENT_TAG_SUCCESS', { api: 'success' }),
	removeDocumentTagError: actionCreator<Payloads["RemoveDocumentTagError"]>('REMOVE_DOCUMENT_TAG_ERROR', { api: 'error' }),

	toggleAutomateTags: actionCreator<Payloads["ToggleAutomateTags"]>('TOGGLE_AUTOMATE_TAGS', { api: 'start' })
};
