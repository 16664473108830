import { connect } from 'react-redux';

import { State } from 'store/types';
import { getBrandName } from 'store/entities/Brand/selectors';
import DocumentItemValuation from './DocumentItemValuation';
import { StateProps } from './types';

const mapStateToProps = (state: State): StateProps => ({
	getBrandName: (id: string) => getBrandName(state, id)
});

export default connect(mapStateToProps)(DocumentItemValuation);
