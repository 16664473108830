import { Model, attr } from "redux-orm";

import { createOrmReducer, ReducerOrm } from "lib/store/reducer";
import { operators } from './actions';
import { BrandFields, BrandModel } from "./types";

export class Brand extends Model<typeof Brand, BrandFields> {
	static get modelName() { return 'Brand' as const; };
	static get idAttribute() { return 'id' as const; };
	static get fields() {
		return {
			id: attr(),
			name: attr(),
			isCrawled: attr()
		};
	};

	static reducer: ReducerOrm<BrandModel> = createOrmReducer<BrandModel>(operators);
}
