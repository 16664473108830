import React from "react";
import { SelectField } from 'lib/imports/react-md';

import { TProps, withT } from "lib/i18n";
import { SendConfiguration } from 'store/entities/Newsletter';
import { newsletterSort } from "store/entities/Newsletter/consts";

import './NewsletterEditFormSort.scss';
import featureFlags from "lib/featureFlags";

type NewsletterSort = SendConfiguration["sort"];

type OwnProps = {
	sort: NewsletterSort,
	onChange: (sort: NewsletterSort) => void
};

type ComponentProps = OwnProps & TProps;

const NewsletterEditFormSort = (props: ComponentProps) => {
	const { sort, t } = props;
	const { onChange } = props;



	const sortSelectInput = React.useMemo(() => {
		let sortOptions = newsletterSort.map(value => ({ value, label: t(`newsletter.form.input.sort.${value}`) }));

		if(featureFlags.isEnabled('no-miv')) {
			sortOptions = sortOptions.filter(option => option.value !== 'miv');
		}

		return (
			<SelectField
				id="newsletterEditSortSelect"
				menuItems={sortOptions}
				onChange={value => onChange(value.toString() as NewsletterSort)}
				value={sort}
				fullWidth
			/>
		);
	}, [t, sort, onChange]);

	return (
		<div id="newsletterEditSectionSort" className="form-section">
			<div className="form-section-title">
				{t('newsletter.form.label.sort')}
			</div>
			<div className="form-section-content">
				<div className="form-section-content-column">
					{sortSelectInput}
				</div>
			</div>
		</div>
	);
};

export default withT(React.memo(NewsletterEditFormSort));
