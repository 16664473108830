import React, { ReactText } from 'react';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import SimpleDialog from 'components/common/dialog/simple';
import { checkName } from 'lib/validate';
import { FocusObject } from 'class/Focus';
import TextField from 'react-md/lib/TextFields/TextField';
import { TextFieldComponent } from 'react-md/lib/TextFields/TextField';
import GA from 'lib/googleAnalytics';
import { FeedObject } from 'class/Feed';

export type DialogAction = "createFocus" | "renameFocus" | "removeFocus" | "removeFeed";
type DialogInfo = {
	id: string,
	text: string
	content: JSX.Element
};

type ComponentState = {
	dialogInputError: string | null,
	dialogInputValue: string
};

class FocusListDialog extends React.Component<ComponentProps, ComponentState> {

	private createFocusTextFieldRef: React.RefObject<any> = React.createRef();
	private renameFocusTextFieldRef: React.RefObject<any> = React.createRef();

	constructor(props: ComponentProps) {
		super(props);
		this.state = { dialogInputError: null, dialogInputValue: "" };
	}

	private onDialogAccepted = () => {
		const { dialogInputValue } = this.state;

		let error: string | null = null;
		switch (this.props.action) {
			case "createFocus":
				error = checkName(dialogInputValue);
				if (!error) {
					this.props.onCreateFocus(dialogInputValue);
					GA.trackEvent({ category: 'Focuses list', action: 'Create new focus', label: 'Ok' });
				}
				break;
			case "renameFocus":
				error = checkName(dialogInputValue);
				if (!error) {
					this.props.onRenameFocus(this.props.focus as FocusObject, dialogInputValue);
					GA.trackEvent({ category: 'Focuses list', action: 'Focus actions', label: 'Rename focus' });
				}
				break;
			case "removeFocus":
				this.props.onRemoveFocus(this.props.focus as FocusObject);
				break;
			case "removeFeed":
				this.props.onRemoveFeed(this.props.focus as FocusObject, this.props.feed as FeedObject);
				break;
		}
		if (error) return this.setState({ dialogInputError: error });
		this.setState({ dialogInputValue: "" });
		this.props.onClose();
	};

	private onDialogCancelled = () => {
		switch (this.props.action) {
			case "createFocus":
				GA.trackEvent({ category: 'Focuses list', action: 'Create new focus', label: 'Cancel' });
				break;
		}
		this.props.onClose();
	};

	public componentDidMount() {
		if (this.props.action === "createFocus") (this.createFocusTextFieldRef.current as TextFieldComponent).focus();
		else if (this.props.action === "renameFocus") {
			this.setState({ dialogInputValue: this.props.focus!.name });
			(this.renameFocusTextFieldRef.current as TextFieldComponent).focus();
		}
	}

	public render() {
		const { t, action, focus } = this.props;
		const { dialogInputValue } = this.state;

		const dialogTextFieldProps = {
			label: t('name'),
			onChange: (value: ReactText) => this.setState({ dialogInputValue: value.toString() }),
			error: !!this.state.dialogInputError,
			errorText: this.state.dialogInputError ? t(`error.focus_${this.state.dialogInputError}`) : null,
			defaultValue: dialogInputValue
		};

		const dialogInfo: { [index: string]: DialogInfo } = {
			createFocus: {
				id: 'focusListCreateFocusSimpleDialog',
				text: t('focus.prompt_new_focus'),
				content: <TextField
					id="focusListCreateFocusInput"
					ref={this.createFocusTextFieldRef}
					{...dialogTextFieldProps}
				/>
			},
			renameFocus: {
				id: 'focusListRenameFocusSimpleDialog',
				text: t('focus.rename.title'),
				content: <TextField
					id="focusListRenameFocusInput"
					ref={this.renameFocusTextFieldRef}
					{...dialogTextFieldProps}
				/>
			},
			removeFocus: {
				id: 'focusListRemoveFocusSimpleDialog',
				text: t('focus.remove.confirm', { focus_name: focus ? focus.name : "" }),
				content: <div>{t('focus.remove.notice')}</div>
			},
			removeFeed: {
				id: 'focusListRemoveFeedSimpleDialog',
				text: t('focus.confirm_remove.feed'),
				content: <div></div>
			}
		};

		const info: DialogInfo = dialogInfo[action as DialogAction];

		return <SimpleDialog
			id={info.id}
			text={info.text}
			onAccept={this.onDialogAccepted}
			onCancel={this.onDialogCancelled}
			content={info.content}
			acceptColor={action === "removeFeed" || action === "removeFocus" ? "red" : "green"}
		/>;
	}

}

export default withT(FocusListDialog);
