import { combineReducers } from 'redux';

import * as manager from './manager';
import * as details from './details';

export const reducers = combineReducers({
	manager: manager.reducers,
	details: details.reducers
});

export interface State {
	manager: manager.State;
	details: details.State
}

export const INITIAL_STATE = {
	manager: manager.INITIAL_STATE,
	details: details.INITIAL_STATE
};
