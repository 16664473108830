import ActionCreators from 'lib/store/actions';

import { FacebookDialogMode } from 'store/app/facebook/types';
import { FacebookPermission } from 'store/entities/FacebookUser';

const actions = new ActionCreators('@@app/facebook');

const [updateStatus, updateStatusSuccess, updateStatusError] = actions.apiOperators<fb.StatusResponse | null>('UPDATE_STATUS');
const [updateAuth, updateAuthSuccess, updateAuthError] = actions.apiOperators<fb.AuthResponse | void>('UPDATE_AUTH');
const [deauthorize, deauthorizeSuccess, deauthorizeError] =	actions.apiOperators('DEAUTHORIZE');

const setDialogMode = actions.operator<FacebookDialogMode>('SET_DIALOG_MODE');
const setSkipped = actions.operator<boolean>('SET_SKIPPED');
const skip = actions.operator('SKIP');

type SetPermissionsMissingPayload = {mode: 'check' | 'auth' , permissionsMissing: FacebookPermission[]};
const setPermissionsMissing = actions.operator<SetPermissionsMissingPayload>('SET_PERMISSIONS_MISSING');

export type Actions = {
	UpdateStatus: ReturnType<typeof updateStatus>;
	UpdateAuth: ReturnType<typeof updateAuth>;
	SetDialogMode: ReturnType<typeof setDialogMode>;
	SetPermissionsMissing: ReturnType<typeof setPermissionsMissing>;
	SetSkipped: ReturnType<typeof setSkipped>;
}

export const operators = {
	updateStatus, updateStatusSuccess, updateStatusError,
	updateAuth, updateAuthSuccess, updateAuthError,
	deauthorize, deauthorizeSuccess, deauthorizeError,
	setDialogMode,
	setPermissionsMissing,
	setSkipped,
	skip
};
