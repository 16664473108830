import React from 'react';
import { Button, Collapse, FontIcon, SelectionControl } from 'lib/imports/react-md';
import { take, includes, cloneDeep, pullAt, orderBy, findIndex, find, isEmpty } from 'lib/imports/lodash';

import GA from 'lib/googleAnalytics';
import { withT } from 'lib/i18n';
import { isCurrentPage } from 'lib/pathCheck';
import { FilterFacet } from 'class/Filter';
import { FacetItem, Facet } from 'class/Facet';
import { ComponentProps } from './types';

import './SearchFacetsGroup.scss';

class SearchFacetsGroup extends React.Component<ComponentProps> {

	private groupLangTxt = "";

	private toggleFacetFilter = (facetItem: FacetItem) => {
		const { t, groupKey, onToggleFacetFilter } = this.props;

		const trackAction = `Filter by ${t(`filters.${this.groupLangTxt}.value`, { lng: "en" })}`;
		const trackLabel = this._getItemNameText(facetItem, "en");
		GA.trackEvent({ category: "Feed results", action: trackAction, label: trackLabel });
		onToggleFacetFilter(groupKey, { key: facetItem.key, name: facetItem.name, detail: facetItem.detail });
	};

	private _addFilteredItemsToGroupItems = (): FacetItem[] => {
		const { groupKey, facetFilters } = this.props;

		const groupItems = cloneDeep(this.props.groupItems);
		let itemsFiltered: FacetItem[] = [];

		if (!facetFilters[groupKey]) return groupItems;
		for (const facetFilter of facetFilters[groupKey]) {
			const facetIndex = findIndex(groupItems, { key: facetFilter.key });
			if (facetIndex !== -1) {
				itemsFiltered.push(groupItems[facetIndex]);
				pullAt(groupItems, facetIndex);
			} else itemsFiltered.push({ key: facetFilter.key, type: facetFilter.type });
		}

		itemsFiltered = orderBy(itemsFiltered, [item => item.counter || ''], ['desc']);
		return [...itemsFiltered, ...groupItems];
	};

	private _getItemNameText = (item: FacetItem, lang?: string): string => {
		const { t, groupKey, facetFilters, getCategoryName, getEventName } = this.props;

		const langOptions: { lng?: string } = {};
		if (lang) langOptions.lng = lang;
		if (groupKey === Facet.mediaGroupKey) return item.name || (find(facetFilters[groupKey], facetFilter => facetFilter.key === item.key) as FilterFacet).name!;
		else if (groupKey === Facet.categoriesKey) return getCategoryName(item.key) || "";
		else if (groupKey === Facet.eventKey) return getEventName(item.key) || "";
		else return t(`filters.${this.groupLangTxt}.${item.key.replace(' ', '_')}`, langOptions);
	};

	private _cleanText = (text: string): string => {
		const { groupKey } = this.props;

		if (groupKey === Facet.mediaGroupKey) {
			text = text.replace(/http:\/\/|https:\/\//, "");
		}

		return text;
	};

	private _getFacetGroupTooltip = () => {
		const { t, location, groupKey, feedType } = this.props;

		if (!isCurrentPage(location.pathname, "preview")) return null;
		if (!feedType || feedType !== "socialmedia") return null;

		let tooltipText = null;
		if (groupKey === Facet.languageGroupKeyVB) tooltipText = t('filters.help.socialmedia.language');
		else if (groupKey === Facet.countryGroupKey) tooltipText = t('filters.help.socialmedia.country');

		if (tooltipText) return <Button className="search-facets-group-help-tooltip" icon tooltipLabel={tooltipText} tooltipPosition="bottom">help_outline</Button>;

		return null;
	};

	private _displayHiddenFacets = (ev: React.MouseEvent) => {
		const { onToggleHiddenFacets } = this.props;
		if (ev.shiftKey) onToggleHiddenFacets();
	};

	public render() {
		const { t, location, groupKey, facetFilters, facetsGroupsOpened, loadingFacets, showHiddenFacets } = this.props;
		const { onToggleFacetGroupOpened, onRemoveFacetFilterGroup, onSetShowMoreDialogFacetGroupKey } = this.props;

		// TODO (GIS-2327): Remove language replace once we have all with the same field and modify replace(/_(.*)) to take into account that language_id will be language.id always
		if (facetFilters['language_id']) {
			facetFilters['language.id'] = facetFilters['language_id'];
			delete facetFilters['language_id'];
		}

		const noReplace = groupKey === Facet.placementType || groupKey === Facet.coverageType || groupKey === Facet.categoriesGroupKeyVB || groupKey === Facet.categoriesKey;

		if (noReplace) {
			this.groupLangTxt = groupKey;
		} else {
			this.groupLangTxt = groupKey.replace(/language\.id/, 'language_id').replace(/_(.*)/gi, '');
			this.groupLangTxt = this.groupLangTxt.replace(/tenant./gi, '');
		}

		const groupHasFilterEnabled = facetFilters[groupKey] !== undefined;

		const groupItems = this._addFilteredItemsToGroupItems();
		const isGroupEnabled = isCurrentPage(location.pathname, "preview") || !isEmpty(groupItems);
		const isGroupOpened = isGroupEnabled && includes(facetsGroupsOpened, groupKey);
		let hiddenFacet = false;

		if (this.groupLangTxt.match('photo.')) {
			switch (this.groupLangTxt) {
				case Facet.countryPathKeyVB:
					this.groupLangTxt = "country";
					break;
				case Facet.photoTypeKey:
					this.groupLangTxt = "photo_type";
					hiddenFacet = true;
					break;
				case Facet.printMediaLevelKey:
					this.groupLangTxt = "media_value";
					hiddenFacet = true;
					break;
				case Facet.frequencyKeyVB:
					this.groupLangTxt = "media_frequency";
					hiddenFacet = true;
					break;
			}
		} else {
			switch (this.groupLangTxt) {
				case Facet.countryPathKey:
					this.groupLangTxt = "country";
					break;
				case Facet.frequencyKey:
					this.groupLangTxt = "media_frequency";
					hiddenFacet = true;
					break;
				case Facet.languageGroupKey:
					this.groupLangTxt = "language";
					break;
				case Facet.placementType:
					this.groupLangTxt = "article_type";
					hiddenFacet = true;
					break;
				case Facet.categoriesGroupKeyVB:
					this.groupLangTxt = "categories";
					break;
				case Facet.categoriesKey:
					this.groupLangTxt = "new_categories";
					break;
				case Facet.coverageType:
					this.groupLangTxt = "coverage_type";
					hiddenFacet = true;
					break;
			}
		}

		if (showHiddenFacets) hiddenFacet = false;

		let items = null;
		items = (
			<Collapse collapsed={!isGroupOpened}>
				<div className="search-facets-group-items">
					{take(groupItems, 10).map(item => {
						const itemSelected = !isEmpty(facetFilters[groupKey]) && !!find(facetFilters[groupKey], facetFilter => facetFilter.key === item.key);
						const itemName = this._cleanText(this._getItemNameText(item));
						return (
							<div className="search-facets-group-item" key={item.key}>
								<SelectionControl
									id={`searchFacetsGroupItemCheckbox-${groupKey}-${item.key}`}
									name={`searchFacetsGroupItemCheckboxs-${groupKey}[]`}
									label=""
									type="checkbox"
									checked={itemSelected}
									className={itemSelected ? "checkbox-green" : ""}
									onChange={() => this.toggleFacetFilter(item)}
								/>
								<div id={`searchFacetsGroupItemName-${groupKey}-${item.key}`} className="search-facets-group-item-name" onClick={() => this.toggleFacetFilter(item)}>
									{(groupKey === Facet.mediaGroupKey) ? <FontIcon className={`search-facets-group-item-icon`} iconClassName={`icon-${item.type}`}></FontIcon> : null}
									<span className={Facet.isCustomFacet(groupKey, item) ? "custom-facet" : undefined}>{itemName}</span>
									{item.counter && !loadingFacets ? <span> - <span className="counter">{item.counter}</span></span> : null}
								</div>
							</div>
						);
					})}
					<div id={`searchFacetsGroupSetShowMore-${groupKey}`} className="search-facets-group-item" onClick={() => {
						const trackLabel = `${t(`filters.${this.groupLangTxt}.value`)} MoreOptions`;
						GA.trackEvent({ category: "Feed results", action: 'Filter by', label: trackLabel });
						onSetShowMoreDialogFacetGroupKey(groupKey);
					}}>
						<FontIcon>add</FontIcon>
						{t('filters.show_more')}
					</div>
				</div>
			</Collapse>
		);
		const groupFilterText = t(`filters.${this.groupLangTxt}.value`);
		return (
			<div className={`search-facets-group ${isGroupEnabled ? "" : "disabled"} ${hiddenFacet ? "hidden" : ""}`} id={`searchFacetsGroup-${groupKey}`}>
				<div
					className={`search-facets-group-header ${groupHasFilterEnabled ? "enabled" : ""}`}
					onClick={ev => { if (isGroupEnabled) onToggleFacetGroupOpened(groupKey); this._displayHiddenFacets(ev); }}
				>
					{isGroupOpened ? <FontIcon>keyboard_arrow_down</FontIcon> : <FontIcon>keyboard_arrow_right</FontIcon>}
					<span>{groupFilterText}</span>
					{this._getFacetGroupTooltip()}
					{groupHasFilterEnabled ?
						<div
							className="search-facets-group-remove-filters"
							onClick={ev => {
								ev.stopPropagation();
								onRemoveFacetFilterGroup(groupKey);
							}}
							title={t('filters.reset.value', { filter: groupFilterText.toLowerCase() })}
						><FontIcon>close</FontIcon></div>
						: null}
				</div>
				{items}
			</div>
		);
	}
}

export default withT(SearchFacetsGroup);
