import React, { useCallback, useMemo } from 'react';
import { withT } from "lib/i18n";

import { FilterFacet } from 'class/Filter';
import { Facet, FacetItem } from 'class/Facet';
import DropdownFilter from "components/common/DropdownFilter/DropdownFilter";
import { getIsoFromPath } from 'components/Search/Facets/Dialog/countryHelper';

import { ComponentProps } from './types';

const Location = (props: ComponentProps) => {
	const { excluded, facetFilters, display, isOpen } = props;
	const { t, onSetExcludeField, onSetFacetGroupFilters, onToggleOpen } = props;

	const facetItems = React.useMemo(() => {
		const countriesCodes = Facet.countryFacetValues;
		const countryByContinent = countriesCodes
			.filter(countryPath => countryPath.length === 2)
			.map(continentPath => ({
				key: continentPath,
				name: t(`filters.country.${getIsoFromPath(continentPath)}`),
				children: countriesCodes
					.filter(countryPath => countryPath.length === 4 && countryPath.indexOf(continentPath) === 0)
					.map(countryPath => ({
						key: countryPath,
						name: t(`filters.country.${getIsoFromPath(countryPath)}`)
					}))
			}));
		return countryByContinent;
	}, [t]);

	const locationFilters = useMemo(() => (facetFilters && facetFilters[Facet.countryGroupKey]) || [], [facetFilters]);

	const handleChange = useCallback((items: FacetItem[]) => {
		onSetFacetGroupFilters(items as FilterFacet[]);
	}, [onSetFacetGroupFilters]);

	const handleExclude = useCallback((set: boolean) => onSetExcludeField(set), [onSetExcludeField]);

	const dropdown = useMemo(() =>
		<DropdownFilter
			id='locationFilter'
			key='filters-location-dropdown'
			title={t('filters.country.value')}
			excluded={excluded}
			filters={facetItems}
			initSelectedFilters={locationFilters as FacetItem[]}
			defaultFilter={{ name: t('reports.all_male_sing'), key: 'all' }}
			disable={false}
			isOpen={isOpen}
			onToggleOpen={onToggleOpen}
			onChange={handleChange}
			onExclude={handleExclude}
		/>

	, [t, excluded, facetItems, locationFilters, isOpen, onToggleOpen, handleChange, handleExclude]);
	return display ? dropdown : null;
};

export default withT(Location);
