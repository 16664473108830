import { ReducerHandlers, createReducer } from 'lib/store/reducer';

import { Actions, operators } from './actions';

export type State = {
	emailListId: string | null
};

export const INITIAL_STATE: State = {
	emailListId: null
};

export const reducerHandlers: ReducerHandlers<State> = {
	setEditList: (state, { payload: { id } }: Actions["SetEditList"]): State => ({ ...state, emailListId: id }),
	resetEditList: state => ({ ...state, emailListId: null })
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
