import { ReducerHandlers } from 'lib/store/reducer';
import { Actions } from './actions';
import { Tree, TreeUtils, TreeNode } from 'class/Tree';
import { FacetItem } from 'class/Facet';

import { State as FacetsState } from '../reducers';

export type ShowMoreFacetItem = {
	value: string,
	text: string,
	detail?: string,
	counter: number,
	type?: string
};

export type FacetItemHash = {
	[item_key: string]: {
		collapsed: boolean,
		selected: boolean,
		node: TreeNode<ShowMoreFacetItem>
	}
};

export type State = {
	readonly facetItemsHash: FacetItemHash,
	readonly facetItems: Tree<ShowMoreFacetItem>,
	readonly seeOnlySelected: boolean,
	readonly filterText: string,
	readonly queriedMedias: FacetItem[]
};

export const INITIAL_STATE: State = {
	facetItemsHash: {},
	facetItems: TreeUtils.createTree([]),
	seeOnlySelected: false,
	filterText: "",
	queriedMedias: []
};

export const reducerHandlers: ReducerHandlers<FacetsState> = {
	setFacetItemsHash: (state, { payload: { facetItemsHash } }: Actions["SetFacetItemsHash"]): FacetsState => ({
		...state,
		extended: {
			...state.extended,
			facetItemsHash
		}
	}),
	setSeeOnlySelected: (state, { payload: { seeOnlySelected } }: Actions["SetSeeOnlySelected"]): FacetsState => ({
		...state,
		extended: {
			...state.extended,
			seeOnlySelected
		}
	}),
	setAll: (state, { payload: { facetItemsHash, facetItems, seeOnlySelected } }: Actions["SetAll"]): FacetsState => ({
		...state,
		extended: {
			...state.extended,
			facetItemsHash,
			facetItems,
			seeOnlySelected
		}
	}),
	setFilterText: (state, { payload: { filterText } }: Actions["SetFilterText"]): FacetsState => ({
		...state,
		extended: {
			...state.extended,
			filterText
		}
	}),
	setQueriedMedias: (state, { payload: { medias } }: Actions["SetQueriedMedias"]): FacetsState => ({
		...state,
		extended: {
			...state.extended,
			queriedMedias: medias
		}
	})
};
