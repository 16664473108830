import { Model, attr } from "redux-orm";

import { createOrmReducer, ReducerOrm } from "lib/store/reducer";
import { operators } from './actions';
import { EventFields, EventModel } from "./types";

export class Event extends Model<typeof Event, EventFields> {
	static get modelName() { return 'Event' as const; };
	static get idAttribute() { return 'id' as const; };
	static get fields() {
		return {
			id: attr(),
			name: attr()
		};
	};

	static reducer: ReducerOrm<EventModel> = createOrmReducer<EventModel>(operators);
}
