import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/search/facets';
import { operators as filterOperators } from 'store/search/filters';
import SearchFacetsFeedsGroup from './SearchFacetsFeedsGroup';
import { DispatchProps, StateProps } from './types';

const mapStateToProps = ({ focus, search: { facets, filters } }: State): StateProps => ({
	focusList: focus.list.focusList,
	focusFiltered: filters.focus,
	feedsFiltered: filters.feeds,
	facetsGroupsOpened: facets.facetsGroupsOpened
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onToggleFocusFiltered: focusId => dispatch(filterOperators.toggleFilteredFocus({ focusId })),
	onToggleFeedFiltered: (focusId, feedId) => dispatch(filterOperators.toggleFilteredFeed({ focusId, feedId })),
	onToggleFacetGroupOpened: groupKey => dispatch(operators.toggleFacetsGroupOpened({ groupKey })),
	onRemoveFocusFeedFilter: () => dispatch(filterOperators.removeFocusFeedFilter())
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchFacetsFeedsGroup);
