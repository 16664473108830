import { all, put, takeLatest, call, select, delay, fork, take, cancel, cancelled } from 'redux-saga/effects';
import { map, isEmpty, filter } from 'lib/imports/lodash';

import Mekong from 'lib/ajax/Mekong';
import reportSelectors from 'store/ui/report/selectors';
import { operators as reportOperators } from 'store/ui/report/actions';

import { ReportJob } from './types';
import selectors from './selectors';
import { operators } from './actions';

export const JOB_STATUS_POOL_DELAY_MS = 3 * 1000;
export const JOBS_LIMIT = 50;

export default function* sagas() {
	yield all([
		takeLatest(operators.fetchFocusJobs, fetchFocusJobs),
		takeLatest(operators.clearJobs, clearJobs),
		takeLatest(operators.startJobStatusPool, startJobStatusPool),
		takeLatest(reportOperators.refresh, refreshJobs)
	]);
}

function* fetchFocusJobs() {
	yield put(operators.stopJobStatusPool());

	const focusId = reportSelectors.getFocusId(yield select());
	try {
		const response: ReportJob[] = yield call(Mekong.get, '/report/search', { params: { focusId, limit: JOBS_LIMIT } });
		yield put(operators.fetchFocusJobsSuccess(response));
		yield put(operators.startJobStatusPool());
	} catch (err) {
		yield put(operators.fetchFocusJobsError(err));
	}
}

function* clearJobs() {
	yield put(operators.stopJobStatusPool());
}

function* startJobStatusPool() {
	const poolTask = yield fork(__jobStatusPool);
	yield take(operators.stopJobStatusPool);
	yield cancel(poolTask);
}

function* refreshJobs() {
	yield put(operators.fetchFocusJobs());
}

function* __jobStatusPool() {
	let currentJobs = selectors.getJobs(yield select());
	try {
		while (true) {
			yield delay(JOB_STATUS_POOL_DELAY_MS);

			const currentActiveJobs = filter(currentJobs, job => (job.status !== 'complete' && job.status !== 'failed'));
			if (isEmpty(currentActiveJobs)) return;

			const ids = map(currentActiveJobs, 'id').join(',');

			yield put(operators.fetchJobStatus());
			currentJobs = yield call(Mekong.get, '/report/status', { params: { ids } });
			yield put(operators.fetchJobStatusSuccess(currentJobs));
		}
	} catch (err) {
		yield put(operators.fetchJobStatusError(err));
	} finally {
		if (yield cancelled()) {
			yield put(operators.fetchJobStatusError({ code: 'SAGA_CANCELLED', message: 'status request pool stopped' }));
		}
	}
}
