import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import TopicSelectors from 'store/entities/Topic/selectors';
import TopicTag from './TopicTag';

const mapStateToProps = (state: State): StateProps => ({
	availableTopicTags: TopicSelectors.getWithTags(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TopicTag);
