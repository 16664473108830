import React from 'react';

import { Button, FontIcon, Collapse } from 'lib/imports/react-md';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import { FeedCampaign } from 'class/Feed';
import featureFlags from 'lib/featureFlags';
import { reject } from 'lodash';
import FocusFeedDefinitionLayoutCampaignExpression from './CampaignExpression/FocusFeedDefinitionLayoutCampaignExpression';

const FocusFeedDefinitionLayoutStories = (props: ComponentProps) => {
	const { feedCampaigns, brand_sync, t } = props;
	const { onSetCampaign, expressionFunctions } = props;

	const [collapse, setCollapse] = React.useState<boolean>(false);

	const addCampaign = React.useCallback(() => {
		onSetCampaign([
			...feedCampaigns,
			{ campaign_id: '', enabled: true }
		]);
	}, [onSetCampaign, feedCampaigns]);

	const removeCampaign = React.useCallback((index: number) => {
		onSetCampaign(reject(feedCampaigns, (campaign, position) => index === position) as FeedCampaign[]);
	}, [onSetCampaign, feedCampaigns]);

	const followersSection = React.useMemo(() => {
		const isCollapsed = collapse || brand_sync;
		return featureFlags.isEnabled('instagram_stories') ? (
			<div id="focusFeedDefinitionSocialStoriesSection" className={`definition-section ${brand_sync ? 'definition-section-disabled' : ''}`}>
				<div className="definition-section-header">
					<div className="definition-header-title" onClick={() => setCollapse(!collapse)}>
						{
							isCollapsed ?
								<FontIcon className="definition-header-title-toggle" >keyboard_arrow_right</FontIcon> :
								<FontIcon className="definition-header-title-toggle">keyboard_arrow_down</FontIcon>
						}
						<span className="definition-header-title-text">{t('feed.form.instagram_stories.label')}</span>
					</div>
				</div>
				<Collapse collapsed={isCollapsed}>
					<div className={`definition-section-content ${brand_sync && 'disabled'}`}>
						<div>
							{feedCampaigns.map((campaign, index) =>
								<FocusFeedDefinitionLayoutCampaignExpression
									key={index}
									index={index}
									campaign={campaign}
									onRemoveCampaignExpression={removeCampaign}
									{...expressionFunctions}
								/>
							)}
							<div className="definition-add-campaign">
								<Button
									id="focusFeedDefinitionSocialAddCampaignBtn"
									className="definition-add-campaign-button"
									flat
									onClick={() => addCampaign()}
								>
									<span>+</span> {t('feed.form.instagram_stories.campaign.add')}
								</Button>
							</div>
						</div>
					</div>
				</Collapse>
			</div>
		) : null;
	}, [brand_sync, collapse, feedCampaigns, expressionFunctions, addCampaign, removeCampaign, t]);

	return <>
		{followersSection}
	</>;
};

export default withT(React.memo(FocusFeedDefinitionLayoutStories));
