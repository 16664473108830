import { put, select, call, all, take, takeLeading } from 'redux-saga/effects';
import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { matchPath } from "react-router";
import * as FS from '@fullstory/browser';

import GA from 'lib/googleAnalytics';
import Api from 'lib/ajax/Api';
import Mekong from 'lib/ajax/Mekong';
import { callCatch } from 'lib/store/sagas';
import { transform, map, omit, filter, isEmpty } from 'lib/imports/lodash';
import { facebookStorage } from 'lib/storage';
import { Session } from 'types/session';
import { OldestDocumentObject } from 'class/Document';
import { FeedAttributes } from 'store/entities/Feed';
import { State } from 'store/types';
import { JWTStorage } from 'lib/storage';

import { operators as focusOperators, FocusAttributes } from 'store/entities/Focus';
import { operators as NewsletterOperators, NewsletterData, NewsletterAttributes } from 'store/entities/Newsletter';
import { operators as EmailListOperators, EmailListData } from 'store/entities/EmailList';
import { operators as TopicOperators, TopicData } from 'store/entities/Topic';
import { operators as UrlQueryOperators } from 'store/ui/urlQuery/actions';
import { operators as CategoryOperators } from 'store/entities/Category';
import { operators as BrandOperators } from 'store/entities/Brand';
import { operators as EventOperators } from 'store/entities/Event';
import { operators as sessionOperators } from 'store/app/session';
import { operators as facebookOperators } from 'store/app/facebook';
import { operators as auditFilterOperators } from 'store/ui/audit/filters';
import { operators as LanguageOperators, LanguageData } from 'store/entities/Language';
import { operators as CountryOperators, CountryData } from 'store/entities/Country';

import { operators } from './actions';
import selectors from './selectors';

export default function* sagas() {
	yield takeLeading(LOCATION_CHANGE, locationChange);
}

function* locationChange({ payload }: LocationChangeAction) {
	if (matchPath(payload.location.pathname, '/public')) return;
	try {
		if (payload.isFirstRendering) yield fetchAuth();
		yield __fetchEntities(payload.location.pathname);
	} catch (err) {
		console.error(err);
	}
}

function* __fetchEntities(path: string) {

	if (matchPath(path, '/newsletter')) {
		yield all([
			__fetchFocus(),
			__fetchBrands(),
			__fetchNewsletters(),
			__fetchTopics()
		]);
	}

	if (matchPath(path, '/article')) {
		yield all([
			__fetchTopics(),
			__fetchLanguages(),
			__fetchCountries()
		]);
	}

	if (matchPath(path, '/topic')) {
		yield all([
			__fetchTopics(),
			__fetchFocus(),
			__fetchLanguages(),
			__fetchCountries(),
			__fetchCategories(),
			__fetchEvents()
		]);
	}

	if (matchPath(path, '/article') || matchPath(path, '/report') || matchPath(path, '/focus')) {
		yield all([
			__fetchBrands(),
			__fetchFocus(),
			__fetchCategories(),
			__fetchEvents()
		]);
	}

	if (matchPath(path, '/audit')) {
		yield all([
			__resetAuditFilters(),
			__fetchBrands()
		]);
	}

	if (matchPath(path, '/report')) {
		yield all([
			__fetchFocus(),
			__fetchTopics()
		]);
	}
}

function* fetchAuth() {
	const state: State = yield select();
	if (!selectors.needsFetch(state, 'auth')) return;
	yield put(operators.fetchAuthStart());

	try {
		const start = GA.startTimer();
		const session: Session = yield call(Mekong.getSession);
		GA.endTimer(start, 'auth', 'load');
		yield call(__setSession, session);
		yield put(operators.fetchAuthSuccess());
		JWTStorage.setToken(session.jwt);
	} catch (err) {
		throw Error(err);
	}
}

export function* __setSession(session: Session) {
	Api.setAuthData(session.api);
	FS.identify(session.profile.user.email, {
		displayName: session.profile.user.name,
		email: session.profile.user.email,
		tenant: session.profile.tenant.name,
		ecosystemId: session.profile.user.ecosystem_id,
		Site: session.profile.tenant.name,
		SiteID: session.profile.tenant.guid,
		EcoAccountId: session.profile.tenant.ecosystem_account_id,
		EcoAccountName: session.profile.tenant.ecosystem_account_name,
		SalesforceId: session.profile.tenant.salesforce_account_id,
		sfparentid: session.profile.tenant.salesforce_account_parent_id
	});

	const userGuiding = (window as any).userGuiding;
	function identifyUser(retryCount: number = 0): any {
		if (retryCount > 5) return console.error('UserGuiding is not available in __setSession');
		if (!userGuiding || !userGuiding.identify) return setTimeout(() => identifyUser(retryCount + 1), 1000);
		try {
			userGuiding.identify(session.profile.user.email, {
				displayName: session.profile.user.name,
				email: session.profile.user.email,
				tenant: session.profile.tenant.name,
				ecosystemId: session.profile.user.ecosystem_id,
				Site: session.profile.tenant.name,
				SiteID: session.profile.tenant.guid,
				EcoAccountId: session.profile.tenant.ecosystem_account_id,
				EcoAccountName: session.profile.tenant.ecosystem_account_name,
				SalesforceId: session.profile.tenant.salesforce_account_id,
				sfparentid: session.profile.tenant.salesforce_account_parent_id
			});
		} catch (error) {
			console.error('Error setting userGuiding', error);
		}
	}
	identifyUser();

	yield put(facebookOperators.setSkipped(facebookStorage.getSkipped()));
	yield put(sessionOperators.updateSession(session));
	yield take(sessionOperators.sessionReady); // waits for all session actions to be dispatched
}

interface FeedAPiResponse extends FeedAttributes {
	inserted_at: string
	updated_at: string
	deleted_at: string
	focus_id: string
	oldest_document?: OldestDocumentObject;
}
interface FocusApiResponse extends FocusAttributes {
	inserted_at: string;
	updated_at: string;
	deleted_at: string;
	entities: { feed: FeedAPiResponse[], search: any };
	oldest_document?: OldestDocumentObject;
}

function* __fetchFocus() {
	const state: State = yield select();
	if (!selectors.needsFetch(state, 'focus')) return;

	const api = new Api();
	try {
		yield put(operators.fetchFocusStart());

		const start = GA.startTimer();
		const focusResponse: FocusApiResponse[] = yield call([api, 'get'], '/definition/focus', { params: { entities: 1 } });
		GA.endTimer(start, 'focus_list', 'load');
		const parsedFocusData = map(focusResponse, focus => {
			const { entities, ...focusProps } = omit(focus, ['updated_at', 'deleted_at']);
			const feeds = map(entities.feed, feed => {
				const feedProps = omit(feed, ['inserted_at', 'updated_at', 'deleted_at', 'oldest_document', 'focus_id']);
				return { ...feedProps, focus: feed.focus_id };
			});
			return { ...focusProps, feeds };
		});

		yield put(focusOperators.parse(parsedFocusData));
		yield put(operators.fetchFocusSuccess());
	} catch (error) {
		yield put(operators.fetchFocusError(error));
	}
}

type NewsletterApiResponse = NewsletterAttributes & { lists: string[], feeds: string[], tags: string[] };
type EmailListApiResponse = { id: string, name: string, emails: string[] };

function* __fetchNewsletters() {
	const state: State = yield select();
	if (!selectors.needsFetch(state, 'newsletters')) return;

	const api = new Api();
	try {
		yield put(operators.fetchNewslettersStart());

		const start = GA.startTimer();
		const { newslettersResponse, emailListsResponse } = yield all({
			newslettersResponse: call([api, 'get'], '/newsletter'),
			emailListsResponse: call([api, 'get'], '/emailList')
		});
		GA.endTimer(start, 'newsletter_list', 'load');

		const { parsedEmailLists, parsedNewsletters } = __parseNewsletterEmailListResponses(newslettersResponse, emailListsResponse);

		yield put(EmailListOperators.create(parsedEmailLists));
		yield put(NewsletterOperators.create(parsedNewsletters));

		yield put(operators.fetchNewslettersSuccess());
	} catch (error) {
		yield put(operators.fetchNewslettersError(error));
	}
}

function* __fetchCategories() {
	try {
		const state: State = yield select();
		if (!selectors.needsFetch(state, 'dmrCategories')) return;

		yield put(operators.fetchDmrCategoriesStart());
		const { categories } = yield call([Mekong, 'get'], "/v1/facet/categories");
		const modelCategories = Object.keys(categories).map(key => ({id: key, name: categories[key]}));
		yield put(CategoryOperators.create(modelCategories));
		yield put(operators.fetchDmrCategoriesSuccess());
	} catch (error) {
		console.error('Error: ', JSON.stringify(error));
		yield put(operators.fetchDmrCategoriesError(error));
	}
}

function* __fetchEvents() {
	try {
		const state: State = yield select();
		if (!selectors.needsFetch(state, 'events')) return;

		yield put(operators.fetchEventsStart());
		const { events } = yield call([Mekong, 'get'], "/v1/facet/events");
		const modelEvents = Object.keys(events).map(key => ({id: key, name: events[key]}));
		yield put(EventOperators.create(modelEvents));
		yield put(operators.fetchEventsSuccess());
	} catch (error) {
		console.error('Error: ', JSON.stringify(error));
		yield put(operators.fetchEventsError(error));
	}
}

function* __fetchBrands() {
	try {
		const state: State = yield select();
		if (!selectors.needsFetch(state, 'brands')) return;

		yield put(operators.fetchBrandsStart());
		const { brands } = yield call([Mekong, 'get'], "/v1/brand");
		yield put(BrandOperators.create(brands));
		yield put(operators.fetchBrandsSuccess());
	} catch (error) {
		console.error('Error: ', JSON.stringify(error));
		yield put(operators.fetchBrandsError(error));
	}
}

function __parseNewsletterEmailListResponses(newsletterResponse: NewsletterApiResponse[], emailListResponse: EmailListApiResponse[]) {
	const parsedEmailLists: EmailListData[] = map(emailListResponse, list => ({
		id: list.id,
		name: list.name,
		emails: transform(list.emails, (parsedList: EmailListData['emails'], email) => parsedList[email] = true, {})
	}));

	const parsedNewsletters: NewsletterData[] = map(newsletterResponse, ({ lists, ...newsletter }) => ({
		...newsletter,
		emailLists: lists
	}));

	return { parsedEmailLists, parsedNewsletters };
}

function* __fetchTopics() {
	const state: State = yield select();
	if (!selectors.needsFetch(state, 'topics')) return;

	yield put(operators.fetchTopicsStart());

	const start = GA.startTimer();
	const topics: TopicData[] = yield callCatch([Mekong.get, '/v1/topic', { params: { include_tags: true } }], operators.fetchTopicsError);

	GA.endTimer(start, 'topic_tag_list', 'load');
	if (!topics) {
		yield put(UrlQueryOperators.setSuggestedTags({suggested_tags: null}));
		return;
	}
	yield put(TopicOperators.parse(topics));
	const stateSuggestedTags: string[] = yield select((state: State) => state.ui.urlQuery.suggested_tags);
	if (stateSuggestedTags) {
		const mappedTopicsTags: any = {};
		topics.forEach(topic => {
			mappedTopicsTags[topic.id] = map(topic.tags, tag => tag.id);
		});

		const filteredSuggestedTags: string[] = filter(map(stateSuggestedTags, suggestedTag => {
			const tagData = suggestedTag.split('_');
			if (!mappedTopicsTags[tagData[0]] || !mappedTopicsTags[tagData[0]].includes(tagData[1])) return '';
			return suggestedTag;
		}));

		if (!isEmpty(filteredSuggestedTags)) {
			yield put(UrlQueryOperators.setSuggestedTags({suggested_tags: filteredSuggestedTags}));
		} else {
			yield put(UrlQueryOperators.setSuggestedTags({suggested_tags: null}));
		}
	}

	yield put(operators.fetchTopicsSuccess());
}

function* __fetchLanguages() {
	try {
		const state: State = yield select();
		if (!selectors.needsFetch(state, 'languages')) return;

		yield put(operators.fetchLanguagesStart());
		const languages: LanguageData[] = yield call([Mekong, 'get'], "/v1/language");
		const modelLanguages = languages.map(language => ({id: language.id, name: language.name, iso_code: language.iso_code}));
		yield put(LanguageOperators.create(modelLanguages));
		yield put(operators.fetchLanguagesSuccess());
	} catch (error) {
		console.error('Error: ', JSON.stringify(error));
		yield put(operators.fetchLanguagesError(error));
	}
}

function* __fetchCountries() {
	try {
		const state: State = yield select();
		if (!selectors.needsFetch(state, 'countries')) return;

		yield put(operators.fetchCountriesStart());
		const countries: CountryData[] = yield call([Mekong, 'get'], "/v1/country");
		const modelCountries = countries.map(country => ({id: country.id, name: country.name, iso_code: country.iso_code}));
		yield put(CountryOperators.create(modelCountries));
		yield put(operators.fetchCountriesSuccess());
	} catch (error) {
		console.error('Error: ', JSON.stringify(error));
		yield put(operators.fetchCountriesError(error));
	}
}

function* __resetAuditFilters() {
	yield put(auditFilterOperators.filterReset());
}
