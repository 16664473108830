import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import { operators } from 'store/article/insert/form/online';
import { operators as articleInsertOperators } from 'store/article/insert';
import { operators as notificationsOperators } from 'store/app/notifications';
import ArticleInsertFormOnline from './ArticleInsertFormOnline';
import profileSelectors from 'store/app/profile/selectors';

const mapStateToProps = (state: State): StateProps => ({
	user: profileSelectors.getUser(state)!,
	tenant: state.app.profile.tenant!,
	url: state.article.insert.url,
	finalUrl: state.article.insert.finalUrl,
	availableMedias: state.article.insert.form.online.availableMedias,
	fetchedMedia: state.article.insert.form.online.fetchedMedia,
	loading: state.article.insert.loading,
	documentExists: state.article.insert.documentExists
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onFetchMedias: media_url => dispatch(operators.fetchMedias({ media_url })),
	onFetchMediaInfo: media_id => dispatch(operators.fetchMediaInfo({ media_id })),
	onAddNotification: notification => dispatch(notificationsOperators.add({ notification })),
	onSelectUrl: url => dispatch(articleInsertOperators.setSelectedUrl({ url })),
	onChangeStep: step => dispatch(articleInsertOperators.changeStep({ step }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleInsertFormOnline);
