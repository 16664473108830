import React from 'react';
import { SelectField } from 'lib/imports/react-md';
import { REPORT_PERIODS } from 'store/ui/report/consts';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';

const ReportSetupSettingsPeriod: React.FC<ComponentProps> = props => {
	const { t, reportType, reportPeriod } = props;
	const { onChangePeriod } = props;

	const selectItems = React.useMemo(() => REPORT_PERIODS.map(period =>
		({ label: t(`reports.${period}`), value: period })
	), [t]);

	if (reportType !== 'periodic') return null;
	return (
		<div id="reportSetupSettingsPeriod" className="form-section" >
			<div className="form-section-title">
				{t('reports.report_type')}
			</div>
			<div className="form-section-content">
				<SelectField
					id="reportSetupSettingsPeriodSelect"
					label={t("reports.ppt_export_template")}
					menuItems={selectItems}
					value={reportPeriod}
					position="br"
					onChange={(value: any) => onChangePeriod(value)}
				/>
			</div>
		</div>
	);
};

export default withT(React.memo(ReportSetupSettingsPeriod));
