import React from 'react';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import SelectionControl from 'react-md/lib/SelectionControls/SelectionControl';
import find from 'lodash/find';
import includes from 'lodash/includes';
import { withT } from 'lib/i18n';

import { ComponentProps } from './types';
import { FocusObject } from 'class/Focus';
import Dialog from 'components/common/dialog';

import './ManageUsers.scss';

type ComponentState = {
	dialogInputError: string | null
};

class FocusListManageUsers extends React.Component<ComponentProps, ComponentState> {

	private getUsersList = (focus: FocusObject) => {
		const { t, tenantUsers, loadingUsersIds, onToggleAllowedUser } = this.props;
		return (
			<div className="manage-users-list">
				{tenantUsers!.map(user => {
					const isAllowed = find(focus.acl_users, { user_id: user.id });
					const isLoading = includes(loadingUsersIds, user.id);

					return (
						<div key={user.id}>
							<div className="manage-users-list-logo"><FontIcon>account_circle</FontIcon></div>
							<div className="manage-users-list-info">
								<span className="manage-users-list-info-name">{`${user.first_name} ${user.last_name}`}</span>
								<span className="manage-users-list-info-email">{user.email}</span>
							</div>
							<div className="manage-users-list-toggle">
								{
									isLoading ?
										<CircularProgress id={`focusListManageUsersToggleUser${user.id}Loading`}></CircularProgress> :
										<SelectionControl
											id={`focusListManageUsersToggleUser${user.id}Switch`}
											type="switch"
											label={isAllowed ? t('focus.user_allowed') : t('focus.user_not_allowed')}
											name={`focusListManageUsersToggleUser${user.id}Switch`}
											checked={!!isAllowed}
											onChange={checked => onToggleAllowedUser(focus.id, user, checked as boolean)}
										/>
								}
							</div>
						</div>
					);
				})}
			</div>
		);
	};

	public componentDidMount() {
		if (!this.props.tenantUsers) this.props.onFecthTenantUsers();
	}

	public render() {
		const { t, onClose, tenantUsers, focusId, focusList } = this.props;
		const focus = find(focusList, { id: focusId });

		const content = tenantUsers && focus ? this.getUsersList(focus) : <CircularProgress id="focusListManageUsersLoading" />;

		return <Dialog
			id="focusListManageFocusDialog"
			title={t('focus.manage_focus_title', { focus_name: focus!.name })}
			content={content}
			onAccept={onClose}
			onAcceptText={t('focus.manage_focus_users_ok')}
		/>;
	}

}

export default withT(FocusListManageUsers);
