import React from 'react';
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { Button, TextField, FontIcon } from 'lib/imports/react-md';
import { find, isNull, some } from 'lib/imports/lodash';

import { withT } from 'lib/i18n';
import { useUpdateEffect } from 'lib/hooks';
import { TopicObject } from 'store/entities/Topic';
import { Link } from 'react-router-dom';
import SimpleDialog from 'components/common/dialog/simple';
import Badge from 'components/common/Badge/Badge';

import { ComponentProps } from './types';

import './TopicManager.scss';

const namesAreEqual = (name: string, name2: string) => name.trim().toLowerCase() === name2.trim().toLowerCase();

const TopicManager = (props: ComponentProps) => {
	const { t, topics, id, isCreatingTopic } = props;
	const { onCreateTopic, onRenameTopic, onDeleteTopic, push } = props;

	const [createTopicName, setCreateTopicName] = React.useState<string | null>(null);
	const [editTopic, setEditTopic] = React.useState<TopicObject | null>(null);
	const [deleteTopic, setDeleteTopic] = React.useState<TopicObject | null>(null);
	const [inputError, setInputError] = React.useState<string | false>();

	const wrapperRef = React.useRef<HTMLDivElement>(null);

	const focusTopicInput = React.useCallback((id?: string) => {
		const selector = '#topicManager' + (id ? 'EditTopic' + id : 'NewTopicInput');
		(wrapperRef.current!.querySelector(selector) as HTMLInputElement).focus();
	}, []);

	React.useEffect(() => {
		if (id && !some(topics, { id })) {
			push("/topic");
		}
	}, [id, push, topics]);

	React.useEffect(() => {
		if (editTopic) focusTopicInput(editTopic.id);
	}, [editTopic, focusTopicInput]);

	React.useEffect(() => {
		if (!isNull(createTopicName)) focusTopicInput();
	}, [createTopicName, focusTopicInput]);

	useUpdateEffect(() => {
		if (!isCreatingTopic) setCreateTopicName("");
	}, [isCreatingTopic]);

	const checkTopicName = React.useCallback((name: string) => {
		if (!name) {
			setInputError("topics.name_too_short");
			return false;
		}
		const topic = find(topics, topic => namesAreEqual(topic.name, name));
		if (topic) {
			setInputError("topics.name_already_exists");
			return false;
		}
		if (name.length > 30) {
			setInputError("topics.name_too_long");
			return false;
		}
		return true;
	}, [topics, setInputError]);

	const onShowAddTopic = React.useCallback(() => {
		if (isNull(createTopicName)) {
			setInputError(false);
			setEditTopic(null);
			setCreateTopicName("");
		}
	}, [createTopicName]);

	const onShowEditTopic = React.useCallback((topic: TopicObject) => {
		setInputError(false);
		setCreateTopicName(null);
		setEditTopic(topic);
	}, []);

	const onTopicAdded = React.useCallback((value: string) => {
		const topicName = value.trim();
		if (!checkTopicName(topicName)) return focusTopicInput();
		onCreateTopic(topicName);
		setInputError(false);
	}, [onCreateTopic, checkTopicName, focusTopicInput]);

	const onTopicRenamed = React.useCallback((topic: TopicObject, value: string) => {
		const editedName = value.trim();
		if (namesAreEqual(topic.name, editedName)) return setEditTopic(null);
		if (!checkTopicName(editedName)) return focusTopicInput(topic.id);
		onRenameTopic(topic.id, topic.name, editedName);
		setInputError(false);
		setEditTopic(null);
	}, [onRenameTopic, checkTopicName, focusTopicInput]);

	const getTopicContent = React.useCallback((topic: TopicObject<"tags">) => {
		let classes = "list-item";
		if (topic.id === id) classes += " selected";
		if (editTopic && editTopic.id === topic.id) classes += " editing";

		const topicText = `${topic.is_general ? t('topics.general') : topic.name} (${topic.tags.length})`;
		const isReadOnly = topic.type !== 'general' && topic.read_only;
		const readOnlyBadge = <Badge label={t('filters.read_only')} type='info' />;
		const topicButtons = !topic.is_general && !topic.read_only ? <>
			<Button id={`topicManagerTopicRename${topic.id}`} icon onClick={() => onShowEditTopic(topic)}>edit</Button>
			<Button id={`topicManagerTopicDelete${topic.id}`} icon onClick={() => setDeleteTopic(topic)}>delete</Button>
		</> : null;
		const topicItemContent =
			editTopic && editTopic.id === topic.id ?
				<form onSubmit={(ev: any) => { ev.preventDefault(); onTopicRenamed(editTopic, ev.currentTarget.elements[0].value); }}>
					<TextField
						id={`topicManagerEditTopic${topic.id}`}
						fullWidth
						defaultValue={topic.name}
						onBlur={(ev: any) => {
							const value = ev.currentTarget.value.trim();
							if (!value) return setEditTopic(null);
							onTopicRenamed(editTopic, value);
						}}
						error={!!inputError}
						errorText={inputError ? t(inputError) : ""}
						placeholder={t('topics.edit_topic_placeholder')}
					/>
				</form>
				: <>
					<Link to={`/topic/${topic.id}`}>
						<div className="list-item-text">{topicText}</div>
					</Link>
					{isReadOnly && readOnlyBadge}
					{topicButtons}
				</>;
		return (
			<div className="droppable-container" key={`topicTagManagerDroppableContainer-${topic.id}`}>
				<Droppable droppableId={topic.id} key={`droppable-${topic.id}`} isDropDisabled={isReadOnly}>
					{(provided: DroppableProvided, _: DroppableStateSnapshot) => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							{provided.placeholder}
							<div key={topic.id} id={`topicManagerTopic${topic.id}`} className={classes}>
								{topicItemContent}
							</div>
						</div>
					)}
				</Droppable>
			</div>
		);
	}, [t, id, editTopic, onTopicRenamed, onShowEditTopic, inputError]);

	const newTopicInput = React.useMemo(() => {
		if (isNull(createTopicName)) return null;

		return (
			<div id="topicManagerNewTopic" className="list-item item-add">
				<form onSubmit={(ev: any) => { ev.preventDefault(); onTopicAdded(ev.currentTarget.elements[0].value); }}>
					<TextField
						id="topicManagerNewTopicInput"
						disabled={isCreatingTopic}
						fullWidth
						onBlur={(ev: any) => {
							const value = ev.currentTarget.value.trim();
							if (!value) return setCreateTopicName(null);
							onTopicAdded(value);
						}}
						onChange={val => setCreateTopicName(val.toString())}
						value={createTopicName}
						error={!!inputError}
						errorText={inputError ? t(inputError) : ""}
						placeholder={t('topics.new_topic_placeholder')}
					/>
				</form>
			</div>
		);
	}, [t, createTopicName, onTopicAdded, inputError, isCreatingTopic]);

	const topicsList = React.useMemo(() => (
		<div id="topicManagerTopicList" className="topic-list">
			{topics.map(topic => getTopicContent(topic))}
			{newTopicInput}
		</div>
	), [topics, getTopicContent, newTopicInput]);

	const deleteDialog = React.useMemo(() => {
		if (!deleteTopic) return null;

		const deleteDialogContent = (
			<div className="remove-dialog-content">
				<FontIcon>delete</FontIcon>
				<div className="remove-dialog-content-title">{t('topics.dialog_confirm_title')}</div>
				<p dangerouslySetInnerHTML={{ __html: t('topics.dialog_confirm_text', { topicName: deleteTopic.name }) }}></p>
			</div>
		);
		return <SimpleDialog
			id="topicManagerDeleteDialog"
			text=""
			content={deleteDialogContent}
			onCancel={() => setDeleteTopic(null)}
			onAccept={() => {
				onDeleteTopic(deleteTopic.id);
				setDeleteTopic(null);
				push("/topic");
			}}
			acceptText={t('topics.dialog_remove_btn')}
			acceptColor="red"
		/>;
	}, [deleteTopic, t, onDeleteTopic, push]);

	return (
		<div id="topicManager" ref={wrapperRef} >
			<div className="topic-title">{t('topics.title')}</div>
			<Button id="topicManagerAddTopic" className="add-new-topic-btn" flat onClick={onShowAddTopic}><span>+</span>{t('topics.add_topic')}</Button>
			{topicsList}
			{deleteDialog}
		</div>
	);
};

export default withT(React.memo(TopicManager));
