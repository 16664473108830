import { State } from "store/types";
import { StateProps } from "./types";
import { operators } from "store/ui/audit/filters";
import { Dispatch } from "redux";
import { DispatchProps } from "./types";
import { connect } from "react-redux";
import AuditSearch from "./AuditSearch";

const mapStateToProps = (state: State): StateProps => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onFilterChanged: searchTerm => dispatch(operators.filterChanged({searchTerm}))
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditSearch);
