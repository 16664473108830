import React from 'react';

import { Blanket } from './Blanket';
import { DropdownMenu } from './DropdownMenu';
import { ComponentProps } from './types';

import './Dropdown.scss';

export const Dropdown = ({ children, isOpen, target, onClose, id }: ComponentProps) => {
	return (
		<div>
			{target}
			{isOpen ? <DropdownMenu id={id}>{children}</DropdownMenu> : null}
			{isOpen ? <Blanket onClick={() => onClose()} /> : null}
		</div>
	);
};