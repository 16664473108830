import { all, put, takeLatest } from "redux-saga/effects";
import {operators} from './actions';
import { operators as auditListOperators } from "store/ui/audit/list";
import { operators as auditPageOperators } from "store/ui/audit/paging";

export default function* sagas() {
	yield all([
		takeLatest(operators.filterChanged, filterChanged),
		takeLatest(operators.eventsChanged.type, filterChanged)
	]);
}

function* filterChanged() {
	yield put(auditPageOperators.auditResetPage());
	yield put(auditListOperators.fetchAudit());
}