import { createSelector } from 'reselect';
import { ormEntities, sessionSelector, createOrmSelector, OrmSelector } from "store/entities/selectors";
import { EmailListObject } from "./types";

export const get: OrmSelector<EmailListObject> = createOrmSelector(ormEntities.EmailList);

export const getByName = createSelector(sessionSelector, (_: any, name: string) => name,
	({ EmailList }, name: string) => {
		return EmailList.filter(list => list.name.toLowerCase() === name.toLowerCase()).toRefArray();
	}
);

export default {
	get,
	getByName
};
