import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { State } from 'store/types';

import { DispatchProps, StateProps } from './types';
import { operators as focusFeedOperators } from 'store/focus/feed';

import FocusFeedDefinitionLayoutStories from './FocusFeedDefinitionLayoutStories';
import { FeedCampaign } from 'class/Feed';

const mapStateToProps = ({ focus: { feed: { feed_campaign, brand_sync } } }: State): StateProps => ({
	feedCampaigns: feed_campaign,
	brand_sync
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetCampaign: (campaigns: FeedCampaign[]) => dispatch(focusFeedOperators.setCampaigns({ campaigns })),
	expressionFunctions: {
		onSetCampaignExpression: (index, campaign) => dispatch(focusFeedOperators.setCampaignsExpression({ index, campaign }))
	}
});


export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedDefinitionLayoutStories);
