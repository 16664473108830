import ActionCreators from 'lib/store/actions';

const actions = new ActionCreators('@@topic/tag/manager');

const [createTag, createTagSuccess, createTagError] = actions.apiOperators<{ name: string, topicId: string }>('CREATE_TAG');
const [renameTag, renameTagSuccess, renameTagError] = actions.apiOperators<{ id: string, oldName: string, name: string }>('RENAME_TAG', { reload: true });
const [deleteTag, deleteTagSuccess, deleteTagError] = actions.apiOperators<{ id: string }>('DELETE_TAG', { reload: true });
const [migrateTag, migrateTagSuccess, migrateTagError] = actions.apiOperators<{ tagId: string, newTopicId: string }, void, { error?: string }>('MIGRATE_TAG');

export type Actions = {
	CreateTag: ReturnType<typeof createTag>;
	CreateTagSuccess: ReturnType<typeof createTagSuccess>;
	CreateTagError: ReturnType<typeof createTagError>;
	RenameTag: ReturnType<typeof renameTag>;
	RenameTagSuccess: ReturnType<typeof renameTagSuccess>;
	RenameTagError: ReturnType<typeof renameTagError>;
	DeleteTag: ReturnType<typeof deleteTag>;
	DeleteTagSuccess: ReturnType<typeof deleteTagSuccess>;
	DeleteTagError: ReturnType<typeof deleteTagError>;
	MigrateTag: ReturnType<typeof migrateTag>;
	MigrateTagSuccess: ReturnType<typeof migrateTagSuccess>;
	MigrateTagError: ReturnType<typeof migrateTagError>;
};

export const operators = {
	createTag,
	createTagSuccess,
	createTagError,
	renameTag,
	renameTagSuccess,
	renameTagError,
	deleteTag,
	deleteTagSuccess,
	deleteTagError,
	migrateTag,
	migrateTagSuccess,
	migrateTagError
};
