import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";

import React, { useEffect, useMemo } from "react";
import moment from "moment";
import { withT } from "lib/i18n";
import { keys } from "lib/imports/lodash";
import { useManagedState } from "lib/hooks";
import { Dropdown } from "components/common/Dropdown";
import TargetButton from "./TargetButton/TargetButton";
import { ComponentProps, ComponentState, Ranges } from "./types";
import { createStaticRanges, DateRangePicker } from "react-date-range";
import { SelectField } from "react-md";

import "./DateFilter.scss";
import { SearchDateType } from "types/search/form";

const DateFilter = ({
	dateType,
	fullDisplay,
	t,
	ranges,
	dateFormat,
	presetOptions,
	defaultRangeKey,
	maxAvailableDate,
	firstAvailableDate,
	isCompareModeActive,
	isOpen,
	editableDateInputs = false,
	onDatesChange,
	onToggleOpen,
	onSetDateTypeFilter
}: ComponentProps) => {
	const [state, setManagedState] = useManagedState<ComponentState>({
		rangesObject: ranges,
		initialRangesValue: ranges,
		endDate: moment(ranges[defaultRangeKey].endDate),
		startDate: moment(ranges[defaultRangeKey].startDate),
		type: "publication_date"
	});

	useEffect(() => {
		setManagedState({
			rangesObject: ranges,
			endDate: moment(ranges[defaultRangeKey].endDate),
			startDate: moment(ranges[defaultRangeKey].startDate)
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ranges, defaultRangeKey]);

	const selectMenuItems = useMemo(
		() => [
			{
				label: t("filters.date_type.publication_date"),
				value: "publication_date",
				key: "PublicationDateButton"
			},
			{
				label: t("filters.date_type.integration_date"),
				value: "indexed_at",
				key: "IntegrationDateButton"
			}
		],
		[t]
	);

	const toggleOpen = onToggleOpen !== undefined ? onToggleOpen : () => { };

	const closeMenu = (shouldWeResetData: boolean = false) => {
		if (shouldWeResetData) {
			resetRangesAndClose();
			return;
		}

		setManagedState({
			initialRangesValue: state.rangesObject,
			type: state.type
		});
		onToggleOpen();
		onDatesChange(state.rangesObject);
		onSetDateTypeFilter(state.type);
	};

	const resetRangesAndClose = () => {
		setManagedState({
			endDate: moment(
				state.initialRangesValue[defaultRangeKey as keyof Ranges]
					.endDate
			),
			startDate: moment(
				state.initialRangesValue[defaultRangeKey as keyof Ranges]
					.startDate
			),
			rangesObject: {
				...state.initialRangesValue
			},
			type: state.type
		});
		onToggleOpen();
	};

	// handle when we change the dates directly in the input or with presetted current date
	const handleChange = (ranges: Ranges) => {
		const rangeKey = keys(ranges)[0];

		setManagedState({
			endDate: moment(ranges[rangeKey as keyof Ranges].endDate),
			startDate: moment(ranges[rangeKey as keyof Ranges].startDate),
			rangesObject: {
				...state.rangesObject,
				[`${rangeKey}`]: {
					...ranges[rangeKey as keyof Ranges]
				}
			}
		});
	};

	const handleChangeDateType = (type: SearchDateType) => {
		setManagedState({ ...state, type });
	};

	return (
		<div className="form-group" id="appsDatePickerFilterComponent">
			<Dropdown
				isOpen={isOpen}
				onClose={() => closeMenu()}
				target={
					<TargetButton
						isOpen={isOpen}
						toggleOpen={toggleOpen}
						endDate={state.endDate.toDate()}
						startDate={state.startDate.toDate()}
					/>
				}
			>
				<DateRangePicker
					className={`my-date-range-picker ${isOpen ? "is-open" : ""
					} ${isCompareModeActive
						? "compare-mode-active"
						: "compare-mode-inactive"
					}`}
					months={2}
					maxDate={moment(maxAvailableDate).toDate()}
					minDate={moment(firstAvailableDate).toDate()} // extract from feed old date?
					onChange={(item: any) => handleChange(item)}
					moveRangeOnFirstSelection={false}
					ranges={Object.values(state.rangesObject)}
					rangeColors={["#00c795", "#C4C4C4"]}
					direction="horizontal"
					staticRanges={createStaticRanges(presetOptions)}
					headerContent={
						<div className="date-filter-preset-dates-header">
							{t("date-filter-presets-header")}
						</div>
					}
					inputRanges={[]}
					editableDateInputs={editableDateInputs}
					showMonthAndYearPickers={false}
					dateDisplayFormat={dateFormat}
					showSelectionPreview={true}
				/>

				<div className="date-filter-footer">
					<div className="date-filter-buttons-container">
						{fullDisplay ? (
							<div className="date-filter-footer-date-type">
								<SelectField
									id="dateTypeSelect"
									className="date-type-select"
									label={t("filters.date_type.intro")}
									repositionOnResize
									menuItems={selectMenuItems}
									defaultValue={dateType}
									value={dateType}
									onChange={value => {
										handleChangeDateType(
											value.toString() as SearchDateType
										);
									}}
								/>
							</div>
						) : (
							<></>
						)}
						<button
							className="buttons-filter clear-button"
							type="button"
							onClick={() => closeMenu(true)}
						>
							{t("date-filter-button-clear")}
						</button>
						<button
							className="buttons-filter done-button"
							type="button"
							onClick={() => closeMenu()}
						>
							{t("date-filter-button-done")}
						</button>
					</div>
					<div className="empty-grey-space"></div>
				</div>
			</Dropdown>
		</div>
	);
};

export default withT(DateFilter);
