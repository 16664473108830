import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import PlacementType from './PlacementType';
import { State } from 'store/types';
import { StateProps, DispatchProps } from './types';

import { operators as horizontalFiltersOperators } from 'store/search/horizontalFilters';
import { operators as filtersOperators } from 'store/search/filters';
import { operators as facetOperators } from 'store/search/facets';
import { FilterFacet } from 'class/Filter';
import { Facet } from 'class/Facet';

const placementTypeGroupKey = Facet.placementType;

const mapStateToProps = (state: State): StateProps => ({
	excluded: state.search.horizontalFilters.excludedFields.includes(placementTypeGroupKey),
	facetFilters: state.search.filters.facetsGroups
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetExcludeField: (set: boolean) => dispatch(horizontalFiltersOperators.setExcludeField({ field: placementTypeGroupKey, set })),
	onSetFacetGroupFilters: (items: FilterFacet[]) => dispatch(filtersOperators.setFacetGroupFilters({
		groupKey: placementTypeGroupKey,
		groupFacetsFiltered: items,
		doSearch: false
	})),
	onFetchPlacementTypeFacetsGroup: () => dispatch(facetOperators.fetchGroupAPIFacets({ groupKey: placementTypeGroupKey }))
});

export default connect(mapStateToProps, mapDispatchToProps)(PlacementType);
