import React from 'react';
import { Collapse, FontIcon, SelectionControl } from 'lib/imports/react-md';
import { includes, some } from 'lib/imports/lodash';

import GA from 'lib/googleAnalytics';
import { withT } from 'lib/i18n';
import { FeedObject } from 'class/Feed';
import { Focus, FocusObject } from 'class/Focus';
import { FocusFeedsGroupKey } from 'types/search/form';
import { ComponentProps } from './types';

// Also uses css from SearchFacetsGroup.scss
import './SearchFacetsFeedsGroup.scss';

class SearchFacetsFeedsGroup extends React.Component<ComponentProps> {

	private toggleFocusFiltered = (focus: FocusObject) => {
		const { t, onToggleFocusFiltered } = this.props;

		GA.trackEvent({ category: "Feed results", action: `Filter by ${t(`filters.source.focus`, { lng: "en" })}` });
		onToggleFocusFiltered(focus.id);
	};

	private toggleFeedFiltered = (focus: FocusObject, feed: FeedObject) => {
		const { t, onToggleFeedFiltered } = this.props;

		GA.trackEvent({ category: "Feed results", action: `Filter by ${t(`filters.source.feed`, { lng: "en" })}` });
		onToggleFeedFiltered(focus.id, feed.id);
	};

	public render() {
		const { t, focusList, focusFiltered, feedsFiltered, facetsGroupsOpened } = this.props;
		const { onToggleFacetGroupOpened, onRemoveFocusFeedFilter } = this.props;

		if (!focusList) return null;

		const isGroupEnabled = focusList.length > 0;
		const isGroupOpened = isGroupEnabled && includes(facetsGroupsOpened, FocusFeedsGroupKey);
		const groupHasFilterEnabled = focusFiltered.length > 0 || feedsFiltered.length > 0;

		const items = [];

		for (const focus of focusList) {
			const focusFeeds = new Focus(focus).getFeeds();
			const isFocusFiltered = includes(focusFiltered, focus.id);
			const focusHasAnyFilteredFeed = some(focusFeeds.map(feed => feed.id), id => includes(feedsFiltered, id));
			items.push(
				<div className="search-facets-group-item" key={`focus-${focus.id}`}>
					<SelectionControl
						id={`searchFacetsFeedsGroupItemCheckbox-focus-${focus.id}`}
						name={`searchFacetsFeedsGroupItemCheckboxs-focus-${focus.id}[]`}
						label=""
						type="checkbox"
						checked={isFocusFiltered}
						className={isFocusFiltered || focusHasAnyFilteredFeed ? "checkbox-green" : ""}
						uncheckedCheckboxIcon={focusHasAnyFilteredFeed ? <FontIcon>indeterminate_check_box</FontIcon> : <FontIcon>check_box_outline_blank</FontIcon>}
						onChange={() => this.toggleFocusFiltered(focus)}
					/>
					<div id={`searchFacetsFeedsGroupItemName-focus-${focus.id}`} className="search-facets-group-item-name" onClick={() => this.toggleFocusFiltered(focus)}>
						{`${t('filters.source.focus')}: ${focus.name}`}
					</div>
				</div>
			);
			for (const feed of focusFeeds) {
				const isFeedFiltered = includes(feedsFiltered, feed.id);
				const feedIcons = { online: "news", socialmedia: "socialmedia", print: "print", print_dmr: "print" };
				items.push(
					<div className="search-facets-group-item feed-item" key={`feed-${feed.id}`}>
						<SelectionControl
							id={`searchFacetsFeedsGroupItemCheckbox-feed-${feed.id}`}
							name={`searchFacetsFeedsGroupItemCheckboxs-feed-${feed.id}[]`}
							label=""
							type="checkbox"
							className={isFocusFiltered || isFeedFiltered ? "checkbox-green" : ""}
							checked={isFocusFiltered || isFeedFiltered}
							onChange={() => this.toggleFeedFiltered(focus, feed)}
						/>
						<FontIcon iconClassName={`icon-${feedIcons[feed.type]}`} />
						<div id={`searchFacetsFeedsGroupItemName-feed-${feed.id}`} className="search-facets-group-item-name" onClick={() => this.toggleFeedFiltered(focus, feed)}>
							{feed.name}
						</div>
					</div>
				);
			}
		}

		return (
			<div className={`search-facets-group ${isGroupEnabled ? "" : "disabled"}`} id={`searchFacetsFeedsGroup-focus`}>
				<div className={`search-facets-group-header ${groupHasFilterEnabled ? "enabled" : ""}`} onClick={() => { if (isGroupEnabled) onToggleFacetGroupOpened(FocusFeedsGroupKey); }}>
					{isGroupOpened ? <FontIcon>keyboard_arrow_down</FontIcon> : <FontIcon>keyboard_arrow_right</FontIcon>}
					<span>{t("filters.source.value")}</span>
					{groupHasFilterEnabled ?
						<div
							className="search-facets-group-remove-filters"
							onClick={ev => {
								ev.stopPropagation();
								onRemoveFocusFeedFilter();
							}}
							title={t('filters.reset.value', { filter: t('filters.source.value').toLowerCase() })}
						><FontIcon>close</FontIcon></div>
						: null}
				</div>
				<Collapse collapsed={!isGroupOpened}>
					<div className="search-facets-group-items">
						{items}
					</div>
				</Collapse>
			</div >
		);
	}
}

export default withT(SearchFacetsFeedsGroup);
