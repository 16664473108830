import React from 'react';

import { withT } from 'lib/i18n';
import { Button, SelectionControl, FontIcon, Collapse } from 'lib/imports/react-md';

import Autocompleter from 'components/common/Input/Autocompleter';
import { BrandApiResponse } from 'types/focus/feed/definition/print/brands';
import { Brand } from 'class/Feed';

import { ComponentProps } from './types';
import { transform, isEmpty } from 'lib/imports/lodash';
import { FetchBrandsQuery } from 'store/focus/feed/definition/print/actions';

type BrandItem = { id: string, names: string[], scope: string, text: string };

const FocusFeedDefinitionPrintSectionBrand = (props: ComponentProps) => {
	const { brands, brandsApiResponse, t } = props;
	const { onFetchBrands, onResetBrands, onAddExpression, onUpdateExpression, onRemoveExpression } = props;

	const [collapse, setCollapse] = React.useState(false);

	const _getFieldNameFromScope = React.useCallback((scope: string) => {
		const fields = scope.split('.');
		return fields[fields.length - 2] as 'brand' | 'company' | 'line';
	}, []);

	const getNamesText = React.useCallback((names: string[]): string => {
		if (names.length === 1) return [...names, `(${t('feed.form.print.all_brands_and_lines')})`].join(' > ');
		if (names.length === 2) return [...names, `(${t('feed.form.print.all_lines')})`].join(' > ');
		return names.join(' > '); // length === 3
	}, [t]);

	// converts the api response object to the item to use in the component
	const _getResponseItem = React.useCallback((response: BrandApiResponse): BrandItem => {
		let id = response.company.id.toString();
		let scope = 'line.brand.company.id';
		const names = [response.company.description];
		if (response.brand) {
			id = response.brand.id.toString();
			scope = 'line.brand.id';
			names.push(response.brand.description);
			if (response.line) {
				id = response.line.id.toString();
				scope = 'line.id';
				names.push(response.line.description);
			}
		}
		return { id, text: getNamesText(names), names, scope };
	}, [getNamesText]);

	const onUpdateItems = React.useCallback((value: string, currentBrand: Brand) => {
		if (value.length < 2) return onResetBrands();

		const excluded = transform(brands, (result: FetchBrandsQuery['excluded'], brand) => {
			if (!brand.id || (currentBrand.scope === brand.scope && currentBrand.id === brand.id)) return;
			const excludeField = _getFieldNameFromScope(brand.scope);
			if (!result[excludeField]) result[excludeField] = [];
			result[excludeField]!.push(brand.id);
		}, {});

		if (isEmpty(excluded)) return onFetchBrands(value);
		onFetchBrands(value, excluded);
	}, [brands, onFetchBrands, onResetBrands, _getFieldNameFromScope]);

	const items: BrandItem[] = React.useMemo(() => brandsApiResponse.map(_getResponseItem), [brandsApiResponse, _getResponseItem]);

	const getExpressionComponent = React.useCallback((index: number, brand: Brand) => (
		<div key={`brand-${index}`}
			className={`print-definition-expression content-expression ${index === 0 ? 'main-expression' : ''}  ${brand.enabled ? 'enabled' : 'disabled'}`}
		>
			<SelectionControl
				id={`focusFeedDefinitionPrintExpressionEnabledBrand-${index}`}
				name={`focusFeedDefinitionPrintExpressionEnabledBrand-${index}`}
				aria-label={`focusFeedDefinitionPrintExpressionEnabledBrand-${index}`}
				className={`definition-expression-enabled ${brand.enabled ? 'checkbox-green' : ''} `}
				type="checkbox"
				checked={brand.enabled}
				onChange={val => onUpdateExpression(index, { ...brand, enabled: val as boolean })}
			/>
			<span className="definition-expression-label">{`${index > 0 ? t('feed.form.or') : ''} ${t('feed.form.label.brands')}:`}</span>
			<Autocompleter id={`brandAutocompleter-${index}`} className="definition-expressions-query"
				items={items}
				defaultText={getNamesText(brand.names)}
				onChange={value => onUpdateItems(value, brand)}
				onSelected={item => {
					const [id, names, scope] = item ? [item.id, item.names, item.scope] : ['', [], ''];
					onUpdateExpression(index, { ...brand, id, names, scope });
				}}
				onBlur={() => onResetBrands()}
				// onFocus={() => onUpdateItems(brand.name)} uncomment to search items on focus
				disabled={!brand.enabled}
			/>
			{index > 0 ? (
				<Button id={`focusFeedPrintBrandRemove-${index}`}
					className="definition-expression-remove-button"
					icon
					onClick={() => onRemoveExpression(brand.id, 'brands', index)}
				>delete</Button>
			) : null}
		</div>
	), [items, onRemoveExpression, onUpdateExpression, onUpdateItems, onResetBrands, getNamesText, t]);

	return (
		<div id="focusFeedDefinitionPrintBrandsSection" className="definition-section">
			<div className="definition-section-header">
				<div className="definition-header-title definition-header-title-brands" onClick={() => setCollapse(!collapse)}>
					{
						collapse ?
							<FontIcon className="definition-header-title-toggle" >keyboard_arrow_right</FontIcon> :
							<FontIcon className="definition-header-title-toggle">keyboard_arrow_down</FontIcon>
					}
					<span className="definition-header-title-text"> {t('feed.form.brands')} </span>
				</div>
			</div>
			<Collapse collapsed={collapse}>
				<div className="definition-section-content">
					<div className="definition-expressions">
						{
							brands.map((brand, index) => getExpressionComponent(index, brand))
						}
						<div className="definition-add-expression">
							<Button
								id="focusFeedDefinitionPrintAddBrandBtn"
								className="definition-add-expression-button"
								flat
								onClick={() => { onAddExpression({ id: '', names: [], scope: '', enabled: true }); }}
							>
								<span>+</span> {t('feed.form.button.add_brands')}
							</Button>
						</div>
					</div>
				</div>
			</Collapse>
		</div>
	);
};

export default withT(React.memo(FocusFeedDefinitionPrintSectionBrand));
