import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { State } from 'store/types';
import { DispatchProps, StateProps, OwnProps } from './types';
import { operators } from 'store/ui/newsletter/edit';
import { operators as notificationOperators } from 'store/app/notifications/actions';
import newsletterSelectors from 'store/ui/newsletter/edit/selectors';
import NewsletterSelectors from 'store/entities/Newsletter/selectors';
import profileSelectors from 'store/app/profile/selectors';
import FocusSelectors from 'store/entities/Focus/selectors';

import NewsletterEditForm from './NewsletterEditForm';

const mapStateToProps = (state: State, {newsletterId}: OwnProps): StateProps => ({
	user: profileSelectors.getUser(state)!,
	newsletter: newsletterId ? newsletterSelectors.getNewsletterWithEmailLists(state, newsletterId) : null,
	feeds: newsletterId ? NewsletterSelectors.getFeeds(state, newsletterId)! : [],
	focusList: FocusSelectors.getAllOrderedByNameWithFeeds(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onAddNotification: notification => dispatch(notificationOperators.add({ notification })),
	push: path => dispatch(push(path)),
	onCreateNewsletter: formNewsletter => dispatch(operators.createNewsletter({ formNewsletter })),
	onUpdateNewsletter: formNewsletter => dispatch(operators.updateNewsletter({ formNewsletter }))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterEditForm);
