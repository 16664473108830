import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { State } from 'store/types';

import { DispatchProps, StateProps } from './types';

import FocusFeedDefinitionLayoutProfile from './FocusFeedDefinitionLayoutProfile';

const mapStateToProps = ({ focus: { feed: {brand_sync} } }: State): StateProps => ({
	brand_sync
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
});


export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedDefinitionLayoutProfile);
