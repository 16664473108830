import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { cloneDeep, isEmpty, map, trim } from 'lodash';

import { State } from 'store/types';
import Mekong from 'lib/ajax/Mekong';
import { FacetItem } from 'class/Facet';

import { operators, Actions } from './actions';

export default function* sagas() {
	yield all([
		takeLatest(operators.fetchQueriedMedias.type, fetchQueriedMedias),
		takeLatest(operators.setExcludeField.type, setExcludeField)
	]);
}

function* fetchQueriedMedias({ payload: { query } }: Actions["FetchQueriedMedias"]): any {
	query = trim(query);
	if (isEmpty(query) || query.length < 2) {
		yield put(operators.resetQueriedMedia());
		return;
	}

	const limit = 100;
	const [onlineMedias, printMedias] = yield all([
		call(Mekong.get, '/v1/mediaSource/online/autocomplete', { params: { query: query + '*', limit, response_type: "autocompleter"} }),
		call(Mekong.post, '/v1/print/medias/search', { data: { search: query, limit } })
	]);

	const APImedias: Array<{ id: string, name: string, url: string, type: string }> = [
		...map(onlineMedias, media => ({ ...media, type: "news" })),
		...map(printMedias, media => ({ ...media, name: media.description + ' - ' + media.country, type: "print" }))
	];

	APImedias.sort((a, b) => {
		if (a.name < b.name) return -1;
		if (a.name > b.name) return 1;
		return 0;
	});

	const media: FacetItem[] = map(APImedias, APImedia => ({ key: APImedia.id, name: APImedia.name, detail: APImedia.url, type: APImedia.type }));
	yield put(operators.setQueriedMedia({ queriedMedia: media }));
}

function* setExcludeField({ payload: { field, set } }: Actions["SetExcludeField"]) {
	let excludeFields: string[] = yield select((state: State) => state.search.horizontalFilters.excludedFields);

	let excludedFields = cloneDeep(excludeFields);
	if (set) excludedFields.push(field);
	else excludedFields = excludedFields.filter(excludedField => excludedField !== field);
	yield put(operators.setExcludedFields({ excludedFields }));
}
