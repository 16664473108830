import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { Actions, operators } from './actions';
import { FilterFacet, FiltersFacetGroup } from 'class/Filter';
import { concat } from 'lodash';

export type State = {
	facetsGroups: FiltersFacetGroup
	focus: string[]
	feeds: string[]
};

const defaultChannelTypes: FilterFacet[] = [
	{ "key": "online", "name": "Online" },
	{ "key": "blogs", "name": "Blogs" },
	{ "key": "23", "name": "Slide show" },
	{ "key": "25", "name": "PrintDMR" },
	{ "key": "30", "name": "Twitter" },
	{ "key": "40", "name": "YouTube" },
	{ "key": "41", "name": "TikTok (Beta)" },
	{ "key": "51", "name": "Instagram" },
	{ "key": "60", "name": "Facebook" }
];

const parkluChannelTypes: FilterFacet[] = [
	{ "key": "31", "name": "Weibo" },
	{ "key": "46", "name": "Douyin" },
	{ "key": "47", "name": "Bilibili" },
	{ "key": "52", "name": "RED" },
	{ "key": "62", "name": "Wechat" }
];

export const INITIAL_STATE: State = {
	facetsGroups: {},
	focus: [],
	feeds: []
};

export const INITIAL_DMR_STATE: State = {
	facetsGroups : {
		channel_type_id: defaultChannelTypes
	},
	focus: [],
	feeds: []
};

export const INITIAL_DMR_PARKLU_STATE: State = {
	facetsGroups : {
		channel_type_id: concat(defaultChannelTypes, parkluChannelTypes)
	},
	focus: [],
	feeds: []
};

const reducerHandlers: ReducerHandlers<State> = {
	resetSearchFiltersData: (state, { payload }: Actions["ResetSearchFiltersData"]): State => ({
		...INITIAL_STATE
	}),
	resetSearchFiltersDMRData: (state, { payload }: Actions["ResetSearchFiltersData"]): State => ({
		...INITIAL_DMR_STATE
	}),
	resetSearchFiltersDMRAndParkluData: (state, { payload }: Actions["ResetSearchFiltersData"]): State => ({
		...INITIAL_DMR_PARKLU_STATE
	}),
	setFilters: (state, { payload: { filters } }: Actions["SetFilters"]): State => ({
		...state,
		...filters
	}),
	setFacetFilters: (state, { payload: { facetsGroups } }: Actions["SetFacetFilters"]): State => ({
		...state,
		facetsGroups
	}),
	setFilteredFocusFeeds: (state, { payload: { focus, feeds } }: Actions["SetFilteredFocusFeeds"]): State => ({
		...state,
		focus,
		feeds
	})
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
