import React from "react";
import { SelectField } from 'lib/imports/react-md';

import timezones from 'config/timezones.json';

import { ComponentProps } from './types';
import { withT } from 'lib/i18n';

const NewsletterEditFormPreferences = (props: ComponentProps) => {
	const { t, language, timezone } = props;
	const { onLanguageUpdated, onTimezoneUpdated } = props;

	const languageInput = React.useMemo(() => (
		<SelectField
			id="newsletterEditLanguageSelect"
			label={t('newsletter.form.input.language')}
			menuItems={[
				{ value: "en", label: t('account.language.en') },
				{ value: "es", label: t('account.language.es') },
				{ value: "fr", label: t('account.language.fr') }
			]}
			onChange={(value: any) => onLanguageUpdated(value)}
			value={language}
			fullWidth
		/>
	), [t, language, onLanguageUpdated]);

	const timezoneInput = React.useMemo(() => (
		<SelectField
			id="newsletterEditTimezoneSelect"
			label={t('newsletter.form.input.timezone')}
			menuItems={timezones}
			onChange={(value: any) => onTimezoneUpdated(value)}
			value={timezone}
			fullWidth
		/>
	), [t, timezone, onTimezoneUpdated]);

	return (
		<div id="newsletterEditSectionPreferences" className="form-section">
			<div className="form-section-title">
				{t('newsletter.form.label.preferences')}
			</div>
			<div className="form-section-content">
				<div className="form-section-content-column">
					{languageInput}
				</div>
				<div className="form-section-content-column">
					{timezoneInput}
				</div>
			</div>
		</div>
	);
};

export default withT(React.memo(NewsletterEditFormPreferences));
