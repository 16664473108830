import { Dispatch } from "redux";
import { connect } from "react-redux";

import { State } from "store/types";
import { DispatchProps, StateProps } from "./types";
import { operators } from "store/article/insert";
import ArticleInsertSelectDocument from "./ArticleInsertSelectDocument";
import { getCategoryName } from "store/entities/Category/selectors";

const mapStateToProps = (state: State): StateProps => ({
	selectedDocumentIds: state.article.insert.selectedDocumentIds,
	tenantDocuments: state.article.insert.tenantDocuments,
	historicalDocuments: state.article.insert.historicalDocuments,
	feedDocuments: state.article.insert.feedDocuments,
	loading: state.article.insert.loading,
	searchNotFoundReason: state.article.insert.searchNotFoundReason,
	tenant: state.app.profile.tenant!,
	getCategoryName: (id: string) => getCategoryName(state, id)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSelectedDocumentId: id => dispatch(operators.setSelectedDocumentId({ id }))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ArticleInsertSelectDocument);
