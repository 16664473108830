import { all, put, select, takeEvery, takeLatest, call } from 'redux-saga/effects';
import { cloneDeep, find, xorBy, isEqual, remove } from 'lib/imports/lodash';
import Mekong from 'lib/ajax/Mekong';
import isURL from 'validator/lib/isURL';
import { operators as notificationOperators } from 'store/app/notifications/';

import { State } from "store/types";
import { DefinitionSocial, ErrorProfile, ScopeSocial, SocialExpressionsType, mentionsScope } from 'class/Feed';

import { Actions, operators } from './actions';

export default function* sagas() {
	yield all([
		takeLatest(operators.searchProfile.type, searchProfile),
		takeLatest(operators.searchMultipleProfiles.type, searchMultipleProfiles),
		takeEvery(operators.toggleDefinitionInstagramAccount.type, toggleDefinitionInstagramAccount),
		takeEvery(operators.changeMainScope.type, changeMainScope),
		takeEvery(operators.changeScope.type, changeScope),
		takeEvery(operators.removeExpression.type, removeExpression)
	]);
}

function* searchProfile({ payload: { url, type, index } }: Actions["SearchProfile"]) {
	try {
		const definition: DefinitionSocial = yield select((state: State) => state.focus.feed.social.definition);
		if (definition[type].length >= 1000) {
			yield put(notificationOperators.add({notification: {t: 'definition.feed.form.error.profile.too_many_profiles', level: 'danger'}}));
			return yield put(operators.removeProfileExpression({type, index}));
		}
		if (!isURL(url, { require_host: true })) return yield put(operators.setExpressionError({ hasError: true, type, index }));
		const response = yield call(Mekong.post, '/v1/mediaSource/social/search', { data: { urls: [url], api_lookup: true }});
		if (response[0].status !== 200) return yield put(operators.searchProfileError({ error: response[0].error, type, index }));
		const profile = response[0].profile;

		if (!__existsProfileInDefinition(definition[type], profile.id)) {
			yield put(operators.searchProfileSuccess({ profile: { ...profile, api_version: 'new' }, type, index }));
		} else {
			yield put(operators.removeProfileExpression({type, index}));
			yield put(notificationOperators.add({notification: {t: 'definition.feed.form.info.source_duplicated', level: 'info'}}));
		}
	} catch (error) {
		yield put(operators.searchProfileError({ error, type, index }));
	}
}

function* searchMultipleProfiles({ payload: { urls, type } }: Actions["SearchMultipleProfiles"]) {
	const definition: DefinitionSocial = yield select((state: State) => state.focus.feed.social.definition);

	const existingProfileIds = new Set(definition[type].map(x => x.id));
	const currentProfilesLength = definition[type].length;
	try {
		const goodURLs = [], wrongURLs: ErrorProfile[] = [];
		for (const url of urls) {
			if (isURL(url, {require_host: true}))
				goodURLs.push(url);
			else
				wrongURLs.push({url, error: 'Profile url is not accepted'});
		}

		if (currentProfilesLength + goodURLs.length > 1000) {
			return yield put(notificationOperators.add({notification: {t: 'definition.feed.form.error.profile.too_many_profiles', level: 'danger'}}));
		}

		const profilesRes = yield call(Mekong.post, '/v1/mediaSource/social/search', { data: { urls: goodURLs, api_lookup: false }});

		const filteredProfiles = [];let duplicatesFound = false;
		for (const profileRes of profilesRes) {
			if (profileRes.status !== 200)
				wrongURLs.push({url: profileRes.input_url, error: profileRes.error.message});
			else if (!existingProfileIds.has(profileRes.profile.id))
				filteredProfiles.push(profileRes);
			else
				duplicatesFound = true;
		}

		if (duplicatesFound) {
			yield put(notificationOperators.add({notification: {t: 'definition.feed.form.info.source_duplicated', level: 'info'}}));
		}

		if (wrongURLs.length) {
			yield put(operators.searchMultipleProfilesError({errorProfiles: wrongURLs, type}));
		}

		const promises = filteredProfiles.map((filteredProfile, idx) => {
			return put(operators.searchProfileSuccess({ profile: { ...filteredProfile.profile, api_version: 'new' }, type, index: currentProfilesLength + idx }));
		});

		yield all(promises);
	} catch (error) {
		return yield put(notificationOperators.add({notification: {t: 'reports.error_try_later', level: 'danger'}}));
	}
}

function* toggleDefinitionInstagramAccount({ payload: { account } }: Actions["ToggleDefinitionInstagramAccount"]) {
	const definition: DefinitionSocial = yield select((state: State) => state.focus.feed.social.definition);

	const definitionInstagramAccounts = xorBy(cloneDeep(definition.instagram_accounts), [account], 'id');
	if (!!find(definitionInstagramAccounts, { id: account.id })) {
		if (account.linkedExpression.type === "main") yield put(operators.changeMainQuery({ q: account.screen_name }));
		else yield put(operators.changeQuery({ q: account.screen_name, type: account.linkedExpression.type, index: account.linkedExpression.index }));
	}

	yield put(operators.setDefinitionInstagramAccounts({ definitionInstagramAccounts }));
}

function* changeMainScope({ payload: { scope } }: Actions["ChangeMainScope"]) {
	yield removeDefinitionInstagramAccount(scope, "main", 0);
}

function* changeScope({ payload: { scope, type, index } }: Actions["ChangeScope"]) {
	yield removeDefinitionInstagramAccount(scope, type, index);
}

function* removeDefinitionInstagramAccount(scope: ScopeSocial[], type: SocialExpressionsType | "main", index: number) {
	const definition: DefinitionSocial = yield select((state: State) => state.focus.feed.social.definition);
	let definitionInstagramAccounts = definition.instagram_accounts;

	if (!isEqual(scope, mentionsScope) && type !== "exclude_expressions") {
		const account = find(definitionInstagramAccounts, { linkedExpression: { type, index } });
		if (account) {
			definitionInstagramAccounts = cloneDeep(definitionInstagramAccounts);
			remove(definitionInstagramAccounts, account);
			yield put(operators.setDefinitionInstagramAccounts({ definitionInstagramAccounts }));

			if (type === 'main') yield put(operators.changeMainQuery({ q: '' }));
			else yield put(operators.changeQuery({ q: '', type, index }));
		}
	}
}

function* removeExpression({ payload: { type, index } }: Actions["RemoveExpression"]) {
	const definition: DefinitionSocial = yield select((state: State) => state.focus.feed.social.definition);

	let definitionInstagramAccounts = cloneDeep(definition.instagram_accounts);

	remove(definitionInstagramAccounts, { linkedExpression: { type, index } });
	definitionInstagramAccounts = definitionInstagramAccounts.map(igAccount => {
		if (igAccount.linkedExpression.type === type && igAccount.linkedExpression.index > index) igAccount.linkedExpression.index--;
		return igAccount;
	});

	yield put(operators.setDefinitionInstagramAccounts({ definitionInstagramAccounts }));
}

function __existsProfileInDefinition(typeDefintion: any, resProfileId: any) {
	return typeDefintion.find((definitionProfile: any) => definitionProfile.id === resProfileId) !== undefined;
}
