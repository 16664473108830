import React from 'react';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';

import { getTypeIcon } from 'lib/svg/feed';
import { FeedType } from 'class/Feed';

import './ArticleInsertSelectType.scss';

type FocusCount = {[key: string]: {focus: number, feed: number}};

const ArticleInsertSelectType = (props: ComponentProps) => {
	const { focusList } = props;
	const { t, onSetType, onChangeStep } = props;

	const focusCount = React.useMemo(() => {
		const count: FocusCount = {online: {focus: 0, feed: 0}, socialmedia: {focus: 0, feed: 0}, print: {focus: 0, feed: 0}};
		for (const focus of focusList || []) {
			const feeds = focus.feeds || {} as {[key: string]: []};
			count.online.focus += feeds.online.length ? 1 : 0;
			count.online.feed += feeds.online.length || 0;
			count.socialmedia.focus += feeds.socialmedia.length ? 1 : 0;
			count.socialmedia.feed += feeds.socialmedia.length || 0;
			count.print.focus += feeds.print.length ? 1 : 0;
			count.print.feed += feeds.print.length || 0;
			count.print.focus += feeds.print_dmr.length ? 1 : 0;
			count.print.feed += feeds.print_dmr.length || 0;
		}
		return count;
	}, [focusList]);

	return (
		<div id="articleInsertDocumentDialogSelectType">
			{Object.keys(focusCount).map(focusType => {
				const isBoxEnabled = focusCount[focusType].feed > 0;
				return (
					<div key={focusType} className={`feed-box ${!isBoxEnabled ? "disabled-box" : ""}`} onClick={() => {
						if (!isBoxEnabled) return;
						onSetType(focusType as FeedType);
						onChangeStep("SELECT_FEED");
					}}>
						<img alt='' src={getTypeIcon(focusType as FeedType, isBoxEnabled)}></img>
						<span className="feed-box-title">{t(`type.${focusType}`)}</span>
						<span className="feed-box-hits">
							<span className="feed-box-hits-text">{focusCount[focusType].focus} focus(es) / {focusCount[focusType as FeedType].feed} feed(s)</span>
						</span>
					</div>					
				);
			})}
		</div>
	);
};

export default React.memo(withT(ArticleInsertSelectType));
