import ActionCreator, { CrudActions } from "lib/store/actions";
import { Country } from "./model";
import { CountryData } from "./types";

const actionCreator = new ActionCreator('@@orm/country');

const crudOperators = actionCreator.crudOperators<Country>();
const parse = actionCreator.operator<CountryData | CountryData []>('PARSE');

export const operators = {
	...crudOperators,
	parse
};

export interface Actions extends CrudActions<Country> {
	Parse: ReturnType<typeof operators.parse>
}
