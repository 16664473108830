import React from 'react';
import { map, transform, without, keyBy, filter, size, omit, keys, isEmpty } from 'lib/imports/lodash';

import { FocusObject } from 'store/entities/Focus';
import FletcherLoader from 'components/common/Fletcher/Loader';

import FocusCard from 'components/common/Focus/Card';
import FocusAutocompleter, { FocusAutocompleterItems } from 'components/common/Focus/Autocompleter';
import FocusFeedCheckbox from 'components/common/Focus/Feed/Checkbox';

import { ComponentProps } from './types';

import './FocusFeedSelect.scss';

const FocusFeedSelect = (props: ComponentProps) => {
	const { focusList, selectedFeedIds, error, multiple, linkedBrandId } = props;
	const { onFeedChange, onFocusChange } = props;

	const [selectedFocusById, setSelectedFocusById] = React.useState<{ [focusId: string]: true }>({});

	const focusByFeedId = React.useMemo(() => transform(focusList, (result: { [feedId: string]: FocusObject }, focus) => {
		focus.feeds.forEach(feed => { result[feed.id] = focus; });
	}, {}), [focusList]);

	React.useEffect(() => {
		const missingSelectedFocusById = transform(selectedFeedIds, (result: { [focusId: string]: true }, feedId) => {
			const focusId = focusByFeedId[feedId].id;
			if (!(focusId in selectedFocusById)) result[focusId] = true;
		}, {});
		if (!isEmpty(missingSelectedFocusById)) setSelectedFocusById({ ...selectedFocusById, ...missingSelectedFocusById });
	}, [selectedFeedIds]); // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		if (onFocusChange) onFocusChange(keys(selectedFocusById));
	}, [selectedFocusById]); // eslint-disable-line react-hooks/exhaustive-deps

	const selectedFeedsById = React.useMemo(() => keyBy(selectedFeedIds), [selectedFeedIds]);

	const selectedFocusList = React.useMemo(() => filter(focusList, focus => (focus.id in selectedFocusById)), [focusList, selectedFocusById]);

	const onAddFocus = React.useCallback((focusId: string) => {
		setSelectedFocusById(selectedFocusById => ({ ...selectedFocusById, [focusId]: true }));
	}, [setSelectedFocusById]);

	const onRemoveFocus = React.useCallback((focus: FocusObject<'feeds'>) => {
		setSelectedFocusById(selectedFocusById => omit(selectedFocusById, focus.id));

		const feedIds = map(focus.feeds, 'id');
		const updatedSelectedFeedIds = without(selectedFeedIds, ...feedIds);
		if (size(updatedSelectedFeedIds) < size(selectedFeedIds)) onFeedChange(updatedSelectedFeedIds);
	}, [selectedFeedIds, onFeedChange]);

	const onToggleFeed = React.useCallback((feedId: string, isSelected: boolean) => {
		if (isSelected) onFeedChange([...selectedFeedIds, feedId]);
		else onFeedChange(without(selectedFeedIds, feedId));
	}, [selectedFeedIds, onFeedChange]);

	const selectedFocusSection = React.useMemo(() => (
		<div className="selected-focus-section">
			{map(selectedFocusList, focus => (
				<FocusCard focus={focus} key={focus.id} onClear={() => onRemoveFocus(focus)}>
					<div className="selected-feeds">
						{map(focus.feeds, feed => (
							<FocusFeedCheckbox
								key={feed.id} feed={feed}
								checked={(feed.id in selectedFeedsById)}
								disabled={feed.type === 'online' && !!linkedBrandId && feed.brand_id !== linkedBrandId}
								onChange={checked => onToggleFeed(feed.id, checked)} />
						))}
					</div>
				</FocusCard>
			))}
		</div>
	), [selectedFocusList, selectedFeedsById, linkedBrandId, onRemoveFocus, onToggleFeed]);

	const autocompleterFocusItems = React.useMemo(() => transform(focusList, (result: FocusAutocompleterItems, { id, name }) => {
		if (!(id in selectedFocusById)) result.push({ id, text: name });
	}), [focusList, selectedFocusById]);

	const focusAutocompleterSection = React.useMemo(() => {
		if (!multiple && size(selectedFocusList) > 0) return null;
		return (<FocusAutocompleter id="focusFeedSelectAutocompleter" items={autocompleterFocusItems} onSelected={item => onAddFocus(item.id)} error={error} />);
	}, [multiple, autocompleterFocusItems, selectedFocusList, onAddFocus, error]);

	return (
		<div className='focus-feed-select'>
			<FletcherLoader resource="focus">
				<>
					{selectedFocusSection}
					{focusAutocompleterSection}
				</>
			</FletcherLoader>
		</div>
	);
};

export default React.memo(FocusFeedSelect);
