import React from "react";
import axios from 'axios';
import queryString from 'query-string';

import { withT, TProps } from 'lib/i18n';
import { TextField } from 'lib/imports/react-md';
import Button from 'react-md/lib/Buttons/Button';
import Snackbar from 'react-md/lib/Snackbars/SnackbarContainer';

import '../../../App/Notifications/Notifications.scss';
import './NewsletterUnsubscribe.scss';

const NewsletterUnsubscribe = ({ t }: TProps) => {
	const [unsubscribed, setUnsubscribed] = React.useState(false);
	const [inputEmail, setInputEmail] = React.useState<string | number>('');
	const [displayError, setDisplayError] = React.useState(false);

	const errorText = React.useMemo(() => {
		return `It seems this email address "${inputEmail}" was not the original recipient of the email/newsletter\
		 you want to unsubscribe from. As such, we cannot unsubscribe "${inputEmail}". The email/newsletter may have\
		  been forwarded to you, we suggest you reach out to the person who forwarded it to stop receiving it.`;
	}, [inputEmail]);

	const checkEmailMatch = React.useCallback(() => {
		const params = queryString.parse(window.location.search);
		const email = params.email as string;
		return !!(email && inputEmail && email === inputEmail);
	}, [inputEmail]);

	const doUnsubscribe = React.useCallback(async () => {
		const apiUrl = process.env.REACT_APP_API_URL;
		const params = queryString.parse(window.location.search);
		const apiCall = apiUrl + '/v1/newsletter/unsubscribe?' + queryString.stringify(params);

		if (!checkEmailMatch()) return setDisplayError(true);

		try {
			const { data: resJson } = await axios.post(apiCall);

			if (resJson.error) {
				console.error(`Some error happened trying this query [${apiCall}] : ${JSON.stringify(resJson)}`);
			}
			else {
				setUnsubscribed(true);
			}

		} catch (error) {
			console.error(error);
		}
	}, [checkEmailMatch]);

	return (
		<div id="unsubscribeContent">
			{displayError && 
				<Snackbar
					id="notifications-snackbar"
					className={'warning'}
					toasts={[{text: errorText}]}
					autohide={true}
					autohideTimeout={15000}
					onDismiss={() => setDisplayError(false)}
				/>
			}
			{unsubscribed && t('newsletter.optout')}
			{!unsubscribed &&
				<div className="unsubscribe-panel">
					<TextField
						id="emailConfirm"
						className="text-field"
						type="email"
						fullWidth={true}
						label="Email"
						value={inputEmail}
						onChange={value => {setDisplayError(false);setInputEmail(value);}}
					/>
					<Button
						id="unsubscribe-btn"
						onClick={() => doUnsubscribe()}
						flat
					>
						{t('newsletter.optout_btn')}
					</Button>
				</div>
			}
		</div>
	);
};

export default withT(NewsletterUnsubscribe);
