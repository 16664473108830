import ActionCreator, { CrudActions } from 'lib/store/actions';
import { Brand } from './model';

const actionCreator = new ActionCreator("@@orm/brand");

const crudOperators = actionCreator.crudOperators<Brand>();

export const operators = {
	...crudOperators
};

export interface Actions extends CrudActions<Brand> {
}
