import { put, all, takeLatest, select, call } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import xor from 'lodash/xor';
import { State } from 'store/types';

import { State as FormState } from 'store/search/form/reducers';
import { State as FiltersState } from 'store/search/filters/reducers';
import { State as FocusFeedState } from 'store/focus/feed/reducers';

import Mekong from 'lib/ajax/Mekong';

import { isCurrentPage } from 'lib/pathCheck';
import GA from 'lib/googleAnalytics';

import extendedSagas from "./extended/sagas";
import { getPreviewSearchFacetsParams } from 'lib/searchParams';
import { Facet } from 'class/Facet';
import { operators, Actions } from './actions';

export default function* sagas() {
	yield all([
		extendedSagas(),
		takeLatest(operators.setAPIFacets.type, setAPIFacets),
		takeLatest(operators.toggleFacetsGroupOpened.type, toggleFacetsGroupOpened),
		takeLatest(operators.fetchGroupAPIFacets.type, fetchGroupAPIFacets),
		takeLatest(operators.setShowMoreFacetGroupKey, setShowMoreFacetGroupKey)
	]);
}

function* setAPIFacets({ payload: { facetsAPI } }: Actions["SetAPIFacets"]) {
	const facets = Facet.transformFromApi(facetsAPI);
	yield put(operators.setFacets({ facets }));
}

function* toggleFacetsGroupOpened({ payload: { groupKey } }: Actions["ToggleFacetsGroupOpened"]) {
	let facetsGroupsOpened: string[] = yield select((state: State) => state.search.facets.facetsGroupsOpened);

	facetsGroupsOpened = xor(cloneDeep(facetsGroupsOpened), [groupKey]);
	yield put(operators.setFacetsGroupsOpened({ facetsGroupsOpened }));
}

function* fetchGroupAPIFacets({ payload: { groupKey } }: Actions["FetchGroupAPIFacets"]) {
	const pathname: string = yield select((state: State) => state.router.location.pathname);
	const feedType = yield select((state: State) => state.focus.feed.feedType);
	const brand_sync: boolean = yield select((state: State) => state.focus.feed.brand_sync);

	const searchForm: FormState = yield select((state: State) => state.search.form);
	let searchFilters: FiltersState = yield select((state: State) => state.search.filters);
	searchFilters = cloneDeep(searchFilters);

	const initialFacetParams = {
		facet_fields: groupKey,
		limit: 500
	};

	let facetsAPI;
	const start = GA.startTimer();
	if (!isCurrentPage(pathname, "article")) {
		const focusFeedState: FocusFeedState = yield select((state: State) => state.focus.feed);
		const facetParams = getPreviewSearchFacetsParams(focusFeedState, searchForm, searchFilters, feedType);
		if (feedType === 'print_dmr') {
			facetsAPI = yield call([Mekong, 'post'], "/v1/definition/focus/feed/preview/facets", { data: { ...facetParams, ...initialFacetParams } });
		} else {
			if (feedType === 'print') return;
			if (brand_sync) {
				yield put(operators.setFacet({ itemFacets: Facet.transformFromApi({}).groups[groupKey], groupKey }));
				return;
			}
			facetsAPI = yield call([Mekong, 'post'], "/v1/definition/focus/feed/preview/facets", { data: { ...facetParams, ...initialFacetParams } });
		}
	}

	GA.endTimer(start, isCurrentPage(pathname, "article") ? 'document_search_unitary_facets' : 'feed_preview_unitary_facets', 'load');
	const itemFacets = Facet.transformFromApi(facetsAPI.facets).groups[groupKey];
	yield put(operators.setFacet({ itemFacets, groupKey }));
}

function* setShowMoreFacetGroupKey({ payload: { facetGroupKey } }: Actions["SetShowMoreFacetGroupKey"]) {
	yield put(operators.setShowMoreFacetGroupKeySuccess({ facetGroupKey }));
}
