import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators as resultsOperators } from 'store/search/results';
import { operators as notificationOperators } from 'store/app/notifications';
import { DispatchProps, StateProps } from './types';
import ArticleActions from './ArticleActions';

const mapStateToProps = (state: State): StateProps => ({
	documentsChecked: state.search.results.documentsChecked,
	documentSources: state.search.results.documentSources,
	toggleAutomateTags: state.search.results.toggleAutomateTags,
	showTagActionPanel: state.search.results.showTagActionPanel,
	suggestedTags: state.ui.urlQuery.suggested_tags
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onToggleAllDocuments: check => dispatch(resultsOperators.toggleAllDocumentsChecked({ check })),
	onRemoveDocumentBulk: () => dispatch(resultsOperators.removeDocumentBulk()),
	onSetTags: (tagIds, newTagNames) => dispatch(resultsOperators.setTags({ tagIds, newTagNames })),
	onBulkTag: (tagIds, newTagNames) => dispatch(resultsOperators.bulkTag({ tagIds, newTagNames })),
	onAutomateTags: (tagIds, newTagNames) => dispatch(resultsOperators.automateTags({ tagIds, newTagNames })),
	onToggleAutomateTags: () => dispatch(resultsOperators.toggleAutomateTags(true)),
	onToggleTagActionPanel: () => dispatch(resultsOperators.toggleTagActionPanel()),
	onAddNotification: notification => dispatch(notificationOperators.add({ notification }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleActions);
