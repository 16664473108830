import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import Mekong from 'lib/ajax/Mekong';
import { Actions, operators } from './actions';
import { operators as notificationOperators } from 'store/app/notifications/';
import { DefinitionOnline } from 'class/Feed';
import { State } from "store/types";

export default function* sagas() {
	yield all([
		takeLatest(operators.fetchMedias, fetchMedias),
		takeLatest(operators.massiveFetchMedia, massiveFetchMedia)
	]);
}

function* fetchMedias({ payload: { query } }: Actions["FetchMedias"]) {
	try {
		const mediaResults = yield call(Mekong.get, '/v1/mediaSource/online/autocomplete', { params: { query: query + '*' } });
		yield put(operators.fetchMediaSuccess({ mediaResults }));
	} catch (error) {
		yield put(operators.fetchMediaError({ error }));
	}
}

function* massiveFetchMedia({ payload: { query, expressionKey } }: Actions["MassiveFetchMedia"]) {
	try {
		const mediaResults = yield call(Mekong.post, '/v1/mediaSource/online/search', { data: { urls: query } });

		const definition: DefinitionOnline = yield select((state: State) => state.focus.feed.online.definition);
		const existingMediaIds = new Set(definition[expressionKey].map(media => media.id));

		const unduplicatedFoundMedia = []; const notFoundMedia = [];
		for (const media of mediaResults) {
			if (media.found === false)
				notFoundMedia.push({ ...media, reason: `definition.online.media.error.reason_msg.${media.reason.toLowerCase()}` });
			else if (!existingMediaIds.has(media.id)) {
				unduplicatedFoundMedia.push(media);
				existingMediaIds.add(media.id);
			}
		}

		if (unduplicatedFoundMedia.length + definition[expressionKey].length > 1000) {
			yield put(notificationOperators.add({ notification: { t: 'definition.feed.form.error.profile.too_many_profiles', level: 'danger' } }));
			return yield put(operators.massiveFetchMediaError());
		}

		yield put(operators.massiveFetchMediaSuccess({ mediaResults: unduplicatedFoundMedia, errorMedias: notFoundMedia, expressionKey }));
		if (unduplicatedFoundMedia.length + notFoundMedia.length !== mediaResults.length) {
			yield put(notificationOperators.add({ notification: { t: 'definition.feed.form.info.source_duplicated', level: 'info' } }));
		}

	} catch (error) {
		yield put(operators.massiveFetchMediaError());
	}
}
