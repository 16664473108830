import moment from "moment";
import { Event } from "./types";
import { Actions } from "./actions";
import { operators } from "./actions";
import { createReducer, ReducerHandlers } from "lib/store/reducer";

export type State = Readonly<{
	endDate: Date,
	events: Event[],
	startDate: Date,
	searchTerm: string,
}>;

export const INITIAL_STATE: State = {
	searchTerm: "",
	events: ['all'],
	endDate: new Date(moment().format('YYYY-MM-DD')),
	startDate: new Date(moment().format('YYYY-MM-DD'))
};

const reducerHandlers: ReducerHandlers<State> = {
	filterReset: (state: State, { payload }: Actions['FilterReset']): State => ({
		...state,
		...INITIAL_STATE
	}),
	filterChanged: (state: State, { payload: filters }: Actions['FilterChanged']): State => ({
		...state,
		...filters
	}),
	eventsChanged: (state: State, { payload: { events } }: Actions['EventsChanged']): State => {
		const newEvents = events.filter(event => !state.events.includes(event));
		const eventsWithoutRemoved = state.events.filter(event => !events.includes(event) && (events.length > 0 && event !== 'all'));
		const finalEvents = [...eventsWithoutRemoved, ...newEvents];
		return {
			...state,
			events: finalEvents.length === 0 ? ['all'] : finalEvents
		};
	}
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
