import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/focus/feed/definition/print';
import { DispatchProps, StateProps } from './types';
import { ProductType } from 'class/Feed';

import FocusFeedDefinitionPrintSectionProductType from './FocusFeedDefinitionPrintSectionProductType';

const mapStateToProps = ({ app: { profile: { tenant } }, focus: { feed: { print: { definition } } } }: State): StateProps => ({
	tenant,
	productTypes: definition.product_types
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onAddExpression: (expression: ProductType) => dispatch(operators.addPrintExpression({ type: 'product_types', expression })),
	onUpdateExpression: (index: number, expression: ProductType) => dispatch(operators.updatePrintExpression({ type: 'product_types', index, expression }))
});


export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedDefinitionPrintSectionProductType);
