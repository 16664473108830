import { all, call, put, take, takeLatest } from 'redux-saga/effects';
import { operators as profileOperators } from 'store/app/profile';
import featureFlags from 'lib/featureFlags';

import { Actions, operators } from './actions';

export default function* sagas() {
	yield all([
		takeLatest(operators.updateSession.type, updateSession)
	]);
}

function* updateSession({ payload: session }: Actions["UpdateSession"]) {
	yield call([featureFlags, 'setConfig'], session.featureFlags);
	yield put(profileOperators.setProfile(session.profile));
	yield take(profileOperators.profileReady);
	yield put(operators.sessionReady());
}
