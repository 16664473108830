import { ReducerHandlers, createReducer } from 'lib/store/reducer';

import { operators } from './actions';

export type State = {
	loading: boolean
};

export const INITIAL_STATE: State = {
	loading: false
};

export const reducerHandlers: ReducerHandlers<State> = {
	createEmailList: state => ({ ...state, loading: true }),
	createEmailListSuccess: state => ({ ...state, loading: false }),
	createEmailListError: state => ({ ...state, loading: false })
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
