import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { operators } from 'store/focus/feed';
import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import DuplicateFeedDialog from './FocusFeedDuplicateFeedDialog';

const mapStateToProps = ({ focus }: State): StateProps => ({
	focusList: focus.list.focusList,
	feedName: focus.feed.feedName,
	focusId: focus.feed.focusId,
	loadingDuplicatedFeed: focus.feed.loadingDuplicateFeed,
	duplicatedFeedId: focus.feed.duplicatedFeedId
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onDuplicateFeedStart: (feedName, focusId) => dispatch(operators.duplicateFeedStart({ feedName, focusId })),
	onToggleShowDuplicateFeed: () => dispatch(operators.toggleShowDuplicateFeed())
});

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateFeedDialog);
