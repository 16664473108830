import { all } from 'redux-saga/effects';

import listSagas from './list/sagas';
import feedSagas from './feed/sagas';
import dashboardSagas from './dashboard/sagas';

// define sagas generator function to register all sagas
export default function* sagas() {
	yield all([
		listSagas(),
		feedSagas(),
		dashboardSagas()
	]);
}
