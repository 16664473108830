import React from "react";
import { SelectField } from 'lib/imports/react-md';
import { withT } from "lib/i18n";

import { newsletterHours, newsletterMinutes } from "store/entities/Newsletter/consts";
import { NewsletterTime } from "store/entities/Newsletter";

import { ComponentProps } from './types';
import './NewsletterEditFormTimeInput.scss';
import { FontIcon } from "react-md";

const NewsletterEditTimeInput = (props: ComponentProps) => {
	const { t, time } = props;
	const { onRemove, onUpdated } = props;

	const onUpdateTime = React.useCallback((newTime: Partial<NewsletterTime>) => {
		onUpdated({ ...time, ...newTime });
	}, [time, onUpdated]);

	const hourInput = React.useMemo(() => (
		<SelectField
			id="newsletterEditTimeHourSelect"
			label={t('newsletter.form.input.hour')}
			menuItems={newsletterHours.map(hour => ({ value: hour, label: hour }))}
			onChange={(hour: any) => onUpdateTime({ hour })}
			value={time.hour}
		/>
	), [t, onUpdateTime, time.hour]);

	const minuteInput = React.useMemo(() => (
		<SelectField
			id="newsletterEditTimeMinuteSelect"
			label={t('newsletter.form.input.minute')}
			menuItems={newsletterMinutes.map(minute => ({ value: minute, label: minute }))}
			onChange={(minute: any) => onUpdateTime({ minute })}
			value={time.minute}
		/>
	), [t, onUpdateTime, time.minute]);

	const removeButton = React.useMemo(() => {
		if (!onRemove) return null;
		return (
			<div id="timeRemoveButton">
				<FontIcon className="icon-cancel" onClick={() => onRemove()} />
			</div>
		);
	}, [onRemove]);

	return (
		<div id="newsletterEditFormTimeInput" className={onRemove ? "removable" : ""}>
			{hourInput}
			<span className="time-separator">:</span>
			{minuteInput}
			{removeButton}
		</div>
	);
};


export default withT(React.memo(NewsletterEditTimeInput));
