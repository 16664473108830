import React from 'react';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import DefinitionLayout from '../Layout';

import './FocusFeedDefinitionPrintDmr.scss';

const FocusFeedDefinitionPrintDmr = (props: ComponentProps) => {
	const {
		definition,
		mediaItems,
		brandItems, brands,
		productTypeItems, productTypes,
		onSetDMRDefinition, onSetDMRExpression,
		onAddBrandExpression, onUpdateBrandExpression, onRemoveBrandExpression, onFetchBrands, onResetBrands,
		onFetchProductTypes, onResetProductTypes, onAddProductTypeExpression, onUpdateProductTypeExpression, onRemoveProductTypeExpression,
		onFetchMedias, onResetMedias,
		t
	} = props;

	return (
		<div id="focusFeedDefinition">
			<DefinitionLayout
				type="print_dmr"
				brands={{
					onFetchBrands, onResetBrands,
					onAddExpression: onAddBrandExpression, onUpdateExpression: onUpdateBrandExpression, onRemoveExpression: onRemoveBrandExpression,
					brandItems, brands
				}}
				productTypes={{
					onFetchProductTypes, onResetProductTypes,
					onAddExpression: onAddProductTypeExpression, onUpdateExpression: onUpdateProductTypeExpression, onRemoveExpression: onRemoveProductTypeExpression,
					productTypeItems, productTypes
				}}
				productNameQueries={{
					onSetDefinition: onSetDMRDefinition, expressionFunctions: { onSetExpression: onSetDMRExpression },
					activeScope: ['print.product.name'], definition,
					scopeMenuItems: [
						{ value: 'print.product.name', label: t('feed.form.scope.option.productName') }
					]
				}}
				contentQueries={{
					onSetDefinition: onSetDMRDefinition, expressionFunctions: { onSetExpression: onSetDMRExpression },
					activeScope: ['title', 'content'],  definition,
					scopeMenuItems: [
						{ value: 'title', label: t('feed.form.scope.option.title') },
						{ value: 'content', label: t('feed.form.scope.option.content') },
						{ value: 'title,content', label: t('feed.form.scope.option.title_content') }
					]
				}}
				medias={{
					definition,
					mediaItems,
					displayMassive: false,
					onFetchMedias,
					onResetMedias,
					onSetDefinition: onSetDMRDefinition
				}}
				showSentimentCheck={false}
			></DefinitionLayout>
		</div>
	);
};

export default withT(React.memo(FocusFeedDefinitionPrintDmr));
