import React from "react";
import { MassiveMediaExpression, GenericExpressionsType, GenericDefinition, GenericMediaExpression } from "class/Feed";
import SimpleDialog from "components/common/dialog/simple";
import { withT } from "lib/i18n";
import { Button, Collapse, FontIcon } from "lib/imports/react-md";
import redCircledBin from 'vendor/svg/feed/definition/bin_icon.svg';

import { ComponentProps } from './types';
import { find, map, reject } from "lib/imports/lodash";
import MediaItem from "./Item/MediaItem";
import MediaAutocompleter from "./Autocompleter/MediaAutocompleter";
import { MediaExpression } from "class/Feed";
import featureFlags from "lib/featureFlags";
import { FocusFeedDefinitionMultipleExpressions } from "../../../MultipleExpressions";
import { Columns } from "components/common/DiscoverTable";
import ListDialog from "components/common/dialog/list/ListDialog";

const FocusFeedDefinitionLayoutSource = (props: ComponentProps) => {
	const { definition, mediaItems, displayMassive, showAutocompleteUrlPlaceholder = false, brand_sync } = props;
	const { t, massiveFunctions, onSetDefinition, onAddNotification, onFetchMedias, onResetMedias } = props;

	const [sourceCollapse, setSourceCollapse] = React.useState(false);
	const [showMassiveInclude, setShowMassiveInclude] = React.useState(false);
	const [showMassiveExclude, setShowMassiveExclude] = React.useState(false);
	const [showRemoveAllMediaDialog, setShowRemoveAllMediaDialog] = React.useState('');

	const onClickSourceSection = React.useCallback(() => {
		setSourceCollapse(!sourceCollapse);
	}, [sourceCollapse]);

	const onClearAllMedias = React.useCallback((expressionKey: GenericExpressionsType) => {
		onSetDefinition({
			...definition,
			[expressionKey]: []
		});
		setShowRemoveAllMediaDialog('');
	}, [onSetDefinition, definition, setShowRemoveAllMediaDialog]);

	const addMedia = React.useCallback((expressionKey: GenericExpressionsType, media: MediaExpression) => {
		const mediaExists = find([...definition["include_medias"], ...definition["exclude_medias"]], { id: media.id });
		if (mediaExists) return onAddNotification({ level: 'info', t: 'definition.feed.form.info.source_duplicated' });
		if (definition[expressionKey as keyof GenericDefinition].length >= 1000) {
			return onAddNotification({ t: 'definition.feed.form.error.profile.too_many_profiles', level: 'danger' });
		}

		onSetDefinition({
			...definition,
			[expressionKey]: [
				...definition[expressionKey as keyof GenericDefinition],
				media
			]
		});
	}, [definition, onAddNotification, onSetDefinition]);

	const addMultipleMedia = React.useCallback((input: string, expressionKey: GenericExpressionsType) => {
		const splitQuery = input.split('\n').map(query => query.toLowerCase()).filter(q => q && q !== '');
		if (massiveFunctions) massiveFunctions.onMassiveFetchMedia(splitQuery, expressionKey);
		expressionKey === 'include_medias' ? setShowMassiveInclude(false) : setShowMassiveExclude(false);
	}, [massiveFunctions]);

	const removeExpression = React.useCallback((expressionKey: GenericExpressionsType, index: number) => {
		onSetDefinition({
			...definition,
			[expressionKey]: reject(definition[expressionKey as keyof GenericDefinition], (expression, pos: number) => index === pos)
		});
	}, [onSetDefinition, definition]);

	const getMediaItem = React.useCallback((expressionKey: GenericExpressionsType) => {
		// Before we did definition[expressionKey as keyof GenericDefinition].map
		// but Typescript thinks that the resulting array can contain different types of data
		// A solution is to build the resulting array manually, since iterating with a forEach loop does not cause problems
		const mediaItems: any[] = [];
		definition[expressionKey as keyof GenericDefinition].forEach((media: any, index: number) => {
			mediaItems.push(
				<div className="media-item-wrapper" key={`${expressionKey}-${index}`}>
					<MediaItem
						index={index}
						media={media as GenericMediaExpression}
						expressionKey={expressionKey}
						onRemoveMedia={removeExpression}
					/>
					<span className="media-or-label">{t('feed.form.or')}</span>
				</div>
			);
		});
		return mediaItems;
	}, [definition, removeExpression, t]);

	const getMediaSection = React.useCallback((expressionKey: GenericExpressionsType) => {
		const headerTxt = expressionKey === 'include_medias' ? 'feed.form.header_positive_medias' : 'feed.form.header_negative_medias';
		const showMassive = expressionKey === 'include_medias' ? showMassiveInclude : showMassiveExclude;
		const onCancel = () => expressionKey === 'include_medias' ? setShowMassiveInclude(false) : setShowMassiveExclude(false);
		const onShowMassive = () => expressionKey === 'include_medias' ? setShowMassiveInclude(true) : setShowMassiveExclude(true);
		return (
			<div id={`focusFeedDefinition-${expressionKey}`}>
				<div className='definition-text-header'>
					<div className="definition-text" dangerouslySetInnerHTML={{ __html: t(headerTxt) + ":" }} ></div>
					{
						(displayMassive && featureFlags.isEnabled('discover-massive-social-import') && !showMassive) &&
						<Button
							id={`focusFeedDefinitionMassiveBtn-${expressionKey}`}
							key={`focusFeedDefinitionMassiveBtn-${expressionKey}`}
							className="massive-add-expression-button"
							flat
							onClick={onShowMassive}
						>
							<span>+</span> {t('feed.form.button.add_url_list')}
						</Button>
					}
					{
						definition[expressionKey as keyof GenericDefinition].length > 0 &&
						<Button
							id={`definitionRemoveAll-${expressionKey}`}
							className='definition-remove-all-button'
							flat
							onClick={() => setShowRemoveAllMediaDialog(expressionKey)}
						>
							<div>{t('feed.form.header_clear_all')}</div>
							<FontIcon className='definition-remove-all-button-icon'>delete</FontIcon>
						</Button>
					}
				</div>
				{
					displayMassive && featureFlags.isEnabled('discover-massive-social-import') &&
					<div key={`multipleExpressions-${expressionKey}`}>
						<FocusFeedDefinitionMultipleExpressions
							key={`multipleExpressions-${expressionKey}`}
							enabled={showMassive}
							placeholder={t('feed.form.multiple_urls.placeholder_enter_urls')}
							cancelLabel={t('feed.form.multiple_urls.cancel')}
							submitLabel={t('feed.form.multiple_urls.submit')}
							onCancel={onCancel}
							onSubmit={input => addMultipleMedia(input, expressionKey)}
						/>
					</div>
				}
				<div className="definition-medias-container">
					{getMediaItem(expressionKey)}
					<div className="focus-feed-definition-media-autocompleter-container">
						<MediaAutocompleter
							showUrlPlaceholder={showAutocompleteUrlPlaceholder}
							expressionKey={expressionKey}
							onAddMedia={addMedia}
							mediaItems={mediaItems}
							onFetchMedias={onFetchMedias}
							onResetMedias={onResetMedias}
						/>
					</div>
				</div>
			</div >
		);
	}, [addMedia, addMultipleMedia, definition, displayMassive, getMediaItem, mediaItems, onFetchMedias, onResetMedias, showAutocompleteUrlPlaceholder, showMassiveExclude, showMassiveInclude, t]);

	const removeAllMediaDialog = React.useMemo(() => (showRemoveAllMediaDialog !== '' ?
		<SimpleDialog
			id="focusFeedDefinitionRemoveAllMediaWarning"
			content={<></>}
			text={t('feed.form.confirm_remove_all_expressions')}
			headerImage={redCircledBin}
			onAccept={() => onClearAllMedias(showRemoveAllMediaDialog as GenericExpressionsType)}
			onCancel={() => setShowRemoveAllMediaDialog('')}
			acceptColor="red"
		/>
		: null), [showRemoveAllMediaDialog, t, onClearAllMedias]);

	const sourceSection = React.useMemo(() => {
		const isCollapsed = sourceCollapse || brand_sync;
		return (
			<div id="focusFeedDefinitionSourceSection" className={`definition-section ${brand_sync ? 'definition-section-disabled' : ''}`}>
				<div className="definition-section-header">
					<div className="definition-header-title" onClick={onClickSourceSection}>
						{
							isCollapsed ?
								<FontIcon className="definition-header-title-toggle" >keyboard_arrow_right</FontIcon> :
								<FontIcon className="definition-header-title-toggle">keyboard_arrow_down</FontIcon>
						}
						<span className="definition-header-title-text">{t('feed.form.header_source_expression')}</span>
					</div>
				</div>

				<Collapse collapsed={isCollapsed}>
					<div className={`definition-section-content ${brand_sync && 'disabled'}`}>
						<div className="definition-expressions">
							{getMediaSection("include_medias")}
							{getMediaSection("exclude_medias")}
						</div>
					</div>
				</Collapse>
			</div>
		);
	}, [getMediaSection, onClickSourceSection, sourceCollapse, t, brand_sync]);

	const buildClipboardString = React.useCallback(() => {
		const rows = massiveFunctions ? map(massiveFunctions.errorMedias, media => media.url) : [];
		const clipboardString = rows.join('\n');
		return clipboardString;
	}, [massiveFunctions]);

	const mediaColumn: Columns<MassiveMediaExpression, MassiveMediaExpression> = React.useMemo(() => ({
		key: 'url',
		type: 'text',
		displayName: t('filters.media.value')
	}), [t]);

	const reasonColumn: Columns<MassiveMediaExpression, MassiveMediaExpression> = React.useMemo(() => ({
		key: 'reason',
		type: 'custom',
		render: props => <>{t(props.reason as string)}</>,
		displayName: t('definition.online.media.error.reason')
	}), [t]);

	const showErrorDialog = React.useCallback(() => displayMassive && massiveFunctions!.errorMedias!.length, [displayMassive, massiveFunctions]);

	const errorListDialog = React.useMemo(() => (massiveFunctions && showErrorDialog() ?
		<ListDialog
			id="mediaErrorListDialog"
			title={t("definition.online.media.error.title")}
			headerText={t("definition.online.media.error.header")}
			tableData={{
				data: massiveFunctions ? massiveFunctions!.errorMedias : [],
				columns: [mediaColumn, reasonColumn]
			}}
			onCancel={massiveFunctions.onClearErrorMedias}
			onFillClipboard={buildClipboardString}
		/>
		: null
	), [showErrorDialog, t, massiveFunctions, mediaColumn, reasonColumn, buildClipboardString]);

	return (
		<>
			{removeAllMediaDialog}
			{errorListDialog}
			{sourceSection}
		</>
	);
};

export default withT(React.memo(FocusFeedDefinitionLayoutSource));
