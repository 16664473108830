import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';

import FocusFeedDefinitionLayoutContent from './FocusFeedDefinitionLayoutSocialContent';
import selectors from 'store/focus/feed/definition/social/selectors';
import { Dispatch } from 'redux';

const mapStateToProps = (state: State): StateProps => ({
	brand_sync: state.focus.feed.brand_sync,
	instagramAccounts: selectors.getInstagramAccountsToSelect(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedDefinitionLayoutContent);
