import React from 'react';
import { withT } from 'lib/i18n';
import { Card, Paper } from 'react-md';
import { DocumentCounter } from 'class/Document';
import { ComponentProps } from './types';
import { isEmpty } from 'lodash';

import './DocumentItemValuation.scss';

const DocumentItemValuation = (props: ComponentProps) => {
	const { defaultValuation, brandedValuation, getBrandName, t } = props;

	const [showGrouped, setShowGrouped] = React.useState(false);
	const onToggleShow = React.useCallback(() => setShowGrouped(show => !show), []);

	let isBrandedValuation = !isEmpty(brandedValuation);
	let valuationToDisplay = isBrandedValuation ? brandedValuation : [defaultValuation];

	let groupedValuations: DocumentCounter[] = React.useMemo(() => [], []);
	if (valuationToDisplay!.length >= 3) {
		// @ts-ignore
		groupedValuations = valuationToDisplay.slice(3);
		// @ts-ignore
		valuationToDisplay = valuationToDisplay.slice(0, 3);
	}

	const getFullTitleAndValue = React.useCallback((valuation: DocumentCounter) => {
		const brandId = valuation.id ? valuation.id.toString() : '';
		let brandName = getBrandName(brandId);
		if (!brandName && isBrandedValuation) brandName = t('feed.form.label.no_brand');
		const title = `${brandName} ${valuation.title}`.trim();
		const value = `${brandName} ${valuation.value}`.trim();
		return { title, value };
	}, [getBrandName, t, isBrandedValuation]);

	const showGroupedContent = React.useMemo(() => {
		if (!showGrouped) return null;
		return (
			<div className="document-grouped-item-valuation">
				{
					groupedValuations.map((groupedValuation, indexGroup) => {
						const { title, value } = getFullTitleAndValue(groupedValuation);
						return <div key={`documentItemGroupedBrand-${indexGroup + 1}`} className="document-grouped-item-valuation-text" title={title}>
							{value}
						</div>;
					})
				}
			</div>
		);
	}, [showGrouped, groupedValuations, getFullTitleAndValue]);

	const showMoreBadge = React.useMemo(() => {
		if (!groupedValuations.length) return null;
		return (
			<>
				<Card className="document-item-valuation-chip show-more" onClick={onToggleShow}>
					<div className="document-item-valuation-chip-text">{"+" + groupedValuations.length}</div>
				</Card>
				{showGroupedContent}
			</>
		);
	}, [groupedValuations.length, onToggleShow, showGroupedContent]);

	return (
		<div className="document-item-valuation"> MIV:
			{
				valuationToDisplay!.map((valuation, index) => {
					if (!valuation) return <></>;
					const { title, value } = getFullTitleAndValue(valuation);
					return <Paper key={`documentItemBrand-${index + 1}`} className="document-item-valuation-chip" zDepth={1}>
						<div className="document-item-valuation-chip-text" title={title}>{value}</div>
					</Paper>;
				})
			}
			{showMoreBadge}
		</div>
	);
};

export default React.memo(withT(DocumentItemValuation));
