import ActionCreator, { CrudActions } from "lib/store/actions";
import { EmailList } from "./model";

const actionCreator = new ActionCreator('@@orm/emailList');

const crudOperators = actionCreator.crudOperators<EmailList>();

export const operators = {
	...crudOperators
};

export interface Actions extends CrudActions<EmailList> {
}
