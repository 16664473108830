import React from "react";
import { Button, SelectionControl } from 'lib/imports/react-md';

import { TProps, withT } from "lib/i18n";
import { NewsletterObject } from "store/entities/Newsletter";

import './NewsletterEditFormType.scss';

type OwnProps = {
	type: NewsletterObject["type"],
	onTypeUpdated: (type: NewsletterObject["type"]) => void
};

type ComponentProps = OwnProps & TProps;

const NewsletterEditFormType = (props: ComponentProps) => {
	const { t, type } = props;
	const { onTypeUpdated } = props;

	const digestInput = React.useMemo(() => (
		<SelectionControl
			id={`newsletterEditSectionTypeDigest`}
			name="newsletterEditSectionType"
			type="radio"
			value="digest"
			label={t('newsletter.form.input.type.digest.value')}
			checked={type === "digest"}
			onChange={(value: any) => onTypeUpdated(value)}
		/>
	), [t, type, onTypeUpdated]);

	const fullInput = React.useMemo(() => (
		<SelectionControl
			id={`newsletterEditSectionTypeFull`}
			name="newsletterEditSectionType"
			type="radio"
			value="full"
			label={t('newsletter.form.input.type.full')}
			checked={type === "full"}
			onChange={(value: any) => onTypeUpdated(value)}
		/>
	), [t, type, onTypeUpdated]);

	return (
		<div id="newsletterEditSectionType" className="form-section">
			<div className="form-section-title">
				{t('newsletter.form.label.type')}
			</div>
			<div className="form-section-content">
				<div className="form-section-content-column">
					{fullInput}
				</div>
				<div className="form-section-content-column newsletter-digest-tooltip">
					{digestInput}
					<Button icon tooltipLabel={<span dangerouslySetInnerHTML={{ __html: t('newsletter.form.input.type.digest.help') }} ></span>} tooltipPosition="right">help_outline</Button>
				</div>
			</div>
		</div>
	);
};

export default withT(React.memo(NewsletterEditFormType));
