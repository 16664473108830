import { FacetItem } from 'class/Facet';
import { Action, actionCreatorFactory } from 'typescript-fsa';

const actionCreator = actionCreatorFactory('@@search');

type Payloads = {
	FetchQueriedMedias: { query: string };
	SetExcludeField: { field: string, set: boolean };
	SetExcludedFields: { excludedFields: string[] };
	SetQueriedMedia: { queriedMedia: FacetItem[] };
	ResetQueriedMedia: void;
	SetDateRange: { startDate: Date, endDate: Date };
}

export type Actions = {
	FetchQueriedMedias: Action<Payloads["FetchQueriedMedias"]>;
	SetExcludeField: Action<Payloads["SetExcludeField"]>;
	SetExcludedFields: Action<Payloads["SetExcludedFields"]>;
	SetQueriedMedia: Action<Payloads["SetQueriedMedia"]>;
	ResetQueriedMedia: Action<Payloads["ResetQueriedMedia"]>;
	SetDateRange: Action<Payloads["SetDateRange"]>;
}

export const operators = {
	fetchQueriedMedias: actionCreator<Payloads["FetchQueriedMedias"]>('FETCH_QUERIED_MEDIAS'),
	setExcludeField: actionCreator<Payloads["SetExcludeField"]>('SET_EXCLUDE_FIELD'),
	setExcludedFields: actionCreator<Payloads["SetExcludedFields"]>('SET_EXCLUDED_FIELDS'),
	setQueriedMedia: actionCreator<Payloads["SetQueriedMedia"]>('SET_QUERIED_MEDIAS'),
	resetQueriedMedia: actionCreator<Payloads["ResetQueriedMedia"]>('RESET_QUERIED_MEDIAS'),
	setDateRange: actionCreator<Payloads["SetDateRange"]>('SET_HORIZONTAL_FILTER_DATE_RANGE')
};
