import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import { operators } from 'store/article/insert';
import ArticleInsertSelectType from './ArticleInsertSelectType';

const mapStateToProps = ({ focus, article }: State): StateProps => ({
	focusList: focus.list.focusList,
	type: article.insert.type
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetType: type => dispatch(operators.setType({ type })),
	onChangeStep: step => dispatch(operators.changeStep({ step }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleInsertSelectType);
