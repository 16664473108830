import { ORM, ORMOpts, OrmState, createReducer } from 'redux-orm';
import { OrmSession } from 'redux-orm/Session';
import { State as RootState } from 'store/types';

import schema, { Schema } from './schema';

export type State = OrmState<Schema>
export type Session = OrmSession<Schema>

export const stateSelector = (state: RootState): State => state.entities;

interface ORMOptsWithSelector extends ORMOpts {
	stateSelector: typeof stateSelector
}
const orm = new ORM<Schema>({ stateSelector } as ORMOptsWithSelector);
// register all models schema
orm.register(...Object.values(schema));

export const reducers = createReducer(orm);

export default orm;
