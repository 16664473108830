import omit from "lodash/omit";
import map from "lodash/map";
import zipObject from "lodash/zipObject";
import without from "lodash/without";

import { createReducer, ReducerHandlers } from "lib/store/reducer";
import { Actions, operators } from "./actions";
import { ApiSearchDocument, DocumentObject } from "class/Document";
import { union, keyBy } from "lib/imports/lodash";

export type DocParams = {
	id: string;
	highlight?: string;
};

export type State = {
	readonly documentSources: DocumentObject[] | null;
	readonly documents: ApiSearchDocument[] | null;
	readonly pageDocuments: number;
	readonly total: number;
	readonly loadingDocumentsSources: boolean;
	readonly loadingDocuments: boolean;
	readonly loadingRemoveDocument: { [id: string]: boolean };
	readonly loadingSetTagsCategoryDocument: { [id: string]: boolean };
	readonly documentsChecked: { [id: string]: boolean };
	readonly toggleAutomateTags: boolean;
	readonly showTagActionPanel: boolean;
};

export const INITIAL_STATE: State = {
	documentSources: null,
	documents: null,
	pageDocuments: 0,
	total: 0,
	loadingDocumentsSources: false,
	loadingDocuments: false,
	loadingRemoveDocument: {},
	loadingSetTagsCategoryDocument: {},
	documentsChecked: {},
	toggleAutomateTags: true,
	showTagActionPanel: false
};

const reducerHandlers: ReducerHandlers<State> = {
	resetSearchResultsData: (state, { payload }: Actions["ResetSearchResultsData"]): State => ({
		...INITIAL_STATE
	}),
	fetchSearch: (state, { payload }: Actions["FetchSearch"]): State => ({
		...state,
		loadingDocuments: true,
		documentsChecked: INITIAL_STATE.documentsChecked
	}),
	fetchSearchSuccess: (state, { payload: { documents, total } }: Actions["FetchSearchSuccess"]): State => ({
		...state,
		loadingDocuments: false,
		documents,
		total
	}),
	fetchSearchError: (state, { payload }: Actions["FetchSearchError"]): State => ({
		...state,
		loadingDocuments: false
	}),
	fetchDocuments: (state, { payload }: Actions["FetchDocuments"]): State => ({
		...state,
		loadingDocumentsSources: true
	}),
	fetchDocumentsSuccess: (state, { payload: { documents } }: Actions["FetchDocumentsSuccess"]): State => ({
		...state,
		loadingDocumentsSources: false,
		documentSources: documents
	}),
	fetchDocumentsError: (state, { payload }: Actions["FetchDocumentsError"]): State => ({
		...state,
		loadingDocumentsSources: false
	}),
	setDocuments: (state, { payload: { documentSources } }: Actions["SetDocuments"]): State => ({
		...state,
		documentSources
	}),
	removeDocument: (state, { payload: { id } }: Actions["RemoveDocument"]): State => ({
		...state,
		loadingRemoveDocument: { ...state.loadingRemoveDocument, [id]: true }
	}),
	removeDocumentSuccess: (state, { payload: { id } }: Actions["RemoveDocument"]): State => ({
		...state,
		loadingRemoveDocument: omit(state.loadingRemoveDocument, id),
		documentsChecked: state.documentsChecked[id]
			? omit(state.documentsChecked, id)
			: state.documentsChecked
	}),
	removeDocumentError: (state, { payload: { id } }: Actions["RemoveDocument"]): State => ({
		...state,
		loadingRemoveDocument: omit(state.loadingRemoveDocument, id)
	}),

	toggleDocumentChecked: (state, { payload: { id } }: Actions["ToggleDocumentChecked"]): State => ({
		...state,
		documentsChecked: state.documentsChecked[id]
			? omit(state.documentsChecked, id)
			: { ...state.documentsChecked, [id]: true }
	}),

	toggleAllDocumentsChecked: (state, { payload: { check } }: Actions["ToggleAllDocumentsChecked"]): State => ({
		...state,
		documentsChecked: check
			? zipObject(
				map(state.documentSources, doc => doc.id),
				map(state.documentSources, () => true)
			)
			: {}
	}),

	toggleTagActionPanel: (state, { payload }: Actions["ToggleTagActionPanel"]): State => ({
		...state,
		showTagActionPanel: !state.showTagActionPanel
	}),

	removeDocumentBulk: (state): State => ({
		...state,
		loadingRemoveDocument: {
			...state.loadingRemoveDocument,
			...zipObject(
				Object.keys(state.documentsChecked),
				map(state.documentsChecked, () => true)
			)
		}
	}),
	removeDocumentBulkSuccess: (state): State => ({
		...state,
		loadingRemoveDocument: {},
		documentsChecked: {}
	}),
	removeDocumentBulkError: (state): State => ({
		...state,
		loadingRemoveDocument: {},
		documentsChecked: {}
	}),
	setTags: (state): State => ({
		...state,
		loadingSetTagsCategoryDocument: {
			...state.loadingSetTagsCategoryDocument,
			...zipObject(
				Object.keys(state.documentsChecked),
				map(state.documentsChecked, () => true)
			)
		}
	}),
	setTagsSuccess: (state, { payload: { ids, topicTag } }: Actions["SetTagsSuccess"]): State => {
		const docIdsToUpdate = keyBy(ids);
		return {
			...state,
			loadingSetTagsCategoryDocument: {},
			documentsChecked: {},
			documentSources: map(state.documentSources, doc => {
				if (!(doc.id in docIdsToUpdate)) return doc;
				return {
					...doc,
					topic_tag: union(doc.topic_tag, topicTag)
				};
			})
		};
	},
	setTagsError: (state): State => ({
		...state,
		loadingSetTagsCategoryDocument: {},
		documentsChecked: {}
	}),
	automateTags: (state): State => ({
		...state,
		loadingSetTagsCategoryDocument: {
			...state.loadingSetTagsCategoryDocument
		}
	}),
	automateTagsError: (state): State => ({
		...state,
		loadingSetTagsCategoryDocument: {},
		documentsChecked: {}
	}),
	removeDocumentCategorySuccess: (state, { payload: { id } }: Actions["RemoveDocumentCategorySuccess"]): State => ({
		...state,
		documentSources: map(state.documentSources, doc =>
			doc.id === id ? { ...doc, category: null } : doc
		)
	}),
	removeDocumentTagSuccess: (state, { payload: { id, tagId } }: Actions["RemoveDocumentTagSuccess"]): State => ({
		...state,
		documentSources: map(state.documentSources, doc =>
			doc.id === id ? { ...doc, topic_tag: without(doc.topic_tag, tagId) } : doc
		)
	}),
	toggleAutomateTags: (state, { payload }: Actions["ToggleAutomateTags"]): State => ({
		...state,
		toggleAutomateTags: payload
	})
};

export const reducers = createReducer<State>(
	INITIAL_STATE,
	reducerHandlers,
	operators
);
