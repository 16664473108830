import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';

import { operators } from 'store/ui/topic/tag/details/actions';

import { getAllLanguages } from 'store/entities/Language/selectors';
import { getAllCountries } from 'store/entities/Country/selectors';
import { getAllCategories } from 'store/entities/Category/selectors';
import { getAllEvents } from 'store/entities/Event/selectors';
import { getAllOrderedByNameWithFeeds } from 'store/entities/Focus/selectors';
import uiSelectors from 'store/ui/topic/tag/details/selectors';

import TagDetails from './TagDetails';

const mapStateToProps = (state: State): StateProps => ({
	countries: getAllCountries(state),
	languages: getAllLanguages(state),
	categories: getAllCategories(state),
	events: getAllEvents(state),
	focus: getAllOrderedByNameWithFeeds(state),
	loading: uiSelectors.startStopIsLoading(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onUpdateTagAutomation: (tagId, tagAutomationId, enabled, definition) => {
		dispatch(operators.updateTagAutomation({ tagId, tagAutomationId, enabled, definition }));
	}
});
export default connect(mapStateToProps, mapDispatchToProps)(TagDetails);
