import { Action, actionCreatorFactory } from 'typescript-fsa';

import { FeedType } from 'class/Feed';
import { DocumentExists, InsertDocumentStep } from 'types/article/insert';
import { BulkDocument, DocumentObject } from 'class/Document';
import { InsertForm } from 'types/article/insert';
import * as form from './form';
import { State } from './reducers';

const actionCreator = actionCreatorFactory('@@article/insert');

type Payloads = {
	SetShowDialog: { show: boolean };
	SetStep: { step: InsertDocumentStep };
	SetType: { type: FeedType };
	SetIsBulkInsert: { isBulkInsert: number };
	ChangeStep: { step: InsertDocumentStep, form?: InsertForm };
	ChangeStepError: { error: object };
	SetSelectedFeedId: { id: string };
	SetSelectedFeed: { id: string, type: FeedType } | null;
	SetFile: { file: File | null };
	SetUrl: { url: string };
	CheckUrl: void;
	SetUrlError: { invalidError: string | false };
	SetFinalUrl: { url: string };
	SetSelectedUrl: { url: string };
	SetLoading: { loading: boolean };
	SetHistoricalDocuments: { historicalDocuments: DocumentObject[] };
	SetTenantDocuments: { tenantDocuments: DocumentObject[] };
	SetFeedDocuments: { feedDocuments: DocumentObject[] };
	SetDocumentNotFoundReason: { reason: string };
	ToggleCheckedDocument: { id: string };
	SetDocumentExists: { media: DocumentExists };
	GetBulkTemplate: { selectedFeeds: string[], type: FeedType }
	SetTemplateBlob: { templateBlob: Blob | null }
	SetBulkDocuments: {  };  // TODO GIS-3655
	SetBulkDocumentsError: {  }; // TODO GIS-3655
	SetBulkDocumentsSuccess: { documents: BulkDocument[], totalErrors: number, errorFileUrl: string, bulkInsertS3Path: string };  // TODO GIS-3655
	AddDocumentImage: { image: File, imageType: 'cover' | 'page', docIndex: number };
	AddDocumentImageSuccess: { imagePath: string, imageType: 'cover' | 'page', docIndex: number };
	RemoveDocumentImage: { imageType: 'cover' | 'page', docIndex: number };
	BulkImportDocuments: void;
	SetContinueInsert: boolean;
	ResetState: Partial<State>
}

export type Actions = {
	SetShowDialog: Action<Payloads["SetShowDialog"]>;
	SetStep: Action<Payloads["SetStep"]>;
	SetType: Action<Payloads["SetType"]>;
	SetFile: Action<Payloads["SetFile"]>;
	SetIsBulkInsert: Action<Payloads["SetIsBulkInsert"]>;
	ChangeStep: Action<Payloads["ChangeStep"]>;
	ChangeStepError: Action<Payloads["ChangeStepError"]>;
	SetSelectedFeedId: Action<Payloads["SetSelectedFeedId"]>;
	SetSelectedFeed: Action<Payloads["SetSelectedFeed"]>;
	SetUrl: Action<Payloads["SetUrl"]>;
	CheckUrl: Action<Payloads["CheckUrl"]>;
	SetUrlError: Action<Payloads["SetUrlError"]>;
	SetFinalUrl: Action<Payloads["SetFinalUrl"]>;
	SetSelectedUrl: Action<Payloads["SetSelectedUrl"]>;
	SetLoading: Action<Payloads["SetLoading"]>;
	SetHistoricalDocuments: Action<Payloads["SetHistoricalDocuments"]>;
	SetTenantDocuments: Action<Payloads["SetTenantDocuments"]>;
	SetFeedDocuments: Action<Payloads["SetFeedDocuments"]>;
	SetDocumentNotFoundReason: Action<Payloads["SetDocumentNotFoundReason"]>;
	ToggleCheckedDocument: Action<Payloads["ToggleCheckedDocument"]>;
	SetDocumentExists: Action<Payloads["SetDocumentExists"]>;
	GetBulkTemplate: Action<Payloads["GetBulkTemplate"]>;
	SetTemplateBlob: Action<Payloads["SetTemplateBlob"]>;
	SetBulkDocuments: Action<Payloads["SetBulkDocuments"]>;
	SetBulkDocumentsError: Action<Payloads["SetBulkDocumentsError"]>;
	SetBulkDocumentsSuccess: Action<Payloads["SetBulkDocumentsSuccess"]>;
	AddDocumentImage: Action<Payloads["AddDocumentImage"]>;
	AddDocumentImageSuccess: Action<Payloads["AddDocumentImageSuccess"]>;
	RemoveDocumentImage: Action<Payloads["RemoveDocumentImage"]>;
	BulkImportDocuments: Action<Payloads["BulkImportDocuments"]>;
	SetContinueInsert: Action<Payloads["SetContinueInsert"]>;
	ResetState: Action<Payloads["ResetState"]>;
}

export const operators = {
	...form.operators,
	setShowDialog: actionCreator<Payloads["SetShowDialog"]>('SET_SHOW_DIALOG'),
	setStep: actionCreator<Payloads["SetStep"]>('SET_STEP'),
	setType: actionCreator<Payloads["SetType"]>('SET_TYPE'),
	setIsBulkInsert: actionCreator<Payloads["SetIsBulkInsert"]>('SET_IS_BULK_INSERT'),
	changeStep: actionCreator<Payloads["ChangeStep"]>('CHANGE_STEP', { api: 'start' }),
	changeStepError: actionCreator<Payloads["ChangeStepError"]>('CHANGE_STEP_ERROR', { api: 'error' }),
	setSelectedFeedId: actionCreator<Payloads["SetSelectedFeedId"]>('SET_SELECTED_FEED_ID'),
	setSelectedFeed: actionCreator<Payloads["SetSelectedFeed"]>('SET_SELECTED_FEED'),
	setFile: actionCreator<Payloads["SetFile"]>('SET_FILE'),
	setUrl: actionCreator<Payloads["SetUrl"]>('SET_URL'),
	checkUrl: actionCreator<Payloads["CheckUrl"]>('CHECK_URL'),
	setUrlError: actionCreator<Payloads["SetUrlError"]>('SET_URL_ERROR'),
	setFinalUrl: actionCreator<Payloads["SetUrl"]>('SET_FINAL_URL'),
	setSelectedUrl: actionCreator<Payloads["SetSelectedUrl"]>('SET_SELECTED_URL'),
	setLoading: actionCreator<Payloads["SetLoading"]>('SET_LOADING'),
	setHistoricalDocuments: actionCreator<Payloads["SetHistoricalDocuments"]>('SET_HISTORICAL_DOCUMENTS'),
	setTenantDocuments: actionCreator<Payloads["SetTenantDocuments"]>('SET_TENANT_DOCUMENTS'),
	setFeedDocuments: actionCreator<Payloads["SetFeedDocuments"]>('SET_FEED_DOCUMENTS'),
	setDocumentNotFoundReason: actionCreator<Payloads["SetDocumentNotFoundReason"]>('SET_DOCUMENT_NOT_FOUND_REASON'),
	setSelectedDocumentId: actionCreator<Payloads["ToggleCheckedDocument"]>('TOGGLE_DOCUMENT_ID'),
	setDocumentExists: actionCreator<Payloads["SetDocumentExists"]>('SET_DOCUMENT_EXISTS'),
	getBulkTemplate: actionCreator<Payloads["GetBulkTemplate"]>("GET_BULK_TEMPLATE"),
	setTemplateBlob: actionCreator<Payloads["SetTemplateBlob"]>("SET_TEMPLATE_BLOB"),
	setBulkDocuments: actionCreator<Payloads["SetBulkDocuments"]>('SET_BULK_DOCUMENTS'),
	setBulkDocumentsError: actionCreator<Payloads["SetBulkDocumentsError"]>('SET_BULK_DOCUMENTS_ERROR'),
	setBulkDocumentsSuccess: actionCreator<Payloads["SetBulkDocumentsSuccess"]>('SET_BULK_DOCUMENTS_SUCCESS'),
	addDocumentImage: actionCreator<Payloads["AddDocumentImage"]>('ADD_DOCUMENT_IMAGE'),
	addDocumentImageSuccess: actionCreator<Payloads["AddDocumentImageSuccess"]>('ADD_DOCUMENT_IMAGE_SUCCESS'),
	removeDocumentImage: actionCreator<Payloads["RemoveDocumentImage"]>('REMOVE_DOCUMENT_IMAGE'),
	bulkImportDocuments: actionCreator<Payloads["BulkImportDocuments"]>('BULK_IMPORT_DOCUMENTS'),
	setContinueInsert: actionCreator<Payloads["SetContinueInsert"]>('SET_CONTINUE_INSERT'),
	resetState: actionCreator<Payloads["ResetState"]>('RESET_STATE')
};
