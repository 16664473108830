import ActionCreator from 'lib/store/actions';
import { PrintExpressionType, Brand, ProductType, Shop } from 'class/Feed';
import { BrandApiResponse } from 'types/focus/feed/definition/print/brands';
import { ShopApiResponse } from 'types/focus/feed/definition/print/shops';

const actionCreator = new ActionCreator('@@focus/feed/print');

type Expression = Brand | ProductType | Shop;
type FetchQuery = { query: string, limit: number };

type AddPrintExpression = { type: PrintExpressionType, expression: Expression };
const addPrintExpression = actionCreator.operator<AddPrintExpression>('ADD_PRINT_EXPRESSION');

type UpdatePrintExpression = { type: PrintExpressionType, index: number, expression: Expression };
const updatePrintExpression = actionCreator.operator<UpdatePrintExpression>('UPDATE_PRINT_EXPRESSION');

type RemovePrintExpression = { type: PrintExpressionType, index: number };
const removePrintExpression = actionCreator.operator<RemovePrintExpression>('REMOVE_PRINT_EXPRESSION');

export interface FetchBrandsQuery extends FetchQuery {
	excluded: {
		company?: string[]
		brand?: string[]
		line?: string[]
	}
}
const fetchBrands = actionCreator.operator<FetchBrandsQuery>('FETCH_BRANDS_DEBOUNCED');
const [fetchBrandsStart, fetchBrandsSuccess, fetchBrandsError] = actionCreator.apiOperators<void, BrandApiResponse[]>('FETCH_BRANDS');

export interface FetchShopsQuery extends FetchQuery {
	excluded: {
		shops?: string[]
	}
}
const fetchShops = actionCreator.operator<FetchShopsQuery>('FETCH_SHOPS_DEBOUNCED');
const [fetchShopsStart, fetchShopsSuccess, fetchShopsError] = actionCreator.apiOperators<void, ShopApiResponse[]>('FETCH_SHOPS');

const resetBrands = actionCreator.operator('RESET_BRANDS');
const resetShops = actionCreator.operator('RESET_SHOPS');

export const operators = {
	addPrintExpression,
	updatePrintExpression,
	removePrintExpression,
	fetchBrands, fetchBrandsStart, fetchBrandsSuccess, fetchBrandsError, resetBrands,
	fetchShops, fetchShopsStart, fetchShopsSuccess, fetchShopsError, resetShops
};

export type Actions = {
	AddPrintExpression: ReturnType<typeof operators.addPrintExpression>;
	RemovePrintExpression: ReturnType<typeof operators.removePrintExpression>;
	UpdatePrintExpression: ReturnType<typeof operators.updatePrintExpression>;
	FetchBrands: ReturnType<typeof operators.fetchBrands>;
	FetchBrandsSuccess: ReturnType<typeof operators.fetchBrandsSuccess>;
	FetchShops: ReturnType<typeof operators.fetchShops>;
	FetchShopsSuccess: ReturnType<typeof operators.fetchShopsSuccess>;
}
