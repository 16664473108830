import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import { operators } from 'store/ui/newsletter/emailList/edit';
import selectors from 'store/ui/newsletter/emailList/edit/selectors';
import { operators as notificationOperators } from 'store/app/notifications';
import NewsletterEmailListEdit from './EmailListEdit';

const mapStateToProps = (state: State): StateProps => ({
	list: selectors.getEmailList(state)!
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onAddNotification: notification => dispatch(notificationOperators.add({ notification })),
	onResetEditList: () => dispatch(operators.resetEditList()),
	onEditListName: name => dispatch(operators.renameEmailList({ name })),
	onAddNewEmailAddress: email => dispatch(operators.addNewEmailAddress({ email })),
	onDeleteEmailAddress: email => dispatch(operators.deleteEmailAddress({ email })),
	onRenameEmailAddress: (oldEmail, newEmail) => dispatch(operators.renameEmailAddress({ oldEmail, newEmail }))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterEmailListEdit);
