import React from 'react';
import moment from 'moment';
import { withT } from 'lib/i18n';
import { Button } from 'lib/imports/react-md';
import { cloneDeep, find, get, isEmpty, map, isEqual, every } from 'lib/imports/lodash';

import { LinkedBrand } from 'class/Feed';
import generateDataTree from './generateLayoutData';
import { NewsletterObject, SendConfiguration } from 'store/entities/Newsletter';
import { EmailListObject } from 'store/entities/EmailList';
import GA from 'lib/googleAnalytics';
import { useAssignReducer } from 'lib/hooks';
import { isValidEmail } from 'lib/validate';
import featureFlags from 'lib/featureFlags';
import { UserObject } from 'store/entities/User';
import { ComponentProps, NewsletterForm, NewsletterInputErrors } from './types';
import { UpdateLayoutParams } from "./Layout/types";
import NewsletterEditFormRecipients from './Recipients';
import NewsletterEditFormFeeds from './Feeds';
import NewsletterEditFormLayout from './Layout';
import NewsletterEditFormPrintCriteria from './PrintCriteria';
import NewsletterEditFormSort from './Sort';
import NewsletterEditFormPreferences from './Preferences';
import NewsletterEditFormSetup from './Setup/NewsletterEditFormSetup';
import NewsletterEditFormEnable from './Enable/NewsletterEditFormEnable';
import NewsletterEditFormType from './Type/NewsletterEditFormType';
import NewsletterEditFormDeliveryMode from './DeliveryMode/NewsletterEditFormDeliveryMode';
import NewsletterEditFormLogo from './Logo/NewsletterEditFormLogo';
import NewsletterEditFormFrequency from './Frequency/NewsletterEditFormFrequency';
import NewsletterEditFormTime from './Time';
import NewsletterEditFormEmailing from './Emailing/NewsletterEditFormEmailing';

import './NewsletterEditForm.scss';

// TODO: When all newsletter have layout config, this can be included in emptyNewsletter
// and all its uses removed
const defaultLayout = {
	layout: [],
	layout_settings: {
		channel: {order: 0, enabled: true},
		focus: {order: 1, enabled: false},
		feed: {order: 2, enabled: true}
	}
};

const getEmptyNewsletter = (user: UserObject): NewsletterForm => {
	let language = user.settings.language_code;
	if (!language.match(/^(en|es|fr)$/)) language = 'en';
	const emptyNewsLetter: NewsletterForm = {
		name: "",
		description: "",
		send_to: "",
		subject: "",
		enabled: false,
		type: "full",
		emails: [],
		emailLists: [],
		send_configuration_json: {
			language: language as NewsletterObject["send_configuration_json"]["language"],
			timezone: get(user, 'settings.timezone') || moment.tz.guess(),
			frequency: 'daily',
			daily_schedule: {
				times: [{
					hour: "09",
					minute: "00"
				}],
				only_work_days: false
			},
			weekly_schedule: {
				time: {
					hour: "09",
					minute: "00"
				},
				day: "monday"
			},
			reply_to: '',
			sender_name: '',
			sort: 'reach',
			filters: {
				country: []
			},
			facebook_url: '',
			delivery_mode: 'direct',
			brand_id: ''
		}
	};
	if (featureFlags.isEnabled('newsletter-split')) {
		emptyNewsLetter.send_configuration_json.layout = defaultLayout.layout;
		emptyNewsLetter.send_configuration_json.layout_settings = defaultLayout.layout_settings;
	}
	return emptyNewsLetter;
};

const purgeLayout = (layout: any) => {
	const acceptedProperties = ['children', 'id', 'type'];
	layout.forEach((entry: { [x: string]: any; }) => {
		Object.keys(entry).forEach(key => {
			if (!acceptedProperties.includes(key)) {
				delete entry[key];
			} else {
				if (key === 'children') entry.children = purgeLayout(entry.children);
			}
		});
	});
	return layout;
};

const newsletterFormReducer = (state: NewsletterForm, update: Partial<NewsletterForm>) => ({ ...state, ...update });

const NewsletterEditForm = (props: ComponentProps) => {
	const { t, newsletterId, newsletter, feeds, user, focusList } = props;
	const { onAddNotification, push, onCreateNewsletter, onUpdateNewsletter } = props;

	const [errors, dispatchErrors] = useAssignReducer<NewsletterInputErrors>({});
	const [formFeedIds, setFormFeedIds] = React.useState<string[]>(map(feeds, 'id'));
	const [formEdited, setFormEdited] = React.useState(false);
	const [formSave, setFormSave] = React.useState(false);
	// const [formNewsletter, dispatchFormNewsletter] = React.useState(newsletter ? cloneDeep(newsletter) : getEmptyNewsletter(user));
	const [formNewsletter, dispatchFormNewsletter] = React.useReducer(
		newsletterFormReducer, undefined, () => newsletter ? cloneDeep(newsletter) : getEmptyNewsletter(user)
	);

	React.useEffect(() => {
		if (!formNewsletter) return;
		if (!newsletter) return setFormEdited(true);
		const existingForm = cloneDeep(formNewsletter);
		const newFormData = cloneDeep(newsletter);
		if (existingForm.send_configuration_json.layout) existingForm.send_configuration_json.layout = purgeLayout(existingForm.send_configuration_json.layout);
		setFormEdited(!isEqual(existingForm, newFormData));
	}, [formNewsletter, newsletter]);

	const setSendConfiguration = React.useCallback((update: Partial<SendConfiguration>) => {
		if (!featureFlags.isEnabled('newsletter-split')) {
			delete update.layout;
			delete update.layout_settings;
		}
		dispatchFormNewsletter({send_configuration_json: { ...formNewsletter.send_configuration_json, ...update }});
	}, [formNewsletter, dispatchFormNewsletter]);

	const onUpdateLayout = React.useCallback(({
		layoutData = formNewsletter.send_configuration_json.layout || defaultLayout.layout,
		layoutSettings = formNewsletter.send_configuration_json.layout_settings || defaultLayout.layout_settings,
		feedIds = formFeedIds
	}: UpdateLayoutParams) => {
		const layout = generateDataTree(layoutData, layoutSettings, feedIds, focusList);
		setSendConfiguration({layout, layout_settings: layoutSettings});
	}, [formFeedIds, formNewsletter, focusList, setSendConfiguration]);

	React.useEffect(() => {
		if (newsletterId && !newsletter) {
			onAddNotification({
				level: "warning",
				text: `${t('error.api_alert_prefix')}: ${t('error.newsletter_not_exists')}`
			});
			push("/newsletter");
		}

		if (formNewsletter) {
			const layoutData = formNewsletter.send_configuration_json.layout;
			const layoutSettings = formNewsletter.send_configuration_json.layout_settings;
			if (layoutData && layoutSettings) onUpdateLayout({layoutData: layoutData, layoutSettings: layoutSettings});
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		if (formSave) {
			GA.trackEvent({ category: "Newsletter form", action: "Save" });
			if (validateForm()) {
				const validatedForm = { ...formNewsletter, feeds: formFeedIds, tags: []};
				if (newsletterId) onUpdateNewsletter(validatedForm);
				else onCreateNewsletter(validatedForm);
			}
			setFormSave(false);
		}
	}, [formSave]); // eslint-disable-line react-hooks/exhaustive-deps

	const validateForm = React.useCallback(() => {
		const newErrors: NewsletterInputErrors = {};

		if (!formNewsletter.name) newErrors.name = t('newsletter.form.input.name.missing');

		if (formNewsletter.enabled) {
			if (isEmpty(formFeedIds)) newErrors.feeds = t('newsletter.form.input.feeds.missing');
			if (isEmpty(formNewsletter.emailLists) && isEmpty(formNewsletter.emails)) newErrors.emails = t('newsletter.form.input.email_lists.missing');

			if (!formNewsletter.subject) newErrors.subject = t('newsletter.form.input.subject.missing');

			if (formNewsletter.send_configuration_json.reply_to && !isValidEmail(formNewsletter.send_configuration_json.reply_to)) newErrors.reply_to = t('newsletter.form.input.reply_to.invalid');

			if (formNewsletter.send_configuration_json.delivery_mode === 'supervised') {
				if (!formNewsletter.send_to) newErrors.editorEmail = t('newsletter.form.input.editor_email.missing');
				else if (find(formNewsletter.send_to.split(','), email => !isValidEmail(email))) newErrors.editorEmail = t('newsletter.form.input.editor_email.invalid');
			}
		}

		const isValid = isEmpty(newErrors);
		if (!isValid) onAddNotification({ level: "warning", t: "newsletter.form.missing_required_fields" });
		dispatchErrors(newErrors);

		return isValid;
	}, [t, formNewsletter, onAddNotification, dispatchErrors, formFeedIds]);

	const updateName = React.useCallback((name: string) => {
		dispatchErrors({ name: undefined });
		dispatchFormNewsletter({ name });
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const onChangeFocusFeed = React.useCallback((feedsIds: string[]) => {
		dispatchErrors({ feeds: undefined });
		setFormEdited(true);
		setFormFeedIds(feedsIds);
		onUpdateLayout({feedIds: feedsIds});
	}, [dispatchErrors, setFormEdited, setFormFeedIds, onUpdateLayout]);

	const onChangeLinkedBrand = React.useCallback((brand: LinkedBrand) => {
		setFormEdited(true);
		setFormFeedIds([]);
		setSendConfiguration({brand_id: brand.id, ...defaultLayout});
	}, [setFormFeedIds, setSendConfiguration]);

	const nameSection = React.useMemo(() => (
		<NewsletterEditFormSetup
			name={formNewsletter.name}
			description={formNewsletter.description}
			nameError={errors.name}
			onNameUpdated={updateName}
			onDescriptionUpdated={description => dispatchFormNewsletter({ description })}
		/>
	), [errors.name, formNewsletter.name, formNewsletter.description, updateName]);

	const enableSection = React.useMemo(() => (
		<NewsletterEditFormEnable
			enabled={formNewsletter.enabled}
			onEnabledUpdated={enabled => dispatchFormNewsletter({ enabled })}
		/>
	), [formNewsletter.enabled, dispatchFormNewsletter]);

	const typeSection = React.useMemo(() => (
		<NewsletterEditFormType
			type={formNewsletter.type}
			onTypeUpdated={type => {
				GA.trackEvent({ category: 'Newsletter form', action: 'Design Type', label: t(`newsletter.form.input.type.${type}`, { lng: "en" }) });
				dispatchFormNewsletter({ ...formNewsletter, type });
				if (type === "full") {
					setSendConfiguration({ delivery_mode: "direct" });
					onUpdateLayout({feedIds: formFeedIds});
				}
			}}
		/>
	), [formNewsletter, t, setSendConfiguration, onUpdateLayout, formFeedIds]);

	const deliveryModeSection = React.useMemo(() => (
		<NewsletterEditFormDeliveryMode
			type={formNewsletter.type}
			deliveryMode={formNewsletter.send_configuration_json.delivery_mode}
			sendTo={formNewsletter.send_to}
			editorEmailError={errors.editorEmail}
			onDeliveryModeUpdated={deliveryMode => {
				GA.trackEvent({ category: 'Newsletter form', action: 'Delivery Mode', label: t(`newsletter.form.delivery_mode.${deliveryMode}`, { lng: "en" }) });
				setSendConfiguration({ delivery_mode: deliveryMode });
			}}
			onSendToUpdated={send_to => {
				const allEmailsAreValid = every(send_to.split(','), email => isValidEmail(email.trim())) || isValidEmail(send_to.trim());
				if (allEmailsAreValid) {
					dispatchErrors({ editorEmail: undefined });
					dispatchFormNewsletter({send_to });
				} else {
					dispatchErrors({ editorEmail: t('newsletter.form.input.editor_email.invalid') });
				}
			}}
		/>
	), [t, errors.editorEmail, formNewsletter.type, formNewsletter.send_configuration_json.delivery_mode, formNewsletter.send_to, setSendConfiguration, dispatchErrors]);

	const feedsSection = React.useMemo(() => {
		return (
			<NewsletterEditFormFeeds
				selectedFeedIds={formFeedIds}
				linkedBrandId={formNewsletter.send_configuration_json.brand_id}
				onChangeLinkedBrand={onChangeLinkedBrand}
				error={errors.feeds}
				onChange={onChangeFocusFeed}
			/>
		);
	}, [onChangeFocusFeed, onChangeLinkedBrand, formNewsletter, errors.feeds, formFeedIds]);

	const layoutSection = React.useMemo(() => {
		if (formNewsletter.type !== 'full' || !featureFlags.isEnabled('newsletter-split')) return <></>;

		const layoutData = formNewsletter.send_configuration_json.layout || defaultLayout.layout;
		const layoutSettings = formNewsletter.send_configuration_json.layout_settings || defaultLayout.layout_settings;
		return (
			<NewsletterEditFormLayout
				layoutData={layoutData}
				layoutSettings={layoutSettings}
				onUpdateLayout={onUpdateLayout}
			/>
		);
	}, [onUpdateLayout, formNewsletter]);

	const printCriteriaSection = React.useMemo(() => (
		<NewsletterEditFormPrintCriteria
			feedIds={formFeedIds}
			printCriteria={formNewsletter.send_configuration_json.print_criteria}
			onPrintCriteriaUpdated={print_criteria => setSendConfiguration({ print_criteria })}
		/>
	), [formNewsletter.send_configuration_json.print_criteria, formFeedIds, setSendConfiguration]);

	const sortSection = React.useMemo(() => (
		<NewsletterEditFormSort
			sort={formNewsletter.send_configuration_json.sort}
			onChange={sort => setSendConfiguration({ sort })}
		/>
	), [formNewsletter.send_configuration_json.sort, setSendConfiguration]);

	const preferencesSection = React.useMemo(() => (
		<NewsletterEditFormPreferences
			language={formNewsletter.send_configuration_json.language}
			timezone={formNewsletter.send_configuration_json.timezone}
			onLanguageUpdated={language => setSendConfiguration({ language })}
			onTimezoneUpdated={timezone => setSendConfiguration({ timezone })}
		/>
	), [formNewsletter.send_configuration_json.language, formNewsletter.send_configuration_json.timezone, setSendConfiguration]);

	const logoSection = React.useMemo(() => (
		<NewsletterEditFormLogo
			facebookUrl={formNewsletter.send_configuration_json.facebook_url}
			onFacebookUrlUpdated={urls => {
				const facebook_url = get(urls, "pageUrl") || "";
				if (formNewsletter.send_configuration_json.facebook_url !== facebook_url) setSendConfiguration({ facebook_url });
			}}
		/>
	), [formNewsletter.send_configuration_json.facebook_url, setSendConfiguration]);

	const frequencySection = React.useMemo(() => (
		<NewsletterEditFormFrequency
			frequency={formNewsletter.send_configuration_json.frequency}
			weekly_schedule={formNewsletter.send_configuration_json.weekly_schedule}
			daily_schedule={formNewsletter.send_configuration_json.daily_schedule}
			onUpdated={payload => {
				if (payload.frequency) {
					GA.trackEvent({ category: 'Newsletter form', action: 'Frequency', label: t(`newsletter.form.input.frequency.${payload.frequency}`, { lng: "en" }) });
				}
				setSendConfiguration(payload);
			}}
		/>
	), [t, formNewsletter.send_configuration_json.frequency, formNewsletter.send_configuration_json.weekly_schedule, formNewsletter.send_configuration_json.daily_schedule, setSendConfiguration]);

	const timeSection = React.useMemo(() => (
		<NewsletterEditFormTime
			weeklyTime={formNewsletter.send_configuration_json.weekly_schedule.time}
			weeklyDay={formNewsletter.send_configuration_json.weekly_schedule.day}
			dailyTimes={formNewsletter.send_configuration_json.daily_schedule.times}
			frequency={formNewsletter.send_configuration_json.frequency}
			onlyWorkingDays={formNewsletter.send_configuration_json.daily_schedule.only_work_days}
			onWeeklyTimeUpdated={time => setSendConfiguration({ weekly_schedule: { ...formNewsletter.send_configuration_json.weekly_schedule, time } })}
			onDailyTimesUpdated={times => setSendConfiguration({ daily_schedule: { ...formNewsletter.send_configuration_json.daily_schedule, times } })}
		/>
	), [setSendConfiguration, formNewsletter.send_configuration_json.frequency, formNewsletter.send_configuration_json.daily_schedule, formNewsletter.send_configuration_json.weekly_schedule]);

	const emailingSection = React.useMemo(() => (
		<NewsletterEditFormEmailing
			subject={formNewsletter.subject}
			replyTo={formNewsletter.send_configuration_json.reply_to}
			senderName={formNewsletter.send_configuration_json.sender_name}
			subjectError={errors.subject}
			replyToError={errors.reply_to}
			onSubjectUpdated={subject => {
				dispatchErrors({ subject: undefined });
				dispatchFormNewsletter({ subject });
			}}
			onReplyToUpdated={replyTo => {
				if (replyTo && !isValidEmail(replyTo)) {
					dispatchErrors({ reply_to: t('newsletter.form.input.reply_to.invalid') });
				} else {
					dispatchErrors({ reply_to: undefined });
					setSendConfiguration({ reply_to: replyTo });
				}
			}}
			onSenderNameUpdated={senderName => setSendConfiguration({ sender_name: senderName })}
		/>
	), [
		formNewsletter.subject, formNewsletter.send_configuration_json.reply_to, formNewsletter.send_configuration_json.sender_name,
		errors.subject, errors.reply_to, dispatchErrors, setSendConfiguration, t
	]);

	const recipientsSection = React.useMemo(() => (
		<NewsletterEditFormRecipients
			emails={formNewsletter.emails!}
			emailListsIds={map(formNewsletter.emailLists, 'id')}
			onEmailsUpdated={(emails: string[]) => {
				dispatchFormNewsletter({ emails });
				dispatchErrors({ emails: undefined });
			}}
			onEmailListsUpdated={(emailLists: EmailListObject[]) => {
				dispatchFormNewsletter({ emailLists });
				dispatchErrors({ emails: undefined });
			}}
			error={errors.emails} />
	), [errors.emails, formNewsletter.emails, formNewsletter.emailLists, dispatchFormNewsletter, dispatchErrors]);

	const buttonsSection = React.useMemo(() => (
		<div id="newsletterEditSectionButtons" className="form-section buttons-section">
			<div className="form-section-content">
				<div className="form-section-title"></div>
				<div className="form-section-content-column">
					<Button
						id="newsletterEditCancelBtn"
						className='form-btn cancel-btn'
						flat
						onClick={() => {
							GA.trackEvent({ category: "Newsletter form", action: "Cancel" });
							push('/newsletter');
						}}
					>
						{t('newsletter.form.button.cancel')}
					</Button>
				</div>
				<div className="form-section-content-column">
					<Button
						id="newsletterEditSaveBtn"
						className='form-btn save-btn'
						flat
						onClick={() => setFormSave(true)}
						disabled={!formEdited}
					>
						{t('newsletter.form.button.save')}
					</Button>
				</div>
			</div>
		</div>
	), [t, push, formEdited]);

	return (
		<div id="newsletterEditForm">
			{nameSection}
			{enableSection}
			{typeSection}
			{deliveryModeSection}
			{feedsSection}
			{layoutSection}
			{printCriteriaSection}
			{sortSection}
			{preferencesSection}
			{logoSection}
			{frequencySection}
			{timeSection}
			{emailingSection}
			{recipientsSection}
			{buttonsSection}
		</div>
	);
};

export default withT(React.memo(NewsletterEditForm));
