import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import { operators } from 'store/article/insert';
import ArticleInsertSelectFeed from './ArticleInsertSelectFeed';

const mapStateToProps = ({ focus, article }: State): StateProps => ({
	focusList: focus.list.focusList,
	selectedFeed: article.insert.selectedFeed,
	url: article.insert.url,
	invalidUrlError: article.insert.invalidUrlError,
	loading: article.insert.loading,
	type: article.insert.type
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetSelectedFeedId: id => dispatch(operators.setSelectedFeedId({ id })),
	onSetUrl: url => dispatch(operators.setUrl({ url })),
	onCheckUrl: () => dispatch(operators.checkUrl()),
	onChangeStep: step => dispatch(operators.changeStep({ step }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleInsertSelectFeed);
