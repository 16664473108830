import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { operators as notificationOperators } from 'store/app/notifications';
import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import FocusDashboardLogoUpload from './FocusDashboardLogoUpload';

const mapStateToProps = ({ focus: { dashboard } }: State): StateProps => ({
	focus: dashboard.focus!,
	imageMeta: dashboard.logo.imageMeta
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onAddNotification: notification => dispatch(notificationOperators.add({ notification }))
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusDashboardLogoUpload);
