import React from 'react';

import { withT } from 'lib/i18n';
import SelectCategory from './SelectCategory';
import TopicTag from './TopicTag';
import Selected from './TopicTag/Selected/Selected';
import { ComponentProps } from './types';
import { concat } from 'lodash';

import './Categorization.scss';

const Categorization: React.FC<ComponentProps> = props => {
	const { tags, suggestedTags, hideOldCategories = false, hideNewCategories = false } = props;
	const { onAddTags, onRemoveTag, onSetCategory, t } = props;

	const [suggestedLoaded, setSuggestedLoaded] = React.useState<boolean>(false);

	if (suggestedTags && !suggestedLoaded) {
		tags.tagIds = Array.from(new Set(concat(tags.tagIds, suggestedTags)));
		setSuggestedLoaded(true);
	}

	const [categories, setCategories] = React.useState<string[]>([]);

	const categoryContent = React.useMemo(() => {
		return (
			<SelectCategory
				categories={categories}
				onAddCategory={category => {
					setCategories([category]);
					onSetCategory(category);
				}}
			/>
		);
	}, [categories, onSetCategory]);

	const newCategoryContent = React.useMemo(() => {
		return (
			<SelectCategory
				categories={categories}
				showNewlabel={true}
				onAddCategory={category => {
					setCategories([category]);
					onSetCategory(category);
				}}
			/>
		);
	}, [categories, onSetCategory]);

	const tagContent = React.useMemo(() => {
		return (
			<TopicTag
				selectedTags={tags}
				onAddTags={onAddTags}
			/>
		);
	}, [tags, onAddTags]);

	const selectedTags = React.useMemo(() => {
		return (
			<Selected
				selectedTags={tags}
				onRemoveTag={onRemoveTag}
			/>
		);
	}, [tags, onRemoveTag]);

	const categorizationTitle = React.useMemo(() => {
		if (hideOldCategories && hideNewCategories) return null;
		return <div className="confirm-info categorization-category-label">
			{t('results.bulk_action.categorize')}
		</div>;
	}, [hideOldCategories, hideNewCategories, t]);

	return (
		<div className="categorization">
			{categorizationTitle}
			{!hideOldCategories ? categoryContent : null}
			{!hideNewCategories ? newCategoryContent : null}
			{tagContent}
			{selectedTags}
		</div>
	);
};

export default withT(React.memo(Categorization));
