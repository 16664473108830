import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import Media from './Media';
import { State } from 'store/types';
import { StateProps, DispatchProps } from './types';

import { operators as filtersOperators } from 'store/search/filters';
import { operators as facetOperators } from 'store/search/facets';
import { operators as horizontalFiltersOperators } from 'store/search/horizontalFilters';
import { FilterFacet } from 'class/Filter';
import { Facet } from 'class/Facet';

const mediaGroupKey = Facet.mediaGroupKey;

const mapStateToProps = (state: State): StateProps => ({
	excluded: state.search.horizontalFilters.excludedFields.includes(mediaGroupKey),
	facetFilters: state.search.filters.facetsGroups,
	queriedMedia: state.search.horizontalFilters.queriedMedia,
	selectedMedia: state.search.filters.facetsGroups[mediaGroupKey]
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetExcludeField: (set: boolean) => dispatch(horizontalFiltersOperators.setExcludeField({ field: mediaGroupKey, set })),
	onSetFacetGroupFilters: (items: FilterFacet[]) => dispatch(filtersOperators.setFacetGroupFilters({
		groupKey: mediaGroupKey,
		groupFacetsFiltered: items,
		doSearch: false
	})),
	onFetchMediaFacetsGroup: () => dispatch(facetOperators.fetchGroupAPIFacets({ groupKey: mediaGroupKey })),
	onMediaInputChange: (input: string) => dispatch(horizontalFiltersOperators.fetchQueriedMedias({ query: input })),
	onResetMediaList: () => dispatch(horizontalFiltersOperators.resetQueriedMedia())
});

export default connect(mapStateToProps, mapDispatchToProps)(Media);
