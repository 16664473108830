import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import selectors from 'store/ui/report/setup/selectors';
import { DispatchProps, StateProps } from './types';

import ReportSetupSettings from './ReportSetupSettings';

const mapStateToProps = (state: State): StateProps => ({
	step: selectors.getStep(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSetupSettings);
