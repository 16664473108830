import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { operators } from 'store/article/insert';
import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import ArticleInsertBulk from './ArticleInsertBulk';

const mapStateToProps = ({ article, focus }: State): StateProps => ({
	focusList: focus.list.focusList,
	type: article.insert.type,
	file: article.insert.file,
	templateBlob: article.insert.templateBlob
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetTemplateBlob: templateBlob => dispatch(operators.setTemplateBlob({ templateBlob })),
	onSetFile: file => dispatch(operators.setFile({ file })),
	onGetTemplate: (selectedFeeds, type) => dispatch(operators.getBulkTemplate({ selectedFeeds, type }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleInsertBulk);
