import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { operators } from './actions';

export type State = {
	loading: boolean
};

export const INITIAL_STATE: State = {
	loading: false
};

const reducerHandlers: ReducerHandlers<State> = {
	createTopic: state => ({ ...state, loading: true }),
	createTopicSuccess: state => ({ ...state, loading: false }),
	createTopicError: state => ({ ...state, loading: false })
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
