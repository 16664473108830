import { Dispatch } from 'redux';
import { State } from 'store/types';
import { connect } from 'react-redux';
import AuditPaging from './AuditPaging';
import { operators} from 'store/ui/audit/paging';
import { StateProps, DispatchProps } from './types';
import selectors from 'store/ui/audit/paging/selectors';

const mapStateToProps = (state: State): StateProps => ({
	isLoadMoreVisible: selectors.getIsLoadMoreVisible(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onNextPage: () => dispatch(operators.auditNextPage())
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditPaging);