import { Dispatch } from 'redux';
import { State } from "store/types";
import AuditList from "./AuditList";
import { connect } from "react-redux";
import {operators} from 'store/ui/audit/list';
import selectors from "store/ui/audit/list/selectors";
import { getBrandName } from 'store/entities/Brand/selectors';

import { StateProps, DispatchProps, Order } from "./types";

const mapStateToProps = (state: State): StateProps => ({
	audits: selectors.getAudits(state),
	auditLoaded: selectors.getAuditsLoaded(state),
	getBrandName: (id: string) => getBrandName(state, id)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	applySort: (field: string, order: Order) => dispatch(operators.applySort({
		field,
		order
	})),
	onResubscribe: (id: string, email: string) => dispatch(operators.resubscribe({id, email}))
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditList);
