import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/ui/report/actions';
import selectors from 'store/ui/report/selectors';
import FocusSelectors from 'store/entities/Focus/selectors';
import { DispatchProps, StateProps } from './types';

import ReportLayout from './ReportLayout';

const mapStateToProps = (state: State): StateProps => ({
	focusId: selectors.getFocusId(state),
	focusExists: selectors.getFocusId(state) ? !!FocusSelectors.get(state, selectors.getFocusId(state)!) : false
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onFocusChanged: focusId => dispatch(operators.changeFocus(focusId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportLayout);
