import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import ChannelType from './ChannelType';
import { State } from 'store/types';
import { StateProps, DispatchProps } from './types';

import { operators as horizontalFiltersOperators } from 'store/search/horizontalFilters';
import { operators as filtersOperators } from 'store/search/filters';
import { operators as facetOperators } from 'store/search/facets';
import { FilterFacet } from 'class/Filter';
import { Facet } from 'class/Facet';

const channelTypeGroupKey = Facet.channelGroupKey;

const mapStateToProps = (state: State): StateProps => ({
	excluded: state.search.horizontalFilters.excludedFields.includes(channelTypeGroupKey),
	facetFilters: state.search.filters.facetsGroups
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetExcludeField: (set: boolean) => dispatch(horizontalFiltersOperators.setExcludeField({ field: channelTypeGroupKey, set })),
	onSetFacetGroupFilters: (items: FilterFacet[]) => dispatch(filtersOperators.setFacetGroupFilters({
		groupKey: channelTypeGroupKey,
		groupFacetsFiltered: items,
		doSearch: false
	})),
	onFetchChannelFacetsGroup: () => dispatch(facetOperators.fetchGroupAPIFacets({ groupKey: channelTypeGroupKey }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelType);
