import React from 'react';
import { ComponentProps } from './types';
import { DateFactory } from 'lib/dateFactory';

import './DateRange.scss';
import moment from 'moment-timezone';
import { PresetOption, Ranges } from 'components/common/DateFilter/types';
import DateFilter from 'components/common/DateFilter';

export const DateRange = ({ startDate, endDate, user, isOpen, onToggleOpen, onDatesChange }: ComponentProps) => {
	const lastDay = new Date('2017-03-01');
	const maxDate = moment().add(90, 'days').toDate();

	const presetOptions: PresetOption[] = [
		{
			label: 'Last week',
			range: () => DateFactory.getPastDateRangeFor('week')
		},
		{
			label: 'Last month',
			range: () => DateFactory.getPastDateRangeFor('month')
		},
		{
			label: 'Last 3 months',
			range: () => DateFactory.getPastDateRangeFor('month', 3)
		},
		{
			label: 'Next 15 days',
			range: () => DateFactory.getFutureDateRangeFor('day', 15)
		}
	];

	const dateRanges: Ranges = {
		selectedDateRange: {
			key: 'selectedDateRange',
			endDate: endDate,
			startDate: startDate
		}
	};

	const onDatesChanged = (ranges: Ranges) => {
		const startDate = moment(ranges.selectedDateRange.startDate).startOf('day');
		const endDate = moment(ranges.selectedDateRange.endDate).endOf('day');
		onDatesChange(moment(startDate).toDate(), endDate.toDate());
	};

	return (
		<div className="filter-date-range-picker-wrapper">
			<DateFilter
				isOpen={isOpen}
				onToggleOpen={onToggleOpen}
				options={[]}
				ranges={dateRanges}
				isCompareModeActive={false}
				fullDisplay={true}
				maxAvailableDate={maxDate}
				firstAvailableDate={lastDay}
				presetOptions={presetOptions}
				onDatesChange={onDatesChanged}
				defaultRangeKey="selectedDateRange"
				editableDateInputs={true}
				dateFormat={user.settings.locale === 'en-US' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
			/>
		</div>
	);
};
