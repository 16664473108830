import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { operators } from 'store/focus/dashboard';
import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import FocusDashboardDialog from './FocusDashboardDialog';

const mapStateToProps = ({ focus: { dashboard } }: State): StateProps => ({
	focus: dashboard.focus!
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onRenameFocus: name => dispatch(operators.renameFocus({ name })),
	onRemoveFocus: () => dispatch(operators.removeFocus()),
	onRemoveFeed: id => dispatch(operators.removeFeed({ id }))
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusDashboardDialog);
