import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import { operators } from 'store/article/insert';
import ArticleInsert from './ArticleInsert';

const mapStateToProps = ({ article }: State): StateProps => ({
	step: article.insert.step,
	selectedFeed: article.insert.selectedFeed,
	url: article.insert.url,
	invalidUrlError: article.insert.invalidUrlError,
	type: article.insert.type,
	file: article.insert.file,
	isBulkInsert: article.insert.isBulkInsert
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetShowInsertDocument: show => dispatch(operators.setShowDialog({ show })),
	onSetUrl: url => dispatch(operators.setUrl({ url })),
	onSetSelectedFeed: feed => dispatch(operators.setSelectedFeed(feed)),
	onChangeStep: (step, form) => dispatch(operators.changeStep({ step, form })),
	onSetInvalidUrl: err => dispatch(operators.setUrlError(err)),
	onSetIsBulkInsert: isBulkInsert => dispatch(operators.setIsBulkInsert({ isBulkInsert })),
	onSetContinueInsert: continueInsert => dispatch(operators.setContinueInsert(continueInsert))
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleInsert);
