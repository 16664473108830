import { connect } from 'react-redux';
import { isArray, every } from 'lib/imports/lodash';

import { State } from 'store/types';
import { StateProps, OwnProps } from './types';
import selectors from 'store/fletcher/selectors';
import FletcherLoader from './FletcherLoader';

const mapStateToProps = (state: State, { resource }: OwnProps): StateProps => ({
	isFetched: every(isArray(resource) ? resource : [resource], resource => selectors.isFetched(state, resource))
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FletcherLoader);
