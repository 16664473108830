import ActionCreators from 'lib/store/actions';

import { Session } from 'types/session';

const actions = new ActionCreators('@@app/session');

const updateSession = actions.operator<Session>('UPDATE_SESSION');
const sessionReady = actions.operator('SESSION_READY');

export const operators = {
	updateSession,
	sessionReady
};

export type Actions = {
	UpdateSession: ReturnType<typeof updateSession>;
}
