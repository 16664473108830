import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import { operators as listOperators } from 'store/ui/newsletter/emailList/list';
import { operators as editOperators } from 'store/ui/newsletter/emailList/edit';
import { operators as notificationOperators } from 'store/app/notifications';
import selectors from 'store/ui/newsletter/emailList/list/selectors';
import NewsletterEmailLists from './EmailLists';

const mapStateToProps = (state: State): StateProps => ({
	lists: selectors.getEmailLists(state),
	loadingLists: selectors.isLoading(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSelectList: id => dispatch(editOperators.setEditList({ id })),
	onCreateEmailList: name => dispatch(listOperators.createEmailList({ name })),
	onDeleteEmailList: listId => dispatch(listOperators.deleteEmailList({ listId })),
	onAddNotification: notification => dispatch(notificationOperators.add({ notification }))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterEmailLists);
