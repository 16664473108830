import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps, OwnProps } from './types';
import { operators as managerOperators } from 'store/ui/topic/tag/manager';
import { operators as detailsOperators } from 'store/ui/topic/tag/details';
import uiSelectors from 'store/ui/topic/tag/manager/selectors';
import topicEntitySelectors from 'store/entities/Topic/selectors';
import TopicTagManager from './TopicTagManager';

import tagDetailsSelectors from 'store/ui/topic/tag/details/selectors';
import { getAllLanguages } from 'store/entities/Language/selectors';
import { getAllCountries } from 'store/entities/Country/selectors';
import { getAllCategories } from 'store/entities/Category/selectors';
import { getAllEvents } from 'store/entities/Event/selectors';

const mapStateToProps = (state: State, { topicId }: OwnProps): StateProps => ({
	tags: topicId ? topicEntitySelectors.getTagsWithTagAutomation(state, topicId) || [] : [],
	selectedTag: tagDetailsSelectors.getSelectedTag(state),
	topic: topicId ? topicEntitySelectors.get(state, topicId) : null,
	isCreatingTag: uiSelectors.isLoading(state),
	countries: getAllCountries(state),
	languages: getAllLanguages(state),
	categories: getAllCategories(state),
	events: getAllEvents(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onCreateTag: (name, topicId) => dispatch(managerOperators.createTag({ name, topicId })),
	onRenameTag: (id, oldName, name) => dispatch(managerOperators.renameTag({ id, oldName, name })),
	onDeleteTag: id => dispatch(managerOperators.deleteTag({ id })),
	onSetSelectedTag: (tag: any) => dispatch(detailsOperators.setSelectedTag({ tag }))
});

export default connect(mapStateToProps, mapDispatchToProps)(TopicTagManager);
