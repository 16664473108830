import React from 'react';
import { withT } from 'lib/i18n';
import { Button } from 'lib/imports/react-md';
import { filter, size, sumBy } from 'lib/imports/lodash';
import { number } from 'lib/format';
import { MAX_FEED_DOCUMENTS } from 'store/ui/report/consts';
import ReportConfirmationDialog from 'components/Report/ConfirmationDialog';

import { ComponentProps } from './types';

const ReportSetupFeedsSummary: React.FC<ComponentProps> = props => {
	const { type, period, feeds, feedsLoaded, feedClippings, t } = props;
	const { onQueueReport } = props;

	const [dialogEnabled, setDialogEnabled] = React.useState<boolean>(false);

	const selectedFeeds = React.useMemo(() => filter(feeds, 'selected'), [feeds]);

	const selectedFeedsTotalHits = React.useMemo(() => {
		if (!feedsLoaded) return 0;
		return sumBy(selectedFeeds, feed => (type === 'excel' && feed.hits > MAX_FEED_DOCUMENTS) ? MAX_FEED_DOCUMENTS : feed.hits);
	}, [feedsLoaded, selectedFeeds, type]);

	const feedClippingsTotalCount = React.useMemo(() => sumBy(feedClippings, feedClipping =>
		(feeds[feedClipping.id].hits < feedClipping.count) ? feeds[feedClipping.id].hits : feedClipping.count
	), [feedClippings, feeds]);

	const onGenerate = React.useCallback(() => {
		setDialogEnabled(true);
		onQueueReport();
	}, [onQueueReport]);

	const dialog = React.useMemo(() => {
		return <ReportConfirmationDialog
			enabled={dialogEnabled}
			onCancel={() => setDialogEnabled(false)}
			textKeys={{
				title: 'reports.confirmation_message_1',
				content: 'reports.confirmation_message_2',
				footer: 'reports.confirmation_message_3'
			}}
		/>;
	}, [dialogEnabled, setDialogEnabled]);

	const reportType = React.useMemo(() => {
		if (type === 'periodic') return `${type}_${period}`;
		return type;
	}, [type, period]);

	return (
		<div id="reportSetupFeedsSummary" className="report-summary form-section buttons-section" >
			<div className="form-section-content">
				<div>
					<div className='setup-summary-info'>
						<div className='setup-summary-info-header'>
							<span className='setup-summary-info-header-type'>{t('reports.summary.number_feeds_selected', { count: size(selectedFeeds) })}</span>
						</div>
						<div className='setup-summary-info-desc'>
							<div>
								{t('reports.feeds.summary_number_documents', { number: number.locale(selectedFeedsTotalHits) })}
							</div>
							{type !== 'excel' && (<div>
								{t('reports.feeds.summary_number_clippings', { number: number.locale(feedClippingsTotalCount) })}
							</div>)}
						</div>

					</div>
				</div>
				<div>
					<Button id='reportSetupFeedsSummaryGenerateButton' data-report-type={reportType} className='form-btn save-btn' flat onClick={onGenerate} disabled={(selectedFeedsTotalHits === 0)}>
						{t(`reports.export_generate_${type}`)}
					</Button>
				</div>
			</div>

			{dialog}

		</div>
	);
};

export default withT(React.memo(ReportSetupFeedsSummary));
