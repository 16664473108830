import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import moment from 'moment';

import { State } from 'store/types';
import { UserObject } from 'class/User';
import { Tenant } from 'class/Tenant';
import { State as FormState } from 'store/search/form';
import { State as UrlQueryState } from 'store/ui/urlQuery';
import { State as FiltersState } from 'store/search/filters';
import { State as HorizontalFiltersState } from 'store/search/horizontalFilters';
import featureFlags from 'lib/featureFlags';

import { operators as urlQueryOperators } from 'store/ui/urlQuery/actions';
import { operators as formOperators } from './form/actions';
import { operators as resultsOperators } from './results/actions';
import { operators as facetsOperators } from './facets/actions';
import { operators as filtersOperators } from './filters/actions';

import formSagas from './form/sagas';
import resultsSagas from './results/sagas';
import facetsSagas from './facets/sagas';
import filtersSagas from './filters/sagas';
import horizontalFiltersSagas from './horizontalFilters/sagas';

import { operators, Actions } from './actions';

export default function* sagas() {
	yield all([
		formSagas(),
		resultsSagas(),
		facetsSagas(),
		filtersSagas(),
		horizontalFiltersSagas(),
		takeLatest(operators.setFormAndFilters.type, setFormAndFilters),
		takeLatest(operators.setFetchSearchError.type, setFetchSearchError)
	]);
}

function __getInitialStateForFilters(checkData: boolean) {
	if (!checkData) return filtersOperators.resetSearchFiltersData();
	else if (featureFlags.isEnabled('all-print')){
		return filtersOperators.resetSearchFiltersData();
	} else {
		if (featureFlags.isEnabled('parklu')) {
			return filtersOperators.resetSearchFiltersDMRAndParkluData();
		} else {
			return filtersOperators.resetSearchFiltersDMRData();
		}
	}
}

export function* resetSearchData(checkData: boolean = false) {
	yield all([
		put(facetsOperators.resetSearchFacetsData()),
		put(__getInitialStateForFilters(checkData)),
		put(formOperators.resetSearchFormData()),
		put(resultsOperators.resetSearchResultsData())
	]);
}

function* setFormAndFilters({ payload: { query } }: Actions["SetFormAndFilters"]) {
	yield call(resetSearchData, true);

	let formState: FormState = yield select((state: State) => state.search.form);
	let urlQuery: UrlQueryState = yield select((state: State) => state.ui.urlQuery);
	let horizontalFilters: HorizontalFiltersState = yield select((state: State) => state.search.horizontalFilters);
	let filtersState: FiltersState = yield select((state: State) => state.search.filters);
	const user: UserObject = yield select((state: State) => state.app.profile.user);
	const tenant: Tenant = yield select((state: State) => new Tenant(state.app.profile.tenant!));

	formState = cloneDeep(formState);
	filtersState = cloneDeep(filtersState);

	let sort = query.sort;
	if (sort) {
		const [sortField, order] = (sort as string).split(':');
		if (!tenant.checkAudienceFieldSort(sortField)) sort = tenant.getAudienceFieldSort() + ':' + order;
	}

	merge(formState, {
		query: query.query,
		sort,
		period: query.period,
		begin_date: query.begin_date ? moment(query.begin_date, "YYYYMMDD").tz(user.settings.timezone, true).toDate() : undefined,
		end_date: query.end_date ? moment(query.end_date, "YYYYMMDD").endOf('day').tz(user.settings.timezone, true).toDate() : undefined,
		date_type: query.date_type,
		limit: query.limit ? +query.limit : undefined,
		start: query.start ? +query.start : undefined
	});

	merge(urlQuery, {
		suggested_tags: query.suggested_tags ? (query.suggested_tags as string).split(',') : undefined
	});

	merge(horizontalFilters, {
		excludedFields: query.excluded_fields ? (query.excluded_fields as string).split(',') : undefined,
		begin_date: (query.begin_date && moment(query.begin_date).isValid()) ? moment(query.begin_date, "YYYYMMDD").tz(user.settings.timezone).toDate() : undefined,
		end_date: (query.end_date && moment(query.end_date).isValid()) ? moment(query.end_date, "YYYYMMDD").endOf('day').tz(user.settings.timezone).toDate() : undefined
	});

	merge(filtersState.facetsGroups, query.facetFilters ? JSON.parse(query.facetFilters as string) : undefined);
	merge(filtersState.focus, query.focusFilters ? (query.focusFilters as string).split(',') : undefined);
	merge(filtersState.feeds, query.feedFilters ? (query.feedFilters as string).split(',') : undefined);

	yield put(formOperators.setForm({ form: formState }));
	yield put(urlQueryOperators.setSuggestedTags({ suggested_tags: urlQuery.suggested_tags }));
	yield put(filtersOperators.setFilters({ filters: filtersState }));
	yield put(resultsOperators.fetchSearch());
}

function* setFetchSearchError({ payload: { error } }: Actions["SetFetchSearchError"]) {
	yield put(facetsOperators.fetchFacetsError());
	yield put(resultsOperators.fetchSearchError());
}
