import ActionCreator, { CrudActions } from 'lib/store/actions';
import { Category } from './model';

const actionCreator = new ActionCreator("@@orm/category");

const crudOperators = actionCreator.crudOperators<Category>();

export const operators = {
	...crudOperators
};

export interface Actions extends CrudActions<Category> {
}
