import React from 'react';
import { Button } from 'react-md';

import { ComponentProps } from './types';

import { withT } from 'lib/i18n';

import './ImageDropzone.scss';

const DEFAULT_MAX_SIZE = 15000000;

const ImageDropzone = (props: ComponentProps) => {
	const { showError = false, disableDrop = false, className = '' } = props;

	const [ imageFile, setImageFile ] = React.useState<File>();

	const getValidationExpression = React.useCallback(() => {
		const accept = 'image/*';
		return new RegExp('^(' + accept.replace(/[, ]+/g, '|').replace(/\/\*/g, '/.*') + ')$', 'i');
	}, []);

	const handleFilesChange = React.useCallback((file: File) => {
		const { maxSize = DEFAULT_MAX_SIZE, onFileAdded, onAddFilesError } = props;
		const regex = getValidationExpression();
		const fileExtension = file.name.match(/\.[^.]*$|$/)![0];

		if (regex && !(regex.test(file.type) || regex.test(fileExtension))) {
			return onAddFilesError({ error: 'incorrect_file_type' });
		}

		if (maxSize && file.size > maxSize) {
			return onAddFilesError({ error: 'file_too_large' });
		}

		setImageFile(file);
		return onFileAdded(file);
	}, [getValidationExpression, props]);

	const onRemoveFile = React.useCallback(() => {
		const { onRemoveFile } = props;
		setImageFile(undefined);
		return onRemoveFile(imageFile!);
	}, [imageFile, props]);

	const onDrop = React.useCallback((event: React.DragEvent) => {
		event.preventDefault();
		if (disableDrop) return;

		const droppedFiles = event.dataTransfer.files;
		if (droppedFiles.length > 0) handleFilesChange(droppedFiles.item(0)!);
	}, [handleFilesChange, disableDrop]);

	const getClassName = React.useCallback(() => {
		const baseClass = `${className} imageDropzone-container`;
		if (showError && !imageFile) return `${baseClass} error`;
		if (imageFile) return `${baseClass} filled`;
		return baseClass;
	}, [className, imageFile, showError]);

	const onInputChange = React.useCallback((event: any) => {
		event.preventDefault();
		const file = event.currentTarget.files || [null];
		handleFilesChange(file[0]);
	}, [handleFilesChange]);

	const renderUploadFileZone = React.useMemo(() => {
		const { message, fileInputLabel, icon } = props;
		return (<>
			{ icon && <img src={icon} alt='' id='imageDropzoneIcon' /> }
			<div className='imageDropzone-text'>
				<span id='imageDropzoneFileInputMessage'>
					{message ? message + ' ' : ''}
					<label className='imageDropzone-file-input' id='imageDropzoneFileInputLabel' key='imageDropzoneFileInputLabel'>
						{fileInputLabel}
						<input
							id='imageDropzoneFileInput'
							key='imageDropzoneFileInput'
							type='file'
							accept='image/*'
							onChange={event => onInputChange(event)}/>
					</label>
				</span>
			</div>
		</>);
	}, [onInputChange, props]);

	return (
		<div id='imageDropzoneContainer'
			key='imageDropzoneContainer'
			className={getClassName()}
			onDrop={onDrop}
			onDragOver={event => event.preventDefault()}
			onDragLeave={event => event.preventDefault()}
		>
			{ imageFile ?
				<div className='imageDropzone-preview'>
					<Button id='imageDropzonePreviewDeleteImage' onClick={() => onRemoveFile()} className='imageDropzone-preview-remove' icon>highlight_off</Button>
					<img id='imageDropzonePreviewImage' className='imageDropzone-preview-image' src={URL.createObjectURL(imageFile)} alt=''/>
				</div>
				: renderUploadFileZone
			}
		</div>
	);
};

export default withT(ImageDropzone);
