import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { keys } from 'lib/imports/lodash';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import { operators } from 'store/ui/report/setup/';
import selectors from 'store/ui/report/setup/selectors';
import FeedSelectors from 'store/entities/Feed/selectors';

import ReportSetupFeedsClipping from './ReportSetupFeedsClipping';

const mapStateToProps = (state: State): StateProps => ({
	feeds: selectors.getFeeds(state) ? FeedSelectors.get(state, keys(selectors.getFeeds(state))) : [],
	reportFeeds: selectors.getFeeds(state),
	feedClippings: selectors.getFeedClippings(state),
	reportType: selectors.getType(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetFeedClippings: feedClippings => dispatch(operators.setFeedClippings(feedClippings))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSetupFeedsClipping);
