import React, { useState } from 'react';
import { SelectionControl } from 'lib/imports/react-md';
import { orderBy, uniqBy } from 'lib/imports/lodash';

import { withT } from 'lib/i18n';
import { Facet, FacetItem } from 'class/Facet';
import { ComponentProps } from './types';

import DropdownFilter from 'components/common/DropdownFilter/DropdownFilter';

import './ReportSetupSettingsCountry.scss';

const majorMarketCountries = ['0400', '0109', '0107', '0106', '0103', '0100'];

const ReportSetupSettingsCountry: React.FC<ComponentProps> = props => {
	const { t, reportCountries, isGroupedByCountry } = props;
	const { onChangeCountry, onChangeGroupedByCountry } = props;

	const [isOpen, setIsOpen] = useState(false);

	const __getItemFromCountryPath = React.useCallback((path: string) => ({ name: t(`filters.country.${path}`), key: path }), [t]);

	const allCountries = React.useMemo(() => {
		const majorCountryItems = majorMarketCountries.map(__getItemFromCountryPath);
		const countryItems = orderBy(Facet.countryFacetValues.map(__getItemFromCountryPath), 'label');
		return [{ name: t('reports.all_male_sing'), key: 'all' }, ...uniqBy([...majorCountryItems, ...countryItems], 'key')];
	}, [t, __getItemFromCountryPath]);

	const countrySelectedItems = React.useMemo(() => {
		const defOption = { name: t('reports.all_male_sing'), key: 'all' };
		return reportCountries
			? reportCountries.split(',').map(countryId => allCountries.find(c => c.key === countryId))
			: [defOption];
	}, [allCountries, reportCountries, t]);

	return (
		<div id='reportSetupSettingsCountry' className='form-section' >
			<div className='form-section-title'>
				{t('reports.country')}
			</div>
			<div className='form-section-content'>
				<span>{t('reports.options_filter_specific_country_or_group')}</span>
				<div>
					<DropdownFilter
						id='location'
						key='reports-setup-settings-country-dropdown'
						excluded={false}
						title='Location'
						filters={allCountries}
						isOpen={isOpen}
						onToggleOpen={() => setIsOpen(!isOpen)}
						initSelectedFilters={countrySelectedItems as FacetItem[]}
						defaultFilter={{ name: t('reports.all_male_sing'), key: 'all' }}
						disable={isGroupedByCountry}
						onChange={onChangeCountry}
					/>
					<SelectionControl
						id='reportSetupSettingsGroupByCountryCheckbox'
						name='reportSetupSettingsGroupByCountryCheckbox'
						type='checkbox'
						label={t('reports.group_by_country')}
						checked={isGroupedByCountry}
						onChange={(checked: any) => onChangeGroupedByCountry(checked)}
					/>
				</div>
			</div>
		</div>
	);
};

export default withT(React.memo(ReportSetupSettingsCountry));
