import React from 'react';
import { cloneDeep, every, filter, forEach, isEmpty, orderBy, some } from 'lib/imports/lodash';
import { Collapse, FontIcon, SelectionControl } from 'lib/imports/react-md';

import GA from 'lib/googleAnalytics';
import { withT } from 'lib/i18n';
import { Facet } from 'class/Facet';
import { TagObject } from 'store/entities/Tag';
import { TopicObject } from 'store/entities/Topic';
import { ComponentProps } from './types';

type FilteredItem = { key: string, topic?: TopicObject<"tags"> };

const isTagFiltered = (tag: TagObject, filteredTags: Record<string, boolean>) =>
	filteredTags[`${tag.topic}_${tag.id}`];

const getTagCounter = (tag: TagObject, facetTags: Record<string, number>) =>
	facetTags[`${tag.topic}_${tag.id}`] || 0;

const filterAndOrderTags = (tags: Readonly<TagObject[]>, facetTags: Record<string, number>, filteredTags: Record<string, boolean>) => {
	const resultTags = filter(tags, tag => {
		const topicTagId = `${tag.topic}_${tag.id}`;
		return facetTags[topicTagId] || filteredTags[topicTagId];
	}) as TagObject[];

	// Order topics by name and tags by if are filtered and number of hits
	return orderBy(resultTags, [
		tag => isTagFiltered(tag, filteredTags),
		tag => getTagCounter(tag, facetTags),
		'name'
	], ['asc', 'desc', 'asc']);
};

const getFilteredTopicItemsFromFacetsAndFilters = (topics: TopicObject<"tags">[], facetTags: Record<string, number>, filteredTags: Record<string, boolean>): FilteredItem[] => {
	if (isEmpty(topics)) return [];
	// Filter topic and tags listed in facets and / or filters
	let filteredTopics: TopicObject<"tags">[] = cloneDeep(topics).map(topic => ({
		...topic,
		tags: filterAndOrderTags(topic.tags, facetTags, filteredTags)
	}));
	filteredTopics = filter(filteredTopics, topic => !isEmpty(topic.tags));

	filteredTopics = orderBy(filteredTopics, 'name');
	const filteredItems: FilteredItem[] = filteredTopics.map(topic => ({ key: topic.id, topic }));
	if (facetTags[Facet.untaggedDocumentKey] || filteredTags[Facet.untaggedDocumentKey]) {
		filteredItems.unshift({ key: Facet.untaggedDocumentKey });
	}
	return filteredItems;
};

const SearchFacetsTopicTagsGroup = (props: ComponentProps) => {
	const { t, isGroupOpened, facetTags, filteredTags, topics, isLoadingFacets } = props;
	const { onToggleFacetGroupOpened, onRemoveGroupFilter, onTagFilterToggled, onTopicFilterToggled, onOpenShowMore } = props;

	const groupHasFilterEnabled = React.useMemo(() => !isEmpty(filteredTags), [filteredTags]);
	const isGroupEnabled = React.useMemo(() =>
		// Group is enabled if there are facets or if there are filters
		(!isEmpty(facetTags)) || !isEmpty(filteredTags),
	[facetTags, filteredTags]);

	const onToggleGroupClicked = React.useCallback(() => { if (isGroupEnabled) onToggleFacetGroupOpened(); }, [onToggleFacetGroupOpened, isGroupEnabled]);
	const onTagFilterToggleClicked = React.useCallback((topicTagId: string) => {
		const trackAction = `Filter by ${t(`filters.tags`, { lng: "en" })}`;
		const trackLabel = topicTagId === Facet.untaggedDocumentKey ? t(`filters.untagged_documents_facet_tag`) : topicTagId;
		GA.trackEvent({ category: "Feed results", action: trackAction, label: trackLabel });
		onTagFilterToggled(topicTagId);
	}, [t, onTagFilterToggled]);
	const onRemoveGroupFilterClicked = React.useCallback(ev => {
		ev.stopPropagation();
		onRemoveGroupFilter();
	}, [onRemoveGroupFilter]);

	const filteredItems = React.useMemo(() => getFilteredTopicItemsFromFacetsAndFilters(topics, facetTags, filteredTags), [topics, facetTags, filteredTags]);
	const topicListContent = React.useMemo(() => {
		const contentItems: JSX.Element[] = [];
		forEach(filteredItems, ({ topic, key }) => {
			const topicHasSomeTagFiltered = topic && some(topic.tags, tag => isTagFiltered(tag, filteredTags));
			const topicHasAllTagsFiltered = topic && every(topic.tags, tag => isTagFiltered(tag, filteredTags));
			const isUntaggedFacet = key === Facet.untaggedDocumentKey;
			const isUntaggedSelected = isUntaggedFacet && !!filteredTags[Facet.untaggedDocumentKey];
			const untaggedCounter = facetTags[Facet.untaggedDocumentKey];

			contentItems.push(
				<div className="search-facets-group-item" key={`topic-${key}`}>
					<SelectionControl
						id={`searchFacetsTopicTagItemCheckbox-topic-${key}`}
						name={`searchFacetsTopicTagItemCheckbox-topic-${key}[]`}
						label=""
						type="checkbox"
						checked={topic ? !!topicHasAllTagsFiltered : isUntaggedSelected}
						className={topicHasSomeTagFiltered || (isUntaggedFacet && isUntaggedSelected) ? "checkbox-green" : ""}
						uncheckedCheckboxIcon={topicHasSomeTagFiltered ? <FontIcon>indeterminate_check_box</FontIcon> : <FontIcon>check_box_outline_blank</FontIcon>}
						onChange={() => topic ? onTopicFilterToggled(topic) : onTagFilterToggleClicked(Facet.untaggedDocumentKey)}
					/>
					<div
						id={`searchFacetsTopicTagItemName-topic-${key}`}
						className="search-facets-group-item-name"
						onClick={() => topic ? onTopicFilterToggled(topic) : onTagFilterToggleClicked(Facet.untaggedDocumentKey)}
					>
						<span className={isUntaggedFacet ? "custom-facet" : ""} >
							{topic ? topic.name : t(`filters.untagged_documents_facet_tag`)}
							{isUntaggedFacet && untaggedCounter && !isLoadingFacets && <span> - <span className="counter">{untaggedCounter}</span></span>}
						</span>
					</div>
				</div>
			);

			if (topic) {
				forEach(topic.tags, tag => {
					const tagCounter = getTagCounter(tag, facetTags);
					contentItems.push(
						<div className="search-facets-group-item tag-item" key={`tag-${tag.id}`}>
							<SelectionControl
								id={`searchFacetsTagItemCheckbox-tag-${tag.id}`}
								name={`searchFacetsTagItemCheckbox-tag-${tag.id}[]`}
								label=""
								type="checkbox"
								className={isTagFiltered(tag, filteredTags) ? "checkbox-green" : ""}
								checked={!!isTagFiltered(tag, filteredTags)}
								onChange={() => onTagFilterToggleClicked(`${topic.id}_${tag.id}`)}
							/>
							<div id={`searchFacetsTagItemName-tag-${tag.id}`} className="search-facets-group-item-name" onClick={() => onTagFilterToggleClicked(`${topic.id}_${tag.id}`)}>
								{tag.name}
								{!!tagCounter && !isLoadingFacets && <span> - <span className="counter">{tagCounter}</span></span>}
							</div>
						</div>
					);
				});
			}
		});

		return contentItems;
	}, [t, filteredItems, filteredTags, facetTags, isLoadingFacets, onTagFilterToggleClicked, onTopicFilterToggled]);

	const showMoreContent = React.useMemo(() => (
		<div id={'searchFacetsGroupSetShowMore-tags'} className="search-facets-group-item" onClick={() => {
			const trackLabel = `${t(`filters.tags`)} MoreOptions`;
			GA.trackEvent({ category: "Feed results", action: 'Filter by', label: trackLabel });
			onOpenShowMore();
		}}>
			<FontIcon>add</FontIcon>
			{t('filters.show_more')}
		</div>
	), [t, onOpenShowMore]);

	return (
		<div className={`search-facets-group ${isGroupEnabled ? "" : "disabled"}`} id={`searchFacetsGroup-tags`}>
			<div className={`search-facets-group-header ${groupHasFilterEnabled ? "enabled" : ""}`} onClick={onToggleGroupClicked}>
				{isGroupOpened && isGroupEnabled ? <FontIcon>keyboard_arrow_down</FontIcon> : <FontIcon>keyboard_arrow_right</FontIcon>}
				<span>{t("filters.topic_tags")}</span>
				{groupHasFilterEnabled &&
					<div
						className="search-facets-group-remove-filters"
						onClick={onRemoveGroupFilterClicked}
						title={t('filters.reset.value', { filter: t('filters.topic_tags').toLowerCase() })}
					><FontIcon>close</FontIcon></div>}
			</div>
			<Collapse collapsed={!isGroupOpened || !isGroupEnabled}>
				<div className="search-facets-group-items">
					{topicListContent}
					{showMoreContent}
				</div>
			</Collapse>
		</div >
	);
};

export default withT(React.memo(SearchFacetsTopicTagsGroup));
