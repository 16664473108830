import React from 'react';
import { Button, Paper } from 'lib/imports/react-md';
import { isEmpty } from 'lib/imports/lodash';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';

import "./BulksPreviewTags.scss";

const BulksPreviewTags = (props: ComponentProps) => {
	const { t, bulkdDocument, getFullCategoryName} = props;

	const [showPopup, setShowPopup] = React.useState(false);
	const [editMode, setEditMode] = React.useState(false);

	const onShowPopup = React.useCallback(() => setShowPopup(true), []);
	const onHidePopup = React.useCallback(() => setShowPopup(false), []);
	const onToggleEditMode = React.useCallback(() => setEditMode(mode => !mode), []);

	const bulkTagsContent = React.useMemo(() => {
		if (isEmpty(bulkdDocument.tags)) return null;

		let tags: JSX.Element[] = [];
		tags = bulkdDocument.tags.map((tag, idx) =>(
			<Paper
				key={`categorization-topic-tag-chip-${tag.id}-${idx}`}
				className="categorization-topic-tag-chip"
				zDepth={1}
			>
				<div className="categorization-topic-tag-chip-text">
					<span>{tag.name}</span>
				</div>
			</Paper>
		));

		return (
			<div className="tags-container">
				<div className="popup-info-label">{t('doc.categorization.tag')}</div>
				<div className="popup-info-content">
					{tags}
				</div>
			</div>
		);
	}, [bulkdDocument, t]);


	const categoryContent = React.useMemo(() => {
		if ((!showPopup && !editMode) || !bulkdDocument.category) return null;
		const categoryName = getFullCategoryName(bulkdDocument.category.id);

		return (
			<div className="category-container">
				<div className="popup-info-label">{t('doc.categorization.group')} » {t('doc.categorization.category')} » {t('doc.categorization.sector')}</div>
				<div className="popup-info-content">
					<div><span>{categoryName}</span></div>
				</div>
			</div>
		);
	}, [showPopup, editMode, bulkdDocument.category, getFullCategoryName, t]);

	const popupContent = React.useMemo(() => {
		if (!showPopup && !editMode) return null;
		return (
			<div className="popup-container">
				{categoryContent}
				{bulkTagsContent}
			</div>
		);
	}, [showPopup, editMode, bulkTagsContent, categoryContent]);

	return (
		<div className="document-item-categorization">
			{popupContent}
			<Button
				icon
				className={`icon-tag${showPopup ? ' active-popup' : ''}`}
				onMouseEnter={onShowPopup}
				onMouseLeave={onHidePopup}
				onClick={onToggleEditMode}
			></Button>
		</div>
	);
};

export default withT(React.memo(BulksPreviewTags));
