import { combineReducers } from 'redux';

import * as categorization from './categorization';
import * as insert from './insert';

export const reducers = combineReducers({
	categorization: categorization.reducers,
	insert: insert.reducers
});

export interface State {
	categorization: categorization.State;
	insert: insert.State;
}

export const INITIAL_STATE = {
	categorization: categorization.INITIAL_STATE,
	insert: insert.INITIAL_STATE
};
