import { createSelector } from "reselect";
import { createSelector as typedCreateOrmSelector } from "redux-orm";
import { State } from "store/types";
import orm, { stateSelector } from './orm';

export interface OrmSelector<R> {
	(state: State): R[];
	(state: State, id: string): R | null;
	(state: State, ids: string[]): R[];
}

export const createOrmSelector = typedCreateOrmSelector as any;
export const ormEntities = orm as any;

// used to get the session with all current models
export const sessionSelector = createSelector(stateSelector, state => orm.session(state));
