import axios from 'axios';
import { all, call, takeLatest, select, put } from 'redux-saga/effects';

import Api from 'lib/ajax/Api';
import { Actions, operators } from './actions';
import { State } from "store/types";
import { FocusLogoImageMeta } from "./reducers";
import { FocusObject } from 'class/Focus';

export default function* sagas() {
	yield all([
		takeLatest(operators.fetchImageMeta.type, fetchImageMeta),
		takeLatest(operators.removeLogo.type, removeLogo),
		takeLatest(operators.acceptFacebook.type, acceptFacebook),
		takeLatest(operators.acceptUpload.type, acceptUpload)
	]);
}

function* fetchImageMeta() {
	try {
		const focus: FocusObject = yield select((state: State) => state.focus.dashboard.focus);
		const response = yield call([axios, 'get'], `${focus.url_logo}.meta`);
		const data = response.data as FocusLogoImageMeta;
		yield put(operators.fetchImageMetaSuccess({ imageMeta: data }));
	} catch (error) {
		yield put(operators.fetchImageMetaError({ error }));
	}
}

function* removeLogo() {
	const api = new Api();
	try {
		const focus: FocusObject = yield select((state: State) => state.focus.dashboard.focus);
		yield call([api, 'delete'], `/definition/focus/${focus.id}/logo`);

		yield put(operators.removeLogoSuccess());
	} catch (error) {
		yield put(operators.removeLogoError({ error }));
	}
}

function* acceptFacebook({ payload: { facebookImageUrl, facebookPageUrl } }: Actions["AcceptFacebook"]) {
	const api = new Api();
	try {
		const params = { facebook_image_url: facebookImageUrl, facebook_page_url: facebookPageUrl };
		const focus: FocusObject = yield select((state: State) => state.focus.dashboard.focus);
		yield call([api, 'post'], `/definition/focus/${focus.id}/logo`, { params });
		yield put(operators.acceptSuccess());
	} catch (error) {
		yield put(operators.acceptError({ error }));
	}
}

function* acceptUpload({ payload: { image, imageCrop } }: Actions["AcceptUpload"]) {
	const api = new Api();
	try {
		const file = image!;
		const formData = new FormData();
		const crop = imageCrop!;
		formData.append('start', `${crop.x}x${crop.y}`);
		formData.append('size', `${crop.width}x${crop.height}`);
		formData.append('mime', file.type);
		formData.append('file', file, file.name);
		const headers = { 'Content-Type': 'multipart/form-data' };
		const focus: FocusObject = yield select((state: State) => state.focus.dashboard.focus);
		yield call([api, 'post'], `/definition/focus/${focus.id}/logo`, { data: formData, headers });
		yield put(operators.acceptSuccess());
	} catch (error) {
		yield put(operators.acceptError({ error }));
	}
}
