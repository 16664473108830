import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { map } from 'lib/imports/lodash';
import { State } from 'store/types';
import { operators } from 'store/app/profile';
import { operators as facebookOperators } from 'store/app/facebook';

import profileSelectors from 'store/app/profile/selectors';
import facebookSelectors from 'store/app/facebook/selectors';
import UserSelectors from 'store/entities/User/selectors';
import { FacebookUserObject } from 'store/entities/FacebookUser';

import SettingsUser from './UserSettings';
import { DispatchProps, StateProps } from './types';

const mapStateToProps = (state: State): StateProps => {
	let facebookUsers: FacebookUserObject<'instagramAccounts'>[] | null = null;
	if (facebookSelectors.isLoaded(state)) {
		facebookUsers = map(UserSelectors.getFacebookUserInstances(state, profileSelectors.getUserId(state)),
			facebookUser => ({
				...facebookUser.ref,
				instagramAccounts: facebookUser.instagramAccounts.toRefArray()
			})
		);
	}
	return {
		user: profileSelectors.getUser(state)!,
		facebookUsers,
		currentFacebookUser: facebookSelectors.getCurrentFacebookUser(state),
		isLoading: profileSelectors.isSettingsLoading(state)
	};
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onUpdateUserSettings: userSettings => dispatch(operators.updateUserSettings(userSettings)),
	onDeauthorizeFacebookUser: () => dispatch(facebookOperators.deauthorize()),
	onSetFacebookDialogMode: mode => dispatch(facebookOperators.setDialogMode(mode)),
	onSetPermissionsMissing: permissionsMissing => dispatch(facebookOperators.setPermissionsMissing({mode: 'check', permissionsMissing}))
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsUser);
