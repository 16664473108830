import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

import NewsletterUnsubscribe from 'components/Public/Newsletter/Unsubscribe';
import NewsletterClipDownload from 'components/Public/Newsletter/ClipDownload';
import NewsletterHtml from 'components/Public/Newsletter/Html';

type ComponentProps = {
	match: RouteComponentProps['match'];
};
export default ({ match }: ComponentProps) => (
	<div id="newsletter">
		<Switch>
			<Route path={`${match.path}/unsubscribe`} component={NewsletterUnsubscribe} />
			<Route path={`${match.path}/:id/download/:sendTime`} render={({ match }) => <NewsletterClipDownload id={match.params.id} sendTime={match.params.sendTime} />} />
			<Route path={`${match.path}/:id/html/:sendTime`} render={({ match }) => <NewsletterHtml id={match.params.id} sendTime={match.params.sendTime} />} />
			<Route render={() => (<div>Page Not Found</div>)} />
		</Switch>
	</div>
);
