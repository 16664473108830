import truncate from 'lodash/truncate';

const PROVIDERS = [
	'News',
	'Print',
	'DMR',
	'Twitter',
	'Gallery',
	'YouTube',
	'Instagram',
	'Facebook',
	'TikTok',
	'Bilibili',
	'Douyin',
	'WechatPublic',
	'Weibo',
	'Red'
] as const;

export type DocumentProvider = typeof PROVIDERS[number];
export type DocumentCounter = {
	id?: string | number
	value: string | number | null | undefined, //TODO (GIS-3281) Remove undefined acceptance..
	title: string | undefined //TODO (GIS-3281) Remove undefined acceptance..
};

export type DocumentPerson = {
	person_id: string,
	person_name: string,
	person_role: 'Stylist' | 'Photographer',
	person_surname: string
};

export type BrandedValuation = {
	id: string,
	value: number
}

export type Paywall = 'nicklpass' | null;

export interface DocumentCounters {
	reach: DocumentCounter;
	engagement: DocumentCounter;
	echo?: DocumentCounter;
	views?: DocumentCounter;
	replies?: DocumentCounter;
	branded_miv?: DocumentCounter[];
}

export interface SourceObject {
	id: string;
	title: string;
	url: string;
	name: string;
}

export interface RankObject { [counter: string]: number; }

export type ApiSearchDocument = ApiArticleSearchDocument | ApiPreviewSearchDocument;

export type ApiArticleSearchDocument = {
	id: string;
	source: {
		queries: string[];
		categories_id: string | null;
		topic_tag: string[] | null;
	};
};

export type ApiPreviewSearchDocument = string;

export const isArticleApiSearchDocument = (document: ApiSearchDocument): document is ApiArticleSearchDocument => {
	return (document as ApiArticleSearchDocument).source !== undefined;
};

// the document type used to render
export class PreparedDocument {
	public id: string;
	public icon?: string;
	public iconSVG?: React.FunctionComponent;
	public iconForced?: string;
	public provider: DocumentProvider;
	public providerName: string;
	public title: string;
	public content: string;
	public link: string;
	public date: string;
	public dateTitle: string;
	public date_from_provider: string;
	public counters: DocumentCounters;
	public categories_id: string;
	public topic_tag: string[];
	public author_name: string;
	public author_link: string;
	public author_icon: string;
	public image: string;
	public cover?: string;
	public cover_link?: string;
	public country: string;
	public page: string;

	constructor(values?: Partial<PreparedDocument>) {
		if (values) Object.assign(this, values);
	}
}

export type BulkDocument = {
	title: string;
	mediaName: string;
	page: string;
	editionNumber: string;
	circulation: string;
	miv: string;
	date: string;
	feed: BulkExtraData;
	country: BulkExtraData;
	category: BulkExtraData;
	tags: BulkExtraData[];
	coverImage?: string;
	pageImage?: string;
	content?: string;
	provider?: DocumentProvider;
	formatedData?: {
		date?: {
			title: string,
			value: string
		}
		miv?: {
			title: string,
			value: string
		},
		reach?: {
			title: string,
			value: string
		}
	}
}

export type BulkExtraData = {
	name: string;
	id: string;
}

export type OldestDocumentObject = {
	id: string,
	publication_date: string
}

export class DocumentObject {
	public id: string;
	public provider: DocumentProvider;
	public content: string;
	public link: string;
	public date: string;
	public title: string;
	public source: SourceObject;
	public social: any;
	public author: any;
	public place: any;
	public date_from_provider: string;
	public queries: string[];
	public category: string | null;
	public topic_tag: string[] | null;
	public media: any;
	public rank: RankObject;
	public language: any;
	public location: any;
	public image_url: string;
	public image: any;
	public images: string[];
	public cover: any;
	public page_number: string;
	public company: string;
	public brand_associated: string;
	public line: string;
	public product_name: string;
	public category_id: string;
	public country: any;
	public issue_number: string;
	public page_occupation: number;
	public paywall: Paywall;
	public persons?: DocumentPerson[];
	public manual?: number;
	public manual_user_name?: string;
	public branded_miv?: BrandedValuation[];

	constructor(values?: Partial<DocumentObject>) {
		if (values) Object.assign(this, values);
	}

	public static getMergedDocument(apiDocument: any, apiSearchDocument: ApiArticleSearchDocument): DocumentObject {
		if (isArticleApiSearchDocument(apiSearchDocument)) {
			return {
				...apiDocument,
				queries: apiSearchDocument.source.queries,
				category: apiSearchDocument.source.categories_id,
				topic_tag: apiSearchDocument.source.topic_tag
			};
		}
		return { ...apiDocument };
	}
}

export abstract class Document extends DocumentObject {
	public abstract prepare(): PreparedDocument;

	public splitTags(text: string, split: boolean = false) {
		if (!text) return { title: '', content: '' };

		text = text.replace(/\n+/g, ' ').replace(/([#@])/g, " $1").replace(/\s+/g, ' ').trim(); // replace line breaks by spaces and put spaces between tags
		if (text.match(/[#@]\S[^#@]+$/)) { // ends with tags
			if (!text.match(/(^|\s)[^#@]/)) { // only tags
				const title = truncate(text, { length: 60, separator: /[,?!\s]+/, omission: '' }); // try to cut at 60 chars
				if (title === text) return { title, content: '' };
				const content = text.substr(title.length);
				const join = (title.match(/\s+$/) || content.match(/^\s+/)) ? '' : '...';
				return { title: title.trim() + join, content: join + content.trim() };

			} else { // mixed tags and text

				if (!split) {
					const re = /(^|.+\s)[^#@]\S*/g; // find last word not being a tag to split
					let result;
					let lastIndex;
					do {
						result = re.exec(text);
						if (result) lastIndex = re.lastIndex;
					} while (result);

					if (lastIndex) return { title: text.substr(0, lastIndex).trim(), content: text.substr(lastIndex).trim() };
				} else {
					let hashRegex = new RegExp(/[#@]\w+/g);
					let result, tags: string[] = [];
					while ((result = hashRegex.exec(text)) !== null) {
						tags.push(result[0]);
					}
					return { title: text.trim(), content: tags.join(' ') };
				}

			}
		}
		return { title: text, content: '' };
	}
}
