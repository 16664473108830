import { createSelector } from "reselect";

import { keys } from 'lib/imports/lodash';
import { State } from 'store/types';

const searchResultsSelector = (state: State) => state.search.results;
export const selectors = {
	getSelectedIds: createSelector(searchResultsSelector,
		state => keys(state.documentsChecked)
	)
};
