import React, { FormEvent } from 'react';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import SimpleDialog from 'components/common/dialog/simple';
import { checkName } from 'lib/validate';
import TextField from 'react-md/lib/TextFields/TextField';
import GA from 'lib/googleAnalytics';

const FocusDashboardDialog = (props: ComponentProps) => {
	const { t, action, focus } = props;
	const { onRenameFocus, onRemoveFocus, onRemoveFeed, onClose } = props;

	const [renameFocusError, setRenameFocusError] = React.useState<string | null>(null);

	const renameInputRef = React.useRef<any>(null);

	const onAccept = () => {
		if (!action) return;
		if (action.type === "renameFocus") {
			const name = renameInputRef.current.value;
			const error = checkName(name);
			if (error) return setRenameFocusError(t(`error.focus_${error}`));
			if (name !== focus.name) {
				onRenameFocus(name);
				GA.trackEvent({ category: 'Focus Dashboard', action: 'Focus actions', label: 'Rename focus' });
			}
		} else if (action.type === "removeFocus") {
			onRemoveFocus();
			GA.trackEvent({ category: 'Focus Dashboard', action: 'Remove focus' });
		} else if (action.type === "removeFeed") {
			onRemoveFeed(action.feed.id);
			GA.trackEvent({ category: 'Focus Dashboard', action: 'Remove feed', label: action.feed.type });
			GA.trackEvent({ category: 'Focus Dashboard', action: 'Feed actions', label: 'remove' });
		}
		onClose();
	};

	const onShow = () => {
		if (!action) return;
		if (action.type === 'renameFocus') {
			setRenameFocusError(null);
			renameInputRef.current.focus();
		}
	};
	const onSubmit = (ev: FormEvent) => {
		ev.preventDefault();
		onAccept();
	};

	let info: { id: string, text: string, content?: JSX.Element } = { id: 'focusDashboardSimpleDialog', text: '' };
	if (action) {
		if (action.type === 'renameFocus') info = {
			id: 'focusDashboardRenameFocusSimpleDialog',
			text: t('focus.rename.title'),
			content: <form id="focusDashboardRenameFocusForm" onSubmit={onSubmit}>
				<TextField id="focusDashboardRenameFocusInput"
					ref={renameInputRef}
					label={t('name')}
					error={!!renameFocusError}
					errorText={renameFocusError}
					defaultValue={focus.name} />
			</form>
		};
		if (action.type === 'removeFocus') info = {
			id: 'focusDashboardRemoveFocusSimpleDialog',
			text: t('focus.remove.confirm', { focus_name: focus!.name }),
			content: <div>{t('focus.remove.notice')}</div>
		};
		if (action.type === 'removeFeed') info = {
			id: 'focusDashboardRemoveFeedSimpleDialog',
			text: t('focus.confirm_remove.feed')
		};
	}

	return <SimpleDialog
		{...info}
		onShow={onShow}
		visible={!!action}
		onAccept={onAccept}
		onCancel={onClose}
		acceptColor={action && (action.type === "removeFeed" || action.type === "removeFocus") ? "red" : "green"}
	/>;
};

export default withT(FocusDashboardDialog);
