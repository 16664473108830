import React from 'react';
import { CircularProgress } from 'react-md/lib/Progress';
import find from 'lodash/find';

import { withT } from 'lib/i18n';
import { Collapse, FontIcon } from 'react-md';
import { ComponentProps } from './types';
import FocusFeedFacets from './Facets';
import FocusFeedForm from './Form';
import SearchResults from 'components/Search/Results';
import SearchPaginator from 'components/Search/Paginator';
import FocusFeedDefinitionSocial from './Definition/Social';
import FocusFeedDefinitionPrint from './Definition/Print';
import FocusDefinitionOnline from './Definition/Online';
import FocusFeedDefinitionPrintDmr from './Definition/PrintDmr';
import GA from "lib/googleAnalytics";
import FocusFeedHeader from './Header/';
import { FeedType } from 'class/Feed';
import { FocusObject } from 'class/Focus';
import DateForm from './DateForm';

import './FocusFeed.scss';

type ComponentState = {
	collapseResults: boolean;
};

class FocusFeed extends React.Component<ComponentProps, ComponentState> {

	constructor(props: ComponentProps) {
		super(props);
		this.state = { collapseResults: false };
	}

	private _getDefinitionComponent = () => {
		switch (this.props.feedType) {
			case "socialmedia": return <FocusFeedDefinitionSocial />;
			case "online": return <FocusDefinitionOnline />;
			case "print": return <FocusFeedDefinitionPrint />;
			case "print_dmr": return <FocusFeedDefinitionPrintDmr />;
		}
	};

	private _getContent = () => {
		const { collapseResults } = this.state;
		const { focusList, feedType, loadingSaveFeed, brand_sync, t } = this.props;

		const isCollapsed = collapseResults || brand_sync;

		if (!focusList || !feedType || loadingSaveFeed) return <div className="focusFeedLoading"><CircularProgress id="focusFeedLoading" scale={1.25} /></div>;
		return (
			<>
				<FocusFeedFacets />
				<div className="main-drawer">
					<DateForm toggleResults={() => {
						this.setState({ collapseResults: false });
					}} />
					{this._getDefinitionComponent()}
					<div id="focusFeedResults" className={`definition-section ${brand_sync ? 'definition-section-disabled' : ''}`}>
						<div className="definition-section-header">
							<div className="definition-header-title" onClick={() => this.setState({ collapseResults: !collapseResults })}>
								{
									isCollapsed ?
										<FontIcon className="definition-header-title-toggle" >keyboard_arrow_right</FontIcon> :
										<FontIcon className="definition-header-title-toggle">keyboard_arrow_down</FontIcon>
								}
								<span className="definition-header-title-text">
									{t('feed.form.header_results')}
								</span>
							</div>
						</div>
						<Collapse collapsed={isCollapsed}>
							<div className='definition-section-content'>
								<FocusFeedForm />
								<SearchResults />
								<SearchPaginator />
							</div>
						</Collapse>
					</div>
				</div >
			</>
		);
	};

	private _resetFeed = (focus: FocusObject) => {
		const { onResetFeed, urlFeedType } = this.props;

		onResetFeed(focus.id, focus.name, urlFeedType as FeedType);
	};

	public componentDidMount() {
		const { urlFocusId, urlFeedId, isCreatePage, urlFeedType } = this.props;
		const { focusList } = this.props;
		const { onFetchFocusList, onFetchFeed } = this.props;
		if (!focusList) onFetchFocusList();

		if (isCreatePage) {
			GA.trackPage(`/focus/:focus/feed/create/${urlFeedType}`);
			const focus = find(focusList, { id: urlFocusId });
			if (!focus) return;
			this._resetFeed(focus);
		} else {
			GA.trackPage('/focus/:focus/feed/:feed');
			onFetchFeed(urlFocusId, urlFeedId!);
		}
	}

	public componentDidUpdate(prevProps: ComponentProps) {
		if (this.props.isCreatePage && this.props.focusList && this.props.focusList !== prevProps.focusList) {
			const focus = find(this.props.focusList, { id: this.props.urlFocusId });
			if (!focus) return;
			this._resetFeed(focus);
		}
	}

	public render() {
		const content = this._getContent();

		return (
			<div id="focusFeed">
				<FocusFeedHeader />
				{content}
			</div>
		);
	}
}

export default withT(FocusFeed);
