import React from 'react';
import { ComponentProps } from './types';

export const DropdownMenu = (props: ComponentProps) => {
	const shadow = 'hsla(218, 50%, 10%, 0.1)';
	return (
		<div
			style={{
				backgroundColor: 'white',
				borderRadius: 4,
				boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
				marginTop: 8,
				position: 'absolute',
				zIndex: 28
			}}
			{...props}
		/>
	);
};
