import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { Actions, operators } from './actions';

export type State = {
	focusId: string | null
};

export const INITIAL_STATE: State = {
	focusId: null
};

const reducerHandlers: ReducerHandlers<State> = {
	changeFocus: (state, { payload: focusId }: Actions['ChangeFocus']): State => ({ ...state, focusId })
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
