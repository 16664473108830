import { all, call, put, takeLatest } from 'redux-saga/effects';
import Api from 'lib/ajax/Api';

import { operators as emailListOperators, EmailListData } from 'store/entities/EmailList';
import { Actions, operators } from './actions';

export default function* sagas() {
	yield all([
		takeLatest(operators.createEmailList, createEmailList),
		takeLatest(operators.deleteEmailList, deleteEmailList)
	]);
}

function* createEmailList({ payload: { name } }: Actions["CreateEmailList"]) {
	const api = new Api();
	try {
		const response = yield call([api, 'post'], '/emailList', { data: { name } });
		const emailList: EmailListData = { emails: {}, name, id: response.id };
		yield put(emailListOperators.create(emailList));
		yield put(operators.createEmailListSuccess());
	} catch (error) {
		yield put(operators.createEmailListError(error));
	}
}

function* deleteEmailList({ payload: { listId } }: Actions["DeleteEmailList"]) {
	const api = new Api();
	try {
		yield put(emailListOperators.delete(listId));
		yield call([api, 'delete'], `/emailList/${listId}`);
		yield put(operators.deleteEmailListSuccess());
	} catch (error) {
		yield put(operators.deleteEmailListError(error));
	}
}
