import React from 'react';
import DialogContainer from 'react-md/lib/Dialogs/DialogContainer';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import Button from 'react-md/lib/Buttons/Button';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';

import './Dialog.scss';

const Dialog = (props: ComponentProps) => {
	const { id, icon, title, content, onAccept, onAcceptText, onCancel, onCancelText } = props;

	const actions = React.useMemo(() => {
		const actions = [];
		if (onCancel && onCancelText) actions.push(<Button id={`${id}CancelBtn`} className="lm-dialog-cancel-btn btn--grey btn--no-background" flat onClick={onCancel}>{onCancelText}</Button>);
		if (onAccept && onAcceptText) actions.push(<Button id={`${id}AcceptBtn`} className="lm-dialog-accept-btn btn--green btn--no-background" flat onClick={onAccept}>{onAcceptText}</Button>);
		return actions;
	}, [id, onCancel, onAccept, onCancelText, onAcceptText]);

	const dialogHeader = React.useMemo(() => (
		<>
			<div className="dialog-close">
				{onCancel ? <Button id={`${id}CloseBtn`} className="btn--no-background" flat onClick={onCancel}><FontIcon>clear</FontIcon></Button> : null}
			</div>
			<div className="dialog-header md-grid">
				{icon ? <div className="dialog-logo">{icon}</div> : null}
				<div className="dialog-title">{title}</div>
			</div>
		</>
	), [id, onCancel, icon, title]);

	const dialogContent = React.useMemo(() => (
		<div className="dialog-container">
			<div className="dialog-content">{content}</div>
		</div>
	), [content]);

	const onHide = React.useCallback(() => {
		if (onCancel) onCancel();
		else if (onAccept) onAccept();
	}, [onCancel, onAccept]);

	const dialog = React.useMemo(() => (
		<DialogContainer
			id={id}
			className="launchmetrics-dialog"
			visible={true}
			onHide={onHide}
			focusOnMount={false}
			aria-labelledby="launchmetrics-dialog"
			title={dialogHeader}
			children={dialogContent}
			footerClassName="dialog-footer md-grid"
			actions={actions}
		/>
	), [id, dialogHeader, dialogContent, actions, onHide]);

	return dialog;
};

export default React.memo(withT(Dialog));
