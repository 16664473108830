import React from 'react';

import { cloneDeep, keys, some } from 'lib/imports/lodash';
import { FontIcon, Collapse } from 'lib/imports/react-md';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import { SocialProfileExpression, SocialThreshold } from 'class/Feed';
import FocusFeedDefinitonLayoutThreshold from './Threshold/FocusFeedDefinitonLayoutThreshold';
import { unset } from 'lodash';

const FocusFeedDefinitionLayoutFollowers = (props: ComponentProps) => {
	const { definition, brand_sync, t } = props;
	const { onSetThreshold } = props;

	const [collapse, setCollapse] = React.useState<boolean>(true);

	const sectionDisabled = React.useCallback(() => {
		return some(definition.include_profiles, (element: SocialProfileExpression) => element.enabled && element.url !== '' && !element.error);
	}, [definition]);

	const onThresholdChange = React.useCallback((threshold: SocialThreshold, channel: number, value: number | "") => {
		const thresholdCopy = cloneDeep(threshold);
		if (value === '') {
			thresholdCopy.error = true;
			thresholdCopy.errorMessage = t('feed.form.threshold.invalid_value');
		} else if (channel === 30 && value < 500) {
			thresholdCopy.error = true;
			thresholdCopy.errorMessage = t('feed.form.threshold.invalid_min_value');
		} else {
			unset(thresholdCopy, 'error');
			unset(thresholdCopy, 'errorMessage');
		}
		thresholdCopy.value = value;
		onSetThreshold(channel, thresholdCopy);
	}, [onSetThreshold, t]);

	const followersSection = React.useMemo(() => {
		const isCollapsed = collapse || brand_sync;
		return (
			<div id="focusFeedDefinitionSocialThresholdSection" className={`definition-section ${brand_sync ? 'definition-section-disabled' : ''}`}>
				<div className="definition-section-header">
					<div className="definition-header-title" onClick={() => setCollapse(!collapse)}>
						{
							isCollapsed ?
								<FontIcon className="definition-header-title-toggle" >keyboard_arrow_right</FontIcon> :
								<FontIcon className="definition-header-title-toggle">keyboard_arrow_down</FontIcon>
						}
						<span>
							<span className="definition-header-title-text"> {t('feed.form.filter_threshold')} </span>
							- {t('feed.form.filter_mentions.description')}
						</span>
					</div>
				</div>
				<Collapse collapsed={isCollapsed}>
					<div className={`thresholds-container ${brand_sync && 'disabled'}`}>
						{sectionDisabled() ?
							<div className="threshold-section-warning">
								{t('feed.form.threshold.active_profiles_warning')}
							</div>
							: null}
						{keys(definition.threshold).map(channel => {
							const threshold = definition.threshold[+channel];
							return (
								<FocusFeedDefinitonLayoutThreshold
									key={'threshold_filter_channel' + channel}
									channel={+channel}
									checked={threshold.enabled}
									disabled={sectionDisabled()}
									value={threshold.value}
									error={threshold.error}
									errorMessage={threshold.errorMessage}
									onCheckToggled={checked => {
										const thresholdCopy = cloneDeep(threshold);
										thresholdCopy.enabled = checked;
										onSetThreshold(+channel, thresholdCopy);
									}}
									onValueChanged={value => onThresholdChange(threshold, +channel, value)}
								/>
							);
						})}
					</div>
				</Collapse>
			</div>
		);
	}, [brand_sync, collapse, definition.threshold, onSetThreshold, onThresholdChange, sectionDisabled, t]);

	return <>
		{followersSection}
	</>;
};

export default withT(React.memo(FocusFeedDefinitionLayoutFollowers));
