import { OldestDocumentObject } from "./Document";

// TODO: Remove this class and move type to entities types when all application uses ORM
export type FeedType = "online" | "socialmedia" | "print" | "print_dmr";
export type ExpressionsType = 'include_expressions' | 'exclude_expressions';

export type MediaExpressionType = 'include_medias' | 'exclude_medias';
export type ProfileExpressionsType = "include_profiles" | "exclude_profiles";

export type PrintExpressionType = "brands" | "product_types" | "shops";
export type PrintDmrExpressionType = "brands" | "product_types" | MediaExpressionType | ExpressionsType;

export type SocialExpressionsType = ExpressionsType | ProfileExpressionsType;
export type OnlineExpressionsType = ExpressionsType | MediaExpressionType;
export type PrintDMRExpressionsType = ExpressionsType | PrintDmrExpressionType;
export type GenericExpressionsType = OnlineExpressionsType | PrintDMRExpressionsType;

export const anywhereScope = ["tags", "mentions", "title", "content", "instagram.users_in_photo", "media_mentions", "sponsorized_mentions", "audio_to_text"];
export const hashtagScope = ["tags"];
export const mentionsScope = ["mentions", "media_mentions", "sponsorized_mentions", "instagram.users_in_photo"];
export const tapTagsScope = ["media_mentions", "instagram.users_in_photo"];
export const speechScope = ["audio_to_text"];
export const sponsoredByScope = ["sponsorized_mentions"];

export const isMentionsScope = (scope: ScopeSocial[]) => scope.join() === mentionsScope.join();
export const isHashtagScope = (scope: ScopeSocial[]) => scope.join() === hashtagScope.join();

export type ScopeSocial = "title" | "content" | "tags" | "mentions" | "instagram.users_in_photo" | "media_mentions" | "sponsorized_mentions" | "audio_to_text";
export type ScopeOnline = "title" | "content";
export type ScopePrintDMR = "title" | "content" | "print.product.name";
export type Scope = ScopePrintDMR | ScopeOnline ;
export type ScopeItem = {
	value: string,
	label: string
};

export type LoadingProfiles = {
	[key: string]: boolean[]
};
export type ErrorProfile = {
	url: string
	error: string
};

export type FeedCampaign = {
	campaign_id: string,
	enabled: boolean
};

export type DefinitionExpression = {
	enabled: boolean,
	q: string,
	scope: ScopeSocial[] | ScopeOnline[] | ScopePrintDMR[],
	error?: true
};
export type OnlineExpression = DefinitionExpression & {
	scope: ScopeOnline[],
	proximity?: string
}
export type OnlineExpressionValues = { [key in keyof Partial<OnlineExpression>]: OnlineExpression[key] };

export type SocialExpression = DefinitionExpression & {
	scope: ScopeSocial[]
};
export type SocialExpressionValues = { [key in keyof Partial<SocialExpression>]: SocialExpression[key] };
export type ProfileExpressionValues = { [key in keyof Partial<SocialProfileExpression>]: SocialProfileExpression[key] };

export type PrintDMRExpression = DefinitionExpression & {
	scope: ScopePrintDMR[],
	proximity?: string
}
export type PrintDMRExpressionValues = { [key in keyof Partial<PrintDMRExpression>]: PrintDMRExpression[key] };

export type GenericExpression = DefinitionExpression & {
	scope: Scope[],
	proximity?: string
}
export type GenericExpressionValues = { [key in keyof Partial<GenericExpression>]: GenericExpression[key] };

export type PrintDefinitionMedia = {
	// TODO GIS-3432 Define correctly
	name: string,
	url: string,
	id: string | number
}

export type MassiveMediaExpression = {
	id: string | number,
	name: string,
	url: string
	found?: boolean
	reason?: string
};

export type MediaExpression = {
	id: string | number,
	name: string,
	url?: string,
	country?: string
};

export type SocialProfileExpression = {
	api_version: string,
	channel_type_id: number,
	enabled: boolean
	id: string,
	name: string,
	picture: string,
	screen_name: string,
	url: string,
	error?: true
};

export type DefinitionSocialInstagramAccount = {
	id: string
	screen_name: string
	linkedExpression: {
		type: SocialExpressionsType | "main"
		index: number
	}
};

export type SocialThreshold = {
	value: number | '',
	enabled: boolean,
	error?: boolean,
	errorMessage?: ''
};

export type GenericSourceDefinition = {
	main: GenericExpression,
	include_expressions: GenericExpression[],
	exclude_expressions: GenericExpression[]
};

export type DefinitionOnline = {
	main: OnlineExpression,
	include_expressions: OnlineExpression[],
	exclude_expressions: OnlineExpression[],
	include_medias: MassiveMediaExpression[],
	exclude_medias: MassiveMediaExpression[]
};

export type DefinitionSocial = {
	main: SocialExpression,
	include_expressions: SocialExpression[],
	exclude_expressions: SocialExpression[],
	include_profiles: SocialProfileExpression[]
	exclude_profiles: SocialProfileExpression[],
	instagram_accounts: DefinitionSocialInstagramAccount[],
	threshold: {
		[key: number]: SocialThreshold
	}
};

export type Brand = {
	id: string
	enabled: boolean
	names: string[]
	scope: string
};

export type LinkedBrand = {
	id: string,
	name: string,
	isCrawled: boolean
}

export type ProductType = {
	id: string
	enabled: boolean
	names?: string[]
	scope?: string
};

export type Shop = {
	id: string
	enabled: boolean
	name: string
};

export type DefinitionPrintVB = {
	brands: Brand[],
	product_types: ProductType[],
	shops: Shop[]
};

export type DefinitionPrint = {
	include_expressions: PrintDMRExpression[],
	exclude_expressions: PrintDMRExpression[],
	brands: Brand[],
	product_types: ProductType[],
	include_medias: PrintDefinitionMedia[],
	exclude_medias: PrintDefinitionMedia[]
};

export type FeedFilter = {
	channel_type_id?: string[],
	"language.id"?: string[],
	"tenant.categories_id"?: string[],
	"tenant.topic_tag"?: string[],
	country_path?: string[],
	media_id?: string[],
	"photo.section.issue.media.country.path"?: string[],
	gender?: string[],
	"photo.section.issue.media.value"?: string[],
	"photo.type.id"?: string[],
	"photo.section.issue.media.frequency"?: string[],
	// New DMR facet keys
	"country.iso"?: string[],
	"print.source.language.iso"?: string[],
	"print.extra_clipping"?: string[],
	"print.article_type"?: string[],
	"print.source.frequency.name"?: string[]
};

export type FeedFilters = { [K in keyof FeedFilter]: string[] }

export type Definition = DefinitionOnline | DefinitionSocial | DefinitionPrintVB | DefinitionPrint;

export class FeedObject {
	public id: string;
	public type: FeedType;
	public name: string;
	public enabled: boolean;
	public disambiguation_model_id: number | null;
	public definition: Definition;
	public filters: FeedFilters;
	public focus_id: string;
	public oldest_document?: OldestDocumentObject;
	public inserted_at: string;
	public updated_at: string;
	public deleted_at: string;
	public brand_id?: string;
	public is_complex: boolean;
	public brand_sync: boolean;
	public sentiment_check: boolean;
	public approximate_hits: number;
	public feed_campaign: FeedCampaign[];

	constructor(values?: Partial<FeedObject>) {
		if (values) Object.assign(this, values);
	}
}

export type GenericDefinition = (DefinitionPrint | DefinitionOnline);

export type GenericMediaExpression = {
	id: string | number,
	name: string,
	url?: string,
	country?: string,
	found?: boolean,
	reason?: string
};

export class Feed extends FeedObject {
}
