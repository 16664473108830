import React from 'react';
import moment from 'moment';
import { DatePicker, SelectField } from 'lib/imports/react-md';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';

import './ReportSetupSettingsDate.scss';

const defaultPeriodIncreaseInDays = 15;

const __getMonthsItems = (fromDate: moment.MomentInput, toDate: moment.MomentInput) => {
	const minDate = moment(fromDate);
	const maxDate = moment(toDate);
	const monthItems = [];

	while (minDate.isSameOrBefore(maxDate, 'month')) {
		monthItems.push({
			label: maxDate.format('MMMM YYYY'),
			value: maxDate.format('MM-YYYY')
		});
		maxDate.subtract(1, 'months');
	}
	return monthItems;
};

const __getQuartersItems = (fromDate: moment.MomentInput, toDate: moment.MomentInput) => {
	const minDate = moment(fromDate);
	const maxDate = moment(toDate);
	const quarterItems = [];

	while (minDate.isSameOrBefore(maxDate, 'quarter')) {
		quarterItems.push({
			label: maxDate.format('[Q]Q YYYY'),
			value: maxDate.format('Q-YYYY')
		});
		maxDate.subtract(1, 'quarters');
	}
	return quarterItems;
};

const __getMonthDays = (date: moment.MomentInput) => {
	const startMonthDate = moment(date).startOf('month');
	const endMonthDate = moment.min(moment(date).endOf('month'), moment());
	return moment(endMonthDate).diff(startMonthDate, 'days') + 1;
};

const __getQuarterDays = (date: moment.MomentInput) => {
	const startQuarterDate = moment(date).startOf('quarter');
	const endQuarterDate = moment.min(moment(date).endOf('quarter'), moment());
	return moment(endQuarterDate).diff(startQuarterDate, 'days') + 1;
};

const ReportSetupSettingsDate: React.FC<ComponentProps> = props => {
	const { t, userSettings, focus, reportType, reportPeriod, reportMinDate, reportMaxDate } = props;
	const { onChangeMinDate, onChangeMaxDate } = props;
	const oldestDocumentDate = React.useCallback(() => {
		if (!focus) return;
		return focus.oldest_document ? focus.oldest_document.publication_date : focus.inserted_at;
	}, [focus])();

	const daysSelectorsContent = React.useMemo(() => {
		if (reportType === 'periodic' || !reportMinDate || !reportMaxDate) return null;

		const focusMinDate = oldestDocumentDate;
		return (
			<>
				<DatePicker
					id="reportSetupSettingsDateBeginDayPicker"
					label={t("reports.begin_date")}
					okLabel={t('filters.period.custom.dialog.ok')}
					cancelLabel={t('filters.period.custom.dialog.cancel')}
					fullWidth={false}
					portal
					icon={null}
					value={moment(reportMinDate).locale(userSettings.locale).toDate()}
					locales={userSettings.locale}
					minDate={moment(focusMinDate).subtract(1, 'day').toDate()}
					maxDate={moment(reportMaxDate).toDate()}
					onChange={(formattedDate, date, ev) => onChangeMinDate(moment(date).tz('UTC', true).format())}
				/>
				<DatePicker
					id="reportSetupSettingsDateEndDayPicker"
					label={t("reports.end_date")}
					okLabel={t('filters.period.custom.dialog.ok')}
					cancelLabel={t('filters.period.custom.dialog.cancel')}
					fullWidth={false}
					portal
					icon={null}
					value={moment(reportMaxDate).locale(userSettings.locale).toDate()}
					locales={userSettings.locale}
					minDate={moment(reportMinDate).toDate()}
					maxDate={moment().add(1, 'year').toDate()}
					onChange={(formattedDate, date, ev) => onChangeMaxDate(moment(date).tz('UTC', true).format())}
				/>
			</>
		);
	}, [t, userSettings, reportType, reportMinDate, reportMaxDate, oldestDocumentDate, onChangeMinDate, onChangeMaxDate]);

	const monthsSelectorsContent = React.useMemo(() => {
		if (reportType !== 'periodic' || reportPeriod !== 'monthly' || !reportMinDate || !reportMaxDate) return null;

		const minDate = oldestDocumentDate;
		const compareFromSelectMonths = __getMonthsItems(moment(minDate).add(1, 'month'), moment());
		const compareToSelectMonths = __getMonthsItems(minDate, moment(reportMaxDate).subtract(1, 'month'));
		const compareFromMonthDays = __getMonthDays(reportMaxDate);
		const compareToMonthDays = __getMonthDays(reportMinDate);

		return (
			<>
				<div className='select-wrapper'>
					<SelectField
						id="reportSetupSettingsDateMonthSelectFrom"
						label={t("reports.compare")}
						menuItems={compareFromSelectMonths}
						value={moment(reportMaxDate).format('MM-YYYY')}
						position="br"
						onChange={(dateStr: any) => onChangeMaxDate(moment(dateStr, 'MM-YYYY').add(defaultPeriodIncreaseInDays, "d").format())}
					/>
					<span>{t('reports.0_days_of_results', { count: compareFromMonthDays })}</span>
				</div>
				<div className='select-wrapper'>
					<SelectField
						id="reportSetupSettingsDateMonthSelectTo"
						label={t("reports.with")}
						menuItems={compareToSelectMonths}
						value={moment(reportMinDate).format('MM-YYYY')}
						position="br"
						onChange={(dateStr: any) => onChangeMinDate(moment(dateStr, 'MM-YYYY').add(defaultPeriodIncreaseInDays, "d").format())}
					/>
					<span>{t('reports.0_days_of_results', { count: compareToMonthDays })}</span>
				</div>
			</>
		);
	}, [t, oldestDocumentDate, reportType, reportPeriod, reportMinDate, reportMaxDate, onChangeMaxDate, onChangeMinDate]);

	const quartersSelectorsContent = React.useMemo(() => {
		if (reportType !== 'periodic' || reportPeriod !== 'quarterly' || !reportMinDate || !reportMaxDate) return null;

		const minDate = oldestDocumentDate;
		const compareFromSelectQuarters = __getQuartersItems(moment(minDate).add(1, 'quarter'), moment());
		const compareToSelectQuarters = __getQuartersItems(minDate, moment(reportMaxDate).subtract(1, 'quarter'));
		const compareFromQuarterDays = __getQuarterDays(reportMaxDate);
		const compareToQuarterDays = __getQuarterDays(reportMinDate);

		return (
			<>
				<div className='select-wrapper'>
					<SelectField
						id="reportSetupSettingsDateQuarterSelectFrom"
						label={t("reports.compare")}
						menuItems={compareFromSelectQuarters}
						value={moment(reportMaxDate).format('Q-YYYY')}
						position="br"
						onChange={(dateStr: any) => onChangeMaxDate(moment(dateStr, 'Q-YYYY').add(defaultPeriodIncreaseInDays, "d").format())}
					/>
					<span>{t('reports.0_days_of_results', { count: compareFromQuarterDays })}</span>
				</div>
				<div className='select-wrapper'>
					<SelectField
						id="reportSetupSettingsDateQuarterSelectTo"
						label={t("reports.with")}
						menuItems={compareToSelectQuarters}
						value={moment(reportMinDate).format('Q-YYYY')}
						position="br"
						onChange={(dateStr: any) => onChangeMinDate(moment(dateStr, 'Q-YYYY').add(defaultPeriodIncreaseInDays, "d").format())}
					/>
					<span>{t('reports.0_days_of_results', { count: compareToQuarterDays })}</span>
				</div>
			</>
		);
	}, [t, oldestDocumentDate, reportType, reportPeriod, reportMinDate, reportMaxDate, onChangeMaxDate, onChangeMinDate]);

	if (userSettings.locale) {
		moment.locale(userSettings.locale.toLowerCase()); // set locale to moment
	}

	return (focus &&
		<div id="reportSetupSettingsDate" className="form-section" >
			<div className="form-section-title">
				{t('reports.time_range')}
			</div>
			<div className="form-section-content">
				<div className="form-section-date">
					{daysSelectorsContent}
					{monthsSelectorsContent}
					{quartersSelectorsContent}
				</div>
				<div className="form-section-date-note">
					<span dangerouslySetInnerHTML={{ __html: t('reports.time_range_notice', { publicationDate: moment(oldestDocumentDate).format('L') }) }}></span>
				</div>

			</div>
		</div>
	);
};

export default withT(React.memo(ReportSetupSettingsDate));
