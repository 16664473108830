import { all, put, debounce } from 'redux-saga/effects';
import Api from 'lib/ajax/Api';
import { callCatch } from 'lib/store/sagas';

import { Actions, operators } from './actions';

export default function* sagas() {
	yield all([
		debounce(200, operators.fetchBrands.type, fetchBrands),
		debounce(200, operators.fetchShops.type, fetchShops)
	]);
}

type FetchParams = { query: string, limit: number }
interface FetchBrandsParams extends FetchParams {
	exclude_brands?: string
	exclude_companies?: string
	exclude_lines?: string
}
function* fetchBrands({ payload: { query, limit, excluded } }: Actions["FetchBrands"]) {
	const api = new Api();

	const params: FetchBrandsParams = { query, limit };
	if (excluded.brand) params.exclude_brands = excluded.brand.join(',');
	if (excluded.company) params.exclude_companies = excluded.company.join(',');
	if (excluded.line) params.exclude_lines = excluded.line.join(',');

	yield put(operators.fetchBrandsStart());
	const brandsApiResponse = yield callCatch(
		[[api, 'get'], `/definition/print/brand/search`, { params }],
		operators.fetchBrandsError
	);
	if (brandsApiResponse) yield put(operators.fetchBrandsSuccess(brandsApiResponse));
}

interface FetchShopsParams extends FetchParams {
	exclude?: string
}
function* fetchShops({ payload: { query, limit, excluded } }: Actions["FetchShops"]) {
	const api = new Api();

	const params: FetchShopsParams = { query, limit };
	if (excluded.shops) params.exclude = excluded.shops.join(',');

	yield put(operators.fetchShopsStart());
	const shopsApiResponse = yield callCatch(
		[[api, 'get'], `/definition/print/shop/search`, { params }],
		operators.fetchShopsError
	);
	if (shopsApiResponse) yield put(operators.fetchShopsSuccess(shopsApiResponse));
}
