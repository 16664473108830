import React from 'react';
import { withT } from 'lib/i18n';
import { AutocompleteProps } from 'react-md/lib/Autocompletes/Autocomplete';
import { orderBy, transform, keyBy, some, filter, isEmpty } from 'lib/imports/lodash';
import { Autocomplete, Button } from 'lib/imports/react-md';

import CategorizationCategoryChip from './Chip';
import { ComponentProps } from './types';

import './SelectCategory.scss';

type AutocompleterItem = AutocompleteProps['data'][number];

const DEFAULT_LEVELS = [3];
const PATH_LENGTHS = [2, 4, 6] as const;
const PATH_LENGTHS_ITERABLE = Array.from(PATH_LENGTHS);

type PathLength = typeof PATH_LENGTHS[number];
type AllowedPathLengths = Partial<{ [index in PathLength]: true }>;

const SelectCategory: React.FC<ComponentProps> = props => {
	const { t, tenant, categories, levels, multiple = false, clearOnAutocomplete = false, showNewlabel = false, allDmrCategories = {}, filteredDmrCategories = {} } = props;
	const { getCategoryName, onAddCategory, onRemoveCategory } = props;

	const [categoryInputValue, setCategoryInputValue] = React.useState("");

	const allowedCategories = React.useMemo(() => {

		// TODO Remove check once we only use new DMR categories
		const allowedPathLengths = transform(levels || DEFAULT_LEVELS, (result: AllowedPathLengths, level) => result[(level * 2) as PathLength] = true, {});
		return showNewlabel ? Object.keys(filteredDmrCategories) : filter(tenant.getCategories(), categoryKey => {
			if (allowedPathLengths[categoryKey.length as PathLength]) return true;
			return false;
		});
	}, [tenant]); // eslint-disable-line react-hooks/exhaustive-deps

	const getNewCategoryTextFromPath = React.useCallback((categoryPath: string) => {
		const categoryIds = categoryPath.split('-');
		if (categoryIds.length === 1) return getCategoryName(categoryPath);

		const texts: (string | undefined)[] = [getCategoryName(categoryIds[0])];
		texts.push(getCategoryName(`${categoryIds[0]}-${categoryIds[1]}`));
		if (categoryIds.length === 3) texts.push(getCategoryName(`${categoryIds[0]}-${categoryIds[1]}-${categoryIds[2]}`));

		return texts.join(' > ') || "";
	}, [getCategoryName]);

	const getCategoryTextFromPath = React.useCallback((categoryPath: string) => {
		if (showNewlabel) return getNewCategoryTextFromPath(categoryPath);
		const texts = transform(PATH_LENGTHS_ITERABLE, (result: string[], sliceLength) => {
			if (sliceLength > categoryPath.length) return;
			result.push(t(`filters.categories.${categoryPath.slice(0, sliceLength)}`));
		}, []);

		if (texts.length === 1) texts.push(`(${t('categories.all_categories_and_products')})`);
		else if (texts.length === 2) texts.push(`(${t('categories.all_products')})`);

		return texts.join(' > ');
	}, [getNewCategoryTextFromPath, showNewlabel, t]);

	const selectedCategoriesByPath = React.useMemo(() => keyBy(categories), [categories]);

	const isCategoryPathSelected = React.useCallback((categoryPath: string): boolean => {
		if (!multiple) return false;
		return some(PATH_LENGTHS_ITERABLE, sliceLength => {
			if (sliceLength > categoryPath.length) return false;
			return categoryPath.slice(0, sliceLength) in selectedCategoriesByPath;
		});
	}, [multiple, selectedCategoriesByPath]);

	const getAutocompleterItemFromCategoryPath = React.useCallback((categoryPath: string): AutocompleterItem | undefined => {
		if (isCategoryPathSelected(categoryPath)) return;

		const text = getCategoryTextFromPath(categoryPath) || "";

		if (categoryPath.length === 6 && text.match(/\(adv\)$/)) return; // TODO: remove paths from config

		return { id: categoryPath, primaryText: text };
	}, [isCategoryPathSelected, getCategoryTextFromPath]);

	const autocompleterItems = React.useMemo(() => {
		const categoryItems = transform(allowedCategories, (result: AutocompleterItem[], categoryPath) => {
			const item = getAutocompleterItemFromCategoryPath(categoryPath);
			if (item) result.push(item);
		}, []);
		return orderBy(categoryItems, 'primaryText', 'asc');
	}, [allowedCategories, getAutocompleterItemFromCategoryPath]);

	const selectedCategoriesContent = React.useMemo(() => {
		if (!multiple) return null;
		return (
			<div className="categorization-category-selected">
				{categories.map(categoryPath => (
					<CategorizationCategoryChip key={categoryPath} categoryId={categoryPath} getCategoryName={getCategoryName} dmrCategories={allDmrCategories} isRemovable
						onCategoryRemoved={() => {
							if (onRemoveCategory) onRemoveCategory(categoryPath);
						}}
					/>
				))}
			</div>
		);
	}, [allDmrCategories, categories, multiple, getCategoryName, onRemoveCategory]);

	const labelText = showNewlabel ? t('results.categorize.label.new_category') : t('results.categorize.label.category');

	return (
		<>
			<div id={showNewlabel ? "categorizationSelectNewCategory" : "categorizationSelectCategory"} className="categorization-category">
				<span className="categorization-category-label">{labelText}</span>
				<Autocomplete
					id={showNewlabel ? "newCategoriesAutocompleter" : "categoriesAutocompleter"}
					className="categorization-category-input"
					data={autocompleterItems}
					disabled={isEmpty(autocompleterItems)}
					filter={Autocomplete.caseInsensitiveFilter}
					value={categoryInputValue}
					onChange={text => setCategoryInputValue(text.toString())}
					onAutocomplete={(suggestion, index, results: any) => {
						if (multiple || clearOnAutocomplete) setCategoryInputValue('');
						else setCategoryInputValue(results[index].primaryText);
						onAddCategory(results[index].id);
					}}
					clearOnAutocomplete={multiple || clearOnAutocomplete}
					showUnfilteredData={true}
				/>
				<Button
					id={showNewlabel ? "categorizationNewCategoryRemoveButton" : "categorizationCategoryRemoveButton"}
					icon
					iconChildren="close"
					children=""
					onClick={() => setCategoryInputValue('')}
				/>
			</div>
			{selectedCategoriesContent}
		</>
	);
};

export default withT(React.memo(SelectCategory));
