import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { State } from 'store/types';
import TagSelectors from 'store/entities/Tag/selectors';
import { TagInstance } from 'store/entities/Tag';
import CategorizationTopicTagChip from './CategorizationTopicTagChip';
import { DispatchProps, StateProps, OwnProps } from './types';

const getTagWithTopic = createSelector(
	(state: State, tagId: string) => TagSelectors.getInstance(state, tagId),
	(tag: TagInstance | null) => tag ? { ...tag.ref, topic: tag.topic.ref } : null
);

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
	tag: getTagWithTopic(state, ownProps.tagId)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CategorizationTopicTagChip);
