import without from 'lodash/without';

import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { Actions, operators } from './actions';
import { FocusObject } from 'class/Focus';

export type State = {
	readonly focusList: FocusObject[] | null
	readonly loading: boolean
	readonly manageFocusLoadingUsers: string[]
};

export const INITIAL_STATE: State = {
	focusList: null,
	loading: false,
	manageFocusLoadingUsers: []
};

const reducerHandlers: ReducerHandlers<State> = {
	fetchFocusList: (state, { payload }: Actions["FetchFocusList"]): State => ({
		...state,
		loading: true
	}),
	fetchFocusListSuccess: (state, { payload: { focusList } }: Actions["FetchFocusListSuccess"]): State => ({
		...state,
		loading: false,
		focusList
	}),
	fetchFocusListError: (state): State => ({
		...state,
		loading: false,
		focusList: null
	}),
	createFocus: (state, { payload }: Actions["CreateFocus"]): State => ({
		...state,
		loading: true
	}),
	createFocusSuccess: (state, { payload: { focusList } }: Actions["CreateFocusSuccess"]): State => ({
		...state,
		loading: false,
		focusList
	}),
	createFocusError: (state): State => ({
		...state,
		loading: false
	}),
	renameFocus: (state, { payload }: Actions["RenameFocus"]): State => ({
		...state,
		loading: true
	}),
	renameFocusSuccess: (state, { payload: { focusList } }: Actions["RenameFocusSuccess"]): State => ({
		...state,
		loading: false,
		focusList
	}),
	renameFocusError: (state): State => ({
		...state,
		loading: false
	}),
	removeFocus: (state, { payload }: Actions["RemoveFocus"]): State => ({
		...state,
		loading: true
	}),
	removeFocusSuccess: (state, { payload: { focusList } }: Actions["RemoveFocusSuccess"]): State => ({
		...state,
		loading: false,
		focusList
	}),
	removeFocusError: (state): State => ({
		...state,
		loading: false
	}),
	removeFeed: (state, { payload }: Actions["RemoveFeed"]): State => ({
		...state,
		loading: true
	}),
	removeFeedSuccess: (state, { payload: { focusList } }: Actions["RemoveFeedSuccess"]): State => ({
		...state,
		loading: false,
		focusList
	}),
	removeFeedError: (state): State => ({
		...state,
		loading: false
	}),
	updateFocusUserAllowAccessSuccess: (state, { payload: { focusList } }: Actions["UpdateFocusUserAllowAccessSuccess"]): State => ({
		...state,
		focusList
	}),
	addManageFocusUserLoading: (state, { payload: { userId } }: Actions["AddManageFocusUserLoading"]): State => ({
		...state,
		manageFocusLoadingUsers: [...state.manageFocusLoadingUsers, userId]
	}),
	removeManageFocusUserLoading: (state, { payload: { userId } }: Actions["RemoveManageFocusUserLoading"]): State => ({
		...state,
		manageFocusLoadingUsers: without([...state.manageFocusLoadingUsers], userId)
	})
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
