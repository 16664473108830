import { createSelector } from "reselect";
import { ormEntities, createOrmSelector, OrmSelector, sessionSelector } from "store/entities/selectors";

import { TagObject } from "store/entities/Tag";
import { TopicObject } from "./types";

export const get: OrmSelector<TopicObject> = createOrmSelector(ormEntities.Topic);
export const getTags: OrmSelector<TagObject[]> = createOrmSelector(ormEntities.Topic.tags);
export const getTagsWithTagAutomation = createSelector(sessionSelector, (_: any, id: string) => id, ({ Topic }, id) => {
	const topic = Topic.withId(id);
	if (!topic || !topic.tags) return [];

	const tagsFromTopic = topic.tags.toModelArray().map(tag => ({
		...tag.ref,
		tag_automation: tag.tag_automation ? tag.tag_automation.ref : undefined
	})) || [];
	return tagsFromTopic;
});
export const getWithTags = createSelector(sessionSelector, ({ Topic }) =>
	Topic.all().toModelArray().map(topic => ({
		...topic.ref,
		tags: topic.tags ? topic.tags.toModelArray().map(tag => ({
			...tag.ref,
			tag_automation: tag.tag_automation ? tag.tag_automation.ref : undefined
		})) : []
	}))
);

export default {
	get,
	getTags,
	getTagsWithTagAutomation,
	getWithTags
};
