import { all, takeLatest, call, put } from 'redux-saga/effects';
import Api from 'lib/ajax/Api';
import { operators } from './actions';

export default function* sagas() {
	yield all([
		takeLatest(operators.fetchTags.type, fetchTags)
	]);
}

function* fetchTags() {
	const api = new Api();

	try {
		const tags = yield call([api, 'get'], '/tags');
		yield put(operators.fetchTagsSuccess({ tags }));
	} catch (error) {
		yield put(operators.fetchTagsError({ error }));
	}
}
