import React from 'react';
import { Button, CircularProgress, Collapse, DialogContainer, FontIcon, SelectionControl, TextField } from 'lib/imports/react-md';
import { concat, every, filter, find, flatten, forEach, get, includes, isEmpty, keys, orderBy, some, sortBy, sum, trim, uniqBy, values } from 'lib/imports/lodash';

import GA from 'lib/googleAnalytics';
import { withT } from 'lib/i18n';
import { FilterFacet } from 'class/Filter';
import { Facet, FacetObject } from 'class/Facet';
import { Tree, TreeNode, TreeUtils } from 'class/Tree';
import featureFlags from 'lib/featureFlags';
import { parkluChanelTypes } from 'lib/providerDiscovery';
import { ShowMoreFacetItem, FacetItemHash } from 'store/search/facets/extended';
import { ComponentProps } from './types';

import './SearchFacetsDialog.scss';
import { getIsoFromPath } from './countryHelper';

const groupsWithCollapse = [Facet.categoriesGroupKeyVB, Facet.countryGroupKey];

class SearchFacetsDialog extends React.Component<ComponentProps> {

	private filterInputTimeout: NodeJS.Timeout | null = null;
	private noItemsToShow: boolean = true;

	private _getFacetItemCounter = (facetValue: string): number => {
		const { groupKey, facets } = this.props;

		const facetItem = facets && facets.groups[groupKey] ? find(facets.groups[groupKey], { key: facetValue }) : null;
		return facetItem && facetItem.counter ? facetItem.counter : 0;
	};

	private _getFacetItemText = (facetValue: string, lang?: string): string => {
		const { t, groupKey, facetItemsHash, dmrCategories } = this.props;
		const langOptions: { lng?: string } = {};
		if (groupKey.match('photo.') || groupKey === Facet.frequencyKey) {
			facetValue = facetValue.replace(' ', '_');
		}

		if (lang) langOptions.lng = "en";
		if (groupKey === Facet.mediaGroupKey) return facetItemsHash[facetValue].node.data.text;
		if (groupKey === Facet.tagsGroupKey) return facetItemsHash[facetValue].node.data.text;

		// TODO GIS-3506 Get from Mekong's response
		if (groupKey === Facet.categoriesKey) return dmrCategories[facetValue];

		const groupText = this._getGroupTextByGroupKey(groupKey);
		return t(`filters.${groupText}.${facetValue}`, langOptions);
	};

	private _getGroupTextByGroupKey = (groupKey: string): string => {
		switch (groupKey) {
			case Facet.countryPathKeyVB:
			case Facet.countryPathKey: return "country";
			case Facet.frequencyKeyVB:
			case Facet.frequencyKey: return "media_frequency";
			case Facet.categoriesGroupKeyVB: return "categories";
			case Facet.photoTypeKey: return "photo_type";
			case Facet.printMediaLevelKey: return "media_value";
			case Facet.languageGroupKey: return "language";
			case Facet.placementType: return "article_type";
			case Facet.coverageType: return "coverage_type";
			default: return groupKey.replace(/language\.id/, 'language_id').replace(/^.*\.|_.*$/g, '');
		}
	};

	private _getGroupText = (lang?: string): string => {
		const { t, groupKey } = this.props;
		let groupText = groupKey.replace(/_(.*)/gi, '');
		groupText = groupText.replace(/tenant./gi, '');

		const langOptions: { lng?: string } = {};
		if (lang) langOptions.lng = lang;
		if (groupKey === Facet.tagsGroupKey) return t(`filters.${groupText}`, langOptions);
		else return t(`filters.${groupText}.value`, langOptions);
	};

	private _cleanText = (text: string | undefined): string | undefined => {
		const { groupKey } = this.props;

		if (!text) return;

		if (groupKey === Facet.mediaGroupKey) {
			text = text.replace(/http:\/\/|https:\/\//, "");
		}

		return text;
	};

	private _filterInputChanged = (query: string) => {
		const { groupKey, onSetFilterText } = this.props;
		const { onFetchQueriedMedias } = this.props;

		onSetFilterText(query);

		if (this.filterInputTimeout) clearTimeout(this.filterInputTimeout);
		this.filterInputTimeout = setTimeout(() => {
			if (groupKey === Facet.mediaGroupKey) onFetchQueriedMedias(query);
		}, 200);
	};

	private _isFacetItemSelected = (node: TreeNode<ShowMoreFacetItem>, prevItemsHash: FacetItemHash): boolean => {
		const { groupKey, facetFilters } = this.props;

		if (prevItemsHash[node.data.value]) return prevItemsHash[node.data.value].selected;
		return facetFilters[groupKey] && !!find(facetFilters[groupKey], filterFacet => filterFacet.key === node.data.value);
	};

	private _createFacetItemHash = (nodes: Array<TreeNode<ShowMoreFacetItem>>, itemsHash: FacetItemHash, prevItemsHash: FacetItemHash): FacetItemHash => {
		forEach(nodes, node => {
			itemsHash[node.data.value] = {
				collapsed: false,
				selected: this._isFacetItemSelected(node, prevItemsHash),
				node
			};
			if (node.children) itemsHash = this._createFacetItemHash(node.children, itemsHash, prevItemsHash);
		});
		return itemsHash;
	};

	private _getHierarchicalTopicTagsTree = (): TreeNode<ShowMoreFacetItem>[] => {
		const { t, topics, facets } = this.props;

		const topicsNodes = topics.map((topic: { id: any; name: any; tags: { id: any; name: any; }[]; }) => {
			const node: TreeNode<ShowMoreFacetItem> = {
				data: {
					value: topic.id,
					text: topic.name,
					counter: 0
				}
			};
			node.children = sortBy(topic.tags.map(tag => ({
				data: {
					value: `${topic.id}_${tag.id}`,
					text: tag.name,
					counter: this._getFacetItemCounter(`${topic.id}_${tag.id}`)
				},
				parent: node
			})), childNode => childNode.data.text.toLowerCase());
			return node;
		});

		if (find(get(facets, ['groups', Facet.tagsGroupKey]), { key: Facet.untaggedDocumentKey })) {
			const untaggedNode = {
				data: {
					value: Facet.untaggedDocumentKey,
					text: t(`filters.untagged_documents_facet_tag`),
					counter: this._getFacetItemCounter(Facet.untaggedDocumentKey)
				}
			};
			return [untaggedNode, ...topicsNodes];
		}
		return topicsNodes;
	};

	private _getHierarchicalMoreItem = (itemValue: string, parentNode: TreeNode<ShowMoreFacetItem> | null, allValues: string[]): TreeNode<ShowMoreFacetItem> => {
		const { groupKey } = this.props;
		const finalItemValue = groupKey === Facet.countryPathKey ? getIsoFromPath(itemValue) : itemValue;
		const item: ShowMoreFacetItem = {
			value: finalItemValue,
			text: this._getFacetItemText(itemValue),
			counter: this._getFacetItemCounter(itemValue)
		};

		const node: TreeNode<ShowMoreFacetItem> = { data: item };

		const children = filter(allValues, value => value.length === (itemValue.length + 2) && value.startsWith(itemValue));
		if (children.length) {
			let childrenNodes = children.map(childItem => this._getHierarchicalMoreItem(childItem, node, allValues));
			item.counter = item.counter + sum(childrenNodes.map(childNode => childNode.data.counter));

			if (groupKey === Facet.categoriesGroupKeyVB) {
				childrenNodes = filter(childrenNodes, childrenNode => !childrenNode.data.text.match(/\(adv\)$/));
			}

			node.children = sortBy(childrenNodes, [childNode => childNode.data.text.toLowerCase()]);
		}
		if (parentNode) node.parent = parentNode;

		return node;
	};

	private _buildCategoryHierarchy = (itemValue: string, parentNode: TreeNode<ShowMoreFacetItem> | null, allValues: string[]): TreeNode<ShowMoreFacetItem> => {
		const item: ShowMoreFacetItem = {
			value: itemValue,
			text: this._getFacetItemText(itemValue),
			counter: this._getFacetItemCounter(itemValue)
		};

		const node: TreeNode<ShowMoreFacetItem> = { data: item };

		const itemLevel = itemValue.split('-').length;
		const children = filter(allValues, child => {
			if (!child.startsWith(itemValue) || child === itemValue) return false;
			const childLevel = child.split('-').length;
			return itemLevel + 1 === childLevel;
		});
		if (children.length) {
			let childrenNodes = children.map(childItem => this._buildCategoryHierarchy(childItem, node, allValues));
			item.counter = item.counter + sum(childrenNodes.map(childNode => childNode.data.counter));
			node.children = sortBy(childrenNodes, [childNode => childNode.data.text.toLowerCase()]);
		}
		if (parentNode) node.parent = parentNode;

		return node;
	};

	private _getItemsFromGroupKey = (facets: FacetObject, prevItemsHash: FacetItemHash): Array<TreeNode<ShowMoreFacetItem>> => {
		const { groupKey, queriedMedias, facetFilters, dmrCategories } = this.props;

		let nodes: Array<TreeNode<ShowMoreFacetItem>> = [];
		const getFacetDataObject = (facet: string) => ({ data: { value: facet, counter: this._getFacetItemCounter(facet), text: this._getFacetItemText(facet) } });

		if (groupKey === Facet.languageGroupKeyVB) {
			nodes = Facet.languageFacetValues.map(lang => getFacetDataObject(lang));
		} else if (groupKey === Facet.languageGroupKey) {
			nodes = Facet.languageFacetCodes.map(lang => getFacetDataObject(lang));
		}
		else if (groupKey === Facet.tagsGroupKey) {
			nodes = this._getHierarchicalTopicTagsTree();
		} else if (groupKey === Facet.mediaGroupKey) {
			nodes = concat(facets.groups[Facet.mediaGroupKey], queriedMedias).map(facetItem => ({
				data: {
					value: facetItem.key,
					counter: this._getFacetItemCounter(facetItem.key),
					text: facetItem.name!,
					type: facetItem.type,
					detail: facetItem.detail
				}
			}));
			// Add selected items in facets store
			if (facetFilters[groupKey]) nodes = concat(nodes, facetFilters[groupKey].map(facetFilter => ({
				data: { value: facetFilter.key, text: facetFilter.name!, detail: facetFilter.detail, counter: 0 }
			})));
			// Add previously selected items in show more dialog
			nodes = concat(nodes, filter(keys(prevItemsHash).map(key => prevItemsHash[key]), item => item.selected).map(item => ({
				data: {
					value: item.node.data.value,
					text: item.node.data.text,
					detail: item.node.data.detail,
					counter: item.node.data.counter
				}
			})));
			// Remove duplicates
			nodes = uniqBy(nodes, node => node.data.value);
		}
		else if (groupKey === Facet.eventKey) {
			const { getEventName } = this.props;
			nodes = facets.groups[Facet.eventKey].map(facetItem => ({
				data: {
					value: facetItem.key,
					counter: this._getFacetItemCounter(facetItem.key),
					text: getEventName(facetItem.key) || ''
				}
			}));
			// Remove duplicates
			nodes = uniqBy(nodes, node => node.data.value);
		} else if (groupKey === Facet.channelGroupKey) {
			const channelFacets = concat(Facet.channelFacetTypes.online, Facet.channelFacetTypes.socialmedia, Facet.channelFacetTypes.print, Facet.channelFacetTypes.print_dmr);
			nodes = channelFacets.map(channelFacet => getFacetDataObject(channelFacet));
		} else if (includes([Facet.categoriesGroupKeyVB, Facet.countryGroupKey, Facet.countryPathKeyVB, Facet.countryPathKey], groupKey)) {
			let facetValues: string[] = [];
			if (groupKey === Facet.countryGroupKey || groupKey === Facet.countryPathKeyVB || groupKey === Facet.countryPathKey) {
				facetValues = Facet.countryFacetValues;
			}
			else if (groupKey === Facet.categoriesGroupKeyVB) {
				facetValues = Facet.categoriesFacetValues;
			}

			const firstLevelItems = filter(facetValues, value => value.length === 2);
			nodes = firstLevelItems.map(item => this._getHierarchicalMoreItem(item, null, facetValues));
			if (groupKey === Facet.categoriesGroupKeyVB) {
				nodes = filter(nodes, node => !node.data.text.match(/\(adv\)$/));
			}
		} else if (groupKey === Facet.frequencyKeyVB || groupKey === Facet.frequencyKey) {
			const frequencyFacets = Facet.frequencyValues;
			nodes = frequencyFacets.map(frequencyFacet => getFacetDataObject(frequencyFacet));
		} else if (groupKey === Facet.genderKey) {
			const genderFacets = Facet.genderValues;
			nodes = genderFacets.map(genderFacet => getFacetDataObject(genderFacet));
		} else if (groupKey === Facet.printMediaLevelKey) {
			const printMediaGroup = Facet.printMediaGroups;
			nodes = printMediaGroup.map(group => getFacetDataObject(group));
		} else if (groupKey === Facet.photoTypeKey) {
			const photoTypeFacets = Facet.photoTypeValues;
			nodes = photoTypeFacets.map(photoTypeFacet => getFacetDataObject(photoTypeFacet));
		} else if (groupKey === Facet.coverageType) {
			const coverageTypeValues = Facet.coverageTypeValues;
			nodes = coverageTypeValues.map(coverageTypeFacet => getFacetDataObject(coverageTypeFacet));
		} else if (groupKey === Facet.placementType) {
			const articleTypeValues = Facet.articleTypeValues;
			nodes = articleTypeValues.map(articleTypeValue => getFacetDataObject(articleTypeValue));
		} else if (groupKey === Facet.categoriesKey) {
			const facetValues = Object.keys(dmrCategories);
			const firstLevelItems = filter(facetValues, value => value.split('-').length === 1);
			nodes = firstLevelItems.map(item => this._buildCategoryHierarchy(item, null, facetValues));
		}

		if (groupKey === Facet.mediaGroupKey || groupKey === Facet.eventKey) return orderBy(nodes, [node => node.data.counter, node => node.data.text.toLowerCase()], ["desc", "asc"]);
		return sortBy(nodes, [node => node.data.text.toLowerCase()]);
	};

	private _getItemContent = (node: TreeNode<ShowMoreFacetItem>, level: number) => {
		const { facetItemsHash, seeOnlySelected, groupKey } = this.props;
		const { onToggleItemCollapsed, onToggleSelectedFilter } = this.props;
		const filterText = trim(this.props.filterText);

		const itemValue = node.data.value;
		const hashItem = facetItemsHash[itemValue];
		const customFacet = Facet.isCustomFacet(groupKey, { ...node.data, key: itemValue, name: node.data.text });
		const itemText = this._cleanText(node.data.text);
		const itemDetailText = this._cleanText(node.data.detail);
		const itemCollapsed = hashItem.collapsed;
		const itemSelected = hashItem.selected || some(TreeUtils.getNodeParentsAttributeValues(node, 'value').map(parentItemValue => facetItemsHash[parentItemValue].selected), Boolean);
		const itemHasChildSelected = some(TreeUtils.getNodeDescendentsAttributeValues(node, 'value', false).map(descendentValue => facetItemsHash[descendentValue].selected), Boolean);
		const itemTextNotMatchesFilter = !isEmpty(filterText)
			&& !some([node.data.text, ...TreeUtils.getNodeDescendentsAttributeValues(node, 'text', false)], txt => txt.toLowerCase().includes(filterText.toLowerCase()));
		/* eslint-disable no-mixed-operators */
		const itemDetailNotMatchesFilter = !node.data.detail || !isEmpty(filterText)
			&& !some([node.data.detail, ...TreeUtils.getNodeDescendentsAttributeValues(node, 'detail', false)], txt => txt.toLowerCase().includes(filterText.toLowerCase()));
		/* eslint-enable no-mixed-operators */

		if ((itemTextNotMatchesFilter && itemDetailNotMatchesFilter) || (seeOnlySelected && !itemSelected && !itemHasChildSelected)) return null;
		this.noItemsToShow = false;

		let collapseIcon = null;
		let childItemsContent = null;
		if (node.children && !isEmpty(node.children)) {
			const collapseIconTxt = itemCollapsed ? "keyboard_arrow_right" : "keyboard_arrow_down";
			collapseIcon = <Button id={`searchFacetsShowMoreDialogItem-${itemValue}-collapseBtn`} icon onClick={() => onToggleItemCollapsed(itemValue)}>{collapseIconTxt}</Button>;
			childItemsContent = (
				<Collapse collapsed={itemCollapsed}>
					<div>
						{node.children.map(childNode => this._getItemContent(childNode, level + 1))}
					</div>
				</Collapse>
			);
		}
		return (
			<React.Fragment key={itemValue}>
				<div id={`searchFacetsShowMoreDialogItem-${itemValue}`} className={`search-facets-dialog-item item-level-${level}`}>
					<SelectionControl
						id={`searchFacetsShowMoreDialogCheckbox-${itemValue}`}
						name={`searchFacetsShowMoreDialogCheckboxs-${itemValue}[]`}
						label=""
						type="checkbox"
						className={itemSelected || itemHasChildSelected ? "checkbox-green" : ""}
						checked={itemSelected}
						uncheckedCheckboxIcon={itemHasChildSelected ? <FontIcon>indeterminate_check_box</FontIcon> : <FontIcon>check_box_outline_blank</FontIcon>}
						onChange={() => {
							const trackAction = `Filter by ${this._getGroupText("en")}`;
							const trackLabel = this._getFacetItemText(node.data.value, "en");
							GA.trackEvent({ category: "Feed results", action: trackAction, label: trackLabel });
							onToggleSelectedFilter(node, itemSelected);
						}}
					/>
					{collapseIcon}
					<div className="search-facets-dialog-item-name">
						<span className={`search-facets-dialog-item-name-text${customFacet ? ' custom-facet' : ''}`}>{itemText}</span>
						{node.data.detail ? <span className="search-facets-dialog-item-detail">{itemDetailText}</span> : null}
						{node.data.counter ? <span className="search-facets-dialog-item-green"> - </span> : null}
						{node.data.counter ? <span className="search-facets-dialog-item-green">{node.data.counter}</span> : null}
					</div>
				</div>
				{childItemsContent}
			</React.Fragment>
		);
	};

	private _filterFacetItemsByProvider = () => {
		const { facetItems, feedType, groupKey } = this.props;
		if (groupKey !== Facet.channelGroupKey) return facetItems;
		if (feedType) { // definition facets
			if (feedType !== 'socialmedia') {
				return this.filterFacetItemsByFeedType();
			} else {
				let filteredFacetItems = this.filterFacetItemsByFeedType();
				return this.filterSocialmediaFacetsByFeatureFlag(filteredFacetItems);
			}
		} else { // article facets
			let filteredFacetItems = TreeUtils.createTree(facetItems.rootNodes);
			return this.filterSocialmediaFacetsByFeatureFlag(filteredFacetItems);
		}
	};

	private filterSocialmediaFacetsByFeatureFlag = (facetItems: Tree<ShowMoreFacetItem>) => {
		if (!featureFlags.isEnabled('parklu')) {
			facetItems = TreeUtils.createTree(facetItems.rootNodes.filter(node => !(node.data.value in parkluChanelTypes)));
		}

		return facetItems;
	};

	private filterFacetItemsByFeedType = () => {
		const { facetItems, feedType } = this.props;
		return TreeUtils.createTree(facetItems.rootNodes.filter(node => (!!Facet.channelFacetTypes[feedType!].find(channelType => channelType === node.data.value))));
	};

	private _getDialogContent = () => {
		const { t, loadingFacetsGroup, filterText } = this.props;

		const filteredFacetItems = this._filterFacetItemsByProvider();

		this.noItemsToShow = true;
		if (loadingFacetsGroup) return <div className="search-facets-dialog-content"><CircularProgress id="searchFacetsShowMoreDialogLoading" /></div>;

		return (
			<div className="search-facets-dialog-content">
				<TextField
					id="searchFacetsShowMoreDialogFilterInput"
					className="filter-input"
					placeholder={t('filters.modal.filter-placeholder')}
					fullWidth={false}
					value={filterText}
					onChange={text => this._filterInputChanged(text.toString())}
					leftIcon={<FontIcon className="left-icon">search_outline</FontIcon>}
					inlineIndicator={<Button id="searchFacetsShowMoreDialogFilterInputClearBtn" icon className="clear-button" onClick={() => this._filterInputChanged("")}>clear</Button>}
				/>
				{filteredFacetItems.rootNodes.map(node => this._getItemContent(node, 1))}
				{this.noItemsToShow ? <div className="search-facets-dialog-content-no-items">{t('filters.modal.no_items')}</div> : null}
			</div>
		);
	};

	private _getToolbarContent = () => {
		const { t, groupKey, facetItemsHash, facetItems, seeOnlySelected } = this.props;
		const { onToggleSelectedAll, onToggleSeeOnlySelected, onToggleCollapseAll } = this.props;

		const parentValues = TreeUtils.getRootNodesAttributeValues(facetItems, 'value');
		const allCollapsed = !isEmpty(parentValues) && every(parentValues.map(parentValue => facetItemsHash[parentValue].collapsed), Boolean);
		const collapseTxt = allCollapsed ? t('filters.modal.expand') : t('filters.modal.collapse');
		const filteredItems = filter(values(facetItemsHash), hashItem => hashItem.selected);
		const allFiltered = !isEmpty(parentValues) && every(parentValues.map(parentValue => facetItemsHash[parentValue].selected), Boolean);

		return (
			<div className="search-facets-dialog-toolbar">
				<div className="search-facets-dialog-toolbar-selected">
					<SelectionControl
						id={`searchFacetsShowMoreDialogSelectAll`}
						name={`searchFacetsShowMoreDialogSelectAll[]`}
						label=""
						type="checkbox"
						checked={allFiltered}
						uncheckedCheckboxIcon={filteredItems.length ? <FontIcon>indeterminate_check_box</FontIcon> : <FontIcon>check_box_outline_blank</FontIcon>}
						onChange={() => onToggleSelectedAll(allFiltered)}
					/>
					{filteredItems.length ? `${filteredItems.length} ${t('filters.modal.selected')}` : null}
				</div>
				<div className="search-facets-dialog-toolbar-menu">
					<span id="searchFacetsShowMoreDialogSeeOnlyFilteredBtn"
						className={!filteredItems.length ? "disabled" : ""}
						onClick={() => { if (filteredItems.length) onToggleSeeOnlySelected(); }}>
						{seeOnlySelected ? t('filters.modal.show-all') : t('filters.modal.show-selected')}
					</span>
					{includes(groupsWithCollapse, groupKey) ? (
						<>{` | `} <span id="searchFacetsShowMoreDialogCollapseAllBtn" onClick={() => onToggleCollapseAll(!allCollapsed)}>{collapseTxt}</span></>
					) : null}
				</div>
			</div>
		);
	};

	private _onCloseAcceptDialog = () => {
		const { groupKey, onSetShowMoreDialogFacetGroupKey, onSetGroupFacetsFilters, facetItemsHash, topics } = this.props;

		const getFacetObject = (facetKey: string) => ({
			key: facetKey,
			name: facetItemsHash[facetKey].node.data.text,
			detail: facetItemsHash[facetKey].node.data.detail,
			type: facetItemsHash[facetKey].node.data.type
		});
		let facetsSelected: FilterFacet[] = filter(keys(facetItemsHash), facetValue => facetItemsHash[facetValue].selected).map(facetKey => getFacetObject(facetKey));

		if (groupKey === Facet.categoriesKey) {
			const categoryFacetsSelected = facetsSelected.map(selectedFacet => {
				// If facet is a 1st or 2nd level category, return a list of their childs
				if (selectedFacet.key.split('-').length < 3) {
					return flatten(keys(facetItemsHash).filter(facet => facet.startsWith(selectedFacet.key)).map(facetKey => getFacetObject(facetKey)));
				}
				return selectedFacet;
			});
			facetsSelected = flatten(categoryFacetsSelected);
		}

		if (groupKey === Facet.tagsGroupKey) {
			const tagsFacetsSelected = facetsSelected.map(facet => {
				// If facet is a tag return the facet. If facet is a topic, return a list of facets for all topic tags
				if (facet.key.includes("_")) return { key: facet.key };
				const topic = find(topics, { id: facet.key });
				if (!topic) return [];
				return topic.tags.map((tag: { id: any; }) => ({ key: `${topic.id}_${tag.id}` }));
			});
			facetsSelected = flatten(tagsFacetsSelected);
		}

		onSetGroupFacetsFilters(groupKey, facetsSelected);
		onSetShowMoreDialogFacetGroupKey(null);
	};

	private _getGroupKeyText = () => {
		const { groupKey } = this.props;

		if (groupKey === Facet.tagsGroupKey) return "topic_tags";
		let groupKeyTxt;
		if (groupKey.match('photo.')) {
			switch (groupKey) {
				case Facet.countryPathKeyVB:
					groupKeyTxt = "country";
					break;
				case Facet.photoTypeKey:
					groupKeyTxt = "photo_type";
					break;
				case Facet.printMediaLevelKey:
					groupKeyTxt = "media_value";
					break;
				case Facet.frequencyKeyVB:
					groupKeyTxt = "media_frequency";
					break;
				default:
					groupKeyTxt = "gender";
			}
		} else {
			switch (groupKey) {
				case Facet.countryPathKey:
					groupKeyTxt = "country";
					break;
				case Facet.frequencyKey:
					groupKeyTxt = "media_frequency";
					break;
				case Facet.languageGroupKey:
					groupKeyTxt = "language";
					break;
				case Facet.placementType:
					groupKeyTxt = "article_type";
					break;
				case Facet.categoriesGroupKeyVB:
					groupKeyTxt = "categories";
					break;
				case Facet.categoriesKey:
					groupKeyTxt = "new_categories";
					break;
				case Facet.coverageType:
					groupKeyTxt = "coverage_type";
					break;
				case Facet.eventKey:
					groupKeyTxt = 'event';
					break;
				default:
					// TODO (GIS-2327): Remove language replace once we have all with the same field to take into account that language_id will be language.id always
					groupKeyTxt = groupKey.replace(/language\.id/, 'language_id').replace(/^.*\.|_.*$/g, '');
					break;
			}
		}

		groupKeyTxt += ".value";
		return groupKeyTxt;
	};

	public componentDidMount() {
		this.props.onFetchFacetsGroup(this.props.groupKey);
	};

	public componentDidUpdate(prevProps: ComponentProps) {
		if (this.props.facets !== prevProps.facets || this.props.queriedMedias !== prevProps.queriedMedias) {
			if (this.props.facets) {
				const facetItems = this._getItemsFromGroupKey(this.props.facets, prevProps.facetItemsHash);
				const facetItemsHash = this._createFacetItemHash(facetItems, {}, prevProps.facetItemsHash);
				this.props.onSetAllState(facetItemsHash, TreeUtils.createTree(facetItems), this.props.seeOnlySelected);
			}
		}
	};

	public render() {
		const { t } = this.props;
		const { onSetShowMoreDialogFacetGroupKey } = this.props;

		const groupKeyTxt = this._getGroupKeyText();

		const toolbarContent = this._getToolbarContent();
		const content = this._getDialogContent();

		const footerContent = (
			<div className="search-facets-dialog-footer">
				<Button flat id="searchFacetsShowMoreDialogCancelBtn" onClick={() => onSetShowMoreDialogFacetGroupKey(null)}>
					{t('filters.period.custom.dialog.cancel')}
				</Button>
				<Button flat id="searchFacetsShowMoreDialogAcceptBtn" onClick={() => this._onCloseAcceptDialog()}>
					{t('filters.period.custom.dialog.ok')}
				</Button>
			</div>
		);

		const dialogContent = (
			<div className="search-facets-dialog">
				<div className="search-facets-dialog-header">{t(`filters.${groupKeyTxt}`)}</div>
				{toolbarContent}
				{content}
				{footerContent}
			</div>
		);

		return <DialogContainer
			id="searchFacetsShowMoreDialog"
			aria-label="searchFacetsShowMoreDialog"
			visible={true}
			onHide={() => onSetShowMoreDialogFacetGroupKey(null)}
			focusOnMount={false}
			children={dialogContent}
			portal
		/>;
	};
}

export default withT(SearchFacetsDialog);
