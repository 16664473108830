import ActionCreators from 'lib/store/actions';
import { ReportJob } from './types';

const actions = new ActionCreators('@@report/status');

const [fetchFocusJobs, fetchFocusJobsSuccess, fetchFocusJobsError] = actions.apiOperators<void, ReportJob[]>('FETCH_FOCUS_JOBS');
const clearJobs = actions.operator('CLEAR_JOBS');
const startJobStatusPool = actions.operator('START_JOB_STATUS_POOL');
const stopJobStatusPool = actions.operator('STOP_JOB_STATUS_POOL');
const [fetchJobStatus, fetchJobStatusSuccess, fetchJobStatusError] = actions.apiOperators<void, ReportJob[]>('FETCH_FOCUS_JOB_STATUS');

export type Actions = {
	FetchFocusJobs: ReturnType<typeof fetchFocusJobs>;
	FetchFocusJobsSuccess: ReturnType<typeof fetchFocusJobsSuccess>;
	FetchFocusJobsError: ReturnType<typeof fetchFocusJobsError>;
	StartJobStatusPool: ReturnType<typeof startJobStatusPool>,
	StopJobStatusPool: ReturnType<typeof stopJobStatusPool>,
	FetchJobStatus: ReturnType<typeof fetchJobStatus>,
	FetchJobStatusSuccess: ReturnType<typeof fetchJobStatusSuccess>,
	FetchJobStatusError: ReturnType<typeof fetchJobStatusError>,
	ClearJobs: ReturnType<typeof clearJobs>;
};

export const operators = {
	fetchFocusJobs,	fetchFocusJobsSuccess, fetchFocusJobsError,
	clearJobs,
	startJobStatusPool, stopJobStatusPool,
	fetchJobStatus, fetchJobStatusSuccess, fetchJobStatusError
};
