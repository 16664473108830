import React from 'react';
import { withT } from 'lib/i18n';
import { ComponentProps, EmailReport } from './types';
import OpenedByColumn from './OpenedByColumn/OpenedByColumn';
import EmailPopupBase from '../EmailPopupBase/EmailPopupBase';
import NewsletterError from './NewsletterError/NewsletterError';
import { Columns, DiscoverTable } from 'components/common/DiscoverTable';

import './EmailPopup.scss';

const EmailPopup = ({ emailReports, errorMessage, initialValue, subtitle, onClose, onListSort, t }: ComponentProps) => {

	const renderOpenedBy = React.useCallback(({ isOpened }: EmailReport) => <OpenedByColumn value={isOpened!} />, []);
	const listColumn: Columns<EmailReport, EmailReport> = React.useMemo(() => ({
		sort: true,
		key: 'list',
		type: 'text',
		onClick: onListSort,
		initialValue: initialValue,
		alignHorizontally: 'left',
		alignVertically: 'center',
		styles: ['audit-email-popup-list-column'],
		displayName: t('audit_email_popup_list_column')
	}), [onListSort, initialValue, t]);

	const emailColumn: Columns<EmailReport, EmailReport> = React.useMemo(() => ({
		key: 'email',
		type: 'text',
		alignHorizontally: 'left',
		alignVertically: 'center',
		displayName: t('audit_email_popup_email_column')
	}), [t]);

	const openedByColumn: Columns<EmailReport, EmailReport> = React.useMemo(() => ({
		key: 'isOpened',
		type: 'custom',
		render: renderOpenedBy,
		alignHorizontally: 'left',
		alignVertically: 'center',
		displayName: t('audit_email_popup_opened_by_column')
	}), [renderOpenedBy, t]);

	const getTableColumns = React.useCallback(
		() => !errorMessage ? [listColumn, emailColumn, openedByColumn] : [listColumn, emailColumn],
		[emailColumn, errorMessage, listColumn, openedByColumn]
	);

	const headerContent = React.useMemo(
		() => errorMessage ? <NewsletterError message={errorMessage} /> : undefined,
		[errorMessage]
	);

	const table = React.useMemo(() => <DiscoverTable
		data={emailReports}
		columns={getTableColumns()}
		tableStyle={['audit-email-popup-list']}
		rowStyle={['audit-email-popup-list-row']}
		headerStyle={['audit-email-popup-list-header']}
	/>, [emailReports, getTableColumns]
	);

	return <EmailPopupBase
		onClose={onClose}
		subTitle={subtitle}
		headerContent={headerContent}
		title={t('audit_email_popup_title')}
	>
		<div className="audit-email-popup-table-wrapper">
			{table}
		</div>
	</EmailPopupBase>;
};

export default withT(EmailPopup);
