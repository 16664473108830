import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import Location from './Location';
import { State } from 'store/types';
import { StateProps, DispatchProps } from './types';

import { operators as horizontalFiltersOperators } from 'store/search/horizontalFilters';
import { operators as filtersOperators } from 'store/search/filters';
import { FilterFacet } from 'class/Filter';
import { Facet } from 'class/Facet';

const locationGroupKey = Facet.countryGroupKey;

const mapStateToProps = (state: State): StateProps => ({
	excluded: state.search.horizontalFilters.excludedFields.includes(locationGroupKey),
	facetFilters: state.search.filters.facetsGroups
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetExcludeField: (set: boolean) => dispatch(horizontalFiltersOperators.setExcludeField({ field: locationGroupKey, set })),
	onSetFacetGroupFilters: (items: FilterFacet[]) => dispatch(filtersOperators.setFacetGroupFilters({
		groupKey: locationGroupKey,
		groupFacetsFiltered: items,
		doSearch: false
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(Location);
