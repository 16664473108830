import { Action, actionCreatorFactory } from 'typescript-fsa';
import { Tree, TreeNode } from 'class/Tree';
import { FacetItemHash, ShowMoreFacetItem } from './reducers';
import { FacetItem } from 'class/Facet';

const actionCreator = actionCreatorFactory('@@search/facets/extended');

type Payloads = {
	SetFacetItemsHash: { facetItemsHash: FacetItemHash };
	SetSeeOnlySelected: { seeOnlySelected: boolean };
	SetAll: { facetItemsHash: FacetItemHash, facetItems: Tree<ShowMoreFacetItem>, seeOnlySelected: boolean };
	ToggleItemCollapsed: { itemValue: string };
	ToggleCollapseAll: { collapse: boolean };
	ToggleSelectedFilter: { node: TreeNode<ShowMoreFacetItem>, isSelected: boolean };
	ToggleSelectedAll: { allSelected: boolean };
	ToggleSeeOnlySelected: void;
	SetFilterText: { filterText: string };
	FetchQueriedMedias: { query: string };
	SetQueriedMedias: { medias: FacetItem[] };
}

export type Actions = {
	SetFacetItemsHash: Action<Payloads["SetFacetItemsHash"]>;
	SetSeeOnlySelected: Action<Payloads["SetSeeOnlySelected"]>;
	SetAll: Action<Payloads["SetAll"]>;
	ToggleItemCollapsed: Action<Payloads["ToggleItemCollapsed"]>;
	ToggleCollapseAll: Action<Payloads["ToggleCollapseAll"]>;
	ToggleSelectedFilter: Action<Payloads["ToggleSelectedFilter"]>;
	ToggleSelectedAll: Action<Payloads["ToggleSelectedAll"]>;
	ToggleSeeOnlySelected: Action<Payloads["ToggleSeeOnlySelected"]>;
	SetFilterText: Action<Payloads["SetFilterText"]>;
	FetchQueriedMedias: Action<Payloads["FetchQueriedMedias"]>;
	SetQueriedMedias: Action<Payloads["SetQueriedMedias"]>;
}

export const operators = {
	setFacetItemsHash: actionCreator<Payloads["SetFacetItemsHash"]>('SET_FACET_ITEMS_HASH'),
	setSeeOnlySelected: actionCreator<Payloads["SetSeeOnlySelected"]>('SET_SEE_ONLY_SELECTED'),
	setAll: actionCreator<Payloads["SetAll"]>('SET_ALL'),
	toggleItemCollapsed: actionCreator<Payloads["ToggleItemCollapsed"]>('TOGGLE_ITEM_COLLAPSED'),
	toggleCollapseAll: actionCreator<Payloads["ToggleCollapseAll"]>('TOGGLE_COLLAPSE_ALL'),
	toggleSelectedFilter: actionCreator<Payloads["ToggleSelectedFilter"]>('TOGGLE_SELECTED_FILTER'),
	toggleSelectedAll: actionCreator<Payloads["ToggleSelectedAll"]>('TOGGLE_SELECTED_ALL'),
	toggleSeeOnlySelected: actionCreator<Payloads["ToggleSeeOnlySelected"]>('TOGGLE_SEE_ONLY_SELECTED'),
	setFilterText: actionCreator<Payloads["SetFilterText"]>('SET_FILTER_TEXT'),
	fetchQueriedMedias: actionCreator<Payloads["FetchQueriedMedias"]>('FETCH_QUERIED_MEDIAS'),
	setQueriedMedias: actionCreator<Payloads["SetQueriedMedias"]>('SET_QUERIED_MEDIAS')
};
