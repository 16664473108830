import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators as focusOperators } from 'store/focus/list';
import fletcherSelectors from 'store/fletcher/selectors';

import SearchFacets from './SearchFacets';
import { DispatchProps, StateProps } from './types';

const mapStateToProps = (state: State): StateProps => ({
	location: state.router.location,
	focusList: state.focus.list.focusList,
	facets: state.search.facets.facets,
	loadingFacets: state.search.facets.loadingFacets,
	areTopicsFetched: fletcherSelectors.isFetched(state, "topics"),
	showMoreFacetGroupKey: state.search.facets.showMoreFacetGroupKey,
	feedType: state.focus.feed.feedType
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onFetchFocusList: () => dispatch(focusOperators.fetchFocusList())
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchFacets);
