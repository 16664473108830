import { createBrowserHistory } from 'history';
import { Saga } from 'redux-saga';
import isEqual from 'lodash/isEqual';
import { combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import { connectRouter } from 'connected-react-router';

import { connectApi } from 'lib/ajax/Api';
import { applyMiddleware, getSagaMiddleware } from 'middleware';
import { State } from './types';

import * as app from './app';
import * as ui from './ui';
import * as article from './article';
import * as search from './search';
import * as focus from './focus';
import * as entities from './entities';
import * as fletcher from './fletcher';

export const history = createBrowserHistory();
// patch to avoid stacking same location multiple times in history
const historyPush = history.push;
let lastLocation = history.location;
history.listen(location => lastLocation = location);
history.push = (path: any, state: object = {}): void => {
	const lastPath = lastLocation.pathname + lastLocation.hash + lastLocation.search;
	if (lastLocation === null || path !== lastPath || !isEqual(state, lastLocation.state)) historyPush(path, state);
};

// create reducers
export const reducers = combineReducers({
	fletcher: fletcher.reducers,
	entities: entities.reducers,
	ui: ui.reducers,
	app: app.reducers,
	article: article.reducers,
	search: search.reducers,
	focus: focus.reducers,
	router: connectRouter(history)
});

const sagaMiddleware = getSagaMiddleware();
export const store = createStore(reducers, composeWithDevTools(applyMiddleware(history, sagaMiddleware)));
export const INITIAL_STATE: State = store.getState();

connectApi(store); // connect api client to the store

export function startSagas(sagas: Saga<any>) {
	sagaMiddleware.run(sagas);
}
