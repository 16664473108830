import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { operators } from 'store/focus/dashboard/logo';
import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import FocusDashboardLogoDialog from './FocusDashboardLogoDialog';

const mapStateToProps = ({ focus: { dashboard } }: State): StateProps => ({
	imageMeta: dashboard.logo.imageMeta,
	loading: dashboard.logo.loading
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onFetchImageMeta: () => dispatch(operators.fetchImageMeta()),
	onRemoveLogo: () => dispatch(operators.removeLogo()),
	onAcceptFacebook: (facebookPageUrl, facebookImageUrl) => dispatch(operators.acceptFacebook({ facebookImageUrl, facebookPageUrl })),
	onAcceptUpload: (image, crop) => dispatch(operators.acceptUpload({ image, imageCrop: crop }))
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusDashboardLogoDialog);
