import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { operators } from 'store/focus/dashboard';
import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import FocusDashboard from './FocusDashboard';
import { getBrandName } from 'store/entities/Brand/selectors';

const mapStateToProps = (state: State): StateProps => ({
	tenant: state.app.profile.tenant!,
	user: state.app.profile.user!,
	focus: state.focus.dashboard.focus,
	feeds: state.focus.dashboard.feeds,
	loadingUpdate: state.focus.dashboard.loadingUpdate,
	loadingFeeds: state.focus.dashboard.loadingFeeds,
	getBrandName: (brandId: string) => getBrandName(state, brandId)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onFetchFocus: id => dispatch(operators.fetchFocus({ id }))
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusDashboard);
