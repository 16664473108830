import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/search/results';
import { DispatchProps, StateProps } from './types';
import SearchResults from './SearchResults';
import { getCategoryName } from 'store/entities/Category/selectors';

const mapStateToProps = (state: State): StateProps => {
	const { router, app: { profile: { user, tenant } }, search: { results } } = state;
	return {
		...results,
		location: router.location,
		user: user!,
		tenant: tenant!,
		brand_sync: state.focus.feed.brand_sync,
		showTagActionPanel: state.search.results.showTagActionPanel,
		getCategoryName: (id: string) => getCategoryName(state, id)
	};
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onRemoveDocument: id => dispatch(operators.removeDocument({ id })),
	onChangeDocumentCheck: id => dispatch(operators.toggleDocumentChecked({ id }))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
