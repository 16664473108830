import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/ui/report/setup';
import selectors from 'store/ui/report/setup/selectors';
import TagSelectors from 'store/entities/Tag/selectors';
import { DispatchProps, StateProps } from './types';

import ReportSetupSettingsSummary from './ReportSetupSettingsSummary';
import { getAllCategories } from 'store/entities/Category/selectors';

const mapStateToProps = (state: State): StateProps => {
	const tagIds = selectors.getTopicTagIds(state).map(id => id.split('_')[1]);
	return {
		step: selectors.getStep(state),
		type: selectors.getType(state),
		period: selectors.getPeriod(state),
		minDate: selectors.getMinDate(state)!,
		maxDate: selectors.getMaxDate(state)!,
		language: selectors.getLanguage(state),
		currency: selectors.getCurrency(state),
		country: selectors.getCountry(state),
		categories: selectors.getCategories(state),
		tags: TagSelectors.get(state, tagIds),
		tagsTopics: TagSelectors.getTopic(state, tagIds),
		allDmrCategories: getAllCategories(state)
	};
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onApply: () => dispatch(operators.applySettings()),
	onGoBackToSettings: () => dispatch(operators.setStep('settings'))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSetupSettingsSummary);
