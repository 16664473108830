import { connect } from 'react-redux';

import { State } from 'store/types';
import { getFullCategoryName } from 'store/entities/Category/selectors';
import { DispatchProps, StateProps } from './types';

import BulksPreviewTags from './BulksPreviewTags';

const mapStateToProps = (state: State): StateProps => ({
	getFullCategoryName: (id: string) => getFullCategoryName(state, id)
});

const mapDispatchToProps = (): DispatchProps => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(BulksPreviewTags);
