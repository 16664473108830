import React from 'react';
import map from 'lodash/map';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import Snackbar from 'react-md/lib/Snackbars/SnackbarContainer';

import { ComponentProps } from './types';
import { QueueNotification } from 'types/notification';
import { withT } from 'lib/i18n';

import './Notifications.scss';

class Notifications extends React.Component<ComponentProps> {

	private toastCache: { [index: number]: any } = {};

	private __reloadPage = () => window.location.reload();

	private __createToast(notification: QueueNotification) {
		const { t } = this.props;
		let action;
		let text = notification.text;
		if (notification.buttonIcon) action = { children: <FontIcon>{notification.buttonIcon}</FontIcon> };
		else if (notification.buttonText) action = { children: notification.buttonText };
		else if (notification.timeout === false) action = { children: <FontIcon>clear</FontIcon> };
		if (notification.reload) {
			action = { children: <span>{t('error.api_reload.btn')}</span>, onClick: this.__reloadPage };
			text += `. ${t('error.api_reload.text')}`;
		}
		return {
			...notification,
			text,
			action
		};
	}

	private __getToasts(notifications: QueueNotification[]) {
		const newToastCache: { [index: number]: any } = {};
		const toasts = map(notifications, notification => {
			const toast = this.toastCache[notification.id] || this.__createToast(notification);
			newToastCache[notification.id] = toast;
			return toast;
		});
		this.toastCache = newToastCache;
		return toasts;
	}

	public render() {
		const { onUnqueueNotification, notifications } = this.props;

		const toasts = this.__getToasts(notifications);
		if (!toasts.length) return null;

		const toast = toasts[0];
		const classes = toast.level + (toast.reload ? " reload" : "");
		return <Snackbar
			id="notifications-snackbar"
			className={classes}
			toasts={toasts}
			autohide={toast.timeout !== false}
			autohideTimeout={toast.timeout || 5000}
			onDismiss={() => onUnqueueNotification()}
		/>;
	}
}
export default withT(Notifications);
