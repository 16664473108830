import set from 'lodash/set';
import filter from 'lodash/filter';

import { ReducerHandlers } from 'lib/store/reducer';
import { Actions } from './actions';
import { State as FocusFeedState } from 'store/focus/feed/reducers';
import { DefinitionSocial, ErrorProfile, LoadingProfiles, anywhereScope } from 'class/Feed';

export type State = {
	definition: DefinitionSocial,
	loadingProfiles: LoadingProfiles,
	errorProfiles: ErrorProfile[]
};

export const INITIAL_STATE: State = {
	definition: {
		main: {
			q: "",
			enabled: true,
			scope: ["tags", "mentions", "title", "content", "instagram.users_in_photo", "media_mentions", "sponsorized_mentions", "audio_to_text"]
		},
		include_expressions: [],
		exclude_expressions: [],
		include_profiles: [],
		exclude_profiles: [],
		instagram_accounts: [],
		threshold: {
			30: {
				value: 500,
				enabled: false
			},
			31: {
				value: 0,
				enabled: false
			},
			40: {
				value: 0,
				enabled: false
			},
			41: {
				value: 0,
				enabled: false
			},
			46: {
				value: 0,
				enabled: false
			},
			47: {
				value: 0,
				enabled: false
			},
			51: {
				value: 0,
				enabled: false
			},
			52: {
				value: 0,
				enabled: false
			},
			60: {
				value: 0,
				enabled: false
			},
			62: {
				value: 0,
				enabled: false
			}
		}
	},
	loadingProfiles: {
		include_profiles: [],
		exclude_profiles: []
	},
	errorProfiles: []
};

export const reducerHandlers: ReducerHandlers<FocusFeedState> = {
	setSocialDefinition: (state, { payload: { definition } }: Actions["SetSocialDefinition"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition
		}
	}),
	setSocialExpression: (state, { payload: { expressionKey, index, values } }: Actions["SetSocialExpression"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				[expressionKey]: expressionKey === "main" ?
					{ ...state.social.definition.main, ...values } :
					set(state.social.definition[expressionKey], index, { ...state.social.definition[expressionKey][index], ...values })
			}
		}
	}),
	setProfileExpression: (state, { payload: { expressionKey, index, values } }: Actions["SetProfileExpression"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				[expressionKey]: set(state.social.definition[expressionKey], index, { ...state.social.definition[expressionKey][index], ...values })
			}
		}
	}),
	changeMainQuery: (state, { payload: { q } }: Actions["ChangeMainQuery"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				main: {
					...state.social.definition.main,
					q
				}
			}
		}
	}),

	changeMainScope: (state, { payload: { scope } }: Actions["ChangeMainScope"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				main: {
					...state.social.definition.main,
					scope
				}
			}
		}
	}),

	toggleEnableMainQuery: (state, { payload: { enabled } }: Actions["ToggleEnableMainQuery"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				main: {
					...state.social.definition.main,
					enabled
				}
			}
		}
	}),

	setMainExpressionError: (state, { payload: { hasError } }: Actions["SetMainExpressionError"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				main: {
					...state.social.definition.main,
					error: hasError ? true : undefined
				}
			}
		}
	}),

	changeQuery: (state, { payload: { q, type, index } }: Actions["ChangeQuery"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				[type]: set(state.social.definition[type], index, { ...state.social.definition[type][index], q })
			}
		}
	}),

	changeScope: (state, { payload: { scope, type, index } }: Actions["ChangeScope"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				[type]: set(state.social.definition[type], index, { ...state.social.definition[type][index], scope })
			}
		}
	}),

	toggleEnableQuery: (state, { payload: { enabled, type, index } }: Actions["ToggleEnableQuery"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				[type]: set(state.social.definition[type], index, { ...state.social.definition[type][index], enabled })
			}
		}
	}),

	addExpression: (state, { payload: { type } }: Actions["AddExpression"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				[type]: [
					...state.social.definition[type],
					{
						q: "",
						enabled: true,
						scope: anywhereScope
					}
				]
			}
		}
	}),

	addProfileExpression: (state, { payload: { type } }: Actions["AddProfileExpression"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				[type]: [
					...state.social.definition[type],
					{
						api_version: '',
						channel_type_id: 0,
						enabled: true,
						id: '',
						name: '',
						picture: '',
						screen_name: '',
						url: ''
					}
				]
			}
		}
	}),

	addMultipleProfileExpressions: (state, { payload: { type, url } }: Actions["AddMultipleProfileExpressions"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				[type]: [
					...state.social.definition[type],
					{
						api_version: '',
						channel_type_id: 0,
						enabled: true,
						id: '',
						name: '',
						picture: '',
						screen_name: '',
						url
					}
				]
			}
		}
	}),

	setExpressionError: (state, { payload: { hasError, type, index } }: Actions["SetExpressionError"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				[type]: set(state.social.definition[type], index, { ...state.social.definition[type][index], error: hasError ? true : undefined })
			},
			loadingProfiles: {
				...state.social.loadingProfiles,
				[type]: set(state.social.loadingProfiles[type], index, false)
			}
		}
	}),

	removeExpression: (state, { payload: { type, index } }: Actions["RemoveExpression"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				[type]: filter(state.social.definition[type], (expr, i: number) => index !== i)
			}
		}
	}),

	removeProfileExpression: (state, { payload: { type, index } }: Actions["RemoveProfileExpression"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				[type]: filter(state.social.definition[type], (expr, i: number) => index !== i)
			},
			loadingProfiles: {
				...state.social.loadingProfiles,
				[type]: set(state.social.loadingProfiles[type], index, false)
			}
		}
	}),

	removeAllProfileExpressions: (state, { payload: { type } }: Actions["RemoveAllProfileExpressions"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				[type]: []
			}
		}
	}),

	changeQueryProfile: (state, { payload: { url, type, index } }: Actions["ChangeQueryProfile"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				[type]: set(state.social.definition[type], index, { ...state.social.definition[type][index], url })
			}
		}
	}),

	searchMultipleProfilesError: (state, { payload: { errorProfiles } }: Actions["SearchMultipleProfilesError"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			errorProfiles
		}
	}),

	clearErrorProfiles: (state): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			errorProfiles: []
		}
	}),

	searchProfile: (state, { payload: { type, index } }: Actions["SearchProfile"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			loadingProfiles: {
				...state.social.loadingProfiles,
				[type]: set(state.social.loadingProfiles[type], index, true)
			}
		}
	}),

	searchProfileSuccess: (state, { payload: { profile, type, index } }: Actions["SearchProfileSuccess"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				[type]: set(state.social.definition[type], index, { ...profile, enabled: state.social.definition[type][index] ? state.social.definition[type][index].enabled : true, error: undefined })
			},
			loadingProfiles: {
				...state.social.loadingProfiles,
				[type]: set(state.social.loadingProfiles[type], index, false)
			}
		}
	}),

	searchProfileError: (state, { payload: { error, type, index } }: Actions["SearchProfileError"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				[type]: set(state.social.definition[type], index, { ...state.social.definition[type][index], error: error ? true : undefined })
			},
			loadingProfiles: {
				...state.social.loadingProfiles,
				[type]: set(state.social.loadingProfiles[type], index, false)
			}
		}
	}),

	setDefinitionInstagramAccounts: (state, { payload: { definitionInstagramAccounts } }: Actions["SetDefinitionInstagramAccounts"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				instagram_accounts: definitionInstagramAccounts
			}
		}
	}),

	setThreshold: (state, { payload: { channel, threshold } }: Actions["SetThreshold"]): FocusFeedState => ({
		...state,
		social: {
			...state.social,
			definition: {
				...state.social.definition,
				threshold: {
					...state.social.definition.threshold,
					[channel]: threshold
				}
			}
		}
	})
};
