import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { operators } from './actions';

/**
 * The flag to keep the fetch state of each kind of {@link Resource}
 *
 * Values:
 *  * null -> initial state, not fetched
 *  * false -> fetching
 *  * true -> fetched, entities created
 *  * 'error': string -> error fetching, entities not created
 */
export type FetchedFlag = boolean | null | 'error' ;
export type Resource = 'auth' | 'focus' | 'articles' | 'newsletters' | 'topics' | 'reports' | 'dmrCategories' | 'events' | 'brands' | 'languages' | 'countries';

export type State = { [E in Resource]: FetchedFlag };

export const INITIAL_STATE: State = {
	auth: null,
	focus: null,
	articles: null,
	newsletters: null,
	topics: null,
	reports: null,
	dmrCategories: null,
	events: null,
	brands: null,
	languages: null,
	countries: null
};

const applyReducer = (state: State, resourceName: Resource, value: FetchedFlag) => ({ ...state, [resourceName]: value });

const reducerHandlers: ReducerHandlers<State> = {
	fetchAuthStart: state => applyReducer(state, 'auth', false),
	fetchAuthSuccess: state => applyReducer(state, 'auth', true),
	fetchAuthError: state => applyReducer(state, 'auth', 'error'),
	fetchFocusStart: state => applyReducer(state, 'focus', false),
	fetchFocusSuccess: state => applyReducer(state, 'focus', true),
	fetchFocusError: state => applyReducer(state, 'focus', 'error'),
	fetchNewslettersStart: state => applyReducer(state, 'newsletters', false),
	fetchNewslettersSuccess: state => applyReducer(state, 'newsletters', true),
	fetchNewslettersError: state => applyReducer(state, 'auth', 'error'),
	fetchTopicsStart: state => applyReducer(state, 'topics', false),
	fetchTopicsSuccess: state => applyReducer(state, 'topics', true),
	fetchTopicsError: state => applyReducer(state, 'topics', 'error'),
	fetchDmrCategoriesStart: state => applyReducer(state, 'dmrCategories', false),
	fetchDmrCategoriesSuccess: state => applyReducer(state, 'dmrCategories', true),
	fetchDmrCategoriesError: state => applyReducer(state, 'dmrCategories', 'error'),
	fetchEventsStart: state => applyReducer(state, 'events', false),
	fetchEventsSuccess: state => applyReducer(state, 'events', true),
	fetchEventsError: state => applyReducer(state, 'events', 'error'),
	fetchBrandsStart: state => applyReducer(state, 'brands', false),
	fetchBrandsSuccess: state => applyReducer(state, 'brands', true),
	fetchBrandsError: state => applyReducer(state, 'brands', 'error'),
	fetchLanguagesStart: state => applyReducer(state, 'languages', false),
	fetchLanguagesSuccess: state => applyReducer(state, 'languages', true),
	fetchLanguagesError: state => applyReducer(state, 'languages', 'error'),
	fetchCountriesStart: state => applyReducer(state, 'countries', false),
	fetchCountriesSuccess: state => applyReducer(state, 'countries', true),
	fetchCountriesError: state => applyReducer(state, 'countries', 'error')
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
