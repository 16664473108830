import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { map, omit } from 'lib/imports/lodash';

import { Actions, operators } from './actions';
import { State } from 'store/types';
import { operators as entitiesOperators } from 'store/entities/Newsletter';
import NewsletterSelectors from 'store/entities/Newsletter/selectors';
import Api from 'lib/ajax/Api';

export default function* sagas() {
	yield all([
		takeLatest(operators.toggleNewsletterEnabled.type, toggleNewsletterEnabled),
		takeLatest(operators.removeNewsletter.type, removeNewsletter)
	]);
}

function* toggleNewsletterEnabled({ payload: { id } }: Actions['ToggleNewsletterEnabled']) {
	const api = new Api();
	try {
		const newsletter = yield _getNewsletter(id);
		if (!('tags' in newsletter)) newsletter.tags = [];
		const newsletterUpdate = omit(newsletter, ['id', 'edited_at', 'inserted_at', 'user_edited', 'user_created']);
		newsletterUpdate.enabled = !newsletter.enabled;
		yield put(entitiesOperators.update({ id, enabled: !newsletter.enabled }));
		yield call([api, 'put'], `/newsletter/${newsletter.id}`, { data: newsletterUpdate });
		yield put(operators.toggleNewsletterEnabledSuccess());
	} catch (error) {
		yield put(operators.toggleNewsletterEnabledError(error));
	}
}

function* removeNewsletter({ payload: { id } }: Actions['RemoveNewsletter']) {
	const api = new Api();
	try {
		yield put(entitiesOperators.delete(id));
		yield call([api, 'delete'], `/newsletter/${id}`);
		yield put(operators.removeNewsletterSuccess());
	} catch (error) {
		yield put(operators.removeNewsletterError(error));
	}
}

function* _getNewsletter(id: string) {
	const state: State = yield select();
	const newsletter = NewsletterSelectors.getInstance(state, id)!;
	return {
		...newsletter.ref,
		feeds: map(newsletter.feeds.all().toRefArray(), 'id'),
		lists: map(newsletter.emailLists.all().toRefArray(), 'id')
	};
}
