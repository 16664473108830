import ActionCreators from 'lib/store/actions';

const actions = new ActionCreators('@@report/main');

const changeFocus = actions.operator<string | null>('CHANGE_FOCUS');
const refresh = actions.operator('REFRESH');

export type Actions = {
	ChangeFocus: ReturnType<typeof changeFocus>;
};

export const operators = {
	changeFocus,
	refresh
};
