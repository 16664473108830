import moment from 'moment';
import { findKey } from 'lodash';
import { forEach, concat, cloneDeep, filter, remove, map, omit } from 'lib/imports/lodash';

import {
	SearchPeriod,
	PeriodParams,
	SearchParams,
	FocusFeedsGroupKey,
	FiltersParams,
	ArticleSearchParams,
	PreviewSearchParams,
	ArticleFacetSearchParams,
	PreviewFacetSearchParams,
	PreviewFiltersParams
} from 'types/search/form';
import { State as FormState } from 'store/search/form/reducers';
import { State as FiltersState } from 'store/search/filters/reducers';
import { State as FocusFeedState } from 'store/focus/feed/reducers';
import { Facet, FacetGroup } from 'class/Facet';
import { FilterFacet } from 'class/Filter';
import { FeedType, Definition, DefinitionSocial, DefinitionPrintVB, DefinitionOnline, DefinitionPrint } from 'class/Feed';
import { getIsoFromPathArray } from 'components/Search/Facets/Dialog/countryHelper';

export const getPeriodParams = (dates: { period: SearchPeriod, beginDate?: Date, endDate?: Date }): PeriodParams => {
	if (dates.period === "custom") {
		return {
			begin_date: moment(dates.beginDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
			end_date: moment(dates.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
		};
	} else return { period: dates.period };
};

export const getInsertMentionSearchParams = (formUrl: string, finalUrl: string | null, level: string, feedId: string, provider: string = "*") => {
	let urlsQuery = finalUrl ? `url:"${formUrl}" OR url:"${finalUrl}"` : `url:"${formUrl}"`;

	// Weibo or Wechat
	if (['31', '62'].includes(provider)) {
		//TODO: Remove once url is properly analyzed in historical & tenant indexes.
		const parsedBase = formUrl.replace(/http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g, '');
		const urlVariations = ['http://', 'https://', 'http://www.', 'https://www.']
			.map(variation => `url:"${variation}${parsedBase}"`)
			.join(' OR ');
		urlsQuery += ` OR ${urlVariations}`;
	}

	// Xioahongshu/Red
	if (['52'].includes(provider)) {
		//TODO: Remove once url is properly analyzed in historical & tenant indexes.
		const regex = '/explore/';
		if (formUrl.includes(regex)) urlsQuery += ` OR url:"${formUrl.replace(regex, '/discovery/item/')}"`;
		else urlsQuery += ` OR url:"${formUrl.replace('/discovery/item/', regex)}"`;
	}

	const params: SearchParams = {
		...getPeriodParams({ period: "custom", beginDate: moment().subtract(13, 'month').toDate(), endDate: moment().toDate() }),
		insights_insert_url_search: 1,
		query: provider === "*" ? urlsQuery : `(${urlsQuery}) AND (channel_type_id: ${provider.replace(/,/g, ' OR channel_type_id:')})`,
		limit: 100,
		sort: "_doc:asc",
		date_type: 'publication_date'
	};

	if (level === "feed") params["insights.filter"] = "feed:" + feedId;
	return params;
};

export const getArticleSearchParams = (searchForm: FormState, searchFilters: FiltersState, excludedFields: string[]): ArticleSearchParams => {
	const periodParams = getPeriodParams({ period: searchForm.period, beginDate: searchForm.begin_date || undefined, endDate: searchForm.end_date || undefined });
	const filtersParams = getSearchFiltersParams(searchFilters);

	const params: ArticleSearchParams = {
		...omit(searchForm, 'insightsFilters'), // This filters are only meant to be used in export button
		...filtersParams,
		excluded_fields: excludedFields.length > 0 ? excludedFields.join() : undefined,
		begin_date: periodParams.begin_date,
		end_date: periodParams.end_date,
		period: periodParams.period
	};

	return params;
};

export const getPreviewSearchParams = (focusFeedState: FocusFeedState, searchForm: FormState, searchFilters: FiltersState, feedType: FeedType): PreviewSearchParams => {
	const periodParams = getPeriodParams({ period: searchForm.period, beginDate: searchForm.begin_date || undefined, endDate: searchForm.end_date || undefined });
	const filtersParams = getPreviewSearchFiltersParams(searchFilters, focusFeedState);
	const definitionParams = getPreviewDefinitionParams(focusFeedState);
	const params: PreviewSearchParams = {
		...searchForm,
		filters: filtersParams,
		definition: definitionParams,
		begin_date: periodParams.begin_date,
		end_date: periodParams.end_date,
		period: periodParams.period,
		type: feedType
	};
	delete (params as any).query;
	delete (params as any).date_type;
	// patch to fix search feed type inconsistency
	if (params.sort === 'publication_date:desc' && params.type && params.type === 'print') {
		params.sort = 'photo.section.issue.date:desc';
	} else if (params.sort === 'publication_date:desc' && params.type && params.type === 'print_dmr') {
		params.sort = 'published_at:desc';
	}
	return params;
};

export const getArticleSearchFacetsParams = (searchForm: FormState, searchFilters: FiltersState): ArticleFacetSearchParams => {
	const periodParams = getPeriodParams({ period: searchForm.period, beginDate: searchForm.begin_date || undefined, endDate: searchForm.end_date || undefined });
	const filtersParams = getSearchFiltersParams(searchFilters);
	const params: ArticleFacetSearchParams = {
		...searchForm,
		...filtersParams,
		begin_date: periodParams.begin_date,
		end_date: periodParams.end_date,
		period: periodParams.period,
		facet_fields: [
			Facet.channelGroupKey, Facet.languageGroupKeyVB, Facet.countryGroupKey,
			Facet.categoriesKey, Facet.categoriesGroupKeyVB, Facet.tagsGroupKey,
			Facet.mediaGroupKey, Facet.eventKey, Facet.placementType
		].join()
	};

	return params;
};

export const getPreviewSearchFacetsParams = (focusFeedState: FocusFeedState, searchForm: FormState, searchFilters: FiltersState, feedType: FeedType): PreviewFacetSearchParams => {
	const periodParams = getPeriodParams({ period: searchForm.period, beginDate: searchForm.begin_date || undefined, endDate: searchForm.end_date || undefined });
	const filtersParams = getSearchFiltersParams(searchFilters, focusFeedState);
	const definitionParams = getPreviewDefinitionParams(focusFeedState);
	const params: PreviewFacetSearchParams = {
		...searchForm,
		...filtersParams,
		begin_date: periodParams.begin_date,
		end_date: periodParams.end_date,
		period: periodParams.period,
		type: feedType,
		facet_fields: getPreviewFacetField(focusFeedState),
		definition: definitionParams
	};

	delete (params as any).query;
	delete (params as any).sort;
	delete (params as any).start;
	delete (params as any).date_type;

	return params;
};

export const getPreviewFacetField = (focusFeedState: FocusFeedState) => {
	if (focusFeedState.feedType === 'print_dmr') return Facet.facetGroupKeys['printDmrPreview'].join();

	return focusFeedState.feedType && focusFeedState.feedType === 'print' ?
		Facet.facetGroupKeys['printPreview'].join() :
		Facet.facetGroupKeys['preview'].join();
};

export const getSearchFiltersParams = (searchFilters: FiltersState, focusFeedState?: FocusFeedState): FiltersParams => {
	const params: FiltersParams = {};
	const filters = cloneDeep(searchFilters);
	const facetGroups = filters.facetsGroups;

	if (facetGroups[Facet.channelGroupKey]) {
		unGroupFacets(facetGroups[Facet.channelGroupKey], Facet.channelGroups);
		if (facetGroups[Facet.channelGroupKey][0].key !== 'all') params.channel_type_id_filter = map(facetGroups[Facet.channelGroupKey], 'key').join(',');
	}

	if (facetGroups[Facet.printMediaLevelKey]) {
		unGroupFacets(facetGroups[Facet.printMediaLevelKey], Facet.printMediaLevelGroup);
		params["photo.section.issue.media.value_filter"] = map(facetGroups[Facet.printMediaLevelKey], 'key').join(',');
	}

	if (searchFilters.focus.length > 0 || searchFilters.feeds.length > 0) {
		const focusFeedsFilters = concat(searchFilters.focus.map(focusId => `focus:${focusId}`), searchFilters.feeds.filter(feedId => feedId !== 'all').map(feedId => `feed:${feedId}`));
		params[FocusFeedsGroupKey] = focusFeedsFilters.join(',') || undefined;
	}

	let facetArrayTypes;
	const isPreviewPage = focusFeedState && focusFeedState.feedType;
	if (isPreviewPage && focusFeedState!.feedType === 'print') {
		facetArrayTypes = [Facet.countryPathKeyVB, Facet.genderKey, Facet.photoTypeKey, Facet.frequencyKeyVB];
	} else if (isPreviewPage && focusFeedState!.feedType === 'print_dmr') {
		facetArrayTypes = Facet.facetGroupKeys.printDmrPreview;
	} else {
		facetArrayTypes = [
			Facet.languageGroupKeyVB, Facet.languageGroupKeyVB, Facet.categoriesKey, Facet.placementType, Facet.originGroupKey,
			Facet.categoriesGroupKeyVB, Facet.tagsGroupKey, Facet.countryGroupKey, Facet.mediaGroupKey, Facet.eventKey
		];
	}

	for (const key of facetArrayTypes) {
		if (facetGroups[key]) {
			//TODO: Only use ISO in all of discover, and stop transforming
			if (key === Facet.countryPathKey) params[`${key}_filter` as keyof FiltersParams] = getIsoFromPathArray(facetGroups[key].map(facet => facet.key)).join(',');
			else if (facetGroups[key][0].key === 'all') continue;
			else params[`${key}_filter` as keyof FiltersParams] = map(facetGroups[key], 'key').join(',');
		}
	}

	return params;
};

export const getPreviewSearchFiltersParams = (searchFilters: FiltersState, focusFeedState: FocusFeedState): PreviewFiltersParams => {
	const params: PreviewFiltersParams = {};
	const filters = cloneDeep(searchFilters);
	const facetGroups = filters.facetsGroups;

	if (facetGroups[Facet.channelGroupKey]) {
		unGroupFacets(facetGroups[Facet.channelGroupKey], Facet.channelGroups);
		params.channel_type_id = map(facetGroups[Facet.channelGroupKey], 'key');
	}

	if (facetGroups[Facet.printMediaLevelKey]) {
		const param = "photo.section.issue.media.value" as keyof PreviewFiltersParams;
		unGroupFacets(facetGroups[Facet.printMediaLevelKey], Facet.printMediaLevelGroup);
		params[param] = map(facetGroups[Facet.printMediaLevelKey], 'key');
	}

	let facetArrayTypes;
	if (focusFeedState.feedType === 'print') {
		facetArrayTypes = [Facet.countryPathKeyVB, Facet.genderKey, Facet.printMediaLevelKey, Facet.photoTypeKey, Facet.frequencyKeyVB];
	} else if (focusFeedState.feedType === 'print_dmr') {
		facetArrayTypes = [Facet.countryPathKey, Facet.languageGroupKey, Facet.coverageType, Facet.placementType, Facet.frequencyKey];
	} else {
		facetArrayTypes = [Facet.languageGroupKeyVB, Facet.categoriesGroupKeyVB, Facet.tagsGroupKey, Facet.countryGroupKey, Facet.mediaGroupKey];
	}

	for (const key of facetArrayTypes) {
		if (facetGroups[key]) {
			if (key === Facet.countryPathKey) params[key as keyof PreviewFiltersParams] = getIsoFromPathArray(facetGroups[key].map(facet => facet.key));
			else params[key as keyof PreviewFiltersParams] = facetGroups[key].map(facetFilter => facetFilter.key);
		}
	}
	return params;
};

export const getPreviewDefinitionParams = (focusFeedStore: FocusFeedState): Definition | null => {
	return cleanFeedDefinition(focusFeedStore);
};

export const cleanFeedDefinition = (focusFeedState: FocusFeedState): Definition | null => {
	if (focusFeedState.feedType === "socialmedia") {
		const definition: DefinitionSocial = cloneDeep(focusFeedState.social.definition);
		definition.include_expressions = filter(definition.include_expressions, expr => !!expr.q);
		definition.exclude_expressions = filter(definition.exclude_expressions, expr => !!expr.q);
		definition.include_profiles = filter(definition.include_profiles, profile => !!profile.url && !!profile.id);
		definition.exclude_profiles = filter(definition.exclude_profiles, profile => !!profile.url && !!profile.id);
		return definition;
	} else if (focusFeedState.feedType && focusFeedState.feedType === 'print') {
		const definition: DefinitionPrintVB = cloneDeep(focusFeedState.print.definition);
		definition.brands = filter(definition.brands, expr => !!expr.id);
		definition.product_types = filter(definition.product_types, expr => !!expr.id);
		definition.shops = filter(definition.shops, expr => !!expr.id);
		return definition;
	} else if (focusFeedState.feedType === "online") {
		const definition: DefinitionOnline = cloneDeep(focusFeedState.online.definition);
		definition.include_expressions = filter(definition.include_expressions, expr => !!expr.q);
		definition.exclude_expressions = filter(definition.exclude_expressions, expr => !!expr.q);
		return definition;
	} else if (focusFeedState.feedType === "print_dmr") {
		const definition: DefinitionPrint = cloneDeep(focusFeedState.printDmr.definition);
		definition.include_expressions = filter(definition.include_expressions, expr => !!expr.q);
		definition.exclude_expressions = filter(definition.exclude_expressions, expr => !!expr.q);
		definition.include_medias = filter(definition.include_medias, media => !!media.name && !!media.id);
		definition.exclude_medias = filter(definition.exclude_medias, media => !!media.name && !!media.id);
		definition.product_types = filter(definition.product_types, expr => !!expr.id);
		definition.brands = filter(definition.brands, expr => !!expr.id);

		return definition;
	}

	return null;
};

export const groupFacets = (facetItems: FilterFacet[], group: FacetGroup): void => {
	forEach(facetItems, facetItem => {
		const groupKey = findKey(group, groupFacet => groupFacet.includes(facetItem.key));
		if (groupKey) facetItem.key = groupKey;
	});
};

const unGroupFacets = (facetItems: FilterFacet[], group: FacetGroup): void => {
	const channelGroupFacetUsed = remove(facetItems, facetItem => group[facetItem.key]);
	const channelGroupKeys = map(channelGroupFacetUsed, 'key');
	forEach(channelGroupKeys, channelGroup => {
		forEach(group[channelGroup], channel => {
			facetItems.push({ key: channel });
		});
	});
};

export default {
	getPeriodParams,
	getInsertMentionSearchParams,
	getArticleSearchParams,
	getPreviewSearchParams,
	getArticleSearchFacetsParams,
	getPreviewSearchFacetsParams,
	getSearchFiltersParams,
	getPreviewDefinitionParams
};
