import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import { operators } from 'store/ui/topic/manager';
import TopicSelectors from 'store/entities/Topic/selectors';
import uiSelectors  from 'store/ui/topic/manager/selectors';
import TopicManager from './TopicManager';

const mapStateToProps = (state: State): StateProps => ({
	topics: TopicSelectors.getWithTags(state),
	isCreatingTopic: uiSelectors.isLoading(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onCreateTopic: name => dispatch(operators.createTopic({ name })),
	onRenameTopic: (id, oldName, name) => dispatch(operators.renameTopic({ id, oldName, name })),
	onDeleteTopic: id => dispatch(operators.deleteTopic({ id })),
	push: path => dispatch(push(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(TopicManager);
