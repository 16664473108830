import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import { operators as formOperators } from 'store/search/form';
import SearchPaginator from './SearchPaginator';

const mapStateToProps = ({ search: { form: { limit, start }, results: { total, loadingDocuments } } }: State): StateProps => ({
	limit,
	start,
	total,
	loadingDocuments
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetStart: start => dispatch(formOperators.setStart({ start }))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPaginator);
