import coverageIcon from 'vendor/svg/report/coverage.svg';
import coverageGreyIcon from 'vendor/svg/report/coverage_grey.svg';
import periodicIcon from 'vendor/svg/report/periodic.svg';
import periodicGreyIcon from 'vendor/svg/report/periodic_grey.svg';
import campaignIcon from 'vendor/svg/report/campaign.svg';
import campaignGreyIcon from 'vendor/svg/report/campaign_grey.svg';
import excelIcon from 'vendor/svg/report/excel.svg';
import excelGreyIcon from 'vendor/svg/report/excel_grey.svg';

import smallCoverage, { ReactComponent as CoverageSVG } from 'vendor/svg/report/small/coverage.svg';
import smallPeriodic, { ReactComponent as PeriodicSVG } from 'vendor/svg/report/small/periodic.svg';
import smallCampaign, { ReactComponent as CampaignSVG } from 'vendor/svg/report/small/campaign.svg';
import smallExcel, { ReactComponent as ExcelSVG } from 'vendor/svg/report/small/excel.svg';

import { ReportType } from 'store/ui/report/types';


const selectedIcons = {
	coverage: coverageIcon,
	periodic: periodicIcon,
	campaign: campaignIcon,
	excel: excelIcon
};

const icons = {
	coverage: coverageGreyIcon,
	periodic: periodicGreyIcon,
	campaign: campaignGreyIcon,
	excel: excelGreyIcon
};

const smallIcons = {
	coverage: smallCoverage,
	periodic: smallPeriodic,
	campaign: smallCampaign,
	excel: smallExcel
};

const smallIconSvgComponents = {
	coverage: CoverageSVG,
	periodic: PeriodicSVG,
	campaign: CampaignSVG,
	excel: ExcelSVG
};

export const getTypeIcon = (type: ReportType, selected: boolean = false) => {
	if (selected) return selectedIcons[type];
	return icons[type];
};
export const getSmallTypeIcon = (type: ReportType) => smallIcons[type];
export const getSmallTypeIconSVG = (type: ReportType) => smallIconSvgComponents[type];
