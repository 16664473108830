import React from "react";
import moment from "moment";
import { withT } from "lib/i18n";
import { Button, FontIcon } from "react-md";

import DateFilter from "components/common/DateFilter";
import { PresetOption, Ranges } from "components/common/DateFilter/types";
import { DateFactory } from "lib/dateFactory";

import './DateForm.scss';
import { User } from "class/User";

function DateForm(props: any) {
	const { t, brand_sync, feedType, user } = props;
	const { onSetBeginDate, onSetEndDate, onRecoverFeed, onFetchSearch, toggleResults } = props;

	const sessionUser = React.useMemo(() => new User(user!), [user]);

	const defaultMinDate: Date = feedType === 'print' || feedType === 'print_dmr' ?
		moment("20170301").startOf('day').toDate() :
		moment("20220101").startOf('day').toDate();

	const defaultMaxDate: Date = moment().endOf('day').add(90, 'days').toDate();


	const [minDate, maxDate] = React.useMemo(() => {
		let minDate;
		let maxDate;
		if (feedType) { // feed preview search
			// min date
			if (sessionUser.hasPermission('recovery.unrestricted_period')) {
				minDate = defaultMinDate;
			} else if (sessionUser.hasPermission('search.unrestricted_period')) {
				minDate = moment().subtract('month', 13).startOf('day').toDate();
			} else {
				minDate = moment().subtract('month', 3).startOf('day').toDate();
			}
			// max date
			if (feedType === 'print' || feedType === 'print_dmr') {
				maxDate = defaultMaxDate;
			} else {
				maxDate = moment().endOf('day').toDate(); // future dates only for print feeds
			}
		} else { // feed search, no restriction
			minDate = defaultMinDate;
			maxDate = defaultMaxDate;
		}
		return [minDate, maxDate];
	}, [feedType, defaultMaxDate, defaultMinDate, sessionUser]);

	const [isOpen, setIsOpen] = React.useState(false);
	const [dateRanges, setDateRanges] = React.useState<Ranges>({
		selectedDateRange: {
			key: 'selectedDateRange',
			startDate: moment().subtract(7, 'days').toDate(),
			endDate: moment().toDate()
		}
	});

	const presetOptions: PresetOption[] = [
		{
			label: t('date-filter-preset-last-week'),
			range: () => DateFactory.getPastDateRangeFor('week')
		},
		{
			label: t('date-filter-preset-last-month'),
			range: () => DateFactory.getPastDateRangeFor('month')
		},
		{
			label: t('filters.period.last_three_months'),
			range: () => DateFactory.getPastDateRangeFor('month', 3)
		}
	];

	const setDates = () => {
		const beginDateTz = moment(dateRanges.selectedDateRange.startDate).startOf('day').toDate();
		const endDateTz = moment(dateRanges.selectedDateRange.endDate).endOf('day').toDate();

		onSetBeginDate(beginDateTz);
		onSetEndDate(endDateTz);
	};

	const executeSearch = () => {
		setDates();
		onFetchSearch();
		toggleResults();
	};

	const recoverFeed = () => {
		setDates();
		onRecoverFeed();
	};

	const _getRecoveryContent = () => {
		const { t, recoveryId, recoveryStatus } = props;

		const capitalizeStatus = (status: string | null) => {
			if (!status) return;
			return status.charAt(0).toUpperCase() + status.slice(1);
		};

		if (recoveryId && recoveryStatus) return (
			<div id="focusRecoveryStatus">
				<p>{t('feed.form.recovery.progress')}</p>
				<div className="recovery-status-container">
					<div className={`recovery-status-badge ${recoveryStatus}`}></div>
					<span>{capitalizeStatus(recoveryStatus)}</span>
				</div>
			</div>
		);
	};

	const _getDateFilter = () => {
		const setDatesOnChange = (values: any) => {
			setDateRanges(values);
			setIsOpen(false);
		};

		return (
			<div id="focusFeedFormPeriodContainer">
				<DateFilter
					isOpen={isOpen}
					onToggleOpen={() => setIsOpen(!isOpen)}
					options={[]}
					ranges={dateRanges}
					isCompareModeActive={false}
					fullDisplay={false}
					firstAvailableDate={minDate}
					maxAvailableDate={maxDate}
					presetOptions={presetOptions}
					onDatesChange={setDatesOnChange}
					defaultRangeKey="selectedDateRange"
					editableDateInputs={false}
					dateFormat={user && user.settings.locale === 'en-US' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
				/>
			</div>
		);
	};

	return (
		<div id="dateForm">
			<div className="dates-input-container">{_getDateFilter()}</div>
			{_getRecoveryContent()}
			<div className="actions">
				<Button id="focusFeedDefinitionExecuteRecoverBtn" className="execute-recover-btn"
					onClick={() => recoverFeed()}
				>{t('feed.form.recovery.button')}</Button>
				<Button id="focusFeedDefinitionSearchBtn" className="definition-header-search-btn" flat
					disabled={brand_sync}
					onClick={executeSearch}
				>
					<FontIcon>search</FontIcon>
					{t('form.search.value')}
				</Button>
			</div>
		</div >
	);
}

export default withT(DateForm);
