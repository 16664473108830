import { connect } from "react-redux";
import { State } from "store/types";
import { StateProps } from "./types";
import profileSelectors from 'store/app/profile/selectors';
import Newsletter from "./Newsletter";

const mapStateToProps = (state: State): StateProps => ({
	user: profileSelectors.getUser(state)!
});

export default connect(mapStateToProps)(Newsletter);
