import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import ArticleInsertFormSocial from './ArticleInsertFormSocial';

const mapStateToProps = (state: State): StateProps => ({
	url: state.article.insert.finalUrl,
	loading: state.article.insert.loading
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleInsertFormSocial);
