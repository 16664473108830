import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { DispatchProps } from './types';
import { operators } from 'store/ui/topic/tag/manager';
import TopicManagerLayout from './TopicManagerLayout';

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onMigrateTag: (tagId: string, newTopicId: string) => dispatch(operators.migrateTag({ tagId, newTopicId }))
});

export default connect(null, mapDispatchToProps)(TopicManagerLayout);
