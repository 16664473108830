import React from 'react';
import DialogContainer from 'react-md/lib/Dialogs/DialogContainer';
import Button from 'react-md/lib/Buttons';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import map from 'lodash/map';

import { TProps, withT } from 'lib/i18n';
import enLocales from 'locales/en.json';
import esLocales from 'locales/es.json';
import itLocales from 'locales/it.json';
import frLocales from 'locales/fr.json';

import './FocusFeedDefinitionHelpDialog.scss';

type OwnProps = {
	onClose: () => void;
};

type ComponentProps = OwnProps & TProps;

type DefinitionTip = {
	key: string;
	example: string;
	operator: string;
	result: string;
};

type DefinitionTipExample = {
	key: string;
	example: string;
	result: string;
};

const _getTipsFromLocales = (definitionDialogLocales: any): DefinitionTip[] => {
	return map(definitionDialogLocales.tips, (tip, key) => ({ key, ...tip }));
};

const _getExamplesFromLocales = (definitionDialogLocales: any): DefinitionTipExample[] => {
	return map(definitionDialogLocales.examples, (example, key) => ({ key, ...example }));
};

const _getDefinitionLocales = (lang: string) => {
	let definitionLocales = enLocales;
	switch (lang) {
		case 'es':
			definitionLocales = esLocales;
			break;
		case 'it':
			definitionLocales = itLocales;
			break;
		case 'fr':
			definitionLocales = frLocales;
			break;
	}
	return definitionLocales.definition.dialog;
};

const _getOperatorsTable = (definitionDialogLocales: any) => {
	const tips = _getTipsFromLocales(definitionDialogLocales);
	return _generateTable(definitionDialogLocales, ['operator', 'example', 'result'], tips);
};

const _getExamplesTable = (definitionDialogLocales: any) => {
	const examples = _getExamplesFromLocales(definitionDialogLocales);
	return _generateTable(definitionDialogLocales, ['example', 'result'], examples);
};

const _generateTable = (definitionDialogLocales: any, columns: string[], values: Array<{ [key: string]: string }>) => {
	return (
		<table>
			<thead>
				<tr>
					{columns.map(column => (
						<th key={column}>{definitionDialogLocales.tip_header[column]}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{values.map(value => (
					<tr key={value.key}>
						{columns.map(column => (
							<td
								key={column}
								data-title={`${definitionDialogLocales.tip_header[column]}:`}
								dangerouslySetInnerHTML={{ __html: value[column] }}
							/>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};

export const FocusFeedDefinitionHelpDialog = (props: ComponentProps) => {
	const { i18n, onClose } = props;

	const definitionDialogLocales = _getDefinitionLocales(i18n.language);

	const content = (
		<div id="focusFeedDefinitionHelpDialogContent">
			<div className="definition-help-header">
				<Button
					id="focusFeedDefinitionHelpDialogCloseBtn"
					icon
					iconEl={<FontIcon iconClassName="icon-cancel"></FontIcon>}
					onClick={onClose}
				/>
			</div>
			<div className="definition-help-content">
				<div className="definition-help-content-title">
					<FontIcon>help_outline</FontIcon>
					<div className="definition-help-content-title-texts">
						<div className="big-text">{definitionDialogLocales.title}</div>
						<div className="small-text">{definitionDialogLocales.subtitle}</div>
					</div>
				</div>
				<div className="definition-help-content-tables">
					<div className="definition-help-table-header-responsive">
						{definitionDialogLocales.tip_header.operator}:
					</div>
					{_getOperatorsTable(definitionDialogLocales)}
					<div className="definition-help-table-header-responsive">
						{definitionDialogLocales.tip_header.example}:
					</div>
					{_getExamplesTable(definitionDialogLocales)}
				</div>
			</div>
		</div>
	);

	return (
		<DialogContainer
			id="focusFeedDefinitionHelpDialogContainer"
			visible={true}
			onHide={onClose}
			aria-labelledby="focusFeedDefinitionHelpDialogContainer"
		>
			{content}
		</DialogContainer>
	);
};

export default withT(FocusFeedDefinitionHelpDialog);
