import React from 'react';
import Drawer from 'react-md/lib/Drawers/Drawer';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import SearchFacets from 'components/Search/Facets';

import './FocusFeedFacets.scss';

class FocusFeedFacets extends React.Component<ComponentProps> {

	constructor(props: ComponentProps) {
		super(props);
		if (Drawer.getCurrentMedia().desktop) props.onToggleDrawer();
	}

	public render() {
		const { drawerVisible } = this.props;
		const { onToggleDrawer } = this.props;

		return (
			<Drawer
				id="facetsDrawer"
				type={Drawer.DrawerTypes.FLOATING}
				inline
				visible={drawerVisible}
				clickableDesktopOverlay={false}
				overlay={!Drawer.getCurrentMedia().desktop && drawerVisible}
				onVisibilityChange={() => onToggleDrawer()}
				onMediaTypeChange={() => { }}
			>
				<SearchFacets />
			</Drawer>
		);
	}
}

export default withT(FocusFeedFacets);
