import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/search/facets';
import { operators as filterOperators } from 'store/search/filters';
import SearchFacetsGroup from './SearchFacetsGroup';
import { DispatchProps, StateProps } from './types';
import { getCategoryName } from 'store/entities/Category/selectors';
import { getEventName } from 'store/entities/Event/selectors';

const mapStateToProps = (state: State): StateProps => {
	const { router, search: { facets, filters }, focus: { feed } } = state;
	return {
		location: router.location,
		facetFilters: filters.facetsGroups,
		facetsGroupsOpened: facets.facetsGroupsOpened,
		feedType: feed.feedType,
		loadingFacets: facets.loadingFacets,
		showHiddenFacets: facets.showHiddenFacets,
		brand_sync: feed.brand_sync,
		getCategoryName: (id: string) => getCategoryName(state, id),
		getEventName: (id: string) => getEventName(state, id)
	};
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onToggleFacetFilter: (facetGroupKey, facetFilter) => dispatch(filterOperators.toggleFacetFilter({ facetGroupKey, facetFilter })),
	onRemoveFacetFilterGroup: facetGroupKey => dispatch(filterOperators.removeFacetFilterGroup({ facetGroupKey })),
	onToggleFacetGroupOpened: groupKey => dispatch(operators.toggleFacetsGroupOpened({ groupKey })),
	onSetShowMoreDialogFacetGroupKey: facetGroupKey => dispatch(operators.setShowMoreFacetGroupKey({ facetGroupKey })),
	onToggleHiddenFacets: () => dispatch(operators.toggleHiddenFacets())
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchFacetsGroup);
