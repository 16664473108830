import React from 'react';
import { Autocomplete } from 'lib/imports/react-md';

import { AutocompleterItem, ComponentProps } from './types';

const Autocompleter = (props: ComponentProps) => {
	const { items, defaultText = '', onSelected, onChange, onBlur, ...rest } = props;

	const [inputValue, setInputValue] = React.useState(defaultText);

	React.useEffect(() => { setInputValue(defaultText); }, [defaultText]);

	const autocompleteProps = {
		filter: Autocomplete.caseInsensitiveFilter,
		showUnfilteredData: true,
		...rest
	};

	return (
		<Autocomplete
			{...autocompleteProps}
			data={items}
			dataLabel="text"
			dataValue="id"
			onBlur={() => {
				if (defaultText) {
					if (!inputValue) onSelected(null);
					else if (defaultText !== inputValue) setInputValue(defaultText);
				} else {
					if (inputValue) setInputValue('');
				}
				if (onBlur) onBlur(inputValue);
			}}
			onChange={value => {
				setInputValue(value);
				if (onChange) onChange(value);
			}}
			value={inputValue}
			onAutocomplete={(id, index, matches) => {
				const item = matches[index] as AutocompleterItem;
				setInputValue(item.text);
				onSelected(item);
			}}
		/>
	);
};

export default React.memo(Autocompleter);
