import React from 'react';

import { withT } from 'lib/i18n';
import { filter, slice } from 'lib/imports/lodash';
import Paginator from 'components/common/Paginator';
import { ComponentProps, ResultRow } from './types';

import './EmailListResults.scss';

const NewsletterEmailListResults = (props: ComponentProps) => {
	const { pageLimit, rows, start, searchValue } = props;
	const { onStartChange, t } = props;

	const filterQuery = new RegExp(searchValue, "gi");
	const filteredResults = filter(rows, result => !!result.filterValue.match(filterQuery));

	const totalResults = filteredResults.length;
	const endPageResult = totalResults < (start + pageLimit) ? totalResults : start + pageLimit;

	const pageResults: ResultRow[] = slice(filteredResults, start, endPageResult);

	let paginatorSection;
	if (totalResults > 0) {
		let paginator;
		if (totalResults > pageLimit) paginator = (
			<Paginator
				start={start}
				limit={pageLimit}
				total={totalResults}
				onStartChange={onStartChange}
			></Paginator>
		);
		else paginator = null;

		paginatorSection = (
			<div className="email-list-results-pagination">
				<span className="email-list-results-counter">{t('results.search.documents.pagination_info', {
					start: start + 1,
					end: endPageResult,
					count: totalResults
				})}</span>
				{paginator}
			</div>
		);
	}
	else paginatorSection = null;

	return (
		<div className="email-list-entity-results-container">
			<div className="email-list-entity-results-table-wrapper">
				<div className="email-list-entity-results-table">
					<div className="email-list-entity-results-table-inner">
						{pageResults.map(result => result.row)}
					</div>
				</div>
			</div>
			{paginatorSection}
		</div>
	);
};

export default React.memo(withT(NewsletterEmailListResults));
