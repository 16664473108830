import { all } from 'redux-saga/effects';

import draftSagas from './draft/sagas';
import listSagas from './list/sagas';
import editSagas from './edit/sagas';
import emailListSagas from './emailList/sagas';

// define sagas generator function to register all sagas
export default function* sagas() {
	yield all([
		draftSagas(),
		listSagas(),
		editSagas(),
		emailListSagas()
	]);
}
