import flatten from 'lodash/flatten';

// Tree structure definition and helper methods.
// This tree has not a main root node, having multiple root nodes instead.

export type Tree<T> = {
	rootNodes: Array<TreeNode<T>>
};

export type TreeNode<T> = {
	parent?: TreeNode<T>,
	children?: Array<TreeNode<T>>
	data: T
};

const createTree = <T>(rootNodes: Array<TreeNode<T>>): Tree<T> => {
	return { rootNodes };
};

const getRootNodesAttributeValues = <T extends any>(tree: Tree<T>, attr: string) => {
	return tree.rootNodes.map(node => node.data[attr]);
};

const getNodeChildrenAttributeValues = <T extends any>(node: TreeNode<T>, attr: string) => {
	if (!node.children) return [];
	return node.children.map(childNode => childNode.data[attr]);
};

const getNodeParentsAttributeValues = <T extends any>(node: TreeNode<T>, attr: string): any[] => {
	if (!node.parent) return [];
	return [node.parent.data[attr], ...flatten(getNodeParentsAttributeValues(node.parent, attr))];
};

const getNodeDescendentsAttributeValues = <T extends any>(node: TreeNode<T>, attr: string, includeSelf: boolean): any[] => {
	const childrenNodesValues = node.children ? flatten(node.children.map(childNode => getNodeDescendentsAttributeValues(childNode, attr, true))) : [];
	if (includeSelf) return flatten([node.data[attr], childrenNodesValues]);
	return childrenNodesValues;
};

export const TreeUtils = {
	createTree,
	getRootNodesAttributeValues,
	getNodeChildrenAttributeValues,
	getNodeParentsAttributeValues,
	getNodeDescendentsAttributeValues
};
