import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/search/facets';
import { DispatchProps, StateProps } from './types';
import FocusFeedFacets from './FocusFeedFacets';

const mapStateToProps = ({ search: { facets } }: State): StateProps => ({
	drawerVisible: facets.drawerVisible
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onToggleDrawer: () => dispatch(operators.toggleDrawer())
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedFacets);
