import { Dispatch } from 'redux';
import { State } from 'store/types';
import { connect } from 'react-redux';
import { operators as searchHorizontalFiltersOperators } from 'store/search/horizontalFilters';
import { StateProps, DispatchProps } from './types';
import { DateRange } from './DateRange';
import { UserObject } from 'class/User';

const mapStateToProps = (state: State): StateProps => ({
	user: state.app.profile.user as UserObject,
	startDate: state.search.horizontalFilters.begin_date,
	endDate: state.search.horizontalFilters.end_date
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onDatesChange: (startDate: Date, endDate: Date) => {
		dispatch(searchHorizontalFiltersOperators.setDateRange({
			startDate,
			endDate
		}));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(DateRange);