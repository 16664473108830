import React from 'react';
import { Redirect } from 'react-router-dom';

import FletcherLoader from 'components/common/Fletcher/Loader';
import ReportStatus from 'components/Report/Status';
import ReportSetup from 'components/Report/Setup';

import { ComponentProps } from './types';

import './ReportLayout.scss';

const ReportLayout: React.FC<ComponentProps> = props => {
	const { pathFocusId, focusId, focusExists } = props;
	const { onFocusChanged } = props;

	React.useEffect(() => {
		onFocusChanged(pathFocusId || null);
	}, [pathFocusId]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div id="reportLayout" className='discover-form-wrapper'>
			<ReportStatus />

			<div className='discover-form-content'>
				<FletcherLoader resource='focus' >
					{(focusId && !focusExists) ? <Redirect to="/report" /> : <ReportSetup />}
				</FletcherLoader>
			</div>
		</div>
	);
};

export default React.memo(ReportLayout);
