import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { isEmpty } from 'lib/imports/lodash';

import Api from 'lib/ajax/Api';
import { State } from 'store/types';
import { Notification } from 'types/notification';
import { operators as notificationOperators } from 'store/app/notifications';
import { operators as EmailListOperators, EmailListObject } from 'store/entities/EmailList';
import EmailListSelectors from 'store/entities/EmailList/selectors';

import { Actions, operators } from './actions';
import selectors from './selectors';

export default function* sagas() {
	yield all([
		takeLatest(operators.renameEmailList, renameEmailList),
		takeLatest(operators.addNewEmailAddress, addNewEmailAddress),
		takeLatest(operators.deleteEmailAddress, deleteEmailAddress),
		takeLatest(operators.renameEmailAddress, renameEmailAddress)
	]);
}

function* renameEmailList({ payload: { name } }: Actions["RenameEmailList"]) {
	const api = new Api();
	try {
		const state: State = yield select();
		const listId = selectors.getEmailListId(state)!;
		const duplicatedName = yield _listNameExists(name);
		if (duplicatedName) {
			const notification: Notification = { level: 'danger', t: 'error.api_server.list_name_already_exists' };
			return yield put(notificationOperators.add({ notification }));
		}
		yield put(EmailListOperators.update({ id: listId, name }));
		yield call([api, 'put'], `/emailList/${listId}`, { data: { name } });
		yield put(operators.renameEmailListSuccess());
	} catch (error) {
		yield put(operators.renameEmailListError(error));
	}
}

function* addNewEmailAddress({ payload: { email } }: Actions["AddNewEmailAddress"]) {
	const api = new Api();
	try {
		const list: EmailListObject = yield _getList();
		yield put(EmailListOperators.update({ id: list.id, emails: { ...list.emails, [email]: true } }));
		yield call([api, 'post'], `/emailList/${list.id}/email`, { data: { email } });
		yield put(operators.addNewEmailAddressSuccess());
	} catch (error) {
		yield put(operators.addNewEmailAddressError(error));
	}
}

function* deleteEmailAddress({ payload: { email } }: Actions["DeleteEmailAddress"]) {
	const api = new Api();
	try {
		const list: EmailListObject = yield _getList();
		const newEmailList = { ...list.emails };
		delete newEmailList[email];
		yield put(EmailListOperators.update({ id: list.id, emails: newEmailList }));
		yield call([api, 'delete'], `/emailList/${list.id}/email`, { params: { email } });
		yield put(operators.deleteEmailAddressSuccess());
	} catch (error) {
		yield put(operators.deleteEmailAddressError(error));
	}
}

function* renameEmailAddress({ payload: { oldEmail, newEmail } }: Actions["RenameEmailAddress"]) {
	const api = new Api();
	try {
		const list: EmailListObject = yield _getList();
		const newEmailList = { ...list.emails, [newEmail]: true };
		delete newEmailList[oldEmail];
		yield put(EmailListOperators.update({ id: list.id, emails: newEmailList }));
		yield call([api, 'delete'], `/emailList/${list.id}/email`, { params: { email: oldEmail } });
		yield call([api, 'post'], `/emailList/${list.id}/email`, { data: { email: newEmail } });
		yield put(operators.renameEmailAddressSuccess());
	} catch (error) {
		yield put(operators.renameEmailAddressError(error));
	}
}

function* _getList() {
	const state: State = yield select();
	return selectors.getEmailList(state);
}

function* _listNameExists(name: string) {
	const state: State = yield select();
	return !isEmpty(EmailListSelectors.getByName(state, name));
}
