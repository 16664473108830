import { Model, attr } from "redux-orm";
import { createOrmReducer, ReducerOrm } from "lib/store/reducer";

import { operators } from "./actions";
import { InstagramAccountFields, InstagramAccountData, InstagramAccountModel, InstagramAccountInstance } from "./types";


export class InstagramAccount extends Model<typeof InstagramAccount, InstagramAccountFields> {
	static get modelName() { return 'InstagramAccount' as const; }
	static get idAttribute() { return 'id' as const; }
	static get fields() {
		return {
			id: attr(),
			name: attr(),
			facebook_page_id: attr(),
			facebook_page_name: attr(),
			profile_picture_url: attr(),
			screen_name: attr()
		};
	}

	static reducer: ReducerOrm<InstagramAccountModel> = createOrmReducer<InstagramAccountModel>(operators);

	static parse(instagramData: InstagramAccountData): InstagramAccountInstance {
		return this.upsert(instagramData);
	}
}
