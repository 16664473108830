import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { User } from 'class/User';

import NewsletterList from './List';
import NewsletterEdit from './Edit';
import NewsletterDraft from './Draft';
import { ComponentProps } from './types';

const Newsletter = ({ user: userObject }: ComponentProps) => {
	const user = React.useMemo(() => new User(userObject!), [userObject]);
	if (!user.hasPermission('newsletter.list')) return <Redirect to={{ pathname: '/' }} />;

	return <>
		<Switch>
			<Route path="/newsletter/:id(\d+)" exact render={({ match }) => <NewsletterEdit newsletterId={match.params.id} />} />

			<Route path="/newsletter/:id(\d+)" strict component={NewsletterDraft} />

			<Route path="/newsletter/create" strict exact component={NewsletterEdit} />
			<Route path="/newsletter/" strict exact render={() => (<Redirect to={"/newsletter/create"} />)} />

			<Route path="/newsletter" strict exact component={NewsletterList} />
			<Route path="/newsletter" render={() => (<Redirect to="/newsletter" />)} />
		</Switch>
	</>;
};

export default Newsletter;
