import { createSelector } from "reselect";
import { State } from "store/types";

const rootSelector = (state: State) => state.ui.report.setup;
const settingsSelector = (state: State) => state.ui.report.setup.settings;

export default {
	getStep: createSelector(rootSelector, state => state.step),
	getType: createSelector(settingsSelector, state => state.type),
	getPeriod: createSelector(settingsSelector, state => state.period),
	getLanguage: createSelector(settingsSelector, state => state.language),
	getCurrency: createSelector(settingsSelector, state => state.currency),
	getMinDate: createSelector(settingsSelector, state => state.minDate),
	getMaxDate: createSelector(settingsSelector, state => state.maxDate),
	getCountry: createSelector(settingsSelector, state => state.country),
	getGroupedByCountry: createSelector(settingsSelector, state => state.groupedByCountry),
	getTopicTagIds: createSelector(settingsSelector, state => state.topicTagIds),
	getCategories: createSelector(settingsSelector, state => state.categories),
	getSettings: createSelector(rootSelector, state => state.settings),
	getFeeds: createSelector(rootSelector, state => state.feeds),
	getFeedClippings: createSelector(rootSelector, state => state.feedClippings),
	areFeedsLoaded: createSelector(rootSelector, state => state.feedsLoaded),
	getTopicTagOperator: createSelector(rootSelector, state => state.settings.topicTagOperator)
};
