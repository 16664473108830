import { FeedType, Definition, FeedFilter } from 'class/Feed';

export const FocusFeedsGroupKey = "insights.filter";
export type SearchPeriod = 'last_week' | 'last_month' | 'last_three_months' | 'next_fifteen_days' | 'custom';
export type SearchDateType = 'publication_date' | 'integration_date';

export type PeriodParams = {
	period?: SearchPeriod,
	begin_date?: string,
	end_date?: string,
};

export type FiltersParams = {
	channel_type_id_filter?: string,
	"language.id_filter"?: string,
	"tenant.categories_id_filter"?: string,
	"tenant.topic_tag_filter"?: string,
	country_path_filter?: string,
	media_id_filter?: string,
	[FocusFeedsGroupKey]?: string,
	"photo.section.issue.media.value_filter"?: string,
	"document_origin_filter"?: string,
	"print.article_type_filter"?: string,
	"print.event.id_filter"?: string
};

export type PreviewFiltersParams = FeedFilter;

export type ArticleFacetSearchParams = PeriodParams & FiltersParams & {
	query: string,
	limit?: number,
	facet_fields: string
};

export type ArticleSearchParams = PeriodParams & FiltersParams & {
	query: string,
	sort?: string,
	limit?: number,
	start?: number,
	insights_insert_url_search?: number,
	date_type: SearchDateType,
	type?: FeedType,
	excluded_fields?: string
};

export type PreviewSearchParams = PeriodParams & {
	sort?: string,
	limit?: number,
	start?: number,
	type: FeedType,
	filters: PreviewFiltersParams,
	definition: Definition | null
};

export type PreviewFacetSearchParams = PeriodParams & FiltersParams & {
	limit?: number,
	facet_fields: string,
	type: FeedType,
	definition: Definition | null
};

export type SearchParams = ArticleSearchParams | ArticleFacetSearchParams | PreviewSearchParams | PreviewFacetSearchParams;
