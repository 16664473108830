import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { operators } from './actions';

export type State = {
	loading: boolean,
	selectedTag: any | null,
	tagLoaded: boolean,
	migrateErrorTags: Map<string, boolean>
};

export const INITIAL_STATE: State = {
	loading: false,
	selectedTag: null,
	tagLoaded: false,
	migrateErrorTags: new Map<string, boolean>()
};

const reducerHandlers: ReducerHandlers<State> = {
	createTag: state => ({ ...state, loading: true }),
	createTagSuccess: state => ({ ...state, loading: false }),
	createTagError: state => ({ ...state, loading: false }),
	migrateTag: state => ({ ...state, loading: true }),
	migrateTagSuccess: state => ({ ...state, loading: false }),
	migrateTagError: state => ({ ...state, loading: false })
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
