import React from 'react';
import "moment-timezone/builds/moment-timezone-with-data-10-year-range";
import { withT } from 'lib/i18n';
import { Button, CircularProgress, FontIcon, SelectField } from 'lib/imports/react-md';
import { isEqual, isEmpty, map } from 'lib/imports/lodash';

import timezones from 'config/timezones.json';
import { useAssignReducer } from 'lib/hooks';
import { FacebookUserObject, FacebookUser, FacebookPermission } from 'store/entities/FacebookUser';
import { LanguageCode, Locale } from 'store/entities/User/types';
import { ComponentProps } from './types';

import './UserSettings.scss';

const UserSettings = (props: ComponentProps) => {
	const { isLoading, user, facebookUsers, currentFacebookUser, t } = props;
	const { onUpdateUserSettings, onSetFacebookDialogMode, onSetPermissionsMissing, onDeauthorizeFacebookUser, onClose } = props;

	const [formSettings, dispatchFormSettings] = useAssignReducer(user.settings);

	const onSave = React.useCallback(() => {
		if (!isEqual(formSettings, user.settings)) onUpdateUserSettings(formSettings);
	}, [formSettings, user, onUpdateUserSettings]);

	const header = React.useMemo(() => (
		<div className="header md-grid">
			<FontIcon className="">person</FontIcon>
			<span>{t('account.value')}</span>
			<Button id="userSettingsCloseButton" className="close-btn btn--no-background" icon primary onClick={onClose}>clear</Button>
		</div>
	), [onClose, t]);

	const formSettingsSection = React.useMemo(() => {
		if (isLoading) return (<CircularProgress id="settingsUserProgress" />);
		return (
			<div className="settings-section">
				<div className="section-title">{t('account.settings')}</div>
				<div className="section-content md-grid md-grid--stacked">
					<SelectField
						id="settingsUserLanguageSelect"
						label={t("account.language.value")}
						className="md-cell select"
						menuItems={["en", "fr", "es", "it"].map(lang => ({ value: lang, label: t(`account.language.${lang}`) }))}
						value={formSettings.language_code}
						onChange={value => dispatchFormSettings({ language_code: value.toString() as LanguageCode })}
					/>
					<SelectField
						id="settingsUserLocaleSelect"
						label={t("account.locale.value")}
						className="md-cell select"
						menuItems={["en-US", "en-GB", "fr-FR", "es-ES", "it-IT"].map(lang => ({ value: lang, label: t(`account.locale.${lang}`) }))}
						value={formSettings.locale}
						onChange={value => dispatchFormSettings({ locale: value.toString() as Locale })}
					/>
					<SelectField
						id="settingsUserTimezoneSelect"
						label={t("account.timezone")}
						className="md-cell select"
						menuItems={timezones}
						value={formSettings.timezone}
						onChange={value => dispatchFormSettings({ timezone: value.toString() as string })}
					/>
					<div className="buttons-container md-grid">
						<Button id="settingsUserCancelButton" className="btn--no-background btn--red-ink" flat onClick={onClose}>{t("filters.period.custom.dialog.cancel")}</Button>
						<Button
							id="settingsUserSaveButton"
							className="btn--green-ink"
							raised
							iconEl={<FontIcon>save</FontIcon>}
							iconBefore={true}
							onClick={onSave}>
							{t("filters.period.custom.dialog.ok")}
						</Button>
					</div>
				</div>
			</div>
		);
	}, [isLoading, formSettings, dispatchFormSettings, onClose, onSave, t]);

	const getFacebookUserContent = React.useCallback((facebookUser: FacebookUserObject<'instagramAccounts'>) => {
		let instagramAccountsContent = null;
		if (!isEmpty(facebookUser.instagramAccounts)) {
			const permissionsMissing = FacebookUser.getMissingPermissions(facebookUser.scope.split(',') as FacebookPermission[]);
			instagramAccountsContent = (
				<div>
					<div className="connected-instagrams-text">{t('account.instagram_accounts.title')}</div>
					{facebookUser.instagramAccounts.map(instagramAccount => (
						<div className="instagram-account md-grid" key={instagramAccount.id}>
							<img src={instagramAccount.profile_picture_url} alt="" />
							<div className="name">{`${instagramAccount.name} - ${instagramAccount.screen_name}`}</div>
							{!isEmpty(permissionsMissing) && (
								<div className="instagram-account-permissions-warn">
									<Button icon
										title={t('facebook.instagram.permissions_missing.icon_title')}
										onClick={() => onSetPermissionsMissing(permissionsMissing)}
									>warning</Button>
								</div>
							)}
							{!instagramAccount.mentions_subscribed && (
								<div id={`settingsUserWebhookErrorWarningBtnInstagramAccount${instagramAccount.id}`} className="instagram-account-permissions-warn">
									<Button icon
										title={t('feed.form.instagram.webhook_failed_title')}
										onClick={() => onSetFacebookDialogMode('instagram_webhook_failed')}
									>warning</Button>
								</div>
							)}
						</div>
					))}
				</div>
			);
		}
		return (
			<div key={facebookUser.id} className="facebook-account-row">
				<div className="facebook-account md-grid">
					<img src={facebookUser.picture_url} alt="" />
					<div className="name">{facebookUser.name}</div>
					{(currentFacebookUser && currentFacebookUser.id === facebookUser.id) ? (
						<Button flat className="disconnect-facebook" onClick={() => {
							if (isEmpty(facebookUser.instagramAccounts)) onDeauthorizeFacebookUser();
							else onSetFacebookDialogMode('instagram_deauthorize_remove');
						}}>
							{t('account.instagram_accounts.unlink')}
						</Button>
					) : null}
				</div>
				{instagramAccountsContent}
			</div>
		);
	}, [onDeauthorizeFacebookUser, onSetFacebookDialogMode, currentFacebookUser, onSetPermissionsMissing, t]);

	const facebookUsersSection = React.useMemo(() => {
		if (isLoading) return null;
		if (!facebookUsers) return (<CircularProgress id="facebookUsersProgress" />);

		return (
			<div className="settings-section facebook-section">
				<div className="section-title">
					<FontIcon iconClassName="icon-facebook"></FontIcon>
					<span>{t('account.facebook_accounts.title')}</span>
				</div>
				<div className="section-content md-grid md-grid--stacked">
					{(isEmpty(facebookUsers)) ?
						(<span className="empty">{t('account.facebook_accounts.empty')}</span>)
						:
						(map(facebookUsers, getFacebookUserContent))
					}
					<div className="add">
						{!currentFacebookUser ?
							<Button id="settingsUserAddFacebookButton" flat onClick={() => onSetFacebookDialogMode('facebook')}>
								<FontIcon iconClassName="icon-facebook"></FontIcon>
								<span className="button-text">{t('account.facebook_accounts.add_button')}</span>
							</Button>
							: (
								<Button id="settingsUserAddInstagramButton" flat
									onClick={() => onSetFacebookDialogMode('instagram')}
								>
									<FontIcon iconClassName="icon-instagram"></FontIcon>
									<span className="button-text">{t('account.instagram_accounts.manage_button')}</span>
								</Button>
							)
						}
					</div>
				</div>
			</div>
		);
	}, [isLoading, facebookUsers, t, getFacebookUserContent, currentFacebookUser, onSetFacebookDialogMode]);

	return (
		<div id="settingsUser">
			{header}
			{formSettingsSection}
			{facebookUsersSection}
		</div>
	);
};

export default withT(UserSettings);
