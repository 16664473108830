import actionCreatorFactory, { Action } from 'typescript-fsa';

const actionCreator = actionCreatorFactory('@@urlQuery');

type Payloads = {
	SetSuggestedTags: { suggested_tags: string[] | null };
}

export type Actions = {
	SetSuggestedTags: Action<Payloads["SetSuggestedTags"]>;
}

export const operators = {
	setSuggestedTags: actionCreator<Payloads["SetSuggestedTags"]>('SET_SUGGESTED_TAGS')
};
