import React from 'react';
import { groupBy, map, transform, filter, keyBy, size, without, isEmpty } from 'lib/imports/lodash';

import { withT } from 'lib/i18n';
import FletcherLoader from 'components/common/Fletcher/Loader';
import { ComponentProps } from './types';

import './FocusSelect.scss';
import FocusCard from 'components/common/Focus/Card';
import FocusAutocompleter, { FocusAutocompleterItems } from 'components/common/Focus/Autocompleter';

const FocusSelect = (props: ComponentProps) => {
	const { focusList, selectedFocusIds, error, multiple } = props;
	const { t, onChange } = props;

	const onAdd = React.useCallback(focusId => onChange([...selectedFocusIds, focusId]), [selectedFocusIds, onChange]);
	const onRemove = React.useCallback(focusId => onChange(without(selectedFocusIds, focusId)), [selectedFocusIds, onChange]);

	const selectedFocusById = React.useMemo(() => keyBy(selectedFocusIds), [selectedFocusIds]);
	const selectedFocusList = React.useMemo(() => filter(focusList, focus => (focus.id in selectedFocusById)), [focusList, selectedFocusById]);

	const selectedFocusSection = React.useMemo(() => (
		<div className="selected-focus-section">
			{map(selectedFocusList, focus => (
				<FocusCard focus={focus} key={focus.id} onClear={() => onRemove(focus.id)}>
					<div className="focus-card-info">
						{map(groupBy(focus.feeds, 'type'), (feeds, type) => (
							<div key={type}>{t(`reports.${type}_feed`, { count: feeds.length })}</div>
						))}
					</div>
				</FocusCard>
			))}
		</div>
	), [selectedFocusList, onRemove, t]);

	const autocompleterFocusItems = React.useMemo(() => transform(focusList, (result: FocusAutocompleterItems, { id, name, feeds }) => {
		if (!(id in selectedFocusById) && !isEmpty(feeds)) result.push({ id, text: name });
	}), [focusList, selectedFocusById]);

	const focusAutocompleterSection = React.useMemo(() => {
		if (!multiple && size(selectedFocusList) > 0) return null;
		return (<FocusAutocompleter id="focusSelectAutocompleter" items={autocompleterFocusItems} onSelected={item => onAdd(item.id)} error={error} />);
	}, [multiple, autocompleterFocusItems, selectedFocusList, onAdd, error]);

	return (
		<div className='focus-select'>
			<FletcherLoader resource="focus">
				<>
					{selectedFocusSection}
					{focusAutocompleterSection}
				</>
			</FletcherLoader>
		</div>
	);
};

export default React.memo(withT(FocusSelect));
