import { DiscoverTable } from 'components/common/DiscoverTable';
import { withT } from 'lib/i18n';
import React from 'react';
import Dialog from '../Dialog';
import { ComponentProps } from './types';
import { ReactComponent as ContentCopy } from 'vendor/svg/feed/file_copy.svg';
import { FontIcon } from 'lib/imports/react-md';

import './ListDialog.scss';

const ListDialog = (props: ComponentProps) => {
	const { id, title, tableData, headerText, onCancelText } = props;
	const { t, onCancel, onFillClipboard } = props;

	const [copied, setCopied] = React.useState(false);

	const onCopy = React.useCallback(() => {
		const clipboardString = onFillClipboard();
		navigator.clipboard.writeText(clipboardString).then(() => setCopied(true));
	}, [onFillClipboard]);

	const table  = React.useMemo(() => (
		<div className="list-dialog-table-wrapper">
			<DiscoverTable
				data={tableData.data}
				columns={tableData.columns}
				tableStyle={['list-dialog-list']}
				rowStyle={['list-dialog-list-row']}
				headerStyle={['list-dialog-list-header']}
			/>
		</div>
	), [tableData]);

	const actions = React.useMemo(() => (
		<div className="md-grid">
			<div className="md-cell">
				<button id="listDialogCopyContentBtn" className="copy-btn md-btn md-btn--flat md-pointer--hover" onClick={onCopy}>
					<ContentCopy></ContentCopy>&nbsp;
					{t('dialog.list.copy')}
				</button>
			</div>
			<div className="md-cell">
				{copied ?
					<div className="copied">
						<FontIcon>done</FontIcon>&nbsp;{t('dialog.list.copied')}
					</div>
					: null
				}
			</div>
		</div>
	), [copied, onCopy, t]);

	const content = React.useMemo(() => (
		<>
			{headerText && (
				<div className="list-dialog-header">{headerText}</div>
			)}
			{table}
			{actions}
		</>
	), [table, actions, headerText]);

	const listDialog = React.useMemo(() => (
		<Dialog
			id={id}
			title={t(title)}
			content={content}
			onCancel={onCancel}
			onCancelText={onCancelText || t('dialog.close')}
		/>
	), [id, title, content, onCancel, onCancelText, t]);

	return listDialog;
};

export default React.memo(withT(ListDialog));