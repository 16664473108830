import ActionCreators from 'lib/store/actions';
import { NewsletterObject } from 'store/entities/Newsletter';

const actions = new ActionCreators('@@newsletter/edit');

const [createNewsletter, createNewsletterSuccess, createNewsletterError] = actions.apiOperators<{ formNewsletter: Partial<NewsletterObject> }>('CREATE_NEWSLETTER');
const [updateNewsletter, updateNewsletterSuccess, updateNewsletterError] = actions.apiOperators<{ formNewsletter: Partial<NewsletterObject> }>('UPDATE_NEWSLETTER');
const [queueNewsletter, queueNewsletterSuccess, queueNewsletterError] = actions.apiOperators<{ formNewsletter: Partial<NewsletterObject> }>('QUEUE_NEWSLETTER');

export type Actions = {
	CreateNewsletter: ReturnType<typeof createNewsletter>;
	CreateNewsletterSuccess: ReturnType<typeof createNewsletterSuccess>;
	CreateNewsletterError: ReturnType<typeof createNewsletterError>;
	UpdateNewsletter: ReturnType<typeof updateNewsletter>;
	UpdateNewsletterSuccess: ReturnType<typeof updateNewsletterSuccess>;
	UpdateNewsletterError: ReturnType<typeof updateNewsletterError>;
	QueueNewsletter: ReturnType<typeof queueNewsletter>;
	QueueNewsletterSuccess: ReturnType<typeof queueNewsletterSuccess>;
	QueueNewsletterError: ReturnType<typeof queueNewsletterError>;
};

export const operators = {
	createNewsletter,
	createNewsletterSuccess,
	createNewsletterError,
	updateNewsletter,
	updateNewsletterSuccess,
	updateNewsletterError,
	queueNewsletter,
	queueNewsletterSuccess,
	queueNewsletterError
};
