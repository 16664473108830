import { Action, actionCreatorFactory } from 'typescript-fsa';
import queryString from 'query-string';

const actionCreator = actionCreatorFactory('@@search');

type Payloads = {
	SetFormAndFilters: { query: queryString.ParsedQuery };
	SetFetchSearchError: { error: object };
}

export type Actions = {
	SetFormAndFilters: Action<Payloads["SetFormAndFilters"]>;
	SetFetchSearchError: Action<Payloads["SetFetchSearchError"]>;
}

export const operators = {
	setFormAndFilters: actionCreator<Payloads["SetFormAndFilters"]>('SET_FORM_AND_FILTERS'),
	setFetchSearchError: actionCreator<Payloads["SetFetchSearchError"]>('FETCH_SEARCH_ERROR', { api: 'error' })
};
