import React from 'react';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import { isEmpty, get } from 'lib/imports/lodash';

import Dropzone, { AddFileError } from 'components/common/Dropzone';
import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import { getImageFromObjectURL } from 'lib/image';

import './FocusDashboardLogoUpload.scss';

const FocusDashboardLogoUpload = (props: ComponentProps) => {
	const { t, focus, imageMeta } = props;
	const { onSetUploadImageCrop, onAddNotification } = props;
	const { onImageUpdated } = props;

	const [imageFile, setImageFile] = React.useState<File | null>(null);
	const [imageUrl, setImageUrl] = React.useState<string>("");
	const [imageCrop, setImageCrop] = React.useState<Point>({ x: 0, y: 0 });
	const [cropZoom, setCropZoom] = React.useState(1);

	const onUploadImageError = React.useCallback((errors: AddFileError[]) => {
		const error = errors[0];
		if (error.error === "incorrect_file_type") onAddNotification({ level: "warning", t: 'newsletter.digest.edit.custom_image.rejected_type' });
		else if (error.error === "file_too_large") onAddNotification({ level: "warning", t: 'error.focus_logo_big_image' });
		else onAddNotification({ level: "warning", t: 'newsletter.digest.edit.custom_image.upload_error' });
	}, [onAddNotification]);
	const onUploadFileAdded = React.useCallback((files: File[]) => {
		setImageFile(files[0]);
	}, [setImageFile]);
	const onUploadFileRemoved = React.useCallback(() => {
		setImageFile(null);
	}, [setImageFile]);
	const onCropCompleted = React.useCallback((cropPtg: Area, cropPx: Area) => {
		onSetUploadImageCrop({
			x: cropPx.x,
			y: cropPx.y,
			width: cropPx.width!,
			height: cropPx.height!
		});
	}, [onSetUploadImageCrop]);
	React.useEffect(() => {
		if (imageUrl) URL.revokeObjectURL(imageUrl);

		if (imageFile) {
			(async () => {
				try {
					const imageBlob = URL.createObjectURL(imageFile);
					const image: HTMLImageElement = await getImageFromObjectURL(imageBlob);
					if (image.width < 100 || image.height < 100) {
						setImageUrl("");
						onImageUpdated(null);
						return onAddNotification({ t: `newsletter.digest.edit.custom_image.small_size`, level: "warning" });
					}
					setImageUrl(imageBlob);
					onImageUpdated(imageFile);
				} catch (err) {
					setImageFile(null);
					setImageUrl("");
					onImageUpdated(null);
					return onAddNotification({ t: `newsletter.digest.edit.custom_image.upload_error`, level: "warning" });
				}
			})();
		}
		else {
			setImageUrl("");
			onImageUpdated(null);
		}
	}, [imageFile]);// eslint-disable-line react-hooks/exhaustive-deps

	const previousImage = React.useMemo(() => {
		return get(imageMeta, 'custom') ? <img
			id="focusDashboardLogoUploadedImage"
			title="logo preview" alt="logo preview"
			src={focus.url_logo}
		/> : null;
	}, [imageMeta, focus]);

	return (
		<div id="focusDashboardLogoUpload">
			<p>{t('focus.uploadLogo.customDescription')}</p>
			<Dropzone
				files={imageFile ? [imageFile] : []}
				multiple={false}
				accept="image/*"
				maxSize={256000}
				onFilesAdded={onUploadFileAdded}
				onAddFilesError={onUploadImageError}
				onRemoveFile={onUploadFileRemoved}
			/>
			{previousImage}
			{imageFile && !isEmpty(imageUrl) ?
				<div id="focusDashboardLogoUploadCrop">
					<Cropper
						image={imageUrl}
						aspect={1}
						crop={imageCrop}
						zoom={cropZoom}
						onCropChange={setImageCrop}
						onZoomChange={setCropZoom}
						onCropComplete={onCropCompleted}
					/>
				</div>
				: null}
		</div>
	);
};

export default withT(React.memo(FocusDashboardLogoUpload));
