import React from 'react';
import { Button, Paper } from 'lib/imports/react-md';

import { withT } from "lib/i18n";
import { ComponentProps } from './types';

import './CategorizationTopicTagChip.scss';

const CategorizationTopicTagChip = (props: ComponentProps) => {
	const { t, tag, isRemovable, isNewTagName, tagId } = props;
	const { onTagRemoved } = props;

	if (!tag && !isNewTagName) return null;
	return <Paper
		className="categorization-topic-tag-chip"
		zDepth={1}
	>
		<div className="categorization-topic-tag-chip-text">
			<span>{isNewTagName ? t('topics.general') : tag!.topic.name}</span>
			<span>{isNewTagName ? tagId : tag!.name}</span>
		</div>
		{isRemovable && <Button
			className="categorization-topic-tag-chip-remove-btn"
			flat
			iconChildren="close"
			children=""
			onClick={onTagRemoved}
		/>}
	</Paper>;
};

export default  withT(React.memo(CategorizationTopicTagChip));
