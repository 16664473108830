import React, { useCallback, useMemo } from 'react';
import { withT } from "lib/i18n";

import { FilterFacet } from 'class/Filter';
import { Facet, FacetItem } from 'class/Facet';
import DropdownFilter from "components/common/DropdownFilter/DropdownFilter";

import { ComponentProps } from './types';
import { split } from 'lodash';

const Category = (props: ComponentProps) => {
	const { excluded, facetFilters, dmrCategories, display, isOpen } = props;
	const { t, onSetExcludeField, onSetFacetGroupFilters, onToggleOpen } = props;

	const facetItems = React.useMemo(() => {
		const parsedCategories = Object.keys(dmrCategories)
			.filter(categoryKey => split(categoryKey, '-').length === 2)
			.map(parentCategoryKey => ({
				key: parentCategoryKey,
				sorting_name: dmrCategories[split(parentCategoryKey, '-')[0]] + '/' + dmrCategories[parentCategoryKey],
				name: dmrCategories[parentCategoryKey],
				children: Object.keys(dmrCategories)
					.filter(categoryKey => split(categoryKey, '-').length === 3 && categoryKey.indexOf(parentCategoryKey) === 0)
					.map(childCategoryKey => ({
						key: childCategoryKey,
						name: dmrCategories[childCategoryKey]
					})).sort((a, b) => a.name.localeCompare(b.name))
			}));
		const sortedCategories: any[] = parsedCategories.sort((a, b) => a.name.localeCompare(b.name));
		if (sortedCategories.length && sortedCategories[0].name === '_NO_DATA') sortedCategories.push(sortedCategories.shift());
		return sortedCategories;
	}, [dmrCategories]);

	const categoryFilters = useMemo(() => (facetFilters && facetFilters[Facet.categoriesKey]) || [], [facetFilters]);

	const handleChange = useCallback((items: FacetItem[]) => {
		onSetFacetGroupFilters(items as FilterFacet[]);
	}, [onSetFacetGroupFilters]);

	const handleExclude = useCallback((set: boolean) => onSetExcludeField(set), [onSetExcludeField]);

	const dropdown = useMemo(() =>
		<DropdownFilter
			id='categoryFilter'
			key='filters-category-dropdown'
			title={t('filters.new_categories.value')}
			excluded={excluded}
			filters={facetItems}
			initSelectedFilters={categoryFilters as FacetItem[]}
			defaultFilter={{ name: t('reports.all_male_sing'), key: 'all' }}
			disable={false}
			isOpen={isOpen}
			onToggleOpen={onToggleOpen}
			onChange={handleChange}
			onExclude={handleExclude}
		/>

	, [t, excluded, facetItems, categoryFilters, isOpen, onToggleOpen, handleChange, handleExclude]);
	return display ? dropdown : null;
};

export default withT(Category);
