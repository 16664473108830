import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { concat, groupBy, isEmpty, keys } from 'lodash';

import { State } from 'store/types';
import { State as FormState } from 'store/search/form';
import { State as FiltersState } from 'store/search/filters';
import { State as HorizontalFiltersState } from 'store/search/horizontalFilters';
import FeedSelectors from 'store/entities/Feed/selectors';
import FocusSelectors from 'store/entities/Focus/selectors';
import { Settings } from 'store/entities/User';
import Mekong from 'lib/ajax/Mekong';
import { getArticleSearchParams, getPeriodParams } from 'lib/searchParams';
import { SearchPeriod } from 'types/search/form';

import { Currency } from 'class/Tenant';
import { FocusObject } from 'class/Focus';

import { operators, Actions } from './actions';

const MAX_FEEDS_PER_FOCUS = 5;

export default function* sagas() {
	yield all([
		takeEvery(operators.exportQuickReport.type, exportQuickReport)
	]);
}

function* exportQuickReport({ payload }: Actions['ExportQuickReport']) {
	const state: State = yield select((state: State) => state);
	const reqBodies: any[] = __buildExportRequest(state);
	if (state.search.results.total === 0 || isEmpty(reqBodies)) {
		yield put(operators.toggleQuickReport(false));
		return;
	}

	try {
		yield all(reqBodies.map(body => call(Mekong.post, '/report', { data: { ...body } })));
		yield put(operators.toggleQuickReport(false));
		yield put(operators.setReportedFocusNames({ focuses: reqBodies.map(body => body.focus.name) }));
	} catch (err) {
		// TODO GIS-3747 / GIS-3742 Add layout related stuff if needed
		console.log('Catched error: ', JSON.stringify(err));
	}
}

function __getReportSearchForm(state: State) {
	const searchForm: FormState = state.search.form;
	const horizontalFilters: HorizontalFiltersState = state.search.horizontalFilters;
	return {
		...searchForm,
		period: 'custom' as SearchPeriod,
		begin_date: horizontalFilters.begin_date,
		end_date: horizontalFilters.end_date
	};
}

function __getQueryObject(state: State) {
	const searchFilters: FiltersState = state.search.filters;
	const currency: Currency = state.app.profile.tenant!.settings.currency;
	const excludedFields: string[] = state.search.horizontalFilters.excludedFields;

	const finalSearchForm = __getReportSearchForm(state);
	const params = getArticleSearchParams(finalSearchForm, searchFilters, excludedFields) as any;

	params.currency = currency;
	return params;
};

function __getJobBody(state: State, feedData: { id: string, name: string }[], focus: any) {
	const currency: Currency = state.app.profile.tenant!.settings.currency;
	const userSettings: Settings = state.app.profile.user!.settings;
	const searchFilters: FiltersState = state.search.filters;

	const finalSearchForm = __getReportSearchForm(state);
	const dates = getPeriodParams({ period: finalSearchForm.period, beginDate: finalSearchForm.begin_date, endDate: finalSearchForm.end_date });

	return {
		origin: 'article',
		type: 'excel',
		minDate: dates.begin_date,
		maxDate: dates.end_date,
		feeds: feedData,
		focus: { id: focus.id, name: focus.name, logoUrl: focus.url_logo },
		feedClippings: [],
		language: userSettings.language_code,
		currency,
		country: searchFilters.facetsGroups['country_path'] ? searchFilters.facetsGroups['country_path'].map(country => country.key).join(',') : 'all',
		groupedByCountry: false,
		topicTagIds: searchFilters.facetsGroups['tenant.topic_tag'] ? searchFilters.facetsGroups['tenant.topic_tag'].map(tag => tag.key) : [],
		topicTagOperator: 'OR',
		categories: searchFilters.facetsGroups['tenant.categories_id'] ? searchFilters.facetsGroups['tenant.categories_id'].map(tag => tag.key) : [],
		languageId: searchFilters.facetsGroups['language.id'] ? searchFilters.facetsGroups['language.id'].map(country => country.key).join(',') : '',
		mediaId: searchFilters.facetsGroups['media_id'] ? searchFilters.facetsGroups['media_id'].map(country => country.key).join(',') : ''
	};
};

function __buildExportRequest(state: State) {
	const focusList: FocusObject[] = state.focus.list.focusList || [];
	const searchFilters: FiltersState = state.search.filters;
	const reqBodies: any[] = [];

	// If there aren't focuses nor feeds selected
	if (isEmpty(searchFilters.focus) && isEmpty(searchFilters.feeds)) {
		focusList.forEach(focus => {
			const queryObj = __getQueryObject(state);
			const focusFeeds = FocusSelectors.getFeeds(state, focus.id)!.slice(0, MAX_FEEDS_PER_FOCUS);
			const jobBodyObject = __getJobBody(state, focusFeeds, focus);
			if (focusFeeds.length > 0) reqBodies.push({ ...jobBodyObject, queryObj });
		});
	} else {
		const feedByFocusId = groupBy(searchFilters.feeds, feedId => FeedSelectors.get(state, feedId)!.focus);
		concat(searchFilters.focus, keys(feedByFocusId)).forEach(focusId => {
			const focus = FocusSelectors.get(state, focusId);
			let feedFocus;
			if (feedByFocusId[focusId]) {
				feedFocus = feedByFocusId[focusId].map(feedId => FeedSelectors.get(state, feedId)!);
			} else {
				feedFocus = FocusSelectors.getFeeds(state, focusId);
			}
			if (!focus || !feedFocus) return;

			const feedData = feedFocus.slice(0, MAX_FEEDS_PER_FOCUS);
			const queryObj = __getQueryObject(state);
			const jobBodyObject = __getJobBody(state, feedData, focus);
			const jobBody = { ...jobBodyObject, queryObj };
			if (feedData && feedData.length > 0) reqBodies.push(jobBody);
		});
	}
	return reqBodies;
}
