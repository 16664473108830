import { ReducerHandlers } from 'lib/store/reducer';
import { DefinitionPrint } from 'class/Feed';
import { State as FocusFeedState } from 'store/focus/feed/reducers';
import { Actions } from './actions';
import set from 'lodash/set';
import filter from 'lodash/filter';
import { BrandItem } from 'types/focus/feed/definition/printDmr/brands';
import { ProductTypeItem } from 'types/focus/feed/definition/printDmr/productTypes';
import { MediaExpression } from 'class/Feed';

export type State = {
	definition: DefinitionPrint,
	brandItems: BrandItem[],
	productTypeItems: ProductTypeItem[],
	mediaItems: MediaExpression[]
};

export const INITIAL_STATE: State = {
	definition: {
		brands: [{
			enabled: true,
			id: '',
			names: [],
			scope: ''
		}],
		product_types: [],
		include_expressions: [],
		exclude_expressions: [],
		include_medias: [],
		exclude_medias: []
	},
	brandItems: [],
	productTypeItems: [],
	mediaItems: []
};

export const reducerHandlers: ReducerHandlers<FocusFeedState> = {
	setDMRDefinition: (state, { payload: { definition } }: Actions["SetDefinition"]): FocusFeedState => ({
		...state,
		printDmr: {
			...state.printDmr,
			definition
		}
	}),

	setDMRExpression: (state, { payload: { expressionKey, index, values } }: Actions["SetExpression"]): FocusFeedState => ({
		...state,
		printDmr: {
			...state.printDmr,
			definition: {
				...state.printDmr.definition,
				[expressionKey]: set(state.printDmr.definition[expressionKey], index, { ...state.printDmr.definition[expressionKey][index], ...values })
			}
		}
	}),

	updatePrintDmrExpression: (state, { payload: { type, index, expression } }: Actions["UpdatePrintDmrExpression"]): FocusFeedState => ({
		...state,
		printDmr: {
			...state.printDmr,
			definition: {
				...state.printDmr.definition,
				[type]: [...set(state.printDmr.definition[type], index, { ...expression })]
			}
		}
	}),

	removePrintDmrExpression: (state, { payload: { type, index } }: Actions["RemovePrintDmrExpression"]): FocusFeedState => ({
		...state,
		printDmr: {
			...state.printDmr,
			definition: {
				...state.printDmr.definition,
				[type]: filter(state.printDmr.definition[type], (expr, i: number) => index !== i)
			}
		}
	}),

	addPrintDmrExpression: (state, { payload: { type, expression } }: Actions["AddPrintDmrExpression"]): FocusFeedState => ({
		...state,
		printDmr: {
			...state.printDmr,
			definition: {
				...state.printDmr.definition,
				[type]: [...state.printDmr.definition[type], expression]
			}
		}
	}),

	fetchDmrBrands: (state): FocusFeedState => ({
		...state,
		printDmr: {
			...state.printDmr,
			brandItems: []
		}
	}),

	fetchDmrBrandsSuccess: (state, { payload: brandItems }: Actions["FetchDmrBrandsSuccess"]): FocusFeedState => ({
		...state,
		printDmr: {
			...state.printDmr,
			brandItems
		}
	}),

	fetchDmrBrandsError: (state): FocusFeedState => ({
		...state,
		printDmr: {
			...state.printDmr,
			brandItems: []
		}
	}),

	resetDmrBrands: (state): FocusFeedState => ({
		...state,
		printDmr: {
			...state.printDmr,
			brandItems: []
		}
	}),

	fetchDmrProductTypes: (state): FocusFeedState => ({
		...state,
		printDmr: {
			...state.printDmr,
			productTypeItems: []
		}
	}),

	fetchDmrProductTypesSuccess: (state, { payload: productTypeItems }: Actions["FetchDmrProductTypesSuccess"]): FocusFeedState => ({
		...state,
		printDmr: {
			...state.printDmr,
			productTypeItems
		}
	}),

	fetchDmrProductTypesError: (state): FocusFeedState => ({
		...state,
		printDmr: {
			...state.printDmr,
			productTypeItems: []
		}
	}),

	resetDmrProductTypes: (state): FocusFeedState => ({
		...state,
		printDmr: {
			...state.printDmr,
			productTypeItems: []
		}
	}),

	fetchDmrMedias: (state): FocusFeedState => ({
		...state,
		printDmr: {
			...state.printDmr,
			mediaItems: []
		}
	}),

	fetchDmrMediasSuccess: (state, { payload: mediaItems }: Actions["FetchDmrMediasSuccess"]): FocusFeedState => ({
		...state,
		printDmr: {
			...state.printDmr,
			mediaItems
		}
	}),

	fetchDmrMediasError: (state): FocusFeedState => ({
		...state,
		printDmr: {
			...state.printDmr,
			mediaItems: []
		}
	}),

	resetDmrMedias: (state): FocusFeedState => ({
		...state,
		printDmr: {
			...state.printDmr,
			mediaItems: []
		}
	})
};
