import React from "react";
import { TextField } from 'lib/imports/react-md';

import { TProps, withT } from "lib/i18n";
import { NewsletterObject } from 'store/entities/Newsletter';


type NewsletterSubject = NewsletterObject["subject"];
type NewsletterReplyTo = NewsletterObject["send_configuration_json"]["reply_to"];
type NewsletterSenderName = NewsletterObject["send_configuration_json"]["sender_name"];

type OwnProps = {
	subject: NewsletterSubject,
	replyTo: NewsletterReplyTo,
	senderName: NewsletterSenderName,
	subjectError?: string
	replyToError?: string
	onSubjectUpdated: (hour: NewsletterSubject) => void
	onReplyToUpdated: (minutes: NewsletterReplyTo) => void
	onSenderNameUpdated: (senderName: NewsletterSenderName) => void
};

type ComponentProps = OwnProps & TProps;

const NewsletterEditFormEmailing = (props: ComponentProps) => {
	const { t, subject, replyTo, senderName, subjectError, replyToError } = props;
	const { onSubjectUpdated, onReplyToUpdated, onSenderNameUpdated } = props;

	const subjectInput = React.useMemo(() => (
		<>
			<TextField
				id="newsletterEditEmailSubjectInput"
				label={t('newsletter.form.input.subject.value')}
				defaultValue={subject}
				onBlur={(event: any) => onSubjectUpdated(event.target.value.trim())}
				required
				fullWidth
				error={!!subjectError}
				errorText={subjectError}
			/>
			<TextField
				id="newsletterEditReplyToInput"
				label={t('newsletter.form.input.reply_to.value')}
				defaultValue={replyTo}
				onBlur={(event: any) => onReplyToUpdated(event.target.value.trim())}
				fullWidth
				error={!!replyToError}
				errorText={replyToError}
			/>
		</>
	), [t, subject, replyTo, subjectError, replyToError, onSubjectUpdated, onReplyToUpdated]);

	const senderNameInput = React.useMemo(() => (
		<TextField
			id="newsletterEditSenderNameInput"
			label={t('newsletter.form.input.sender_name')}
			defaultValue={senderName}
			onBlur={(event: any) => onSenderNameUpdated(event.target.value.trim())}
		/>
	), [t, senderName, onSenderNameUpdated]);

	return (
		<div id="newsletterEditSectionEmailing" className="form-section vertical-align-top">
			<div className="form-section-title">
				{t('newsletter.form.label.emailing')}
			</div>
			<div className="form-section-content">
				<div className="form-section-content-column column-direction">
					{subjectInput}
				</div>
				<div className="form-section-content-column column-direction">
					{senderNameInput}
				</div>
			</div>
		</div>
	);
};

export default withT(React.memo(NewsletterEditFormEmailing));
