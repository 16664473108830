import { Action, actionCreatorFactory } from 'typescript-fsa';
import { DefinitionOnline, ExpressionsType, MediaExpression, OnlineExpressionValues, MediaExpressionType, MassiveMediaExpression } from 'class/Feed';

const actionCreator = actionCreatorFactory('@@focus/feed/online');

type Payloads = {
	SetDefinition: { definition: DefinitionOnline }
	SetExpression: { expressionKey: ExpressionsType | 'main', index: number, values: OnlineExpressionValues }
	ResetMedias: void
	FetchMedias: { query: string }
	FetchMediaSuccess: { mediaResults: MediaExpression[] }
	FetchMediaError: { error: object }
	MassiveFetchMedia: { query: string[], expressionKey: MediaExpressionType }
	MassiveFetchMediaSuccess: { mediaResults: MassiveMediaExpression[], errorMedias: MassiveMediaExpression[], expressionKey: MediaExpressionType }
	ClearErrorMedias: void
	MassiveFetchMediaError: void
}

export type Actions = {
	SetDefinition: Action<Payloads["SetDefinition"]>
	SetExpression: Action<Payloads["SetExpression"]>
	ResetMedias: Action<Payloads["ResetMedias"]>
	FetchMedias: Action<Payloads["FetchMedias"]>
	FetchMediaSuccess: Action<Payloads["FetchMediaSuccess"]>
	FetchMediaError: Action<Payloads["FetchMediaError"]>
	MassiveFetchMedia: Action<Payloads["MassiveFetchMedia"]>
	MassiveFetchMediaSuccess: Action<Payloads["MassiveFetchMediaSuccess"]>
	ClearErrorMedias: Action<Payloads["ClearErrorMedias"]>
	MassiveFetchMediaError: Action<Payloads["MassiveFetchMediaError"]>
}

export const operators = {
	setDefinition: actionCreator<Payloads["SetDefinition"]>('SET_DEFINITION'),
	setExpression: actionCreator<Payloads["SetExpression"]>('SET_EXPRESSION'),
	resetMedias: actionCreator<Payloads["ResetMedias"]>('RESET_MEDIAS'),
	fetchMedias: actionCreator<Payloads["FetchMedias"]>('FETCH_MEDIAS', { api: 'start' }),
	fetchMediaSuccess: actionCreator<Payloads["FetchMediaSuccess"]>('FETCH_MEDIAS_SUCCESS', { api: 'success' }),
	fetchMediaError: actionCreator<Payloads["FetchMediaError"]>('FETCH_MEDIAS_ERROR', { api: 'success' }),
	massiveFetchMedia: actionCreator<Payloads["MassiveFetchMedia"]>('MASSIVE_FETCH_MEDIA', { api: 'start' }),
	massiveFetchMediaSuccess: actionCreator<Payloads["MassiveFetchMediaSuccess"]>('MASSIVE_FETCH_MEDIA_SUCCESS', { api: 'success' }),
	clearErrorMedias: actionCreator<Payloads["ClearErrorMedias"]>('CLEAR_ERROR_MEDIAS'),
	massiveFetchMediaError: actionCreator<Payloads["MassiveFetchMediaError"]>('MASSIVE_FETCH_MEDIA_ERROR', { api: 'success' })
};
