import { all } from 'redux-saga/effects';

import categorizationSagas from './categorization/sagas';
import insertSagas from './insert/sagas';

// define sagas generator function to register all sagas
export default function* sagas() {
	yield all([
		categorizationSagas(),
		insertSagas()
	]);
}
