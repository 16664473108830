import Categorization from './Categorization';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';

const mapStateToProps = (state: State): StateProps => ({
	suggestedTags: state.ui.urlQuery.suggested_tags
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Categorization);
