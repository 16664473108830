import React from 'react';

import { withT } from 'lib/i18n';
import ReportSetupSettingsType from './Type';
import ReportSetupSettingsPeriod from './Period';
import ReportSetupSettingsDate from './Date';
import ReportSetupSettingsCategoryTags from './CategoryTags';
import ReportSetupSettingsLanguageCurrency from './LanguageCurrency';
import ReportSetupSettingsCountry from './Country';

import './ReportSetupSettings.scss';

const ReportSetupSettings: React.FC = props => {
	return (
		<>
			<ReportSetupSettingsType />
			<ReportSetupSettingsPeriod />
			<ReportSetupSettingsDate />
			<ReportSetupSettingsCategoryTags />
			<ReportSetupSettingsCountry />
			<ReportSetupSettingsLanguageCurrency />
		</>
	);
};

export default withT(React.memo(ReportSetupSettings));
