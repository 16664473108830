import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import { operators as searchFormOperators } from 'store/search/form';

import FocusFeedForm from './FocusFeedForm';
import { DefinitionSocial, FeedType } from 'class/Feed';

function haveMentions(feedType: FeedType | null, definition: DefinitionSocial) {
	if (feedType !== 'socialmedia') return false;
	return definition.instagram_accounts.map(instagramAccount => {
		const linkedExpression = instagramAccount.linkedExpression;
		if (linkedExpression.type === "main")
			return definition.main.enabled;
		else {
			const expression = definition[linkedExpression.type];
			const linkedExpressionIndex = +linkedExpression.index;
			return expression[linkedExpressionIndex].enabled;
		}
	}).length > 0;
}

const mapStateToProps = (state: State): StateProps => {
	const { app: { profile: { tenant } }, search, focus } = state;
	return {
		start: search.form.start,
		limit: search.form.limit,
		total: search.results.total,
		loadingDocuments: search.results.loadingDocuments,
		sort: search.form.sort,
		feedType: focus.feed.feedType,
		tenant: tenant!,
		brand_sync: focus.feed.brand_sync,
		haveMentions: haveMentions(state.focus.feed.feedType, state.focus.feed.social.definition)
	};
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetSort: sort => dispatch(searchFormOperators.setSort({ sort }))
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedForm);
