import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { Actions, operators } from './actions';

export type FocusLogoImageMeta = { url?: string, custom?: number, default?: string };

export type State = {
	readonly imageMeta: FocusLogoImageMeta | null
	readonly loading: boolean
};

export const INITIAL_STATE: State = {
	imageMeta: null,
	loading: false
};

export const reducerHandlers: ReducerHandlers<State> = {
	fetchImageMeta: (state, { payload }: Actions["FetchImageMeta"]): State => ({
		...state,
		loading: true
	}),
	fetchImageMetaSuccess: (state, { payload }: Actions["FetchImageMetaSuccess"]): State => ({
		...state,
		imageMeta: payload.imageMeta,
		loading: false
	}),
	fetchImageMetaError: (state, { payload }: Actions["FetchImageMetaError"]): State => ({
		...state,
		imageMeta: null,
		loading: false
	}),
	removeLogo: (state): State => ({
		...state,
		loading: true
	}),
	removeLogoSuccess: (state): State => ({
		...state,
		imageMeta: null,
		loading: false
	}),
	removeLogoError: (state): State => ({
		...state,
		imageMeta: null,
		loading: false
	}),
	acceptFacebook: (state): State => ({
		...state,
		loading: true
	}),
	acceptUpload: (state): State => ({
		...state,
		loading: true
	}),
	acceptSuccess: (state): State => ({
		...state,
		imageMeta: null,
		loading: false
	}),
	acceptError: (state): State => ({
		...state,
		imageMeta: null,
		loading: false
	})
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
