import React from 'react';
import { Button, DialogContainer } from 'lib/imports/react-md';
import { isUndefined } from 'lib/imports/lodash';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';

import './SimpleDialog.scss';

const SimpleDialog = (props: ComponentProps) => {

	const { t, id, text, visible, headerImage, content, acceptText, cancelText, acceptColor } = props;
	const { onShow, onClose, onAccept, onCancel } = props;

	const cancelBtn = React.useMemo(() => {
		if (!onCancel) return null;
		return <Button id={`${id}CancelBtn`} className='lm-simple-dialog-cancel-btn' flat onClick={onCancel}>{cancelText || t('form.cancel')}</Button>;
	}, [id, t, onCancel, cancelText]);

	const acceptBtn = React.useMemo(() => {
		if (!onAccept) return null;
		return <Button id={`${id}AcceptBtn`} className={`lm-simple-dialog-accept-btn btn--${acceptColor || "green"}`} flat onClick={onAccept}>{acceptText || t('form.accept')}</Button>;
	}, [id, t, onAccept, acceptText, acceptColor]);

	const dialogContent = React.useMemo(() => (
		<div className="dialog-container">
			<div className="dialog-header md-grid">
				{ headerImage && <img src={headerImage} alt="" id="dialog-header-img" /> }
				<div className="dialog-text">{text}</div>
			</div>
			<div className="dialog-content">{content}</div>
			<div className={"dialog-footer md-grid"}>
				{cancelBtn}
				{acceptBtn}
			</div>
		</div>
	), [headerImage, text, content, cancelBtn, acceptBtn]);

	const dialog = React.useMemo(() => {
		if (!onClose && !onCancel) {
			console.error('At least one of [ onClose | onCancel ] handlers required');
			return null;
		}

		return <DialogContainer
			id={id}
			className="launchmetrics-simple-dialog"
			visible={isUndefined(visible) ? true : visible}
			onShow={onShow}
			onHide={onClose || onCancel}
			closeOnEsc
			focusOnMount={false}
			aria-labelledby="launchmetrics-dialog"
			children={dialogContent}
		/>;
	}, [id, dialogContent, visible, onShow, onClose, onCancel]);

	return dialog;
};

export default React.memo(withT(SimpleDialog));
