import { Model, attr } from "redux-orm";

import { createOrmReducer, ReducerOrm } from "lib/store/reducer";
import { operators } from './actions';
import { CategoryFields, CategoryModel } from "./types";

export class Category extends Model<typeof Category, CategoryFields> {
	static get modelName() { return 'Category' as const; };
	static get idAttribute() { return 'id' as const; };
	static get fields() {
		return {
			id: attr(),
			name: attr()
		};
	};

	static reducer: ReducerOrm<CategoryModel> = createOrmReducer<CategoryModel>(operators);
}