import React from 'react';
import { Link } from 'react-router-dom';

import { withT } from 'lib/i18n';
import Api from 'lib/ajax/Api';

import { CircularProgress, FontIcon } from 'lib/imports/react-md';
import { ComponentProps } from './types';

import './NewsletterDraftSend.scss';

const NewsletterDraftSend = (props: ComponentProps) => {
	const { id, timestamp, t } = props;

	const [loading, setLoading] = React.useState(true);
	const [errorResponse, setErrorResponse] = React.useState<string | null>(null);

	React.useEffect(() => {
		const source = Api.getCancelTokenSource();
		(async () => {
			try {
				const api = new Api();
				await api.post(`/newsletter/${id}/draft/${timestamp}/deliver`);
				setLoading(false);
			} catch (error) {
				if (error.code === 'AXIOS_CANCELLED') return;
				setErrorResponse(error.code as string);
				setLoading(false);
			}
		})();
		return () => {
			source.cancel('component unmounted');
		};
	}, [id, timestamp]);

	const content = React.useMemo(() => {
		if (loading) return (<CircularProgress id="newsletterDraftSendContent" className="loader" />);
		if (!errorResponse) return (<div>{t('newsletter.digest.dispatched.sent_msg')}</div>);

		if (errorResponse === 'GONE') return (<div>{t('newsletter.digest.dispatched.sent_msg')}</div>);
		return (<div>{t('newsletter.digest.error.incorrect_tenant')}</div>); //NOT_FOUND | UNAUTHORIZED | WHATEVER
	}, [loading, errorResponse, t]);

	return (
		<div id="newsletterDraftSendContent">
			{content}
			<Link to="/newsletter">
				<FontIcon>reply</FontIcon>
				<span>{t('newsletter.digest.dispatched.return_to_list')}</span>
			</Link>
		</div>
	);
};

export default React.memo(withT(NewsletterDraftSend));
