import { connect } from 'react-redux';

import FocusSelectors from 'store/entities/Focus/selectors';

import { MapStateToProps, MapDispatchToProps } from './types';
import FocusFeedSelect from './FocusFeedSelect';

const mapStateToProps: MapStateToProps = state => ({
	focusList: FocusSelectors.getAllOrderedByNameWithFeeds(state)
});

const mapDispatchToProps: MapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedSelect);
