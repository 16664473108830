import { map, filter, join } from 'lib/imports/lodash';

const INSTAGRAM_ACCOUNT_FIELDS = 'ig_id,biography,name,username,profile_picture_url,website,followers_count,follows_count,media_count';

export type GetDetails = {
	id: string,
	name: string,
	email: string
}

export type GetPicture = {
	data: {
		url: string,
		height: number,
		width: number,
		is_silhouette: boolean
	}
}
type GetAccountsResponse = {
	data: Array<{
		id: string,
		name: string,
		picture: GetPicture,
		access_token: string,
		tasks: string[],
		instagram_business_account?: {
			id: string,
			ig_id: number,
			biography: string,
			name: string,
			username: string,
			profile_picture_url: string,
			website: string,
			followers_count: number,
			follows_count: number,
			media_count: number
		}
	}>
}
export type GetAccounts = Array<{
	id: string,
	screen_name: string,
	name: string,
	profile_picture_url: string,
	facebook_page_id: string,
	facebook_page_name: string,
	facebook_page_access_token: string
}>

type GetPermissionsResponse = {
	data: Array<{
		status: string,
		permission: string
	}>
}
export type GetPermissions = string[]
export type RevokePermissions = { success: boolean }


export default class FacebookGraph {

	private __token: string;

	constructor(token: string) {
		this.__token = token;
	}

	public getDetails() {
		return this.__request<GetDetails>('get', '/me', { fields: 'name,email' });
	}

	public getPicture() {
		return this.__request<GetPicture>('get', '/me/picture', { redirect: false });
	}

	public getAccounts() {
		const fields = `id,name,picture,access_token,tasks,instagram_business_account{${INSTAGRAM_ACCOUNT_FIELDS}}`;
		return this.__request<GetAccountsResponse>('get', '/me/accounts', { fields }).then(this._parseAccountsResponse);
	}

	public getPermissions() {
		return this.__request<GetPermissionsResponse>('get', '/me/permissions').then(this._parsePermissionsResponse);
	}

	public revokePermissions() {
		return this.__request<RevokePermissions>('delete', '/me/permissions');
	}

	private __request<Response>(method: "get" | "post" | "delete", url: string, params: object = {}) {
		return new Promise<Response>((resolve, reject) => {
			FB.api(`${url}?access_token=${this.__token}`, method, params, (response: any) => {
				if (!response || response.error) {
					const errorText = response ? join(map(response.error, (value, key) => `${key}: ${value}`), ', ') : 'unknown';
					console.error(`[FacebookGraph] Api Error ${errorText}`);
					reject({ code: 'FACEBOOK_API_ERROR', message: errorText });
				} else resolve(response);
			});
		});
	}

	private _parseAccountsResponse(response: GetAccountsResponse): GetAccounts {
		return map(filter(response.data, 'instagram_business_account'),
			({ id, name, access_token, instagram_business_account: ig }) => ({
				id: ig!.id,
				screen_name: ig!.username,
				name: ig!.name,
				profile_picture_url: ig!.profile_picture_url,
				facebook_page_id: id,
				facebook_page_name: name,
				facebook_page_access_token: access_token
			})
		);
	}

	private _parsePermissionsResponse(response: GetPermissionsResponse): GetPermissions {
		return map(filter(response.data, { status: 'granted' }), 'permission');
	}
}
