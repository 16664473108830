import { EventItem } from './types';
const Focus: EventItem[] = [
	{ name: 'Focus Creation', event: 'focus_creation' },
	{ name: 'Focus Deletion', event: 'focus_deletion' },
	{ name: 'Focus Renaming', event: 'focus_renaming' }
];

const Feed: EventItem[] = [
	{ name: 'Feed Creation', event: 'feed_creation' },
	{ name: 'Feed Deletion', event: 'feed_deletion' },
	{ name: 'Feed Renaming', event: 'feed_renaming' },
	{ name: 'Feed Update', event: 'feed_update' },
	{ name: 'Feed Branding', event: 'feed_branding' },
	{ name: 'Feed Branding Sync', event: 'feed_branding_sync' },
	{ name: 'Feed Sentiment', event: 'feed_sentiment_check' }
];

const Topic: EventItem[] = [
	{ name: 'Topic Creation', event: 'topic_creation' },
	{ name: 'Topic Deletion', event: 'topic_deletion' },
	{ name: 'Topic Renaming', event: 'topic_renaming' }
];

const Tag: EventItem[] = [
	{ name: 'Tag Creation', event: 'tag_creation' },
	{ name: 'Tag Deletion', event: 'tag_deletion' },
	{ name: 'Tag Renaming', event: 'tag_renaming' },
	{ name: 'Tag Automation', event: 'tag_automation' }
];

const Newsletter: EventItem[] = [
	{ name: 'Newsletter Creation', event: 'newsletter_creation' },
	{ name: 'Newsletter Deletion', event: 'newsletter_deletion' },
	{ name: 'Newsletter Update', event: 'newsletter_update' },
	{ name: 'Newsletter Unsubscribe', event: 'newsletter_unsubscribe' },
	{ name: 'Newsletter Resubscribe', event: 'newsletter_resubscribe' },
	{ name: "Newsletter Queue", event: 'newsletter_queue'},
	{ name: "Newsletter Generate", event: 'newsletter_generate'},
	{ name: "Newsletter Send", event: 'newsletter_send'},
	{ name: 'Newsletter Open', event: 'newsletter_email_open' },
	{ name: 'Newsletter Delivery', event: 'newsletter_email_delivery' },
	{ name: 'Newsletter Click', event: 'newsletter_email_click' },
	{ name: 'Newsletter Failure', event: 'newsletter_email_failure' }
];

const Feeds_Results: EventItem[] = [
	{ name: 'Manual Insertion', event: 'feedsresult_manual_insertion' },
	{ name: 'Placement Deletion', event: 'feedsresult_placement_deletion' },
	{ name: 'Tagging / Categorising', event: 'feedsresult_tagging_categorizing' },
	{ name: 'Tag Deletion', event: 'feedsresult_tag_deletion' },
	{ name: 'Category Deletion', event: 'feedsresult_category_deletion' }
];

export const allEvents = {
	Focus,
	Feed,
	Topic,
	Tag,
	Newsletter,
	Feeds_Results
};
