import { all } from 'redux-saga/effects';

import managerSagas from './manager/sagas';
import detailsSagas from './details/sagas';

export default function* sagas() {
	yield all([
		managerSagas(),
		detailsSagas()
	]);
}
