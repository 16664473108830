import { withT } from "lib/i18n";
import React from "react";
import { ComponentProps } from "./types";
import { getSmallTypeIconSVG } from 'lib/svg/report';
import Dialog from 'components/common/dialog';

import './ReportConfirmationDialog.scss';

const ConfirmationDialog: React.FC<ComponentProps> = props => {
	const { enabled, type, user, textKeys, focuses } = props;
	const { t, onCancel } = props;

	if (!enabled) return null;

	const ReportIconSVG = getSmallTypeIconSVG(type);
	return (
		<Dialog
			id="reportConfirmationDialog"
			icon={<ReportIconSVG />}
			title={t(`reports.${type}_report`)}
			content={(
				<div className="report-confirmation-body">
					<div className="report-confirmation-description description-line-1">
						{t(textKeys.title, { context: type })}
					</div>
					<div className="report-confirmation-description description-line-2">
						{t(textKeys.content)}
					</div>
					{focuses && focuses.length > 0 && 
						<div className="report-confirmation-description">
							{t('reports.confirmation_focus_list')}
							{focuses.map(focus => <div key={focus}>{focus}</div>)}
						</div>
					}
					{textKeys.footer &&
						<div className="report-confirmation-description description-line-3">
							<div>{t(textKeys.footer, { type })}</div>
							<div>{user.email}</div>
						</div>
					}
				</div>
			)}
			onCancel={onCancel}
		/>
	);
};

export default withT(React.memo(ConfirmationDialog));