import { Document, DocumentObject } from 'class/Document';
import { FacebookDocument } from './FacebookDocument';
import { GalleryDocument } from './GalleryDocument';
import { InstagramDocument } from './InstagramDocument';
import { OnlineDocument } from './OnlineDocument';
import { PrintDocument } from './PrintDocument';
import { TwitterDocument } from './TwitterDocument';
import { YoutubeDocument } from './YoutubeDocument';
import { TiktokDocument } from './TiktokDocument';
import { BilibiliDocument } from './BilibiliDocument';
import { DouyinDocument } from './DouyinDocument';
import { WechatDocument } from './WechatDocument';
import { WeiboDocument } from './WeiboDocument';
import { RedDocument } from './RedDocument';

const PROVIDERS = {
	'News': OnlineDocument,
	'Print': PrintDocument,
	'DMR': PrintDocument,
	'Twitter': TwitterDocument,
	'Gallery': GalleryDocument,
	'YouTube': YoutubeDocument,
	'Instagram': InstagramDocument,
	'Facebook': FacebookDocument,
	'TikTok': TiktokDocument,
	'Bilibili': BilibiliDocument,
	'Douyin': DouyinDocument,
	'WechatPublic': WechatDocument,
	'Weibo': WeiboDocument,
	'Red': RedDocument
} as const;

export default function documentFactory (document: DocumentObject): Document {
	if (!(document.provider in PROVIDERS)) {
		console.error('Unknown document provider, fallback to online. Document:', document);
		document.provider = 'News';
	}
	return new PROVIDERS[document.provider](document);
};
