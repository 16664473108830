import { createSelector } from "reselect";

import { State as RootState } from 'store/types';
import { Resource } from "./reducers";

const rootSelector = (state: RootState) => state.fletcher;

export default {
	isAuthenticated: createSelector(rootSelector, state => state.auth === true),
	isNewslettersFetched: createSelector(rootSelector, state => state.newsletters === true),
	isFocusFetched: createSelector(rootSelector, state => state.focus === true),
	isTopicsFetched: createSelector(rootSelector, state => state.topics === true),
	isFetched: createSelector(rootSelector, (state: RootState, resource: Resource) => resource,
		(state, resource) => state[resource] === true
	),
	needsFetch: createSelector(rootSelector, (state: RootState, resource: Resource) => resource,
		(state, resource) => state[resource] === null
	)
};
