import { createSelector } from 'reselect';
import { sessionSelector } from "store/entities/selectors";

export const getAllBrands = createSelector(sessionSelector, (_: any) => _,
	({Brand}): {id: string, name: string, isCrawled: boolean}[] => {
		return Brand.all().toModelArray().map(brand => ({id: brand.id, name: brand.name, isCrawled: brand.isCrawled}));
	}
);

export const getBrandName = createSelector(sessionSelector, (_: any, id: string) => id,
	({Brand}, id): string => {
		const brand = Brand.withId(id);
		return brand ? brand.name : '';
	}
);

export const getIsCrawled = createSelector(sessionSelector, (_: any, id: string) => id,
	({Brand}, id): boolean => {
		const brand = Brand.withId(id);
		return brand!.isCrawled;
	}
);
