import { all, call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { replace } from "connected-react-router";
import queryString from "query-string";
import moment from 'moment';
import { cloneDeep, concat, filter, find, get, has, isEmpty, keyBy, map, remove, some, transform, omit } from 'lib/imports/lodash';

import Api from 'lib/ajax/Api';
import Mekong from 'lib/ajax/Mekong';
import GA from 'lib/googleAnalytics';
import { isCurrentPage } from 'lib/pathCheck';
import { getArticleSearchParams, getPreviewSearchParams, getArticleSearchFacetsParams, getPreviewSearchFacetsParams, getPreviewDefinitionParams, getSearchFiltersParams } from 'lib/searchParams';
import { State } from 'store/types';
import { SearchParams, ArticleSearchParams, SearchPeriod, PreviewFacetSearchParams } from 'types/search/form';
import { FiltersFacetGroup } from 'class/Filter';
import { FeedType, DefinitionExpression, Definition } from 'class/Feed';
import { APIFacetItemsGroup, Facet, FacetItemArray, FacetObject } from 'class/Facet';
import { ApiSearchDocument, DocumentObject, ApiPreviewSearchDocument, ApiArticleSearchDocument } from 'class/Document';
import { State as UrlQueryState } from 'store/ui/urlQuery/reducers';
import { State as FormState } from 'store/search/form/reducers';
import { State as FiltersState } from 'store/search/filters/reducers';
import { State as HorizontalFiltersState } from 'store/search/horizontalFilters/reducers';
import { State as FocusFeedState } from 'store/focus/feed/reducers';
import { operators as feedOperators } from 'store/focus/feed';
import { operators as facetsOperators } from 'store/search/facets';
import { operators as filterOperators } from 'store/search/filters';
import { operators as searchOperators } from 'store/search/actions';
import { operators as notificationOperators } from 'store/app/notifications';
import { operators as TagOperators } from 'store/entities/Tag/actions';
import { operators as tagAutomationOperators } from 'store/entities/TagAutomation';
import { operators as FormOperators } from 'store/search/form/actions';
import { DocParams } from './reducers';
import { selectors } from './selectors';
import { operators, Actions } from './actions';
import { getAllTagsAndTagAutomation } from 'store/entities/Tag/selectors';

const LIMIT = 5000;
const START = 0;

type UrlSearchQueryParams = {
	query?: string,
	sort: string,
	period: SearchPeriod,
	begin_date?: string,
	end_date?: string,
	limit: number,
	start: number,
	facetFilters?: string,
	focusFilters?: string,
	feedFilters?: string,
	suggested_tags?: string,
	excluded_fields?: string
};

export default function* sagas() {
	yield all([
		takeLatest(operators.fetchSearch.type, fetchSearch),
		takeEvery(operators.removeDocument.type, removeDocument),
		takeLatest(operators.removeDocumentBulk.type, removeDocumentBulk),
		takeLatest(operators.setTags.type, setTagsAction),
		takeEvery(operators.removeDocumentCategory.type, removeDocumentCategory),
		takeEvery(operators.removeDocumentTag.type, removeDocumentTag),
		takeEvery(operators.automateTags.type, automateTags),
		takeLatest(operators.bulkTag.type, bulkTag)
	]);
}

function* bulkTag({ payload: { tagIds, newTagNames } }: Actions["BulkTag"]) {
	try {
		const params: SearchParams = yield getSearchParams();
		const selectedAndNewTagIds: string[] = yield createNewTags(tagIds, newTagNames);

		const body = {
			querySearchParams: { ...params, start: START, limit: LIMIT },
			tagIds: selectedAndNewTagIds
		};

		yield call(Mekong.post, "/v1/document/tagByQuery", { data: body });
		yield put(operators.bulkTagSuccess());
	} catch (error) {
		yield put(operators.bulkTagError({ error }));
	}
}

function* fetchSearch({ payload }: Actions["FetchSearch"]) {
	try {
		yield all([
			call(fetchSearchDocuments),
			call(fetchSearchFacets)
		]);
	} catch (error) {
		yield put(searchOperators.setFetchSearchError({ error }));
	}
}

function* fetchSearchDocuments() {
	const pathname: string = yield select((state: State) => state.router.location.pathname);
	const feedType: FeedType = yield select((state: State) => state.focus.feed.feedType);
	const brand_sync: boolean = yield select((state: State) => state.focus.feed.brand_sync);

	const params: SearchParams = yield getSearchParams();
	const isArticlePage = isCurrentPage(pathname, "article");

	const start = GA.startTimer();
	let searchResult: { documents: ApiSearchDocument[], total: number, took: number };
	if (isArticlePage) {
		searchResult = yield call([Mekong, 'get'], '/v1/document/search', { params: { ...omit(params, 'insightsFilters'), response_type: 'source' } });
	} else if (feedType === 'print_dmr') {
		if (!hasErrorExpression(params)) {
			const results: { documents: DocumentObject[], total: number, took: number } = yield call(
				[Mekong, 'post'], `/v1/definition/focus/feed/preview/search`, { data: { ...omit(params, 'insightsFilters') } }
			);
			yield put(operators.fetchSearchSuccess({ documents: results.documents.map(doc => doc.id), total: results.total }));

			yield put(operators.fetchDocumentsSuccess({ documents: results.documents }));
			yield put(operators.setDocuments({ documentSources: results.documents }));
		} else {
			yield put(notificationOperators.add({ notification: { t: 'error.query_syntax', level: 'warning' } }));
		}
		GA.endTimer(start, isArticlePage ? 'document_search_ids' : 'feed_preview_ids', 'load');
		return;
	} else {
		if (brand_sync) {
			yield put(operators.fetchSearchSuccess({ documents: [], total: 0 }));
			return;
		}
		if (feedType === 'print') return;
		if (!hasErrorExpression(params)) {
			searchResult = yield call([Mekong, 'post'], `/v1/definition/focus/feed/preview/search`, { data: { ...omit(params, 'insightsFilters') } });
		} else {
			searchResult = { documents: [], total: 0, took: 0 };
			yield put(notificationOperators.add({ notification: { t: 'error.query_syntax', level: 'warning' } }));
		}
	}
	GA.endTimer(start, isArticlePage ? 'document_search_ids' : 'feed_preview_ids', 'load');

	const { documents: apiSearchDocuments, total, took } = searchResult;
	yield put(operators.fetchSearchSuccess({ documents: apiSearchDocuments, total }));
	yield put(feedOperators.setComplexityInfo({ took, total }));

	if (!apiSearchDocuments.length) {
		yield put(operators.fetchDocumentsSuccess({ documents: [] }));
		yield put(operators.setDocuments({ documentSources: [] }));
	} else {
		yield put(operators.fetchDocuments());
		const docsStart = GA.startTimer();
		if (isArticlePage) yield fetchArticleDocuments(apiSearchDocuments as ApiArticleSearchDocument[], params as ArticleSearchParams);
		else yield fetchPreviewDocuments(apiSearchDocuments as ApiPreviewSearchDocument[]);
		GA.endTimer(docsStart, isArticlePage ? 'document_search_data' : 'feed_preview_data', 'load');
	}

	yield put(FormOperators.getInsightsFilters());
	GA.endTimer(start, isArticlePage ? 'document_search' : 'feed_preview', 'load');
}

function* fetchArticleDocuments(apiSearchDocuments: ApiArticleSearchDocument[], params: ArticleSearchParams) {
	const filteredFeeds: string[] = yield select((state: State) => state.search.filters.feeds);
	const documentsParam: DocParams[] = apiSearchDocuments.map(apiSearchDocument => {
		const docParam: DocParams = { id: apiSearchDocument.id };
		if (!params.query) docParam.highlight = apiSearchDocument.source.queries.join(' ');
		return docParam;
	});
	const data: { documents: DocParams[], highlight?: string, filteredFeeds: string[] } = { documents: documentsParam, filteredFeeds };
	if (params.query) data.highlight = params.query;
	const apiDocuments: DocumentObject[] = yield fetchDocumentsAPI(data);
	yield putDocumentsSources(apiSearchDocuments, apiDocuments);
}

function* fetchPreviewDocuments(apiSearchDocuments: ApiPreviewSearchDocument[]) {
	const documentsParam: DocParams[] = apiSearchDocuments.map(apiSearchDocument => ({ id: apiSearchDocument }));
	const data: { documents: DocParams[], highlight?: string } = { documents: documentsParam };
	const highlights = yield getHighlightFromDefinition();
	if (!isEmpty(highlights)) data.highlight = highlights.join(' ');
	const apiDocuments: DocumentObject[] = yield fetchDocumentsAPI(data);
	yield putDocumentsSources(map(apiSearchDocuments, id => ({ id })), apiDocuments);
}

function* getHighlightFromDefinition() {
	const focusFeedState: FocusFeedState = yield select((state: State) => state.focus.feed);
	const definitionParams: Definition = getPreviewDefinitionParams(focusFeedState)!;
	let highlights = [];

	if (get(definitionParams, 'main.q') && get(definitionParams, "main.enabled")) highlights.push(get(definitionParams, 'main.q'));
	const include_expressions: DefinitionExpression[] = get(definitionParams, 'include_expressions', []);
	return concat(highlights, map(filter(include_expressions, 'enabled'), 'q'));
}

function* putDocumentsSources(apiSearchDocumentsIds: object[], apiDocuments: DocumentObject[]) {
	const apiDocumentsById: { [id: string]: DocumentObject } = keyBy(apiDocuments, 'id');
	const documentSources: DocumentObject[] = transform(apiSearchDocumentsIds, (docs: DocumentObject[], searchDocument: any) => {
		const documentData = apiDocumentsById[searchDocument.id];
		if (documentData) docs.push(DocumentObject.getMergedDocument(documentData, searchDocument as ApiArticleSearchDocument));
		else console.error(`Document ${searchDocument.id} not found on Aurora`); // TODO: Alert, document (searchDocument.id) not returned by documents endpoint
	}, []);
	yield put(operators.setDocuments({ documentSources }));
}

function* fetchDocumentsAPI(data: { documents: DocParams[], highlight?: string, filteredFeeds?: string[] }) {
	const formattedDocuments: DocumentObject[] = yield call(
		Mekong.post, '/v1/document/format',
		{ data: { formatter: 'discover', documents: data.documents, highlight: data.highlight, feeds: data.filteredFeeds } }
	);
	yield put(operators.fetchDocumentsSuccess({ documents: formattedDocuments }));
	return formattedDocuments;
}

function* fetchSearchFacets() {
	const pathname: string = yield select((state: State) => state.router.location.pathname);
	const feedType: FeedType = yield select((state: State) => state.focus.feed.feedType);
	const brand_sync: boolean = yield select((state: State) => state.focus.feed.brand_sync);

	const isArticlePage = isCurrentPage(pathname, "article");
	if (!isArticlePage && brand_sync) {
		yield put(facetsOperators.setAPIFacets({ facetsAPI: {} }));
		return;
	};
	if (!isArticlePage && feedType === 'print_dmr') {
		const params: PreviewFacetSearchParams = yield getFacetParams();
		if (!hasErrorExpression(params)) {
			const { facets: facetsAPI } = yield call(Mekong.post, "/v1/definition/focus/feed/preview/facets", { data: params });
			yield put(facetsOperators.fetchAPIFacets());
			yield put(facetsOperators.setAPIFacets({ facetsAPI }));
		}
		return;
	}

	yield put(facetsOperators.fetchAPIFacets());
	const params = yield getFacetParams();

	let facetsResponse: { facets: APIFacetItemsGroup };
	const start = GA.startTimer();
	if (isArticlePage || feedType === 'print') return;
	else facetsResponse = yield call([Mekong, 'post'], "/v1/definition/focus/feed/preview/facets", { data: params });
	GA.endTimer(start, isCurrentPage(pathname, "article") ? 'document_search_facets' : 'feed_preview_facets', 'load');

	const { facets: facetsAPI } = facetsResponse;
	yield put(facetsOperators.setAPIFacets({ facetsAPI }));
}

function* getSearchParams() {
	const pathname = yield select((state: State) => state.router.location.pathname);
	const timezone: string = yield select((state: State) => state.app.profile.user!.settings.timezone);
	const urlQuery: UrlQueryState = yield select((state: State) => state.ui.urlQuery);
	const searchFilters: FiltersState = yield select((state: State) => state.search.filters);
	let searchForm: FormState = yield select((state: State) => state.search.form);
	let excludedFields: string[] = yield select((state: State) => state.search.horizontalFilters.excludedFields);

	let params: SearchParams;
	if (isCurrentPage(pathname, "article")) {
		const horizontalFilters: HorizontalFiltersState = yield select((state: State) => state.search.horizontalFilters);
		const finalSearchForm = {
			...searchForm,
			period: 'custom',
			begin_date: horizontalFilters.begin_date,
			end_date: horizontalFilters.end_date
		} as FormState;
		yield updateUrlQuery(finalSearchForm, urlQuery, searchFilters, excludedFields, timezone);
		params = getArticleSearchParams(finalSearchForm, searchFilters, excludedFields);
	} else {
		const focusFeedState: FocusFeedState = yield select((state: State) => state.focus.feed);
		const feedType: FeedType = focusFeedState.feedType!;
		params = getPreviewSearchParams(focusFeedState, searchForm, searchFilters, feedType);
	}

	return params;
}

function* getFacetParams() {
	const pathname = yield select((state: State) => state.router.location.pathname);
	const searchForm: FormState = yield select((state: State) => state.search.form);
	let searchFilters: FiltersState = yield select((state: State) => state.search.filters);
	searchFilters = cloneDeep(searchFilters);

	let params: SearchParams;
	if (isCurrentPage(pathname, "article"))
		params = getArticleSearchFacetsParams(searchForm, searchFilters);
	else {
		const focusFeedState: FocusFeedState = yield select((state: State) => state.focus.feed);
		const feedType: FeedType = focusFeedState.feedType!;

		params = getPreviewSearchFacetsParams(focusFeedState, searchForm, searchFilters, feedType);
	}
	return params;
}

function hasErrorExpression(params: any) {
	if (params.definition.main && params.definition.main.error) return params.definition.main.error;

	let hasExprError;
	params.definition.include_expressions.forEach((exp: any) => {
		if (exp.error) {
			hasExprError = exp.error;
			return;
		}
	});
	params.definition.exclude_expressions.forEach((exp: any) => {
		if (exp.error) {
			hasExprError = exp.error;
			return;
		}
	});
	return hasExprError;
}

function* updateUrlQuery(searchForm: FormState, urlQuery: UrlQueryState, searchFilters: FiltersState, excludedFields: string[], timezone: string) {
	let cleanFacetsGroups: any = {};
	Object.keys(searchFilters.facetsGroups).forEach(facet => {
		const facetGroup = searchFilters.facetsGroups[facet as keyof typeof searchFilters.facetsGroups];
		if (facetGroup[0].key !== 'all') {
			cleanFacetsGroups[facet] = facetGroup;
		}
	});

	const newUrlQuery: UrlSearchQueryParams = {
		...searchForm,
		begin_date: searchForm.begin_date ? moment(searchForm.begin_date).format("YYYYMMDD") : undefined,
		end_date: searchForm.end_date ? moment(searchForm.end_date).format("YYYYMMDD") : undefined,
		facetFilters: Object.keys(cleanFacetsGroups).length > 0 ? JSON.stringify(cleanFacetsGroups) : undefined,
		focusFilters: searchFilters.focus.length > 0 ? searchFilters.focus.join() : undefined,
		feedFilters: searchFilters.feeds.length > 0 ? searchFilters.feeds.filter(feed => feed !== 'all').join() : undefined,
		suggested_tags: urlQuery.suggested_tags ? urlQuery.suggested_tags.join() : undefined,
		excluded_fields: excludedFields.length > 0 ? excludedFields.join() : undefined
	};

	yield put(replace({ search: queryString.stringify(newUrlQuery) }));
}

const removeDocumentsQueue: { [id: string]: true } = {};
function* removeDocument({ payload: { id } }: Actions["RemoveDocument"]) {
	const api = new Api();

	try {
		removeDocumentsQueue[id] = true;
		yield call([api, 'delete'], "/documents", { data: JSON.stringify({ ids: id }) });
		yield put(operators.removeDocumentSuccess({ id }));
		delete removeDocumentsQueue[id];
		if (isEmpty(removeDocumentsQueue)) yield put(operators.fetchSearch());
	} catch (error) {
		yield put(operators.removeDocumentError({ error, id }));
	}
}

function* removeDocumentBulk() {
	const api = new Api();
	const ids = selectors.getSelectedIds(yield select());
	try {
		// Quotes are needed so api doesn't throw an error (for some reason ids in the request are not parsed as strings)
		yield call([api, 'delete'], "/documents", { data: JSON.stringify({ ids: ids.join(',') }) });
		yield put(operators.removeDocumentBulkSuccess());
		yield put(operators.fetchSearch());
	} catch (error) {
		yield put(operators.removeDocumentBulkError({ error }));
	}
}

function* setTagsAction({ payload: { tagIds, newTagNames } }: Actions["SetTags"]) {
	const ids = selectors.getSelectedIds(yield select());
	try {
		const selectedAndNewTagIds: string[] = yield createNewTags(tagIds, newTagNames);
		const tagResponse = yield setTags(selectedAndNewTagIds, ids);
		let topicTag: string[] = [];
		if (tagResponse) topicTag = tagResponse.tags;
		yield put(operators.setTagsSuccess({ ids, topicTag }));
	} catch (error) {
		yield put(operators.setTagsError({ error }));
	}
}

function* automateTags({ payload: { tagIds, newTagNames } }: Actions["AutomateTags"]) {
	const searchFilters: FiltersState = yield select((state: State) => state.search.filters);
	const searchForm: FormState = yield select((state: State) => state.search.form);
	const searchFilterParams = getSearchFiltersParams(searchFilters);
	const excludedFields: string[] = yield select((state: State) => state.search.horizontalFilters.excludedFields);

	try {
		const selectedAndNewTagIds: string[] = yield createNewTags(tagIds, newTagNames);
		const definition = {
			query: searchForm.query,
			filters: { ...searchFilterParams },
			excluded_fields: excludedFields.length > 0 ? excludedFields.join() : undefined
		};

		if (isEmpty(definition.filters) && isEmpty(definition.query)) {
			yield put(notificationOperators.add({ notification: { t: 'results.bulk_action.tag.empty_definition', level: 'warning' } }));
			return;
		}

		const state: State = yield select((state: State) => state);
		if (tagIds && !isEmpty(tagIds) && _tagHasAutomation(state, tagIds)) {
			yield put(notificationOperators.add({ notification: { t: 'results.bulk_action.remove.confirm.override_automated_tag', level: 'info' } }));
		}

		const storeTags = getAllTagsAndTagAutomation(state);
		for (const topicTag of selectedAndNewTagIds) {
			const [topicId, tagId] = topicTag.split('_');
			const response = yield call(Mekong.post, `/v1/tag/${tagId}/automation`, { data: { definition, topic_id: topicId } });
			if (storeTags[tagId] && isEmpty(storeTags[tagId].tag_automation)) yield put(tagAutomationOperators.create({ tag: tagId, ...response }));
			else yield put(tagAutomationOperators.update({ tag: tagId, ...response }));
		}

		yield put(operators.toggleAutomateTags(false));
		yield put(notificationOperators.add({ notification: { t: 'results.bulk_action.remove.confirm.automate_tag_applied', level: 'info' } }));
	} catch (error) {
		yield put(operators.automateTagsError({ error }));
	}
}

function* createNewTags(tagIds: string[], newTagNames: string[]) {
	let selectedAndNewTagIds: string[] = tagIds;
	if (!isEmpty(newTagNames)) {
		const tagCreationResponse = yield call(Mekong.post, "/v1/tag/multiple", { data: { tags: newTagNames } });
		if (tagCreationResponse) {
			yield addNewTagsToORM(tagCreationResponse);
			const newTagIdsToInsert: string[] = tagCreationResponse.map((newTag: any) => (`${newTag.topic_id}_${newTag.id}`));
			selectedAndNewTagIds = tagIds.concat(newTagIdsToInsert);
		}
	}
	return selectedAndNewTagIds;
}

function* addNewTagsToORM(tagCreationResponse: any) {
	let newCreatedTags: any[] = tagCreationResponse.map((newTag: any) => ( // FIXME GIS-4405 types -> newCreatedTags: TagData[]
		{
			id: newTag.id,
			topic: newTag.topic_id,
			name: newTag.name,
			read_only: 0
		}
	));
	yield put(TagOperators.create(newCreatedTags));
}

function* setTags(tagIds: string[], selectedIds: string[]) {
	if (isEmpty(tagIds)) return;

	const data = {
		documentIds: selectedIds,
		tagIds
	};
	return yield call(Mekong.post, "/v1/document/tag", { data });
}

function* removeDocumentCategory({ payload: { id, category } }: Actions["RemoveDocumentCategory"]) {
	const api = new Api();
	try {
		const { deleted } = yield call([api, 'delete'], "/documents/categories", { params: { ids: id, category } });
		if (deleted === 0) return;

		yield _decreaseFacetCounter(Facet.categoriesGroupKeyVB, category);
		yield put(operators.removeDocumentCategorySuccess({ id }));
	} catch (error) {
		yield put(operators.removeDocumentCategoryError({ error }));
	}
}

function* removeDocumentTag({ payload: { id, tagId } }: Actions["RemoveDocumentTag"]) {
	try {
		const { deleted } = yield call(Mekong.delete, `/v1/document/${id}/tag/${tagId}`);
		if (deleted === 0) return;

		yield _decreaseFacetCounter(Facet.tagsGroupKey, tagId);
		yield put(operators.removeDocumentTagSuccess({ id, tagId }));
	} catch (error) {
		yield put(operators.removeDocumentTagError({ error }));
	}
}

function* _decreaseFacetCounter(groupKey: string, facetKey: string) {
	let facets: FacetObject | null = yield select((state: State) => state.search.facets.facets);
	if (!facets) return;

	facets = cloneDeep(facets);

	const facetGroup: FacetItemArray = facets.groups[groupKey];
	const facet = find(facetGroup, { key: facetKey });
	if (!(facet && facet.counter)) return;

	facet.counter--;
	if (facet.counter === 0) remove(facetGroup, facet);
	yield put(facetsOperators.setFacets({ facets }));

	if (facet.counter !== 0) return;

	let filters: FiltersFacetGroup = yield select((state: State) => state.search.filters.facetsGroups);
	if (!has(filters, groupKey)) return;

	filters = cloneDeep(filters);
	if (!isEmpty(remove(filters[groupKey], { key: facetKey }))) {
		yield put(filterOperators.setFacetGroupFilters({ groupKey, groupFacetsFiltered: filters[groupKey] }));
	}
}

function _tagHasAutomation(state: State, tagIds: string[]) {
	const storeTags = getAllTagsAndTagAutomation(state);
	if (!storeTags || isEmpty(storeTags)) return false;

	return some(tagIds, function (topicTag: string) {
		const tagId = topicTag.split('_')[1];
		return !isEmpty(storeTags[tagId].tag_automation);
	});
}
