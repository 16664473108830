import { actionCreatorFactory, ActionCreator, ActionCreatorFactory, Action } from 'typescript-fsa';
import { AnyModel, CreateProps, UpsertProps, IdType } from 'redux-orm/Model';

export type DefaultPayloadError = { code: string, message: string };

type ApiOperators<Payload, PayloadSuccess, PayloadError> = [
	ActionCreator<Payload>,
	ActionCreator<PayloadSuccess>,
	ActionCreator<PayloadError>
];

type CrudOperators<M extends AnyModel> = {
	create: ActionCreator<CreateProps<M> | CreateProps<M>[]>
	replace: ActionCreator<UpsertProps<M> | UpsertProps<M>[]>
	update: ActionCreator<UpsertProps<M>>
	delete: ActionCreator<IdType<M>>
};

export type CrudActions<M extends AnyModel> = {
	create: Action<CreateProps<M>>
	replace: Action<UpsertProps<M>>
	update: Action<UpsertProps<M>>
	delete: Action<IdType<M>>
}

type ApiOperatorOptions = {
	reload?: boolean
};

export default class {

	private __factory: ActionCreatorFactory;

	constructor(prefix: string) {
		this.__factory = actionCreatorFactory(prefix);
	}

	public operator<Payload = void>(type: string): ActionCreator<Payload> {
		return this.__factory<Payload>(type);
	}

	public apiOperators<Payload = void, PayloadSuccess = void, PayloadError = DefaultPayloadError>(type: string, options?: ApiOperatorOptions): ApiOperators<Payload, PayloadSuccess, PayloadError> {
		return [
			this.__factory<Payload>(type, { api: 'start' }),
			this.__factory<PayloadSuccess>(type + '_SUCCESS', { api: 'success' }),
			this.__factory<PayloadError>(type + '_ERROR', { api: 'error', ...options })
		];
	}

	public crudOperators<M extends AnyModel>(): CrudOperators<M> {
		return {
			create: this.__factory<CreateProps<M> | CreateProps<M>[]>('CREATE'),
			replace: this.__factory<UpsertProps<M> | UpsertProps<M>[]>('REPLACE'),
			update: this.__factory<UpsertProps<M>>('UPDATE'),
			delete: this.__factory<IdType<M>>('DELETE')
		};
	}
};
