import sortBy from 'lodash/sortBy';

import i18n from './i18n';
import { Facet } from 'class/Facet';

export const getAvailableLanguages = () => {
	const translationKey = Facet.languageGroupKeyVB.replace(/\.(.*)/gi, '');
	return sortBy(Facet.languageFacetCodes.map((facetLanguage: string) => {
		return { id: facetLanguage, name: i18n.t(`filters.${translationKey}.${facetLanguage}`) };
	}), 'name');
};

export const getAvailableCountries = () => {
	const translationKey = Facet.countryGroupKey.replace(/_(.*)/gi, '');
	return sortBy(Facet.countryFacetCodes.map((facetCountry: string) => {
		return { id: facetCountry, name: i18n.t(`filters.${translationKey}.${facetCountry}`) };
	}), 'name');
};

export const parseNumericInput = (input: string): number | '' => {
	let parsedInput: number | '';
	if (input) {
		parsedInput = +input;
		if (parsedInput < 0) parsedInput = 0;
	} else parsedInput = '';
	return parsedInput;
};

export default { getAvailableLanguages, getAvailableCountries, parseNumericInput };
