import {operators} from './actions';
import { all, put, takeLatest } from "redux-saga/effects";
import { operators as auditListOperators } from "store/ui/audit/list";

export default function* sagas() {
	yield all([
		takeLatest(operators.auditNextPage, auditNextPage)
	]);
}

function* auditNextPage() {
	yield put(auditListOperators.fetchNextPage());
}