import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import Api from 'lib/ajax/Api';
import selectors from 'store/ui/newsletter/draft/edit/selectors';

import { Actions, operators } from './actions';
import { NewsletterDraftChannels, NewsletterDraftChannel, NewsletterDraftSection } from './types';

export default function* sagas() {
	yield all([
		takeLatest(operators.fetchContent, fetchContent),
		takeLatest(operators.updateSection, updateSection),
		takeLatest(operators.updateCustomImageSection, updateCustomImageSection)
	]);
}

type DraftReponse = { channels: NewsletterDraftChannels };
function* fetchContent({ payload: { id, timestamp } }: Actions["FetchContent"]) {
	const draftEndpoint = `/newsletter/${id}/draft/${timestamp}`;
	try {
		const api = new Api();
		const [{ channels }, previewHtml]: [DraftReponse, string] = yield all([
			call([api, 'get'], draftEndpoint),
			call([api, 'get'], draftEndpoint + '/preview')
		]);
		yield put(operators.fetchContentSuccess({ channels, previewHtml }));

	} catch (err) {
		if (err.code === 'OBJECT_NOT_FOUND') yield put(operators.setStatus('missing'));
		else if (err.code === 'GONE') yield put(operators.setStatus('sent'));
		else if (err.code === 'TENANT_NOT_ALLOWED') yield put(operators.setStatus('unauthorized'));
		else yield put(operators.fetchContentError(err));
	}
}

function* updateSection({ payload: { section, sectionChannel } }: Actions["UpdateSection"]) {
	try {
		yield __putSectionChannel(section, sectionChannel);
		const sectionHtml: string = yield __getSectionPreview(section);
		yield put(operators.updateSectionSuccess({ section, sectionHtml, sectionChannel }));
	} catch (err) {
		yield put(operators.updateSectionError(err));
	}
}

function* updateCustomImageSection({ payload: { section, sectionChannel, documentId, imageFile } }: Actions["UpdateCustomImageSection"]) {
	try {
		yield all([
			__postCustomImage(documentId, imageFile),
			__putSectionChannel(section, sectionChannel)
		]);
		const sectionHtml: string = yield __getSectionPreview(section);

		yield put(operators.updateCustomImageSectionSuccess({ section, sectionHtml, sectionChannel }));

	} catch (err) {
		yield put(operators.updateCustomImageSectionError(err));
	}
}

function* __putSectionChannel(section: NewsletterDraftSection, data: NewsletterDraftChannel) {
	const { id, timestamp } = selectors.getIdAndTimestamp(yield select());
	const draftSectionEndpoint = `/newsletter/${id}/draft/${timestamp}/section/${section}`;

	const api = new Api();
	yield call([api, 'put'], draftSectionEndpoint, { data });
}

function* __getSectionPreview(section: NewsletterDraftSection) {
	const { id, timestamp } = selectors.getIdAndTimestamp(yield select());
	const draftSectionEndpoint = `/newsletter/${id}/draft/${timestamp}/section/${section}`;

	const api = new Api();
	const sectionHtml: string = yield call([api, 'get'], draftSectionEndpoint + '/preview');

	return sectionHtml;
}

function* __postCustomImage(documentId: string, imageFile: File) {
	const { id, timestamp } = selectors.getIdAndTimestamp(yield select());
	const data = new FormData();
	data.append('mime', imageFile.type);
	data.append('image_file', imageFile, imageFile.name);
	const headers = { 'Content-Type': 'multipart/form-data' };

	const api = new Api();
	yield call([api, 'post'], `/newsletter/${id}/draft/${timestamp}/image/${documentId}`, { data, headers });
}
