import React from 'react';

import { withT } from 'lib/i18n';

import DefinitionLayout from '../Layout';
import { ComponentProps } from './types';

import './FocusFeedDefinitionOnline.scss';

const FocusFeedDefinitionOnline = (props: ComponentProps) => {
	const { t, definition, mediaItems, errorMedias } = props;
	const { onFetchMedias, onResetMedias, onSetDefinition, onSetExpression, onMassiveFetchMedia, onClearErrorMedias } = props;

	return (
		<div id="focusFeedDefinition">
			<DefinitionLayout
				type="online"
				showFeedLinkedBrands={true}
				showAutocompleteUrlPlaceholder={true}
				contentQueries={{
					onSetDefinition,
					expressionFunctions: { onSetExpression },
					activeScope: ['title', 'content'],  definition,
					scopeMenuItems: [
						{ value: 'title', label: t('feed.form.scope.option.title') },
						{ value: 'content', label: t('feed.form.scope.option.content') },
						{ value: 'title,content', label: t('feed.form.scope.option.title_content') }
					]
				}}
				medias={{
					definition,
					mediaItems,
					displayMassive: true,
					massiveFunctions: {
						errorMedias,
						onClearErrorMedias,
						onMassiveFetchMedia
					},
					onFetchMedias,
					onResetMedias,
					onSetDefinition
				}}
			></DefinitionLayout>
		</div>
	);
};

export default withT(React.memo(FocusFeedDefinitionOnline));
