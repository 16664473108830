import { Dispatch } from 'redux';
import { State } from 'store/types';
import { connect } from 'react-redux';
import { UserObject } from 'class/User';
import { operators } from 'store/ui/audit/filters';
import { StateProps, DispatchProps } from './types';
import { AuditDatePicker } from './AuditDatePicker';

const mapStateToProps = (state: State): StateProps => ({
	user: state.app.profile.user as UserObject
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onDatesChange: (startDate: Date, endDate: Date) => dispatch(operators.filterChanged({
		endDate: endDate,
		startDate: startDate
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditDatePicker);
