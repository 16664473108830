import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { keys } from 'lib/imports/lodash';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import { operators } from 'store/ui/report/setup/';
import selectors from 'store/ui/report/setup/selectors';
import FeedSelectors from 'store/entities/Feed/selectors';

import ReportSetupFeedsSelect from './ReportSetupFeedsSelect';

const mapStateToProps = (state: State): StateProps => ({
	type: selectors.getType(state),
	feeds: selectors.getFeeds(state) ? FeedSelectors.get(state, keys(selectors.getFeeds(state))) : [],
	reportFeeds: selectors.getFeeds(state),
	loaded: selectors.areFeedsLoaded(state),
	linkedBrandId: state.ui.report.setup.linkedBrandId
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onChangeFeedSelected: (feedId, selected) => dispatch(operators.changeFeedSettings({ feedId, settings: { selected } }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSetupFeedsSelect);
