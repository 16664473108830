import { ReducerHandlers } from 'lib/store/reducer';
import { DefinitionPrintVB } from 'class/Feed';
import { State as FocusFeedState } from 'store/focus/feed/reducers';
import { Actions } from './actions';
import set from 'lodash/set';
import filter from 'lodash/filter';
import { BrandApiResponse } from 'types/focus/feed/definition/print/brands';
import { ShopApiResponse } from 'types/focus/feed/definition/print/shops';

export type State = {
	definition: DefinitionPrintVB,
	brandsApiResponse: BrandApiResponse[],
	shopsApiResponse: ShopApiResponse[]
};

export const INITIAL_STATE: State = {
	definition: {
		brands: [
			{
				enabled: true,
				id: '',
				names: [],
				scope: ''
			}
		],
		product_types: [],
		shops: []
	},
	brandsApiResponse: [],
	shopsApiResponse: []
};

export const reducerHandlers: ReducerHandlers<FocusFeedState> = {
	updatePrintExpression: (state, { payload: { type, index, expression } }: Actions["UpdatePrintExpression"]): FocusFeedState => ({
		...state,
		print: {
			...state.print,
			definition: {
				...state.print.definition,
				[type]: [...set(state.print.definition[type], index, { ...expression })]
			}
		}
	}),

	removePrintExpression: (state, { payload: { type, index } }: Actions["RemovePrintExpression"]): FocusFeedState => ({
		...state,
		print: {
			...state.print,
			definition: {
				...state.print.definition,
				[type]: filter(state.print.definition[type], (expr, i: number) => index !== i)
			}
		}
	}),

	addPrintExpression: (state, { payload: { type, expression } }: Actions["AddPrintExpression"]): FocusFeedState => ({
		...state,
		print: {
			...state.print,
			definition: {
				...state.print.definition,
				[type]: [...state.print.definition[type], expression]
			}
		}
	}),

	fetchBrands: (state): FocusFeedState => ({
		...state,
		print: {
			...state.print,
			brandsApiResponse: []
		}
	}),

	fetchBrandsSuccess: (state, { payload: brandsApiResponse }: Actions["FetchBrandsSuccess"]): FocusFeedState => ({
		...state,
		print: {
			...state.print,
			brandsApiResponse
		}
	}),

	fetchBrandsError: (state): FocusFeedState => ({
		...state,
		print: {
			...state.print,
			brandsApiResponse: []
		}
	}),

	resetBrands: (state): FocusFeedState => ({
		...state,
		print: {
			...state.print,
			brandsApiResponse: []
		}
	}),

	fetchShops: (state): FocusFeedState => ({
		...state,
		print: {
			...state.print,
			brandsApiResponse: []
		}
	}),

	fetchShopsSuccess: (state, { payload: shopsApiResponse }: Actions["FetchShopsSuccess"]): FocusFeedState => ({
		...state,
		print: {
			...state.print,
			shopsApiResponse
		}
	}),

	fetchShopsError: (state): FocusFeedState => ({
		...state,
		print: {
			...state.print,
			shopsApiResponse: []
		}
	}),

	resetShops: (state): FocusFeedState => ({
		...state,
		print: {
			...state.print,
			shopsApiResponse: []
		}
	})
};
