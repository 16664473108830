import { createSelector } from 'reselect';
import { sessionSelector } from "store/entities/selectors";

export const getCountryIso = createSelector(sessionSelector, (_: any, id: string) => id,
	({Country}, id): string => {
		const country = Country.withId(id);
		return country ? country.iso_code : 'undefined';
	}
);

export const getAllCountries = createSelector(sessionSelector, (_: any) => _,
	({Country}): {[id: string]: string} => {
		const countryObject: {[id: string]: string} = {};
		Country.all().toModelArray().map(country => countryObject[country.id] = country.name);
		return countryObject;
	}
);
