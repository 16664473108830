import React, { useCallback, useMemo } from 'react';
import { withT } from "lib/i18n";
import { concat, uniqBy } from 'lodash';

import DropdownFilter from "components/common/DropdownFilter/DropdownFilter";
import { FilterFacet } from 'class/Filter';
import { Facet, FacetItem } from 'class/Facet';

import { ComponentProps } from './types';

const Media = (props: ComponentProps) => {
	const { excluded, facetFilters, display, isOpen, queriedMedia, selectedMedia } = props;
	const { t, onSetExcludeField, onSetFacetGroupFilters, onToggleOpen, onMediaInputChange, onResetMediaList } = props;

	const getFacetItems = useCallback(() => {
		const media = uniqBy(concat(queriedMedia, selectedMedia || []), 'key');
		const mediaBySection = Facet.defaultMediaSections
			.map(section => ({
				key: section.id,
				name: t(section.name),
				children: media
					.filter(medium => section.type.includes(medium.type!))
					.map(medium => ({
						key: medium.key,
						name: medium.name,
						type: medium.type
					}))
			}));
		const facetMediaItems = mediaBySection.filter(section => section.children.length);
		return facetMediaItems;
	}, [queriedMedia, selectedMedia, t]);

	const mediaFilters = useMemo(() => (facetFilters && facetFilters[Facet.mediaGroupKey]) || [], [facetFilters]);

	const handleChange = useCallback((items: FacetItem[]) => {
		onSetFacetGroupFilters(items as FilterFacet[]);
	}, [onSetFacetGroupFilters]);

	const handleExclude = useCallback((set: boolean) => onSetExcludeField(set), [onSetExcludeField]);

	return display ?
		<DropdownFilter
			id='mediaFilter'
			key='filters-media-dropdown'
			title={t('filters.media.value')}
			excluded={excluded}
			filters={getFacetItems()}
			initSelectedFilters={mediaFilters as FacetItem[]}
			defaultFilter={{ name: t('reports.all_male_sing'), key: 'all' }}
			disable={false}
			isOpen={isOpen}
			onToggleOpen={() => { onResetMediaList(); onToggleOpen(); }}
			onChange={handleChange}
			onExclude={handleExclude}
			onInputChange={input => onMediaInputChange(input)}
		/>
		: null;
};

export default withT(Media);
