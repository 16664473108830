import React from 'react';
import { withT } from 'lib/i18n';
import { Button } from 'lib/imports/react-md';
import { findIndex, reject, cloneDeep, get, set } from 'lib/imports/lodash';

import { ComponentProps } from './types';

import './NewsletterDraftEditContentLayer.scss';

const __isOnlineImageType = (type: string) => (type === 'online-main' || type === 'online-secondary');
const __isOnlineType = (type: string) => (type === 'online-main' || type === 'online-secondary' || type === 'online-more' || type === 'blogs');

const NewsletterDraftEditContentLayer = (props: ComponentProps) => {
	const { documentId, type, section, text, sectionChannel, imageCount, imageSize, imageOriginalUrl, t } = props;
	const { onUpdateSection, onUpdateCustomImageSection, onDialogImageAction, onDialogTextAction } = props;

	const index = findIndex(sectionChannel.documents, { id: documentId });

	const onDelete = () => {
		const sectionChannelUpdate = cloneDeep(sectionChannel);
		sectionChannelUpdate.documents = reject(sectionChannelUpdate.documents, { id: documentId });
		sectionChannelUpdate.total--;
		onUpdateSection(section, sectionChannelUpdate);
	};

	const onEditText = async () => {
		const sectionChannelUpdate = cloneDeep(sectionChannel);

		const result = await onDialogTextAction(text);
		if (result.action === 'closed') return;

		const document = sectionChannelUpdate.documents[index];

		if (text !== result.text) {
			document.text = result.text;
			onUpdateSection(section, sectionChannelUpdate);
		}
	};

	const onMovePrevious = () => {
		const sectionChannelUpdate = cloneDeep(sectionChannel);
		const previousIndex = index - 1;
		sectionChannelUpdate.documents[previousIndex] = sectionChannel.documents[index];
		sectionChannelUpdate.documents[index] = sectionChannel.documents[previousIndex];
		onUpdateSection(section, sectionChannelUpdate);
	};

	const onMoveNext = () => {
		const sectionChannelUpdate = cloneDeep(sectionChannel);
		const nextIndex = index + 1;
		sectionChannelUpdate.documents[nextIndex] = sectionChannel.documents[index];
		sectionChannelUpdate.documents[index] = sectionChannel.documents[nextIndex];
		onUpdateSection(section, sectionChannelUpdate);
	};

	const onPreviousImage = () => {
		const sectionChannelUpdate = cloneDeep(sectionChannel);
		const document = sectionChannelUpdate.documents[index];
		let imageIndex = get(document, 'image.index', 0);
		imageIndex = imageIndex > 0 ? imageIndex - 1 : imageCount - 1;
		set(document, 'image.index', imageIndex);
		delete document.image!.custom;
		onUpdateSection(section, sectionChannelUpdate);
	};

	const onNextImage = () => {
		const sectionChannelUpdate = cloneDeep(sectionChannel);
		const document = sectionChannelUpdate.documents[index];
		let imageIndex = get(document, 'image.index', 0);
		imageIndex = (imageIndex + 1) % imageCount;
		set(document, 'image.index', imageIndex);
		delete document.image!.custom;
		onUpdateSection(section, sectionChannelUpdate);
	};

	const onOpenDialogAction = async (actionType: 'cropImage' | 'customImage') => {
		const [width, height] = imageSize.split('x').map(n => parseInt(n));

		const result = await onDialogImageAction(actionType, { url: imageOriginalUrl, aspect: (width / height) });
		if (result.action === 'closed') return;

		const sectionChannelUpdate = cloneDeep(sectionChannel);
		const document = sectionChannelUpdate.documents[index];
		if (result.action === 'removeCustomImage') {
			set(document, 'image.custom', false);
			return onUpdateSection(section, sectionChannelUpdate);
		}

		const custom = (actionType === 'customImage');
		const imageIndex = get(document, 'image.index', 0);
		const imageCropIndex = custom ? 'custom' : imageIndex;
		const crop = get(document, 'image.crop', {});

		crop[imageCropIndex] = {
			[imageSize]: {
				start: result.crop!.x + 'x' + result.crop!.y,
				size: result.crop!.width + 'x' + result.crop!.height
			}
		};

		document.image = { index: imageIndex, custom, crop };

		if (actionType === 'customImage' && result.file) {
			onUpdateCustomImageSection(documentId, result.file, section, sectionChannelUpdate);
		} else {
			onUpdateSection(section, sectionChannelUpdate);
		}
	};

	return (
		<div className="newsletter-draft-edit-content-layer">

			<Button className="delete" icon onClick={() => onDelete()} title={t('newsletter.digest.edit.icon.delete')}>clear</Button>

			{__isOnlineType(type) && (
				<Button className="edit-text" title={t('newsletter.digest.edit.icon.edit_text')}
					onClick={() => onEditText()} icon>format_quote</Button>
			)}

			{index > 0 && (
				<Button className="move-previous" title={t('newsletter.digest.edit.icon.move_prev')}
					onClick={() => onMovePrevious()} icon>arrow_back</Button>
			)}

			{index < (sectionChannel.documents.length - 1) && (
				<Button className="move-next" title={t('newsletter.digest.edit.icon.move_next')}
					onClick={() => onMoveNext()} icon >arrow_forward</Button>
			)}

			{imageCount > 0 && (
				<Button className="crop" title={t('newsletter.digest.edit.icon.crop')}
					onClick={() => onOpenDialogAction('cropImage')} icon>crop</Button>
			)}

			{(imageCount === 0 && __isOnlineImageType(type)) && (
				<Button className="upload-image" title={t('newsletter.digest.edit.icon.upload_image')}
					onClick={() => onOpenDialogAction('customImage')} icon>file_upload</Button>
			)}

			{(imageCount > 0 && __isOnlineImageType(type)) && (
				<>
					<Button className="previous-image" title={t('newsletter.digest.edit.icon.img_prev')}
						onClick={() => onPreviousImage()} icon>chevron_left</Button>
					<Button className="next-image" title={t('newsletter.digest.edit.icon.img_next')}
						onClick={() => onNextImage()} icon>chevron_right</Button>
				</>
			)}
		</div>
	);
};

export default withT(NewsletterDraftEditContentLayer);
