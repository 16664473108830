import ActionCreator, { CrudActions } from "lib/store/actions";
import { Language } from "./model";
import { LanguageData } from "./types";

const actionCreator = new ActionCreator('@@orm/language');

const crudOperators = actionCreator.crudOperators<Language>();
const parse = actionCreator.operator<LanguageData | LanguageData []>('PARSE');

export const operators = {
	...crudOperators,
	parse
};

export interface Actions extends CrudActions<Language> {
	Parse: ReturnType<typeof operators.parse>
}
