import { Document, PreparedDocument } from "class/Document";
import format from "lib/format";
import i18n from "lib/i18n";

export class PrintDocument extends Document {
	private _getContent(): string {
		let brand = '';
		if (this.brand_associated) {
			brand = this.provider === "DMR" ? `${i18n.t('doc.company.line')}` : `${i18n.t('doc.company.brand')}`;
			brand += ` <span class="bold">${this.brand_associated}</span> -`;
		}

		const i18nKey = 'filters.categories.' + this.category_id;
		let productText = i18n.t(i18nKey);
		if (productText === i18nKey) productText = this.category || productText;

		const product = `${i18n.t('doc.categorization.sector')} <span class="bold">${productText}</span>`;
		const productName = this.product_name ? `- ${i18n.t('doc.product.name')} <span class="bold">${this.product_name}</span>` : '';

		// eslint-disable-next-line
		const pageIssue = `${i18n.t('results.content.print.page')} ${this.page_number ? this.page_number : '-'}, ${i18n.t('results.content.print.issue')} ${this.issue_number ? this.issue_number : '-'}`;
		let occupation = '';
		if (this.page_occupation) occupation = ', ' + Number(this.page_occupation * 100).toFixed(0) + i18n.t('results.content.print.page_occupation');

		return `${brand} ${product} ${productName}</br> ${pageIssue}${occupation}`;
	}
	public prepare(): PreparedDocument {
		const counters = {
			audience: {
				audience: this.rank.audience || 0
			},
			reach: {
				reach: this.rank.audience || 0
			}
		};
		return new PreparedDocument({
			id: this.id,
			provider: this.provider,
			providerName: 'Print',
			link: this.link,
			icon: "icon-print",
			title: this.content || this.source.name,
			content: this._getContent(),
			author_name: this.source.name,
			date: format.date.doc(this.date_from_provider || this.date),
			dateTitle: format.date.localeTitle(this.date_from_provider || this.date),
			categories_id: this.category || "",
			image: this.image.thumb || '',
			cover: this.cover.thumb || '',
			cover_link: this.cover.url || '',
			country: this.country.iso,
			counters: {
				reach: {
					value: format.number.counter(counters, 'audience'),
					title: format.text.counter(counters, 'audience')
				},
				engagement: {
					value: format.number.counter(counters, 'echo'),
					title: format.text.counter(counters, 'echo')
				},
				echo: {
					value: format.number.currency(this.rank, this.date_from_provider || this.date),
					title: format.number.currency(this.rank, this.date_from_provider || this.date, 'locale', true)
				},
				branded_miv: this.branded_miv ? this.branded_miv.map(brand => ({
					id: brand.id,
					value: format.number.brandedCurrency(brand.value, this.date_from_provider || this.date),
					title: format.number.brandedCurrency(brand.value, this.date_from_provider || this.date, 'locale', true)
				})) : []
			}
		});
	}
}
