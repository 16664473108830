import ActionCreator, { CrudActions } from "lib/store/actions";
import { FacebookUserData } from "store/entities/FacebookUser";
import { Tenant } from "./model";

const actionCreator = new ActionCreator('@@orm/tenant');

const crudOperators = actionCreator.crudOperators<Tenant>();
const addFacebookUser = actionCreator.operator<{ id: string, facebookUserId: string }>('ADD_FACEBOOK_USER');
const removeFacebookUser = actionCreator.operator<{ id: string, facebookUserId: string }>('REMOVE_FACEBOOK_USER');
const setFacebookUsersData = actionCreator.operator<{ id: string, facebookUsersData: FacebookUserData[] }>('SET_FACEBOOK_USERS_DATA');

export const operators = {
	...crudOperators,
	addFacebookUser,
	removeFacebookUser,
	setFacebookUsersData
};

export interface Actions extends CrudActions<Tenant> {
	AddFacebookUser: ReturnType<typeof operators.addFacebookUser>
	RemoveFacebookUser: ReturnType<typeof operators.removeFacebookUser>
	SetFacebookUsersData: ReturnType<typeof operators.setFacebookUsersData>
}
