
export type UrlPage = "article" | "preview";
// Fill this function with the pages that need to be identified
export const getCurrentPage = (path: string): UrlPage | "other" => {
	if (path.match(/^\/article/)) return "article";
	else if (path.match(/^\/focus\/.*\/feed\/.*/)) return "preview";
	return "other";
};

export const isCurrentPage = (path: string, page: UrlPage): boolean => {
	return getCurrentPage(path) === page;
};

export default { getCurrentPage, isCurrentPage };
