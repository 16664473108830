import React from 'react';
import { withT } from 'lib/i18n';
import { CircularProgress, Button, FontIcon } from 'lib/imports/react-md';
import { Link } from 'react-router-dom';
import { date as dateFormatter } from 'lib/format';

import { ComponentProps } from './types';
import NewsletterDraftEditContent from './Content';

import './NewsletterDraftEdit.scss';

const NewsletterDraftEdit = (props: ComponentProps) => {
	const { id, timestamp, newsletter, status, t } = props;
	const { onFetchContent } = props;

	React.useEffect(() => {
		onFetchContent(id, timestamp);
	}, [id, timestamp, onFetchContent]);

	const errorText = React.useMemo(() => {
		if (status === 'missing') return t('newsletter.digest.error.incorrect_tenant');
		if (status === 'sent') return t('newsletter.digest.dispatched.sent_msg');
		if (status === 'unauthorized') return t('newsletter.digest.error.incorrect_tenant');
		return null;
	}, [status, t]);

	const content = React.useMemo(() => {
		if (errorText) return (
			<div className="errorText">
				<div>{errorText}</div>
				<Link to="/newsletter">
					<FontIcon>reply</FontIcon>
					<span>{t('newsletter.digest.dispatched.return_to_list')}</span>
				</Link>
			</div>
		);
		if (status === 'loading') return (<CircularProgress id="loadingDraftChannels" className="loader" />);
		return <NewsletterDraftEditContent />; // status: ready | updating
	}, [status, errorText, t]);

	const sendLink = React.useMemo(() => {
		if (status !== 'ready') return null;
		return (
			<div className="send-newsletter">
				<Link to={`/newsletter/${id}/send/${timestamp}`}>
					<Button flat>{t('newsletter.digest.edit.submit.button')}</Button>
				</Link>
				<div className="submit-text">{t('newsletter.digest.edit.submit.description')}</div>
			</div>
		);
	}, [status, id, timestamp, t]);

	const formattedDate = React.useMemo(() => (
		dateFormatter.formatTimestamp(parseInt(timestamp), 'YYYY/MM/DD HH:mm')
	), [timestamp]);

	return (
		<div id="newsletterDraftEdit" className="discover-entity-list">
			<div className="discover-entity-list-toolbar">
				<div className="discover-entity-list-title">
					<span>{t('newsletter.digest.value')}: </span>
					{newsletter && (<span className="label-name">{newsletter.name}</span>)}
				</div>
			</div>
			<div className="body">
				<div className="description">
					{!errorText && (<>
						<div className="title">
							{t('newsletter.digest.edit.title')} {formattedDate}
						</div>
						<div className="text">
							<div>{t('newsletter.digest.edit.description_1')}</div>
							<div>{t('newsletter.digest.edit.description_2')}</div>
							<div>{t('newsletter.digest.edit.description_3')}</div>
						</div>
						<div className="confirm">
							{sendLink}
						</div>
					</>)}
				</div>

				<div className="content-wrapper">
					{content}
				</div>

			</div>
			<div className="footer">
				{sendLink}
			</div>
		</div>
	);
};

export default React.memo(withT(NewsletterDraftEdit));
