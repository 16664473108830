import { createSelector } from "reselect";
import { map } from 'lib/imports/lodash';

import fletcherSelectors from 'store/fletcher/selectors';
import NewsletterSelectors from 'store/entities/Newsletter/selectors';

export default {
	getNewsletters: createSelector(state => state, fletcherSelectors.isNewslettersFetched, fletcherSelectors.isFocusFetched,
		(state, isNewslettersFetched, isFocusFetched) => {
			if (!isNewslettersFetched || !isFocusFetched) return null;
			return map(NewsletterSelectors.getInstances(state), newsletter => ({
				...newsletter.ref,
				feeds: newsletter.feeds.all().toRefArray()
			}));
		})
};
