import { ReducerHandlers } from 'lib/store/reducer';
import { State as ArticleInsertState } from '../reducers';

import * as online from './online';
import * as print from './print';

export type State = {
	readonly online: online.State,
	readonly print: print.State,
};

export const INITIAL_STATE: State = {
	online: online.INITIAL_STATE,
	print: print.INITIAL_STATE
};

export const reducerHandlers: ReducerHandlers<ArticleInsertState> = {
	...online.reducerHandlers,
	...print.reducerHandlers
};
