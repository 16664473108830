import { withT } from 'lib/i18n';
import Switch from 'react-switch';
import React, { useCallback, useState } from 'react';
import { Button, FontIcon, TextField } from 'lib/imports/react-md';

import { FacetItem } from 'class/Facet';
import { ComponentProps } from './types';
import { Dropdown } from 'components/common/Dropdown';
import DropdownFilterClear from './DropdownFilterClear';
import { CheckStyle } from './DropdownFilterCheckbox/types';
import DropdownFilterCheckbox from './DropdownFilterCheckbox/DropdownFilterCheckbox';

import './DropdownFilter.scss';

const DropdownFilter = (props: ComponentProps) => {
	const { id, excluded, filters, initSelectedFilters, defaultFilter, isOpen, disable, title } = props;
	const { onChange, onExclude, onToggleOpen, onInputChange, t } = props;

	const [exclude, setExclude] = useState(excluded);
	const [selection, setSelection] = useState(initSelectedFilters);
	const [searchInput, setSearchInput] = useState('');
	const [childrenParent, setChildrenParent] = useState<FacetItem | null>(null);

	React.useEffect(() => {
		setSearchInput('');
		setChildrenParent(null);
	}, []);

	React.useEffect(() => {
		if(disable && defaultFilter) setSelection([defaultFilter]);
	}, [defaultFilter, disable]);

	React.useEffect(() => {
		setSelection(initSelectedFilters);
	}, [initSelectedFilters]);

	const onDropdown = useCallback(() => {
		setSearchInput('');
		setChildrenParent(null);
		onToggleOpen();
	}, [onToggleOpen]);

	const countSelectedChildren = useCallback((item: FacetItem) => {
		if (!item.children) return 0;
		let nbSelectedChildren = 0;
		item.children.forEach(child => nbSelectedChildren += selection.filter(s => s.key === child.key).length);
		return nbSelectedChildren;
	}, [selection]);

	const isChecked = useCallback((item: FacetItem) => {
		if (!item.children || !item.children.length) return selection.some(s => s.key === item.key) ? CheckStyle.CHECKED : CheckStyle.UNCHECKED;
		const nbSelectedChildren = countSelectedChildren(item);
		if (nbSelectedChildren === item.children.length) return CheckStyle.CHECKED;
		else if (nbSelectedChildren > 0) return CheckStyle.DASHED;
		else return CheckStyle.UNCHECKED;
	}, [selection, countSelectedChildren]);

	const onChildrenToggle = useCallback((parent: FacetItem) => {
		if (childrenParent && childrenParent.key === parent.key) return setChildrenParent(null);
		setChildrenParent(parent);
	}, [childrenParent]);

	const addToSelection = useCallback((items: FacetItem[]) => {
		let updatedSelection = [...selection];
		for (const item of items) {
			if (isChecked(item)) continue;
			if (defaultFilter && item.key === defaultFilter.key) return [defaultFilter];
			updatedSelection.push(item);
		}
		if (defaultFilter) updatedSelection = updatedSelection.filter(s => s.key !== defaultFilter.key);
		return updatedSelection;
	}, [selection, defaultFilter, isChecked]);

	const removeFromSelection = useCallback((items: FacetItem[]) => {
		let filteredSelection = [...selection];
		items.forEach(item => {
			filteredSelection = filteredSelection.filter(s => s.key !== item.key);
		});
		if (defaultFilter && filteredSelection.length === 0) return [defaultFilter];
		return filteredSelection;
	}, [selection, defaultFilter]);

	const onSelect = useCallback((item: FacetItem) => {
		let newSelection: FacetItem[] = [];
		// Parent clicked
		if (item.children && item.children.length) {
			const nbSelectedChildren = countSelectedChildren(item);
			newSelection = nbSelectedChildren > 0 ? removeFromSelection(item.children) : addToSelection(item.children);
		}
		// Child clicked
		else newSelection = isChecked(item) ? removeFromSelection([item]) : addToSelection([item]);

		setSelection(newSelection);
		onChange(newSelection);
	}, [setSelection, isChecked, addToSelection, removeFromSelection, countSelectedChildren, onChange]);

	const onClear = useCallback(() => {
		const clearedSelection = defaultFilter ? [defaultFilter] : [];
		setSelection(clearedSelection);
		onChange(clearedSelection);
	}, [defaultFilter, setSelection, onChange]);

	const showDefaultFilter = React.useMemo(() => {
		if (!defaultFilter) return false;
		if (!selection.length || (selection.length === 1 && selection[0].key === defaultFilter.key)) return true;
	}, [defaultFilter, selection]);

	const dropdownButton = React.useMemo(() =>
		<Button flat id={`dropdown-button-${id}`} key={`dropdown-button-${id}`} className={`dropdown-button ${isOpen ? 'is-open' : ''}`} onClick={() => onDropdown()} disabled={disable}>
			<label key='dropdown-filter-btn-label' id='dropdown-filter-btn-label'>
				{title} &middot;
				{(defaultFilter && showDefaultFilter) || disable ? (
					<label
						key={'dropdown-filter-default-label'+id}
						id={'dropdown-filter-default-label'+id}
						className='dropdown-filter-second-label'
					>
						{defaultFilter && defaultFilter!.name}
					</label>
				) : (
					<>
						<label
							key='dropdown-filter-length-label'
							id='dropdown-filter-length-label'
							className='dropdown-filter-second-label nb-of-selected-label'
						>
							{selection.length}
						</label>
						<DropdownFilterClear onClick={onClear} />
					</>
				)}
			</label>
		</Button>
	, [id, isOpen, disable, title, defaultFilter, showDefaultFilter, selection.length, onClear, onDropdown]);

	const content = React.useMemo(() => {
		const options = filters.filter(fo => {
			if (!searchInput) return true;
			const lowerSearchInput = searchInput.toLowerCase().split(' ').filter(q => q !== '');
			if (lowerSearchInput.filter(query => query && fo.name!.toLowerCase().search(query) !== -1).length === lowerSearchInput.length) return true;
			for (const child of (fo.children || [])) {
				if (lowerSearchInput.filter(query => query && child.name!.toLowerCase().search(query) !== -1).length === lowerSearchInput.length) return true;
			}
			return false;
		});
		return <>
			{options.map((filter, i) => (
				<div key={`dropdown-filter-options-${i}`} hidden={defaultFilter && filter.key === defaultFilter.key}>
					<DropdownFilterCheckbox
						key={`dropdown-filter-options-${filter.name}-${filter.key}-${i}`}
						optionItem={filter}
						isChecked={isChecked(filter)}
						onClick={onSelect}
						onChildrenToggle={onChildrenToggle}
						isChildrenOpen={childrenParent !== null && childrenParent.key === filter.key}
					/>
				</div>
			))}
		</>;
	}, [defaultFilter, filters, searchInput, childrenParent, isChecked, onSelect, onChildrenToggle]);

	const childrenContent = React.useMemo(() => (
		childrenParent && childrenParent.children &&
		<div className='dropdown-filter-children'>
			<div className='dropdown-filter-body'>
				<div className='dropdown-filter-options'>
					{childrenParent.children
						.filter(fo => {
							const lowerSearchInput = searchInput.toLowerCase().split(' ').filter(q => q !== '');
							return !lowerSearchInput || lowerSearchInput.filter(query => query && fo.name!.toLowerCase().search(query) !== -1).length === lowerSearchInput.length;
						})
						.map((childOption, i) => (
							<div key={`dropdown-filter-options-${i}`} >
								<DropdownFilterCheckbox
									key={`dropdown-filter-options-${childOption!.name}-${childOption.key}-${i}`}
									optionItem={childOption}
									isChecked={isChecked(childOption)}
									onClick={onSelect}
								/>
							</div>
						))
					}
				</div>
			</div>
		</div>
	), [childrenParent, isChecked, onSelect, searchInput]);

	const excludeSwitch = React.useMemo(() => {
		if (!onExclude) return null;
		return <div className='exclude-button'>
			<Switch
				id="dropdownExcludeSwitch"
				height={20} width={40} onColor="#4DF7B9" uncheckedIcon={false} checkedIcon={false}
				checked={exclude}
				onChange={checked => {
					setExclude(checked);
					onExclude(checked);
				}}
			></Switch>
			<span className='exclude-text'>{t('dropdown_filter.exclude')}</span>
		</div>;
	}, [exclude, onExclude, setExclude, t]);

	const onDone = useCallback(() => {
		onDropdown();
		onChange(selection);
	}, [onChange, onDropdown, selection]);

	return (
		<div id={id} key={`dropdownFilter-${id}`}>
			<div className='dropdown-wrapper' key='dropdown-wrapper'>
				<Dropdown
					isOpen={isOpen}
					onClose={() => onDone()}
					target={dropdownButton}
				>
					<div className='dropdown-filter-content'>
						<div className='dropdown-filter-header'>
							<TextField
								type='text'
								placeholder={t('dropdown_filter.search')}
								id='dropdown-filter-input'
								className='dropdown-filter-input'
								onChange={input => {
									setSearchInput(input.toString());
									if (onInputChange) onInputChange(input.toString());
								}}
								leftIcon={<FontIcon className='ddfi-icon'>search</FontIcon>}
							/>
						</div>
						<div className='dropdown-filter-body'>
							<div className='dropdown-filter-options'>
								{content}
							</div>
						</div>
						<div className='dropdown-filter-footer'>
							{excludeSwitch}
							<div>
								<Button flat key='dropdown-filter-clear-btn' id='dropdown-filter-clear-btn' className='buttons-filter clear-button' onClick={onClear}>
									{t('dropdown_filter.clear')}
								</Button>
								<Button flat key='dropdown-filter-done-btn' id='dropdown-filter-done-btn' className='buttons-filter done-button' onClick={onDone}>
									{t('dropdown_filter.done')}
								</Button>
							</div>
						</div>
					</div>
					{childrenContent}
				</Dropdown>
			</div>
		</div>
	);
};

export default withT(DropdownFilter);
