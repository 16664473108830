import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import FocusFeed from './FocusFeed';
import { State } from 'store/types';
import { StateProps, DispatchProps } from './types';

import { operators as focusOperators } from 'store/focus/list';
import { operators as filtersOperators } from 'store/search/filters';
import { operators as horizontalFiltersOperators } from 'store/search/horizontalFilters';

const mapStateToProps = (state: State): StateProps => ({
	excluded: state.search.horizontalFilters.excludedFields.includes("feedFilters"),
	focusFilters: state.search.filters.focus,
	feedFilters: state.search.filters.feeds,
	focusList: state.focus.list.focusList
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	fetchFocusList: () => dispatch(focusOperators.fetchFocusList()),
	onSetExcludeField: (set: boolean) => dispatch(horizontalFiltersOperators.setExcludeField({ field: "feedFilters", set })),
	onSetFilteredFocusFeeds: (focus: string[], feeds: string[]) => dispatch(filtersOperators.setFilteredFocusFeeds({ focus, feeds }))
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusFeed);
