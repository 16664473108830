import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { FacebookPermission } from 'store/entities/FacebookUser/types';

import { Actions, operators } from './actions';
import { FacebookDialogMode } from './types';

export type State = {
	readonly loaded: boolean,
	readonly skipped: boolean,
	readonly currentAuth: fb.AuthResponse | null,
	readonly dialogMode: FacebookDialogMode
	readonly permissionsMissing: FacebookPermission[] | null
};

export const INITIAL_STATE: State = {
	loaded: false,
	skipped: false,
	currentAuth: null,
	dialogMode: 'closed',
	permissionsMissing: null
};

const setLoaded = (state: State): State => ({ ...state, loaded: true });
const reducerHandlers: ReducerHandlers<State> = {
	updateStatus: (state, { payload: statusResponse }: Actions['UpdateStatus']): State => ({
		...state,
		currentAuth: statusResponse && statusResponse.status === 'connected' ? statusResponse.authResponse : null,
		loaded: false
	}),
	updateStatusSuccess: setLoaded,
	updateStatusError: setLoaded,
	updateAuth: (state, { payload: currentAuth }: Actions['UpdateAuth']): State => ({
		...state,
		currentAuth: currentAuth ? currentAuth : state.currentAuth,
		loaded: false
	}),
	updateAuthSuccess: setLoaded,
	updateAuthError: setLoaded,
	deauthorize: (state): State => ({
		...state,
		loaded: false
	}),
	deauthorizeSuccess: (state): State => ({
		...state,
		currentAuth: null,
		loaded: true
	}),
	setDialogMode: (state, { payload: dialogMode }: Actions["SetDialogMode"]): State => ({
		...state,
		dialogMode,
		permissionsMissing: null
	}),
	setPermissionsMissing: (state, { payload: { mode, permissionsMissing } }: Actions["SetPermissionsMissing"]): State => ({
		...state,
		dialogMode: `${mode}_permissions_missing` as ('check_permissions_missing' | 'auth_permissions_missing'),
		permissionsMissing
	}),
	skip: (state): State => ({
		...state,
		skipped: true
	}),
	setSkipped: (state, { payload: skipped }: Actions['SetSkipped']): State => ({
		...state,
		skipped
	})
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
