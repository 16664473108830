import React from "react";

import { withT } from 'lib/i18n';
import { TProps } from "lib/i18n";

import CategorizationTopicTagChip from "../Chip";

import "./Selected.scss";

type OwnProps = {
	selectedTags: {
		tagIds: string[],
		newTagNames: string[]
	},
	operator?: string,
	onRemoveTag: (tag: string) => void
};

export type ComponentProps = OwnProps & TProps;

const Selected = (props: ComponentProps) => {
	const { t } = props;
	const { selectedTags, operator, onRemoveTag } = props;

	return React.useMemo(() =>
		<>
			{operator && selectedTags.tagIds.length ? <div className="selected-tags-operator">{t(`reports.selected.operator.description.${operator}`)}</div> : null}
			<div className="selected-tags">
				{
					selectedTags.tagIds.map(selectedTag =>
						<CategorizationTopicTagChip key={selectedTag} tagId={selectedTag.replace(/(.*)_/, "")} isRemovable onTagRemoved={() => onRemoveTag(selectedTag)} />
					)
				}
				{
					selectedTags.newTagNames.map(selectedTag =>
						<CategorizationTopicTagChip key={selectedTag} isNewTagName tagId={selectedTag} isRemovable onTagRemoved={() => onRemoveTag(selectedTag)} />
					)
				}
			</div>
		</>, [t, onRemoveTag, selectedTags.newTagNames, selectedTags.tagIds, operator]);
};

export default withT(React.memo(Selected));
