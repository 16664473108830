import { all, call, takeLatest, put, select, delay } from 'redux-saga/effects';
import { push } from "connected-react-router";
import moment from 'moment';

import { cloneDeep, find, values, map, isEmpty } from 'lib/imports/lodash';

import GA from 'lib/googleAnalytics';
import Api from 'lib/ajax/Api';
import Mekong from 'lib/ajax/Mekong';
import { State } from "store/types";
import { State as FormState } from 'store/search/form/reducers';
import { setWrongPath } from 'lib/sagas';
import { checkName } from 'lib/validate';
import { getPeriodParams, getPreviewDefinitionParams, getPreviewSearchFiltersParams, cleanFeedDefinition } from 'lib/searchParams';

import { UserInstance } from 'store/entities/User/types';
import profileSelectors from 'store/app/profile/selectors';
import { State as FiltersState } from 'store/search/filters/reducers';
import { State as FocusFeedState, FocusFeedStateDefinitions } from 'store/focus/feed/reducers';
import { operators as resultsOperators } from 'store/search/results';
import { operators as filtersOperators } from 'store/search/filters';
import { operators as facetsOperators } from 'store/search/facets';
import { operators as searchOperators } from 'store/search/form/actions';
import { operators as notificationsOperators } from 'store/app/notifications';
import { resetSearchData } from 'store/search/sagas';
import { operators as FeedOperators } from 'store/entities/Feed';

import { FeedObject, FeedType, DefinitionSocial, DefinitionPrintVB, DefinitionOnline, DefinitionPrint, FeedCampaign } from 'class/Feed';
import { Tenant, TenantObject } from 'class/Tenant';
import { SearchPeriod } from 'types/search/form';
import { FiltersFacetGroup } from 'class/Filter';
import { FacetObject } from 'class/Facet';
import Filter from 'class/Filter';

import { INITIAL_STATE as SOCIAL_INITIAL_STATE } from './definition/social';
import { INITIAL_STATE as PRINT_INITIAL_STATE } from './definition/print';
import { INITIAL_STATE as ONLINE_INITIAL_STATE } from './definition/online';
import { INITIAL_STATE as PRINT_DMR_INITIAL_STATE } from './definition/printDmr';

import definitionSocialSagas from './definition/social/sagas';
import definitionPrintSagas from './definition/print/sagas';
import definitionPrintDmrSagas from './definition/printDmr/sagas';
import definitionOnlineSagas from './definition/online/sagas';

import { Actions, operators } from './actions';

export default function* sagas() {
	yield all([
		definitionSocialSagas(),
		definitionPrintSagas(),
		definitionOnlineSagas(),
		definitionPrintDmrSagas(),
		takeLatest(operators.setBeginDate.type, setBeginDate),
		takeLatest(operators.setEndDate.type, setEndDate),
		takeLatest(operators.fetchSearch.type, fetchSearch),
		takeLatest(operators.resetFeed.type, resetFeed),
		takeLatest(operators.fetchFeed.type, fetchFeed),
		takeLatest(operators.saveFeed.type, saveFeed),
		takeLatest(operators.saveFeedName.type, saveFeedName),
		takeLatest(operators.checkRecovery.type, checkRecovery),
		takeLatest(operators.recoverFeed.type, recoverFeed),
		takeLatest(operators.recoverPollingStart.type, recoverPollingStart),
		takeLatest(operators.duplicateFeed.type, duplicateFeed),
		takeLatest(operators.duplicateFeedStart.type, duplicateFeedStart)
	]);
}

function* setBeginDate({ payload: { date } }: Actions["SetBeginDate"]) {
	const searchForm: FormState = yield select((state: State) => state.search.form);
	yield put(searchOperators.setPeriodAndDatesRange({ period: "custom", begin_date: date, end_date: searchForm.end_date! }));
}

function* setEndDate({ payload: { date } }: Actions["SetEndDate"]) {
	const searchForm: FormState = yield select((state: State) => state.search.form);
	yield put(searchOperators.setPeriodAndDatesRange({ period: "custom", begin_date: searchForm.begin_date!, end_date: date }));
}

function* fetchSearch() {
	const focusFeedState: FocusFeedState = yield select((state: State) => state.focus.feed);
	if (focusFeedState.feedType === 'print') return;
	if (focusFeedState.feedType === 'socialmedia') {
		const displayAlert: boolean = yield _shouldDisplayPrivateDataAlert();
		if (displayAlert) yield put(notificationsOperators.add({ notification: { t: `definition.feed.form.info.no_private_data`, level: "info" } }));
	}
	if (!_checkFeedInclusive(focusFeedState)) {
		return yield _showNoInclusiveTermsAlert(focusFeedState.feedType!);
	}
	return yield put(resultsOperators.fetchSearch());
}

function* _shouldDisplayPrivateDataAlert() {
	const definitionInstagramAccounts = yield select((state: State) => state.focus.feed.social.definition.instagram_accounts);
	return !isEmpty(definitionInstagramAccounts);
}

function* resetFeed({ payload: { focusId, focusName, feedType } }: Actions["ResetFeed"]) {
	const socialDefinition = cloneDeep(SOCIAL_INITIAL_STATE);
	const printDefinition = cloneDeep(PRINT_INITIAL_STATE);
	const onlineDefinition = cloneDeep(ONLINE_INITIAL_STATE);
	const printDmrDefinition = cloneDeep(PRINT_DMR_INITIAL_STATE);

	const definitions: FocusFeedStateDefinitions = {
		social: socialDefinition,
		print: printDefinition,
		online: onlineDefinition,
		printDmr: printDmrDefinition
	};

	if (feedType === "socialmedia") definitions.social.definition.main.q = focusName;
	else if (feedType === "online") definitions.online.definition.main.q = focusName;

	yield call(resetSearchData);
	yield put(operators.setInitialFeed({ focusId, feedType, definitions }));

	if (feedType === 'print') {
		const initialPrintFacets: FacetObject = {
			groups: {
				"photo.section.issue.media.country.path": [],
				gender: [],
				"photo.section.issue.media.value": [],
				"photo.type.id": [],
				"photo.section.issue.media.frequency": []
			}
		};

		const initialPrintFilters: FiltersState = {
			facetsGroups: {
				"photo.section.issue.media.value": [{ key: "magazine" }],
				"photo.type.id": [{ key: "7" }, { key: "17" }, { key: "18" }]
			},
			focus: [],
			feeds: []
		};

		yield put(facetsOperators.setFacets({ facets: initialPrintFacets }));
		yield put(filtersOperators.setFilters({ filters: initialPrintFilters }));
	} else if (feedType === 'print_dmr') {
		const initialPrintDMRFacets: FacetObject = {
			groups: {
				"country.iso": [],
				"print.source.language.iso": [],
				"print.extra_clipping": [],
				"print.article_type": [],
				"print.source.frequency.name": []
			}
		};

		const initialPrintFilters: FiltersState = {
			facetsGroups: {
				"print.extra_clipping": [{ key: "0" }],
				"print.article_type": [{ key: "editorial" }]
			},
			focus: [],
			feeds: []
		};

		yield put(facetsOperators.setFacets({ facets: initialPrintDMRFacets }));
		yield put(filtersOperators.setFilters({ filters: initialPrintFilters }));
	}
	else yield put(resultsOperators.fetchSearch());

}

function* fetchFeed({ payload: { focusId, feedId } }: Actions["FetchFeed"]) {
	yield call(resetSearchData);
	const focusFeedState: FocusFeedState = yield select((state: State) => state.focus.feed);
	const tenantObj: TenantObject = yield select((state: State) => state.app.profile.tenant);

	const tenant = new Tenant(tenantObj);
	try {
		const feed: FeedObject = yield call(Mekong.get, `/v1/definition/focus/${focusId}/feed/${feedId}`, { params: { extend_feed: 1 } });
		if (feed.type === "socialmedia" && !tenant.hasSocialMediaTier()) {
			return yield setWrongPath({ redirectTo: `/focus/${focusId}` });
		}

		const definitions = _updateFocusFeedDefinitions(focusFeedState, feed);
		const filterFacets: FiltersFacetGroup = Filter.transformFromApi(feed.filters);

		yield put(filtersOperators.setFacetFilters({ facetsGroups: filterFacets }));
		yield put(operators.setFeed({ feed, definitions }));
		yield put(operators.checkRecovery());
		yield put(resultsOperators.fetchSearch());
	} catch (error) {
		yield put(operators.fetchFeedError({ error }));
	}
}

function* saveFeedName({ payload }: Actions["SaveFeedName"]) {
	const focusId: string = yield select((state: State) => state.focus.feed.focusId);
	const feedId: string | null = yield select((state: State) => state.focus.feed.feedId);
	const feedName: string = yield select((state: State) => state.focus.feed.feedName);
	const feedType: string = yield select((state: State) => state.focus.feed.feedType);
	const editNameInputValue: string | null = yield select((state: State) => state.focus.feed.editNameInputValue);
	const api = new Api();

	if (feedType === 'print') return;

	if (feedId && editNameInputValue !== null) {
		const error = checkName(editNameInputValue);
		if (error) {
			yield put(notificationsOperators.add({ notification: { t: `error.feed_${error}`, level: "warning" } }));
			return yield put(operators.setEditNameInputValue({ feedName }));
		}
		try {
			yield put(operators.setLoadingSaveFeedName({ loading: true }));
			yield call([Mekong, 'post'], `/v1/definition/focus/${focusId}/feed/${feedId}`, { data: { name: editNameInputValue, old_name: feedName } });
			if (feedType !== 'print_dmr') {
				yield call([api, 'put'], `definition/focus/${focusId}/feed/${feedId}`, { data: { name: editNameInputValue, old_name: feedName } });
			}

			yield put(FeedOperators.update({ id: feedId, name: editNameInputValue }));
			yield put(operators.saveFeedNameSuccess({ feedName: editNameInputValue }));
		} catch (error) {
			yield put(operators.saveFeedNameError({ error }));
		}
	} else if (editNameInputValue) yield put(operators.saveFeedNameSuccess({ feedName: editNameInputValue }));
}

function* checkRecovery({ payload }: Actions["CheckRecovery"]) {
	const focusId: string = yield select((state: State) => state.focus.feed.focusId);
	const feedId: string = yield select((state: State) => state.focus.feed.feedId);

	try {
		const { recovery_id: recoveryId } = yield call([Mekong, 'get'], `/v1/definition/focus/${focusId}/feed/${feedId}/recovery`);
		if (recoveryId) {
			yield put(operators.recoverFeedSuccess({ recoveryId }));
			yield put(operators.recoverPollingStart());
		}
	} catch (error) {
		yield put(operators.recoverFeedError({ error }));
	}
}

function* recoverFeed({ payload }: Actions["RecoverFeed"]) {
	const focusFeedState: FocusFeedState = yield select((state: State) => state.focus.feed);
	const focusId: string = focusFeedState.focusId!;
	const feedId: string | null = focusFeedState.feedId;
	const feedType: FeedType = focusFeedState.feedType!;
	const feedEnabled: boolean = focusFeedState.feedEnabled;
	const feedChanged: boolean = focusFeedState.feedChanged;
	const period: SearchPeriod = yield select((state: State) => state.search.form.period);
	const beginDate: Date = yield select((state: State) => state.search.form.begin_date);
	const endDate: Date = yield select((state: State) => state.search.form.end_date);
	const user = profileSelectors.getUserInstance(yield select());

	const requireSave = !feedId || feedChanged;
	if (!feedEnabled) return yield put(notificationsOperators.add({ notification: { t: 'feed.form.recovery.must_enable_feed', level: "info" } }));
	if (!_checkFeedInclusive(focusFeedState)) return yield _showNoInclusiveTermsAlert(feedType);
	if (!_searchIsInRange(period, beginDate, endDate, user)) return yield put(notificationsOperators.add({ notification: { t: 'error.search_period.more_than_3_months', level: "info" } }));

	let savedFeedId: string | null = null;
	if (requireSave) {
		yield _saveFeed();
		savedFeedId = yield select((state: State) => state.focus.feed.feedId);
		if (!savedFeedId) return;
	}

	const periodParams = getPeriodParams({ period, beginDate, endDate });
	if (periodParams.begin_date || periodParams.end_date) {
		periodParams.begin_date = encodeURIComponent(periodParams.begin_date!);
		periodParams.end_date = encodeURIComponent(periodParams.end_date!);
	}

	try {
		GA.trackEvent({ category: "Feed definition", action: "Historic recovery" });

		const { recovery_id } = yield call([Mekong, 'post'],
			`/v1/definition/focus/${focusId}/feed/${feedId ? feedId : savedFeedId}/recovery?begin_date=${periodParams.begin_date}&end_date=${periodParams.end_date}`);

		yield put(operators.recoverFeedSuccess({ recoveryId: recovery_id }));
		yield put(operators.recoverPollingStart());
		yield put(notificationsOperators.add({ notification: { t: 'feed.form.recovery.success', level: "success" } }));
	} catch (error) {
		yield put(operators.recoverFeedError({ error }));
	}
}

function* recoverPollingStart({ payload }: Actions["RecoverPollingStart"]) {
	const recoveryId: string = yield select((state: State) => state.focus.feed.recoveryId);
	const focusId: string = yield select((state: State) => state.focus.feed.focusId);
	const feedId: string | null = yield select((state: State) => state.focus.feed.feedId);

	while (true) {
		try {
			const { status }: { status: string; } = yield call([Mekong, 'get'], `/v1/definition/focus/${focusId}/feed/${feedId}/recovery/${recoveryId}`);
			if (status !== 'ended') {
				//TODO change this, is for testing
				yield put(operators.setRecoverPollingProgress({ status }));
				yield delay(5000);
			} else {
				yield put(operators.setRecoverPollingProgress({ status }));
				break;
			}
		} catch (error) {
			yield put(operators.recoverFeedError({ error }));
			break;
		}
	}
}

function* saveFeed({ payload }: Actions["SaveFeed"]) {
	yield _saveFeed();
}

function* _saveFeed() {
	const searchFilters: FiltersState = yield select((state: State) => state.search.filters);
	const focusFeedState: FocusFeedState = yield select((state: State) => state.focus.feed);
	const feedId: string | null = focusFeedState.feedId;
	const focusId: string = focusFeedState.focusId!;
	const feedType: FeedType = focusFeedState.feedType!;
	const feedName: string = focusFeedState.feedName!;
	const feedBrandId: string = focusFeedState.brand_id;
	const feedCampaign: FeedCampaign[] = focusFeedState.feed_campaign.filter(c => !isEmpty(c.campaign_id));
	let feedEnabled: boolean = focusFeedState.feedEnabled;

	const error = checkName(feedName);
	if (error) return yield put(notificationsOperators.add({ notification: { t: `error.feed_${error}`, level: "warning" } }));

	if (!_checkFeedDefinition(focusFeedState) || _checkMediaNotFound(focusFeedState)) {
		return yield put(notificationsOperators.add({ notification: { t: 'feed.form.invalid_expressions', level: "warning" } }));
	}
	if (!_checkFeedInclusive(focusFeedState) && feedEnabled && !focusFeedState.brand_sync) {
		feedEnabled = false;
		yield put(operators.toggleFeedEnabled());
	}

	const params = {
		extend_feed: 1
	};

	const data = {
		definition: cleanFeedDefinition(focusFeedState),
		filters: getPreviewSearchFiltersParams(searchFilters, focusFeedState),
		enabled: feedEnabled,
		name: feedName,
		type: feedType,
		brand_id: feedBrandId,
		is_complex: focusFeedState.is_complex,
		brand_sync: focusFeedState.brand_sync,
		sentiment_check: focusFeedState.sentiment_check,
		approximate_hits: focusFeedState.approximate_hits,
		feed_campaign: feedCampaign
	};

	yield put(operators.setLoadingSaveFeed({ loading: true }));
	yield put(operators.setShowComplexityDialog({ show: false }));
	let responseFeed: FeedObject;
	try {
		if (feedType === 'print_dmr') {
			data.definition = _cleanPrintDefinitionMedia(data);

			if (feedId) responseFeed = yield call([Mekong, 'post'], `/v1/definition/focus/${focusId}/feed/${feedId}`, { params, data });
			else responseFeed = yield call([Mekong, 'post'], `/v1/definition/focus/${focusId}/feed/`, { params, data });
		} else {
			if (feedId) {
				responseFeed = yield call([Mekong, 'post'], `/v1/definition/focus/${focusId}/feed/${feedId}`, { params, data });
			} else {
				responseFeed = yield call([Mekong, 'post'], `/v1/definition/focus/${focusId}/feed`, { params, data });
			}
		}
		const definitions = _updateFocusFeedDefinitions(focusFeedState, responseFeed);
		yield put(operators.setFeed({ feed: responseFeed, definitions }));

		yield put(FeedOperators.replace({ ...responseFeed, focus: focusId }));

		if (!feedId) yield put(push(`/focus/${focusId}/feed/${responseFeed.id}`));
		yield put(resultsOperators.fetchSearch());
	} catch (error) {
		yield put(operators.saveFeedError({ error }));
		return error;
	}
}

function* duplicateFeed({ payload }: Actions["DuplicateFeed"]) {
	const focusFeedState: FocusFeedState = yield select((state: State) => state.focus.feed);
	const feedName: string = focusFeedState.feedName!;
	let feedEnabled: boolean = focusFeedState.feedEnabled;
	const error = checkName(feedName);
	if (error) return yield put(notificationsOperators.add({ notification: { t: `error.feed_${error}`, level: "warning" } }));

	if (!_checkFeedInclusive(focusFeedState) && feedEnabled) {
		feedEnabled = false;
		yield put(operators.toggleFeedEnabled());
	}
	const definitionError = !_checkFeedDefinition(focusFeedState);

	const savedFeedId: string | null = yield select((state: State) => state.focus.feed.feedId);
	if (!savedFeedId || definitionError) {
		return yield put(operators.duplicateFeedError({ error: { code: "FEED_DEFINITION_ERROR" } }));
	}
	yield put(operators.toggleShowDuplicateFeed());
}

function* duplicateFeedStart({ payload }: Actions["DuplicateFeedStart"]) {
	const searchFilters: FiltersState = yield select((state: State) => state.search.filters);
	const focusFeedState: FocusFeedState = yield select((state: State) => state.focus.feed);
	const feedType: string = focusFeedState.feedType!;
	const feedEnabled: boolean = focusFeedState.feedEnabled;
	const feedCampaign: FeedCampaign[] = focusFeedState.feed_campaign.filter(c => !isEmpty(c.campaign_id));


	const data = {
		name: payload.feedName,
		type: feedType,
		enabled: feedEnabled,
		definition: getPreviewDefinitionParams(focusFeedState),
		filters: getPreviewSearchFiltersParams(searchFilters, focusFeedState),
		brand_id: focusFeedState.brand_id,
		is_complex: focusFeedState.is_complex,
		brand_sync: focusFeedState.brand_sync,
		approximate_hits: focusFeedState.approximate_hits,
		feed_campaign: feedCampaign
	};

	if (feedType === 'print_dmr' && data.definition) data.definition = _cleanPrintDefinitionMedia(data);

	const api = new Api();
	try {
		GA.trackEvent({ category: "Feed definition", action: "Duplicate feed" });
		let feed: FeedObject;
		feed = yield call([Mekong, 'post'], `/v1/definition/focus/${payload.focusId}/feed`, { data });
		if (feedType !== 'print_dmr') yield call([api, 'put'], `definition/focus/${payload.focusId}/feed/${feed.id}`, { data });

		yield put(FeedOperators.create({ ...feed, focus: payload.focusId }));

		yield put(operators.duplicateFeedSuccess({ feedId: feed.id }));
		yield put(notificationsOperators.add({ notification: { t: 'feed.form.duplicate.dupped_ok', level: "info" } }));
	} catch (error) {
		yield put(operators.duplicateFeedError({ error }));
	}
}

const _updateFocusFeedDefinitions = (focusFeedState: FocusFeedState, feed: FeedObject): FocusFeedStateDefinitions => {
	const definitions: FocusFeedStateDefinitions = cloneDeep({
		social: focusFeedState.social,
		print: focusFeedState.print,
		online: focusFeedState.online,
		printDmr: focusFeedState.printDmr
	});
	switch (feed.type) {
		case "socialmedia":
			definitions.social.definition = feed.definition as DefinitionSocial;
			break;
		case 'print':
			definitions.print.definition = feed.definition as DefinitionPrintVB;
			break;
		case 'print_dmr':
			definitions.printDmr.definition = feed.definition as DefinitionPrint;
			break;
		case "online":
			definitions.online.definition = feed.definition as DefinitionOnline;
			break;
		case "print_dmr":
			definitions.printDmr.definition = feed.definition as DefinitionPrint;
	}
	return definitions;
};

const _checkFeedDefinition = (focusFeedState: FocusFeedState) => {
	if (focusFeedState.feedType === "socialmedia") {
		const definition: DefinitionSocial = cloneDeep(focusFeedState.social.definition);

		if (definition.main.error) return false;
		if (definition.include_expressions && find(definition.include_expressions, exp => exp.error)) return false;
		if (definition.exclude_expressions && find(definition.exclude_expressions, exp => exp.error)) return false;
		if (definition.include_profiles && find(definition.include_profiles, exp => exp.error)) return false;
		if (definition.exclude_profiles && find(definition.exclude_profiles, exp => exp.error)) return false;
		if (definition.threshold && find(values(definition.threshold), threshold => {
			const value = String(threshold.value);
			return !value.length || !value.match(/^\d+$/) || +value < 0 || threshold.error;
		})) return false;
	}
	else if (focusFeedState.feedType === "online") {
		const definition: DefinitionOnline = cloneDeep(focusFeedState.online.definition);

		if (definition.main.error) return false;
		if (definition.include_expressions && find(definition.include_expressions, exp => exp.error)) return false;
		if (definition.exclude_expressions && find(definition.exclude_expressions, exp => exp.error)) return false;
	} else if (focusFeedState.feedType === "print_dmr") {
		const definition: DefinitionPrint = focusFeedState.printDmr.definition;
		if (definition.include_expressions && find(definition.include_expressions, exp => exp.error)) return false;
		if (definition.exclude_expressions && find(definition.exclude_expressions, exp => exp.error)) return false;
	}
	return true;
};

const _checkFeedInclusive = (focusFeedState: FocusFeedState) => {
	if (focusFeedState.feedType === "socialmedia") {
		const definition: DefinitionSocial = focusFeedState.social.definition;
		if (definition.main.q && definition.main.enabled) return true;
		if (find(definition.include_expressions, expr => (expr.q && expr.enabled))) return true;
		if (find(definition.include_profiles, profile => profile.enabled)) return true;
	} else if (focusFeedState.feedType && focusFeedState.feedType === 'print') {
		const definition: DefinitionPrintVB = focusFeedState.print.definition;
		if (find(definition.brands, brand => !!brand.id && brand.enabled)) return true;
		if (find(definition.product_types, product_type => !!product_type.id && product_type.enabled)) return true;
		if (find(definition.shops, shop => !!shop.id && shop.enabled)) return true;
	} else if (focusFeedState.feedType === "online") {
		const definition: DefinitionOnline = focusFeedState.online.definition;
		if (definition.main.q && definition.main.enabled) return true;
		if (find(definition.include_expressions, expr => (expr.q && expr.enabled))) return true;
		if (definition.include_medias.length) return true;
	} else if (focusFeedState.feedType === "print_dmr") {
		const definition: DefinitionPrint = focusFeedState.printDmr.definition;
		if (find(definition.brands, brand => !!brand.id && brand.enabled)) return true;
		if (find(definition.product_types, product_type => !!product_type.id && product_type.enabled)) return true;
	}
	return false;
};

const _checkMediaNotFound = (focusFeedState: FocusFeedState) => {
	const definition: DefinitionOnline = focusFeedState.online.definition;
	return definition.include_medias.find(includedMedia => includedMedia.found === false) !== undefined
		|| definition.exclude_medias.find(excludedMedia => excludedMedia.found === false) !== undefined;
};

const _cleanPrintDefinitionMedia = (data: any) => {
	const definition = data.definition as DefinitionPrint;

	//@ts-ignore
	if (definition && !isEmpty(definition.include_medias)) definition.include_medias = map(definition.include_medias, media => `${media.id}`);
	//@ts-ignore
	if (definition && !isEmpty(definition.exclude_medias)) definition.exclude_medias = map(definition.exclude_medias, media => `${media.id}`);

	return definition;
};

const _searchIsInRange = (period: SearchPeriod, beginDate: Date, endDate: Date, user: UserInstance): boolean => {
	if (period !== "custom") return true;
	if (user.hasPermission('search.unrestricted_period') || user.hasPermission('recovery.unrestricted_period')) return true;
	return (moment(beginDate).add(3, 'months').toDate() >= moment(endDate).toDate());
};

function* _showNoInclusiveTermsAlert(feedType: FeedType) {
	let langType: string = feedType;
	if (feedType === "socialmedia") langType = "social";
	yield put(notificationsOperators.add({ notification: { t: `feed.form.preview.no_inclusive_terms_${langType}_feed`, level: "info" } }));
}
