import { all, call, put, takeLatest } from 'redux-saga/effects';
import { push } from "connected-react-router";
import moment from 'moment';

import { operators as NewsletterOperators, NewsletterObject, NewsletterAttributes } from 'store/entities/Newsletter';
import Api from 'lib/ajax/Api';
import Mekong from 'lib/ajax/Mekong';
import { cloneDeep, forEach, map } from 'lib/imports/lodash';
import { TreeElement } from 'components/Newsletter/Edit/Form/Layout/types';
import { Actions, operators } from './actions';

export default function* sagas() {
	yield all([
		takeLatest(operators.createNewsletter, createNewsletter),
		takeLatest(operators.updateNewsletter, updateNewsletter),
		takeLatest(operators.queueNewsletter, queueNewsletter)
	]);
}

type NewsletterApiData = NewsletterAttributes & { lists: string[], feeds: string[], tags: string[] };

function* createNewsletter({ payload: { formNewsletter } }: Actions["CreateNewsletter"]) {
	const api = new Api();
	try {
		const data = __cleanNewsletterData(formNewsletter);
		const newsletter = yield call([api, 'post'], '/newsletter', { data });
		__mapListsToEmailLists(newsletter);
		yield put(NewsletterOperators.create(newsletter));
		yield put(push(`/newsletter/${newsletter.id}`));
		yield put(operators.createNewsletterSuccess());
	} catch (error) {
		yield put(operators.createNewsletterError(error));
	}
}

function* updateNewsletter({ payload: { formNewsletter } }: Actions["UpdateNewsletter"]) {
	const api = new Api();
	try {
		const data = __cleanNewsletterData(formNewsletter);
		const newsletter = yield call([api, 'put'], `/newsletter/${formNewsletter.id}`, { data });
		__mapListsToEmailLists(newsletter);
		yield put(NewsletterOperators.update(newsletter));
		yield put(operators.updateNewsletterSuccess());
	} catch (error) {
		yield put(operators.updateNewsletterError(error));
	}
}

function* queueNewsletter({ payload: { formNewsletter } }: Actions["QueueNewsletter"]) {
	try {
		const frequency = formNewsletter.send_configuration_json!.frequency;
		const queueParameters = {
			to: moment().format(),
			from: frequency === 'weekly' ? moment().subtract(1, 'week').format() : moment().subtract(1, 'day').format()
		};
		yield call(Mekong.post, `/v1/newsletter/${formNewsletter.id}/queue`, { data: queueParameters });
		yield put(operators.queueNewsletterSuccess());
	} catch (error) {
		yield put(operators.queueNewsletterError(error));
	}
}

const __cleanNewsletterData = (formNewsletter: Partial<NewsletterObject>): NewsletterApiData => {
	const newsletterData: any = cloneDeep(formNewsletter);
	newsletterData.lists = map(newsletterData.emailLists, 'id');
	delete newsletterData.emailLists;
	delete newsletterData.id;
	delete newsletterData.edited_at;
	delete newsletterData.inserted_at;
	forEach(newsletterData.send_configuration_json, (value, config) => {
		if (!value) delete newsletterData.send_configuration_json[config];
	});
	__cleanLayoutDataTree(newsletterData.send_configuration_json.layout);
	return newsletterData;
};

function __cleanLayoutDataTree(layoutData: TreeElement[]) {
	for (const element of layoutData || []) {
		delete element.name;
		delete element.subtype;
		if (element.children) __cleanLayoutDataTree(element.children);
	};
}

const __mapListsToEmailLists = (newsletter: any) => {
	newsletter.emailLists = newsletter.lists;
	delete newsletter.lists;
};
