import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { keys, uniqBy } from 'lodash';
import { State } from 'store/types';
import selectors from 'store/ui/report/setup/selectors';
import { operators as reportOperators } from 'store/ui/report/setup/';
import { DispatchProps, StateProps } from './types';
import FeedSelectors from 'store/entities/Feed/selectors';
import { getBrandName, getIsCrawled } from 'store/entities/Brand/selectors';

import ReportSetupFeeds from './ReportSetupFeeds';

const mapStateToProps = (state: State): StateProps => ({
	reportType: selectors.getType(state),
	linkedBrandId: state.ui.report.setup.linkedBrandId,
	linkedBrandList: uniqBy(FeedSelectors.get(state, keys(selectors.getFeeds(state))).filter(feed => !!feed.brand_id).map(feed => ({
		id: feed.brand_id as string,
		name: getBrandName(state, feed.brand_id as string),
		isCrawled: getIsCrawled(state, feed.brand_id as string)
	})), brand => brand.id)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetLinkedBrandId: linkedBrand => dispatch(reportOperators.setLinkedBrandId(linkedBrand.id !== '' ? linkedBrand.id : null)),
	onUnselectAllFeeds: () => dispatch(reportOperators.unselectAllFeeds())
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSetupFeeds);
