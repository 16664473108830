import React from 'react';
import { isArray } from 'lib/imports/lodash';

import { ComponentProps } from './types';
import { CircularProgress } from 'react-md';

const FletcherLoader = (props: ComponentProps) => {
	const { isFetched, children, resource } = props;

	if (!isFetched) return (
		<CircularProgress
			className="fletcher-loader"
			id={`fletcherLoader-${isArray(resource) ? resource.join('_') : resource}`}
		/>
	);
	return children;
};

export default React.memo(FletcherLoader);
