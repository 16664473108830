import React from 'react';

import { withT } from 'lib/i18n';
import { DialogContainer, CircularProgress, FontIcon } from 'lib/imports/react-md';

import { FacebookPermission } from 'store/entities/FacebookUser';
import { ComponentProps } from './types';

import './FacebookAuth.scss';

const basicPermissions: FacebookPermission[] = ["email", "public_profile"];

type FacebookLoginButtonProps = {
	onLogin: (authStatus: fb.StatusResponse) => void
};

const FacebookLoginButton = React.memo(({ onLogin }: FacebookLoginButtonProps) => {
	const buttonRef = React.useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;
	const [loading, setLoading] = React.useState<boolean>(false);

	React.useEffect(() => {
		setLoading(true);
		(window as any).onFacebookLogin = onLogin;
		window.FB.XFBML.parse(buttonRef.current, () => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div ref={buttonRef}>
			{loading ? <CircularProgress id="facebookButtonLoading" /> : null}
			<span id="facebookButton" className="fb-login-button"
				data-size="large"
				data-scope={basicPermissions.join(',')}
				data-button-type="continue_with"
				data-show-faces="false"
				data-auto-logout-link="false"
				data-use-continue-as="false"
				data-onlogin="onFacebookLogin">
			</span>
		</div>
	);
});

const FacebookAuth = (props: ComponentProps) => {
	const { dialogMode, t } = props;
	const { onUpdateAuth, onSetDialogMode, onSkip } = props;

	const onAuthenticated = React.useCallback(({ status, authResponse }: fb.StatusResponse) => {
		if (status === 'connected') onUpdateAuth(authResponse);
		onSetDialogMode('closed');
	}, [onUpdateAuth, onSetDialogMode]);

	const onSkipped = React.useCallback(() => {
		onSkip();
		onSetDialogMode('closed');
	}, [onSkip, onSetDialogMode]);

	const content = React.useMemo(() => (
		<DialogContainer id="facebookLoginDialog" aria-describedby="facebookLoginDialog" modal
			visible={(dialogMode === 'facebook')}
			focusOnMount={false}
			onHide={() => { }}
		>
			<div className="content">
				<div className="header">
					<FontIcon iconClassName="icon-instagram" />
					<div className="title">{t('facebook.auth.title')}</div>
				</div>
				<div className="description">
					<p>{t('facebook.auth.description')}</p>
					<p>{t('facebook.auth.description_2')}</p>
				</div>
				<div className="facebook-login">
					<FacebookLoginButton onLogin={onAuthenticated} />
				</div>
				<div className="info">{t('facebook.auth.description_3')}</div>
				<div id="skip"><span onClick={onSkipped}>{t('skip')}</span></div>
			</div>
		</DialogContainer>
	), [dialogMode, onAuthenticated, onSkipped, t]);

	return content;
};

export default withT(FacebookAuth);
