import { connect } from 'react-redux';

import { operators as notificationOperators } from 'store/app/notifications';
import { MapStateToProps, MapDispatchToProps } from './types';
import NewsletterEditFormLayout from './NewsletterEditFormLayout';

const mapStateToProps: MapStateToProps = () => ({
});

const mapDispatchToProps: MapDispatchToProps = dispatch => ({
	onAddNotification: notification => dispatch(notificationOperators.add({ notification }))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterEditFormLayout);
