import React from "react";

import { TProps, withT } from "lib/i18n";
import FacebookImageInput from "components/common/facebook/ImageInput";
import { OnFacebookUrlsParams } from "components/common/facebook/ImageInput/types";

type OwnProps = {
	facebookUrl?: string,
	onFacebookUrlUpdated: (url: OnFacebookUrlsParams) => void
};

type ComponentProps = OwnProps & TProps;

const NewsletterEditFormLogo = (props: ComponentProps) => {
	const { t, facebookUrl } = props;
	const { onFacebookUrlUpdated } = props;

	return (
		<div id="newsletterEditSectionLogo" className="form-section vertical-align-top">
			<div className="form-section-title">
				{t('newsletter.form.label.logo')}
			</div>
			<div className="form-section-content">
				<div className="form-section-content-column">
					<FacebookImageInput
						url={facebookUrl}
						helpText={t('newsletter.form.input.facebook_image.help')}
						onFacebookUrls={onFacebookUrlUpdated} />
				</div>
			</div>
		</div>
	);
};

export default withT(React.memo(NewsletterEditFormLogo));
