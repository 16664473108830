import { ReducerHandlers } from 'lib/store/reducer';
import { DefinitionOnline, MassiveMediaExpression, MediaExpression } from 'class/Feed';
import { State as FocusFeedState } from 'store/focus/feed/reducers';
import { set } from 'lib/imports/lodash';
import { Actions } from './actions';

export type State = {
	definition: DefinitionOnline,
	mediaResults: MediaExpression[],
	errorMedias: MassiveMediaExpression[]
};

export const INITIAL_STATE: State = {
	definition: {
		main: {
			enabled: true,
			q: "",
			scope: ['title', 'content']
		},
		include_expressions: [],
		exclude_expressions: [],
		include_medias: [],
		exclude_medias: []
	},
	mediaResults: [],
	errorMedias: []
};

export const reducerHandlers: ReducerHandlers<FocusFeedState> = {
	setDefinition: (state, { payload: { definition } }: Actions["SetDefinition"]): FocusFeedState => ({
		...state,
		online: {
			...state.online,
			definition
		}
	}),
	setExpression: (state, { payload: { expressionKey, index, values } }: Actions["SetExpression"]): FocusFeedState => ({
		...state,
		online: {
			...state.online,
			definition: {
				...state.online.definition,
				[expressionKey]: expressionKey === "main" ?
					{ ...state.online.definition.main, ...values } :
					set(state.online.definition[expressionKey], index, { ...state.online.definition[expressionKey][index], ...values })
			}
		}
	}),
	resetMedias: (state, payload: Actions["ResetMedias"]): FocusFeedState => ({
		...state,
		online: {
			...state.online,
			mediaResults: []
		}
	}),
	fetchMediaSuccess: (state, { payload: { mediaResults } }: Actions["FetchMediaSuccess"]): FocusFeedState => ({
		...state,
		online: {
			...state.online,
			mediaResults
		}
	}),
	fetchMediaError: (state, payload: Actions["FetchMediaError"]): FocusFeedState => ({
		...state,
		online: {
			...state.online,
			mediaResults: []
		}
	}),
	massiveFetchMediaSuccess: (state, { payload: { mediaResults, errorMedias, expressionKey } }: Actions["MassiveFetchMediaSuccess"]): FocusFeedState => ({
		...state,
		online: {
			...state.online,
			definition: {
				...state.online.definition,
				[expressionKey]: [
					...state.online.definition[expressionKey],
					...mediaResults
				]
			},
			errorMedias
		}
	}),
	clearErrorMedias: state => ({
		...state,
		online: {
			...state.online,
			errorMedias: []
		}
	}),
	massiveFetchMediaError: (state, payload: Actions["MassiveFetchMediaError"]): FocusFeedState => ({
		...state
	})
};
