import React from 'react';
import { FontIcon } from 'react-md';
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, DragStart, Droppable, DroppableProvided, DroppableStateSnapshot, DropResult } from 'react-beautiful-dnd';

import { withT } from 'lib/i18n';
import { TreeElement, TreeLayers } from '../types';
import { ComponentProps } from './types';

import { ReactComponent as GenericLayoutLevelOne } from 'vendor/svg/newsletter/placement-Level1.svg';
import { ReactComponent as GenericLayoutLevelTwo } from 'vendor/svg/newsletter/placement-Level2.svg';
import { ReactComponent as GenericLayoutLevelThree } from 'vendor/svg/newsletter/placement-Level3.svg';

import './LayoutLine.scss';

const LayoutLine = (props: ComponentProps) => {
	const { layoutSettings, layoutData, onUpdateLayout, t } = props;

	const [placeholderProps, setPlaceholderProps] = React.useState({ clientHeight: 0, clientWidth: 0 });

	const showMarker = React.useCallback((rowType: string) => rowType === 'focus' || rowType === 'feed', []);

	const handleDragStart = React.useCallback((initial: DragStart) => {
		const domQuery = `[data-rbd-drag-handle-draggable-id='${initial.draggableId}']`;
		const draggedDOM = document.querySelector(domQuery);

		if (!draggedDOM) return;

		const { clientHeight, clientWidth } = draggedDOM;
		setPlaceholderProps({ clientHeight, clientWidth });
	}, []);

	const handleDragEnd = React.useCallback((dropData: DropResult) => {
		if (!dropData.destination) return;
		const indexes = dropData.destination.droppableId.split('-').map(index => parseInt(index));
		let currentDataLayer = layoutData;
		// `i` starts at 1 to skip mainDroppable level
		for (let i = 1; i < indexes.length; i++) currentDataLayer = currentDataLayer[indexes[i]].children;
		const sourceElement = currentDataLayer[dropData.source.index];
		currentDataLayer.splice(dropData.source.index, 1);
		currentDataLayer.splice(dropData.destination.index, 0, sourceElement);
		onUpdateLayout({ layoutData });
	}, [layoutData, onUpdateLayout]);

	const getRowName = React.useCallback((row: TreeElement) => {
		switch (row.type) {
			case 'channel':
				return <div>{`${t(`newsletter.form.layout.channel.${row.id}_coverage`)}`}</div>;
			case 'focus':
				return <div>{`${t('newsletter.form.layout.focus')}: ${row.name}`}</div>;
			case 'feed':
				let icon;
				switch (row.subtype) {
					case 'online': icon = 'icon-news'; break;
					case 'print': icon = 'icon-print'; break;
					case 'socialmedia': icon = 'icon-socialmedia'; break;
				}
				return (<>
					<FontIcon className='layout-row-item-icon' iconClassName={icon} />
					<div>{row.name}</div>
				</>);
		}
	}, [t]);

	const genericLevelOne = React.useMemo(() => <><GenericLayoutLevelOne /><GenericLayoutLevelOne /><GenericLayoutLevelOne /></>, []);
	const genericLevelTwo = React.useMemo(() => <><GenericLayoutLevelTwo /><GenericLayoutLevelTwo /></>, []);
	const genericLevelThree = React.useMemo(() => <><GenericLayoutLevelThree /><GenericLayoutLevelThree /></>, []);

	const getGenericLayout = React.useCallback((type: TreeLayers) => {
		if (layoutSettings.feed.enabled) return null;

		const channelFirstThanFocus = layoutSettings.channel.order < layoutSettings.focus.order;
		const channelAndFocusEnabled = layoutSettings.channel.enabled && layoutSettings.focus.enabled;

		if (
			(type === 'channel' && channelFirstThanFocus && channelAndFocusEnabled) ||
			(type === 'focus' && !channelFirstThanFocus && channelAndFocusEnabled)
		) {
			return null;
		}

		if (
			(layoutSettings.channel.enabled && !layoutSettings.focus.enabled) ||
			(!layoutSettings.channel.enabled && layoutSettings.focus.enabled)
		) {
			return genericLevelTwo;
		}

		if (channelAndFocusEnabled) {
			return genericLevelThree;
		}

		return genericLevelOne;
	}, [
		layoutSettings.feed.enabled,
		layoutSettings.channel.enabled, layoutSettings.channel.order,
		layoutSettings.focus.enabled, layoutSettings.focus.order,
		genericLevelOne, genericLevelThree, genericLevelTwo
	]);

	const droppableContent = React.useCallback((subItems: TreeElement[], indexList: string) => {
		// TODO Evaluate if we can add the arrow-functions as hooks functions written elsewhere
		return (
			<Droppable
				droppableId={indexList}
				type={`droppableSubItem-${indexList}`}
			>
				{(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
					<>
						<div ref={provided.innerRef}>
							{subItems.map((item, index: number) => (
								<Draggable
									key={`${indexList}-${index}`}
									draggableId={`${indexList}-${index}`}
									index={index}
									isDragDisabled={item.type === 'feed' && !layoutSettings.feed.enabled}
								>
									{(draggProvided: DraggableProvided, _: DraggableStateSnapshot) => (
										<>
											<div
												className='layout-row'
												key={`item-${indexList}-${index}`}
												ref={draggProvided.innerRef}
												{...draggProvided.draggableProps}
												{...draggProvided.dragHandleProps}
											>
												<>
													<div className={`layout-row-item layout-row-item-${item.type}`}>
														{showMarker(item.type) && <div className={`layout-row-item-${item.type}-marker`}></div>}
														<FontIcon>drag_handle</FontIcon>
														{getRowName(item)}
													</div>
													{item.children && droppableContent(item.children, `${indexList}-${index}`)}
												</>
												{getGenericLayout(item.type)}
											</div>
											{provided.placeholder}
										</>
									)}
								</Draggable>
							))}
						</div>
						{snapshot.isDraggingOver &&
							<div style={{ height: placeholderProps.clientHeight, width: placeholderProps.clientWidth }} />
						}
					</>
				)}
			</Droppable>
		);
	}, [getGenericLayout, getRowName, layoutSettings.feed.enabled, placeholderProps.clientHeight, placeholderProps.clientWidth, showMarker]);

	return (<>
		<DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
			<Droppable droppableId="newsletterLayoutDroppable" type='mainDroppable'>
				{(provided: DroppableProvided, _: DroppableStateSnapshot) => (
					<div ref={provided.innerRef} {...provided.droppableProps}>
						{
							layoutSettings.channel.enabled || layoutSettings.focus.enabled || layoutSettings.feed.enabled
								? droppableContent(layoutData, `0`)
								: genericLevelOne
						}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	</>);
};

export default withT(React.memo(LayoutLine));
