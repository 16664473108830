import { Model, attr } from "redux-orm";
import { isArray } from "lib/imports/lodash";
import { createOrmReducer, ReducerOrm } from "lib/store/reducer";
import { Schema } from "store/entities/schema";

import { Actions, operators } from "./actions";
import { TopicFields, TopicData, TopicInstance, TopicModel } from "./types";

export class Topic extends Model<typeof Topic, TopicFields> {
	static get modelName() { return 'Topic' as const; };
	static get idAttribute() { return 'id' as const; };
	static get fields() {
		return {
			id: attr(),
			name: attr(),
			type: attr(),
			read_only: attr(),
			is_general: attr()
		};
	};

	static reducer: ReducerOrm<TopicModel> = createOrmReducer<TopicModel>(operators, Topic => ({
		parse: ({ payload: topicData }: Actions["Parse"]) => {
			const topicDataArray = isArray(topicData) ? topicData : [topicData];
			topicDataArray.forEach(topicData => (Topic as any).parse(topicData));
		}
	}));

	public static parse(topicData: TopicData): TopicInstance {
		const { Tag }: Schema = (this as any).session;
		const { tags, ...upsertData } = topicData;

		const topic: TopicInstance = this.upsert(upsertData);
		if (tags) tags.forEach(tag => Tag.parse({ ...tag, topic }));

		return topic;
	}
}
