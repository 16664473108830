import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { User } from 'class/User';
import TopicManagerLayout from "./ManagerLayout/index";
import { ComponentProps } from './types';

const Topic = ({ user: userObject }: ComponentProps) => {
	const user = React.useMemo(() => new User(userObject!), [userObject]);
	if (!user.hasPermission('document.tag')) return <Redirect to={{ pathname: '/' }} />;

	return <>
		<Switch>
			<Route path="/topic/:id(\d+)" exact
				render={({ match }) => <TopicManagerLayout topicId={match.params.id} />}
			/>
			<Route path="/topic/:id(\d+)" /* not exact: Fallback */
				render={({ match }) => (<Redirect to={`/topic/${match.params.id}`} />)}
			/>
			<Route path="/topic" exact component={TopicManagerLayout} />
			<Route path="/topic" /* not exact: Fallback */
				render={() => (<Redirect to="/topic" />)}
			/>
		</Switch>
	</>;
};

export default Topic;
