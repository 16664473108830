import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { operators } from './actions';

export type State = {
	loading: boolean,
	queued: boolean
};

export const INITIAL_STATE: State = {
	loading: false,
	queued: false
};

const reducerHandlers: ReducerHandlers<State> = {
	createNewsletter: state => ({ ...state, loading: true }),
	createNewsletterError: state => ({ ...state, loading: false }),
	createNewsletterSuccess: state => ({ ...state, loading: false }),
	updateNewsletter: state => ({ ...state, loading: true }),
	updateNewsletterError: state => ({ ...state, loading: false }),
	updateNewsletterSuccess: state => ({ ...state, loading: false }),
	queueNewsletter: state => ({ ...state, queued: true }),
	queueNewsletterError: state => ({ ...state, queued: false }),
	queueNewsletterSuccess: state => ({ ...state, queued: false })
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
