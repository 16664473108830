import { connect } from 'react-redux';
import fletcherSelectors from 'store/fletcher/selectors';

import { MapDispatchToProps, MapStateToProps } from './types';

import App from './App';

const mapStateToProps: MapStateToProps = state => ({
	authenticated: fletcherSelectors.isAuthenticated(state),
	tenant: state.app.profile.tenant,
	user: state.app.profile.user,
	location: state.router.location
});

const mapDispatchToProps: MapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
