import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { Actions } from 'store/ui/audit/list/actions';
import { Audit, Sort } from './types';
import {operators} from './actions';

export type State = {
    audits: Audit[],
    auditLoaded: boolean,
    sort: Sort
};

export const INITIAL_STATE: State = {
	audits: [],
	auditLoaded: false,
	sort: {
		"date": 'desc'
	}
};

const reducerHandlers: ReducerHandlers<State> = {
	fetchAudit: (state: State) => ({
		...state,
		audits: [],
		auditLoaded: false
	}),
	fetchAuditSuccess: (state: State, {payload: audits}: Actions['FetchAuditSuccess']) => ({
		...state,
		audits: [...audits],
		auditLoaded: true
	}),
	fetchAuditError: (state: State, {payload: error}: Actions['FetchAuditError']) => ({
		...state,
		auditLoaded: true
	}),
	fetchNextPage: (state: State) => ({
		...state,
		auditLoaded: false
	}),
	fetchNextPageSuccess: (state: State, {payload: audits}: Actions['FetchNextPageSuccess']) => ({
		...state,
		audits: [...state.audits, ...audits],
		auditLoaded: true
	}),
	fetchNextPageError: (state: State, {payload: error}: Actions['FetchNextPageError']) => ({
		...state,
		auditLoaded: true
	}),
	applySort: (state: State, {payload: sortValue}: Actions['ApplySort']) => ({
		audits: [],
		auditLoaded: false,
		sort: {
			...state.sort,
			[sortValue.field]: sortValue.order
		}
	})
};

export const reducers = createReducer(INITIAL_STATE, reducerHandlers, operators);
