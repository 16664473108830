import React from "react";
import { Button, Collapse, SelectionControl } from 'lib/imports/react-md';

import { FeedObject } from "store/entities/Feed";
import { withT } from "lib/i18n";
import { ComponentProps } from "./types";

const hasPrintFeeds = (feeds: FeedObject[]) => feeds.some(feed => feed.type === 'print' || feed.type === 'print_dmr');

const NewsletterEditFormPrintCriteria = (props: ComponentProps) => {
	const { t, selectedFeeds, printCriteria } = props;
	const { onPrintCriteriaUpdated } = props;

	React.useEffect(() => {
		if (hasPrintFeeds(selectedFeeds) && !printCriteria) onPrintCriteriaUpdated('integration');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFeeds]);

	const coverInput = React.useMemo(() => (
		<SelectionControl
			id={`newsletterEditSectionPrintCriteriaCover`}
			name="newsletterEditSectionPrintCriteria"
			type="radio"
			value="cover"
			label={t('newsletter.form.input.print_criteria.cover.value')}
			checked={printCriteria === "cover"}
			onChange={(value: any) => onPrintCriteriaUpdated(value)}
		/>
	), [t, printCriteria, onPrintCriteriaUpdated]);

	const integrationInput = React.useMemo(() => (
		<SelectionControl
			id={`newsletterEditSectionPrintCriteriaIntegration`}
			name="newsletterEditSectionPrintCriteria"
			type="radio"
			value="integration"
			label={t('newsletter.form.input.print_criteria.integration')}
			checked={printCriteria === "integration"}
			onChange={(value: any) => onPrintCriteriaUpdated(value)}
		/>
	), [t, printCriteria, onPrintCriteriaUpdated]);

	return (
		<Collapse collapsed={!hasPrintFeeds(selectedFeeds)}>
			<div id="newsletterEditSectionPrintCriteria" className="form-section">
				<div className="form-section-title">
					{t('newsletter.form.label.print')}
				</div>
				<div className="form-section-content">
					<div className="form-section-content-column">
						{coverInput}
						<Button
							icon
							tooltipLabel={<span dangerouslySetInnerHTML={{ __html: t('newsletter.form.input.print_criteria.cover.help') }}></span>}
							tooltipPosition="right">help_outline</Button>
					</div>
					<div className="form-section-content-column">
						{integrationInput}
					</div>
				</div>
			</div>
		</Collapse>
	);
};

export default withT(React.memo(NewsletterEditFormPrintCriteria));
