import React from 'react';
import { ComponentProps } from './types';

import './DateColumn.scss';

export const DateColumn = ({ utcDate }: ComponentProps) => {

	const dateParts = utcDate.split('-').join('/').split(/[T:]+/);

	const getDateFormat = (date: string) => date.split('/').map((datePart: string) => datePart.length > 2 ? datePart.substring(2, 4) : datePart).reverse().join('/');

	return <div className="audit-date-column-container">
		<div>{getDateFormat(dateParts[0])}</div>
		<div className="audit-date-column-time-segment">{`${dateParts[1]}:${dateParts[2]}`}</div>
	</div>;
};