import { Model, attr, many } from "redux-orm";
import { map, isArray, difference } from "lib/imports/lodash";
import { createOrmReducer, ReducerOrm } from "lib/store/reducer";
import { Schema } from "store/entities/schema";

import { Actions, operators } from "./actions";
import { FacebookUserFields, FacebookUserModel, FacebookUserData, FacebookUserInstance, FacebookPermission } from "./types";

export const allPermissions =
	["public_profile", "email", "pages_show_list", "instagram_basic", "instagram_manage_comments", "instagram_manage_insights",
		"pages_manage_metadata", "pages_read_engagement"] as const;
const requiredPermissions: readonly FacebookPermission[] =
	["pages_show_list", "instagram_basic", "instagram_manage_comments", "instagram_manage_insights",
		"pages_manage_metadata", "pages_read_engagement"] as const;

export class FacebookUser extends Model<typeof FacebookUser, FacebookUserFields> {
	static get modelName() { return 'FacebookUser' as const; };
	static get idAttribute() { return 'id' as const; };
	static get fields() {
		return {
			id: attr(),
			name: attr(),
			instagramAccounts: many('InstagramAccount', 'facebookUsers')
		};
	};

	static reducer: ReducerOrm<FacebookUserModel> = createOrmReducer<FacebookUserModel>(operators, FacebookUser => ({
		addUser: ({ payload: { id, userId } }: Actions["AddUser"]) => {
			const facebookUser = FacebookUser.withId(id);
			if (!facebookUser) console.warn('FacebookUser <addUser> id not found:', id);
			else facebookUser.users.add(userId);
		},
		removeUser: ({ payload: { id, userId } }: Actions["RemoveUser"]) => {
			const facebookUser = FacebookUser.withId(id);
			if (!facebookUser) console.warn('FacebookUser <removeUser> id not found:', id);
			else facebookUser.users.remove(userId);
		},
		parse: ({ payload: facebookUserData }: Actions["Parse"]) => {
			const facebookUserDataArray = isArray(facebookUserData) ? facebookUserData : [facebookUserData];
			facebookUserDataArray.forEach(facebookUserData => (FacebookUser as any).parse(facebookUserData));
		}
	}));

	public static parse({ instagram_accounts, ...facebookUserData }: FacebookUserData): FacebookUserInstance {
		const { InstagramAccount }: Schema = (this as any).session;
		const parsedData = {
			...facebookUserData,
			instagramAccounts: map(instagram_accounts, instagramAccount => InstagramAccount.parse(instagramAccount))
		};
		return this.upsert(parsedData);
	}

	public static getMissingPermissions(permissions: FacebookPermission[]): FacebookPermission[] {
		return difference(requiredPermissions, permissions);
	}
}
