import { ormEntities, createOrmSelector, OrmSelector } from "store/entities/selectors";
import { UserObject } from "store/entities/User";
import { InstagramAccountObject } from "store/entities/InstagramAccount";
import { FacebookUserObject } from "./types";

export const get: OrmSelector<FacebookUserObject> = createOrmSelector(ormEntities.FacebookUser);
export const getUsers: OrmSelector<UserObject> = createOrmSelector(ormEntities.FacebookUser.users);
export const getInstagramAccounts: OrmSelector<InstagramAccountObject[]> =
	createOrmSelector(ormEntities.FacebookUser.instagramAccounts);

export default {
	get,
	getUsers,
	getInstagramAccounts
};
