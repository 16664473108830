import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import queryString from 'query-string';

import Header from 'components/common/Header';
import Newsletter from 'components/Public/Newsletter';
import Terms from 'components/Public/Terms';

import { withT, setLanguage } from 'lib/i18n';
import { LanguageCode } from 'class/User';

import 'components/App/App.scss';
import './App.scss';

const App = () => {
	const params = queryString.parse(window.location.search);
	setLanguage((params.lang || 'en') as LanguageCode);
	return (
		<div id="app">
			<Header>
				<Link className="link-login" to='/'>Login</Link>
			</Header>
			<div id="appContent">
				<Switch>
					<Route path="/public/newsletter" component={Newsletter} />
					<Route path="/public/terms" component={Terms} />
					<Route render={() => (<div>Page Not Found</div>)} />
				</Switch>
			</div>
		</div>
	);
};

export default withT(App);
