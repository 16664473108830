import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/ui/report/setup';
import reportSelectors from 'store/ui/report/selectors';
import setupSelectors from 'store/ui/report/setup/selectors';
import profileSelectors from 'store/app/profile/selectors';
import FocusSelectors from 'store/entities/Focus/selectors';
import { DispatchProps, StateProps } from './types';

import ReportSetupSettingsDate from './ReportSetupSettingsDate';

const mapStateToProps = (state: State): StateProps => ({
	userSettings: profileSelectors.getUser(state)!.settings,
	focus: FocusSelectors.get(state, reportSelectors.getFocusId(state)!)!,
	reportType: setupSelectors.getType(state),
	reportPeriod: setupSelectors.getPeriod(state),
	reportMinDate: setupSelectors.getMinDate(state),
	reportMaxDate: setupSelectors.getMaxDate(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onChangeMinDate: date => dispatch(operators.changeSettings({ minDate: date })),
	onChangeMaxDate: date => dispatch(operators.changeSettings({ maxDate: date }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSetupSettingsDate);
