import React from 'react';
import { isEmpty } from 'lib/imports/lodash';

import { withT } from 'lib/i18n';
import Autocompleter from 'components/common/Input/Autocompleter';
import { ComponentProps } from './types';

import './FocusAutocompleter.scss';

const FocusAutocompleter = (props: ComponentProps) => {
	const { id, items, error } = props;
	const { t, onSelected } = props;

	return (
		<div className="focus-autocompleter">
			<Autocompleter
				id={`${id}_input`}
				className="focus-autocompleter-input"
				label={t('reports.select_focus')}
				placeholder={t('newsletter.form.input.feeds.placeholder')}
				clearOnAutocomplete
				items={items}
				disabled={isEmpty(items)}
				onSelected={selectedOption => {
					if (selectedOption) onSelected(selectedOption);
				}}
				error={!!error}
			/>
		</div>
	);
};

export default React.memo(withT(FocusAutocompleter));
