import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { operators } from 'store/search/form';
import { operators as facetsOperators } from 'store/search/facets';
import { operators as insertOperators } from 'store/article/insert';
import { operators as reportOperators } from 'store/ui/report/direct';
import { operators as resultsOperators } from 'store/search/results';
import { DispatchProps, StateProps } from './types';
import profileSelectors from 'store/app/profile/selectors';
import ArticleForm from './ArticleForm';

const mapStateToProps = (state: State): StateProps => ({
	...state.search.form,
	total: state.search.results.total,
	loadingDocuments: state.search.results.loadingDocuments,
	showInsertDocument: state.article.insert.showDialog,
	tenant: state.app.profile.tenant!,
	user: profileSelectors.getUser(state)!,
	quickReportEnabled: state.ui.report.direct.quickReportEnabled,
	reportedFocusNames: state.ui.report.direct.reportedFocusNames,
	filters: state.search.form.insightsFilters,
	showTagActionPanel: state.search.results.showTagActionPanel
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSubmit: () => dispatch(operators.submit()),
	onSetSort: sort => dispatch(operators.setSort({ sort })),
	onSetQuery: query => dispatch(operators.setQuery({ query })),
	onToggleDrawer: () => dispatch(facetsOperators.toggleDrawer()),
	onSetLimit: limit => dispatch(operators.setLimit({ limit })),
	onSetShowInsertDocument: show => dispatch(insertOperators.setShowDialog({ show })),
	onQuickReport: () => dispatch(reportOperators.exportQuickReport()),
	onSetReportedFocusNames: focuses => dispatch(reportOperators.setReportedFocusNames({ focuses })),
	onToggleTagPanel: () => dispatch(resultsOperators.toggleTagActionPanel())
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleForm);
