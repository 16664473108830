import React from "react";
import TextField from 'react-md/lib/TextFields';
import SelectionControl from 'react-md/lib/SelectionControls/SelectionControl';

import { withT } from "lib/i18n";
import { ComponentProps } from './types';
import featureFlags from 'lib/featureFlags';
import providerDiscovery from 'lib/providerDiscovery';

import './FocusFeedDefinitonLayoutThreshold.scss';

const FocusFeedDefinitonLayoutThreshold = (props: ComponentProps) => {
	const { channel, checked, disabled, value, error, errorMessage } = props;
	const { t, onCheckToggled, onValueChanged } = props;

	if (providerDiscovery.isParklu(channel) && !featureFlags.isEnabled('parklu') && !checked) return null;

	return (
		<div className={`social-definition-threshold-container ${disabled ? 'disabled-threshold' : ''}`}>
			<SelectionControl
				id={`focusFeedDefinitionSocialThreshold${channel}`}
				name={`focusFeedDefinitionSocialThreshold${channel}`}
				aria-label={`focusFeedDefinitionSocialThreshold${channel}`}
				className={`threshold-checkbox ${checked ? "checkbox-green" : ''}`}
				type="checkbox"
				checked={checked}
				disabled={disabled}
				onChange={val => onCheckToggled(val as boolean)}
			/>
			<span className="threshold-name">
				{t('channel.' + channel)}
			</span>
			{checked ?
				<span className="threshold-value-container">
					{t('feed.form.threshold.accounts_minimum')}
					<TextField
						id={`focusFeedDefinitionSocialThreshold${channel}Value`}
						type='number'
						className={`threshold-input-value`}
						fullWidth={false}
						value={value}
						onChange={newValue => {
							const parsedValue = newValue.toString();
							if (parsedValue === '') return onValueChanged('');
							onValueChanged(+parsedValue > 0 ? +parsedValue : 0);
						}}
						disabled={disabled}
						error={error}
						errorText={errorMessage}
						min={0}
					/>
					{t('feed.form.threshold.followers')}.
				</span>
				: null}
		</div>
	);
};

export default withT(FocusFeedDefinitonLayoutThreshold);
