import { connect } from 'react-redux';

import { MapStateToProps, MapDispatchToProps } from './types';
import LayoutLine from './LayoutLine';

const mapStateToProps: MapStateToProps = state => ({
});

const mapDispatchToProps: MapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutLine);
