import { Model, attr } from "redux-orm";

import { createOrmReducer, ReducerOrm } from "lib/store/reducer";
import { operators } from './actions';
import { CountryFields, CountryModel } from "./types";

export class Country extends Model<typeof Country, CountryFields> {
	static get modelName() { return 'Country' as const; };
	static get idAttribute() { return 'id' as const; };
	static get fields() {
		return {
			id: attr(),
			name: attr(),
			iso_code: attr()
		};
	};

	static reducer: ReducerOrm<CountryModel> = createOrmReducer<CountryModel>(operators);
}