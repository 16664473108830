import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment';

import { State } from "store/types";
import { Settings as TenantSettings } from 'class/Tenant';
import { Settings as UserSettings } from 'class/User';
import { setLanguage } from 'lib/i18n';
import * as formatter from 'lib/format';
import Mekong from 'lib/ajax/Mekong';
import { isCurrentPage } from 'lib/pathCheck';
import { operators as searchOperators } from 'store/search/form/actions';
import { operators as resultsOperators } from 'store/search/results';

import { operators as UserOperators } from 'store/entities/User/actions';
import { operators as TenantOperators } from 'store/entities/Tenant/actions';
import { State as FormState } from "store/search/form/reducers";
import { Actions, operators } from './actions';
import selectors from './selectors';

export default function* sagas() {
	yield all([
		takeLatest(operators.setProfile.type, setProfile),
		takeLatest(operators.updateTenantSettings.type, updateTenantSettings),
		takeLatest(operators.updateUserSettings.type, updateUserSettings),
		takeLatest(operators.fetchTenantUsers.type, fetchTenantUsers)
	]);
}

function* setProfile({ payload: profile }: Actions["SetProfile"]) {
	yield put(UserOperators.replace(profile.user));
	yield put(TenantOperators.replace(profile.tenant));
	yield call(formatter.setSettings, profile.user.settings, profile.tenant.settings);
	yield call(setLanguage, profile.user.settings.language_code);
	yield put(operators.profileReady());
}

function* _setUserSettings(userSettings: UserSettings) {
	yield call(formatter.setUserSettings, userSettings);
	yield call(setLanguage, userSettings.language_code);
}

function* _setTenantSettings(tenantSettings: TenantSettings) {
	yield call(formatter.setTenantSettings, tenantSettings);
}

function* updateTenantSettings({ payload: tenantSettings }: Actions["UpdateTenantSettings"]) {
	const tenantId = selectors.getTenantId(yield select());
	try {
		const settingsResponse: TenantSettings = yield call(Mekong.post, '/tenant/settings', { data: tenantSettings });
		yield put(TenantOperators.update({ id: tenantId, settings: settingsResponse }));
		yield _setTenantSettings(settingsResponse);

		yield put(operators.updateTenantSettingsSuccess(settingsResponse));
	} catch (error) {
		yield put(operators.updateTenantSettingsError(error));
	}
}

function* updateUserSettings({ payload: userSettings }: Actions["UpdateUserSettings"]) {
	const user = selectors.getUser(yield select())!;
	try {
		const settingsResponse: UserSettings = yield call(Mekong.post, '/v1/user/settings', { data: userSettings });
		yield put(UserOperators.update({ id: user.id, settings: settingsResponse }));
		yield _setUserSettings(settingsResponse);

		// TODO: remove, it should update automatically when User timezone state changes
		if (user.settings.timezone !== settingsResponse.timezone) {
			const searchForm: FormState = yield select((state: State) => state.search.form);
			if (searchForm.period === "custom" && searchForm.begin_date && searchForm.end_date) {
				yield put(searchOperators.setPeriodAndDatesRange({
					period: "custom",
					begin_date: moment.tz(searchForm.begin_date, user.settings.timezone).tz(settingsResponse.timezone, true).startOf('day').toDate(),
					end_date: moment.tz(searchForm.end_date, user.settings.timezone).tz(settingsResponse.timezone, true).endOf('day').toDate()
				}));
				const pathname = yield select((state: State) => state.router.location.pathname);
				if (isCurrentPage(pathname, 'article') || isCurrentPage(pathname, 'preview')) yield put(resultsOperators.fetchSearch());
			}
		}

		yield put(operators.updateUserSettingsSuccess(settingsResponse));
	} catch (error) {
		yield put(operators.updateUserSettingsError(error));
	}
}

function* fetchTenantUsers() {
	try {
		let tenantUsers = yield call(Mekong.get, '/v1/tenant/list_users');
		yield put(operators.fetchTenantUsersSuccess(tenantUsers));
	} catch (error) {
		yield put(operators.fetchTenantUsersError(error));
	}
}
