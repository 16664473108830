import { createSelector } from 'reselect';
import { flatten, map, orderBy, uniq } from 'lib/imports/lodash';

import { ormEntities, sessionSelector, createOrmSelector, OrmSelector } from "store/entities/selectors";

import { FeedObject } from 'store/entities/Feed/types';
import { FocusObject } from "store/entities/Focus";

export const get: OrmSelector<FocusObject> = createOrmSelector(ormEntities.Focus);
export const getFeeds: OrmSelector<FeedObject[]> = createOrmSelector(ormEntities.Focus.feeds);

export const getWithFeeds = createSelector(sessionSelector, (_: any, id: string) => id,
	({ Focus }, id): FocusObject<'feeds'> | null => {
		const focus = Focus.withId(id);
		if (!focus) return null;
		return {
			...focus.ref,
			feeds: focus.feeds.all().toRefArray()
		};
	}
);

export const getAllOrderedByNameWithFeeds = createSelector(sessionSelector,
	({ Focus }): FocusObject<'feeds'>[] => orderBy(map(Focus.all().toModelArray(), focus => ({
		...focus.ref,
		feeds: focus.feeds.all().toRefArray()
	})), 'name')
);

export const getAllLinkedBrandIds = createSelector(sessionSelector, 
	({ Focus }): string[] => 
		uniq(flatten(Focus.all().toModelArray().map(focus =>
			focus.feeds.all().toRefArray()
				.filter(feed => !!feed.brand_id)
				.map(feed => feed.brand_id!)
		)))
);

export default {
	get,
	getFeeds,
	getWithFeeds,
	getAllOrderedByNameWithFeeds,
	getAllLinkedBrandIds
};
