import { combineReducers } from 'redux';

import * as facebook from './facebook';
import * as notifications from './notifications';
import * as profile from './profile';

export const reducers = combineReducers({
	facebook: facebook.reducers,
	profile: profile.reducers,
	notifications: notifications.reducers
});

export interface State {
	facebook: facebook.State;
	profile: profile.State;
	notifications: notifications.State;
}

export const INITIAL_STATE = {
	facebook: facebook.INITIAL_STATE,
	profile: profile.INITIAL_STATE,
	notifications: notifications.INITIAL_STATE
};
