import { ComponentProps } from './types';
import React, { MouseEvent } from 'react';
import { SelectionControl } from 'lib/imports/react-md';

const EventPickerCheckbox = ({ eventItem, onClick, isChecked }: ComponentProps) => {
	const [isSelected, setIsSelected] = React.useState(isChecked(eventItem.event));

	const onSelectionWithStopping = (e: Event | MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		onSelection();
	};

	const onSelection = () => {
		setIsSelected(!isSelected);
		onClick(eventItem.event);
	};

	return <div className="audit-events-group-item" key={`audit-event-${eventItem.event}`}>
		<SelectionControl
			label=""
			type="checkbox"
			checked={isSelected}
			onChange={() => onSelection()}
			onClick={e => e.stopPropagation()}
			className={isSelected ? "checkbox-green" : ""}
			id={`auditEventsGroupItem-${eventItem.event}`}
			key={`auditEventsGroupItem-${eventItem.event}`}
			name={`auditEventsGroupItem-${eventItem.event}[]`}
		/>
		<div
			className="audit-event-name"
			onClick={e => onSelectionWithStopping(e)}
			id={`auditEventsGroupItemName-event-${eventItem.event}`}
			key={`auditEventsGroupItemName-event-${eventItem.event}`}
		>
			{eventItem.name}
		</div>
	</div>;
};

export default EventPickerCheckbox;