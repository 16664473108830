import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { operators } from 'store/focus/feed/definition/print/actions';
import { State } from 'store/types';

import { DispatchProps, StateProps } from './types';
import FocusFeedDefinitionPrintExpressionShop from './FocusFeedDefinitionPrintSectionShop';

const mapStateToProps = ({ focus: { feed: { print: { shopsApiResponse, definition } } } }: State): StateProps => ({
	shopsApiResponse,
	shops: definition.shops
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onFetchShops: (query, excluded = {}, limit = 50) => dispatch(operators.fetchShops({ query, excluded, limit })),
	onResetShops: () => dispatch(operators.resetShops()),
	onAddExpression: expression => dispatch(operators.addPrintExpression({ type: 'shops', expression })),
	onUpdateExpression: (index, expression) => dispatch(operators.updatePrintExpression({ type: 'shops', index, expression }))
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedDefinitionPrintExpressionShop);
