import React from 'react';
import Button from 'react-md/lib/Buttons/Button';
import TextField from 'react-md/lib/TextFields/TextField';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';
import isEmpty from 'lodash/isEmpty';
import isURL from 'validator/lib/isURL';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import Api from 'lib/ajax/Api';

import './FacebookImageInput.scss';

type InputState = {
	value: string,
	loading: boolean,
	error: string | null
}

const FacebookImageInput = (props: ComponentProps) => {
	const { t, url, helpText, onFacebookUrls } = props;

	const [inputState, setInputState] = React.useState<InputState>({
		value: url || "",
		loading: false,
		error: null
	});
	const [fetchUrl, setFetchUrl] = React.useState<string>(url || "");
	const [finalUrl, setFinalUrl] = React.useState<string | null>(null);

	const onErrorImg = React.useCallback(() => {
		const error = t('error.facebook_logo_not_found');
		onFacebookUrls(null);
		setInputState({ ...inputState, error });
	}, [onFacebookUrls, inputState, setInputState, t]);

	const onClearBtn = React.useCallback(() => {
		setInputState({ value: "", error: null, loading: false });
		setFinalUrl(null);
		onFacebookUrls(null);
	}, [setInputState, setFinalUrl, onFacebookUrls]);

	const onInputChange = React.useCallback(val => {
		setInputState({ ...inputState, value: val.toString() });
	}, [inputState]);

	const onBadUrl = React.useCallback(() => {
		if (inputState.value.trim() === "") {
			setInputState({ value: "", error: null, loading: false });
		} else {
			const error = t('error.url_not_valid');
			setInputState({ ...inputState, error, loading: false });
		}
		setFetchUrl("");
		setFinalUrl(null);
		onFacebookUrls(null);
	}, [inputState, setInputState, setFinalUrl, onFacebookUrls, t]);

	const onFormSubmit = React.useCallback(ev => {
		ev.preventDefault();
		if (!inputState.value.trim()) return onBadUrl();
		let fixedUrl = inputState.value.trim().replace(/http:\/\//, 'https://');
		if (!fixedUrl.match(/https:\/\/|facebook\.com/)) fixedUrl = 'https://www.facebook.com/' + fixedUrl;
		else if (fixedUrl.match(/^facebook\.com/)) fixedUrl = 'https://www.' + fixedUrl;
		else if (fixedUrl.match(/^www\.facebook\.com/)) fixedUrl = 'https://' + fixedUrl;
		else if (fixedUrl.match(/^https:\/\/facebook\.com/)) fixedUrl = fixedUrl.replace(/https:\/\/facebook\.com/, 'https://www.facebook.com');
		if (fixedUrl && isURL(fixedUrl)) {
			setInputState({ value: fixedUrl, loading: false, error: null });
			setFetchUrl(fixedUrl);
		}
		else onBadUrl();
	}, [inputState.value, onBadUrl]);

	React.useEffect(() => {
		if (!fetchUrl || !isURL(fetchUrl)) return;

		(async () => {
			const api = new Api();
			try {
				setInputState({ ...inputState, error: null, loading: true });
				const facebookImageUrl = await api.get('/facebookImage', { params: { url: fetchUrl } });
				setInputState({ value: fetchUrl, loading: false, error: null });
				setFinalUrl(facebookImageUrl);
				onFacebookUrls({ pageUrl: fetchUrl, imageUrl: facebookImageUrl });
			} catch (error) {
				onErrorImg();
				setFinalUrl(null);
			}
		})();

	}, [fetchUrl]); // eslint-disable-line react-hooks/exhaustive-deps

	let facebookImage = <></>;
	if (inputState.loading) facebookImage = <CircularProgress id="facebookImageInputLoading" centered={false} />;
	else if (!inputState.error && !isEmpty(finalUrl)) facebookImage = (
		<img className="facebook-image" onError={onErrorImg} src={finalUrl!} alt=""></img>
	);

	const clearFacebookUrlButton = !isEmpty(inputState.value) ? <Button icon id="clearFacebookInput" onClick={onClearBtn}>clear</Button> : null;

	return (
		<div id="facebookImageInput">
			<form onSubmit={onFormSubmit}>
				<div className={`form-field ${inputState.error ? "with-error" : ""}`}>
					<TextField
						id="facebookImageUrlInput"
						label={t('tenant.settings.facebook_page.label')}
						fullWidth={false}
						onChange={onInputChange}
						error={!!inputState.error}
						errorText={inputState.error}
						onBlur={onFormSubmit}
						value={inputState.value}
					/>
					{clearFacebookUrlButton}
					<Button icon id="helpFacebookUrlInput" tooltipLabel={helpText} tooltipPosition="right">help_outline</Button>
				</div>
				{facebookImage}
			</form>
		</div>
	);
};

export default withT(React.memo(FacebookImageInput));
