import React from 'react';

import { isEmpty, map, transform } from 'lib/imports/lodash';
import { Button, SelectionControl, FontIcon, Collapse } from 'lib/imports/react-md';

import { withT } from 'lib/i18n';
import { Shop } from 'class/Feed';
import Autocompleter from 'components/common/Input/Autocompleter';

import { ComponentProps } from './types';

const FocusFeedDefinitionPrintSectionShop = (props: ComponentProps) => {
	const { shops, shopsApiResponse, t } = props;
	const { onFetchShops, onResetShops, onAddExpression, onUpdateExpression, onRemoveExpression } = props;

	const [collapse, setCollapse] = React.useState<boolean>(false);

	const onUpdateItems = React.useCallback((value: string, currentShop: Shop) => {
		if (value.length < 2) return onResetShops();
		const shopsIdsToExclude = transform(shops, (result: Shop['id'][], shop: Shop) => {
			if (shop.id && shop.id !== currentShop.id) result.push(shop.id);
		});

		if (isEmpty(shopsIdsToExclude)) return onFetchShops(value);
		onFetchShops(value, { shops: shopsIdsToExclude });
	}, [shops, onFetchShops, onResetShops]);

	const items = React.useMemo(() => map(shopsApiResponse, shop => ({
		id: shop.id.toString(), text: shop.description
	})), [shopsApiResponse]);

	const getExpressionComponent = React.useCallback((index: number, shop: Shop) => (
		<div key={`shop-${index}`}
			className={`print-definition-expression content-expression ${shop.enabled ? 'enabled' : 'disabled'}`}
		>
			<SelectionControl
				id={`focusFeedDefinitionPrintExpressionEnabledShop-${index}`}
				name={`focusFeedDefinitionPrintExpressionEnabledShop-${index}`}
				aria-label={`focusFeedDefinitionPrintExpressionEnabledShop-${index}`}
				className={`definition-expression-enabled ${shop.enabled ? "checkbox-green" : ''} `}
				type="checkbox"
				checked={shop.enabled}
				onChange={val => onUpdateExpression(index, { ...shop, enabled: val as boolean })}
			/>
			<span className="definition-expression-label">{`${index > 0 ? t('feed.form.or') : ''} ${t('feed.form.label.shops')}:`}</span>
			<Autocompleter id={`shopAutocompleter-${index}`} className="definition-expressions-query"
				items={items}
				defaultText={shop.name}
				onChange={value => onUpdateItems(value, shop)}
				onSelected={item => {
					const [id, name] = item ? [item.id, item.text] : ['', ''];
					onUpdateExpression(index, { ...shop, id, name });
				}}
				onBlur={() => onResetShops()}
				// onFocus={() => onUpdateItems(shop.name)} uncomment to search items on focus
				disabled={!shop.enabled}
			/>
			<Button
				id={`focusFeedPrintShopRemove-${index}`}
				className='definition-expression-remove-button'
				icon
				onClick={() => onRemoveExpression(shop.id, 'shops', index)}
			>delete</Button>
		</div>
	), [items, onUpdateItems, onResetShops, onRemoveExpression, onUpdateExpression, t]);

	return (
		<div id="focusFeedDefinitionPrintShopsSection" className="definition-section">
			<div className="definition-section-header">
				<div className="definition-header-title definition-header-title-shops" onClick={() => setCollapse(!collapse)}>
					{
						collapse ?
							<FontIcon className="definition-header-title-toggle" >keyboard_arrow_right</FontIcon> :
							<FontIcon className="definition-header-title-toggle">keyboard_arrow_down</FontIcon>
					}
					<span className="definition-header-title-text"> {t('feed.form.shops')} </span>
				</div>
			</div>
			<Collapse collapsed={collapse}>
				<div className="definition-section-content">
					<div className="definition-expressions">
						{
							shops.map((shop, index) => getExpressionComponent(index, shop))
						}
						<div className="definition-add-expression">
							<Button id="focusFeedDefinitionPrintAddShopBtn" flat
								className="definition-add-expression-button"
								onClick={() => { onAddExpression({ id: '', name: '', enabled: true }); }}
							>
								<span>+</span> {t('feed.form.button.add_shops')}
							</Button>
						</div>
					</div>
				</div>
			</Collapse>
		</div>
	);
};

export default withT(React.memo(FocusFeedDefinitionPrintSectionShop));
