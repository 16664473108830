import React, { ReactText } from 'react';
import { isEmpty, map, keyBy, keys, omit, pickBy, reject, transform, size, filter, without } from 'lib/imports/lodash';
import { FontIcon, SelectField } from 'lib/imports/react-md';

import { withT } from 'lib/i18n';
import Autocompleter from 'components/common/Input/Autocompleter';
import { ReportFeedClipping, ReportFeedClippings } from 'store/ui/report/setup/types';
import { REPORT_FEED_CLIPPING_COUNT, REPORT_FEED_CLIPPING_SORT } from 'store/ui/report/setup/consts';
import { ComponentProps } from './types';

import './ReportSetupFeedsClipping.scss';
import featureFlags from 'lib/featureFlags';

const ReportSetupFeedsClipping: React.FC<ComponentProps> = props => {
	const { t, feeds, reportFeeds, feedClippings, reportType } = props;
	const { onSetFeedClippings } = props;

	const feedsById = React.useMemo(() => keyBy(feeds, 'id'), [feeds]);

	const removeFeedClipping = React.useCallback((id: string) => {
		onSetFeedClippings(reject(feedClippings, { id }));
	}, [feedClippings, onSetFeedClippings]);

	const addFeedClipping = React.useCallback((feedClipping: ReportFeedClipping) => {
		onSetFeedClippings([...feedClippings, feedClipping]);
	}, [feedClippings, onSetFeedClippings]);

	const updateFeedClippingSettings = React.useCallback((id: string, settings: Partial<ReportFeedClipping>) => {
		const modifiedFeedClippings = map(feedClippings, feedClipping => {
			if (feedClipping.id === id) return { ...feedClipping, ...settings };
			return feedClipping;
		});

		onSetFeedClippings(modifiedFeedClippings);
	}, [feedClippings, onSetFeedClippings]);

	const setFeedClippingsFromReportFeeds = React.useCallback(() => {
		const feedClippingsById = keyBy(feedClippings, 'id');
		onSetFeedClippings(transform(reportFeeds, (results: ReportFeedClippings, reportFeed, feedId) => {
			if (!reportFeed.selected) return;

			const feedClipping = feedClippingsById[feedId];
			if (feedClipping) results.push(feedClipping);
			else results.push({
				id: feedId,
				count: 10,
				sort: 'reach'
			});
		}, []));
	}, [reportFeeds, feedClippings]); // eslint-disable-line react-hooks/exhaustive-deps

	const syncFeedClippingsFromReportFeeds = React.useCallback(() => {
		const removedFeedClippings = filter(feedClippings, feedClipping => reportFeeds[feedClipping.id] && reportFeeds[feedClipping.id].selected);
		if (size(removedFeedClippings) < size(feedClippings)) onSetFeedClippings(removedFeedClippings);
	}, [reportFeeds, feedClippings, onSetFeedClippings]);

	React.useEffect(() => {
		if (reportType === 'coverage') {
			setFeedClippingsFromReportFeeds();
		} else {
			syncFeedClippingsFromReportFeeds();
		}
	}, [reportType, reportFeeds]); // eslint-disable-line react-hooks/exhaustive-deps

	const feedDropdown = React.useMemo(() => {
		if (reportType === 'coverage') return null;
		const selectedFeeds = pickBy(reportFeeds, { selected: true });
		const selectorFeedIds = keys(omit(selectedFeeds, map(feedClippings, "id")));
		const feedOptions = map(selectorFeedIds, selectorFeedId => ({
			text: feedsById[selectorFeedId].name,
			id: selectorFeedId
		}));

		return (
			<>
				<span className="feed-clipping-box-dropdown-title">{t('reports.add_your_best_clippings')}</span>
				<Autocompleter
					id="reportSetupFeedsClippingAutocmpleterInput"
					className="report-setup-feeds-clipping-autocompleter-input"
					label={t('reports.please_select_feed')}
					clearOnAutocomplete
					items={feedOptions}
					defaultText={''}
					disabled={isEmpty(feedOptions)}
					onSelected={selectedOption => {
						if (!selectedOption) return;
						addFeedClipping({ id: selectedOption.id, count: 10, sort: 'reach' });
					}}
				/>
			</>
		);
	}, [t, reportType, reportFeeds, feedClippings, feedsById, addFeedClipping]);

	const feeedClippingsSection = React.useMemo(() => {
		if (isEmpty(feedClippings)) return null;

		return map(feedClippings, feedClipping => {
			const feedType = feedsById[feedClipping.id].type;
			const includeClippingsKey = feedType === 'print' ? 'number_references' : 'number_placements';
			const isCoverage = reportType === 'coverage';

			const includeClippingMenuItems = map(REPORT_FEED_CLIPPING_COUNT, n => (
				{ label: t(`reports.feeds.first_${includeClippingsKey}`, { count: n }), value: n }
			));

			let clippingSortOptions = feedType === 'socialmedia' ? REPORT_FEED_CLIPPING_SORT : without(REPORT_FEED_CLIPPING_SORT, 'echo');

			if(featureFlags.isEnabled('no-miv')) {
				clippingSortOptions = without(clippingSortOptions, 'miv');
			}

			const sortingMenuItems = map(clippingSortOptions, field => (
				{ label: t(`reports.${field}`), value: field }
			));

			return (
				<div key={feedClipping.id} className="feed-clipping-box-settings">
					<div className='name-wrapper'>
						{!isCoverage && <FontIcon onClick={() => removeFeedClipping(feedClipping.id)} >close</FontIcon>}
						<span className="feed-clipping-box-settings-feed-name">{feedsById[feedClipping.id].name}</span>
					</div>
					<SelectField
						id={`reportSetupFeedsClippingSelectCountFeed${feedClipping.id}`}
						className="report-setup-feeds-clipping-select"
						label={t('reports.include_clippings_of')}
						repositionOnResize
						menuItems={includeClippingMenuItems}
						onChange={(value: ReactText) => {
							const count = parseInt(value.toString()) as ReportFeedClipping['count'];
							updateFeedClippingSettings(feedClipping.id, { count });
						}}
						defaultValue={10}
						value={feedClipping.count}
					/>
					<SelectField
						id={`reportSetupFeedsClippingSelectSortingFeed${feedClipping.id}`}
						className="report-setup-feeds-sorting-select"
						label={t('reports.feeds.filter_sort')}
						repositionOnResize
						menuItems={sortingMenuItems}
						onChange={(value: ReactText) => {
							const sort = value as ReportFeedClipping['sort'];
							updateFeedClippingSettings(feedClipping.id, { sort });
						}}
						defaultValue={"reach"}
						value={feedClipping.sort}
					/>
				</div>
			);
		});

	}, [t, feedClippings, feedsById, reportType, removeFeedClipping, updateFeedClippingSettings]);

	const optionalContent = React.useMemo(() => {
		if (reportType === 'coverage') return null;
		return <span className="section-title-info">{t('reports.optional')}</span>;
	}, [t, reportType]);

	return (
		<div id="reportSetupFeedsClipping" className="form-section vertical-align-top">
			<div className="form-section-title">
				<div>
					<span>{t('reports.clippings')}</span>
				</div>
				<div>
					{optionalContent}
				</div>
			</div>
			<div className="form-section-content">
				<div className="feed-clipping-box-dropdown">
					{feedDropdown}
				</div>
				{feeedClippingsSection}
			</div>
		</div>
	);
};

export default withT(React.memo(ReportSetupFeedsClipping));
