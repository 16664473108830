import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { DispatchProps, StateProps } from './types';
import FacebookImageInput from './FacebookImageInput';

const mapStateToProps = (): StateProps => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FacebookImageInput);
