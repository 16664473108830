import React, { useEffect } from 'react';

import { Button, FontIcon } from 'lib/imports/react-md';

import { withT } from 'lib/i18n';
import { ComponentProps } from './types';
import { PrintExpressionType } from 'class/Feed';

import SimpleDialog from 'components/common/dialog/simple';

import FocusFeedDefinitionPrintSectionBrand from './Section/Brand';
import FocusFeedDefinitionPrintSectionProductType from './Section/ProductType/';
import FocusFeedDefinitionPrintSectionShop from './Section/Shop';

import './FocusFeedDefinitionPrint.scss';

const FocusFeedDefinitionPrint = (props: ComponentProps) => {
	const { t } = props;
	const { onFetchSearch, onRemoveExpression, onAddNotification } = props;

	const [expressionToRemove, setExpressionToRemove] = React.useState<{ type: PrintExpressionType; index: number } | null>(null);

	useEffect(() => {
		onAddNotification({ level: 'warning', text: t('feed.form.deprecate_print_feeds') });
	}, [onAddNotification, t]);

	const _removeExpression = React.useCallback((q: string, type: PrintExpressionType, index: number) => {
		if (q) setExpressionToRemove({ type, index });
		else onRemoveExpression(type, index);
	}, [setExpressionToRemove, onRemoveExpression]);

	const searchButton = React.useMemo(() => (
		<Button id="focusFeedDefinitionPrintSearchBtn" disabled={true} className="definition-header-search-button" flat
			onClick={() => onFetchSearch()}
		>
			<FontIcon>search</FontIcon>
			{t('form.search.value')}
		</Button>
	), [onFetchSearch, t]);

	const removeExpressionDialog = React.useMemo(() => {
		if (!expressionToRemove) return null;
		return (
			<SimpleDialog id="focusFeedDefinitionRemoveExpressionWarning"
				content={<></>}
				text={t('feed.form.confirm_remove_expression')}
				onAccept={() => {
					onRemoveExpression(expressionToRemove.type, expressionToRemove.index);
					setExpressionToRemove(null);
				}}
				onCancel={() => setExpressionToRemove(null)}
				acceptColor="red"
			/>
		);
	}, [expressionToRemove, onRemoveExpression, setExpressionToRemove, t]);

	return (
		<div id="focusFeedPrintDefinition">
			{removeExpressionDialog}
			<div> {searchButton} </div>
			{/* TODO: enable when print has `sentiment` or `brand link` enabled */}
			{/* <FocusFeedDefinitionInformation/> */}
			<FocusFeedDefinitionPrintSectionBrand onRemoveExpression={_removeExpression} />
			<FocusFeedDefinitionPrintSectionProductType onRemoveExpression={_removeExpression} />
			<FocusFeedDefinitionPrintSectionShop onRemoveExpression={_removeExpression} />
		</div>
	);
};

export default withT(React.memo(FocusFeedDefinitionPrint));
