import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { Actions, operators } from './actions';
import { SearchPeriod, SearchDateType } from 'types/search/form';

export type State = {
	readonly query: string,
	readonly sort: string,
	readonly period: SearchPeriod,
	readonly begin_date: Date | null,
	readonly end_date: Date | null,
	readonly date_type: SearchDateType,
	readonly limit: number,
	readonly start: number,
	readonly insightsFilters: any
};

export const INITIAL_STATE: State = {
	query: '',
	sort: 'publication_date:desc',
	period: 'last_week',
	begin_date: null,
	end_date: null,
	date_type: 'publication_date',
	limit: 20,
	start: 0,
	insightsFilters: {}
};

const reducerHandlers: ReducerHandlers<State> = {
	resetSearchFormData: (state, { payload }: Actions["ResetSearchFormData"]): State => ({
		...INITIAL_STATE
	}),
	setForm: (state, { payload: { form } }: Actions["SetForm"]): State => ({
		...state,
		...form
	}),
	setSort: (state, { payload: { sort } }: Actions["SetSort"]): State => ({
		...state,
		sort,
		start: 0
	}),
	setQuery: (state, { payload: { query } }: Actions["SetQuery"]): State => ({
		...state,
		query
	}),
	setLimit: (state, { payload: { limit } }: Actions["SetLimit"]): State => ({
		...state,
		limit,
		start: 0
	}),
	setPeriodAndDatesRange: (state, { payload: { period, begin_date, end_date } }: Actions["SetPeriodAndDatesRange"]): State => ({
		...state,
		period,
		begin_date: begin_date || state.begin_date,
		end_date: end_date || state.end_date
	}),
	setDateType: (state, { payload: { type } }: Actions["SetDateType"]): State => ({
		...state,
		date_type: type
	}),
	setDateTypeFilter: (state, { payload: { type } }: Actions["SetDateTypeFilter"]): State => ({
		...state,
		date_type: type
	}),
	setStart: (state, { payload: { start } }: Actions["SetStart"]): State => ({
		...state,
		start
	}),
	setInsightsFilters: (state, { payload: { filters } }: Actions["SetInsightsFilters"]): State => ({
		...state,
		//TODO we do not want to insert that here this way
		insightsFilters: {
			...state.insightsFilters,
			...filters
		}
	})
};

export const reducers = createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
