import DateFilter from './DateFilter';

import { Dispatch } from 'redux';
import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import { connect } from 'react-redux';
import { operators as searchFormOperators } from 'store/search/form';
import { SearchDateType } from 'types/search/form';

const mapStateToProps = (state: State): StateProps => ({
	dateType: state.search.form.date_type
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onSetDateTypeFilter: (type: SearchDateType) => dispatch(searchFormOperators.setDateTypeFilter({ type }))
});

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);