import { withT } from 'lib/i18n';
import React, { useState } from 'react';
import { ComponentProps } from './types';
import { DialogContainer } from 'lib/imports/react-md';
import { EmailPopup } from '../EmailPopups/EmailPopup/';
import { EmailPopupErrorType } from 'store/ui/audit/list/types';

const NewsletterErrorDetails = ({details, newsletterData: { emailReports, errorType }, t}: ComponentProps) => {
	const [isVisible, setIsVisible] = useState(false);
	const [emailList, setEmailList] = useState(emailReports);

	const localeSort = React.useCallback((value: 'asc' | 'desc') =>  Array.from(emailList).sort((a, b) => {
		return value === 'asc' ? 
			a.list.localeCompare(b.list, 'en', { sensitivity: 'base' }) : 
			-a.list.localeCompare(b.list, 'en', { sensitivity: 'base' });
	}), [emailList]);

	const sortList = React.useCallback((value: 'asc' | 'desc') => {
		setEmailList(localeSort(value));
	}, [localeSort]);

	const getErrorMessage = React.useCallback((type: EmailPopupErrorType) => type === 'no_content' ? 
		t('audit_email_popup_no_content_msg') : 
		t('audit_email_popup_error_occurred_msg')
	, [t]);

	const getEmailPopup = React.useCallback(() => <EmailPopup 
		subtitle={details}
		initialValue='asc'
		onListSort={sortList}
		emailReports={emailList}
		onClose={() => setIsVisible(false)}
		errorMessage={errorType ? getErrorMessage(errorType) : undefined}
	/>, [details, emailList, getErrorMessage, errorType, sortList]);

	const getNewsletterLink = React.useCallback(() => <div>
		<DialogContainer 
			visible={isVisible}
			focusOnMount={false}
			children={getEmailPopup()}
			onHide={() => setIsVisible(false)}
			onClick={() => setIsVisible(false)}
			id="auditDetailsColumnShowMoreDialog"
			aria-labelledby="launchmetrics-dialog"
		/>
		<div className="audit-table-newsletter-details-link" onClick={() => setIsVisible(!isVisible)}>
			Show more
		</div>
	</div>, 
	[isVisible, getEmailPopup]
	);

	return<div>
		{errorType && 
            <div className="audit-list-newsletter-details">
                reason: {getErrorMessage(errorType)}	
            </div>
		}
		{getNewsletterLink()}
	</div>;
};

export default withT(NewsletterErrorDetails);