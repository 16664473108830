import React from 'react';
import { Draggable, DraggableProvided, DraggableStateSnapshot, Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { withT } from 'lib/i18n';

import { TagObject } from 'store/entities/Tag';
import { Button, TextField, FontIcon } from 'lib/imports/react-md';
import { find, isNull } from 'lib/imports/lodash';
import { useUpdateEffect } from 'lib/hooks';
import SimpleDialog from 'components/common/dialog/simple';

import { ComponentProps } from './types';

import './TopicTagManager.scss';
import TagDetails from '../Details';

const namesAreEqual = (name: string, name2: string) => name.trim().toLowerCase() === name2.trim().toLowerCase();

const TopicTagManager = (props: ComponentProps) => {
	const { topicId, tags, selectedTag, topic, isCreatingTag } = props;
	const { onCreateTag, onRenameTag, onDeleteTag, onSetSelectedTag, t } = props;

	const [createTagName, setCreateTagName] = React.useState<string | null>(null);
	const [editTag, setEditTag] = React.useState<TagObject | null>(null);
	const [deleteTag, setDeleteTag] = React.useState<TagObject | null>(null);
	const [inputError, setInputError] = React.useState<string | false>();
	const [visibleTag, setVisibleTag] = React.useState<any | null>(null);
	const [automatedTagDrawerVisible, setAutomatedTagVisible] = React.useState(false);

	const wrapperRef = React.useRef<HTMLDivElement>(null);

	const focusTagInput = React.useCallback((id?: string) => {
		const selector = '#topicTagManager' + (id ? 'EditTag' + id : 'NewTagInput');
		(wrapperRef.current!.querySelector(selector) as HTMLInputElement).focus();
	}, []);

	React.useEffect(() => {
		if (editTag) focusTagInput(editTag.id);
	}, [editTag, focusTagInput]);

	React.useEffect(() => {
		if (!isNull(createTagName)) focusTagInput();
	}, [createTagName, focusTagInput]);

	React.useEffect(() => {
		setVisibleTag(selectedTag);
	}, [selectedTag]);

	useUpdateEffect(() => {
		if (!isCreatingTag) setCreateTagName(null);
	}, [isCreatingTag]);

	const checkTagName = React.useCallback((name: string) => {
		if (!name) {
			setInputError("tags.name_too_short");
			return false;
		}
		const tag = find(tags, tag => namesAreEqual(tag.name, name));
		if (tag) {
			setInputError("tags.name_already_exists");
			return false;
		}
		if (name.length > 100) {
			setInputError("tags.name_too_long");
			return false;
		}
		return true;
	}, [tags, setInputError]);

	const onShowAddTag = React.useCallback(() => {
		if (isNull(createTagName)) {
			setInputError(false);
			setEditTag(null);
			setCreateTagName("");
		}
	}, [createTagName]);

	const onShowEditTag = React.useCallback((tag: TagObject) => {
		setInputError(false);
		setCreateTagName(null);
		setEditTag(tag);
	}, []);

	const onTagAdded = React.useCallback((value: string) => {
		const tagName = value.trim().toLowerCase();
		if (!checkTagName(tagName)) return focusTagInput();
		onCreateTag(tagName, topicId!!);
		setInputError(false);
	}, [checkTagName, focusTagInput, onCreateTag, topicId]);

	const onTagRenamed = React.useCallback((tag: TagObject, value: string) => {
		const editedName = value.trim().toLowerCase();
		if (namesAreEqual(tag.name, editedName)) return setEditTag(null);
		if (!checkTagName(editedName)) return focusTagInput(tag.id);
		onRenameTag(tag.id, tag.name, editedName);
		setInputError(false);
		setEditTag(null);
	}, [checkTagName, focusTagInput, onRenameTag]);


	const getDragStyle = React.useCallback((isDragging, draggableStyle) => ({
		...draggableStyle,
		background: isDragging ? 'white' : '',
		"boxShadow": isDragging ? "2px 5px 5px rgba(0, 0, 0, 0.3)" : '',
		"width": isDragging ? "fit-content" : ''
	}), []);

	const showAutomatedTagInfo = React.useCallback((tag: TagObject<"tag_automation">) => {
		setVisibleTag(null);
		onSetSelectedTag(tag);
		setAutomatedTagVisible(true);
	}, [onSetSelectedTag]);



	const getTagContent = React.useCallback((tag: any) => {
		let classes = "list-item";
		if (tag.read_only) return (
			<div key={tag.id} id={`topicTagManagerTag${tag.id}`} className={classes}>
				<div className="list-item-text">{tag.name}</div>
			</div>
		);

		if (editTag && editTag.id === tag.id) classes += " editing";
		const tagButtons = (<>
			<Button id={`topicTagManagerRename${tag.id}`} icon onClick={() => onShowEditTag(tag)}>edit</Button>
			{
				tag.tag_automation &&
				<Button id={`topicTagManagerInfo${tag.id}`} icon onClick={() => showAutomatedTagInfo(tag)}>settings</Button>
			}
			<Button id={`topicTagManagerDelete${tag.id}`} icon onClick={() => setDeleteTag(tag)}>delete</Button>
		</>);

		const tagItemContent =
			editTag && editTag.id === tag.id ?
				<form onSubmit={(ev: any) => { ev.preventDefault(); onTagRenamed(tag, ev.currentTarget.elements[0].value); }}>
					<TextField
						id={`topicTagManagerEditTag${tag.id}`}
						fullWidth
						defaultValue={tag.name}
						onBlur={(ev: any) => {
							const value = ev.currentTarget.value.trim().toLowerCase();
							if (!value) return setEditTag(null);
							onTagRenamed(tag, value);
						}}
						error={!!inputError}
						errorText={inputError ? t(inputError) : ""}
						placeholder={t("tags.edit_tag_placeholder")}
					/>
				</form>
				: <>
					<div className="list-item-text">{tag.tag_automation ? <FontIcon className="list-item-icon">bolt</FontIcon> : ''}{tag.name}</div>
					{tagButtons}
				</>;
		return (
			<Droppable droppableId={`droppable-${tag.id}`} key={`droppable-${tag.id}`}>
				{(provided: DroppableProvided, _: DroppableStateSnapshot) => (
					<div {...provided.droppableProps} ref={provided.innerRef}>
						<Draggable draggableId={tag.id} key={`draggable-${tag.id}`} index={+tag.id}>
							{(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
								<>
									<div
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										ref={provided.innerRef}
										key={tag.id}
										id={`topicTagManagerTag${tag.id}`}
										style={getDragStyle(snapshot.isDragging, provided.draggableProps.style)}
										className={classes}
									>
										{tagItemContent}
									</div>
								</>
							)}
						</Draggable>
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		);
	}, [editTag, t, inputError, onShowEditTag, onTagRenamed, getDragStyle, showAutomatedTagInfo]);

	const newTagInput = React.useMemo(() => {
		if (isNull(createTagName)) return null;

		return (
			<div id="topicTagManagerNewTag" className="list-item item-add">
				<form onSubmit={(ev: any) => { ev.preventDefault(); onTagAdded(ev.currentTarget.elements[0].value); }}>
					<TextField
						id="topicTagManagerNewTagInput"
						disabled={isCreatingTag}
						fullWidth
						onBlur={(ev: any) => {
							const value = ev.currentTarget.value.trim().toLowerCase();
							if (!value) return setCreateTagName(null);
							onTagAdded(value);
						}}
						onChange={val => setCreateTagName(val.toString())}
						value={createTagName}
						error={!!inputError}
						errorText={inputError ? t(inputError) : ""}
						placeholder={t('tags.new_tag_placeholder')}
					/>
				</form>
			</div>
		);
	}, [createTagName, isCreatingTag, inputError, t, onTagAdded]);

	const tagList = React.useMemo(() => (
		<div id="topicTagManagerTagList" className="tag-list">
			{tags.map((tag: TagObject<"tag_automation">) => getTagContent(tag))}
			{newTagInput}
		</div>
	), [getTagContent, newTagInput, tags]);

	const deleteDialog = React.useMemo(() => {
		if (!deleteTag) return null;

		const deleteDialogContent = (
			<div className="remove-dialog-content">
				<FontIcon>delete</FontIcon>
				<div className="remove-dialog-content-title">{t('tags.dialog_confirm_title')}</div>
				<p dangerouslySetInnerHTML={{ __html: t('tags.dialog_confirm_text', { tagName: deleteTag.name }) }}></p>
			</div>
		);
		return <SimpleDialog
			id="topicTagManagerDeleteDialog"
			text=""
			content={deleteDialogContent}
			onCancel={() => setDeleteTag(null)}
			onAccept={() => {
				onDeleteTag(deleteTag.id);
				setDeleteTag(null);
			}}
			acceptText={t('tags.dialog_remove_btn')}
			acceptColor="red"
		/>;
	}, [deleteTag, t, onDeleteTag]);

	const noTopicMessage = React.useMemo(() => (
		<div id="noTopicMessage">
			<div className="no-topic-message-title">{t('tags.no_topic_title')}</div>
			<p>{t('tags._no_topic_message')}</p>
		</div>
	), [t]);

	const showAddTagButton = topic && (topic.is_general || !topic.read_only);

	const content = topicId && topic ? (
		<div className="tags-content">
			<div className="tag-title">{t('tags.section_title')}</div>
			{showAddTagButton ?
				<Button id="topicTagManagerAddTag" className="add-new-tag-btn" flat onClick={onShowAddTag}><span>+</span>{t('tags.add_tag')}</Button>
				: null}
			{tagList}
			{deleteDialog}
			<TagDetails
				isVisible={automatedTagDrawerVisible}
				tag={visibleTag}
				setVisible={setAutomatedTagVisible} />
		</div>
	) : noTopicMessage;

	return (
		<div id="topicTagManager" ref={wrapperRef}>
			{content}
		</div>
	);
};

export default withT(React.memo(TopicTagManager));
