import { Model, attr, oneToOne } from "redux-orm";
import { createOrmReducer, ReducerOrm } from "lib/store/reducer";

import { operators } from "./actions";
import { TagAutomationFields, TagAutomationData, TagAutomationModel, TagAutomationInstance } from "./types";

export class TagAutomation extends Model<typeof TagAutomation, TagAutomationFields> {
	static get modelName() { return 'TagAutomation' as const; }
	static get idAttribute() { return 'id' as const; }
	static get fields() {
		return {
			id: attr(),
			enabled: attr(),
			definition: attr(),
			tag: oneToOne('Tag', 'tag_automation')
		};
	}

	static reducer: ReducerOrm<TagAutomationModel> = createOrmReducer<TagAutomationModel>(operators);

	static parse(tagAutomationData: TagAutomationData): TagAutomationInstance {
		return this.upsert(tagAutomationData);
	}
}
