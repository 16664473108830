import React from 'react';
import { Button, SelectField, SelectionControl, TextField } from 'lib/imports/react-md';
import { withT } from 'lib/i18n';
import lucene from 'lucene';
import XRegExp from 'xregexp';

import { checkLuceneQueryValue } from 'lib/luceneValidator';
import { differenceBy, find, isEmpty } from 'lib/imports/lodash';
import { ScopeSocial, SocialExpressionsType, isHashtagScope, isMentionsScope } from 'class/Feed';

import FocusFeedDefinitionSocialInstagramAccount from 'components/Focus/Feed/Definition/Layout/Sections/SocialContent/SocialExpression/InstagramAccount';
import FocusFeedDefinitionSocialInstagramAccountsList from 'components/Focus/Feed/Definition/Layout/Sections/SocialContent/SocialExpression/InstagramAccountsList';
import { ComponentProps } from './types';
import './FocusFeedDefinitionSocialExpression.scss';

const isSensitiveQuery = (query: string) => query.match('sen:');

const isValidQuery = (query: string, scope: ScopeSocial[]) => {
	if (query.trim() === "") return true;
	if (!checkLuceneQueryValue(query)) return false;
	else if ((isMentionsScope(scope) || isHashtagScope(scope)) && !query.match(/^$|(^(?:\b\S{2,}\b\s*)+$)/)) return false;
	/*
		\p{L} matches a single code point in the category "letter".
		\p{N} matches any kind of numeric character in any script.
		\p{Z} or \p{Separator}: any kind of whitespace or invisible separator
	*/
	else if (!query.match(XRegExp('^$|[\\p{L}\\p{N}"\\p{Z}]{2,}'))) return false;
	else
		try {
			// Check lucene syntax
			const results = lucene.parse(query);
			if (queryHasField(results) && !isSensitiveQuery(query)) return false;
			else return true;
		} catch (err) {
			return false;
		}
};

const queryHasField =(parsedQuery: any) => {
	if (parsedQuery.field) return parsedQuery.field !== '<implicit>';
	if (parsedQuery.left && queryHasField(parsedQuery.left)) return true;
	if (parsedQuery.right && queryHasField(parsedQuery.right)) return true;
	return false;
};

const FocusFeedDefinitionSocialExpression = (props: ComponentProps) => {
	const { expression, expressionKey, index, scopeMenuItems, instagramAccounts, definitionInstagramAccounts, t } = props;
	const { onSetExpression, onInstagramAccountToggled, onRemoveExpression } = props;
	const { enabled, scope, error, q: query } = expression;

	const inputRef: React.RefObject<any> = React.useRef();
	const [queryValue, setQueryValue] = React.useState(query);
	const [scopeValue, setScopeValue] = React.useState(scope);

	React.useEffect(() => {
		if (isEmpty(query)) inputRef.current.focus();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		setQueryValue(query);
	}, [query]);

	const isMainExpression = expressionKey === "main";
	const isExcludeExpression = expressionKey === "exclude_expressions";

	const selectedDefinitionInstagramAccount = find(definitionInstagramAccounts, { linkedExpression: { type: expressionKey, index } });
	const selectedInstagramAccount = (selectedDefinitionInstagramAccount && instagramAccounts) ? find(instagramAccounts, { id: selectedDefinitionInstagramAccount.id }) : undefined;
	const instagramAccountsToList = instagramAccounts ? differenceBy(instagramAccounts, definitionInstagramAccounts, 'id') : null;
	const shouldShowInstagramAccounts = isMentionsScope(scope) && !isExcludeExpression && !selectedInstagramAccount;

	const onChangeScope = React.useCallback((selectedScope: React.ReactText) => {
		const scopes = selectedScope.toString().split(',');
		onSetExpression(expressionKey, index, { scope: scopes as ScopeSocial[] });
		setScopeValue(scopes as ScopeSocial[]);
	}, [expressionKey, index, onSetExpression]);

	const onBlurHandler = React.useCallback((event: any) => {
		const query = event.target.value;
		onSetExpression(expressionKey, index, { q: query, error: isValidQuery(query, scopeValue as ScopeSocial[]) ? undefined : true });
	}, [expressionKey, index, onSetExpression, scopeValue]);

	const enableCheckbox = React.useMemo(() => (
		<SelectionControl
			id={`focusFeedDefinitionSocialExpressionEnabled${expressionKey}${index}`}
			name={`focusFeedDefinitionSocialExpressionEnabled${expressionKey}${index}`}
			aria-label={`focusFeedDefinitionSocialExpressionEnabled${expressionKey}${index}`}
			className={`definition-expression-enabled ${enabled ? "checkbox-green" : ''} `}
			type="checkbox"
			checked={enabled}
			onChange={() => onSetExpression(expressionKey, index, { enabled: !enabled })}
		/>
	), [enabled, expressionKey, index, onSetExpression]);

	const scopeSelector = React.useMemo(() => (
		<SelectField
			id={`focusFeedSocialDefinitionExpressionScope${expressionKey}${index}`}
			className="definition-expression-scope"
			menuItems={scopeMenuItems}
			onChange={onChangeScope}
			value={scope.join(',')}
			disabled={!enabled}
		/>
	), [enabled, expressionKey, index, onChangeScope, scope, scopeMenuItems]);

	const queryInput = React.useMemo(() => {
		if (selectedDefinitionInstagramAccount && selectedInstagramAccount) {
			return <FocusFeedDefinitionSocialInstagramAccount
				account={selectedInstagramAccount}
				removable={true}
				onSelected={() => onInstagramAccountToggled!({ id: selectedInstagramAccount.id, screen_name: selectedInstagramAccount.screen_name, linkedExpression: { type: expressionKey, index } })}
			/>;
		} else if (!selectedDefinitionInstagramAccount) {
			return <TextField
				id={`focusFeedSocialExpressionQuery${expressionKey}${index}`}
				className={`definition-expressions-query ${error ? 'wrong-query' : ''}`}
				fullWidth={false}
				value={queryValue}
				error={error}
				onChange={value => setQueryValue(value.toString())}
				onBlur={onBlurHandler}
				disabled={!enabled}
				ref={inputRef}
			/>;
		} else {
			return <></>;
		}
	}, [enabled, error, expressionKey, index, onBlurHandler, queryValue, onInstagramAccountToggled, selectedDefinitionInstagramAccount, selectedInstagramAccount]);

	const removeExpressionButton = React.useMemo(() => (!isMainExpression && onRemoveExpression ?
		(
			<Button
				id={`focusFeedSocialExpressionRemove${expressionKey}${index}`}
				className='definition-expression-remove-button'
				icon
				onClick={() => onRemoveExpression(expressionKey as Exclude<typeof expressionKey, 'main'>, index)}
			>
				delete
			</Button>
		) : null
	), [expressionKey, index, isMainExpression, onRemoveExpression]);

	let className = 'social-definition-expression content-expression';
	if (isMainExpression) className += " main-expression";
	else if (index === 0) className += " is-first-expression";
	className += enabled ? " enabled" : " disabled";
	return (
		<div className={className}>
			{enableCheckbox}
			{isMainExpression ?
				<span className="definition-expression-label">{t('feed.form.label.main_expression_social_media_react')}</span> :
				<span className="definition-expression-label">{t('feed.form.or')}</span>
			}
			{scopeSelector}
			{queryInput}
			{removeExpressionButton}
			{shouldShowInstagramAccounts ?
				<FocusFeedDefinitionSocialInstagramAccountsList
					instagramAccounts={instagramAccountsToList}
					expressionKey={expressionKey as SocialExpressionsType}
					index={index}
				/>
				: null}
		</div>
	);
};

export default React.memo(withT(FocusFeedDefinitionSocialExpression));
