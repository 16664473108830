import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { map } from 'lib/imports/lodash';

import { State } from 'store/types';
import { FeedObject } from 'store/entities/Feed';
import FeedSelectors from 'store/entities/Feed/selectors';
import fletcherSelectors from 'store/fletcher/selectors';

import { DispatchProps, StateProps, OwnProps } from './types';
import NewsletterEditFormPrintCriteria from './NewsletterEditFormPrintCriteria';

const mapStateToProps = (state: State, { feedIds }: OwnProps): StateProps => {
	let selectedFeeds: FeedObject[] = [];
	if (!fletcherSelectors.isFocusFetched(state)) return { selectedFeeds };
	selectedFeeds = map(feedIds, feedId => FeedSelectors.get(state, feedId)!);
	return { selectedFeeds };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterEditFormPrintCriteria);
