import { Action, actionCreatorFactory } from 'typescript-fsa';
import { ApiTags } from './reducers';

const actionCreator = actionCreatorFactory('@@article/categorization');

type Payloads = {
	FetchTags: void;
	FetchTagsSuccess: { tags: ApiTags };
	FetchTagsError: { error: object };
}

export type Actions = {
	FetchTags: Action<Payloads["FetchTags"]>;
	FetchTagsSuccess: Action<Payloads["FetchTagsSuccess"]>;
	FetchTagsError: Action<Payloads["FetchTagsError"]>;
}

export const operators = {
	fetchTags: actionCreator<Payloads["FetchTags"]>('FETCH_TAGS', { api: 'start' }),
	fetchTagsSuccess: actionCreator<Payloads["FetchTagsSuccess"]>('FETCH_TAGS_SUCCESS', { api: 'success' }),
	fetchTagsError: actionCreator<Payloads["FetchTagsError"]>('FETCH_TAGS_ERROR', { api: 'error' })
};
