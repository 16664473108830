import { all } from 'redux-saga/effects';

import sessionSagas from './session/sagas';
import profileSagas from './profile/sagas';
import facebookSagas from './facebook/sagas';
import notificationsSagas from './notifications/sagas';

export default function* sagas() {
	yield all([
		sessionSagas(),
		profileSagas(),
		facebookSagas(),
		notificationsSagas()
	]);
}
