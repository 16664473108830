import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import { operators } from 'store/ui/newsletter/list';
import profileSelectors from 'store/app/profile/selectors';
import newsletterSelectors from 'store/ui/newsletter/list/selectors';

import NewsletterList from './NewsletterList';

const mapStateToProps = (state: State): StateProps => ({
	user: profileSelectors.getUserInstance(state)!,
	newsletters: newsletterSelectors.getNewsletters(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onToggleNewsletterEnabled: id => dispatch(operators.toggleNewsletterEnabled({ id })),
	onRemoveNewsletter: id => dispatch(operators.removeNewsletter({ id }))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterList);
