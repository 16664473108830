import React, { useCallback, useMemo } from 'react';
import { Checkbox, Collapse, FontIcon } from 'react-md';
import { withT } from 'lib/i18n';

import LinkedBrand from 'components/common/LinkedBrand';
import { ComponentProps } from './types';

import './FocusFeedDefinitionInformation.scss';
import featureFlags from 'lib/featureFlags';

const FocusFeedDefinitionInformation = (props: ComponentProps) => {
	const { user, linkedBrandId, linkedBrandList, brand_sync, sentiment_check, showFeedLinkedBrands = false, showSentimentCheck = true, type, t } = props;
	const { onAddLinkedBrand, onSetBrandSync, onSetSentimentCheck, getBrandIsCrawled } = props;

	const showLinkedBrand = user && user.role !== 'user' && showFeedLinkedBrands;
	const showSentimentCheckbox = user && user.role !== 'user' && showSentimentCheck && featureFlags.isEnabled('sentiment');
	const rainbowFeatureFlag = featureFlags.isEnabled('rainbow-query-link');

	const [specificationCollapse, setSpecificationCollapse] = React.useState(false);
	const [linkedBrandsResult, setLinkedBrandResult] = React.useState(linkedBrandList);

	const onFetchLinkedBrand = useCallback((query: string) => {
		setLinkedBrandResult(linkedBrandList.filter(brand => brand.name.toLowerCase().includes(query.toLowerCase())));
	}, [setLinkedBrandResult, linkedBrandList]);

	const isRainbowCheckboxEnabled = useMemo(() => {
		if (!linkedBrandId || !showLinkedBrand || type === 'social') return false;
		return getBrandIsCrawled(linkedBrandId);
	}, [linkedBrandId, showLinkedBrand, type, getBrandIsCrawled]);

	const linkedBrand = useMemo(() => {
		return showLinkedBrand && <>
			<div>
				<span className="definition-text">{t('feed.form.label.linked_brand')}</span>
				<LinkedBrand
					linkedBrandsResult={linkedBrandsResult}
					linkedBrandId={linkedBrandId}
					linkedBrandList={linkedBrandList}
					onFetchLinkedBrand={onFetchLinkedBrand}
					onResetLinkedBrand={() => {
						setLinkedBrandResult(linkedBrandList);
						onSetBrandSync(false);
					}}
					onAddLinkedBrand={brand => {
						onAddLinkedBrand(brand);
						if (rainbowFeatureFlag && type === 'social' && brand.id && brand.name) onSetBrandSync(true);
					}} />
			</div>
			{rainbowFeatureFlag ? <Checkbox
				id="focusFeedDefinitionLinkedBrandCheckbox"
				name="focusFeedDefinitionLinkedBrandCheckbox"
				disabled={!isRainbowCheckboxEnabled}
				className="definition-checkbox"
				label={t('feed.form.label.brand_sync')}
				checked={brand_sync}
				onChange={checked => onSetBrandSync(checked)}
			/> : null}
		</>;
	}, [showLinkedBrand, t, linkedBrandsResult, linkedBrandId, linkedBrandList, onFetchLinkedBrand, rainbowFeatureFlag,
		isRainbowCheckboxEnabled, brand_sync, onSetBrandSync, type, onAddLinkedBrand]);

	const sentiment = useMemo(() => {
		return showSentimentCheckbox ? <Checkbox
			id="focusFeedDefinitionSentimentCheckbox"
			name="focusFeedDefinitionSentimentCheckbox"
			className="definition-checkbox"
			label={t('feed.form.label.sentiment_check')}
			checked={sentiment_check}
			onChange={checked => onSetSentimentCheck(checked)}
		/> : null;
	}, [sentiment_check, showSentimentCheckbox, onSetSentimentCheck, t]);

	return showLinkedBrand || showSentimentCheckbox ?
		<div id="focusFeedDefinitionLinkedBrand" className="definition-section">
			<div className="definition-section-header">
				<div className="definition-header-title" onClick={() => setSpecificationCollapse(!specificationCollapse)}>
					{
						specificationCollapse ?
							<FontIcon className="definition-header-title-toggle" >keyboard_arrow_right</FontIcon> :
							<FontIcon className="definition-header-title-toggle">keyboard_arrow_down</FontIcon>
					}
					<span className="definition-header-title-text">
						{t('feed.form.header_linked_brand')}
					</span>
				</div>
			</div>

			<Collapse collapsed={specificationCollapse}>
				<div className="definition-section-content definition-linked-brand">
					{linkedBrand}
					{sentiment}
				</div>
			</Collapse>
		</div>
		: null;
};

export default withT(React.memo(FocusFeedDefinitionInformation));
