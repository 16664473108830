import { createSelector } from 'reselect';

import { forEach, values, reduce } from 'lib/imports/lodash';
import { State } from 'store/types';

import { InstagramAccountObject } from 'store/entities/InstagramAccount/types';
import TenantSelectors from 'store/entities/Tenant/selectors';
import profileSelectors from 'store/app/profile/selectors';
import facebookSelectors from 'store/app/facebook/selectors';

export default {
	getInstagramAccountsToSelect: createSelector((state: State) => state, state => {
		if (!facebookSelectors.isLoaded(state)) return null;
		const tenantFacebookUsers = TenantSelectors.getFacebookUserInstances(state, profileSelectors.getTenantId(state));
		const instagramAccountsIndexed = reduce(tenantFacebookUsers,
			(result: { [key: string]: InstagramAccountObject<'facebookUsers'> }, facebookUser) => {
				forEach(facebookUser.instagramAccounts.toModelArray(), instagramAccount => {
					if (!result[instagramAccount.id]) result[instagramAccount.id] = {
						...instagramAccount.ref,
						facebookUsers: instagramAccount.facebookUsers.toRefArray()
					};
				});
				return result;
			}, {});
		return values(instagramAccountsIndexed);
	})
};
