import React from 'react';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom';

import NewsletterDraftEdit from './Edit';
import NewsletterDraftSend from './Send';
import FletcherLoader from 'components/common/Fletcher/Loader';

import './NewsletterDraft.scss';

type NewsletterDraftRouteProps = RouteComponentProps<{ id: string, timestamp: string }>

const NewsletterDraft = () => {
	return (
		<div id="newsletterDraftContainer">
			<Switch>
				<Route path="/newsletter/:id(\d+)/edit/:timestamp(\d{10})" render={(draftProps: NewsletterDraftRouteProps) => (
					<FletcherLoader resource="newsletters">
						<NewsletterDraftEdit {...draftProps.match.params} />
					</FletcherLoader>
				)} />

				<Route path="/newsletter/:id(\d+)/send/:timestamp(\d{10})" render={(draftProps: NewsletterDraftRouteProps) => (
					<FletcherLoader resource="newsletters">
						<NewsletterDraftSend {...draftProps.match.params} />
					</FletcherLoader>
				)} />

				<Route render={() => (<Redirect to="/newsletter" />)} />
			</Switch>
		</div>
	);
};

export default NewsletterDraft;
