import React from 'react';
import { allEvents } from './const';
import { ComponentProps } from './types';
import { keys } from 'lib/imports/lodash';
import { FontIcon, TextField } from 'lib/imports/react-md';
import { Event as AuditEvent } from 'store/ui/audit/filters/types';
import EventCheckbox from '../EventPickerCheckbox/EventPickerCheckbox';

import './EventPickerContent.scss';

export const EventPickerContent = ({ events, onEventsChanged }: ComponentProps) => {

	const [eventItems, setEventItems] = React.useState(allEvents);
	const [changedEvents, setChangedEvents] = React.useState<AuditEvent[]>([]);

	const isChecked = React.useMemo(() => (event: AuditEvent) => events.includes(event), [events]);

	const onDone = () => {
		onEventsChanged(changedEvents);
	};

	const onClear = () => {
		setChangedEvents([]);
		onEventsChanged([]);
	};

	const onSearchChangeHandler = (value: number | string, event: Event) => {
		event.stopPropagation();
		filterEventsBy(value + '');
	};

	const addEvent = React.useMemo(() => (e: AuditEvent) => setChangedEvents([...changedEvents, e]), [changedEvents]);
	const removeEvent = React.useMemo(() => (e: AuditEvent) => setChangedEvents(changedEvents.filter(event => event !== e)), [changedEvents]);
	const onEventSelected = React.useMemo(
		() => (e: AuditEvent) => changedEvents.find(event => event === e) ? removeEvent(e) : addEvent(e),
		[addEvent, changedEvents, removeEvent]
	);

	const filterEventsBy = (value: string) => {
		let result = {};
		keys(allEvents)
			.forEach(
				eventGroupKey => Object.assign(result, {
					[eventGroupKey]: allEvents[eventGroupKey as keyof typeof allEvents].filter(eventItem => eventItem.name.toLowerCase().includes(value.toLowerCase()))
				})
			);
		setEventItems(result as typeof allEvents);
	};

	const stopPropagation = React.useCallback((event: React.MouseEvent<HTMLElement>) => event.stopPropagation(), []);

	const eventsList = React.useMemo(() => (
		<>
			{
				keys(eventItems).map(eventGroupKey => (
					<div key={`${eventGroupKey}-wrapper`} className={`${eventGroupKey}-wrapper`}>
						{(eventItems[eventGroupKey as keyof typeof eventItems].length > 0) &&
							<div key={eventGroupKey} className="audit-events-group">
								<div className="audit-events-group-header">{eventGroupKey !== 'Feeds_Results' ? eventGroupKey : 'Feeds Results'}</div>
								{eventItems[eventGroupKey as keyof typeof eventItems].map(eventItem => (
									<EventCheckbox key={`auditEventsGroupItem-${eventItem.event}`} eventItem={eventItem} isChecked={isChecked} onClick={onEventSelected} />
								))}
							</div>
						}
					</div>
				))
			}
		</>
	), [eventItems, isChecked, onEventSelected]);

	return <div key={`audits-events-dialog-content`} className="audits-events-dialog-content">
		<div className="audits-events-dialog-content-header">
			<TextField
				type="text"
				placeholder="Search"
				onClick={stopPropagation}
				id="audit-events-search-input"
				onChange={onSearchChangeHandler}
				className="audit-events-search-input"
				leftIcon={<FontIcon>search</FontIcon>}
			/>
		</div>
		<div className="audits-events-dialog-content-body" onClick={e => e.stopPropagation()}>
			{eventsList}
		</div>
		<div className="audits-events-dialog-content-footer">
			<button className="buttons-filter clear-button" type="button" onClick={onClear}>
				CLEAR
			</button>
			<button className="buttons-filter done-button" type="button" onClick={() => onDone()}>
				DONE
			</button>
		</div>
	</div>;
};
