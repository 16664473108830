import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import Filters from './Filters';
import { State } from 'store/types';
import { StateProps, DispatchProps } from './types';

import { operators as resultsOperators } from 'store/search/results';
import {operators as directOperators} from 'store/ui/report/direct';

const mapStateToProps = (state: State): StateProps => ({
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onFetchSearch: () => dispatch(resultsOperators.fetchSearch()),
	toggleQuickReport: (enabled: boolean) => dispatch(directOperators.toggleQuickReport(enabled))
});
export default connect(mapStateToProps, mapDispatchToProps)(Filters);
