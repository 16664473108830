import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { Facet } from 'class/Facet';
import { State } from 'store/types';
import TopicSelectors from 'store/entities/Topic/selectors';
import { operators as filterOperators } from 'store/search/filters';
import { operators as facetOperators } from 'store/search/facets';
import { selectors as facetSelectors } from 'store/search/facets/selectors';
import { selectors as filtersSelectors } from 'store/search/filters/selectors';
import SearchFacetsTopicTagsGroup from './SearchFacetsTopicTagsGroup';
import { DispatchProps, StateProps } from './types';

const mapStateToProps = (state: State): StateProps => ({
	isGroupOpened: facetSelectors.isFacetGroupOpened(state, Facet.tagsGroupKey),
	facetTags: facetSelectors.getFacetCounters(state, Facet.tagsGroupKey),
	filteredTags: filtersSelectors.getFacetFilters(state, Facet.tagsGroupKey),
	topics: TopicSelectors.getWithTags(state),
	isLoadingFacets: facetSelectors.isLoading(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onTopicFilterToggled: topic => dispatch(filterOperators.toggleFilteredTopic({ topic })),
	onTagFilterToggled: topicTagId => dispatch(filterOperators.toggleFacetFilter({ facetGroupKey: Facet.tagsGroupKey, facetFilter: { key: topicTagId } })),
	onToggleFacetGroupOpened: () => dispatch(facetOperators.toggleFacetsGroupOpened({ groupKey: Facet.tagsGroupKey })),
	onRemoveGroupFilter: () => dispatch(filterOperators.removeFacetFilterGroup({ facetGroupKey: Facet.tagsGroupKey })),
	onOpenShowMore: () => dispatch(facetOperators.setShowMoreFacetGroupKey({ facetGroupKey: Facet.tagsGroupKey }))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchFacetsTopicTagsGroup);
