import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State } from 'store/types';
import { DispatchProps, StateProps } from './types';
import { operators } from 'store/ui/report/setup/';
import profileSelectors from 'store/app/profile/selectors';

import ReportSetupFeedsSummary from './ReportSetupFeedsSummary';
import selectors from 'store/ui/report/setup/selectors';

const mapStateToProps = (state: State): StateProps => ({
	type: selectors.getType(state),
	period: selectors.getPeriod(state),
	feeds: selectors.getFeeds(state),
	feedsLoaded: selectors.areFeedsLoaded(state),
	feedClippings: selectors.getFeedClippings(state),
	user: profileSelectors.getUser(state)!
});


const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onQueueReport: () => dispatch(operators.queueReport())
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSetupFeedsSummary);
