import reject from 'lodash/reject';

import { createReducer, ReducerHandlers } from 'lib/store/reducer';
import { Actions, operators } from './actions';
import { FocusObject } from 'class/Focus';
import { FeedObject, LinkedBrand } from 'class/Feed';

export type State = {
	readonly focus: FocusObject | null
	readonly feeds: FeedObject[] | null
	readonly loadingUpdate: boolean
	readonly loadingFeeds: boolean
	readonly linkedBrandList: LinkedBrand[]
};

export const INITIAL_STATE: State = {
	focus: null,
	feeds: null,
	loadingUpdate: false,
	loadingFeeds: false,
	linkedBrandList: []
};

const reducerHandlers: ReducerHandlers<State> = {
	fetchFocus: (state): State => ({
		...state,
		focus: INITIAL_STATE['focus'],
		feeds: INITIAL_STATE['feeds']
	}),
	fetchFocusSuccess: (state, { payload: { focus, feeds } }: Actions["FetchFocusSuccess"]): State => ({
		...state,
		focus,
		feeds
	}),
	fetchFocusError: (state): State => ({
		...state,
		focus: INITIAL_STATE['focus'],
		feeds: INITIAL_STATE['feeds']
	}),
	renameFocus: (state): State => ({
		...state,
		loadingUpdate: true
	}),
	renameFocusSuccess: (state, { payload: { focus } }: Actions["RenameFocusSuccess"]): State => ({
		...state,
		loadingUpdate: false,
		focus
	}),
	renameFocusError: (state): State => ({
		...state,
		loadingUpdate: false
	}),
	removeFeed: (state): State => ({
		...state,
		loadingFeeds: true
	}),
	removeFeedSuccess: (state, { payload: { id } }: Actions["RemoveFeedSuccess"]): State => ({
		...state,
		loadingFeeds: false,
		feeds: reject(state.feeds, { id })
	}),
	removeFeedError: (state): State => ({
		...state,
		loadingFeeds: false
	}),
	removeFocus: (state): State => ({
		...state,
		loadingUpdate: true
	}),
	removeFocusSuccess: (): State => ({
		...INITIAL_STATE
	}),
	removeFocusError: (state): State => ({
		...state,
		loadingUpdate: false
	})
};

export default createReducer<State>(INITIAL_STATE, reducerHandlers, operators);
