import React from "react";
import queryString from 'query-string';
import axios from 'axios';
import { CircularProgress } from 'lib/imports/react-md';
import { useNewsletterParser } from "lib/components/hooks/newsletter";
import { NewsletterDraftSection } from "store/ui/newsletter/draft/edit/types";

import './NewsletterHtml.scss';

type ComponentProps = {
	id: string;
	sendTime: string;
};

const NewsletterHtml = ({ id, sendTime }: ComponentProps) => {
	const [loading, setLoading] = React.useState<boolean | null>(true);

	const [newsletterContent, parseContent, openSection] = useNewsletterParser();

	React.useEffect(() => {
		(async () => {
			try {
				const apiUrl = process.env.REACT_APP_API_URL;
				const params = queryString.parse(window.location.search);
				const apiCall = `${apiUrl}/v1/newsletter/${id}/html/${sendTime}?${queryString.stringify(params)}`;
				let { data: { response } } = await axios.get(apiCall);

				if (!response) {
					console.error("Some error happened trying to get html from s3");
					setLoading(false);
				}
				parseContent(response);
				setLoading(false);
				const section = window.location.hash.replace('#', '') as NewsletterDraftSection;
				if (section) openSection(section);

			} catch (error) {
				console.error(error);
			}
		})();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const content = React.useMemo(() => {
		if (loading) return <div className="loader"><CircularProgress id="newsletterHtmlLoadingProgress"/></div>;
		if (newsletterContent) return newsletterContent;
		return null;
	}, [loading, newsletterContent]);

	return <div id="newsletterHtml">{content}</div>;
};

export default React.memo(NewsletterHtml);
