import ActionCreator, { CrudActions } from "lib/store/actions";
import { Newsletter } from "./model";

const actionCreator = new ActionCreator('@@orm/newsletter');

const crudOperators = actionCreator.crudOperators<Newsletter>();
const addEmailList = actionCreator.operator<{ id: string, listId: string }>('ADD_EMAIL_LIST');
const removeEmailList = actionCreator.operator<{ id: string, listId: string }>('REMOVE_EMAIL_LIST');

export const operators = {
	...crudOperators,
	addEmailList,
	removeEmailList
};

export interface Actions extends CrudActions<Newsletter> {
	AddEmailList: ReturnType<typeof operators.addEmailList>
	RemoveEmailList: ReturnType<typeof operators.removeEmailList>
}
