import moment, { MomentInput } from 'moment';
import isUndefined from 'lodash/isUndefined';
import sum from 'lodash/sum';
import values from 'lodash/values';
import map from 'lodash/map';
import i18n from 'lib/i18n';
import { Settings as UserSettings } from 'class/User';
import { Settings as TenantSettings } from 'class/Tenant';
import { convert, getSymbol, setCurrency } from 'lib/currency';
import { RankObject } from 'class/Document';
import { FeedType } from 'class/Feed';

// Document types must be defined in class document. hardcoded by now

type CounterMode = string;
type Counters = { [counter: string]: { [subcounter: string]: number } };

// internal settings needed
interface Settings {
	locale: UserSettings['locale'];
	valuationMetric: TenantSettings['valuation_metric'];
	timezone: UserSettings['timezone'];
}
const _settings: Settings = {
	locale: 'en-US',
	valuationMetric: 'miv',
	timezone: 'UTC'
};

// set from settings functions
interface UserSettingsParam {
	locale?: UserSettings['locale'];
	timezone?: UserSettings['timezone'];
}
interface TenantSettingsParam {
	valuation_metric?: TenantSettings['valuation_metric'];
	currency?: TenantSettings['currency'];
}
export function setSettings(userSettings: UserSettingsParam, tenantSettings: TenantSettingsParam) {
	setUserSettings(userSettings);
	setTenantSettings(tenantSettings);
}
export async function setUserSettings(userSettings: UserSettingsParam) {
	if (userSettings.locale) {
		_settings.locale = userSettings.locale;
		moment.locale(userSettings.locale.toLowerCase()); // set locale to moment
	}
	if (userSettings.timezone) _settings.timezone = userSettings.timezone;
}
export function setTenantSettings(tenantSettings: TenantSettingsParam) {
	if (tenantSettings.valuation_metric) _settings.valuationMetric = tenantSettings.valuation_metric;
	if (tenantSettings.currency) setCurrency(tenantSettings.currency);
}

// implementations

const _metricNumberRanges = [
	{ divider: 1e6, suffix: 'M', decimal: 1 },
	{ divider: 1e3, suffix: 'K', decimal: 0 }
];

// number formatters
export const number = {

	metric(n: number, decimal?: number): string {
		const numberRange = _metricNumberRanges.find(range => n >= range.divider);
		if (!numberRange) return this.locale(n, decimal);
		const nAbr = n / numberRange.divider;
		return this.locale(nAbr, !isUndefined(decimal) ? decimal : numberRange.decimal) + numberRange.suffix;
	},

	locale(n: number, decimal: number = 2): string {
		if (n === 0) return '0';
		if (Number.isInteger(n)) return n.toLocaleString(_settings.locale);
		n = Number(n);
		return Number(n.toFixed(decimal)).toLocaleString(_settings.locale);
	},

	counter(counters: Counters, mode: CounterMode) {
		const countersMode = _settings.valuationMetric === 'miv' && mode === 'audience' ? 'reach' : mode;
		if (!counters[countersMode]) return this.metric(0);
		const sumValue = sum(values(counters[countersMode]));
		return this.metric(sumValue || 0);
	},

	currencyConversion(valueToConvert: number, date: string, disableK: boolean = false) {
		let value;
		if (disableK) value = ~~(convert(valueToConvert, date));
		else value = this.metric(convert(valueToConvert, date), 0);
		return value;
	},

	brandedCurrency(brandValue: number, documentDate: string, format: 'locale' | 'metric' = 'metric', disableK: boolean = false) {
		const value = this.currencyConversion(brandValue, documentDate, disableK);
		return format === 'locale' ? `${i18n.t(`results.${_settings.valuationMetric}`)}: ${getSymbol()}${value}` : `${getSymbol()}${value}`;
	},

	currency(rank: RankObject, documentDate: string, format: 'locale' | 'metric' = 'metric', disableK: boolean = false) {
		let value;
		if (!rank) return undefined; //TODO (GIS-3281) Remove this
		if (rank[_settings.valuationMetric] === undefined || rank[_settings.valuationMetric] === null) {
			value = '-';
		} else {
			value = this.currencyConversion(rank[_settings.valuationMetric], documentDate, disableK);
		}
		return format === 'locale' ? `${i18n.t(`results.${_settings.valuationMetric}`)}: ${getSymbol()}${value}` : getSymbol() + value;
	},

	singleCurrency(rank: string, format: 'locale' | 'metric' = 'metric') {
		const value = this.metric(+rank, 0);
		return format === 'locale' ? `${i18n.t(`results.${_settings.valuationMetric}`)}: ${getSymbol()}${value}` : getSymbol() + value;
	}
};

// text formatters
export const text = {

	counter(counters: Counters, mode: CounterMode): string {
		const countersMode = _settings.valuationMetric === 'miv' && mode === 'audience' ? 'reach' : mode;
		if (!counters || !counters[countersMode]) return i18n.t('results.sort.' + countersMode) + ': 0';
		const desc = map(counters[countersMode], (value, name) => number.metric(value) + ' ' + i18n.t('result.counters.' + name)).join(' + ');
		return i18n.t('results.sort.' + countersMode) + ': ' + desc;
	},

	singleCounter(counter: string, mode: CounterMode): string {
		const countersMode = _settings.valuationMetric === 'miv' && mode === 'audience' ? 'reach' : mode;
		if (!counter) return i18n.t('results.sort.' + countersMode) + ': 0';
		return i18n.t('results.sort.' + countersMode) + ': ' + counter;
	}

};

// date formatters
export const date = {

	formatTimestamp(timestamp: number, format: string) {
		const mom = _settings.timezone ? moment.unix(timestamp).tz(_settings.timezone) : moment.unix(timestamp);
		return mom.format(format);
	},

	format(dateString: MomentInput, format?: string) {
		const mom = _settings.timezone ? moment(dateString).tz(_settings.timezone) : moment(dateString);
		return mom.format(format);
	},

	doc(dateString: MomentInput, format: string = 'MMM DD') {
		const mom = _settings.timezone ? moment(dateString).tz(_settings.timezone) : moment(dateString); // to local time
		return mom.format(format);
	},

	docFromFormat(dateString: MomentInput, originalFormat: string = 'DD/MM/YYYY', desiredFormat: string = 'MMM DD') {
		return moment(dateString, originalFormat).format(desiredFormat);
	},

	locale(dateString: MomentInput) {
		const mom = _settings.timezone ? moment(dateString).tz(_settings.timezone) : moment(dateString); // to local time
		return mom.format("L");
	},

	localeTitle(dateString: MomentInput) {
		const mom = _settings.timezone ? moment(dateString).tz(_settings.timezone) : moment(dateString); // to local time
		return mom.format("LLLL");
	},

	localeTitleFromFormat(dateString: string, format: string = 'DD/MM/YYYY') {
		return moment(dateString, format).format("LLLL");
	},

	getNewDocumentDate(dateString: MomentInput, feedType: FeedType) {
		const momentDate = moment.tz(moment(dateString).format('YYYY-MM-DD'), 'utc');
		const today = moment().tz('utc');
		if (feedType === "print") {
			momentDate.hour(today.hour()).minute(today.minute()).seconds(today.seconds());
			return moment.tz(momentDate.format('YYYY-MM-DD HH:mm:ss'), 'utc').toDate();
		}
		const hours = momentDate.isSame(today, 'day') ? today.hours() : 12;
		const parsedDate = moment.tz(momentDate.format('YYYY-MM-DD'), 'utc').add(hours, 'h').add(today.minutes(), 'm').add(today.seconds(), 's').add(today.milliseconds(), 'ms');
		return parsedDate.toDate();
	}

};

export default { number, text, date };
