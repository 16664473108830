import { put, all, takeLatest, select, call } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import values from 'lodash/values';
import some from 'lodash/some';
import every from 'lodash/every';
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import trim from 'lodash/trim';
import map from 'lodash/map';

import Mekong from 'lib/ajax/Mekong';

import { State } from 'store/types';
import { operators, Actions } from './actions';
import { TreeUtils, TreeNode } from 'class/Tree';
import { FacetItemHash, ShowMoreFacetItem } from './reducers';
import { FacetItem } from 'class/Facet';

export default function* sagas() {
	yield all([
		takeLatest(operators.toggleItemCollapsed.type, toggleItemCollapsed),
		takeLatest(operators.toggleCollapseAll.type, toggleCollapseAll),
		takeLatest(operators.toggleSelectedFilter.type, toggleSelectedFilter),
		takeLatest(operators.toggleSelectedAll.type, toggleSelectedAll),
		takeLatest(operators.toggleSeeOnlySelected.type, toggleSeeOnlySelected),
		takeLatest(operators.fetchQueriedMedias.type, fetchQueriedMedias)
	]);
}

function* toggleItemCollapsed({ payload: { itemValue } }: Actions["ToggleItemCollapsed"]) {
	let facetItemsHash = yield select((state: State) => state.search.facets.extended.facetItemsHash);
	facetItemsHash = cloneDeep(facetItemsHash);

	facetItemsHash[itemValue].collapsed = !facetItemsHash[itemValue].collapsed;
	yield (put(operators.setFacetItemsHash({ facetItemsHash })));
}

function* toggleCollapseAll({ payload: { collapse } }: Actions["ToggleCollapseAll"]) {
	let facetItemsHash = yield select((state: State) => state.search.facets.extended.facetItemsHash);
	const facetItems = yield select((state: State) => state.search.facets.extended.facetItems);
	facetItemsHash = cloneDeep(facetItemsHash);

	const parentValues = TreeUtils.getRootNodesAttributeValues(facetItems, 'value');
	forEach(parentValues, parentValue => {
		facetItemsHash[parentValue].collapsed = collapse;
	});
	yield (put(operators.setFacetItemsHash({ facetItemsHash })));
}

function* toggleSelectedFilter({ payload: { node, isSelected } }: Actions["ToggleSelectedFilter"]) {
	let facetItemsHash = yield select((state: State) => state.search.facets.extended.facetItemsHash);
	let seeOnlySelected = yield select((state: State) => state.search.facets.extended.seeOnlySelected);
	const facetItems = yield select((state: State) => state.search.facets.extended.facetItems);
	facetItemsHash = cloneDeep(facetItemsHash);

	if (isSelected) _disableItemSelected(facetItemsHash, node);
	else _enableItemSelected(facetItemsHash, node);
	if (isEmpty(filter(values(facetItemsHash), hashItem => hashItem.selected))) seeOnlySelected = false;

	yield (put(operators.setAll({ facetItemsHash, facetItems, seeOnlySelected })));
}

function* toggleSelectedAll({ payload: { allSelected } }: Actions["ToggleSelectedAll"]) {
	let facetItemsHash = yield select((state: State) => state.search.facets.extended.facetItemsHash);
	let seeOnlySelected = yield select((state: State) => state.search.facets.extended.seeOnlySelected);
	const facetItems = yield select((state: State) => state.search.facets.extended.facetItems);
	facetItemsHash = cloneDeep(facetItemsHash);

	const parentValues = TreeUtils.getRootNodesAttributeValues(facetItems, 'value');
	if (allSelected) {
		forEach(parentValues, parentValue => { facetItemsHash[parentValue].selected = false; });
		seeOnlySelected = false;
	} else
		forEach(keys(facetItemsHash), itemValue => {
			if (includes(parentValues, itemValue)) facetItemsHash[itemValue].selected = true;
			else facetItemsHash[itemValue].selected = false;
		});

	yield (put(operators.setAll({ facetItemsHash, facetItems, seeOnlySelected })));
}

function* toggleSeeOnlySelected({ payload }: Actions["ToggleSeeOnlySelected"]) {
	const seeOnlySelected = yield select((state: State) => state.search.facets.extended.seeOnlySelected);

	yield (put(operators.setSeeOnlySelected({ seeOnlySelected: !seeOnlySelected })));
}

function* fetchQueriedMedias({ payload: { query } }: Actions["FetchQueriedMedias"]) {
	query = trim(query);
	if (isEmpty(query)) return yield put(operators.setQueriedMedias({ medias: [] }));

	const [onlineMedias, printMedias] = yield all([
		call(Mekong.get, '/v1/mediaSource/online/autocomplete', { params: { query: query + '*', response_type: "autocompleter"} }),
		call(Mekong.post, '/v1/print/medias/search', { data: { search: query } })
	]);

	const APImedias: Array<{ id: string, name: string, url: string, type: string }> = [
		...map(onlineMedias, media => ({ ...media, type: "news" })),
		...map(printMedias, media => ({ ...media, name: media.description + ' - ' + media.country, type: "print" }))
	];

	APImedias.sort((a, b) => {
		if (a.name < b.name) return -1;
		if (a.name > b.name) return 1;
		return 0;
	});

	const medias: FacetItem[] = map(APImedias, APImedia => ({ key: APImedia.id, name: APImedia.name, detail: APImedia.url, type: APImedia.type }));
	yield put(operators.setQueriedMedias({ medias }));
}

const _disableItemSelected = (facetItemsHash: FacetItemHash, node: TreeNode<ShowMoreFacetItem>) => {
	const parentItemsValues = TreeUtils.getNodeParentsAttributeValues(node, 'value');
	facetItemsHash[node.data.value].selected = false;
	if (node.parent) {
		if (some(parentItemsValues.map(parentItemValue => facetItemsHash[parentItemValue].selected), Boolean))
			_disableItemSelectedEnablingChildsNotInList(facetItemsHash, node.parent, [node.data.value, ...parentItemsValues]);
	}
};

const _disableItemSelectedEnablingChildsNotInList = (facetItemsHash: FacetItemHash, node: TreeNode<ShowMoreFacetItem>, ignoreItemsValuesList: string[]) => {
	const childItemsValues = TreeUtils.getNodeChildrenAttributeValues(node, 'value');
	facetItemsHash[node.data.value].selected = false;
	forEach(childItemsValues, childItemValue => {
		if (!includes(ignoreItemsValuesList, childItemValue)) facetItemsHash[childItemValue].selected = true;
	});
	if (node.parent && facetItemsHash[node.parent.data.value].selected) {
		_disableItemSelectedEnablingChildsNotInList(facetItemsHash, node.parent, [node.parent.data.value, ...ignoreItemsValuesList]);
	}
};

const _disableItemChildsSelected = (facetItemsHash: FacetItemHash, node: TreeNode<ShowMoreFacetItem>) => {
	if (node.children)
		forEach(node.children, childNode => {
			facetItemsHash[childNode.data.value].selected = false;
			_disableItemChildsSelected(facetItemsHash, childNode);
		});
};

const _enableItemSelected = (facetItemsHash: FacetItemHash, node: TreeNode<ShowMoreFacetItem>) => {
	facetItemsHash[node.data.value].selected = true;
	_disableItemChildsSelected(facetItemsHash, node);
	if (node.parent) {
		const parentChildItemsValues = TreeUtils.getNodeChildrenAttributeValues(node.parent, 'value');
		if (every(parentChildItemsValues.map(parentChildValue => facetItemsHash[parentChildValue].selected))) _enableItemSelected(facetItemsHash, node.parent);
	}
};
