import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { operators } from 'store/focus/feed/definition/social';
import { operators as facebookOperators } from 'store/app/facebook';

import { DispatchProps } from './types';
import FocusFeedDefinitionSocialInstagramAccountsList from './FocusFeedDefinitionSocialInstagramAccountsList';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onToggleInstagramAccount: account => dispatch(operators.toggleDefinitionInstagramAccount({ account })),
	onOpenFacebookDialog: () => dispatch(facebookOperators.setDialogMode('instagram'))
});

export default connect(mapStateToProps, mapDispatchToProps)(FocusFeedDefinitionSocialInstagramAccountsList);
