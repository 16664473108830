import React from 'react';

import { map, reduce, orderBy, omit } from 'lib/imports/lodash';
import { Button, SelectionControl, FontIcon, Collapse } from 'lib/imports/react-md';

import { withT } from 'lib/i18n';
import { Tenant } from 'class/Tenant';
import { ComponentProps } from './types';
import { ProductType } from 'class/Feed';
import Autocompleter from 'components/common/Input/Autocompleter';

type ProductTypeItem = { id: string; text: string };
type ProductTypeIndexedItems = { [id: string]: ProductTypeItem };

const FocusFeedDefinitionPrintSectionProductType = (props: ComponentProps) => {
	const { productTypes, tenant, t } = props;
	const { onAddExpression, onUpdateExpression, onRemoveExpression } = props;

	const [collapse, setCollapse] = React.useState<boolean>(false);

	// prepare static translations for the autocompleter, this reference should never change
	const productTypeItems: ProductTypeIndexedItems = React.useMemo(() => {
		const tenantObject: Tenant = new Tenant(tenant!);

		return reduce(tenantObject.getAvailableCategories(), (result: ProductTypeIndexedItems, id: string) => {
			const lastTranslation = t(`filters.categories.${id}`);
			if (lastTranslation.match(/\(adv\)$/)) return result; // exclude duplicated categories with "(adv)" in the end

			const parts = [];
			if (id.length > 2) parts.push(2);
			if (id.length > 4) parts.push(4);
			const translations = map(parts, sliceIndex => t(`filters.categories.${id.slice(0, sliceIndex)}`));
			translations.push(lastTranslation);

			if (translations.length === 2) translations.push(`(${t('categories.all_products')})`);
			if (translations.length === 1) translations.push(`(${t('categories.all_categories_and_products')})`);

			const text = translations.join(' > ');
			result[id] = { id, text };
			return result;
		}, {});
	}, [tenant, t]);

	const getAutocompleterItems = React.useCallback((productTypeId: string) => {
		const currentIds = map(productTypes, 'id');
		const cleanedProductTypes: ProductTypeIndexedItems = omit(productTypeItems, currentIds);
		if (productTypeId) cleanedProductTypes[productTypeId] = productTypeItems[productTypeId];
		return orderBy(cleanedProductTypes, 'text');
	}, [productTypeItems, productTypes]);

	const getExpressionComponent = React.useCallback((index: number, productType: ProductType) => (
		<div key={`productType-${index}`}
			className={`print-definition-expression content-expression ${productType.enabled ? 'enabled' : 'disabled'}`}
		>
			<SelectionControl
				id={`focusFeedDefinitionPrintExpressionEnableProductType-${index}`}
				name={`focusFeedDefinitionPrintExpressionEnableProductType-${index}`}
				aria-label={`focusFeedDefinitionPrintExpressionEnableProductType-${index}`}
				className={`definition-expression-enabled ${productType.enabled ? 'checkbox-green' : ''} `}
				type="checkbox"
				checked={productType.enabled}
				onChange={val => onUpdateExpression(index, { ...productType, enabled: val as boolean })}
			/>
			<span className="definition-expression-label">
				{`${index > 0 ? t('feed.form.or') : ''} ${t('feed.form.label.product_types')}:`}
			</span>
			<Autocompleter id={`productTypeAutocompleter-${index}`} className="definition-expressions-query"
				items={getAutocompleterItems(productType.id)}
				defaultText={productType.id ? productTypeItems[productType.id].text : ''}
				onSelected={item => {
					const id = item ? item.id : '';
					onUpdateExpression(index, { ...productType, id });
				}}
				disabled={!productType.enabled}
			/>
			<Button id={`focusFeedPrintProductTypeRemove-${index}`}
				className="definition-expression-remove-button"
				icon
				onClick={() => onRemoveExpression(productType.id, 'product_types', index)}
			>delete</Button>
		</div>
	), [getAutocompleterItems, onRemoveExpression, onUpdateExpression, productTypeItems, t]);

	return (
		<div id="focusFeedDefinitionPrintProductTypesSection" className="definition-section">
			<div className="definition-section-header">
				<div className="definition-header-title definition-header-title-product-types" onClick={() => setCollapse(!collapse)}>
					{
						collapse ?
							<FontIcon className="definition-header-title-toggle">keyboard_arrow_right</FontIcon> :
							<FontIcon className="definition-header-title-toggle">keyboard_arrow_down</FontIcon>
					}
					<span className="definition-header-title-text"> {t('feed.form.product_types')} </span>
				</div>
			</div>
			<Collapse collapsed={collapse}>
				<div className="definition-section-content">
					<div className="definition-expressions">
						{
							productTypes.map((productType, index) => getExpressionComponent(index, productType))
						}
						<div className="definition-add-expression">
							<Button
								id="focusFeedDefinitionPrintAddProductTypeBtn"
								className="definition-add-expression-button"
								flat
								onClick={() => { onAddExpression({ id: '', enabled: true }); }}
							>
								<span>+</span> {t('feed.form.button.add_product_types')}
							</Button>
						</div>
					</div>
				</div>
			</Collapse>
		</div>
	);
};

export default withT(React.memo(FocusFeedDefinitionPrintSectionProductType));
