import React from 'react';
import { withT } from 'lib/i18n';
import { map } from 'lib/imports/lodash';
import { CircularProgress } from 'lib/imports/react-md';

import ReportStatusItem from './Item';
import { ComponentProps } from './types';

import './ReportStatus.scss';

const ReportStatus: React.FC<ComponentProps> = props => {
	const { focusId, focus, jobs, jobsLoaded, t } = props;
	const { onFetchFocusJobs, onClearJobs } = props;

	React.useMemo(() => {
		if (focus) onFetchFocusJobs();
		else onClearJobs();
		return onClearJobs;
	}, [focus]); // eslint-disable-line react-hooks/exhaustive-deps

	const header = React.useMemo(() => {
		if (!focusId) return (
			<div className="report-status-header">
				<div className="description-title">
					<div className="description-title-header">{t('reports.select_focus')}</div>
				</div>
				<div className="description-text">
					<p>{t('reports.please_select_your_focus')}</p>
				</div>
			</div>
		);
		return (
			<div className="report-status-header">
				<div className="description-title">
					<div className="description-title-header">{t('reports.create_report')}</div>
				</div>
				<div className="description-text">
					<p>{t('reports.create_report_steps_1')}</p>
					<p>{t('reports.create_report_steps_2')}</p>
				</div>
			</div>
		);
	}, [focusId, t]);

	const content = React.useMemo(() => {
		if (!focus) return null;
		if (!jobsLoaded) return <CircularProgress id="reportJobsLoading" />;
		return (
			<div className="report-status-list">
				<div className="report-status-list-title">{t('reports.status_reports_available', { count: jobs.length })}</div>
				<div className="report-status-list-items">
					{map(jobs, job => <ReportStatusItem job={job} key={`job-${job.id}`} />)}
				</div>
			</div>
		);
	}, [focus, jobsLoaded, jobs, t]);

	return (
		<div id="reportStatus" className='discover-form-sidebar'>
			<div className="sticky-wrapper">
				{header}
				{content}
			</div>
		</div>
	);
};

export default withT(React.memo(ReportStatus));
