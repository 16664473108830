import { put, all, takeLatest, select } from 'redux-saga/effects';
import { cloneDeep, concat, every, find, get, includes, keys, map, pull, pullAll, reject, transform, xor, forEach } from 'lib/imports/lodash';

import { State } from 'store/types';

import { operators, Actions } from './actions';
import { operators as formOperators } from 'store/search/form';
import { operators as directReportOperators } from 'store/ui/report/direct';
import { operators as automateTagsOperators } from 'store/search/results';
import { FiltersFacetGroup, FilterFacet } from 'class/Filter';
import { Focus, FocusObject } from 'class/Focus';
import { Facet } from 'class/Facet';

export default function* sagas() {
	yield all([
		takeLatest(operators.toggleFacetFilter.type, toggleFacetFilter),
		takeLatest(operators.removeFacetFilterGroup.type, removeFacetFilterGroup),
		takeLatest(operators.toggleFilteredTopic.type, toggleFilteredTopic),
		takeLatest(operators.toggleFilteredFocus.type, toggleFilteredFocus),
		takeLatest(operators.toggleFilteredFeed.type, toggleFilteredFeed),
		takeLatest(operators.removeFocusFeedFilter.type, removeFocusFeedFilter),
		takeLatest(operators.setFacetGroupFilters.type, setFacetGroupFilters)
	]);
}

function* toggleFacetFilter({ payload: { facetGroupKey, facetFilter } }: Actions["ToggleFacetFilter"]) {
	let facetsGroups: FiltersFacetGroup = yield select((state: State) => state.search.filters.facetsGroups);

	facetsGroups = cloneDeep(facetsGroups);

	if (facetsGroups[facetGroupKey] && !!find(facetsGroups[facetGroupKey], groupFacetFilter => groupFacetFilter.key === facetFilter.key)) {
		facetsGroups[facetGroupKey] = reject(facetsGroups[facetGroupKey], { key: facetFilter.key });
		if (facetsGroups[facetGroupKey].length === 0) delete facetsGroups[facetGroupKey];
	} else {
		if (!facetsGroups[facetGroupKey]) facetsGroups[facetGroupKey] = [];
		facetsGroups[facetGroupKey].push(facetFilter);
	}

	yield put(operators.setFacetFilters({ facetsGroups }));
	yield put(formOperators.setStart({ start: 0 }));
	yield put(directReportOperators.toggleQuickReport(true));
	yield put(automateTagsOperators.toggleAutomateTags(true));
}

function* removeFacetFilterGroup({ payload: { facetGroupKey } }: Actions["RemoveFacetFilterGroup"]) {
	let facetsGroups: FiltersFacetGroup = yield select((state: State) => state.search.filters.facetsGroups);

	facetsGroups = cloneDeep(facetsGroups);

	delete facetsGroups[facetGroupKey];

	yield put(operators.setFacetFilters({ facetsGroups }));
	yield put(formOperators.setStart({ start: 0 }));
	yield put(directReportOperators.toggleQuickReport(true));
	yield put(automateTagsOperators.toggleAutomateTags(true));
}

function* toggleFilteredFocus({ payload: { focusId } }: Actions["ToggleFilteredFocus"]) {
	let focus: string[] = yield select((state: State) => state.search.filters.focus);
	let feeds: string[] = yield select((state: State) => state.search.filters.feeds);
	const focusList: FocusObject[] = yield select((state: State) => state.focus.list.focusList);

	focus = cloneDeep(focus);
	feeds = cloneDeep(feeds);
	const focusFeedsIds = new Focus(find(focusList, { id: focusId })).getFeeds().map(feed => feed.id);

	focus = xor(focus, [focusId]);
	pullAll(feeds, focusFeedsIds);

	yield put(operators.setFilteredFocusFeeds({ focus, feeds }));
	yield put(formOperators.setStart({ start: 0 }));
	yield put(directReportOperators.toggleQuickReport(true));
	yield put(automateTagsOperators.toggleAutomateTags(true));
}

function* toggleFilteredTopic({ payload: { topic } }: Actions["ToggleFilteredTopic"]) {
	const topicTagsIds = map(topic.tags, tag => `${topic.id}_${tag.id}`);
	let filterFacetsGroup: FiltersFacetGroup = yield select((state: State) => state.search.filters.facetsGroups);
	let filteredTags = transform<FilterFacet, Record<string, boolean>>(get(filterFacetsGroup, [Facet.tagsGroupKey]), (result, filter) => result[filter.key] = true, {});

	// If all tags are filtered, remove all from filters. Otherwise add them.
	if (every(topicTagsIds, tagId => !!filteredTags[tagId])) forEach(topicTagsIds, tagId => delete filteredTags[tagId]);
	else forEach(topicTagsIds, tagId => filteredTags[tagId] = true);

	filterFacetsGroup[Facet.tagsGroupKey] = map(keys(filteredTags), tagId => ({ key: tagId }));
	yield put(operators.setFacetFilters({ facetsGroups: filterFacetsGroup }));
	yield put(formOperators.setStart({ start: 0 }));
	yield put(directReportOperators.toggleQuickReport(true));
	yield put(automateTagsOperators.toggleAutomateTags(true));
}

function* toggleFilteredFeed({ payload: { focusId, feedId } }: Actions["ToggleFilteredFeed"]) {
	let focus: string[] = yield select((state: State) => state.search.filters.focus);
	let feeds: string[] = yield select((state: State) => state.search.filters.feeds);
	const focusList: FocusObject[] = yield select((state: State) => state.focus.list.focusList);

	focus = cloneDeep(focus);
	feeds = cloneDeep(feeds);
	const focusFeedsIds = new Focus(find(focusList, { id: focusId })).getFeeds().map(feed => feed.id);

	if (includes(focus, focusId)) {
		const feedsToEnable = pull(cloneDeep(focusFeedsIds), feedId);
		pull(focus, focusId);
		feeds = concat(feeds, feedsToEnable);
	} else {
		feeds = xor(feeds, [feedId]);
		if (every(focusFeedsIds, id => includes(feeds, id))) {
			focus.push(focusId);
			pullAll(feeds, focusFeedsIds);
		}
	}

	yield put(operators.setFilteredFocusFeeds({ focus, feeds }));
	yield put(formOperators.setStart({ start: 0 }));
	yield put(directReportOperators.toggleQuickReport(true));
	yield put(automateTagsOperators.toggleAutomateTags(true));
}

function* removeFocusFeedFilter() {
	yield put(operators.setFilteredFocusFeeds({ focus: [], feeds: [] }));
	yield put(formOperators.setStart({ start: 0 }));
	yield put(directReportOperators.toggleQuickReport(true));
	yield put(automateTagsOperators.toggleAutomateTags(true));
}

function* setFacetGroupFilters({ payload: { groupKey, groupFacetsFiltered, doSearch = true } }: Actions["SetFacetGroupFilters"]) {
	let facetsGroups: FiltersFacetGroup = yield select((state: State) => state.search.filters.facetsGroups);

	facetsGroups = cloneDeep(facetsGroups);
	if (!groupFacetsFiltered.length || groupFacetsFiltered[0].key === 'all') delete facetsGroups[groupKey];
	else facetsGroups[groupKey] = groupFacetsFiltered;

	yield put(operators.setFacetFilters({ facetsGroups }));
	if (doSearch) {
		yield put(formOperators.setStart({ start: 0 }));
	}
	yield put(directReportOperators.toggleQuickReport(true));
	yield put(automateTagsOperators.toggleAutomateTags(true));
}
