import ActionCreators from 'lib/store/actions';

const actions = new ActionCreators('@@newsletter/list');

const [toggleNewsletterEnabled, toggleNewsletterEnabledSuccess, toggleNewsletterEnabledError] = actions.apiOperators<{ id: string }>('TOGGLE_NEWSLETTER_ENABLED', {
	reload: true
});
const [removeNewsletter, removeNewsletterSuccess, removeNewsletterError] = actions.apiOperators<{ id: string }>('REMOVE_NEWSLETTER', { reload: true });

export type Actions = {
	ToggleNewsletterEnabled: ReturnType<typeof toggleNewsletterEnabled>;
	ToggleNewsletterEnabledSuccess: ReturnType<typeof toggleNewsletterEnabledSuccess>;
	ToggleNewsletterEnabledError: ReturnType<typeof toggleNewsletterEnabledError>;
	RemoveNewsletter: ReturnType<typeof removeNewsletter>;
	RemoveNewsletterSuccess: ReturnType<typeof removeNewsletterSuccess>;
	RemoveNewsletterError: ReturnType<typeof removeNewsletterError>;
};

export const operators = {
	toggleNewsletterEnabled,
	toggleNewsletterEnabledSuccess,
	toggleNewsletterEnabledError,
	removeNewsletter,
	removeNewsletterSuccess,
	removeNewsletterError
};
