
import React from 'react';
import reject from 'lodash/reject';
import { CircularProgress } from 'react-md/lib/Progress';
import differenceBy from 'lodash/differenceBy';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import uniqBy from 'lodash/uniqBy';
import Checkbox from 'react-md/lib/SelectionControls/Checkbox';

import { withT } from 'lib/i18n';
import DocumentItem from 'components/common/Document/Item/DocumentItem';
import { Document } from 'class/Document';
import documentFactory from 'class/Document/factory';
import { ComponentProps } from './types';

import './ArticleInsertSelectDocument.scss';

class ArticleInsertSelectDocument extends React.Component<ComponentProps> {

	private __getDocumentItems(documents: Document[], showCheckBox: boolean) {
		const { selectedDocumentIds, onSelectedDocumentId, getCategoryName, tenant } = this.props;

		return documents.map(document => {
			document.category = getCategoryName(document.category_id) || "";
			return (
				<div key={document.id} id={`articleInsertSelectDocument-${document.id}`} className="document-results-item">
					{showCheckBox ? <div className="document-results-item-select">
						<Checkbox
							id={`documentSelect-${document.id}`}
							className="document-results-item-select-checkbox"
							aria-label={document.id}
							name="documentSelect"
							checked={!!selectedDocumentIds[document.id]}
							onChange={() => onSelectedDocumentId(document.id)}
						/>
					</div>
						: null}
					<div className="document-item">
						<DocumentItem document={document.prepare()} tenant={tenant} />
					</div>
				</div>
			);
		});
	}

	public render() {
		const { t, loading, historicalDocuments, tenantDocuments, feedDocuments, searchNotFoundReason } = this.props;

		// We merge tenant + historical documents and then filter feed documents from the list
		const historicalDocumentsNotInFeed = differenceBy(uniqBy(concat(historicalDocuments, tenantDocuments), 'id'), feedDocuments, 'id');
		const filteredFeedDocuments = reject(feedDocuments.map(document => documentFactory(document)), isNull) as Document[];
		const filteredHistoricalDocuments = reject(historicalDocumentsNotInFeed.map(document => documentFactory(document)), isNull) as Document[];

		if (searchNotFoundReason) return <p>{t(`insert_article.modal.not_found.${searchNotFoundReason}`)}</p>;
		if (isEmpty(filteredFeedDocuments) && isEmpty(filteredHistoricalDocuments)) return null;
		if (loading) return <CircularProgress id="insertDocumentDialogLoading" />;

		let historicalDivContent = <></>;
		if (!isEmpty(filteredHistoricalDocuments)) {
			historicalDivContent = (
				<div id="articleInsertSelectHistoricalDocuments">
					<div className="description">{t('insert_article.modal.historical_document_found', [filteredHistoricalDocuments.length])}</div>
					{this.__getDocumentItems(filteredHistoricalDocuments, true)}
				</div>);
		}

		let tenantDivContent = <></>;
		if (!isEmpty(filteredFeedDocuments)) {
			tenantDivContent = (
				<div id="articleInsertSelectFeedDocuments">
					<div className="description">{t('insert_article.modal.tenant_document_found')}</div>
					{this.__getDocumentItems(filteredFeedDocuments, false)}
				</div>);
		}

		return (
			<div id="articleInsertSelectDocument">
				{historicalDivContent}
				{tenantDivContent}
			</div>
		);
	}
}

export default withT(ArticleInsertSelectDocument);
